import { FC } from "react";
import { PieTooltipProps } from "@nivo/pie";
import cn from "classnames";

import { PieData } from "common/components/atoms/Charts/Pie.chart";
import useFormatNumbers from "common/hooks/useFormatNumbers";

import classes from "./Charts.module.scss";

type PropsTypes = {
  pieTooltipData: PieTooltipProps<PieData>;
};

const ShareClassesTooltip: FC<PropsTypes> = ({ pieTooltipData }) => {
  const fNumber = useFormatNumbers();
  const text = `${pieTooltipData.datum.label}: ${fNumber(pieTooltipData.datum.value, "percent")} shares`;
  const width = text.length * 8 + 10;

  return (
    <div className={classes["chart-tooltip"]} style={{ width }}>
      <div className="d-flex">
        <div
          className={cn(classes["circle"], "me-1")}
          style={{ marginTop: 2, backgroundColor: pieTooltipData.datum.data.color }}
        />
        <p className="m-0 ui-xs">
          {pieTooltipData.datum.label}: <strong>{fNumber(pieTooltipData.datum.value, "percent")}</strong>
        </p>
      </div>
    </div>
  );
};

export default ShareClassesTooltip;
