import { FC } from "react";
import { FormControl, FormControlProps, InputGroup } from "react-bootstrap";
import cn from "classnames";

import InputFeedback, { InputFeedbackProps } from "../input-blocks/input-feedback/input-feedback";
import InputHeader, { InputHeaderProps } from "../input-blocks/input-header/input-header";
import classes from "./TextArea.module.scss";

type TextAreaCustomProps = {
  isDisabled?: boolean;
  isResizable?: boolean;
  maxLength?: number;
  rows?: number;
  name?: string;
};

type TextAreaProps = Omit<FormControlProps, "size" | "disabled"> &
  InputHeaderProps &
  InputFeedbackProps &
  TextAreaCustomProps;

const TextArea: FC<TextAreaProps> = ({
  label,
  isDisabled = false,
  isOptional = false,
  isTouched = false,
  isResizable = true,
  meta,
  error,
  info,
  rows,
  maxLength,
  children,
  className,
  ...props
}) => {
  return (
    <InputGroup className={cn("flex-column", { [className as string]: className })} data-testid="text-area-container">
      <InputHeader label={label} isDisabled={isDisabled} isOptional={isOptional} meta={meta} />

      <div>
        <FormControl
          aria-valuemax={maxLength}
          as="textarea"
          aria-label={label}
          disabled={isDisabled}
          isInvalid={isTouched && !!error}
          rows={rows}
          {...props}
          value={props.value || ""}
          data-testid={`text-area-${label}`}
          className={cn(classes["control"], {
            [classes["resize"]]: isResizable,
          })}
        />
      </div>

      <InputFeedback isDisabled={isDisabled} isTouched={isTouched} error={error} info={info} />
    </InputGroup>
  );
};

export default TextArea;
