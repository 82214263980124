import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import StepInfo from "common/components/atoms/Step/StepInfo";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { LockIcon, PoolsIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { Variant } from "store/GettingStartedGuideModel";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import InfoCart from "../InfoCard/InfoCard";
import classes from "./stepThree.module.scss";

const t = createTranslation(TranslationNS.pages, "company.gettingStartedGuide");

const StepThree: FC<{ status: Variant }> = ({ status }) => {
  const navigate = useNavigate();
  const { capTable, pools } = useStoreState((state) => state.gettingStartedGuide);
  const fNumber = useFormatNumbers();

  const active = !!pools.length;
  const setupCapTable = !capTable.numberOfShareHolders;

  const handleMenagePool = useCallback(() => {
    if (active) {
      navigate(getEMPath(["plans", "poolsAndPrograms"]));
    } else {
      navigate(getEMPath(["createPool", "basic"]));
    }
  }, [active, navigate]);

  const isInactive = status === "waiting" || status === "undone";

  return (
    <Step.Item status={status}>
      <Step.Point
        icon={
          status === "done" ? undefined : status === "noSubscription" ? (
            <LockIcon />
          ) : (
            <PoolsIcon width="24px" height="24px" />
          )
        }
      />
      <StepInfo>
        {status === "undone" && <Step.Important className="mb-1" />}
        <div className="d-flex align-items-center">
          <div className={classes.poolItem}>
            <b>{t("stepThree.title")}</b>
            <p className="mt-1 mb-3">{t("stepThree.description")}</p>
            {!isInactive && !setupCapTable ? (
              <>
                {!active ? (
                  <Button size="s" isDisabled={status === "noSubscription"} onClick={handleMenagePool}>
                    {t("stepThree.createAPool")}
                  </Button>
                ) : (
                  <Button
                    size="s"
                    variant="secondary"
                    isDisabled={status === "noSubscription"}
                    onClick={handleMenagePool}
                  >
                    {t("stepThree.managePools")}
                  </Button>
                )}
                {!active && status !== "noSubscription" ? (
                  <div className={classNames("mt-1", classes.poolRequired)}>{t("stepThree.poolsRequired")}</div>
                ) : null}
              </>
            ) : null}
          </div>
          <div className={classes.poolItem}>
            <div className="d-flex align-items-center justify-content-center" style={{ marginRight: "-20px" }}>
              {status === "noSubscription" ? (
                <>
                  <LockIcon color={scssVariables.foregroundDisabled} />
                  <Ui.m className="ms-1" style={{ color: scssVariables.foregroundDisabled }}>
                    {t("subscribeForAccess")}
                  </Ui.m>
                </>
              ) : (
                <div className={classNames(classes.pool, "ms-3")}>
                  {isInactive || !pools.length ? (
                    <PoolsIcon width="50" height="50" />
                  ) : (
                    <>
                      {pools.map((item, index) => (
                        <InfoCart
                          key={index}
                          className={classes.poolCard}
                          description={t("stepThree.shares", {
                            value: fNumber(item.numberOfShares, "amount"),
                          })}
                          title={item.poolName}
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={classes.blurSection}></div>
        </div>
      </StepInfo>
    </Step.Item>
  );
};

export default StepThree;
