import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { DropdownButton, LinkHelpText, LoaderContainer, Tag } from "common/components/atoms";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import ConvertLoanPanel from "./convertible-loan/convert-loan-panel/ConvertLoanPanel";
import DeleteLoanModal from "./convertible-loan/delete-loan-modal/DeleteLoanModal";
import ConvertibleLoanEditPanel from "./convertible-loan/edit-panel/ConvertibleLoanEditPanel";
import ConvertibleLoanTable from "./convertible-loan/table/ConvertibleLoanTable";
import ConvertNotePanel from "./convertible-note/convert-note-panel/ConvertNotePanel";
import DeleteNoteModal from "./convertible-note/delete-note-modal/DeleteNoteModal";
import ConvertibleNoteEditPanel from "./convertible-note/edit-panel/ConvertibleNoteEditPanel";
import ConvertibleNoteTable from "./convertible-note/table/ConvertibleNoteTable";
import IssueEquityContext from "./IssueEquityContext";
import classes from "./Tables.module.scss";
import DeleteWarrantSlidePanel from "./warrants/conversion/delete-warrant/DeleteWarrantSlidePanel";
import ExpiredWarrantModal from "./warrants/conversion/expired-warrant/ExpiredWarrantModal";
import SettleWarrantSlidePanel from "./warrants/conversion/settle-warrant/SettleWarrantSlidePanel";
import WarrantsEditPanel from "./warrants/edit-panel/WarrantsEditPanel";
import WarrantsTable from "./warrants/table/WarrantsTable";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.issueEquity"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const Content = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId } = useParams<{ companyId: string }>();

  const { isLoading } = IssueEquityContext.useStoreState((state) => state);
  const {
    getIssueEquityThunk,
    setIsConvertibleNoteEditPanelOpen,
    setIsConvertibleLoanEditPanelOpen,
    setIsWarrantsEditPanelOpen,
  } = IssueEquityContext.useStoreActions((actions) => actions);

  const { hasFullAccess } = useFeatures(FEATURES.issueEquity);

  useEffect(() => {
    getIssueEquityThunk(Number(companyId));
  }, [companyId, getIssueEquityThunk]);

  return (
    <>
      <PageContent data-testid="issue-equity-page-test-id">
        <PageContent.Header>
          <div className="d-flex  align-items-center">
            <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>

          {!hasFullAccess ? (
            <LinkHelpText
              tooltipWithTitle
              overlayClass={classes["tooltip"]}
              title={!hasFullAccess ? tCommon("viewOnly") : ""}
              content={!hasFullAccess ? tCommon("tooltip") : undefined}
            >
              <DropdownButton
                alignToRight
                isDisabled={!hasFullAccess}
                dataTestId="issue-dropdown"
                title={t("actions.issue")}
                dropdownIconColor={scssVariables.surface1}
                size="m"
                options={[
                  {
                    title: t("actions.issueConvertibleNote"),
                    handler: setIsConvertibleNoteEditPanelOpen.bind(null, { open: true }),
                  },
                  {
                    title: t("actions.issueConvertibleLoan"),
                    handler: setIsConvertibleLoanEditPanelOpen.bind(null, { open: true }),
                  },
                  {
                    title: t("actions.issueWarrant"),
                    handler: setIsWarrantsEditPanelOpen.bind(null, { open: true }),
                  },
                ]}
              />
            </LinkHelpText>
          ) : (
            <DropdownButton
              alignToRight
              dataTestId="issue-dropdown"
              title={t("actions.issue")}
              dropdownIconColor={scssVariables.surface1}
              size="m"
              options={[
                {
                  title: t("actions.issueConvertibleNote"),
                  handler: setIsConvertibleNoteEditPanelOpen.bind(null, { open: true }),
                },
                {
                  title: t("actions.issueConvertibleLoan"),
                  handler: setIsConvertibleLoanEditPanelOpen.bind(null, { open: true }),
                },
                {
                  title: t("actions.issueWarrant"),
                  handler: setIsWarrantsEditPanelOpen.bind(null, { open: true }),
                },
              ]}
            />
          )}
        </PageContent.Header>

        <LoaderContainer loading={isLoading}>
          <ConvertibleNoteTable />
          <ConvertibleLoanTable />
          <WarrantsTable />
        </LoaderContainer>

        <ConvertibleNoteEditPanel />
        <ConvertibleLoanEditPanel />
        <WarrantsEditPanel />

        <DeleteNoteModal />
        <DeleteLoanModal />

        {/* conversion */}
        <SettleWarrantSlidePanel />
        <DeleteWarrantSlidePanel />
        <ExpiredWarrantModal />

        <ConvertNotePanel />
        <ConvertLoanPanel />
      </PageContent>
    </>
  );
};

const IssueEquity = () => {
  return (
    <IssueEquityContext.Provider>
      <Content />
    </IssueEquityContext.Provider>
  );
};

export default IssueEquity;
