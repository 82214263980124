import { FC, useEffect } from "react";
import classNames from "classnames";

import { useStoreActions } from "store/store";

import WizardLayoutStore from "./store";
import UnfinishedChangesProvider, {
  UnfinishedChangesProviderProps,
} from "./UnfinishedChangesProvider/UnfinishedChangesProvider";
import WizardCloseConfirm from "./WizardCloseConfirm/WizardCloseConfirm";
import WizardContent from "./WizardContent/WizardContent";
import WizardHeader from "./WizardHeader/WizardHeader";
import WizardHeaderBar from "./WizardHeaderBar/WizardHeaderBar";
import classes from "./WizardLayout.module.scss";

export type WizardLayoutProps = JSX.IntrinsicElements["div"] & {
  checkUnfinishedChanges?: UnfinishedChangesProviderProps;
};

type WizardLayoutComponent = FC<WizardLayoutProps> & {
  CloseConfirm: typeof WizardCloseConfirm;
  Content: typeof WizardContent;
  Header: typeof WizardHeader;
};

const WizardLayout: WizardLayoutComponent = ({ className, checkUnfinishedChanges, children, ...props }) => {
  const setIsWizardLayoutAction = useStoreActions((actions) => actions.app.setIsWizardLayout);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsWizardLayoutAction(true);

    return () => {
      setIsWizardLayoutAction(false);
    };
  }, [setIsWizardLayoutAction]);

  if (checkUnfinishedChanges) {
    return (
      <WizardLayoutStore.Provider>
        <UnfinishedChangesProvider {...checkUnfinishedChanges}>
          <WizardHeaderBar />

          <div className={classNames(classes["wrap"], className)} {...props}>
            {children}
          </div>
        </UnfinishedChangesProvider>
      </WizardLayoutStore.Provider>
    );
  }

  return (
    <WizardLayoutStore.Provider>
      <div className={classes["view"]}>
        <WizardHeaderBar />

        <div className={classNames(classes["wrap"], className)} {...props}>
          {children}
        </div>
      </div>
    </WizardLayoutStore.Provider>
  );
};

WizardLayout.CloseConfirm = WizardCloseConfirm;
WizardLayout.Content = WizardContent;
WizardLayout.Header = WizardHeader;

export default WizardLayout;
