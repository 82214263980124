import { FC, useCallback, useMemo } from "react";
import classNames from "classnames";
import { FormikConfig, useFormik } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { Button, ModalInfo, TextField } from "common/components/atoms";
import { PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ManagePlansContext from "../../../managePlansContext";
import classes from "./SendInvite.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans.sendInvite");
const tValidate = createTranslation(TranslationNS.validation);

type SendInviteProps = {
  companyId: string;
};

const SendInvite: FC<SendInviteProps> = ({ companyId }) => {
  const planData = ManagePlansContext.useStoreState((state) => state.sendInviteInfo);
  const { setSendInviteInfo } = ManagePlansContext.useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setSendInviteInfo(null);
  }, [setSendInviteInfo]);

  return (
    <ModalInfo
      className={classes.sendInvite}
      header={t("title", { name: planData?.representedBy })}
      show={!!planData}
      handleClose={handleClose}
    >
      {planData?.id && (
        <FormData
          userEmail={
            planData.statusId === PlanStatusesBasedOnAPIStatusId.waitingForManager
              ? planData.managerEmail
              : planData.stakeholderEmail
          }
          handleClose={handleClose}
          planId={planData.id}
          companyId={companyId}
        />
      )}
    </ModalInfo>
  );
};

type FormDataProps = {
  handleClose: () => void;
  planId: number;
  companyId: string;
  userEmail?: string;
};
const FormData: FC<FormDataProps> = ({ handleClose, planId, companyId, userEmail }) => {
  const { setConfirmAndSendEmailThunk } = useStoreActions((actions) => actions.planModel);
  const { getOwnershipPlansThunk } = useStoreActions((actions) => actions.company);

  const initialValues = useMemo(
    () => ({
      email: R.defaultTo("", userEmail),
    }),
    [userEmail]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(tValidate("invalidEmail")).required(tValidate("required")),
      }),
    []
  );

  const submitHandler = useCallback<FormikConfig<typeof initialValues>["onSubmit"]>(
    async (values, { setSubmitting }) => {
      const res = await setConfirmAndSendEmailThunk({ planId, email: values.email });
      if (res) {
        getOwnershipPlansThunk(+companyId);
        notify(t("emailSent"), true, "success");
        handleClose();
      }
      setSubmitting(false);
    },
    [companyId, getOwnershipPlansThunk, handleClose, planId, setConfirmAndSendEmailThunk]
  );

  const { values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-center">
          <TextField
            className={classNames(classes.email, "mb-5")}
            type="email"
            label={t("description")}
            value={values.email}
            error={errors.email}
            isTouched={touched.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="primary" type="submit" className="me-2">
            {t("sendInvite")}
          </Button>
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SendInvite;
