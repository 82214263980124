import { FC } from "react";
import { FormControlProps } from "react-bootstrap/FormControl";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import TextArea from "common/components/atoms/TextArea/TextArea";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.purposeField");

const PurposeField: FC<FormGroupProps & Pick<FormControlProps, "onFocus">> = ({ onFocus, ...props }) => {
  const { values, handleChange } = useFormikContext<Pick<ShareClassFormData, "purpose">>();

  return (
    <FormGroup controlId="purpose" {...props}>
      <TextArea
        rows={8}
        isOptional
        label={t("label")}
        placeholder={t("label")}
        value={values.purpose || ""}
        onFocus={onFocus}
        onChange={handleChange}
      />
    </FormGroup>
  );
};

export default PurposeField;
