import { FC, ReactNode, useEffect } from "react";

import TableBrowserContext from "./TableBrowserContext";

type TableBrowserStorageProps = {
  children: ReactNode;
  tableName: string;
};

const TableBrowserStorage: FC<TableBrowserStorageProps> = ({ children, tableName }) => {
  return (
    <TableBrowserContext.Provider>
      <TableBrowserInit tableName={tableName}>{children}</TableBrowserInit>
    </TableBrowserContext.Provider>
  );
};

type TableBrowserInitProps = {
  children: ReactNode;
  tableName: string;
};
const TableBrowserInit: FC<TableBrowserInitProps> = ({ children, tableName }) => {
  const setTableName = TableBrowserContext.useStoreActions((actions) => actions.setTableName);

  useEffect(() => {
    setTableName(tableName);
  }, [setTableName, tableName]);
  return children;
};

export default TableBrowserStorage;
