import { FC, useEffect, useRef } from "react";
import { useFormikContext } from "formik";

import { scrollToHeaderTop } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

const tValidation = createTranslation(TranslationNS.common, "notify");
type FormValidatorProps = {
  panelId: string;
};
const FormValidatorToast: FC<FormValidatorProps> = ({ panelId }) => {
  const { isValid, isValidating, isSubmitting, submitCount } = useFormikContext();
  const numberOfSubmits = useRef(0);

  useEffect(() => {
    if (!isValid && !isValidating && !isSubmitting) {
      if (submitCount > numberOfSubmits.current) {
        notify(tValidation("formValidationError"), true, "error", 3000);
        scrollToHeaderTop(panelId);
        numberOfSubmits.current = submitCount;
      }
    }
  }, [isValid, isValidating, isSubmitting, submitCount, panelId]);
  return <></>;
};

export default FormValidatorToast;
