import { useMemo } from "react";
import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import TextField from "common/components/atoms/TextField/TextField";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData, fields } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.basics");

const ProgramNameField = () => {
  const { handleBlur, values, errors, touched, handleChange } = useFormikContext<CreateProgramFormData>();

  const answer = useMemo(
    () => ({
      render: () => t.el("name.answer"),
    }),
    []
  );

  return (
    <Helper.Question questionId="name" type="input" answer={answer}>
      <TextField
        label={t("name.label")}
        value={values.name}
        isTouched={touched.name}
        error={errors.name}
        name={fields.name}
        onBlur={handleBlur}
        onChange={handleChange}
      />
    </Helper.Question>
  );
};

export default ProgramNameField;
