import { forwardRef, ForwardRefRenderFunction, ReactNode, useId } from "react";
import FormCheck, { FormCheckProps } from "react-bootstrap/FormCheck";
import classNames from "classnames";

import { Ui } from "../Typography";
import classes from "./Check.module.scss";

type CheckboxCustomProps = {
  description?: string;
  isNoFocus?: boolean;
  customLabel?: ReactNode;
};

export type CheckboxProps = FormCheckProps & CheckboxCustomProps;

const Check: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (
  { className, id, label, customLabel, description, isNoFocus, disabled, ...props },
  ref
) => {
  const formCheckId = useId();

  const labelInfo =
    description && label ? (
      <>
        <Ui.m className="font-weight-normal">{label}</Ui.m>
        <Ui.s
          className={classNames(classes["description"], {
            [classes["disabled"]]: disabled,
          })}
        >
          {description}
        </Ui.s>
      </>
    ) : label ? (
      <Ui.m className="font-weight-normal">{label}</Ui.m>
    ) : null;

  return (
    <FormCheck
      className={classNames(classes["form-check"], className, {
        [classes["disabled"]]: disabled,
        [classes["no-focus"]]: isNoFocus,
      })}
      id={formCheckId}
      label={customLabel ? customLabel : labelInfo}
      disabled={disabled}
      data-testid={`${props.name}-checkbox-test-id`}
      {...props}
      ref={ref}
    />
  );
};

export default forwardRef(Check);
