import { FC, memo } from "react";

import Spinner from "../Spinner/Spinner";

type LoaderContainerProps = {
  loading: boolean;
  children?: any;
};

const LoaderContainer: FC<LoaderContainerProps> = ({ loading, children }) => {
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return children;
};

export default memo(LoaderContainer);
