import { useFormikContext } from "formik";

import Helper from "common/components/atoms/Helper/Helper";
import SigningManagerField from "common/components/molecules/signing-manager/SigningManagerField";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.summary");

const ProgramSigningManagerField = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<Pick<CreateProgramFormData, "managerId">>();
  const changeVal = (id: number | string) => {
    setFieldValue("managerId", id);
  };
  return (
    <Helper.Question questionId="signingManager" type="input" answer={t("signingManager.answer")}>
      <SigningManagerField
        error={errors.managerId}
        selectedId={values.managerId}
        isTouched={touched.managerId}
        label={t("signingManager.label")}
        onChange={changeVal}
      />
    </Helper.Question>
  );
};
export default ProgramSigningManagerField;
