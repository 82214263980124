import { FC } from "react";

import { PlusIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./add-share-class-card.module.scss";

type PropsTypes = {
  handleClick: () => void;
};

const t = createTranslation(TranslationNS.pages, "managerOnboarding.shareClassesStep.addShareClassCard");

const AddShareClassCard: FC<PropsTypes> = ({ handleClick }) => {
  return (
    <div className={classes["add-share-class-card"]} onClick={handleClick}>
      <div className={classes["plus-icon"]}>
        <PlusIcon fontSize={28} />
      </div>
      <div>{t("title")}</div>
    </div>
  );
};

export default AddShareClassCard;
