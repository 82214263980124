import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { format as formatFns } from "date-fns";
import { useFormikContext } from "formik";

import { Dropdown } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import { newPlanFieldsNames } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../../../../../ownership/transactions/transactions-service";
import { AvailableShareClass } from "../../../../../../../ownership/transactions/types";
import { OwnershipPlanForm } from "../../../../CreateGrantOneOffPlan";

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.shareClassField");

const StakeholderShareClasses: FC = () => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [availableShareClasses, setAvailableShareClasses] = useState<AvailableShareClass[]>([]);

  const { values, handleChange, errors, touched, handleBlur, setFieldValue } = useFormikContext<OwnershipPlanForm>();
  const { getAvailableShareClasses } = useTransactionsService(companyId);

  useEffect(() => {
    if (values.sharesFromStakeholderId) {
      setIsLoading(true);
      getAvailableShareClasses({
        companyId: Number(companyId),
        stakeholderId: values.sharesFromStakeholderId,
        transactionDate: formatFns(new Date(), dateFormatWithTime),
      }).then((shareClasses) => {
        setAvailableShareClasses(shareClasses);
      });
      setIsLoading(false);
    }
  }, [companyId, getAvailableShareClasses, values.sharesFromStakeholderId]);

  const shareClassesOptions = useMemo(() => {
    return availableShareClasses.map((shareClass) => ({
      id: shareClass.shareClassId,
      name: `${shareClass.shareClassName} (${shareClass.sharesAvailable})`,
    }));
  }, [availableShareClasses]);

  useEffect(() => {
    if (values.shareClassId) {
      const shareClass = availableShareClasses.find((shareClass) => shareClass.shareClassId === values.shareClassId);
      if (shareClass) {
        setFieldValue(newPlanFieldsNames.numberOfSharesLeft, shareClass.sharesAvailable || 0, true);
      }
    }
  }, [availableShareClasses, setFieldValue, values.shareClassId]);

  return (
    <Dropdown
      label={t("label")}
      selectedValue={values.shareClassId || undefined}
      optionsIsObject
      name={"shareClassId"}
      onChange={handleChange}
      onBlur={handleBlur}
      error={errors.shareClassId}
      isTouched={touched.shareClassId}
      options={shareClassesOptions}
      isLoading={isLoading}
      isDisabled={isLoading}
    />
  );
};

export default StakeholderShareClasses;
