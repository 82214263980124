import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import TextField from "common/components/atoms/TextField/TextField";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.sharesDetails");

const ProgramExercisePriceField = () => {
  const { currencySymbol } = useCurrency();

  const { values, handleBlur, touched, errors, handleChange } = useFormikContext<CreateProgramFormData>();

  return (
    <Helper.Question questionId="exercisePrice" type="input" answer={t("exercisePrice.answer")}>
      <TextField
        min={0}
        step={0.01}
        type="number"
        error={errors.exercisePrice}
        isTouched={touched.exercisePrice}
        name="exercisePrice"
        label={t("exercisePrice.label")}
        value={values.exercisePrice}
        onChange={handleChange}
        onBlur={handleBlur}
        iconRight={currencySymbol}
      />
    </Helper.Question>
  );
};

export default ProgramExercisePriceField;
