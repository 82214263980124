import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { H, Helper } from "common/components/atoms";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import TerminationFields from "common/program/components/ProgramForm/TerminationFields";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.termination");

const Termination: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { isSubmitting } = useFormikContext<CreateProgramFormData>();

  const buttonBackClickHandler = useCallback((): void => {
    navigate(getEMPath(["createProgram", "vesting"]), { state });
  }, [navigate, state]);

  return (
    <WizardContent.Content step={4}>
      <Helper>
        <Helper.Question questionId="termination" answer={t("answer")}>
          <H.xs className="text-left">{t("title")}</H.xs>
        </Helper.Question>
        <Helper.Answer className="mt-1" answerId="termination" />

        <Form className="mt-5">
          <TerminationFields />

          <div className="d-flex mt-6">
            <WizardContent.Controls.BackButton
              className="ms-auto me-2"
              onClick={buttonBackClickHandler}
              isDisabled={isSubmitting}
            />
            <WizardContent.Controls.ContinueButton isLoading={isSubmitting} />
          </div>
        </Form>
      </Helper>
    </WizardContent.Content>
  );
};

export default Termination;
