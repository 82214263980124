import { FC, useCallback } from "react";
import cn from "classnames";
import * as R from "ramda";

import Button from "common/components/atoms/Button/Button";
import { FacebookIcon, HyperlinkIcon, InstagramIcon, LinkedInIcon, MediumIcon, TwitterIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import classes from "./SocialsSection.module.scss";

const t = createTranslation(TranslationNS.pages, "public.companyDetails");

export enum Socials {
  facebook = "facebook",
  linkedIn = "linkedIn",
  medium = "medium",
  twitter = "twitter",
  instagram = "instagram",
}

type SocialsSectionProps = {
  [Socials.facebook]?: string;
  [Socials.linkedIn]?: string;
  [Socials.medium]?: string;
  [Socials.twitter]?: string;
  [Socials.instagram]?: string;
};

const icons = {
  [Socials.facebook]: <FacebookIcon width={12} height={12} />,
  [Socials.linkedIn]: <LinkedInIcon width={12} height={12} />,
  [Socials.medium]: <MediumIcon width={12} height={14} />,
  [Socials.twitter]: <TwitterIcon width={12} height={12} />,
  [Socials.instagram]: <InstagramIcon width={12} height={12} />,
};

const SocialsSection: FC<SocialsSectionProps> = (data) => {
  const copyLinkHandler = () => {
    return new Promise((res) => {
      const el = document.createElement("input");
      el.value = window.location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      res(true);
    }).then(() => {
      notify(t("linkCopied"), true, "success", 2000, false, "bottom-center");
    });
  };

  const renderSocials = useCallback(() => {
    return R.keys(data).map((el) => {
      return !R.isNil(data[el]) ? (
        <div
          key={`Social icon key for section is -${el}`}
          className={cn("d-flex align-items-center justify-content-center cursor-pointer me-2", classes["social"])}
        >
          <a
            href={data[el] as string}
            rel="noreferrer"
            target="_blank"
            className="d-flex align-items-center justify-content-center"
          >
            {(icons as any)[el]}
          </a>
        </div>
      ) : null;
    });
  }, [data]);

  return (
    <div className="d-flex align-items-center mt-2 mb-7" data-testid="socials-section-test-id">
      {renderSocials()}
      <Button
        size="s"
        variant="secondary"
        onClick={copyLinkHandler}
        data-testid="copy-link-btn-test-id"
        iconRight={<HyperlinkIcon width={12} height={12} />}
      >
        {t("copyLink")}
      </Button>
    </div>
  );
};

export default SocialsSection;
