import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { parse } from "date-fns";

import { NewAvatar, Tooltip, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { InformationCircleOutlinedIcon } from "common/icons/svg";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { CurrentOwnership } from "../../MyPortfolioContext";
import Actions from "./Actions";
import SendTaxableValueRequest from "./components/SendTaxableValueRequest/SendTaxableValueRequest";
import classes from "./Ownership.module.scss";
import ProfitOrLoss from "./ProfitOrLoss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.ownership");

const useOwnershipTable = () => {
  const fNumber = useFormatNumbers();

  const columns = useMemo<ColumnDef<CurrentOwnership>[]>(
    () => [
      {
        accessorKey: "name",
        cell: (info) => {
          return (
            <>
              <NewAvatar
                initials={info.row.original.initials}
                imageUrl={info.row.original.avatarFilePath}
                company
                size="s"
              />
              <Ui.m className="ms-1" bold>
                {info.getValue() as string}
              </Ui.m>
            </>
          );
        },
        header: () => <Ui.s tag="span">{t("table.company")}</Ui.s>,
        enableResizing: false,
        meta: { allowClick: true },
      },
      {
        accessorKey: "numberOfShares",
        cell: (info) => fNumber(info.getValue() as number, "amount"),
        header: () => <Ui.s tag="span">{t("table.shares")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", allowClick: true },
        enableResizing: false,
      },
      {
        accessorKey: "averagePurchasePrice",
        cell: (info) =>
          fNumber(info.getValue() as number, "unitPrice", { customCurrencySymbol: info.row.original.currencySymbol }),
        header: () => <Ui.s tag="span">{t("table.purchasePrice")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", allowClick: true },
        enableResizing: true,
        minSize: 150,
      },
      {
        accessorKey: "ownershipPercentage",
        cell: (info) => fNumber(info.getValue() as number, "percent"),
        header: () => <Ui.s tag="span">{t("table.ownership")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", allowClick: true },
        enableResizing: false,
      },
      {
        accessorKey: "sharePrice",
        cell: (info) =>
          fNumber(info.getValue() as number, "sharePrice", { customCurrencySymbol: info.row.original.currencySymbol }),
        header: () => <Ui.s tag="span">{t("table.sharePrice")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", allowClick: true },
        enableResizing: true,
        minSize: 136,
      },
      {
        accessorKey: "taxableValue",
        cell: (info) => (
          <SendTaxableValueRequest
            value={fNumber(info.getValue() as number, "value", {
              customCurrencySymbol: info.row.original.currencySymbol,
            })}
            request={info.getValue() ? "receivedRequest" : "notRequested"}
            companyName={info.row.original.name}
            companyId={info.row.original.companyId}
          />
        ),
        header: () => <Ui.s tag="span">{t("table.taxableValue")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right" },
        enableResizing: false,
      },
      {
        accessorKey: "value",
        cell: (info) => (
          <div className={classes.value}>
            <span className={classes.tooltip}>
              <Tooltip
                className={classes.content}
                popupContent={
                  <div>
                    <Ui.xs tag="span">
                      Company valuation{" "}
                      {info?.row?.original?.companyValuationDate &&
                        transformDateToCommonDateFormat(
                          parse(info?.row?.original?.companyValuationDate, "yyyy-MM-dd", new Date())
                        )}
                      :{" "}
                    </Ui.xs>
                    <Ui.xs tag="span" bold>
                      {fNumber(info.row.original.companyValuation, "value", {
                        customCurrencySymbol: info.row.original.currencySymbol,
                      })}
                    </Ui.xs>
                  </div>
                }
              >
                <InformationCircleOutlinedIcon />
              </Tooltip>
            </span>
            {fNumber(info.getValue() as number, "value", { customCurrencySymbol: info.row.original.currencySymbol })}
          </div>
        ),
        header: () => <Ui.s tag="span">{t("table.value")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", allowClick: true },
        enableResizing: false,
      },
      {
        accessorKey: "profitLossValue",
        cell: (info) => {
          const row = info.row.original;
          return (
            <ProfitOrLoss
              value={info.getValue() as number | null}
              status={row.profit ? "profit" : "loss"}
              currency={info.row.original.currencySymbol}
              percentage={info.row.original.profitLossValuePercentage}
            />
          );
        },
        header: () => <Ui.s tag="span">{t("table.profit")}</Ui.s>,
        meta: { headClass: "right", bodyClass: "right", allowClick: true },
        enableResizing: false,
      },
      {
        accessorKey: "companyId",
        cell: (info) => (
          <Actions
            companyId={info.getValue() as number}
            companyName={info.row.original.name}
            hasAccessToCaptable={info.row.original.hasAccessToCapTable}
          />
        ),
        header: "",
        enableSorting: false,
        maxSize: 56,
        enableResizing: true,
      },
    ],
    [fNumber]
  );

  return { columns };
};

export default useOwnershipTable;
