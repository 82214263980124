import { FC, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import { useStoreActions, useStoreState } from "store/store";

const CompanyCheckerRoute: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);

  const { setCompanyId } = useStoreActions((action) => action.activeCompanyModel);

  useEffect(() => {
    if (companyId ? +companyId : 0 !== activeCompanyId) {
      setCompanyId(companyId ? +companyId : null);
    }
  }, [activeCompanyId, companyId, setCompanyId]);

  return <Outlet />;
};

export default CompanyCheckerRoute;
