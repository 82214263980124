import * as Yup from "yup";

import { TFunction } from "translation/helpers";

import { DocumentStatusEnum } from "../../../../../../../../common/enums/enum";
import { DocumentationDetailsFields } from "./form-fields";

export const documentationDetailsValidationSchema = (tFunction: TFunction) => ({
  [DocumentationDetailsFields.documentStatusId]: Yup.number()
    .min(1, tFunction("requiredSection"))
    .required(tFunction("requiredSection")),
  [DocumentationDetailsFields.documents]: Yup.array().when(DocumentationDetailsFields.documentStatusId, {
    is: DocumentStatusEnum.DOCUMENT_UPLOADED,
    then: Yup.array().test("documents", tFunction("required"), (documents: any, obj) => {
      return documents && (documents?.length > 0 || obj.parent?.prevFiles?.length > 0);
    }),
  }),
});
