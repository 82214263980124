import { FC, useMemo } from "react";

import Table from "common/components/atoms/Table/Table";
import { Ui } from "common/components/atoms/Typography";
import { sortHistoryValuation } from "common/helpers";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ValuationTableBody from "../ValuationTable/ValuationTableBody";
import ValuationTableHead from "../ValuationTable/ValuationTableHead";
import classes from "./ValuationHistory.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const ValuationHistory: FC = () => {
  const { valuation } = useStoreState((state) => state.company);

  const valuationSorted = useMemo(() => sortHistoryValuation(valuation?.valuations || []), [valuation?.valuations]);

  const rows = useMemo(
    () => [
      t("valuationHistoryTable.description"),
      t("valuationHistoryTable.date"),
      t("valuationHistoryTable.editor"),
      t("valuationHistoryTable.valuation"),
    ],
    []
  );

  return (
    <div className={classes["container"]}>
      <Ui.xl bold className="ms-3 pt-4 mb-3">
        {t("valuationHistory")}
      </Ui.xl>

      <Table
        className={classes.table}
        headComponent={<ValuationTableHead rows={rows} />}
        bodyComponent={<ValuationTableBody data={valuationSorted} />}
      />
    </div>
  );
};

export default ValuationHistory;
