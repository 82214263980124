import { FC, useCallback } from "react";
import classNames from "classnames";

import { ChevronLeftIcon, ChevronRightIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./pagination.module.scss";

interface IPagination {
  currentPage: number;
  totalPages: number;
  clickPrevious: (page: number) => void;
  clickNext: (page: number) => void;
}

const t = createTranslation(TranslationNS.common, "components.pagination");

const Pagination: FC<IPagination> = ({ currentPage, totalPages, clickPrevious, clickNext }) => {
  const handlePrevious = useCallback(() => {
    if (currentPage !== 0) {
      clickPrevious(currentPage - 1);
    }
  }, [clickPrevious, currentPage]);

  const handleNext = useCallback(() => {
    if (currentPage + 1 !== totalPages) {
      clickNext(currentPage + 1);
    }
  }, [clickNext, currentPage, totalPages]);
  return (
    <div className={classNames("my-3", classes["pagination"])}>
      <div className="me-2">
        <p className="m-0 me-3" style={{fontSize: "14px"}}>
          {t("count", {
            first: currentPage + 1,
            last: totalPages,
          })}
        </p>

        <ChevronLeftIcon
          className={classNames("me-1", {
            [classes["disabled"]]: currentPage === 0,
          })}
          onClick={handlePrevious}
        />
        <ChevronRightIcon
          className={classNames({
            [classes["disabled"]]: currentPage + 1 === totalPages,
          })}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default Pagination;
