import { FC, useMemo } from "react";

import PlansOverview from "common/components/molecules/PlansOverview/PlansOverview";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans");

const OverviewComponent: FC = () => {
  const fNumber = useFormatNumbers();

  const { ownershipPlans } = useStoreState((state) => state.company);

  const totalPlansAndProgramsValue = useMemo(() => {
    if (ownershipPlans) {
      return (
        +(
          ((ownershipPlans.sharesRestricted + ownershipPlans.sharesVested) / ownershipPlans.totalSharesInCompany) *
          100
        ).toFixed(2) || 0
      );
    }
    return 0;
  }, [ownershipPlans]);

  return (
    <PlansOverview
      plandata={ownershipPlans}
      title={t("combinedPlans")}
      chartLabel={t("sharesVested")}
      description={t("totalAmount")}
      remainRowTitle={t("remainingShares")}
      completedRowTitle={t("sharesVested")}
      subTotalDescription1={t("totalPlanValue1")}
      subTotalDescription2={t("totalPlanValue2", {
        value: fNumber(totalPlansAndProgramsValue, "percent"),
      })}
    />
  );
};

export default OverviewComponent;
