export const THUNK_TYPES = {
  START: "startType",
  SUCCESS: "successType",
  FAIL: "failType",
};
export const getThunkTypes = (...actions: any[]) => {
  const types = [THUNK_TYPES.START, THUNK_TYPES.SUCCESS, THUNK_TYPES.FAIL];
  const listActions: any[] = [];
  actions.forEach((action) => {
    listActions.push(...types.map((type) => action[type]));
  });
  return listActions;
};
