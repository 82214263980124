import { FC } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import cn from "classnames";

import classes from "./MegaDropdown.module.scss";

type MegaDropdownProps = {
  error?: boolean;
  children: JSX.Element;
  HeadComponent?: JSX.Element;
};

const MegaDropdown: FC<MegaDropdownProps> = ({ children, error, HeadComponent }) => {
  return (
    <Accordion>
      <Card className={cn(error ? classes["mega-dropdown-container-error"] : classes["mega-dropdown-container"])}>
        <Card.Header className={classes["mega-dropdown-header"]}>{HeadComponent}</Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className={classes["mega-dropdown-collapse-container"]}>{children}</Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default MegaDropdown;
