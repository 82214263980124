import { FC, useCallback } from "react";
import { Form, useFormikContext } from "formik";

import { ModalInfo, P } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "onboard.company.importTransaction.modal");

type OrgNumberModalProps = {
  show: boolean;
  handleClose: () => void;
  setCompletedOnboarding: (value: boolean) => void;
};

const TransactionModal: FC<OrgNumberModalProps> = ({ show, handleClose, setCompletedOnboarding }) => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  const handleClickSubmit = useCallback(() => {
    setCompletedOnboarding(true);
  }, [setCompletedOnboarding]);

  const Footer = useCallback(() => {
    return (
      <Form onSubmit={handleSubmit}>
        <Button isLoading={isSubmitting} isDisabled={isSubmitting} onClick={handleClickSubmit} type="submit">
          {t("confirm")}
        </Button>
        <Button className="ms-1" isDisabled={isSubmitting} onClick={handleClose} variant="secondary">
          {t("cancel")}
        </Button>
      </Form>
    );
  }, [handleClickSubmit, handleClose, handleSubmit, isSubmitting]);
  return (
    <ModalInfo size="sm" show={show} handleClose={handleClose} header={t("title")} footer={<Footer />}>
      <P.m>{t("description")}</P.m>
    </ModalInfo>
  );
};

export default TransactionModal;
