import axios from "axios";
import { action, createContextStore, thunk } from "easy-peasy";

import {
  ConversionDetailsCLGetDTO,
  ConversionDetailsCNGetDTO,
  ConvertCLPostDTO,
  ConvertCNPostDTO,
  ConvertibleLoanDTO,
  ConvertibleNoteDTO,
  IssueEquityContextModel,
  IssueEquityTablesDataDTO,
  IssueEquityWarrantsDTO,
} from "./IssueEquityContext.types";

const IssueEquityContext = createContextStore<IssueEquityContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    isIssueEquityEditPanelOpen: false,
    setIsIssueEquityEditPanelOpen: action((state, payload) => {
      state.isIssueEquityEditPanelOpen = payload;
    }),

    isConvertibleNoteEditPanelOpen: false,
    noteId: undefined,
    setIsConvertibleNoteEditPanelOpen: action((state, payload) => {
      state.isConvertibleNoteEditPanelOpen = payload.open;
      state.noteId = payload.noteId;
      state.convertibleNote = null;
    }),

    isConvertNoteEditPanelOpen: false,
    setIsConvertNoteEditPanelOpen: action((state, payload) => {
      state.isConvertNoteEditPanelOpen = payload.open;
      state.noteId = payload.noteId;
      state.conversionDetailsCN = null;
    }),

    isConvertLoanEditPanelOpen: false,
    setIsConvertLoanEditPanelOpen: action((state, payload) => {
      state.isConvertLoanEditPanelOpen = payload.open;
      state.loanId = payload.loanId;
      state.conversionDetailsCL = null;
    }),

    isConvertibleLoanEditPanelOpen: false,
    setIsConvertibleLoanEditPanelOpen: action((state, payload) => {
      state.isConvertibleLoanEditPanelOpen = payload.open;
      state.loanId = payload.loanId;
      state.convertibleLoan = null;
    }),

    isWarrantsEditPanelOpen: false,
    setIsWarrantsEditPanelOpen: action((state, payload) => {
      state.isWarrantsEditPanelOpen = payload.open;
      state.warrantId = payload.warrantId;
      state.warrantDetails = null;
    }),

    issueEquity: null,
    setIssueEquity: action((state, payload) => {
      state.issueEquity = payload;
    }),
    getIssueEquityThunk: thunk(async (actions, id) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<IssueEquityTablesDataDTO>(`/api/equity-management/issue-equity/${id}`);

        if (request.status === 200) {
          actions.setIssueEquity(request.data);
        }
      } finally {
        actions.setIsLoading(false);
      }
    }),

    // conversion
    warrantId: undefined,
    warrantDetails: null,
    setWarrantDetails: action((state, payload) => {
      state.warrantDetails = payload;
    }),
    getWarrantDetailsThunk: thunk(async (actions, id) => {
      try {
        const request = await axios.get<IssueEquityWarrantsDTO>(`/api/equity-management/issue-equity/warrant/${id}`);

        actions.setWarrantDetails(request.data);
        return request;
      } catch (e) {
        return await Promise.reject(e);
      }
    }),

    isSettleWarrantEditPanelOpen: false,
    setIsSettleWarrantEditPanelOpen: action((state, payload) => {
      state.isSettleWarrantEditPanelOpen = payload.open;
      state.warrantId = payload.warrantId;
    }),

    isDeleteWarrantEditPanelOpen: false,
    setIsDeleteWarrantEditPanelOpen: action((state, payload) => {
      state.isDeleteWarrantEditPanelOpen = payload.open;
      state.warrantId = payload.warrantId;
    }),

    isSetExpiredWarrantEditPanelOpen: false,
    setIsSetExpiredWarrantEditPanelOpen: action((state, payload) => {
      state.isSetExpiredWarrantEditPanelOpen = payload.open;
      state.warrantId = payload.warrantId;
      state.warrantDetails = null;
    }),

    // convert note
    convertibleNote: null,
    setConvertibleNote: action((state, payload) => {
      state.convertibleNote = payload;
    }),
    getConvertibleNoteThunk: thunk(async (actions, id) => {
      try {
        const request = await axios.get<ConvertibleNoteDTO>(
          `/api/equity-management/issue-equity/convertible-note/${id}`
        );

        actions.setConvertibleNote(request.data);
        return request;
      } catch (e) {
        return await Promise.reject(e);
      }
    }),

    isOverrideModalVisible: false,
    setIsOverrideModalVisible: action((state, payload) => {
      state.isOverrideModalVisible = payload;
    }),

    isOverrideSharesPriceModalVisible: false,
    setIsOverrideSharesPriceModalVisible: action((state, payload) => {
      state.isOverrideSharesPriceModalVisible = payload;
    }),

    numberOfNoteShares: 0,
    setNumberOfNoteShares: action((state, payload) => {
      state.numberOfNoteShares = payload;
    }),

    noteSharePrice: undefined,
    setNoteSharePrice: action((state, payload) => {
      state.noteSharePrice = payload;
    }),

    calculateCN: null,
    setCalculateCN: action((state, payload) => {
      state.calculateCN = payload;
    }),
    getCalculateCNThunk: thunk(async (actions, payload) => {
      actions.setConvertNoteLoading(true);
      try {
        const request = await axios.post("/api/equity-management/issue-equity/convertible-note/calculate", payload);
        actions.setCalculateCN(request.data);
        return request;
      } catch (e) {
        return await Promise.reject(e);
      } finally {
        actions.setConvertNoteLoading(false);
      }
    }),

    conversionDetailsCN: null,
    setConversionDetailsCN: action((state, payload) => {
      state.conversionDetailsCN = payload;
    }),
    getConversionDetailsCNThunk: thunk(async (actions, id) => {
      try {
        const request = await axios.get<ConversionDetailsCNGetDTO>(
          `/api/equity-management/issue-equity/convertible-note/conversion-details/${id}`
        );
        actions.setConversionDetailsCN(request.data);
        actions.setCalculateCN({
          discount: request?.data?.discount || 0,
          numberOfNewShares: request?.data?.numberOfNewShares || 0,
          percentageOfCompany: request?.data?.percentageOfCompany || 0,
          investmentAmountWithInterest: request?.data?.investmentAmountWithInterest || 0,
          useDiscount: request?.data?.useDiscount || false,
        });
        return request;
      } catch (e) {
        return await Promise.reject(e);
      }
    }),

    convertNoteLoading: false,
    setConvertNoteLoading: action((state, payload) => {
      state.convertNoteLoading = payload;
    }),

    isOpenDeleteModalCN: false,
    stakeholderNameCN: "",
    setIsOpenDeleteModalCN: action((state, payload) => {
      state.isOpenDeleteModalCN = payload.open;
      state.noteId = payload.noteId;
      state.stakeholderNameCN = payload.stakeholderName;
    }),
    deleteConvertibleNoteThunk: thunk(async (_actions, noteId) => {
      return await axios.delete(`/api/equity-management/issue-equity/convertible-note/${noteId}`);
    }),

    convertCNThunk: thunk(async (_actions, payload) => {
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        const item = key as keyof ConvertCNPostDTO;
        const allowTypes = ["number", "string", "boolean"];
        if (allowTypes.includes(typeof payload?.[item])) {
          formData.append(key, String(payload[item]));
        }
      });
      if (payload?.documents?.newFiles?.length) {
        payload.documents.newFiles.forEach((file) => {
          formData.append("filesData.files", file);
        });
      }
      if (payload?.documents?.oldFiles?.length) {
        payload.documents.oldFiles.forEach((file) => {
          formData.append("filesData.oldFileIds", String(file.fileId));
        });
      }
      formData.append("SendInvitationEmail", "false");
      formData.append("DocumentsNeedsSignature", "false");
      return await axios.post("/api/equity-management/issue-equity/convertible-note/convert", formData);
    }),

    // convert loan or CL
    loanId: undefined,
    convertibleLoan: null,
    setConvertibleLoan: action((state, payload) => {
      state.convertibleLoan = payload;
    }),
    getConvertibleLoanThunk: thunk(async (actions, id) => {
      try {
        const request = await axios.get<ConvertibleLoanDTO>(
          `/api/equity-management/issue-equity/convertible-loan/${id}`
        );

        actions.setConvertibleLoan(request.data);
        return request;
      } catch (e) {
        return await Promise.reject(e);
      }
    }),

    calculateCL: null,
    setCalculateCL: action((state, payload) => {
      state.calculateCL = payload;
    }),
    getCalculateCLThunk: thunk(async (actions, payload) => {
      actions.setConvertLoanLoading(true);
      try {
        const request = await axios.post("/api/equity-management/issue-equity/convertible-loan/calculate", payload);
        actions.setCalculateCL(request.data);
        return request;
      } catch (e) {
        return await Promise.reject(e);
      } finally {
        actions.setConvertLoanLoading(false);
      }
    }),

    conversionDetailsCL: null,
    setConversionDetailsCL: action((state, payload) => {
      state.conversionDetailsCL = payload;
    }),
    getConversionDetailsCLThunk: thunk(async (actions, id) => {
      try {
        const request = await axios.get<ConversionDetailsCLGetDTO>(
          `/api/equity-management/issue-equity/convertible-loan/conversion-details/${id}`
        );
        actions.setConversionDetailsCL(request.data);
        actions.setCalculateCL({
          discount: request?.data?.discount || 0,
          numberOfNewShares: request?.data?.numberOfNewShares || 0,
          percentageOfCompany: request?.data?.percentageOfCompany || 0,
          investmentAmount: request?.data?.investmentAmount || 0,
          remainingInvestment: request?.data?.remainingInvestment || 0,
          loanAmount: request?.data?.loanAmount || 0,
          interest: request?.data?.interest || 0,
          interestToRepay: request?.data?.interestToRepay || 0,
          loanAmountRepaid: request?.data?.loanAmountRepaid || 0,
          valuationCap: request?.data?.valuationCap || 0,
          useDiscount: request?.data?.useDiscount || false,
        });
        return request;
      } catch (e) {
        return await Promise.reject(e);
      }
    }),

    convertLoanLoading: false,
    setConvertLoanLoading: action((state, payload) => {
      state.convertLoanLoading = payload;
    }),

    isOpenDeleteModalCL: false,
    stakeholderNameCL: "",
    setIsOpenDeleteModalCL: action((state, payload) => {
      state.isOpenDeleteModalCL = payload.open;
      state.loanId = payload.loanId;
      state.stakeholderNameCL = payload.stakeholderName;
    }),
    deleteConvertibleLoanThunk: thunk(async (_actions, noteId) => {
      return await axios.delete(`/api/equity-management/issue-equity/convertible-loan/${noteId}`);
    }),

    convertCLThunk: thunk(async (_actions, payload) => {
      const formData = new FormData();
      Object.keys(payload).forEach((key) => {
        const item = key as keyof ConvertCLPostDTO;
        const allowTypes = ["number", "string", "boolean"];
        if (allowTypes.includes(typeof payload?.[item])) {
          formData.append(key, String(payload[item]));
        }
      });
      if (payload?.documents?.newFiles?.length) {
        payload.documents.newFiles.forEach((file) => {
          formData.append("filesData.files", file);
        });
      }
      if (payload?.documents?.oldFiles?.length) {
        payload.documents.oldFiles.forEach((file) => {
          formData.append("filesData.oldFileIds", String(file.fileId));
        });
      }
      formData.append("SendInvitationEmail", "false");
      formData.append("DocumentsNeedsSignature", "false");
      return await axios.post("/api/equity-management/issue-equity/convertible-loan/convert", formData);
    }),
  },
  {
    name: "Equity management. Issue equity",
  }
);

export default IssueEquityContext;
