import { FC, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form as FForm, useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import Helper from "common/components/atoms/Helper/Helper";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H } from "common/components/atoms/Typography";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import VestingDetails from "common/program/components/ProgramForm/VestingDetails/VestingDetails";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.vesting");

const Vesting: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { isSubmitting, values, setFieldValue } = useFormikContext<CreateProgramFormData>();

  const buttonBackClickHandler = useCallback((): void => {
    navigate(getEMPath(["createProgram", "sharesDetails"]), { state });
  }, [navigate, state]);

  useEffect(() => {
    if (values.vestingTypeId === 1) {
      setFieldValue("conditions", []);
    } else if (values.vestingTypeId === 2) {
      setFieldValue("vestingPeriod", undefined);
      setFieldValue("vestingInterval", undefined);
      setFieldValue("vestingCliff", undefined);
    }
  }, [setFieldValue, values.vestingTypeId]);

  return (
    <WizardContent.Content step={3}>
      <Helper>
        <Helper.Question questionId="vesting">
          <H.xs className="text-left">{t("title")}</H.xs>
        </Helper.Question>
        <Helper.Answer
          className="mt-1"
          answerId="vesting"
          text={t("answer")}
          linkText={t("readMore")}
          link="/"
          linkHidden={false}
        />
      </Helper>
      <FForm className="mt-3">
        <VestingDetails type="program" />

        <div className="mt-8 d-flex">
          <WizardContent.Controls.BackButton
            className="ms-auto"
            onClick={buttonBackClickHandler}
            isDisabled={isSubmitting}
          />
          <WizardContent.Controls.ContinueButton isLoading={isSubmitting} className="ms-2" />
        </div>
      </FForm>

      {isSubmitting ? <Spinner /> : null}
    </WizardContent.Content>
  );
};
export default Vesting;
