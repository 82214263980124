import { FC, Fragment, useCallback, useState } from "react";
import { AxisProps } from "@nivo/axes";
import { ComputedDatum, Datum, Layer, LineSvgProps, Point, PointTooltip, ResponsiveLine } from "@nivo/line";
import { area, curveStepAfter } from "d3-shape";
import * as R from "ramda";

import { formatNumber } from "common/hooks/useFormatNumbers";
import { AreaLayerProps, CustomComputedSerie, singleStepChartProps, stepPoint } from "common/types/Charts.types";

type StepChartProps = {
  title?: string;
  data: singleStepChartProps[];
  height?: number;
  customPoint?: boolean;
  lineLeftValue?: number;
  lineRightValue?: number;
  disableLegend?: boolean;
  showPointOnHover?: boolean;
  axisRight?: AxisProps<any> | null;
  labelLineWidthOnStepStart?: boolean;
  renderTooltip?: PointTooltip;
  axisFormatter?: (value: AxisProps["format"]) => void;
};

type ModifiedPoint = Point & { isLast?: boolean; data: any };

const StepChart: FC<StepChartProps & LineSvgProps> = ({
  data,
  title,
  axisRight,
  lineLeftValue,
  lineRightValue,
  customPoint,
  height = 350,
  renderTooltip,
  disableLegend = false,
  labelLineWidthOnStepStart = false,
  axisFormatter,
  showPointOnHover,
  ...props
}) => {
  const [current, setCurrent] = useState<ModifiedPoint>({} as ModifiedPoint);

  const handleHover = useCallback(
    (point: ModifiedPoint) => {
      if (point?.index === data?.[0].data.length - 1) {
        setCurrent({ ...point, isLast: true });
      } else {
        setCurrent({ ...point });
      }
    },

    [setCurrent, data]
  );

  const AreaLayer = ({ series, xScale, yScale }: AreaLayerProps) => {
    return series.map((el: CustomComputedSerie, index: number, array: CustomComputedSerie[]) => {
      const isLastEl = index === array.length - 1;

      const areaGenerator = area()
        .x((d: Datum) => xScale(d.data.x))
        .y0(() => yScale(Math.min(...el.data.map((item: Point) => item.data.y))))
        .y1((d: Datum) => yScale(d.data.y))
        .curve(curveStepAfter);

      return (
        <path
          pointerEvents="none"
          d={areaGenerator(el.data) as string}
          fill={isLastEl ? "transparent" : el.color}
          key={`Area svg custom layer key is - ${index}`}
          style={{
            strokeDasharray: "12, 6",
            strokeWidth: 2,
          }}
        />
      );
    });
  };

  const DashedLine = ({ series, lineGenerator, xScale, yScale }: AreaLayerProps) => {
    return series.map(({ id, data, color }: CustomComputedSerie, index: number, array: CustomComputedSerie[]) => {
      const isLastItem = index === array.length - 1;

      return (
        <path
          key={id}
          fill="none"
          stroke={color}
          style={{
            strokeWidth: 2,
            strokeDasharray: isLastItem ? "4, 4" : undefined,
          }}
          d={
            lineGenerator(
              data.map((d: ComputedDatum) => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              }))
            ) as string
          }
        />
      );
    });
  };

  function CustomPoint({ points }: AreaLayerProps) {
    const shownPoints = data.reduce(
      (acc: Point[], cur: singleStepChartProps, index: number, array: singleStepChartProps[]) => {
        if (index !== array.length - 1) {
          const value = R.last(cur.data) as stepPoint;
          const idx = points.findIndex((el) => el.data.y === value.y);

          acc.push(points[idx]);
        }

        return acc;
      },
      []
    );

    return (
      <g>
        {shownPoints.map((point: Point) => {
          return (
            <Fragment key={point.id}>
              <foreignObject width={40} height={100} x={-40} y={point.y - 20}>
                <p
                  className="m-0 p-0 ui-xxs fw-bold"
                  style={{
                    color: point.serieColor,
                  }}
                >
                  {formatNumber(R.defaultTo(Number(point.data.y), lineLeftValue))}
                </p>
              </foreignObject>
              <foreignObject width={180} height={100} x={0} y={point.y - 20}>
                <p
                  className="m-0 p-0 ui-xs fw-bold"
                  style={{
                    color: point.serieColor,
                  }}
                >
                  {point.serieId}
                </p>
              </foreignObject>
              {lineRightValue && (
                <foreignObject height={30} width="95%" y={point.y - 20} style={{ position: "relative" }}>
                  <p
                    className="m-0 p-0 ui-xxs fw-bold"
                    style={{
                      right: 0,
                      position: "absolute",
                      color: point.serieColor,
                    }}
                  >
                    {formatNumber(lineRightValue)}
                  </p>
                </foreignObject>
              )}

              <line
                x1={0}
                x2={labelLineWidthOnStepStart ? point.x : "100%"}
                y1={point.y}
                y2={point.y}
                strokeWidth={1}
                strokeDasharray="4, 4"
                stroke={point.serieColor}
              />
            </Fragment>
          );
        })}
      </g>
    );
  }

  return (
    <div
      style={{
        height,
        width: "100%",
      }}
    >
      {!R.isNil(title) ? (
        <p className=" ui-l fw-bold" style={{ margin: "0 0 0 15px" }}>
          {title}
        </p>
      ) : null}

      <ResponsiveLine
        useMesh
        enableArea
        data={data}
        isInteractive
        curve="stepAfter"
        axisRight={axisRight}
        colors={{ datum: "color" }}
        tooltip={renderTooltip ? renderTooltip : undefined}
        margin={{ bottom: 40, left: 40, right: 40, top: 40 }}
        layers={
          [
            "axes",
            "mesh",
            "slices",
            "legends",
            "grid",
            AreaLayer,
            DashedLine,
            customPoint && CustomPoint,
            showPointOnHover ? () => <ActivePointLayer point={current} /> : undefined,
          ] as Layer[]
        }
        axisBottom={{
          tickSize: 0,
          tickPadding: 15,
          format: axisFormatter ? axisFormatter : undefined,
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 10,
          tickValues: [0, Math.max(...(R.pluck("y", R.flatten(R.pluck("data", data))) as number[]))],
          format: (value) => formatNumber(value),
        }}
        legends={
          disableLegend
            ? undefined
            : [
                {
                  itemWidth: 70,
                  itemHeight: 20,
                  symbolSize: 12,
                  translateY: -10,
                  direction: "row",
                  anchor: "top-left",
                  symbolShape: "circle",
                  itemDirection: "left-to-right",
                },
              ]
        }
        theme={{
          axis: {
            ticks: {
              text: {
                fill: "#89898D",
              },
            },
          },
        }}
        onMouseMove={showPointOnHover ? handleHover : undefined}
        {...props}
      />
    </div>
  );
};

function ActivePointLayer({ point }: { point: ModifiedPoint }) {
  if (R.isEmpty(point)) {
    return null;
  }

  return (
    <g
      transform={`translate(${point.x}, ${point.y})`}
      style={{
        transition: "all 0.5s",
        pointerEvents: "none",
      }}
    >
      <circle
        r="16"
        fill={point.color}
        style={{
          opacity: 0.3,
        }}
      />
      <circle
        r="12"
        fill={point.color}
        style={{
          opacity: 0.5,
        }}
      />
      <circle r="6" fill={point.color} />
    </g>
  );
}

export default StepChart;
