import { useEffect, useRef, useState } from "react";

const useDebounce = <T>(value: T, delay: number, preventDebounce: boolean = false) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (preventDebounce) return;

    if (value === debouncedValue) return;

    if (value !== debouncedValue && timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [value, delay, preventDebounce, debouncedValue]);

  return debouncedValue;
};

export default useDebounce;
