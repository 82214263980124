import React, { ComponentType, FC, ReactNode, RefObject, useContext } from "react";
import { AccordionContext, Dropdown, useAccordionButton } from "react-bootstrap";
import Accordion, { AccordionProps } from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import cn from "classnames";

import { ChevronDownIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";

import { Ui } from "../Typography";
import classes from "./Collapsible.module.scss";

type InnerProps = {
  width?: number;
  reverse?: boolean;
  headTitle?: string;
  collapsible?: boolean;
  dividerColor?: string;
  defaultOpen?: boolean;
  children: ReactNode;
  classNames?: string;
  withoutArrow?: boolean;
  withoutDivider?: boolean;
  collapseRef?: ((instance: HTMLDivElement | null) => void) | RefObject<HTMLDivElement> | null | undefined;
  callBackOnCollapseClick?: () => void;
  Header?: ComponentType<CollapsibleHeaderProps>;
};

type CollapsibleContainerProps = InnerProps & AccordionProps;

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({
  width,
  Header,
  reverse,
  classNames,
  children,
  headTitle,
  defaultOpen,
  dividerColor,
  collapseRef,
  withoutArrow,
  withoutDivider,
  callBackOnCollapseClick,
  collapsible = true,
  ...props
}) => {
  return (
    <Accordion
      dir="bottom"
      style={{ width }}
      className={classNames}
      data-testid="collapsible-container-test-id"
      defaultActiveKey={defaultOpen || !collapsible ? "0" : undefined}
      {...props}
    >
      {!reverse ? (
        <Card.Header
          className="p-0"
          style={{
            borderBottom: "none",
          }}
        >
          {
            <AccordionHeader
              eventKey="0"
              Header={Header}
              collapsible={collapsible}
              callBackOnCollapseClick={callBackOnCollapseClick}
              headTitle={headTitle}
              withoutArrow={withoutArrow}
            />
          }
        </Card.Header>
      ) : null}

      <Accordion.Collapse eventKey="0" ref={collapseRef}>
        <>
          {withoutDivider ? null : (
            <Dropdown.Divider
              data-testid="collapsible-divider-test-id"
              className="p-0 m-0"
              style={{
                height: 1,
                opacity: 1,
                backgroundColor: dividerColor ? dividerColor : scssVariables.strokeMedium,
              }}
            />
          )}

          <Card.Body className="p-0">{children}</Card.Body>
        </>
      </Accordion.Collapse>

      {reverse ? (
        <Card.Header
          className="p-0"
          style={{
            borderBottom: "none",
          }}
        >
          {
            <AccordionHeader
              eventKey="0"
              Header={Header}
              collapsible={collapsible}
              callBackOnCollapseClick={callBackOnCollapseClick}
              headTitle={headTitle}
              withoutArrow={withoutArrow}
            />
          }
        </Card.Header>
      ) : null}
    </Accordion>
  );
};

type AccordionHeaderProps = {
  eventKey: string;
  Header?: ComponentType<CollapsibleHeaderProps>;
  callBackOnCollapseClick?: () => void;
  headTitle?: string;
  withoutArrow?: boolean;
  collapsible?: boolean;
};

const AccordionHeader: FC<AccordionHeaderProps> = ({
  eventKey,
  Header,
  callBackOnCollapseClick,
  headTitle,
  withoutArrow,
  collapsible,
}) => {
  const decoratedOnClick = useAccordionButton(eventKey);

  const { activeEventKey } = useContext(AccordionContext);

  return Header ? (
    <Header activeEventKey={activeEventKey} onClick={decoratedOnClick} />
  ) : (
    <div
      className={cn("d-flex justify-content-between collapsible-header-part", classes["collapsible-head"])}
      onClick={
        !collapsible
          ? undefined
          : (e) => {
              decoratedOnClick(e);

              if (callBackOnCollapseClick) {
                callBackOnCollapseClick();
              }
            }
      }
    >
      <Ui.l bold className="collapsible-title">
        {headTitle}
      </Ui.l>
      {withoutArrow || !collapsible ? null : (
        <div>
          <ChevronDownIcon
            fontSize={24}
            color={scssVariables.foregroundMedium}
            direction={activeEventKey ? "top" : "bottom"}
          />
        </div>
      )}
    </div>
  );
};

export default CollapsibleContainer;
