import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { isNil } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, LoaderContainer, P, Tag, Ui } from "common/components/atoms";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { CloseIcon, InformationCircleIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import EditValuation from "./components/EditValuation/EditValuation";
import RemoveValuationModal from "./components/RemoveValiationModal/RemoveValuationModal";
import TaxableValueModal from "./components/TaxableValueModal/TaxableValueModal";
import ValuationHistory from "./components/ValuationHistory/ValuationHistory";
import ValuationOverview from "./components/ValuationOverview/ValuationOverview";
import classes from "./valuation.module.scss";
import ValuationContext from "./ValuationContext";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.valuation"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const Content: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId } = useParams<{ companyId: string }>();
  const [showTaxableValueModal, setShowTaxableValueModal] = useState(false);
  const { valuation } = useStoreState((actions) => actions.company);
  const { getValuationThunk } = useStoreActions((actions) => actions.company);

  const [isNoSharesSectionVisible, setIsNoSharesSectionVisible] = useState<boolean>(false);

  const handleManageTaxableValue = useCallback(() => {
    setShowTaxableValueModal((prev) => !prev);
  }, []);

  const handleValuationOpen = () => {
    setIsValuationEdit(true);
  };

  const { hasFullAccess } = useFeatures(FEATURES.valuation);
  const { isLoading, selectedRowId } = ValuationContext.useStoreState((state) => state);
  const { setIsValuationEdit, setSelectedRowId } = ValuationContext.useStoreActions((actions) => actions);

  useEffect(() => {
    if (!isNil(valuation) && valuation.issuedShares === 0) {
      setIsNoSharesSectionVisible(true);
    }
  }, [valuation]);

  useEffect(() => {
    if (selectedRowId) {
      setTimeout(() => {
        setSelectedRowId(undefined);
      }, 10000);
    }
  }, [selectedRowId, setSelectedRowId]);

  useEffect(() => {
    if (companyId) {
      getValuationThunk(Number(companyId));
    }
  }, [companyId, getValuationThunk]);

  return (
    <PageContent data-testid="company-valuation-page">
      <PageContent.Header>
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>

        <div>
          <Button
            isFocusDisabled
            variant="tertiary"
            onClick={handleManageTaxableValue}
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          >
            Update taxable value
          </Button>
          <Button
            isFocusDisabled
            className="ms-3"
            data-testid="valuation-open-edit-test-id"
            onClick={handleValuationOpen}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            isDisabled={!hasFullAccess}
          >
            {t("updateVluation")}
          </Button>
        </div>
      </PageContent.Header>

      {isNoSharesSectionVisible ? (
        <div className={classNames("p-3 mb-3 d-flex", classes["no-shares"])}>
          <InformationCircleIcon width={24} height={24} />

          <div className="ms-2">
            <Ui.m bold className="mb-1">
              Add more information to see values
            </Ui.m>

            <P.s>
              Some values on this page are calculated based on shares, shareholders, and transactions. To be able to
              view them, they need to be added during the company registration process, or on the transaction page.
            </P.s>
          </div>

          <CloseIcon
            className={classes["close-icon"]}
            onClick={() => {
              setIsNoSharesSectionVisible(false);
            }}
          />
        </div>
      ) : null}

      <LoaderContainer loading={isLoading}>
        <ValuationOverview />

        <ValuationHistory />

        <EditValuation />
        <RemoveValuationModal />
        <TaxableValueModal companyId={companyId} show={showTaxableValueModal} handleClose={handleManageTaxableValue} />
      </LoaderContainer>
    </PageContent>
  );
};

const CompanyValuationPage: FC = () => {
  return (
    <ValuationContext.Provider>
      <Content />
    </ValuationContext.Provider>
  );
};

export default CompanyValuationPage;
