import { FC } from "react";
import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import { createTranslation, TranslationNS } from "translation";

import { PoolFormValues } from "../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";

export type NameFieldProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.nameField");

const PoolNameField: FC<NameFieldProps> = ({ className }) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Pick<PoolFormValues, "name">>();

  return (
    <TextField
      className={className}
      label={t("label")}
      isTouched={touched.name}
      error={errors.name}
      value={values.name}
      name="name"
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default PoolNameField;
