import { useEffect, useState } from "react";

import { useTableBrowserStorage } from "common/components/atoms/ImportTable/TableBrowserStorage";
import { BreggCompanyShareholders } from "store/modelTypes";
import { useStoreState } from "store/store";

const useImportStakeholders = () => {
  const [foundersData, setFoundersData] = useState<BreggCompanyShareholders[]>([]);

  const founders = useStoreState((state) => state.companyOnboardingModel.founders);
  // const { bregg, uniMicro } = useStoreState((state) => state.companyOnboardingModel.integrations);

  const { getImportTableData } = useTableBrowserStorage<BreggCompanyShareholders[]>();

  useEffect(() => {
    const browserData = getImportTableData()?.data;

    const holders =
      (browserData && browserData.length && browserData) || // if browserData is not empty
      (founders.length && founders) || // if shareholders is not empty
      // TODO: update this if there will be founders from integration
      // bregg?.founders || // if bregg?.shareholders is not empty
      // uniMicro?.founders || // if uniMicro?.shareholders is not empty
      [];

    if (holders.length) {
      setFoundersData(holders);
    }
  }, [founders, getImportTableData]);

  return { foundersData, setFoundersData };
};

export default useImportStakeholders;
