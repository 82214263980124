import { IPublicClientApplication } from "@azure/msal-browser";
import axios from "axios";
import {
  curveBasis,
  curveCardinal,
  curveCatmullRom,
  curveLinear,
  curveMonotoneX,
  curveMonotoneY,
  curveNatural,
  curveStep,
  curveStepAfter,
  curveStepBefore,
} from "d3-shape";
import { format, isValid } from "date-fns";
import * as R from "ramda";

import { ReadAndAcceptDocumentType } from "common/components/molecules/read-and-accept-documents/ReadAndAcceptDocuments";
import { InstrumentTypesNamesEnum, IssueEquityConvertibleStatuses } from "common/enums/enum";
import { stepPoint } from "common/types/Charts.types";
import { OwnershipProgramTableData, programTablePlanStatuses, sortingParams } from "common/types/Collapsible.types";
import { CommonObjectType } from "store/modelTypes";

import { HEADER_ID, scssVariables } from "./constants";
import { notify } from "./notify/notifyFunction";

export function initBeforeUnLoad(showExitPrompt: boolean) {
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
}

export function transformDropDownsData(data?: CommonObjectType[] | null, idField?: string) {
  return !R.isNil(data)
    ? data.map((el) => ({
        id: el?.id ? el?.id : el[idField as "id"],
        value: el.name,
        label: el.name,
      }))
    : undefined;
}

export function handleLogout(instance: IPublicClientApplication) {
  instance.logoutRedirect().catch((e) => {
    console.error(e);
  });
}

export function getPlanNameBasedOnAPIId(id = 1) {
  switch (id) {
    case 1: {
      return InstrumentTypesNamesEnum.RSA;
    }
    case 2: {
      return InstrumentTypesNamesEnum.RSU;
    }
    case 3: {
      return InstrumentTypesNamesEnum.OPTION;
    }
  }
}

export function setProgramTableStatusColor(status: keyof typeof programTablePlanStatuses) {
  switch (status) {
    case programTablePlanStatuses.Granted: {
      return "#4BB97E";
    }
    case programTablePlanStatuses.Draft: {
      return "#89898D";
    }
    case programTablePlanStatuses.Active: {
      return "#B17225";
    }
    case programTablePlanStatuses.Approved: {
      return "#153EA6";
    }

    default: {
      return "#153EA6";
    }
  }
}

export function setConveribleTagColorBasedOnId(
  id: (typeof IssueEquityConvertibleStatuses)[keyof typeof IssueEquityConvertibleStatuses]
) {
  switch (id) {
    case 1: {
      return {
        bgColor: scssVariables.warning050,
        textColor: scssVariables.warning900,
      };
    }
    case 2: {
      return {
        bgColor: scssVariables.positive050,
        textColor: scssVariables.positive900,
      };
    }
    case 3: {
      return {
        bgColor: scssVariables.information050,
        textColor: scssVariables.information900,
      };
    }
    case 4: {
      return {
        bgColor: scssVariables.critical050,
        textColor: scssVariables.critical900,
      };
    }
    case 5: {
      return {
        bgColor: scssVariables.additional4500,
        textColor: scssVariables.foregroundHigh,
      };
    }
    case 6: {
      return {
        bgColor: scssVariables.critical050,
        textColor: scssVariables.critical900,
      };
    }
    case 7: {
      return {
        bgColor: scssVariables.positive050,
        textColor: scssVariables.positive900,
      };
    }
    case 8: {
      return {
        bgColor: scssVariables.element3,
        textColor: scssVariables.foregroundHigh,
      };
    }
    case 9: {
      return {
        bgColor: scssVariables.additional4500,
        textColor: scssVariables.foregroundHigh,
      };
    }
  }
}

export function sortProgramTableData({
  data,
  sortedField,
  sortType,
}: {
  data: OwnershipProgramTableData[];
  sortedField: keyof OwnershipProgramTableData;
  sortType: keyof typeof sortingParams;
}) {
  return data.sort((a: OwnershipProgramTableData, b: OwnershipProgramTableData) => {
    let innerA = a;
    let innerB = b;

    if (sortedField === "startDate" || sortedField === "endTime") {
      innerA = R.assoc(sortedField, new Date(a[sortedField]).valueOf(), a);

      innerB = R.assoc(sortedField, new Date(b[sortedField]).valueOf(), b);
    }

    const comparatorA = R.isNil(innerA[sortedField])
      ? 0
      : typeof innerA[sortedField] === "string"
      ? (innerA[sortedField] as string).toLowerCase()
      : (innerA[sortedField] as string | number);
    const comparatorB = R.isNil(innerB[sortedField])
      ? 0
      : typeof innerB[sortedField] === "string"
      ? (innerB[sortedField] as string).toLowerCase()
      : (innerB[sortedField] as string | number);

    if (comparatorA < comparatorB) {
      return sortType === sortingParams.inc ? -1 : 1;
    } else {
      return sortType === sortingParams.inc ? 1 : -1;
    }
  });
}

export function sortCaptableData<T>({
  data = [],
  sortedField,
  sortType,
}: {
  data: T[];
  sortedField: keyof T;
  sortType: keyof typeof sortingParams;
}) {
  return (data || []).sort((a, b) => {
    const comparatorA = R.isNil(a[sortedField])
      ? 0
      : typeof a[sortedField] === "string"
      ? (a[sortedField] as string).toLowerCase()
      : a[sortedField];
    const comparatorB = R.isNil(b[sortedField])
      ? 0
      : typeof a[sortedField] === "string"
      ? (b[sortedField] as string).toLowerCase()
      : b[sortedField];

    if (comparatorA < comparatorB) {
      return sortType === sortingParams.inc ? -1 : 1;
    } else {
      return sortType === sortingParams.inc ? 1 : -1;
    }
  });
}

export function setDefaultDataForOwnership({
  type,
  isEmpty,
  value,
}: {
  type: 1 | 2 | 3 | 4;
  isEmpty: boolean;
  value: number;
}) {
  switch (type) {
    case 1: {
      return {
        color: isEmpty ? "#D0D0D1" : scssVariables.information050,
        value: value,
      };
    }
    case 2: {
      return {
        color: isEmpty ? "#89898D" : scssVariables.critical050,
        value: value,
      };
    }
    case 3: {
      return {
        color: isEmpty ? "#ECECED" : scssVariables.primary1,
        value: value,
      };
    }
    case 4: {
      return {
        color: isEmpty ? "#626267" : "#FF668F",
        value: value,
      };
    }

    default: {
      return {
        color: isEmpty ? "#D0D0D1" : scssVariables.warning050,
        value: isEmpty ? 0 : value,
      };
    }
  }
}

export function setLinearChartType(
  curve:
    | "basis"
    | "cardinal"
    | "catmullRom"
    | "linear"
    | "monotoneX"
    | "monotoneY"
    | "natural"
    | "step"
    | "stepAfter"
    | "stepBefore"
) {
  switch (curve) {
    case "basis": {
      return curveBasis;
    }
    case "cardinal": {
      return curveCardinal;
    }
    case "catmullRom": {
      return curveCatmullRom;
    }
    case "linear": {
      return curveLinear;
    }
    case "monotoneX": {
      return curveMonotoneX;
    }
    case "monotoneY": {
      return curveMonotoneY;
    }
    case "natural": {
      return curveNatural;
    }
    case "step": {
      return curveStep;
    }
    case "stepAfter": {
      return curveStepAfter;
    }
    case "stepBefore": {
      return curveStepBefore;
    }
  }
}

export function addHttp(url: string) {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = "http://" + url;
  }

  return url;
}

export const digitsOnly = (value = "") => /^\d+$/.test(value);

export const scrollToHeaderTop = (id: string = HEADER_ID) => {
  document.getElementById(id)?.scrollIntoView({ block: "start", behavior: "smooth" });
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export function axisXChartFormatter(value: string, data: stepPoint[], formatDateValue?: string) {
  const currentIndex = data.findIndex((el) => el.x === value);

  const prevYear = data[currentIndex - 1] ? new Date(data[currentIndex - 1].x).getFullYear() : undefined;

  const currentYear = new Date(value as string).getFullYear();

  const yearFormat = !prevYear ? "QQQ yyy" : prevYear !== currentYear ? "QQQ yyy" : "QQQ";

  return format(new Date(value as string), formatDateValue ? formatDateValue : yearFormat);
}

export function prepareAcceptDocuments(documents: ReadAndAcceptDocumentType[], agreementId: number) {
  const result: {
    agreement?: ReadAndAcceptDocumentType;
    rest: Array<ReadAndAcceptDocumentType>;
  } = {
    agreement: undefined,
    rest: [],
  };

  const agreementDocument = documents.find((el) => el.documentTypeId === agreementId);
  const difference = R.difference(documents, [R.defaultTo([], agreementDocument)]);

  result.agreement = agreementDocument;
  result.rest = difference as ReadAndAcceptDocumentType[];

  return result;
}

export async function openRemoteDocument(id?: string) {
  return await axios
    .get(`/api/download/${id}`, {
      responseType: "blob",
    })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open() as Window;
      pdfWindow.location.href = fileURL;
    });
}

export async function downloadRemoteDocument(id: string, name: string) {
  return await axios
    .get(`/api/download/${id}`, {
      responseType: "blob",
    })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = name;
      alink.click();
    });
}

export async function removeRemoteDocument(id: number) {
  return await axios.delete(`/api/document/${id}`);
}

export function loadRemoteImage(url?: string) {
  if (!url) {
    return;
  }

  return `${process.env.REACT_APP_BLOB_PUBLIC_URL}${url}`;
}

export function transformProgramInstrumentTypeName(type: InstrumentTypesNamesEnum) {
  if (type === "Restricted Stock Award") {
    return "RSA";
  }

  if (type === "Restricted Stock Unit") {
    return "RSU";
  }

  return type;
}

export function transformUserNameToInitials(name?: string) {
  return name
    ?.split(" ")
    ?.map((el) => el[0])
    ?.join(" ");
}

export function getLeftPaddingValueForChartLabel(data: number[]) {
  const maxValue = Math.max(...data);

  const maxValueLength = R.length(String(maxValue));

  if (maxValueLength <= 4) {
    return 40;
  }

  if (maxValueLength <= 6) {
    return 50;
  }

  return 80;
}

export function transformDateForLabel(label: string) {
  const splittedText = R.defaultTo("", label).split(" ");
  const stringBeforeDate = R.dropLast(1, splittedText);
  const date = R.last(splittedText) as string;

  return `${stringBeforeDate.join(" ")}  ${isValid(new Date(date)) ? format(new Date(date), "dd.MM.yyyy") : label}`;
}

export function transformDateToCommonDateFormat(date?: string | Date, formattedString?: string) {
  return format(date ? new Date(date) : new Date(), formattedString ? formattedString : "dd.MM.yyyy");
}

export const moxios = {
  defaultConfig: {
    response: undefined,
    throwError: false,
    timeout: 1000,
  },

  get<ResponseData>(
    url: string,
    config: {
      response?: ResponseData;
      throwError?: boolean;
      timeout?: number;
    }
  ): Promise<{ status: number; data?: ResponseData }> {
    const mergedConfig = { ...this.defaultConfig, ...config };

    return new Promise((resolve, reject) =>
      setTimeout(() => {
        if (mergedConfig.throwError) {
          reject(new Error());
          notify("MOCK Error", true, "error");
        } else {
          resolve({ status: 200, data: mergedConfig.response });
        }
      }, mergedConfig.timeout)
    );
  },

  post<ResponseData>(
    url: string,
    body: any,
    config: {
      response?: ResponseData;
      throwError?: boolean;
      timeout?: number;
    }
  ): Promise<{ status: number; data?: ResponseData }> {
    const mergedConfig = { ...this.defaultConfig, ...config };

    return new Promise((resolve, reject) =>
      setTimeout(() => {
        if (mergedConfig.throwError) {
          reject(new Error());
        } else {
          resolve({ status: 200, data: mergedConfig.response });
        }
      }, 1000)
    );
  },
};
