import { FC, memo, useMemo } from "react";

import ProgressChart from "common/components/atoms/Charts/Progress.chart";
import { Ui } from "common/components/atoms/Typography";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../basics.module.scss";

const t = createTranslation(TranslationNS.pages, "createPlan.basics");

type ProgressBarProps = {};
const ProgressBar: FC<ProgressBarProps> = () => {
  const fNumber = useFormatNumbers();
  const program = useStoreState((state) => state.programModel.program);

  const { usedShares, sharesInDraft, sharesLeft, totalShares } = useMemo(() => {
    return {
      usedShares: program?.totalSharesInPlans || 0,
      sharesInDraft: program?.totalSharesInDraftPlans || 0,
      sharesLeft:
        (program?.numberOfShares || 0) - (program?.totalSharesInPlans || 0) - (program?.totalSharesInDraftPlans || 0),
      totalShares: program?.numberOfShares || 0,
    };
  }, [program?.numberOfShares, program?.totalSharesInDraftPlans, program?.totalSharesInPlans]);

  const progressData = useMemo(() => {
    return [
      {
        id: "used",
        color: scssVariables.positive500,
        withThumb: true,
        value: usedShares || 0,
        labelColor: "transparent",
        thumbColor: scssVariables.positive700,
        showTooltip: true,
      },
      {
        id: "draft",
        color: scssVariables.warning050,
        withThumb: false,
        value: sharesInDraft || 0,
        labelColor: "transparent",
        thumbColor: scssVariables.warning700,
        showTooltip: true,
      },
    ];
  }, [sharesInDraft, usedShares]);

  return (
    <div className={`${classes["progress-chart"]} mt-7`}>
      <Ui.l bold className="mb-3">
        {t("chart.availableShares", {
          value: fNumber(program?.numberOfShares, "amount"),
        })}
      </Ui.l>
      <ProgressChart withoutLabel total={totalShares} data={progressData} />

      <div>
        <div className="d-flex pt-1">
          <div className="d-flex align-items-center">
            <div className={classes["used-shares"]} />
            <Ui.s className={classes["shares-element"]}>
              {t("chart.sharesUsed", { value: fNumber(usedShares, "amount") })}
            </Ui.s>
          </div>
          <div className="d-flex align-items-center">
            <div className={classes["draft-shares"]} />
            <Ui.s className={classes["shares-element"]}>
              {t("chart.sharesInDraft", { value: fNumber(sharesInDraft, "amount") })}
            </Ui.s>
          </div>
          <div className="d-flex align-items-center">
            <div className={classes["unused-shares"]} />
            <Ui.s className={classes["shares-element"]}>
              {t("chart.sharesLeft", { value: fNumber(sharesLeft, "amount") })}
            </Ui.s>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ProgressBar);
