import { FC } from "react";
import cn from "classnames";

import { CommonFileIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { downloadRequest } from "common/utils/download";
import { transformDateToCommonDateFormat } from "common/utils/functions";

import { Ui } from "../Typography";
import classes from "./FilesList.module.scss";

export type ViewOnlyFile = {
  downloadId: string;
  filename: string;
  uploadedAt: string;
};

type PropsTypes = {
  files: ViewOnlyFile[];
  className?: string;
};

const FilesList: FC<PropsTypes> = ({ files, className }) => {
  return (
    <ul className={cn("list-unstyled mb-0", className)}>
      {files.length ? (
        files.map((file) => (
          <li
            key={file.downloadId}
            className={cn(classes.fileContainer, "d-flex justify-content-between align-items-center p-3 mt-2")}
            onClick={() => downloadRequest(file.downloadId, file.filename)}
          >
            <div className="d-flex align-items-center">
              <CommonFileIcon fontSize={24} color={scssVariables.foregroundLow} />
              <Ui.s className="ms-3">{file.filename}</Ui.s>
            </div>
            <Ui.s color="foregroundLow">{transformDateToCommonDateFormat(file.uploadedAt)}</Ui.s>
          </li>
        ))
      ) : (
        <li className="mt-2 pt-3 px-3">
          <Ui.s color="foregroundLow">No documents available</Ui.s>
        </li>
      )}
    </ul>
  );
};

export default FilesList;
