import { FC } from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

import Button from "common/components/atoms/Button/Button";
import { H, P } from "common/components/atoms/Typography";
import { createTranslation,TranslationNS } from "translation";

import classes from "../DocumentToggleElement.module.scss";

const t = createTranslation(TranslationNS.common, "molecules.documentList");

type RemoveDocumentModalProps = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onDelete: () => void;
};

const RemoveDocumentModal: FC<RemoveDocumentModalProps> = ({ isOpen, setIsOpen, onDelete }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <Modal
      size="lg"
      centered
      show={isOpen}
      className={classes["delete-modal"]}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={handleClose}
    >
      <div className={classes["delete-content"]}>
        <H.xs className="text-center">{t("deleteModalTitle")}</H.xs>

        <P.m className="mt-3 mb-5 text-center">{t("deleteModalDescription")}</P.m>

        <div className="text-center">
          <Button className={classes["delete-btn"]} onClick={handleDelete}>
            {t("delete")}
          </Button>

          <Button variant="secondary" className="ms-2" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveDocumentModal;
