import { Action, action } from "easy-peasy";

export interface OnboardingModel {
  investorTypeId: number | null;
  oneliner: string | null;
  setInvestorTypeId: Action<this, number>;
  setOneliner: Action<this, string>;
}

export const onboarding: OnboardingModel = {
  investorTypeId: null,
  oneliner: null,

  setInvestorTypeId: action((state, payload) => {
    state.investorTypeId = payload;
  }),
  setOneliner: action((state, payload) => {
    state.oneliner = payload;
  }),
};
