import { useLayoutEffect } from "react";

import { INITIAL_DOCUMENT_TITLE } from "../utils/constants";

// This hook will update your title for HTML with passing string you need

const useDocumentTitleUpdate = (val: string) => {
  useLayoutEffect(() => {
    document.title = `${val} - ${INITIAL_DOCUMENT_TITLE}`;

    return () => {
      document.title = INITIAL_DOCUMENT_TITLE;
    };
  }, [val]);

  return null;
};

export default useDocumentTitleUpdate;
