import { FC } from "react";
import cn from "classnames";

import { NewAvatar, Tooltip, Ui } from "common/components/atoms/index";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./AvatarsList.module.scss";

export type Stakeholder = {
  avatarFilePath: string;
  id: number;
  initials: string;
  name: string;
  issuedShares: number;
  status: number;
  totalShares: number;
  isCompanyOwned: boolean;
};

type PropsTypes = {
  stakeholders: Stakeholder[];
  className?: string;
};
const t = createTranslation(TranslationNS.common, "atoms.avatarsList");

const AvatarsList: FC<PropsTypes> = ({ stakeholders, className }) => {
  const fNumber = useFormatNumbers();
  const firstFiveStakeholders = stakeholders.slice(0, 5);
  const isMoreThenFiveStakeholders = stakeholders.length > 5;

  return (
    <Tooltip
      popupContent={
        <ul className="d-flex flex-column align-items-start list-unstyled">
          <Ui.xs bold>{t("tooltipTitle")}</Ui.xs>
          {firstFiveStakeholders.map((stakeholder) => (
            <li key={stakeholder.id} className="text-start">
              {`${stakeholder.name} - ${fNumber(stakeholder.totalShares, "amount")} ${t("shares")}`}
            </li>
          ))}
          {isMoreThenFiveStakeholders && <Ui.xs className="mt-2">{`+${stakeholders.length - 5} ${t("more")}`}</Ui.xs>}
        </ul>
      }
    >
      <div className={cn("d-flex align-items-center", className)}>
        {firstFiveStakeholders.map((stakeholder, index) => (
          <NewAvatar
            key={stakeholder.id}
            imageUrl={stakeholder.avatarFilePath}
            company={stakeholder.isCompanyOwned}
            initials={stakeholder.initials}
            className={classes.avatar}
            style={{ left: `-${index * 6}px` }}
            size="s"
          />
        ))}

        {isMoreThenFiveStakeholders && (
          <Ui.xs
            className="position-relative ms-half"
            style={{
              left: "-24px",
            }}
            color={scssVariables.foregroundMedium}
          >{`+${stakeholders.length - 5}`}</Ui.xs>
        )}
      </div>
    </Tooltip>
  );
};

export default AvatarsList;
