import BTable from "react-bootstrap/Table";
import { flexRender, Table } from "@tanstack/react-table";
import classNames from "classnames";

import { FilledArrowDownIcon } from "common/icons/svg";

import classes from "../../my-porfolio/portfolio/OverviewTable/OverviewTable.module.scss";

type AdminTableProps<T extends object> = {
  table: Table<T>;
  withHover?: boolean;
  handleClickRow?: (row: T) => void;
};

const AdminTable = <T extends object>({ table, withHover, handleClickRow }: AdminTableProps<T>) => {
  return (
    <BTable className={classNames(classes.table, { [classes.withHover]: withHover })}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const canResize = header.column.getCanResize();
              const maxWidth = canResize ? header.column.columnDef.maxSize : undefined;
              const minWidth = canResize ? header.column.columnDef.minSize : undefined;
              const width = canResize ? header.column.getSize() : undefined;
              return (
                <th key={header.id} colSpan={header.colSpan} style={{ maxWidth, minWidth, width }}>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: classNames("d-flex align-items-center", header.column.columnDef.meta?.headClass, {
                          "cursor-pointer select-none": header.column.getCanSort(),
                        }),
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {header.column.getCanSort() && (
                        <span className={classes.sorting}>
                          <FilledArrowDownIcon
                            style={{
                              color: header.column.getIsSorted() ? "black" : "#806779",
                              transition: "all 0.2s",
                              transform:
                                (header.column.getIsSorted() as string) === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                            }}
                          />
                        </span>
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              onClick={() => {
                handleClickRow?.(row.original);
              }}
            >
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    onClick={(e) => {
                      if (cell.column.id === "companyId") {
                        e.preventDefault();
                      }
                    }}
                    key={cell.id}
                  >
                    <div className={classNames("d-flex align-items-center", cell.column.columnDef.meta?.bodyClass)}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
};

export default AdminTable;
