import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import Step from "common/components/atoms/Step/Step";
import StepInfo from "common/components/atoms/Step/StepInfo";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { TaxIcon } from "common/icons/svg";
import { Variant } from "store/GettingStartedGuideModel";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import InfoCart from "../InfoCard/InfoCard";

const t = createTranslation(TranslationNS.pages, "company.gettingStartedGuide.stepOne");

const StepOne: FC<{ status: Variant }> = ({ status }) => {
  const fNumber = useFormatNumbers();
  const navigate = useNavigate();
  const valuationAndShares = useStoreState((state) => state.gettingStartedGuide.valuationAndShares);

  const shareClassNames = useMemo(() => {
    return valuationAndShares.shareClassNames.join(", ");
  }, [valuationAndShares.shareClassNames]);

  const handleEditValuation = useCallback(() => {
    navigate(getEMPath(["ownership", "valuation"]));
  }, [navigate]);

  const handleEditShareClasses = useCallback(() => {
    navigate(getEMPath(["ownership", "shareClasses"]));
  }, [navigate]);

  const isInProgress = status === "progress" || status === "undone";

  return (
    <Step.Item status={status}>
      <Step.Point icon={status !== "done" ? <TaxIcon width="24px" height="24px" /> : undefined} />
      <StepInfo>
        {status === "undone" && <Step.Important className="mb-1" />}
        <div>
          <b>{t("title")}</b>
          <p className="mt-1 mb-3">{t("description")}</p>
          <div className="d-flex gap-2">
            <InfoCart
              handleClick={handleEditValuation}
              title={fNumber(valuationAndShares.currentValuation, "value", {
                customCurrencySymbol: valuationAndShares.currency,
              })}
              description={t("yourCompanyValuation")}
            />
            {shareClassNames && (
              <InfoCart
                handleClick={handleEditShareClasses}
                title={shareClassNames}
                description={t("shareClasses", { value: valuationAndShares.shareClassNames.length })}
              />
            )}
          </div>
          {isInProgress && (
            <div className="mt-3">
              <Button onClick={handleEditValuation} size="s">
                {t("enterValuation")}
              </Button>
            </div>
          )}
        </div>
      </StepInfo>
    </Step.Item>
  );
};

export default StepOne;
