import { useCallback } from "react";

import { Tooltip, Ui } from "common/components/atoms";
import { InformationCircleIcon } from "common/icons/svg";
import { useStoreState } from "store/store";

import { transformDateToCommonDateFormat } from "../../../../utils/functions";
import { StakeholderFormValues, updatedStakeholderFields } from "../Stakeholder.types";

type StakeholderTooltipProps = {
  stakeholder: Partial<StakeholderFormValues>;
};
const StakeholderTooltip = ({ stakeholder }: StakeholderTooltipProps) => {
  const countries = useStoreState((state) => state.common.dropdowns?.countries);
  const relationshipTypes = useStoreState((state) => state.common.dropdowns?.relationshipTypes);
  const TooltipContent = useCallback(() => {
    return !stakeholder.isCompanyOwned ? (
      <div className="text-start">
        <Ui.xs bold>
          {stakeholder.firstName} {stakeholder.lastName}
        </Ui.xs>
        <Ui.xs>
          {stakeholder.relationshipTypeId && relationshipTypes
            ? relationshipTypes.find((item) => item.id === stakeholder.relationshipTypeId)?.name
            : ""}
        </Ui.xs>
        <Ui.xs>{stakeholder.email}</Ui.xs>
        <Ui.xs>{stakeholder.phoneNumber}</Ui.xs>
        <Ui.xs>
          {stakeholder.countryId && countries ? countries.find((item) => item.id === stakeholder.countryId)?.name : ""}
        </Ui.xs>
        <Ui.xs>{stakeholder.address}</Ui.xs>
        <Ui.xs>{stakeholder?.dateOfBirth ? transformDateToCommonDateFormat(stakeholder?.dateOfBirth) : ""}</Ui.xs>
      </div>
    ) : (
      <div className="text-start">
        <Ui.xs bold>{stakeholder.companyName}</Ui.xs>
        <Ui.xs>
          {stakeholder.relationshipTypeId && relationshipTypes
            ? relationshipTypes.find((item) => item.id === stakeholder.relationshipTypeId)?.name
            : ""}
        </Ui.xs>
        <Ui.xs>{stakeholder.organizationNumber}</Ui.xs>
        <div className="mb-1" />
        <Ui.xs>
          {stakeholder.firstName} {stakeholder.lastName}
        </Ui.xs>
        <Ui.xs>{stakeholder.email}</Ui.xs>
        <Ui.xs>{stakeholder.phoneNumber}</Ui.xs>
        <Ui.xs>
          {stakeholder.countryId && countries ? countries.find((item) => item.id === stakeholder.countryId)?.name : ""}
        </Ui.xs>
        <Ui.xs>{stakeholder.address}</Ui.xs>
        <Ui.xs>{stakeholder?.dateOfBirth ? transformDateToCommonDateFormat(stakeholder?.dateOfBirth) : ""}</Ui.xs>
      </div>
    );
  }, [stakeholder, countries, relationshipTypes]);

  return (
    <Tooltip popupContent={<TooltipContent />}>
      <InformationCircleIcon className="ms-1" style={{ cursor: "pointer" }} />
    </Tooltip>
  );
};

export default StakeholderTooltip;
