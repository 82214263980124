import React from "react";
import classNames from "classnames";

import classes from "./empty.module.scss";

const tableAmount = [15, 13, 8, 8, 11, 13, 12];

const EmptyTable = () => {
  return (
    <>
      <div className="d-flex w-100 justify-content-between mt-7 mb-5">
        <div className={classes["action-first"]} />
        <div className={classes["action-last"]} />
      </div>

      <div>
        <div className={classNames("px-3", classes["head"])}>
          {tableAmount.map((el, i) => (
            <div key={i} className={classNames("me-6", classes["head-row"])} style={{ width: `${el}%` }} />
          ))}
        </div>

        <div className="px-3">
          {Array.from({ length: 5 }, (_, i) => {
            return (
              <div className={classes["body-row"]} key={i}>
                {tableAmount.map((el, i) => (
                  <div
                    key={i}
                    className={classNames("me-6", classes["inner"], {
                      [classes["no-bg"]]: i === 0,
                    })}
                    style={{ width: `${el}%` }}
                  >
                    {i === 0 ? (
                      <div className="d-flex align-items-center">
                        <div className={classes["sub-circle"]} />
                        <div className={classNames("ms-1 w-100", classes["inner"])} />
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            );
          })}
        </div>

        <div className={classNames("px-3", classes["footer"])}>
          {tableAmount.map((el, i) => (
            <div
              key={i}
              className={classNames("me-6", classes["inner"], {
                [classes["no-bg"]]: i === 0 || i === 1 || i === 5 || i === 6,
              })}
              style={{ width: `${el}%` }}
            >
              {i === 0 ? <div className={classes["sub-rect"]} /> : null}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmptyTable;
