import { FC, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import { format } from "date-fns";
import * as R from "ramda";

import { Button, Collapsible, Ui } from "common/components/atoms";
import { ChevronDownIcon } from "common/icons/svg";
import { EventHistory as EventHistoryType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "./EventHistory.module.scss";

const t = createTranslation(TranslationNS.common, "components.eventHistory");

type EventHistoryProps = {
  collapsible?: boolean;
  className?: string;
  data?: EventHistoryType[];
};

const EVENTS_COUNT = 3;
const INITIAL_COUNT = 1;

const EventHistory: FC<EventHistoryProps> = ({ data, className, collapsible = false, ...props }) => {
  const [currentStep, setCurrentStep] = useState(INITIAL_COUNT);

  const splittedData = useMemo(() => {
    if (R.isNil(data) || R.isEmpty(data)) {
      return [];
    }

    return R.splitEvery(EVENTS_COUNT, data);
  }, [data]);

  const initialStepHandler = useCallback(() => {
    setCurrentStep(INITIAL_COUNT);
  }, []);

  const currentStepHandler = useCallback(() => {
    setCurrentStep((prev) => prev + 1);
  }, []);

  if (R.isNil(data) || R.isEmpty(data)) {
    return null;
  }

  return (
    <Collapsible
      defaultOpen
      withoutDivider
      headTitle={t("title")}
      collapsible={collapsible}
      className={cn(classes["header"], className)}
      callBackOnCollapseClick={initialStepHandler}
      {...props}
    >
      <div className={cn(classes["container"])}>
        {R.flatten(R.take(currentStep, splittedData))?.map((el, index) => {
          return (
            <div key={`Key for event single item is - ${el.title} - ${index}`} className={classes["history-single"]}>
              <Ui.xs>{format(new Date(el.occuredAt), "dd-MM-yyyy")}</Ui.xs>
              <div>
                <Ui.xs bold>{el.title}</Ui.xs>
                <Ui.xs>{el.message}</Ui.xs>
              </div>
            </div>
          );
        })}

        {currentStep >= splittedData?.length ? null : (
          <Button
            variant="tertiary"
            className={classes["view-more-btn"]}
            iconRight={<ChevronDownIcon />}
            onClick={currentStepHandler}
          >
            {t("viewMore")}
          </Button>
        )}
      </div>
    </Collapsible>
  );
};

export default EventHistory;
