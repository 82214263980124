import React from "react";
import { toast, ToastPosition } from "react-toastify";

import "./toasts.scss";

const autoCloseTime = 5000;
const autoCloseErrorTime = 5000;

enum notifyStatuses {
  success = "success",
  error = "error",
  warning = "warning",
  info = "info",
}

export function notify(
  message: string,
  autoClose: boolean,
  type?: keyof typeof notifyStatuses,
  timeout?: number,
  closeButton = false,
  toastPosition: ToastPosition = "top-center",
  className?: string,
  messageComponent?: React.ReactNode
) {
  switch (type) {
    case "success":
      toast(messageComponent ? messageComponent : message, {
        className: `success-toast toast-text ${className ? className : ""}`,
        position: toastPosition,
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
    case "error":
      toast(message, {
        className: `error-toast toast-text ${className ? className : ""}`,
        position: toastPosition,
        autoClose: autoClose ? (timeout ? timeout : autoCloseErrorTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
        toastId: message, // prevent duplicated notification
      });
      break;
    case "warning":
      toast(message, {
        className: `warning-toast toast-text ${className ? className : ""}`,
        position: toastPosition,
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
        toastId: message, // prevent duplicated notification
      });
      break;
    case "info":
      toast(message, {
        className: `info-toast toast-text ${className ? className : ""}`,
        position: toastPosition,
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
    default:
      toast(message, {
        className: `regular-toast toast-text ${className ? className : ""}`,
        position: toastPosition,
        autoClose: autoClose ? (timeout ? timeout : autoCloseTime) : false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: closeButton,
      });
      break;
  }
}
