import { motion } from "framer-motion";
import cn from "classnames";
import { useCallback, FC } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Button from "common/components/atoms/Button/Button";
import { scssVariables } from "common/utils/constants";
import { TranslationNS, createTranslation } from "translation";
import { getPath } from "app/Router/RouterHelper";

import classes from "./SingleCompanyDetail.module.scss";
import { ImageIcon } from "../../../../common/icons/svg";

const t = createTranslation(TranslationNS.pages, "public.companyList.singleCard");

type SingleCompanyDetailProps = {
  id: number;
  url?: string;
  city?: string;
  title: string;
  country: string;
  tags?: { id: number; name: string }[];
  oneLiner?: string;
  foundedAt?: string;
  companyPhase: string;
};

const SingleCompanyDetail: FC<SingleCompanyDetailProps> = ({
  id,
  url,
  city,
  tags,
  title,
  country,
  oneLiner,
  foundedAt,
  companyPhase,
}) => {
  const navigate = useNavigate();

  const cardClickHandler = () => {
    navigate(getPath(["companyDetails"], { id }));
  };

  const renderTags = useCallback(() => {
    return (
      <div className="d-flex flex-wrap justify-content-center">
        {tags?.map((el, index) => {
          return (
            <div
              key={`Single key for company ${el.id} list tag is - ${el.id} ${index}`}
              className={cn("d-flex mt-1 align-items-center", classes["singe-detail-tag"])}
            >
              <p className="m-0 ui-xxs text-center" style={{ color: scssVariables.information900 }}>
                {el.name}
              </p>
            </div>
          );
        })}
      </div>
    );
  }, [tags]);

  return (
    <motion.div
      layout
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className={cn("d-flex flex-column align-items-center single-detail", classes["single-detail"])}
    >
      {url ? (
        <Image width={182} height={82} src={url} style={{ borderRadius: 4, objectFit: "contain" }} />
      ) : (
        <div
          className={cn(
            "d-flex align-items-center justify-content-center single-detail-empty-img",
            classes["single-detail-empty-img"]
          )}
        >
          <ImageIcon />
        </div>
      )}

      <p className="m-0 head-xxxxs mt-3 mb-1">{title}</p>

      <p className="m-0 ui-xs mb-1">{`${city ? city + "," : ""} ${country ? country : ""}`}</p>

      {renderTags()}

      <p className={cn("m-0 ui-xs mt-2", classes["single-detail-description"])}>{oneLiner}</p>

      <div className="d-flex flex-column align-items-center" style={{ marginTop: "auto" }}>
        <p className="m-0 ui-xxs mb-2 fw-bold" style={{ color: scssVariables.information900 }}>{`${
          foundedAt
            ? t("foundedIn", {
                year: new Date(foundedAt).getFullYear(),
              }) + (companyPhase ? ", " : " ")
            : ""
        }${companyPhase ? companyPhase : ""}`}</p>

        <Button
          size="s"
          variant="secondary"
          onClick={cardClickHandler}
          data-testid="single-company-public-submit-test-id"
          style={{ border: "2px solid black" }}
        >
          {t("readMore")}
        </Button>
      </div>
    </motion.div>
  );
};

export default SingleCompanyDetail;
