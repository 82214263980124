import axios from "axios";
import { action, thunk } from "easy-peasy";

import { ActiveCompanyModel, RemainingStakeholdersDto } from "./modelTypes/activeCompanyModel.types";

export const activeCompanyModel: ActiveCompanyModel = {
  companyId: null,
  remainingStakeholders: {
    maxNumberOfStakeholders: 0,
    remainingStakeholders: null,
    stakeholdersCount: 0,
  },
  // actions
  setCompanyId: action((state, payload) => {
    state.companyId = payload;
  }),
  setRemainingStakeholders: action((state, payload) => {
    state.remainingStakeholders = payload;
  }),
  // thunks
  getRemainingStakeholdersThunk: thunk(async (actions, companyId) => {
    try {
      const response = await axios.get<RemainingStakeholdersDto>(
        `/api/company/subscription-remaining-stakeholders/${companyId}`
      );
      actions.setRemainingStakeholders(response.data);
      return response;
    } catch (e) {
      return await Promise.reject(e);
    }
  }),
};
