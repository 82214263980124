import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikConfig } from "formik";
import * as R from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { LoaderContainer } from "common/components/atoms";
import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import { H } from "common/components/atoms/Typography";
import useRSAAgreementDetails from "common/components/organisms/agreements/components/rsa/rsa-agreement-details/useRSAAgreementDetails";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useStepForm, { ThreeStepForm } from "../../components/step/useStepForm";
import AgreementSummary from "../common/agreement-summary/agreement-summary";
import PaymentInformation from "../common/peyment-information/payment-information";
import classes from "../common/ReceivePlan.wizard.module.scss";
import ScheduleChart from "../common/schedule-chart/schedule-chart";
import VestingMilestones from "../common/vesting-milestones/vesting-milestones";
import ReceiveRsaPlanContext from "./receive-rsa-plan-context.wizard";
import ReceiveRSAPlanFormData from "./ReceiveRSAPlanFormData";
import RSAInfo from "./RSA-info/RSA-info";

const t = createTranslation(TranslationNS.pages, "company.receivePlan");

type PlanForm = ThreeStepForm & {
  approvalKey: string;
  planId: number;
};

const Content = () => {
  const navigate = useNavigate();

  const { invitedUser } = useStoreState((state) => state);
  const { setIsDarkTheme, setIsFooterShown, setHeaderContextShown } = useStoreActions((actions) => actions.app);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);
  const { isLoading, planDetails } = ReceiveRsaPlanContext.useStoreState((state) => state);
  const { getPlanDetailsThunk, acceptPlanThunk } = ReceiveRsaPlanContext.useStoreActions((actions) => actions);

  const planId = useMemo(() => Number(invitedUser.invitedUser?.entityId), [invitedUser.invitedUser?.entityId]);
  const isExisting = Boolean(planDetails?.existingPlan);

  const otherValues = useMemo(() => {
    return {
      approvalKey: String(invitedUser.invitedUser?.approvalKey),
      planId,
    };
  }, [invitedUser.invitedUser?.approvalKey, planId]);

  const docLength = planDetails?.documentFiles ? planDetails?.documentFiles.length : 0;

  const { initialValues, validationSchema } = useStepForm(
    docLength,
    Boolean(planDetails?.documentsNeedsSignature),
    otherValues
  );

  const { agreementItems, isShowMilestones, isShowScheduleChart } = useRSAAgreementDetails(planDetails);

  const submitPlanHandler = useCallback<FormikConfig<PlanForm>["onSubmit"]>(
    async (values) => {
      try {
        const request = await acceptPlanThunk({
          approvalKey: values.approvalKey,
          planId: values.planId,
          signature: values.signature || undefined,
        });

        if (request) {
          setInvitedUser(null);

          notify(t("notifications.acceptSuccess"), true, "success");

          navigate(isExisting ? `/my-portfolio/${planDetails?.id}/rsa-plan` : getPath(["myPortfolio", "portfolio"]), {
            replace: true,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [acceptPlanThunk, isExisting, navigate, planDetails?.id, setInvitedUser]
  );

  useEffect(() => {
    if (!R.isNil(invitedUser?.invitedUser) && !R.isNil(invitedUser?.invitedUser?.approvalKey)) {
      getPlanDetailsThunk(R.defaultTo("", invitedUser?.invitedUser?.approvalKey)).catch(() => {
        navigate(getPath(["user", "dashboard"]), { replace: true });
      });
    }
  }, [invitedUser, invitedUser?.invitedUser?.approvalKey, getPlanDetailsThunk, navigate]);

  useEffect(() => {
    setIsDarkTheme(true);
    setIsFooterShown(false);
    setHeaderContextShown(false);

    return () => {
      setIsDarkTheme(false);
      setIsFooterShown(true);
      setHeaderContextShown(true);
    };
  }, [setIsDarkTheme, setIsFooterShown, setHeaderContextShown]);

  return (
    <LoaderContainer loading={isLoading && !planDetails}>
      {planDetails && (
        <PageContent className={classes["container"]} data-testid="receive-plan-test-id">
          <H.s className="mb-4">
            {t(isExisting ? "RSA.titleExisting" : "RSA.title", {
              companyName: planDetails.companyName,
            })}
          </H.s>

          <RSAInfo className="mb-6" planDetails={planDetails} />

          {isShowScheduleChart && (
            <CollapsibleContainer className="mb-8" defaultOpen headTitle={t("chartTitle")} withoutDivider>
              <ScheduleChart planDetails={planDetails} className="mt-4" />
            </CollapsibleContainer>
          )}

          {isShowMilestones && (
            <CollapsibleContainer className="mb-8" defaultOpen headTitle={t("milestones.title")} withoutDivider>
              <VestingMilestones milestones={planDetails.vestingMilestones || []} />
            </CollapsibleContainer>
          )}

          <CollapsibleContainer className="mb-8" defaultOpen headTitle={t("summaryTitle")} withoutDivider>
            <AgreementSummary agreementItems={agreementItems} essentialTerms={planDetails?.essentialTerms} />
          </CollapsibleContainer>

          {planDetails?.paymentInfo && planDetails?.totalPurchasePrice ? (
            <CollapsibleContainer className="mb-8" defaultOpen headTitle={t("payment.paymentTitle")}>
              <PaymentInformation
                {...planDetails.paymentInfo}
                stakeholderName={`${planDetails.stakeholder.firstName} ${planDetails.stakeholder.lastName}`}
                currencyId={planDetails.currencyId}
              />
            </CollapsibleContainer>
          ) : null}
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={submitPlanHandler}>
            <ReceiveRSAPlanFormData />
          </Formik>
        </PageContent>
      )}
    </LoaderContainer>
  );
};

const ReceiveRsaPlanWizard = () => {
  return (
    <ReceiveRsaPlanContext.Provider>
      <Content />
    </ReceiveRsaPlanContext.Provider>
  );
};

export default ReceiveRsaPlanWizard;
