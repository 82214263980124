import { FC, useCallback, useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { formatDistanceToNow } from "date-fns";
import { nb } from "date-fns/locale";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";

import { CheckIcon, DownloadIcon, PreloaderIcon, QuestionCircleIcon } from "common/icons/svg";
import { createTranslation, Languages, TranslationNS } from "translation";

import { Ui } from "../../../Typography";
import { useTableBrowserStorage } from "../../TableBrowserStorage";
import classes from "../StickyActions.module.scss";

const t = createTranslation(TranslationNS.common, "atoms.saveButton");

type SaveButtonProps = {
  descriptionContent?: string;
};

const SaveButton: FC<SaveButtonProps> = ({ descriptionContent }) => {
  const { i18n } = useTranslation();
  const { values } = useFormikContext<(object & { checkbox?: boolean })[]>();

  const { setImportTable, setImportTableDebounce, timestamp } = useTableBrowserStorage();

  useEffect(() => {
    if (values.length) {
      setImportTableDebounce(values);
    }

    return () => {
      setImportTableDebounce.cancel();
    };
  }, [setImportTableDebounce, values]);

  const handleSave = useCallback(() => {
    setImportTable(values);
  }, [setImportTable, values]);

  const [timeAgo, setTimeAgo] = useState("");
  const [saving, setSaving] = useState(false);
  const [hover, setHover] = useState(false);
  const [initialTimestamp, setInitialTimestamp] = useState(timestamp);

  useEffect(() => {
    const distance = formatDistanceToNow(new Date(+timestamp), {
      addSuffix: true,
      locale: i18n.language === Languages.no ? nb : undefined,
    });
    setTimeAgo(distance);
  }, [i18n.language, timeAgo, timestamp]);

  useEffect(() => {
    const interval = setInterval(() => {
      const distance = formatDistanceToNow(new Date(+timestamp), { addSuffix: true });
      setTimeAgo(distance);
      return () => clearInterval(interval);
    }, 60000); // Update every minute

    return () => clearInterval(interval);
  }, [timestamp]);

  useEffect(() => {
    if (timestamp && timestamp !== initialTimestamp) {
      setSaving(true);
      setInitialTimestamp(timestamp);
      setTimeout(() => {
        setSaving(false);
      }, 1000);
    }
  }, [initialTimestamp, timestamp]);

  const handleMouseOver = useCallback(() => setHover(true), []);
  const handleMouseLeave = useCallback(() => setHover(false), []);

  const RightIconComponent = useCallback(() => {
    if (hover && !saving) {
      return <DownloadIcon />;
    } else if (saving) {
      return (
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className={classes.leftIcon}
        >
          <PreloaderIcon />
        </motion.div>
      );
    } else if (!saving && !hover) {
      return <CheckIcon />;
    }
  }, [hover, saving]);

  if (!timestamp) return null;

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip className={classes.tooltip} id="button-tooltip-2">
          <Ui.xs style={{ marginBottom: 2 }} bold>
            {t("tooltipTitle")}
          </Ui.xs>
          {descriptionContent ? (
            <Ui.xs>{descriptionContent}</Ui.xs>
          ) : (
            <>
              <Ui.xs>{t("tooltipDescription1")}</Ui.xs>
              <Ui.xs className="mt-2">{t("tooltipDescription2")}</Ui.xs>
            </>
          )}
        </Tooltip>
      }
    >
      {({ ref, ...triggerHandler }) => (
        <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <button
            ref={ref}
            className={classNames(classes.saveButton, {
              [classes.saving]: saving,
              [classes.hover]: hover && !saving,
            })}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            onClick={handleSave}
          >
            <span className="d-flex align-items-center">
              <span className={classes.leftIcon}>
                <RightIconComponent />
              </span>
              <span>
                {hover && !saving && t("saveNow")}
                {saving && t("saving")}
                {!saving && !hover && `${t("saved")} ${timeAgo.replace(t("lessMinuteAgo"), t("justNow"))}`}
              </span>
            </span>
            <span {...triggerHandler} className={classes.rightIcon}>
              <QuestionCircleIcon />
            </span>
          </button>
        </motion.div>
      )}
    </OverlayTrigger>
  );
};

export default SaveButton;
