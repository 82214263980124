import { FC, useCallback, useEffect, useState } from "react";
import axios from "axios";
import * as R from "ramda";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import WarrantDetails from "common/components/molecules/warrantDetails/WarrantDetails";
import { notify } from "common/utils/notify/notifyFunction";
import IssueEquityContext from "pages/equity-management/ownership/financial-instruments/IssueEquityContext";
import { createTranslation, TranslationNS } from "translation";

import classes from "./DeleteWarrantSlidePanel.module.scss";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.conversion.delete");

const DeleteContent: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { warrantDetails, warrantId } = IssueEquityContext.useStoreState((state) => state);
  const { getIssueEquityThunk, getWarrantDetailsThunk } = IssueEquityContext.useStoreActions((actions) => actions);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteWarrantHandler = async () => {
    try {
      setIsDeleting(true);

      const formData = new FormData();
      formData.append("CompanyId", String(warrantDetails?.companyId));
      formData.append("WarrantId", String(warrantDetails?.warrantId));

      const request = await axios.delete("/api/equity-management/issue-equity/warrant", {
        data: formData,
      });

      if (request.status === 200) {
        handleClose();
        getIssueEquityThunk(Number(warrantDetails?.companyId));

        notify(t("successMessage"), true, "success", 10000);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    if (warrantId) {
      setIsLoading(true);
      getWarrantDetailsThunk(warrantId)
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [getWarrantDetailsThunk, warrantId]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <SlidePanel.Section title="" isSingle>
        <WarrantDetails
          title={t("details")}
          companyPercentage={7.05}
          email={warrantDetails?.email}
          expirationDate={warrantDetails?.expirationDate}
          strikePrice={R.defaultTo(0, warrantDetails?.strikePrice)}
          numberOfShares={R.defaultTo(0, warrantDetails?.numberOfShares)}
          warrantPrice={R.defaultTo(0, Number(warrantDetails?.warrantPrice))}
          representedBy={`${R.defaultTo("", warrantDetails?.firstName)} ${R.defaultTo("", warrantDetails?.lastName)}`}
        />
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button
          isLoading={isDeleting}
          isDisabled={isDeleting}
          className={classes["delete-btn"]}
          onClick={deleteWarrantHandler}
        >
          {t("deleteWarrant")}
        </Button>

        <Button isLoading={isDeleting} isDisabled={isDeleting} variant="dark" className="ms-3" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </SlidePanel.Actions>
    </SlidePanel.Body>
  );
};
const DeleteWarrantSlidePanel: FC = () => {
  const { isDeleteWarrantEditPanelOpen } = IssueEquityContext.useStoreState((state) => state);
  const { setIsDeleteWarrantEditPanelOpen } = IssueEquityContext.useStoreActions((actions) => actions);

  const handleClosePanel = useCallback(() => {
    setIsDeleteWarrantEditPanelOpen({ open: false });
  }, [setIsDeleteWarrantEditPanelOpen]);

  return (
    <SlidePanel show={isDeleteWarrantEditPanelOpen}>
      <SlidePanel.Header title={t("title")} onHide={handleClosePanel} />
      <DeleteContent handleClose={handleClosePanel} />
    </SlidePanel>
  );
};

export default DeleteWarrantSlidePanel;
