import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import StepInfo from "common/components/atoms/Step/StepInfo";
import { HighFive, LockIcon, SendEmail } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { Variant } from "store/GettingStartedGuideModel";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./stepFive.module.scss";

const t = createTranslation(TranslationNS.pages, "company.gettingStartedGuide");

const StepFive: FC<{ status: Variant }> = ({ status }) => {
  const { numberOfUnGrantedPlans, numberOfGrantedPlans, capTable } = useStoreState(
    (state) => state.gettingStartedGuide
  );
  const navigate = useNavigate();

  const setupCapTable = !capTable.numberOfShareHolders;
  const isInactive = status === "waiting" || status === "undone";

  const handleDraftedPlan = useCallback(() => {
    navigate(getEMPath(["plans", "agreements"]));
  }, [navigate]);

  return (
    <Step.Item status={status}>
      <Step.Point
        icon={
          status === "done" ? undefined : status === "noSubscription" ? (
            <LockIcon />
          ) : (
            <SendEmail width="24px" height="24px" />
          )
        }
      />
      <StepInfo>
        {status === "undone" && <Step.Important className="mb-1" />}
        <div className="d-flex align-items-center">
          <div className={classes.capTableItem}>
            <b>{t("stepFive.title")}</b>
            <p className="mt-1 mb-3">{t("stepFive.description")}</p>
            {!isInactive && !setupCapTable && (
              <>
                {+numberOfUnGrantedPlans ? (
                  <Button size="s" isDisabled={status === "noSubscription"} onClick={handleDraftedPlan}>
                    {t("stepFive.grantTheDraftedPlans", { value: numberOfUnGrantedPlans })}
                  </Button>
                ) : (
                  <Button size="s" isDisabled={status === "noSubscription"} onClick={handleDraftedPlan}>
                    {t("stepFive.addPlans")}
                  </Button>
                )}
              </>
            )}
          </div>
          <div className={classes.capTableItem}>
            <div className="d-flex flex-column align-items-center justify-content-center">
              {status === "noSubscription" ? (
                <div className="d-flex ms-3 align-items-center">
                  <LockIcon id={classes["icon"]} color={scssVariables.foregroundDisabled} />
                  <Ui.m className="ms-1" style={{ color: scssVariables.foregroundDisabled }}>
                    {t("subscribeForAccess")}
                  </Ui.m>
                </div>
              ) : (
                <>
                  <HighFive width="50" height="50" className="ms-3" />
                  {!!numberOfGrantedPlans && (
                    <b className="mt-1">{t("stepFive.plansGrantedDoFar", { value: numberOfGrantedPlans })}</b>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </StepInfo>
    </Step.Item>
  );
};

export default StepFive;
