import { FC, ReactNode } from "react";
import { Spinner } from "react-bootstrap";

import classes from "./LoaderContent.module.scss";

type LoaderContentProps = {
  children: ReactNode;
  show: boolean;
};

const LoaderContent: FC<LoaderContentProps> = ({ children, show }) => {
  return (
    <div className={classes.wrap}>
      {show && (
        <div className={classes.loaderContent}>
          <Spinner animation="border" />
        </div>
      )}
      {children}
    </div>
  );
};

export default LoaderContent;
