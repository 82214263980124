import { FC, useCallback } from "react";

import { CommonFileIcon, ExclamationMarkCircle, FolderIcon } from "common/icons/svg";

type Props = {
  pledgedShares?: number;
  transactionDescriptions?: number;
  documents?: number;
  hasPledges?: boolean;
};

import classNames from "classnames";

import { Tooltip, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import classes from "./NotesCell.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.table.common.notesInfo");

const NotesCell: FC<Props> = ({ pledgedShares, transactionDescriptions, documents, hasPledges }) => {
  const formNumber = useFormatNumbers();

  const PopupContent = useCallback(
    ({ title, count, type }: { title: string; count?: string | number; type?: "circle" }) => {
      return (
        <div className="d-flex align-items-center">
          <Ui.xs className="me-1" color="foregroundMedium" bold>
            {title}:
          </Ui.xs>
          <Ui.xs color="foregroundMedium" className={classNames({ [classes["circle"]]: type === "circle" })}>
            {count}
          </Ui.xs>
        </div>
      );
    },
    []
  );

  return (
    <div className={classes.wrap}>
      {!!hasPledges && !!pledgedShares && (
        <span>
          <Tooltip
            popupContent={<PopupContent title={t("pledgedShares")} count={formNumber(pledgedShares, "amount")} />}
          >
            <ExclamationMarkCircle />
          </Tooltip>
        </span>
      )}
      {!!transactionDescriptions && (
        <span>
          <Tooltip
            popupContent={
              <PopupContent type="circle" title={t("transactionDescriptions")} count={transactionDescriptions} />
            }
          >
            <CommonFileIcon />
          </Tooltip>
        </span>
      )}
      {!!documents && (
        <span>
          <Tooltip popupContent={<PopupContent type="circle" title={t("documents")} count={documents} />}>
            <FolderIcon />
          </Tooltip>
        </span>
      )}
    </div>
  );
};

export default NotesCell;
