import { forwardRef, ForwardRefRenderFunction, ReactNode, useCallback, useEffect, useImperativeHandle } from "react";
import { Offcanvas, OffcanvasBody } from "react-bootstrap";

import Button from "common/components/atoms/Button/Button";
import { CloseIcon } from "common/icons/svg";

import WizardLayoutStore from "../store";
import classes from "./WizardCloseConfirm.module.scss";

export type WizardCloseConfirmRef = {
  close(): void;
};

export type WizardCloseConfirmProps = {
  onHide?(): void;
  onExit?: () => void;
  children?: ReactNode;
};

const WizardCloseConfirm: ForwardRefRenderFunction<WizardCloseConfirmRef, WizardCloseConfirmProps> = (
  { onHide, children, onExit },
  ref
) => {
  const closeConfirmOpenStore = WizardLayoutStore.useStoreState((state) => state.closeConfirmOpen);
  const showCloseModal = WizardLayoutStore.useStoreState((state) => state.showCloseModal);
  const setCloseConfirmOpenAction = WizardLayoutStore.useStoreActions((actions) => actions.setCloseConfirmOpen);

  const modalHideHandler = useCallback((): void => {
    setCloseConfirmOpenAction(false);

    onHide?.();
  }, [setCloseConfirmOpenAction, onHide]);

  useImperativeHandle(
    ref,
    () => ({
      close: modalHideHandler,
    }),
    [modalHideHandler]
  );

  useEffect(() => {
    if (!showCloseModal && closeConfirmOpenStore) {
      onExit?.();
    }
  }, [closeConfirmOpenStore, onExit, showCloseModal]);

  return (
    <Offcanvas
      show={closeConfirmOpenStore && showCloseModal}
      onHide={modalHideHandler}
      className={classes["off-canvas"]}
      placement="end"
    >
      <OffcanvasBody className="p-5">
        <Button
          size="s"
          isOnlyIcon
          variant="dark"
          className={`p-0 mt-1 me-1 ${classes["close-button"]}`}
          onClick={modalHideHandler}
        >
          <CloseIcon />
        </Button>

        {children}
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default forwardRef(WizardCloseConfirm);
