import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { Formik, FormikConfig } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { LoaderContainer } from "common/components/atoms";
import TableBrowserStorage, { useTableBrowserStorage } from "common/components/atoms/ImportTable/TableBrowserStorage";
import UnsavedChangesModal, {
  SelectionItems,
  UnsavedChangesOptions,
} from "common/components/atoms/ImportTable/UnsavedChangesModal/UnsavedChangesModal";
import { OnboardingTransaction } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useOnboardCompanyIntegration from "../useOnboardCompanyIntegration";
import classes from "./ImportTransaction.module.scss";
import TransactionActions from "./TransactionActions/TransactionActions";
import TransactionTable from "./TransactionTable/TransactionTable";
import useImportTransactionColumns from "./useImportTransactionColumns";
import useImportTransactionForm, { ImportTransactionFormValues, initValues } from "./useImportTransactionForm";

const t = createTranslation(TranslationNS.pages, "onboard.company.importTransaction");

const ImportTransactionForm: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId?: string }>();
  const [completedOnboarding, setCompletedOnboarding] = useState(false);

  const [transactions, setTransactions] = useState<OnboardingTransaction[]>([]);

  useOnboardCompanyIntegration(true);

  const transaction = useStoreState((state) => state.companyOnboardingModel.transaction);
  const loading = useStoreState((state) => state.companyOnboardingModel.isGetCompanyLoading);
  const currencyId = useStoreState((state) => state.companyOnboardingModel.company?.currencyId);

  const { getTransactionsThunk, setTransactionsThunk } = useStoreActions((actions) => actions.companyOnboardingModel);

  const { getImportTableData, removeImportTable } = useTableBrowserStorage<OnboardingTransaction[]>();

  useEffect(() => {
    const browserData = getImportTableData()?.data;

    const data =
      (browserData && browserData.length && browserData) || // if browserData is not empty
      transaction?.transactions || // if transactions is not empty
      [];

    if (data && data.length) {
      setTransactions(data);
    }
  }, [getImportTableData, transaction?.transactions]);

  const handleImportExcelData = useCallback((data: OnboardingTransaction[]) => {
    setTransactions((prev) => [...prev, ...data]);
  }, []);

  const { validationSchema, initialValues } = useImportTransactionForm(
    transactions,
    transaction?.transactionTypes,
    transaction?.shareClasses
  );

  const { columns, countOfActiveCheckboxes } = useImportTransactionColumns(
    transaction?.shareClasses,
    transaction?.transactionTypes,
    transaction?.stakeholders,
    currencyId
  );

  const handleSubmit = useCallback<FormikConfig<ImportTransactionFormValues[]>["onSubmit"]>(
    async (values) => {
      try {
        if (companyId) {
          const existedIds = values
            .filter((transaction) => transaction.transactionId)
            .map((transaction) => transaction.transactionId);

          const transactionIdsToDelete = (transaction?.transactions || [])
            .filter((transaction) => !existedIds.includes(transaction.transactionId))
            .map((transaction) => transaction.transactionId) as number[];

          await setTransactionsThunk({
            companyId: Number(companyId),
            transactions: values.map((transaction) => ({
              ...transaction,
              sourceShareholderId: transaction.sourceShareholderId === 1 ? null : transaction.sourceShareholderId,
              transactionType: transaction.transactionType || 2, // 2 is share issue,
            })),
            completedOnboarding,
            transactionIdsToDelete,
          });

          removeImportTable();

          navigate(getEMPath(["ownership", "capTable"], { companyId }));
        }
      } catch (e) {
        console.error({ e });
      }
    },
    [companyId, completedOnboarding, navigate, removeImportTable, setTransactionsThunk, transaction?.transactions]
  );

  useEffect(() => {
    if (companyId) {
      getTransactionsThunk(Number(companyId)).catch((e) => {
        console.error({ e });
      });
    }
  }, [companyId, getTransactionsThunk]);

  const additionalItems = useMemo(() => {
    const items: SelectionItems<["saved"]>[] = [];

    if (transaction?.lastSavedAt) {
      items.push({
        title: t("savedVersion.title"),
        description: t("savedVersion.subtitle", {
          date: format(parseISO(transaction?.lastSavedAt), "eee dd. MMM, HH:mm"),
        }),
        id: "saved",
      });
    }

    return items;
  }, [transaction?.lastSavedAt]);

  const handleSelectData = useCallback(
    (type: UnsavedChangesOptions<["saved"]>) => {
      if (type === "saved") {
        setTransactions(transaction?.transactions || []);
      }
      if (type === "remove") {
        setTransactions([]);
      }
    },
    [transaction?.transactions]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <div className={classes.wrap}>
        <TransactionActions handleImportData={handleImportExcelData} setCompletedOnboarding={setCompletedOnboarding} />
        <LoaderContainer loading={loading}>
          <TransactionTable
            columns={columns}
            initialItems={initValues(transaction?.shareClasses)}
            countOfActiveCheckboxes={countOfActiveCheckboxes}
          />
          <UnsavedChangesModal selectedTypeCallback={handleSelectData} additionalItems={additionalItems} />
        </LoaderContainer>
      </div>
    </Formik>
  );
};

const ImportTransaction: FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  return (
    <TableBrowserStorage tableName={`transaction-${companyId}`}>
      <ImportTransactionForm />
    </TableBrowserStorage>
  );
};

export default ImportTransaction;
