import { FC, useCallback, useMemo } from "react";
import { format, parseISO } from "date-fns";

import UnsavedChangesModal, {
  SelectionItems,
  UnsavedChangesOptions,
} from "common/components/atoms/ImportTable/UnsavedChangesModal/UnsavedChangesModal";
import { BreggCompanyShareholders } from "store/modelTypes";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import OwnershipTable from "../../import-ownership/OwnershipTable/OwnershipTable";
import useImportOwnershipColumns from "../../import-ownership/useImportOwnershipColumns";
import { initValues } from "../../import-ownership/useImportOwnershipForm";

const t = createTranslation(TranslationNS.pages, "onboard.company.importShareholders.shareholdersTable");

type ShareholdersTableProps = {
  handleLoadData: (data: BreggCompanyShareholders[]) => void;
};

const ShareholdersTable: FC<ShareholdersTableProps> = ({ handleLoadData }) => {
  const shareholders = useStoreState((state) => state.companyOnboardingModel.shareholders);
  const shareClasses = useStoreState((state) => state.companyOnboardingModel.companyShareClasses);
  const { bregg, uniMicro } = useStoreState((state) => state.companyOnboardingModel.integrations);
  const lastSavedAt = useStoreState((state) => state.companyOnboardingModel.lastSavedAt);
  const currencyId = useStoreState((state) => state.companyOnboardingModel.company?.currencyId);

  const shareClassesData = useMemo(() => {
    return (shareClasses.length && shareClasses) || bregg?.shareClasses || uniMicro?.shareClasses || [];
  }, [bregg?.shareClasses, uniMicro?.shareClasses, shareClasses]);

  const { columns, countOfActiveCheckboxes } = useImportOwnershipColumns(shareClassesData, false, currencyId);

  const additionalItems = useMemo(() => {
    const items: SelectionItems<["saved", "bregg"]>[] = [];
    if (lastSavedAt) {
      items.push({
        title: t("savedVersion.title"),
        description: t("savedVersion.subtitle", { date: format(parseISO(lastSavedAt), "eee dd. MMM, HH:mm") }),
        id: "saved",
      });
    }
    if (bregg?.shareholders?.length || uniMicro?.shareholders?.length) {
      items.push({
        title: t("breggVersion.title"),
        description: t("breggVersion.subtitle", { date: "31.12.2022" }),
        id: "bregg",
      });
    }

    return items;
  }, [bregg?.shareholders?.length, lastSavedAt, uniMicro?.shareholders?.length]);

  const handleSelectData = useCallback(
    (type: UnsavedChangesOptions<["saved", "bregg"]>) => {
      if (type === "saved") {
        handleLoadData(shareholders);
      } else if (type === "remove") {
        handleLoadData([]);
      } else if (type === "bregg") {
        handleLoadData(bregg?.shareholders || uniMicro?.shareholders || []);
      }
    },
    [bregg?.shareholders, handleLoadData, shareholders, uniMicro?.shareholders]
  );

  return (
    <>
      <OwnershipTable
        columns={columns}
        initialItems={initValues(shareClasses)}
        countOfActiveCheckboxes={countOfActiveCheckboxes}
      />
      <UnsavedChangesModal selectedTypeCallback={handleSelectData} additionalItems={additionalItems} />
    </>
  );
};

export default ShareholdersTable;
