import { FC, useCallback, useEffect, useState } from "react";
import { OffcanvasProps } from "react-bootstrap/Offcanvas";
import Spinner from "react-bootstrap/Spinner";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { ShareClassFormProps } from "common/shareClass/components/ShareClassForm/ShareClassForm";
import ShareClassService, {
  ShareClassServiceCreateData,
  ShareClassServiceUpdateData,
} from "common/shareClass/ShareClassService";
import { ShareClass, ShareClassTypes } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

import Form, { FormProps } from "./Form/Form";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassFormOffcanvas");

type ContentProps = Pick<FormProps, "onCancel"> & {
  shareClassId?: string | number;
  onSave?(shareClass: ShareClass): void;
  onHide?: () => void;
};

const Content: FC<ContentProps> = ({ shareClassId, onHide, onSave, onCancel }) => {
  const [loading, setLoading] = useState<boolean>(!!shareClassId);
  const [shareClass, setShareClass] = useState<any>({
    hasVotingRights: true,
    hasDividends: true,
    isParticipating: true,
    seniorityLevel: 1,
    shareClassTypeId: ShareClassTypes.common,
  });

  const submitHandler = useCallback<ShareClassFormProps["onSubmit"]>(
    (values): void => {
      setLoading(true);

      (shareClassId
        ? ShareClassService.update(values as ShareClassServiceUpdateData)
        : ShareClassService.create(values as ShareClassServiceCreateData)
      )
        .then(({ data }) => {
          onSave?.(data);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [onSave, shareClassId]
  );

  useEffect(() => {
    if (shareClassId) {
      setLoading(true);

      ShareClassService.get(shareClassId)
        .then(({ data }) => {
          setShareClass(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [shareClassId]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <SlidePanel.Header
        isEdit={!!shareClassId}
        title={shareClassId ? shareClass.name : t("createTitle")}
        onHide={onHide}
      />

      <Form shareClass={shareClass} onSubmit={submitHandler} onCancel={onCancel} />
    </>
  );
};

export type ShareClassFormOffcanvasProps = OffcanvasProps & ContentProps;

const ShareClassFormOffcanvas: FC<ShareClassFormOffcanvasProps> = ({
  show,
  shareClassId,
  onSave,
  onHide,
  onCancel,
}) => {
  return (
    <SlidePanel show={show} onHide={onHide as () => void}>
      <Content shareClassId={shareClassId} onSave={onSave} onCancel={onCancel} onHide={onHide} />
    </SlidePanel>
  );
};

export default ShareClassFormOffcanvas;
