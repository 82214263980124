/*eslint-disable */

export const EMAIL_VALIDATION_PATTERN = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,}$/;

export const PASSWORD_VALIDATION_PATTERN = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{0,})/;

export const URL_VALIDATION_PATTERN =
  /^(www|http|https|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gmu;

export const INSTAGRAM_VALIDATION_PATTERN =
  /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am|twitter\.com)\/([A-Za-z0-9-_\.]+)/im;

export const FACEBOOK_VALIDATION_PATTERN = /^(https?:\/\/){0,1}(www\.){0,1}facebook\.com/;

export const SELECT_DIGITS_PATTERN = /[^\d]/g;
