import { FC, memo, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button } from "common/components/atoms";
import { FilledArrowDownIcon, PlusIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import DocumentsContext, { editPanelTypes, GoverningDocumentsType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import EmptyDocumentsRow from "../../EmptyDocumentsRow";
import NestedTableRow from "./NestedTableRow";

type CompanyDocumentsTableRowProps = {
  data: GoverningDocumentsType["documentCategories"][0];
};

const CompanyDocumentsTableRow: FC<CompanyDocumentsTableRowProps> = ({ data }) => {
  const { hasFullAccess } = useFeatures(FEATURES.documents);

  const { searchValue } = DocumentsContext.useStoreState((state) => state);
  const { setIsEditPanelOpen } = DocumentsContext.useStoreActions((actions) => actions);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const rowClickHandler = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const uploadHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      const selectedSlidePanelType =
        data.documentType === 10
          ? editPanelTypes.generalGoverning
          : data.documentType === 11
          ? editPanelTypes.generalAssembly
          : editPanelTypes.boardProtocols;

      setIsEditPanelOpen({
        isOpen: true,
        documentType: "governing",
        editPanel: selectedSlidePanelType,
      });
    },
    [data.documentType, setIsEditPanelOpen]
  );

  useEffect(() => {
    if (searchValue.trim() && !isOpen) {
      rowRef?.current?.click();
    }
  }, [isOpen, searchValue]);

  return (
    <>
      <motion.tr
        ref={rowRef}
        data-testid="company-documents-table-single-row-test-id"
        className={cn(classes["table-row"], {
          [classes.hovered]: isHovered,
          [classes.selected]: isOpen,
        })}
        onHoverEnd={setIsHovered.bind(null, false)}
        onHoverStart={setIsHovered.bind(null, true)}
        onClick={rowClickHandler}
      >
        <td>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center position-relative">
              <motion.div
                className="me-1"
                initial={{ transform: "rotate(0deg)" }}
                animate={{
                  transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              >
                <FilledArrowDownIcon color={scssVariables.foregroundLow} />
              </motion.div>

              <Highlighter
                autoEscape={true}
                textToHighlight={data.name}
                searchWords={[searchValue as string]}
                highlightClassName={classes.highligted}
                unhighlightClassName={classes.unhighlited}
              />

              {isHovered ? (
                <Button
                  variant="tertiary"
                  iconLeft={<PlusIcon />}
                  size="s"
                  className={classes["upload-btn"]}
                  isDisabled={!hasFullAccess}
                  onClick={uploadHandler}
                >
                  Upload
                </Button>
              ) : null}
            </div>
          </div>
        </td>
      </motion.tr>

      <AnimatePresence>
        {isOpen &&
          (data.documents.length ? (
            data.documents.map((el, index) => <NestedTableRow key={`Inner table row index - ${index}`} data={el} />)
          ) : (
            <EmptyDocumentsRow
              isRequired
              className={classes["empty-label"]}
              iconClassName="ms-4 d-none"
              label="Uploaded documents will appear here"
            />
          ))}
      </AnimatePresence>
    </>
  );
};

export default memo(CompanyDocumentsTableRow);
