import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";

import { getPath } from "app/Router/RouterHelper";
import { H, ModalInfo, P, Ui } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import { ArrowRightIcon, CheckIcon, CloseIcon, InformationCircleOutlinedV2 } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./IncludeTransactionModal.module.scss";

const t = createTranslation(TranslationNS.pages, "onboard.company.basic.integration.modal");

type OrgNumberModalProps = {
  show: boolean;
  handleClose: () => void;
};

const IncludeTransactionModal: FC<OrgNumberModalProps> = ({ show, handleClose }) => {
  const { companyId } = useParams<{ companyId: string }>();

  return (
    <ModalInfo size="xl" show={show} handleClose={handleClose}>
      <Button onClick={handleClose} variant="tertiary" isOnlyIcon className={classes.closeIcon}>
        <CloseIcon />
      </Button>

      <H.xs className="text-start mt-4 px-4">{t("title")}</H.xs>

      <div className="px-4">
        <Row className="mx-0 my-5 p-0">
          <Col className="text-start m-0 p-0 pe-9" md={6}>
            <Ui.m bold>{t("left.description")}</Ui.m>
            <Ui.m className="mt-4">{t("left.benefits")}</Ui.m>
            <div className="d-flex align-items-start mt-2">
              <span className={classes.checkmark}>
                <CheckIcon />
              </span>
              <P.m className="ms-1">{t("left.pointOne")}</P.m>
            </div>
            <div className="d-flex align-items-start mt-2">
              <span className={classes.checkmark}>
                <CheckIcon />
              </span>
              <P.m className="ms-1">{t("left.pointTwo")}</P.m>
            </div>
            <div className="d-flex align-items-start mt-2">
              <span className={classes.checkmark}>
                <CheckIcon />
              </span>
              <P.m className="ms-1">{t("left.pointThree")}</P.m>
            </div>
            <div className="mt-4">
              <Link to={getPath(["onboard", "company", "importStakeholders"], { companyId: companyId || 0 })}>
                <Button as="span" iconRight={<ArrowRightIcon className="ms-2" />}>
                  {t("addTransaction")}
                </Button>
              </Link>
            </div>
          </Col>

          <Col className={classNames("text-start m-0 p-0 ps-9", classes.rightSection)} md={6}>
            <Ui.m bold>{t("right.description")}</Ui.m>
            <Ui.m className="mt-4">{t("right.benefits")}</Ui.m>
            <div className="d-flex align-items-start mt-2">
              <span className={classes.checkmark}>
                <CheckIcon />
              </span>
              <P.m className="ms-1">{t("right.pointOne")}</P.m>
            </div>
            <div className="d-flex align-items-start mt-2">
              <span className={classes.checkmark}>
                <CheckIcon />
              </span>
              <P.m className="ms-1">{t("right.pointTwo")}</P.m>
            </div>
            <div className={classNames("d-flex align-items-start mt-2", classes.info)}>
              <span className={classes.infoIcon}>
                <InformationCircleOutlinedV2 />
              </span>
              <P.s className="ms-1 me-2">{t("right.pointThree")}</P.s>
            </div>
            <div className="mt-4">
              <Link to={getPath(["onboard", "company", "importShareholders"], { companyId: companyId || 0 })}>
                <Button
                  as="span"
                  onClick={handleClose}
                  variant="secondary"
                  iconRight={<ArrowRightIcon className="ms-2" />}
                >
                  {t("later")}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </ModalInfo>
  );
};

export default IncludeTransactionModal;
