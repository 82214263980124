import { FC, useCallback } from "react";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { createTranslation, TranslationNS } from "translation";

type PropsType = {
  handleAddPlan?: () => void;
  handleClose?: (isOpen: boolean) => void;
  isSubmitting: boolean;
  isEdit?: boolean;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const Footer: FC<PropsType> = ({ handleAddPlan, isSubmitting, handleClose, isEdit }) => {
  const handleCancel = useCallback(() => {
    handleClose?.(false);
  }, [handleClose]);

  return (
    <SlidePanel.Actions>
      <Button
        aria-label={t("addPlan")}
        className="me-4"
        variant="primary"
        onClick={handleAddPlan}
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        data-testid="add-plan-btn"
      >
        {isEdit ? t("saveChanges") : t("addPlan")}
      </Button>
      <Button
        aria-label={t("cancel")}
        variant="secondary"
        onClick={handleCancel}
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
        data-testid="handle=close-btn"
      >
        {t("cancel")}
      </Button>
    </SlidePanel.Actions>
  );
};

export default Footer;
