import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { dissoc, equals } from "ramda";

import { Button } from "common/components/atoms";

import ManagePlansContext, { initialFiltersState } from "../../managePlansContext";
import classes from "./AgreementsAppliedFilters.module.scss";
import AgreementsStakeholderSearch from "./components/AgreementsStakeholderSearch";
import DatesFiltersDropdown from "./components/DatesFiltersDropdown";
import StatusFiltersDropdown from "./components/StatusFiltersDropdown";

const AgreementsAppliedFilters = () => {
  const { companyId = "0" } = useParams();

  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const setSavedAppliedFilters = ManagePlansContext.useStoreActions((actions) => actions.setSavedAppliedFilters);

  const filtersForCurrentCompany = savedAppliedFilters?.[companyId];

  const isFiltersEquals = useMemo(
    () => equals(filtersForCurrentCompany, initialFiltersState),
    [filtersForCurrentCompany]
  );

  return (
    <div className={classNames("my-4 px-3 d-flex align-items-center", classes["container"])}>
      <StatusFiltersDropdown />

      <DatesFiltersDropdown />

      <AgreementsStakeholderSearch />

      {!isFiltersEquals ? (
        <Button
          size="s"
          className="ms-1"
          variant="tertiary"
          onClick={() => {
            setSavedAppliedFilters(dissoc(companyId, savedAppliedFilters || {}));
          }}
        >
          Clear filters
        </Button>
      ) : null}
    </div>
  );
};

export default AgreementsAppliedFilters;
