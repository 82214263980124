import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import * as R from "ramda";

import AvatarUploader from "common/components/atoms/AvatarUploader/AvatarUploader";
import Button from "common/components/atoms/Button/Button";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import TextField from "common/components/atoms/TextField/TextField";
import { H, Ui } from "common/components/atoms/Typography";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./general.module.scss";
import useGeneralForm from "./useGeneralForm";

const t = createTranslation(TranslationNS.pages, "user.general");

const GeneralProfilePage: FC = () => {
  useDocumentTitleUpdate("My profile");

  const { initialValues, validationSchema } = useGeneralForm();

  const { user } = useStoreState((state) => state.account);
  const countriesDropDown = useStoreState((state) => state.common.dropdowns)?.countries;
  const { getUserProfileThunk, setUserName, getAccountThunk } = useStoreActions((state) => state.account);

  const [isUserProfileLoading, setIsUserProfileLoading] = useState<boolean>(true);
  const [profileImageFile, setProfileImageFile] = useState<File | undefined>();
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>();

  const uploadFile = useCallback(async () => {
    if (profileImageFile) {
      const formData = new FormData();
      formData.append("ImageFile", profileImageFile);

      await axios.post(`/api/users/profile-image/${user?.id}`, formData);
      setProfileImageFile(undefined);
    }
  }, [profileImageFile, user?.id]);

  const updateProfileDataHandler = useCallback(
    async (values: typeof initialValues) => {
      const sex = values.sex === "Not disclosed" ? "NotDisclosed" : values.sex;

      const updateProfileData = {
        firstname: values.firstName,
        lastname: values.lastName,
        dateOfBirth: values.dateOfBirth || null,
        sex,
        linkedInUrl: values.linkedInUrl,
        twitterHandle: values.twitter,
        streamingPlatformUrl: values.youtube,
        blogUrl: values.blog,
        personalWebsiteUrl: values.website,
        oneliner: values.oneliner,
        countryOfResidenceId: values?.countryOfResidenceId || null,
        cityOfResidence: values.cityOfResidence,
      };

      await axios.post("/api/users/user-profile", updateProfileData);

      await getUserProfileThunk();
      await getAccountThunk();
      setUserName(values.firstName);
    },
    [getAccountThunk, getUserProfileThunk, setUserName]
  );

  const submitHandler = useCallback(
    async (values: typeof initialValues) => {
      try {
        await updateProfileDataHandler(values);

        if (!R.isNil(setProfileImageFile)) {
          await uploadFile();
        }

        notify(t("notify.profileUpdated"), true, "success");
        getUserProfileThunk();
      } catch (e) {
        console.log(e);
      }
    },
    [getUserProfileThunk, updateProfileDataHandler, uploadFile]
  );

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue, handleBlur, isSubmitting } = useFormik<
    typeof initialValues
  >({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const genderDropdownOptions: string[] = ["Female", "Male", "Other", "Not disclosed"];

  useEffect(() => {
    if (!R.isNil(user) && !R.isNil(user?.profileImageFilePath)) {
      setImagePreviewUrl(`${process.env.REACT_APP_BLOB_PUBLIC_URL}${user.profileImageFilePath}`);
    }
  }, [user]);

  useEffect(() => {
    setIsUserProfileLoading(true);
    getUserProfileThunk().finally(() => {
      setIsUserProfileLoading(false);
    });
  }, [getUserProfileThunk]);

  const maxDate = useMemo(() => new Date(), []);

  const handleChangeDate = useCallback(
    (date: Date | string, name?: string) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  return (
    <PageContent data-testid="user-profile-general-container-test-id">
      <PageContent.Header>
        <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
      </PageContent.Header>
      <LoaderContainer loading={isUserProfileLoading}>
        <div className={classes["general"]}>
          <form onSubmit={handleSubmit} className={classes.wrap}>
            <H.xxxs className="pb-5">{t("generalInformation.title")}</H.xxxs>

            <Ui.m className="mb-2 fw-500">{t("generalInformation.subtitle")}</Ui.m>

            <div className="d-flex flex-column mb-7">
              <AvatarUploader
                circularImage
                defaultPreviewImageType="profile"
                descriptionText={t("generalInformation.replacePhoto.descriptionText")}
                buttonText={t(
                  imagePreviewUrl
                    ? "generalInformation.replacePhoto.buttonReplace"
                    : "generalInformation.replacePhoto.buttonUpload"
                )}
                previewUrl={imagePreviewUrl}
                setFileToUpload={setProfileImageFile}
              />
            </div>

            <Row>
              <Col>
                <TextField
                  type="firstname"
                  data-testid="text-input-firstname"
                  label={t("generalInformation.firstName.label")}
                  value={values.firstName}
                  name="firstName"
                  onChange={handleChange}
                  isTouched={touched.firstName}
                  error={errors.firstName}
                  onBlur={handleBlur}
                />
              </Col>

              <Col>
                <TextField
                  type="lastname"
                  label={t("generalInformation.lastName.label")}
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                  isTouched={touched.lastName}
                  error={errors.lastName}
                  onBlur={handleBlur}
                />
              </Col>
            </Row>

            <div className="d-flex flex-column mt-4">
              <H.xxxs className="mb-2">{t("details.title")}</H.xxxs>

              <Ui.s className="mb-5">{t("details.subtitle")}</Ui.s>

              <div className="d-flex flex-column">
                <Row>
                  <Col md={6}>
                    <DatePicker
                      isOptional
                      isDateOnlyString
                      isClearable
                      label={t("details.dateOfBirth.label")}
                      date={values.dateOfBirth}
                      maxDate={maxDate}
                      name="dateOfBirth"
                      onChange={handleChangeDate}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Dropdown
                      label={t("details.sex.label")}
                      data-testid="dropdown-toggle-Sex-test-id"
                      className="mt-4 mb-4"
                      selectedValue={values.sex}
                      onChange={(sex: string) => {
                        setFieldValue("sex", sex);
                      }}
                      options={R.defaultTo([], genderDropdownOptions).map((el) => el)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Dropdown
                      isOptional
                      isSearchable
                      searchType="startsWith"
                      searchPlaceholder="Country name"
                      label={t("details.countryOfResidenceId.label")}
                      options={countriesDropDown || []}
                      optionsIsObject
                      selectedValue={values?.countryOfResidenceId}
                      name="countryOfResidenceId"
                      onChange={handleChange}
                      isTouched={touched.countryOfResidenceId}
                      error={errors.countryOfResidenceId}
                      onBlur={handleBlur}
                    />
                  </Col>
                  <Col>
                    <TextField
                      label={t("details.cityOfResidence.label")}
                      value={values.cityOfResidence}
                      name="cityOfResidence"
                      onChange={handleChange}
                      isOptional
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className="mt-4 mb-4">
              <H.xxxs className="mb-2">{t("onlinePresence.title")}</H.xxxs>

              <Ui.s className="mb-4">{t("onlinePresence.subtitle")}</Ui.s>

              <TextField
                isOptional
                value={values.linkedInUrl}
                error={errors.linkedInUrl}
                isTouched={touched.linkedInUrl}
                placeholder="https://www.linkedin.com/in/"
                label={t("onlinePresence.linkedIn.label")}
                name="linkedInUrl"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>

            <div className="d-flex flex-column" style={{ width: 160 }}>
              <Button
                dataTestId="submit-button-test-id"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
                type="submit"
              >
                {t("save")}
              </Button>
            </div>
          </form>
        </div>
      </LoaderContainer>
    </PageContent>
  );
};

export default GeneralProfilePage;
