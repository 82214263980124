import { FC, useState } from "react";

import Table from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";

import { GetStakeholdersDTO } from "../../types";
import TableBody from "./body";
import TableHead from "./header";
import classes from "./table-view.module.scss";

type PropsTypes = {
  stakeholders: GetStakeholdersDTO;
  handleOpenEditSidebar: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
  handleOpenDetailModal?: (stakeholderId: number) => void;
};

export enum SortOptions {
  Name = "name",
  Email = "email",
  Relationship = "relationship",
}

export type SortSettings = {
  field: SortOptions;
  type: sortingParams;
};

const TableView: FC<PropsTypes> = ({
  stakeholders,
  handleRemoveStakeholder,
  handleOpenEditSidebar,
  handleOpenDetailModal,
}) => {
  const [sortSettings, setSortSettings] = useState<SortSettings>({
    field: SortOptions.Name,
    type: sortingParams.desc,
  });

  return (
    <Table
      className={classes.table}
      headComponent={<TableHead sortSettings={sortSettings} setSortSettings={setSortSettings} />}
      bodyComponent={
        <TableBody
          stakeholders={stakeholders}
          onEdit={handleOpenEditSidebar}
          onClick={handleOpenDetailModal}
          handleRemoveStakeholder={handleRemoveStakeholder}
          sortSettings={sortSettings}
        />
      }
    />
  );
};

export default TableView;
