import { useNavigate } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { createTranslation, TranslationNS } from "translation";

import Button from "../Button/Button";

const t = createTranslation(TranslationNS.common, "components.viewPitchButton");

const ViewPitchButton = ({ id }: { id: number }) => {
  const navigate = useNavigate();

  return (
    <Button
      size="s"
      variant="secondary"
      onClick={() => {
        navigate(getPath(["companyDetails"], { id }));
      }}
    >
      {t("title")}
    </Button>
  );
};

export default ViewPitchButton;
