import { FC, ReactNode } from "react";
import cn from "classnames";

import classes from "./step.module.scss";

type PropsTypes = {
  children: ReactNode;
  className?: string;
};
const StepInfo: FC<PropsTypes> = ({ children, className }) => {
  return <div className={cn(classes.info, className)}>{children}</div>;
};

export default StepInfo;
