import { Action, action, Computed, computed, createContextStore } from "easy-peasy";

import { PoolType, ProgramType } from "store/types";

interface PoolsAndProgramsStoreModel {
  editingItem?: PoolType | ProgramType;
  deletingItem?: PoolType | ProgramType;
  setEditingItem: Action<this, this["editingItem"]>;
  setDeletingItem: Action<this, this["deletingItem"]>;

  editingPool: Computed<this, PoolType | ProgramType | undefined>;
  editingProgram: Computed<this, PoolType | ProgramType | undefined>;
}

const PoolsAndProgramsStore = createContextStore<PoolsAndProgramsStoreModel>({
  setEditingItem: action((state, payload) => {
    state.editingItem = payload;
  }),
  setDeletingItem: action((state, payload) => {
    state.deletingItem = payload;
  }),
  editingPool: computed((state) => (state.editingItem?.type === "Pool" ? state.editingItem : undefined)),
  editingProgram: computed((state) => (state.editingItem?.type === "Program" ? state.editingItem : undefined)),
});

export default PoolsAndProgramsStore;
