import { FC } from "react";
import classNames from "classnames";

import classes from "./Title.module.scss";

export type TitleProps = JSX.IntrinsicElements["div"] & {};

const Title: FC<TitleProps> = ({ className, children, ...props }) => (
  <div
    className={classNames(classes["title"], "head-xxxxs", className)}
    {...props}
  >
    {children}
  </div>
);

export default Title;
