import { FC, useCallback, useState } from "react";
import Button from "react-bootstrap/Button";
import classNames from "classnames";

import { CloseIcon, InformationCircleOutlinedIcon } from "common/icons/svg";

import WizardLayoutStore from "../../store";
import ContentItems from "./ContentItems/ContentItems";
import Description from "./Description/Description";
import Media from "./Media/Media";
import classes from "./Sidebar.module.scss";
import Title from "./Title/Title";

export type SidebarProps = JSX.IntrinsicElements["div"] & {
  defaultOpen?: boolean;
};

type SidebarComponent = FC<SidebarProps> & {
  ContentItems: typeof ContentItems;
  Description: typeof Description;
  Media: typeof Media;
  Title: typeof Title;
};

const Sidebar: SidebarComponent = ({ className, defaultOpen = true, children, ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const sidebarContentActiveItemStore = WizardLayoutStore.useStoreState((state) => state.sidebarContentActiveItem);

  const toggleIsOpen = useCallback((): void => {
    setIsOpen((state) => !state);
  }, []);

  return (
    <div
      className={classNames(
        classes["sidebar"],
        {
          [classes["open"]]: isOpen && sidebarContentActiveItemStore,
        },
        className
      )}
      {...props}
    >
      <Button
        className={classNames(classes["toggle-button"], {
          [classes["hide"]]: !sidebarContentActiveItemStore,
        })}
        variant="link"
        onClick={toggleIsOpen}
      >
        {isOpen && !!sidebarContentActiveItemStore ? <CloseIcon /> : <InformationCircleOutlinedIcon />}
      </Button>

      <div className={classes["content"]}>{children}</div>
    </div>
  );
};

Sidebar.ContentItems = ContentItems;
Sidebar.Description = Description;
Sidebar.Media = Media;
Sidebar.Title = Title;

export default Sidebar;
