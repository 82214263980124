import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";
import { isNil } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { useRedirectTo } from "common/hooks/useRedirectTo";
import ShareClassService, { ShareClassServiceUpdateData } from "common/shareClass/ShareClassService";
import { ShareClassTypes } from "common/shareClass/types";
import { getShareClassValidationObject } from "common/shareClass/validationSchema";

import CreateShareClassStore from "../../store";
import SpecificsForm from "./SpecificsForm";

export type SpecificsFormData = Pick<
  ShareClassServiceUpdateData,
  "shareClassTypeId" | "hasVotingRights" | "votingRightsMultiple" | "hasDividends"
>;

const SpecificsClass: FC = () => {
  const navigate = useNavigate();
  const redirectTo = useRedirectTo();

  const formDataStore = CreateShareClassStore.useStoreState((state) => state.formData);
  const updateFormDataAction = CreateShareClassStore.useStoreActions((actions) => actions.updateFormData);

  const initialValues = useMemo<SpecificsFormData>(
    () => ({
      shareClassTypeId: formDataStore.shareClassTypeId || ShareClassTypes.common,
      // nominalValuePerShare: currentShareClassStore?.nominalValuePerShare || 100,
      hasVotingRights: isNil(formDataStore.hasVotingRights) ? true : formDataStore.hasVotingRights,
      votingRightsMultiple: formDataStore.votingRightsMultiple || 1,
      hasDividends: isNil(formDataStore.hasDividends) ? true : formDataStore.hasDividends,
      // dividendsMultiple: formDataStore.dividendsMultiple || 1,
    }),
    [
      formDataStore.hasDividends,
      formDataStore.hasVotingRights,
      formDataStore.shareClassTypeId,
      formDataStore.votingRightsMultiple,
    ]
  );

  const validationSchema = useMemo(
    () =>
      getShareClassValidationObject().pick([
        "shareClassTypeId",
        "hasVotingRights",
        "votingRightsMultiple",
        "hasDividends",
      ]),
    []
  );

  const goToShareClassesPage = useCallback((): void => {
    redirectTo(getEMPath(["ownership", "shareClasses"]));
  }, [redirectTo]);

  const submitHandler = useCallback(
    (values: SpecificsFormData, { setSubmitting }: FormikHelpers<SpecificsFormData>): Promise<void> => {
      return (
        formDataStore.shareClassId
          ? ShareClassService.update({ ...formDataStore, ...values })
          : ShareClassService.create({ ...formDataStore, ...values })
      )
        .then(({ data }) => {
          updateFormDataAction(values);

          if (values.shareClassTypeId === ShareClassTypes.preferred) {
            navigate(getEMPath(["createShareClass", "preferredPreferences"], { shareClassId: data.id }));
            return;
          }

          goToShareClassesPage();
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [formDataStore, updateFormDataAction, navigate, goToShareClassesPage]
  );

  return (
    <Formik<SpecificsFormData>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      <SpecificsForm />
    </Formik>
  );
};

export default SpecificsClass;
