import { FC, ReactNode } from "react";
import classNames from "classnames";

import Tag from "common/components/atoms/Tag/Tag";
import { CheckIcon, CloseIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Possibility.module.scss";

export type PossibilityProps = JSX.IntrinsicElements["div"] & {
  big?: boolean;
  enabled?: boolean;
  multiply?: string | number | null;
  label: "voting-rights" | "dividends" | "preferences" | ReactNode;
};

const t = createTranslation(TranslationNS.common, "shareClass.components.possibility");

const Possibility: FC<PossibilityProps> = ({ className, big, enabled, multiply, label, ...props }) => (
  <div className={classNames(classes["wrap"], { [classes["big"]]: big }, className)} {...props}>
    <div className={classes["icon-wrap"]}>
      {enabled ? (
        <CheckIcon className={`${classes["icon"]} ${classes["enabled"]}`} />
      ) : (
        <CloseIcon className={classes["icon"]} />
      )}
      {enabled && !!multiply && <Tag variant="complete">{`${multiply}x`}</Tag>}
    </div>
    {t(`labels.${label}`, { defaultValue: label })}
  </div>
);

export default Possibility;
