import { useCallback } from "react";
import axios from "axios";

export type GetStakeholderInfoDTO = {
  firstName: string;
  lastName: string;
  companyName: string;
  companyInvitationId: number;
  email: string;
  stakeholderId?: number;
};

export type PostInviteDTO = {
  companyInvitationId: number;
  stakeholderId: number;
  hasAccessToViewOwnership: boolean;
  hasAccessToViewCapTable: boolean;
  doNotShowThisAgain: boolean;
};

const useInviteShareholderService = (companyId: string) => {
  const getShareholderInfo = useCallback(async () => {
    try {
      const response = await axios.get<GetStakeholderInfoDTO>(`api/equity-management/cap-table/invite/${companyId}`);

      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }, [companyId]);

  const postInviteShareholder = useCallback(async (payload: PostInviteDTO) => {
    try {
      const response = await axios.post("/api/ownership/stakeholder/grant-company-invite", payload);

      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const postNeverShowAgain = useCallback(async (data?: PostInviteDTO) => {
    try {
      const payload: PostInviteDTO = {
        companyInvitationId: 0,
        stakeholderId: 0,
        hasAccessToViewCapTable: false,
        // TODO: remove after api will be updated
        hasAccessToViewOwnership: false,
        doNotShowThisAgain: true,
      };

      const response = await axios.post("/api/ownership/stakeholder/grant-company-invite", data ? data : payload);

      if (response.status === 200) {
        return true;
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    getShareholderInfo,
    postInviteShareholder,
    postNeverShowAgain,
  };
};

export default useInviteShareholderService;
