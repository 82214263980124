import { PeriodTimeEnum } from "common/enums/enum";

const receiverFieldsNames = {
  firstName: "firstName",
  lastName: "lastName",
  isCompanyOwned: "isCompanyOwned",
  phoneNumber: "phoneNumber",
  dateOfBirth: "dateOfBirth",
  address: "address",
  countryId: "countryId",
  companyName: "companyName",
  organizationNumber: "organizationNumber",
  email: "email",
  relationshipTypeId: "relationshipTypeId",
  businessPostAddress: "businessPostAddress",
  existingPlan: "existingPlan",
} as const;

const shareSources = {
  poolId: "poolId",
  shareClassId: "shareClassId",
  companyId: "companyId",
  sharesFromStakeholderId: "sharesFromStakeholderId",
  sharesFromStakeholderName: "sharesFromStakeholderName",
} as const;

const shareDetails = {
  reason: "reason",
  planTypeId: "planTypeId",
  numberOfShares: "numberOfShares",
  purchasePrice: "purchasePrice",
  numberOfSharesLeft: "numberOfSharesLeft",
  optionsExpirationDate: "optionsExpirationDate",
  essentialTerms: "essentialTerms",
} as const;

const stockOptionsFieldsNames = {
  optionsPrice: "optionsPrice",
  exercisePrice: "exercisePrice",
  expirationDate: "expirationDate",
  optionsExpiration: "optionsExpiration",
  optionsExpirationTimeUnit: "optionsExpirationTimeUnit",
  managerId: "managerId",
  vestingRelativeExpiry: "vestingRelativeExpiry",
} as const;

const vestingDetailsFieldsNames = {
  vestingStartsAt: "vestingStartsAt",
  vestingTypeId: "vestingTypeId",
  vestingPeriod: "vestingPeriod",
  vestingInterval: "vestingInterval",
  vestingCliff: "vestingCliff",
  vestingConditions: "vestingConditions",
  allowAcceleratedVesting: "allowAcceleratedVesting",
  approvedAt: "approvedAt",
  grantedAt: "grantedAt",
  timeVestedShares: "timeVestedShares",
  conditions: "conditions",
} as const;

const exerciseClauseFieldsNames = {
  retirement: "retirement",
  retirementTimeUnit: "retirementTimeUnit",
  disability: "disability",
  disabilityTimeUnit: "disabilityTimeUnit",
  terminationWithCause: "terminationWithCause",
  terminationWithCauseTimeUnit: "terminationWithCauseTimeUnit",
  involuntaryTermination: "involuntaryTermination",
  involuntaryTerminationTimeUnit: "involuntaryTerminationTimeUnit",
  voluntaryTermination: "voluntaryTermination",
  voluntaryTerminationTimeUnit: "voluntaryTerminationTimeUnit",
  byPassingAway: "byPassingAway",
  byPassingAwayTimeUnit: "byPassingAwayTimeUnit",
} as const;

const documentFieldsNames = {
  file: "file",
  fileAgreement: "fileAgreement",
  documentStatusId: "documentStatusId",
  sendEmailInvitation: "sendEmailInvitation",
  documentsNeedsSignature: "documentsNeedsSignature",
  signingManagerEmail: "signingManagerEmail",
  saveAsDraft: "saveAsDraft",
} as const;

export const newPlanFieldsNames = {
  ...receiverFieldsNames,
  ...shareSources,
  ...shareDetails,
  ...stockOptionsFieldsNames,
  ...vestingDetailsFieldsNames,
  ...exerciseClauseFieldsNames,
  ...documentFieldsNames,
};

export const periodVariants = [PeriodTimeEnum.YEAR, PeriodTimeEnum.MONTH, PeriodTimeEnum.DAY];
