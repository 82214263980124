import { useMemo } from "react";
import { defaultTo } from "ramda";
import * as Yup from "yup";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { BreggCompanyShareholders, OnboardingTransactionGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { LegalEntityEnum } from "../components/importOwnership.types";

const t = createTranslation(TranslationNS.validation);

export type OwnershipFormValues = {
  firstName: string;
  lastName: string;
  companyName: string;
  organizationNumber: string;
  address: string;
  numberOfShares: number | undefined;
  countryId: number;
  shareClassName: string;
  dateOfOwnership: string;
  isCompanyOwned: number;
  stakeholderId?: number | null;
  shareholderId?: number | null;
  relationshipTypeId?: number;
  issuedShareId?: number | null;
  email?: string;
  postAddress?: string;
  phoneNumber?: string;
};

export const fields = {
  firstName: "firstName",
  lastName: "lastName",
  companyName: "companyName",
  organizationNumber: "organizationNumber",
  address: "address",
  email: "email",
  numberOfShares: "numberOfShares",
  countryId: "countryId",
  shareClassName: "shareClassName",
  dateOfOwnership: "dateOfOwnership",
  isCompanyOwned: "isCompanyOwned",
  stakeholderId: "stakeholderId",
  issuedShareId: "issuedShareId",
  relationshipTypeId: "relationshipTypeId",
  averageSharePrice: "averageSharePrice",
  canDeleteStakeholder: "canDeleteStakeholder",
  postAddress: "postAddress",
  phoneNumber: "phoneNumber",
  ownership: "ownership",
  allowToDeleteRecord: "allowToDeleteRecord",
} as const;

export const initValues = (shareClasses?: OnboardingTransactionGetDTO["shareClasses"]) => {
  const selectedShareClass = shareClasses?.length === 1 ? shareClasses[0].name : "";

  return {
    [fields.firstName]: "",
    [fields.lastName]: "",
    [fields.companyName]: "",
    [fields.organizationNumber]: "",
    [fields.address]: "",
    [fields.numberOfShares]: undefined,
    [fields.countryId]: 166,
    [fields.shareClassName]: selectedShareClass,
    [fields.dateOfOwnership]: createDateString(new Date()),
    [fields.isCompanyOwned]: LegalEntityEnum.PERSON,
    [fields.stakeholderId]: undefined,
    [fields.issuedShareId]: undefined,
    [fields.relationshipTypeId]: 2,
    [fields.averageSharePrice]: undefined,
    [fields.canDeleteStakeholder]: true,
    [fields.email]: "",
    [fields.phoneNumber]: "",
    [fields.allowToDeleteRecord]: true,
  };
};

const useImportOwnershipForm = (
  shareholders: BreggCompanyShareholders[],
  withTransaction = false,
  dateOfValuation?: string,
  shareClasses?: OnboardingTransactionGetDTO["shareClasses"]
) => {
  const initialValues = useMemo<OwnershipFormValues[]>(() => {
    if (shareholders.length > 0) {
      let filteredShareholders = shareholders;

      if (withTransaction) {
        const shareholdersWithFullName = shareholders.map((shareholder) => ({
          ...shareholder,
          fullName: shareholder.firstName + "_" + shareholder.lastName + "_" + (shareholder.organizationNumber || ""),
        }));

        filteredShareholders = [];
        const fullNames: string[] = [];
        shareholdersWithFullName.forEach((shareholder) => {
          if (!fullNames.includes(shareholder.fullName)) {
            fullNames.push(shareholder.fullName);
            filteredShareholders.push(shareholder);
          }
        });
      }

      return filteredShareholders.map((shareholder) => {
        const selectedShareClass = shareClasses?.length === 1 ? shareClasses[0]?.name : shareholder.shareClassName;

        return {
          [fields.firstName]: defaultTo("", shareholder.firstName),
          [fields.lastName]: defaultTo("", shareholder.lastName),
          [fields.companyName]: defaultTo("", shareholder.companyName),
          [fields.organizationNumber]: defaultTo("", shareholder.organizationNumber),
          [fields.address]: defaultTo("", shareholder.address),
          [fields.email]: defaultTo("", shareholder.email),
          [fields.phoneNumber]: defaultTo("", shareholder.phoneNumber),
          [fields.countryId]: defaultTo(0, shareholder.countryId),
          [fields.shareClassName]: defaultTo("", selectedShareClass),
          [fields.numberOfShares]: defaultTo(0, shareholder.numberOfShares),
          [fields.isCompanyOwned]: shareholder.isCompanyOwned ? LegalEntityEnum.COMPANY : LegalEntityEnum.PERSON,
          [fields.dateOfOwnership]: defaultTo(
            dateOfValuation || createDateString(new Date()),
            shareholder.dateOfOwnership
          ),
          [fields.stakeholderId]: defaultTo(undefined, shareholder.stakeholderId),
          [fields.issuedShareId]: defaultTo(undefined, shareholder.issuedShareId),
          [fields.relationshipTypeId]: defaultTo(2, shareholder.relationshipTypeId),
          [fields.averageSharePrice]: shareholder?.averageSharePrice || undefined,
          [fields.canDeleteStakeholder]: shareholder.canDeleteStakeholder !== false,
        };
      });
    }
    return [initValues(shareClasses)];
  }, [dateOfValuation, shareClasses, shareholders, withTransaction]);

  const validationSchema = useMemo(() => {
    const objectSchema = Yup.object().shape({
      [fields.firstName]: Yup.string().when(fields.isCompanyOwned, {
        is: (legalEntityType: number) => legalEntityType === LegalEntityEnum.PERSON,
        then: Yup.string().required(t("required")),
      }),
      [fields.lastName]: Yup.string().when(fields.isCompanyOwned, {
        is: (legalEntityType: number) => legalEntityType === LegalEntityEnum.PERSON,
        then: Yup.string().required(t("required")),
      }),
      [fields.companyName]: Yup.string().when(fields.isCompanyOwned, {
        is: (legalEntityType: number) => legalEntityType === LegalEntityEnum.COMPANY,
        then: Yup.string().required(t("required")),
        otherwise: Yup.string().equals([""], t("empty")),
      }),
      [fields.isCompanyOwned]: Yup.number().min(0, t("required")),
      [fields.shareClassName]: Yup.string().required(t("required")),
      [fields.numberOfShares]: Yup.number()
        .nullable(true)
        .transform((_, val) => (+val === Number(+val) ? +val : null))
        .integer(t("integer"))
        .min(1, t("required"))
        .required(t("required")),
      [fields.dateOfOwnership]: Yup.string().nullable().required(t("required")),
      [fields.email]: Yup.string().nullable().notRequired().email(t("invalidEmail")),
      [fields.averageSharePrice]: Yup.number()
        .nullable(true)
        .test(
          "greaterThanZeroOrEqualZero",
          t("minNumber", { number: "0,000001 or 0" }),
          (value) => value === 0 || Number(value) > 0.000001 || value === null || value === undefined
        ),
    });

    return Yup.array().of(
      withTransaction
        ? objectSchema.omit([
            fields.dateOfOwnership,
            fields.numberOfShares,
            fields.shareClassName,
            fields.averageSharePrice,
          ])
        : objectSchema
    );
  }, [withTransaction]);

  return { validationSchema, initialValues };
};

export default useImportOwnershipForm;
