import { useState } from "react";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, P, Ui } from "common/components/atoms";
import { CloseIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./EmptyState.module.scss";

const t = createTranslation(TranslationNS.pages, "company.shareClasses.empty");

const EmptyState = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { hasFullAccess } = useFeatures(FEATURES.shareClasses);

  return isOpen ? (
    <div className={cn(classes.container, "mb-5")}>
      <Ui.l bold className="mb-2">
        {t("title")}
      </Ui.l>
      <P.m>{t("description")}</P.m>

      <Button
        className={classes["close-btn"]}
        size="s"
        isOnlyIcon
        variant="dark"
        onClick={setIsOpen.bind(null, false)}
        isDisabled={!hasFullAccess}
      >
        <CloseIcon />
      </Button>
    </div>
  ) : null;
};

export default EmptyState;
