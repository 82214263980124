import axios from "axios";
import { action, thunk } from "easy-peasy";

import { ProgramModel } from "./modelTypes";
import { generateFormDataPlan } from "./PlanModel";

export const programModel: ProgramModel = {
  program: null,
  isGetProgramLoading: false,
  // Actions
  setLoadingAction: action((state, { param, status }) => {
    state[param] = status;
  }),
  setProgramAction: action((state, program) => {
    state.program = program || null;
  }),
  // thunks
  getProgramThunk: thunk(async (actions, programId) => {
    try {
      actions.setLoadingAction({ param: "isGetProgramLoading", status: true });
      const res = await axios.get(`/api/program/${programId}`);
      actions.setProgramAction(res.data);
      return res;
    } catch (e) {
      actions.setProgramAction(null);
      return Promise.reject(e);
    } finally {
      actions.setLoadingAction({ param: "isGetProgramLoading", status: false });
    }
  }),
  setProgramThunk: thunk(async (_actions, program) => {
    const formData = generateFormDataPlan(program);

    return await axios.post("/api/program", formData);
  }),
  approveProgramThunk: thunk(async (_actions, program) => {
    return await axios.post("/api/program/approve", program);
  }),
};
