import React, { FC, useContext, useMemo } from "react";
import { Accordion, AccordionContext, useAccordionButton } from "react-bootstrap";
import cn from "classnames";

import { Tag, Ui } from "common/components/atoms";
import { CheckIcon, ChevronDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { SplitPlan, SplitShareholder, SplitWarrant } from "../../../../../types";
import SplitAccordionList from "./split-accordion-list";
import classes from "./styles.module.scss";

type PropsTypes = {
  eventKey: string;
  title: string;
  isConfirmed: boolean;
  realListLength: number;
  list: SplitShareholder[] | SplitPlan[] | SplitWarrant[];
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.splitDetails");

const SplitAccordion: FC<PropsTypes> = ({ eventKey, title, isConfirmed, className, realListLength, list }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const decoratedOnClick = useAccordionButton(eventKey);

  const isActive = useMemo(() => {
    if (typeof activeEventKey === "string") {
      return activeEventKey === eventKey;
    }

    if (typeof activeEventKey === "object") {
      return activeEventKey?.find((key) => key === eventKey);
    }
  }, [activeEventKey, eventKey]);

  return (
    <div className={cn(classes.splitAccordion, className)}>
      <div
        className="d-flex justify-content-between align-items-center cursor-pointer py-2 px-3"
        onClick={decoratedOnClick}
      >
        <Ui.m>{title}</Ui.m>
        <div className="d-flex">
          <Tag variant={isConfirmed ? "complete" : "information"} className="me-2">
            {isConfirmed && <CheckIcon width={14} height={14} className="me-1" />}
            {realListLength}
          </Tag>
          <ChevronDownIcon
            fontSize={24}
            color={scssVariables.foregroundMedium}
            direction={isActive ? "top" : "bottom"}
          />
        </div>
      </div>
      <Accordion.Collapse eventKey={eventKey}>
        <div className={classes.body}>
          <SplitAccordionList list={list} />
          {realListLength > 10 && realListLength > list.length && (
            <Ui.s className="p-3 text-low">
              +{realListLength - list.length} {t(`moreThan10${eventKey}`)}
            </Ui.s>
          )}
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default SplitAccordion;
