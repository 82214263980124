import { FC } from "react";
import FormCheck, { FormCheckProps } from "react-bootstrap/FormCheck";
import classNames from "classnames";

export type ToggleSwitchProps = FormCheckProps & {};
const ToggleSwitch: FC<ToggleSwitchProps> = ({ className, ...props }) => {
  return <FormCheck {...props} className={classNames(className, "p-0")} type="switch" />;
};

export default ToggleSwitch;
