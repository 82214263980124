import { FC } from "react";
import { useFormikContext } from "formik";

import { useTableBrowserStorage } from "common/components/atoms/ImportTable/TableBrowserStorage";
import StakeholdersLimitModal from "common/components/atoms/StakeholdersLimitModal/StakeholdersLimitModal";

import { OwnershipValues } from "../../import-ownership/OwnershipTable/OwnershipTable";

type PropsTypes = {
  isOpen: boolean;
  handleClose(): void;
};
const StakeholdersLimitModalWrapper: FC<PropsTypes> = (props) => {
  const { setImportTable } = useTableBrowserStorage();
  const { values } = useFormikContext<OwnershipValues[]>();

  const saveBeforeUpgrade = () => {
    setImportTable(values);
  };

  return <StakeholdersLimitModal {...props} onUpgrade={saveBeforeUpgrade} />;
};

export default StakeholdersLimitModalWrapper;
