import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikConfig } from "formik";
import * as R from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { LoaderContainer } from "common/components/atoms";
import { H } from "common/components/atoms/Typography";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useStepForm, { ThreeStepForm } from "../components/step/useStepForm";
import classes from "./ConvertibleNote.wizard.module.scss";
import ConvertibleNoteFormData from "./ConvertibleNoteFormData";
import Info from "./Info/Info";
import ReceiveConvertibleNoteContext from "./ReceiveConvertibleNoteContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleNote.receiverSide");

type ConvertibleNoteForm = ThreeStepForm & {
  approvalKey: string;
};

const Context = () => {
  const navigate = useNavigate();

  const { invitedUser } = useStoreState((state) => state);
  const { setIsDarkTheme, setIsFooterShown, setHeaderContextShown } = useStoreActions((actions) => actions.app);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const { isLoading, convertibleNoteDetails } = ReceiveConvertibleNoteContext.useStoreState((state) => state);
  const { getConvertibleNoteDetails, acceptConvertibleNoteThunk } = ReceiveConvertibleNoteContext.useStoreActions(
    (state) => state
  );

  const otherValues = useMemo(() => {
    return {
      approvalKey: String(invitedUser.invitedUser?.approvalKey),
    };
  }, [invitedUser.invitedUser?.approvalKey]);

  const docLength = convertibleNoteDetails?.documentFiles ? convertibleNoteDetails?.documentFiles.length : 0;
  const { initialValues, validationSchema } = useStepForm(
    docLength,
    !!convertibleNoteDetails?.documentsNeedsSignature,
    otherValues
  );

  const submitHandler = useCallback<FormikConfig<ConvertibleNoteForm>["onSubmit"]>(
    async (values) => {
      const request = await acceptConvertibleNoteThunk({
        approvalKey: values.approvalKey,
        signature: values.signature || undefined,
      });

      if (request) {
        setInvitedUser(null);

        navigate(getPath(["user", "dashboard"]), { replace: true });
      }
    },
    [acceptConvertibleNoteThunk, navigate, setInvitedUser]
  );

  useEffect(() => {
    if (!R.isNil(invitedUser) && !R.isNil(invitedUser.invitedUser) && !R.isNil(invitedUser?.invitedUser?.approvalKey)) {
      getConvertibleNoteDetails(invitedUser.invitedUser.approvalKey).catch(() => {
        navigate(getPath(["user", "dashboard"]), { replace: true });
      });
    }
  }, [invitedUser, getConvertibleNoteDetails, navigate]);

  useEffect(() => {
    setIsDarkTheme(true);
    setIsFooterShown(false);
    setHeaderContextShown(false);

    return () => {
      setIsDarkTheme(false);
      setIsFooterShown(true);
      setHeaderContextShown(true);
    };
  }, [setIsDarkTheme, setIsFooterShown, setHeaderContextShown]);

  return (
    <LoaderContainer loading={isLoading}>
      <PageContent className={classes["container"]} data-testid="receive-issued-equity-test-id">
        <H.s>{t("title")}</H.s>
        <Info />
        <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
          <ConvertibleNoteFormData />
        </Formik>
      </PageContent>
    </LoaderContainer>
  );
};

const ReceiveConvertibleNoteWizard = () => {
  return (
    <ReceiveConvertibleNoteContext.Provider>
      <Context />
    </ReceiveConvertibleNoteContext.Provider>
  );
};

export default ReceiveConvertibleNoteWizard;
