import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { Spinner } from "common/components/atoms";
import { PostStakeholdersInviteDTO, SuggestedStakeholder } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import InviteForm from "./invite-form";

const tValidation = createTranslation(TranslationNS.validation);

export type StakeholderFormType = Omit<SuggestedStakeholder, "suggestedAccessRoleId"> & {
  isSelected: boolean;
  roleId: number;
  email: string;
};

export type FormValues = StakeholderFormType[];

type PropsTypes = {
  onClose?: () => void;
};

const Modal: FC<PropsTypes> = ({ onClose }) => {
  const { companyId = "0" } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const { stakeholderToInvite } = useStoreState((store) => store.stakeholderInvitation);
  const { getSuggestedStakeholdersThunk, inviteStakeholdersThunk, closeMultipleInviteModal } = useStoreActions(
    (actions) => actions.stakeholderInvitation
  );

  const [stakeholdersList, setStakeholdersList] = useState<FormValues>([]);

  useEffect(() => {
    setIsLoading(true);
    getSuggestedStakeholdersThunk(+companyId).then((result) => {
      if (!result.length) {
        closeMultipleInviteModal();
      }

      const stakeholdersWithCheckboxState = result.map((stakeholder) =>
        stakeholder.email
          ? {
              ...stakeholder,
              isSelected: true,
              roleId: stakeholder.suggestedAccessRoleId,
              email: stakeholder.email,
            }
          : { ...stakeholder, isSelected: false, roleId: stakeholder.suggestedAccessRoleId, email: "" }
      );

      // remove this IF when backend will return list filtered by "invited", not by "active" user status
      if (stakeholderToInvite) {
        const listWithoutUserFromSingleInviteStep = stakeholdersWithCheckboxState.filter(
          (stakeholder) => stakeholder.stakeholderId !== stakeholderToInvite.stakeholderId
        );

        if (listWithoutUserFromSingleInviteStep.length) {
          setStakeholdersList(listWithoutUserFromSingleInviteStep);
        } else {
          closeMultipleInviteModal();
        }
        setIsLoading(false);
      } else {
        setStakeholdersList(stakeholdersWithCheckboxState);
        setIsLoading(false);
      }
    });
  }, [closeMultipleInviteModal, companyId, getSuggestedStakeholdersThunk, stakeholderToInvite]);

  const inviteStakeholder = useCallback(
    async (values: FormValues) => {
      const selectedStakeholders = values.filter((stakeholder) => stakeholder.isSelected);

      const formattedStakeholders = selectedStakeholders.map((stakeholder) => ({
        stakeholderId: stakeholder.stakeholderId,
        email: stakeholder.email,
        accessRoleId: stakeholder.roleId,
        accessRoleFeatureIds: [], // TODO can be optional?
      }));

      const postBody: PostStakeholdersInviteDTO = {
        companyId: Number(companyId),
        accessInvitations: formattedStakeholders,
      };

      const success = await inviteStakeholdersThunk({ data: postBody, onClose, companyId: Number(companyId) });

      if (success) {
        closeMultipleInviteModal();
      }
    },
    [closeMultipleInviteModal, companyId, inviteStakeholdersThunk, onClose]
  );

  const validationSchema = useMemo(
    () =>
      Yup.array().of(
        Yup.object().shape({
          isSelected: Yup.boolean(),
          email: Yup.string().when("isSelected", {
            is: true,
            then: Yup.string().email(tValidation("invalidEmail")).required(tValidation("required")),
          }),
          roleId: Yup.number().when("isSelected", {
            is: true,
            then: Yup.number().min(1, tValidation("required")).required(tValidation("required")),
          }),
        })
      ),
    []
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Formik
      onSubmit={inviteStakeholder}
      initialValues={stakeholdersList}
      enableReinitialize
      validationSchema={validationSchema}
    >
      <InviteForm />
    </Formik>
  );
};

const InviteStakeholdersModal: FC<PropsTypes> = (props) => {
  const { isMultipleInviteModalOpen } = useStoreState((store) => store.stakeholderInvitation);

  if (isMultipleInviteModalOpen) {
    return <Modal {...props} />;
  }
};

export default InviteStakeholdersModal;
