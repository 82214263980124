import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AccordionContext } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { defaultTo } from "ramda";

import { useTransactionsService } from "../../../../transactions-service";
import { SplitPreviewGetResponseDTO, Transaction, TransactionStatus } from "../../../../types";
import { splitPreviewDefault } from "../../../forms/form-sections/split-details/split-details";
import SplitOverview from "../../../forms/form-sections/split-details/split-overview";
import { TransactionContextMenuHandlers } from "../body";

type PropsTypes = {
  transaction: Transaction;
  contextMenuHandlers?: TransactionContextMenuHandlers;
  eventKey: string;
};

const SplitBody: FC<PropsTypes> = ({ transaction, contextMenuHandlers, eventKey }) => {
  const { companyId = "0" } = useParams();
  const { activeEventKey } = useContext(AccordionContext);
  const { getSplitPreview, getSplitSnapshot } = useTransactionsService(companyId);

  const [splitData, setSplitData] = useState<SplitPreviewGetResponseDTO>(splitPreviewDefault);
  const [isLoading, setIsLoading] = useState(true);

  const isConfirmed = defaultTo(false, transaction?.statusId === TransactionStatus.Confirmed);

  const getSplitData = useCallback(async () => {
    setIsLoading(true);
    if (isConfirmed) {
      setSplitData((await getSplitSnapshot(transaction?.transactionId)) || splitPreviewDefault);
    } else {
      setSplitData((await getSplitPreview(transaction?.multiplier || 1)) || splitPreviewDefault);
    }

    setIsLoading(false);
  }, [getSplitPreview, getSplitSnapshot, isConfirmed, transaction?.multiplier, transaction?.transactionId]);

  useEffect(() => {
    // check if accordion body is opened
    if (eventKey === activeEventKey) {
      getSplitData();
    }
  }, [activeEventKey, eventKey, getSplitData]);

  return (
    <SplitOverview
      transaction={transaction}
      splitPreview={splitData}
      isConfirmed={isConfirmed}
      isLoading={isLoading}
      description={transaction?.description}
      multiplier={transaction?.multiplier}
      contextMenuHandlers={contextMenuHandlers}
    />
  );
};

export default SplitBody;
