import { ReactNode, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import AccessMiddlewareEM from "app/middleware/AccessMiddleware/AccessMiddlewareEM";
import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import {
  AccessIcon,
  AgreementIcon,
  BankIcon,
  DocumentsIcon,
  EquityIcon,
  ExercisingIcon,
  InformationCircleOutlinedIcon,
  PieChartIcon,
  PoolsIcon,
  ShareClassesIcon,
  TaxIcon,
  TransactionsIcon,
  UsersIcon,
} from "common/icons/svg";
import MainLayout from "common/layout/MainLayout/MainLayout";
import Menu from "common/layout/MainLayout/Menu/Menu";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import GettingStartedGuide from "./getting-started-guide/GettingStartedGuide";
import useGettingStartedGuide from "./getting-started-guide/useGettingStartedGuide";
import OwnershipRouter from "./ownership/OwnershipRouter";
import PlansRouter from "./plans/PlansRouter";
import SettingRouter from "./settings/SettingRouter";

export type CompanyRouterItem = {
  path: string;
  component: ReactNode;
};

const t = createTranslation(TranslationNS.pages, "company.sideBar");

const EquityManagementRouter = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { getShareClassesCompanyThunk } = useStoreActions((actions) => actions.shareClassModel);

  const isCompanyMenuOpen = useStoreState((state) => state.company.isCompanyMenuOpen);
  const companies = useStoreState((state) => state.account?.user?.companies);

  const notCompletedTooltip = useMemo(() => {
    const notCompletedOnboarding = (companies || []).find((el) => el.id === Number(companyId))?.notCompletedOnboarding;
    if (notCompletedOnboarding) {
      return <div style={{ width: 148, textAlign: "left" }}>Finish company registration to access this page.</div>;
    }
    return undefined;
  }, [companies, companyId]);

  const { progress, PieChartProgress } = useGettingStartedGuide();

  const companyMenus = useMemo(() => {
    const additionalSettingsMenuMenu = [];
    if (progress !== 100) {
      additionalSettingsMenuMenu.push({
        to: getEMPath(["gettingStartedGuide"], { companyId }),
        icon: <PieChartProgress />,
        label: t("settings.routes.quickstartGuide", { progress }),
      });
    }

    return {
      equityPlans: [
        {
          to: getEMPath(["ownership", "capTable"], { companyId }),
          icon: <PieChartIcon />,
          label: t("ownership.routes.capTable"),
        },
        {
          to: getEMPath(["plans", "poolsAndPrograms"], { companyId }),
          icon: <PoolsIcon />,
          label: t("equityPlans.routes.poolsAndPrograms"),
        },
        {
          to: getEMPath(["plans", "agreements"], { companyId }),
          icon: <AgreementIcon />,
          label: t("equityPlans.routes.agreements"),
          isDisabled: !!notCompletedTooltip,
          tooltip: notCompletedTooltip,
        },
        {
          to: getEMPath(["plans", "exercising"], { companyId }),
          icon: <ExercisingIcon />,
          label: t("equityPlans.routes.exercise"),
          isDisabled: !!notCompletedTooltip,
          tooltip: notCompletedTooltip,
        },
        {
          to: getEMPath(["ownership", "financialInstruments"], { companyId }),
          icon: <EquityIcon />,
          label: t("equityPlans.routes.issueEquity"),
          isDisabled: !!notCompletedTooltip,
          tooltip: notCompletedTooltip,
        },
        {
          to: getEMPath(["ownership", "transactions"], { companyId }),
          icon: <TransactionsIcon />,
          label: t("ownership.routes.transactions"),
          isDisabled: !!notCompletedTooltip,
          tooltip: notCompletedTooltip,
        },
        {
          to: getEMPath(["ownership", "valuation"], { companyId }),
          icon: <TaxIcon />,
          label: t("ownership.routes.valuation"),
        },
        {
          to: getEMPath(["ownership", "shareClasses"], { companyId }),
          icon: <ShareClassesIcon />,
          label: t("ownership.routes.shareClasses"),
        },
        {
          to: getEMPath(["ownership", "stakeholdersManagement"], { companyId }),
          icon: <UsersIcon />,
          label: t("ownership.routes.stakeholdersManagement"),
        },
        {
          to: getEMPath(["ownership", "documents"], { companyId }),
          icon: <DocumentsIcon />,
          label: t("ownership.routes.documents"),
        },
      ],
      settings: [
        {
          to: getEMPath(["settings", "companyInformation"], { companyId }),
          icon: <InformationCircleOutlinedIcon />,
          label: t("settings.routes.companyInformation"),
        },
        {
          to: getEMPath(["settings", "bankDetails"], { companyId }),
          icon: <BankIcon />,
          label: t("settings.routes.bankDetails"),
        },
        {
          to: getEMPath(["settings", "accessControl"], { companyId }),
          icon: <AccessIcon />,
          label: t("settings.routes.accessControl"),
        },
        ...additionalSettingsMenuMenu,
      ],
    };
  }, [PieChartProgress, companyId, notCompletedTooltip, progress]);

  useEffect(() => {
    if (companyId) {
      getShareClassesCompanyThunk(Number(companyId));
    }
  }, [companyId, getShareClassesCompanyThunk]);

  return (
    <MainLayout>
      {isCompanyMenuOpen && (
        <MainLayout.Sidebar id="company-ownership-sidebar" isPlanInfoWidgetVisible>
          <Menu title={undefined} items={companyMenus.equityPlans} />
          <Menu title={undefined} items={companyMenus.settings} />
        </MainLayout.Sidebar>
      )}

      <Routes>
        <Route element={<AccessMiddlewareEM />}>
          <Route path={ROUTER_V2.equityManagement.ownership.relativeFull} element={<OwnershipRouter />} />
          <Route path={ROUTER_V2.equityManagement.plans.relativeFull} element={<PlansRouter />} />
          <Route path={ROUTER_V2.equityManagement.gettingStartedGuide} element={<GettingStartedGuide />} />
          <Route path={ROUTER_V2.equityManagement.settings.relativeFull} element={<SettingRouter />} />

          <Route path="*" element={<Navigate to={ROUTER_V2.equityManagement.ownership.capTable.base} />} />
        </Route>
      </Routes>
    </MainLayout>
  );
};

export default EquityManagementRouter;
