import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import { P, Ui } from "common/components/atoms/Typography";
import { createTranslation, TranslationNS } from "translation";

import classes from "./NoManagePlansData.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const NoManagePlansData: FC = () => {
  const navigate = useNavigate();

  const { hasFullAccess } = useFeatures(FEATURES.poolsAndPrograms);

  const redirectToCreatePool = () => {
    navigate(getEMPath(["createPool", "basic"]));
  };

  return (
    <div className={classes["empty"]}>
      <Ui.l bold className="mb-2">
        {t("empty.title")}
      </Ui.l>

      <P.m className="mb-2">{t("empty.description")}</P.m>

      <P.m className="mb-4">{t("empty.descriptionEnd")}</P.m>

      <Button onClick={redirectToCreatePool} isDisabled={!hasFullAccess} tooltipMessage={tCommon("tooltip")}>
        {t("empty.createFirstPool")}
      </Button>
    </div>
  );
};

export default NoManagePlansData;
