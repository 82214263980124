import { useMemo } from "react";
import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import TextArea from "common/components/atoms/TextArea/TextArea";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData, fields } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.basics");

const ProgramDescriptionField = () => {
  const { handleBlur, values, errors, touched, handleChange } = useFormikContext<CreateProgramFormData>();

  const answer = useMemo(() => ({ render: () => t.el("purpose.answer") }), []);

  return (
    <Helper.Question questionId="description" type="input" answer={answer}>
      <TextArea
        rows={6}
        isOptional
        label={t("purpose.label")}
        name={fields.purpose}
        value={values.purpose || ""}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.purpose}
        isTouched={touched.purpose}
      />
    </Helper.Question>
  );
};

export default ProgramDescriptionField;
