import { useCallback, useMemo } from "react";
import axios from "axios";
import * as R from "ramda";
import * as Yup from "yup";

import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../../IssueEquityContext";

const tValidation = createTranslation("validation");
const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.conversion.settle");

const useSettleWarrantForm = (handleClosePanel: () => void) => {
  const { warrantDetails } = IssueEquityContext.useStoreState((state) => state);

  const { getIssueEquityThunk } = IssueEquityContext.useStoreActions((actions) => actions);

  const initialValues = useMemo(() => {
    return {
      numberOfShares: R.defaultTo(0, warrantDetails?.numberOfShares),
      settleDate: undefined,
      shareClassId: R.defaultTo(0, warrantDetails?.shareClassId),
      documentStatus: 1,
      documents: [],
      isNeedSignatureChecked: false,
      isNeedEmailInvitationChecked: false,
    };
  }, [warrantDetails]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        numberOfShares: Yup.number()
          .integer(tValidation("integer"))
          .min(1, tValidation("minNumber", { number: 1 }))
          .max(
            R.defaultTo(0, warrantDetails?.numberOfShares),
            tValidation("maxNumber", {
              number: R.defaultTo(0, warrantDetails?.numberOfShares),
            })
          )
          .required(tValidation("required")),
        documentStatus: Yup.number().required(tValidation("requiredSection")),
        settleDate: Yup.string().required(tValidation("required")),
        shareClassId: Yup.number().required(tValidation("required")).min(1, tValidation("required")),
      }),
    [warrantDetails?.numberOfShares]
  );

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const formData = new FormData();
        formData.append("WarrantId", String(warrantDetails?.warrantId));
        formData.append("CompanyId", String(warrantDetails?.companyId));
        formData.append("NumberOfShares", String(values.numberOfShares));
        formData.append("SettleDate", String(values.settleDate));
        formData.append("ShareClassId", String(values.shareClassId));
        formData.append("DocumentStatusId", String(values.documentStatus));
        formData.append("DocumentsNeedsSignature", String(values.isNeedSignatureChecked));
        formData.append("SendInvitationEmail", String(values.isNeedEmailInvitationChecked));

        if (!R.isEmpty(values.documents)) {
          values.documents.forEach((el: any) => formData.append("FilesData.Files", el));
        }

        const request = await axios.post("/api/equity-management/issue-equity/warrant/settle", formData);

        if (request.status === 200) {
          getIssueEquityThunk(Number(warrantDetails?.companyId));
          notify(t("successMessage"), true, "success", 10000);
          handleClosePanel();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [getIssueEquityThunk, handleClosePanel, warrantDetails?.companyId, warrantDetails?.warrantId]
  );

  return { initialValues, validationSchema, onSubmit };
};

export default useSettleWarrantForm;
