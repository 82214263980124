import { FC, ReactNode, useCallback, useMemo } from "react";
import cn from "classnames";

import LinkHelpText from "../LinkHelpText/LinkHelpText";
import classes from "./ToggleButton.module.scss";

type ToggleVariant = {
  title: string | ReactNode;
  value: string | number;
  tooltipMessage?: string | ReactNode;
};

type PropsTypes = {
  firstVariant: ToggleVariant;
  secondVariant: ToggleVariant;
  selected: string | number;
  onClick: (selectedValue: string | number) => void;
  isDisabled?: boolean;
  className?: string;
  dataTestId?: string;
};

const ToggleButton: FC<PropsTypes> = ({
  firstVariant,
  secondVariant,
  selected,
  onClick,
  isDisabled,
  className,
  dataTestId,
}) => {
  const firstActive = useMemo(() => {
    return firstVariant.value === selected;
  }, [selected, firstVariant]);

  const handleActiveFirst = useCallback(() => {
    if (!isDisabled) {
      onClick(firstVariant.value);
    }
  }, [firstVariant.value, isDisabled, onClick]);

  const handleActiveSecond = useCallback(() => {
    if (!isDisabled) {
      onClick(secondVariant.value);
    }
  }, [isDisabled, onClick, secondVariant.value]);

  return (
    <span
      className={cn(classes["toggle-button"], { [className as string]: className, [classes["disabled"]]: isDisabled })}
      // onClick={handleClick}
      aria-disabled={isDisabled}
      data-testid={dataTestId}
    >
      <span
        className={cn(firstActive ? classes["option_active"] : classes["option"], {
          [classes["disabled"]]: isDisabled,
        })}
        onClick={handleActiveFirst}
      >
        {firstVariant.title}
      </span>

      <span
        onClick={handleActiveSecond}
        className={cn(firstActive ? classes["option"] : classes["option_active"], {
          [classes["disabled"]]: isDisabled,
        })}
      >
        {secondVariant?.tooltipMessage ? (
          <LinkHelpText trigger="click" title="" content={secondVariant.tooltipMessage}>
            {secondVariant.title}
          </LinkHelpText>
        ) : (
          secondVariant.title
        )}
      </span>
    </span>
  );
};

export default ToggleButton;
