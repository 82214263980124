import { FC, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

import { InfoAlert, P, Ui } from "common/components/atoms/index";
import { InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

type PropsTypes = {
  isSubmitting: boolean;
  className?: string;
};

const t = createTranslation(TranslationNS.common, "atoms.longRequestInfo");

const SlowRequestInfo: FC<PropsTypes> = ({ isSubmitting, className }) => {
  const [showInfo, setShowInfo] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isSubmitting) {
      timerRef.current = setTimeout(() => setShowInfo(true), 4000);
    }

    if (timerRef.current && !isSubmitting) {
      clearTimeout(timerRef.current);
      setShowInfo(false);
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [isSubmitting]);

  if (!showInfo) return null;

  return (
    <motion.div initial={{ y: -100, opacity: 0 }} animate={{ y: 0, opacity: 1 }} exit={{ y: 100, opacity: 0 }}>
      <InfoAlert
        type="Warning"
        className={className}
        customContent={
          <div className="d-flex">
            <InformationCircleIcon fontSize={24} className="me-2" color={scssVariables.foregroundLow} />
            <div>
              <Ui.m bold className="mb-half">
                {t("title")}
              </Ui.m>
              <P.s>{t("content")}</P.s>
            </div>
          </div>
        }
      />
    </motion.div>
  );
};

export default SlowRequestInfo;
