import { FC } from "react";
import { useFormikContext } from "formik";
import * as R from "ramda";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import TextField from "common/components/atoms/TextField/TextField";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { FormGeneralValues } from "../general";

const translation = createTranslation(TranslationNS.pages, "companyProfile.general");

const HeadquartersPart: FC<{ isTestsPurposes?: boolean }> = ({ isTestsPurposes }) => {
  const countriesDropDown = useStoreState((state) => state.common.dropdowns)?.countries;

  const { values, handleChange, setFieldValue } = useFormikContext<FormGeneralValues>();
  return (
    <div>
      <div className="d-flex flex-column mb-10">
        <h4 className="m-0 head-xxs mb-3">{translation("headquarters")}</h4>

        <div className="d-flex">
          <Dropdown
            isOptional
            data-testid="dropdown-toggle-headquarters-location-test-id"
            className="w-100"
            isSearchable={!isTestsPurposes}
            searchType="startsWith"
            label={translation("headquartersCountry.label")}
            searchPlaceholder={translation("headquartersCountry.placeholder")}
            options={!R.isNil(countriesDropDown) ? countriesDropDown?.map((el) => el.name) : []}
            selectedValue={
              !R.isNil(countriesDropDown)
                ? countriesDropDown?.find((el) => el.id === R.defaultTo(0, values?.headquartersCountryId))?.name
                : ""
            }
            onChange={(country: string) => {
              const selectedCountry = countriesDropDown?.find((el) => el.name === country);

              setFieldValue("headquartersCountryId", selectedCountry?.id);
            }}
          />

          <TextField
            isOptional
            className="ms-3 w-100"
            type="city"
            label={translation("headquartersCity.label")}
            value={String(values.headquartersCity)}
            onChange={handleChange("headquartersCity")}
          />
        </div>
      </div>
    </div>
  );
};

export default HeadquartersPart;
