import { ChangeEvent } from "react";
import { useFormikContext } from "formik";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import FileUploadSection from "common/components/atoms/FileUploadSection/FileUploadSection";
import TextField from "common/components/atoms/TextField/TextField";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { FormGeneralValues } from "../general";

const translation = createTranslation(TranslationNS.pages, "companyProfile.general");
const TEXT_INPUT_WIDTH = 250;

const MainPart = () => {
  const { hasFullAccess } = useFeatures(FEATURES.attract);
  const { values, errors, touched, handleChange, setFieldValue } = useFormikContext<FormGeneralValues>();
  return (
    <div>
      <TextField
        data-testid="company-name-test-id"
        className="mb-5"
        type="companyName"
        value={values.name}
        label={translation("name.label")}
        error={errors.name}
        isTouched={touched.name}
        onChange={handleChange("name")}
      />

      <h4 className="ui-m">{translation("companyLogo.label")}</h4>

      <FileUploadSection
        buttonText={translation(values.logoPreview ? "companyLogo.buttonReplace" : "companyLogo.buttonUpload")}
        imageWidth={200}
        descriptionText=""
        circularImage={false}
        alternativeText="logo"
        defaultImageType="company-logo"
        previewUrl={values.logoPreview}
        setFileToUpload={(file) => {
          setFieldValue("logoFile", file);
        }}
        acceptedFormats=".jpg, .png, .svg"
        isDisabled={!hasFullAccess}
      />

      <TextField
        isOptional
        className="my-5"
        type="organization"
        style={{ width: TEXT_INPUT_WIDTH }}
        value={values.organizationNumber}
        label={translation("organizationNumber.label")}
        onChange={handleChange("organizationNumber")}
        error={errors.organizationNumber}
        isTouched={touched.organizationNumber}
      />

      <DatePicker
        isDateOnlyString
        className="mb-5"
        date={values?.foundedAt ? new Date(values?.foundedAt) : undefined}
        label={translation("foundedAt.label")}
        onChange={(date) => {
          setFieldValue("foundedAt", date);
        }}
      />

      <TextField
        type="number"
        className="mb-10"
        style={{ width: TEXT_INPUT_WIDTH }}
        label={translation("numberOfEmployees.label")}
        value={String(values.numberOfEmployees)}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (Number(e.target.value) < 0) {
            notify(translation("notify.notNegativeNumberInField"), true, "error");
            return;
          }
          setFieldValue("numberOfEmployees", Number(e.target.value));
        }}
      />
    </div>
  );
};

export default MainPart;
