import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { assocPath, defaultTo, isEmpty } from "ramda";

import { Dropdown } from "common/components/atoms";
import { CompanyBuildingIcon, UserIcon } from "common/icons/svg";
import { highlightSubstring } from "common/utils/HighliteText";
import { useStoreState } from "store/store";
import { StakeholderWithAgreement } from "store/types";

import ManagePlansContext from "../../../managePlansContext";
import classes from "../AgreementsAppliedFilters.module.scss";

const ItemElement: FC<{ data: StakeholderWithAgreement; value: string }> = ({ data, value }) => {
  const email = data.stakeholderEmail ? `(${data.stakeholderEmail})` : "";

  return (
    <div className="d-flex justify-content-between" style={{ color: "#2B011F" }}>
      <span>
        {highlightSubstring(
          data.isCompanyOwned ? `${data.stakeholderCompanyName} - ${data.representedBy}` : `${data.representedBy}`,
          value
        )}{" "}
        {email}
      </span>
      <span>{data.isCompanyOwned ? <CompanyBuildingIcon fontSize={20} /> : <UserIcon fontSize={20} />}</span>
    </div>
  );
};

const AgreementsStakeholderSearch = () => {
  const { companyId = "0" } = useParams();

  const [stakeholders, setStakeholders] = useState<{ id: string | number; name: string; element: ReactNode }[]>([]);

  const ownershipPlans = useStoreState((state) => state.company.ownershipPlans);
  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const setSavedAppliedFilters = ManagePlansContext.useStoreActions((actions) => actions.setSavedAppliedFilters);

  const appliedFilters = savedAppliedFilters?.[companyId];

  const handleHighlight = useCallback(
    (search: string) => {
      const transformedData = (ownershipPlans?.stakeholdersWithAgreements || []).map((data) => ({
        id: data.stakeholderId || "",
        name: data.isCompanyOwned ? `${data.stakeholderCompanyName} (${data.representedBy})` : `${data.representedBy}`,
        element: <ItemElement data={data} value={search} />,
      }));

      setStakeholders(transformedData);
    },
    [ownershipPlans?.stakeholdersWithAgreements]
  );

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const selectedStakeholder = ownershipPlans?.stakeholdersWithAgreements?.find(
        (el) => el.stakeholderId === +e.target.value
      );

      setSavedAppliedFilters(
        assocPath([companyId, "selectedStakeholder"], defaultTo(null, selectedStakeholder), savedAppliedFilters)
      );
    },
    [companyId, ownershipPlans?.stakeholdersWithAgreements, savedAppliedFilters, setSavedAppliedFilters]
  );

  useEffect(() => {
    if (!isEmpty(ownershipPlans?.stakeholdersWithAgreements)) {
      const transformedData = (ownershipPlans?.stakeholdersWithAgreements || []).map((data) => ({
        id: data.stakeholderId || "",
        name: data.isCompanyOwned ? `${data.stakeholderCompanyName} (${data.representedBy})` : `${data.representedBy}`,
        element: <ItemElement data={data} value="" />,
      }));

      setStakeholders(transformedData);
    }
  }, [ownershipPlans?.stakeholdersWithAgreements]);

  return (
    <div className="ms-1" style={{ height: 32 }}>
      <Dropdown
        isClearable
        isSearchable
        showSearchIcon
        optionsIsObject
        isSearchComponent
        withoutToggleChevron
        placeholder="Search stakeholders"
        selectedValue={appliedFilters?.selectedStakeholder?.stakeholderId}
        className={classes["stakeholder-dropdown"]}
        options={stakeholders}
        onChange={handleChange}
        searchChangeCallback={handleHighlight}
      />
    </div>
  );
};

export default AgreementsStakeholderSearch;
