import { FC, useCallback } from "react";
import Image from "react-bootstrap/Image";
import axios from "axios";
import cn from "classnames";
import { Formik } from "formik";

import Button from "common/components/atoms/Button/Button";
import DatePicker, { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import TextField from "common/components/atoms/TextField/TextField";
import { H, Ui } from "common/components/atoms/Typography";
import Card from "common/components/molecules/Card/Card";
import BoyIcon from "common/icons/boy-icon.png";
import StepWrapper, { StepWrapperProps } from "common/layout/WizardSinglePage/StepWrapper/StepWrapper";
import stepWrapperClasses from "common/layout/WizardSinglePage/StepWrapper/StepWrapper.module.scss";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { EquityManagementOnboardingDTO, OnSubmit } from "../../types";
import { valuationFields, valuationSchema } from "./shema";
import { ValuationGetDTO, ValuationPostDTO } from "./types";
import classes from "./valuation.module.scss";

type PropsTypes = {
  prefillInitialValues: ValuationGetDTO | null;
  onSubmit: OnSubmit;
  onSkip: () => void;
  companyId?: number | null;
} & StepWrapperProps;

const t = createTranslation(TranslationNS.pages, "managerOnboarding.valuationStep");

const Valuation: FC<PropsTypes> = ({ prefillInitialValues, stepRef, isDisabled, onSubmit, onSkip, companyId }) => {
  const initialValues: ValuationPostDTO = {
    companyId: companyId || 0,
    valuation: prefillInitialValues?.valuation || 0,
    validFrom: prefillInitialValues?.validFrom || createDateString(new Date()),
  };

  const handleCompanySubmit = useCallback(
    async (values: ValuationPostDTO /*, actions: FormikHelpers<ValuationPostDTO>*/) => {
      try {
        const response = await axios.post<EquityManagementOnboardingDTO>("/api/onboarding/valuation", values);
        onSubmit(response.data);
        notify(t("successNotification"), true, "success");
      } catch (e) {
        console.log(e);
      }
    },
    [onSubmit]
  );

  const handleSkipStep = useCallback(() => {
    onSkip();
    // notify(t("successNotification"), true, "success");
  }, [onSkip]);

  return (
    <div className="d-flex gap-2">
      <StepWrapper className={classes.valuationBlock} stepRef={stepRef} isDisabled={isDisabled}>
        <Formik
          enableReinitialize={true}
          initialValues={prefillInitialValues ? { ...prefillInitialValues, companyId: companyId || 0 } : initialValues}
          validationSchema={valuationSchema}
          onSubmit={handleCompanySubmit}
        >
          {({ values, errors, touched, handleChange, setFieldValue, isSubmitting, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Ui.l bold className="mb-1">
                  {t("subtitle")}
                </Ui.l>
                <H.m className="mb-1">{t("title")}</H.m>
                <Ui.s style={{ maxWidth: "560px", lineHeight: 1.6 }} className="text-grey mb-3">
                  {t("paragraph")}
                </Ui.s>

                <div className="d-flex mb-3">
                  <TextField
                    className="me-2 w-100"
                    label={t("valuation")}
                    placeholder="30 000"
                    value={values.valuation || ""}
                    isTouched={touched.valuation}
                    error={errors.valuation}
                    onChange={handleChange(valuationFields.valuation)}
                  />
                  <DatePicker
                    label={t("date")}
                    date={values.validFrom || new Date()}
                    isDateOnlyString={true}
                    isTouched={touched.validFrom}
                    error={errors.validFrom}
                    onChange={(date) => setFieldValue(valuationFields.validFrom, date)}
                  />
                </div>

                <div className="d-flex">
                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    isDisabled={!!prefillInitialValues?.valuation}
                    className="me-2"
                  >
                    {t("submitBtn")}
                  </Button>
                  <Button
                    variant="tertiary"
                    isLoading={isSubmitting}
                    isDisabled={!!prefillInitialValues?.valuation}
                    onClick={handleSkipStep}
                  >
                    {t("setTo0Btn")}
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </StepWrapper>
      <div className={cn({ [stepWrapperClasses["disabled"]]: isDisabled })}>
        <Card
          className={classes.card}
          circleBg={scssVariables.primary1}
          bgColor={scssVariables.secondary1}
          cardTitle={t("helpCard.title")}
          icon={
            <Image
              width={140}
              height={140}
              src={BoyIcon}
              style={{
                position: "absolute",
                transform: "translate(-10%, -10%)",
              }}
            />
          }
          cardDescription={t("helpCard.content")}
        />
      </div>
    </div>
  );
};

export default Valuation;
