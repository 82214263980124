import React, { MouseEventHandler, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { isNil } from "ramda";
import * as Yup from "yup";

import { Button, H, ModalInfo, TextArea } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext from "../../ExercisingContext";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.exercising.decline"),
  createTranslation(TranslationNS.validation),
];

const DeclineExercising = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const requestLoading = ExercisingContext.useStoreState((state) => state.requestLoading);
  const declineExercising = ExercisingContext.useStoreState((state) => state.declineExercising);
  const setDeclineExercising = ExercisingContext.useStoreActions((actions) => actions.setDeclineExercising);
  const setLoadingRequest = ExercisingContext.useStoreActions((actions) => actions.setLoadingRequest);
  const getMenageExercisingThunk = ExercisingContext.useStoreActions((actions) => actions.getMenageExercisingThunk);

  const initialValues = useMemo(
    () => ({
      comment: "",
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        comment: Yup.string()
          .max(
            100,
            tValidation("maxCharacters", {
              number: 100,
            })
          )
          .required(tValidation("required")),
      }),
    []
  );

  const handleClose = useCallback(() => {
    setDeclineExercising(null);
  }, [setDeclineExercising]);

  const handleDecline = useCallback(
    async (values: { comment: string }) => {
      setLoadingRequest(true);

      try {
        const formData = new FormData();
        formData.append("ExerciseRequestId", String(declineExercising?.exerciseRequestId));
        formData.append("Comment", values.comment);

        const request = await axios.post("/api/ownership/manage-exercising/decline", formData);

        if (request.status === 200) {
          handleClose();
          getMenageExercisingThunk(Number(companyId));
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [companyId, declineExercising?.exerciseRequestId, getMenageExercisingThunk, handleClose, setLoadingRequest]
  );

  const { values, handleChange, errors, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleDecline,
  });

  return (
    <ModalInfo
      show={!isNil(declineExercising)}
      handleClose={handleClose}
      header={
        <H.xxxs>
          {t("title", {
            name: declineExercising?.requestorName?.split(" ")?.[0],
          })}
        </H.xxxs>
      }
      footer={
        <>
          <Button
            isLoading={requestLoading}
            isDisabled={requestLoading}
            onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
          >
            {t("decline")}
          </Button>
          <Button variant="secondary" isDisabled={requestLoading} onClick={handleClose}>
            {t("cancel")}
          </Button>
        </>
      }
    >
      <TextArea
        value={values.comment}
        label={t("label")}
        isTouched
        error={errors.comment}
        meta={`${values.comment.length}/100`}
        onChange={handleChange("comment")}
      />
    </ModalInfo>
  );
};

export default DeclineExercising;
