import { Navigate, Outlet, useLocation } from "react-router-dom";

import { useStoreState } from "../../store/store";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { getPath } from "../Router/RouterHelper";
import { ROUTER_V2 } from "../Router/RouterV2.types";

const Root = () => {
  const location = useLocation();
  const userStore = useStoreState((state) => state.account.user);

  const isNotOnboarded = userStore && !userStore.isOnboarded;
  const dontShowHeader = location?.pathname !== "/" && location?.pathname !== ROUTER_V2.signIn.base;

  return location.pathname === "/" ? (
    <Navigate to={getPath(["user", "dashboard"])} />
  ) : (
    <>
      {!isNotOnboarded && dontShowHeader && <Header />}
      <Outlet />
      {dontShowHeader && <Footer />}
    </>
  );
};
export default Root;
