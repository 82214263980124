import React, { Fragment, ReactNode, useCallback, useState } from "react";
import { AxisProps } from "@nivo/axes";
import { DotsItem } from "@nivo/core";
import { Datum, Layer, LineSvgProps, Point, ResponsiveLine } from "@nivo/line";
import * as R from "ramda";

import { AreaLayerProps, CustomComputedSerie, singleStepChartProps } from "common/types/Charts.types";
import { scssVariables } from "common/utils/constants";

import { Ui } from "../Typography";

type ModifiedPoint = Point & { isLast?: boolean; data: Datum };

type StepMonoChartProps = {
  height?: number;
  isInteractive?: boolean;
  withCustomPoints?: boolean;
  selectedPointsLabel?: {
    point: string;
    description?: (point: Point) => ReactNode;
  }[];
  data: singleStepChartProps[];
  axisXFormatter?: (value: AxisProps["format"]) => void;
};

const theme = {
  axis: {
    ticks: {
      text: {
        fontSize: 12,
        fill: scssVariables["additional1600"],
      },
    },
    legend: {
      text: {
        fontSize: 12,
        fill: scssVariables["additional1600"],
      },
    },
  },
};

const StepMonoChart: React.FC<StepMonoChartProps & LineSvgProps> = ({
  data,
  height = 350,
  axisXFormatter,
  withCustomPoints,
  selectedPointsLabel,
  isInteractive = true,
  ...props
}) => {
  const [current, setCurrent] = useState<ModifiedPoint>({} as ModifiedPoint);

  const handleHover = useCallback(
    (point: ModifiedPoint) => {
      if (point.index === data[0].data.length - 1) {
        setCurrent({ ...point, isLast: true });
      } else {
        setCurrent({ ...point });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCurrent]
  );

  function CustomPoints({ points }: { points: ModifiedPoint[] }) {
    const shownPoints = points.filter((el) => el.data?.additionalDot);

    return (
      <g>
        {shownPoints.map((point) => {
          return (
            <Fragment key={point.id}>
              <foreignObject width={110} height={60} x={point.x - 20} y={point.y - 70} style={{ position: "relative" }}>
                <Ui.xs bold style={{ color: scssVariables.information500 }}>
                  {point.data?.additionalDot?.title}
                </Ui.xs>
                {point.data?.additionalDot?.icon}
              </foreignObject>

              <DotsItem
                size={10}
                x={point.x}
                y={point.y}
                borderWidth={1}
                labelYOffset={0}
                datum={point.data}
                color={point.color}
                borderColor={point.borderColor}
              />
            </Fragment>
          );
        })}
      </g>
    );
  }

  const DashedLine = ({ series, lineGenerator, xScale, yScale }: AreaLayerProps) => {
    return series.map(({ id, data, color, strokeWidth, strokeDasharray }: CustomComputedSerie) => {
      return (
        <path
          key={id}
          fill="none"
          stroke={color}
          d={
            lineGenerator(
              data.map((d: Point) => ({
                x: xScale(d.data.x),
                y: yScale(d.data.y),
              }))
            ) as string
          }
          style={{
            strokeWidth: strokeWidth || 2,
            strokeDasharray: strokeDasharray || "4, 4",
          }}
        />
      );
    });
  };

  return (
    <div
      style={{
        height,
        width: "100%",
      }}
    >
      <ResponsiveLine
        useMesh
        data={data}
        theme={theme}
        curve="stepAfter"
        colors={{ datum: "color" }}
        margin={{ bottom: 10, left: 40, right: 40, top: 5 }}
        layers={
          [
            "axes",
            "mesh",
            "slices",
            DashedLine,
            withCustomPoints && CustomPoints,
            () => <ActivePointLayer point={current} />,
          ] as Layer[]
        }
        tooltip={() => null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 20,
          format: axisXFormatter,
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: 5,
          tickPadding: 15,
        }}
        onMouseMove={isInteractive ? handleHover : undefined}
        {...props}
      />
    </div>
  );
};

function ActivePointLayer({ point }: { point: ModifiedPoint }) {
  if (R.isEmpty(point) || point.serieId !== "ScheduleChart") {
    return null;
  }

  const isFirstPoint = point.index === 0;

  return (
    <>
      <foreignObject
        width={180}
        height={100}
        x={point.x - (isFirstPoint ? 0 : 80)}
        y={point.y - (point.isLast ? 10 : 50)}
      >
        <p
          className="m-0 p-0 ui-xs fw-bold"
          style={{
            color: point.serieColor,
          }}
        >
          {point.data.content}
        </p>
      </foreignObject>
      <DotsItem
        size={16}
        x={point.x}
        y={point.y}
        borderWidth={1}
        labelYOffset={0}
        datum={point.data}
        color={point.color}
        borderColor={point.borderColor}
      />
    </>
  );
}

export default StepMonoChart;
