import { FC } from "react";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import { EquityIcon, ExercisingIcon, TimeHourglassIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext from "../../ExercisingContext";
import classes from "./ExercisingOverview.module.scss";

const t = createTranslation(TranslationNS.pages, "company.exercising.overview");

const ExercisingOverview: FC = () => {
  const overview = ExercisingContext.useStoreState((state) => state.overview);

  return (
    <div className={classes["container"]}>
      <div className={classNames("px-4", classes["single"])}>
        <div>
          <Ui.s color="foregroundLow" className="fw-500 mb-2">
            {t("exercisePending")}
          </Ui.s>
          <Ui.xxl bold>{overview.pendingApprovalTotal}</Ui.xxl>
        </div>

        <div
          className={classNames(classes["circle"], {
            [classes["info"]]: true,
          })}
        >
          <TimeHourglassIcon fontSize={32} strokeWidth={1} />
        </div>
      </div>

      <div className={classNames("px-4", classes["single"])}>
        <div>
          <Ui.s color="foregroundLow" className="fw-500 mb-2">
            {t("waitingForPayment")}
          </Ui.s>
          <Ui.xxl bold>{overview.waitingForPaymentTotal}</Ui.xxl>
        </div>

        <div
          className={classNames(classes["circle"], {
            [classes["danger"]]: true,
          })}
        >
          <EquityIcon fontSize={32} strokeWidth={1} />
        </div>
      </div>

      <div className={classNames("px-4", classes["single"])}>
        <div>
          <Ui.s color="foregroundLow" className="fw-500 mb-2">
            {t("exerciseSubmitted")}
          </Ui.s>
          <Ui.xxl bold>{overview.submittedTotal}</Ui.xxl>
        </div>

        <div
          className={classNames(classes["circle"], {
            [classes["success"]]: true,
          })}
        >
          <ExercisingIcon fontSize={32} strokeWidth={1} />
        </div>
      </div>
    </div>
  );
};

export default ExercisingOverview;
