import { FC, memo, useCallback } from "react";
import { FormikHandlers } from "formik";
import { motion } from "framer-motion";

import { Button, ChecksGroup, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ViewDocumentIcon } from "common/icons/svg";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import CreatePlanStore from "../../../store";
import { summaryFields } from "../form-fields";
import { DraftPlans } from "../types";

const t = createTranslation(TranslationNS.pages, "createPlan.summary.tableSummary.row");

type TableRowProps = {
  row: DraftPlans;
  index: number;
  error?: string;
  handleBlur: FormikHandlers["handleBlur"];
  handleChange: FormikHandlers["handleChange"];
};

const TableRow: FC<TableRowProps> = ({ row, index, handleChange, handleBlur }) => {
  const fNumber = useFormatNumbers();
  const { setUpdatePlanEmail } = CreatePlanStore.useStoreActions((actions) => actions);

  const handleViewDocument = useCallback(() => {
    if (row?.planDocument?.downloadId) {
      openRemoteDocument(row?.planDocument?.downloadId).then();
    }
  }, [row?.planDocument?.downloadId]);

  const handleAddEmail = useCallback(() => {
    setUpdatePlanEmail({ planId: row.id, name: row.stakeholderName });
  }, [row.id, row.stakeholderName, setUpdatePlanEmail]);

  return (
    <motion.tr
      data-testid={`create-plan-summary-single-row-${index}`}
      initial={{
        opacity: 0,
        x: -20,
      }}
      animate={{ opacity: 1, x: 0, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.5 }}
      style={{
        backgroundColor: "transparent",
      }}
    >
      <td className="ps-2">
        <Ui.m bold>{row.stakeholderCompany || row.representedBy || "-"}</Ui.m>
        {row.stakeholderCompany && <Ui.s color="foregroundMedium">{row.representedBy || "-"}</Ui.s>}
      </td>
      <td className="ps-2">
        <Ui.m>{fNumber(row.numberOfShares, "amount")}</Ui.m>
      </td>
      <td className="ps-2">
        <div className="d-flex justify-content-center">
          {!!row?.planDocument?.downloadId && (
            <Button variant="tertiary" onClick={handleViewDocument} isOnlyIcon>
              <ViewDocumentIcon />
            </Button>
          )}
        </div>
      </td>
      <td className="ps-2">
        <div className="d-flex justify-content-center me-2">
          {row.stakeholderEmail ? (
            <ChecksGroup.Check
              type="checkbox"
              name={`${summaryFields.plans}[${index}].sendInvitation`}
              checked={row.sendInvitation}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          ) : (
            <Button onClick={handleAddEmail} style={{ textDecoration: "underline" }} size="s" variant={"tertiary"}>
              {t("addEmail")}
            </Button>
          )}
        </div>
      </td>
    </motion.tr>
  );
};

export default memo(TableRow);
