import { Action, action } from "easy-peasy";

export interface AppModel {
  redirectUrl: string;
  setRedirectUrl: Action<this, string>;

  isDarkTheme: boolean;
  setIsDarkTheme: Action<this, this["isDarkTheme"]>;

  isWizardLayout: boolean;
  setIsWizardLayout: Action<this, this["isDarkTheme"]>;

  isPublicLayout: boolean;
  setIsPublicLayout: Action<this, this["isPublicLayout"]>;

  isFooterShown: boolean;
  setIsFooterShown: Action<this, boolean>;

  isHeaderContextShown: boolean;
  setHeaderContextShown: Action<this, boolean>;

  isOnboardingInProgress: boolean;
  setIsOnboardingInProgress: Action<this, boolean>;
}

const appModel: AppModel = {
  redirectUrl: localStorage.getItem("redirectUrl") || "",
  setRedirectUrl: action((state, payload) => {
    localStorage.setItem("redirectUrl", payload);
    state.redirectUrl = payload;
  }),
  isDarkTheme: false,
  setIsDarkTheme: action((state, payload) => {
    state.isDarkTheme = payload;
  }),

  isWizardLayout: false,
  setIsWizardLayout: action((state, payload) => {
    state.isWizardLayout = payload;
  }),

  isPublicLayout: false,
  setIsPublicLayout: action((state, payload) => {
    state.isPublicLayout = payload;
  }),

  isFooterShown: true,
  setIsFooterShown: action((state, payload) => {
    state.isFooterShown = payload;
  }),

  isHeaderContextShown: true,
  setHeaderContextShown: action((state, payload) => {
    state.isHeaderContextShown = payload;
  }),

  isOnboardingInProgress: false,
  setIsOnboardingInProgress: action((state, payload) => {
    state.isOnboardingInProgress = payload;
  }),
};

export default appModel;
