import { createContext, FC, useCallback, useState } from "react";
import { useFormikContext } from "formik";
import { defaultTo, isNil } from "ramda";

import { P, ToggleButton, Ui } from "common/components/atoms";
import SubscriptionLimitsReached from "common/components/atoms/SubscriptionLimitsReached/SubscriptionLimitsReached";
import { RelationshipTypesEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

import ExistingStakeholder from "./components/ExistingStakeholder";
import NewStakeholder from "./components/NewStakeholder";
import { initialEmptyValues, StakeholderFieldsParamsType, StakeholderFormValues } from "./Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder");

export enum StakeholderOptionType {
  EXISTING = 1,
  NEW,
}

type StakeholderSectionProps = {
  option?: StakeholderOptionType;
  updatedFields?: StakeholderFieldsParamsType;
  placeholder?: string;
  isDisabled?: boolean;
  allowUpdate?: boolean;
  relationshipTypeId?: RelationshipTypesEnum; // add this param if you feel it's necessary

  // isAgreementsFlow property need to handle check seats request to get available seats to show seats limit banner
  isAgreementsFlow?: boolean;
};

export type StakeholderContextProps = { updatedFields?: StakeholderFieldsParamsType };

export const StakeholderSectionContext = createContext<StakeholderContextProps>({ updatedFields: undefined });

const StakeholderSection: FC<StakeholderSectionProps> = ({
  option,
  updatedFields,
  placeholder,
  isDisabled = false,
  allowUpdate = true,
  relationshipTypeId,
  isAgreementsFlow = false,
}) => {
  const [stakeholderOption, setStakeholderOption] = useState<number>(option || StakeholderOptionType.EXISTING);

  const { values, setValues, setTouched, touched, setFieldValue } = useFormikContext<
    StakeholderFormValues & { subscriptionAvailable?: any }
  >();

  const subscriptionLimitReached =
    !isNil(values.subscriptionAvailable) &&
    defaultTo(0, values.subscriptionAvailable.remainingAgreements) <= 0 &&
    isAgreementsFlow;

  const handleChangeOption = useCallback(
    (option: number | string) => {
      // feature for warrant, loan and note
      const modifiedInitValues: Partial<StakeholderFormValues & { subscriptionAvailable?: any }> = {};
      if (relationshipTypeId) {
        modifiedInitValues.relationshipTypeId = relationshipTypeId;
      }

      // end feature for warrant, loan and note
      setStakeholderOption(option as number);

      if (option === StakeholderOptionType.NEW && stakeholderOption !== StakeholderOptionType.NEW) {
        setValues({ ...values, ...initialEmptyValues, ...modifiedInitValues });
        setTouched({
          ...touched,
          ...Object.fromEntries(Object.entries(initialEmptyValues).map(([key, _]) => [key, false])),
        });
      }

      if (option === StakeholderOptionType.EXISTING && stakeholderOption !== StakeholderOptionType.EXISTING) {
        // feature for agreements
        setFieldValue("subscriptionAvailable", undefined);
      }
    },
    [relationshipTypeId, setFieldValue, setTouched, setValues, stakeholderOption, touched, values]
  );

  return (
    <StakeholderSectionContext.Provider value={{ updatedFields }}>
      <div>
        {!values.stakeholderId ? (
          <div>
            {placeholder && <P.m className="mb-4">{placeholder}</P.m>}
            <ToggleButton
              className="mb-4"
              firstVariant={{ title: t("selectExisting"), value: StakeholderOptionType.EXISTING }}
              secondVariant={{ title: t("createNew"), value: StakeholderOptionType.NEW }}
              selected={stakeholderOption}
              onClick={handleChangeOption}
            />
          </div>
        ) : (
          <Ui.m className="mb-1 font-weight-normal">{t("selectedStakeholder")}</Ui.m>
        )}

        {subscriptionLimitReached && stakeholderOption === StakeholderOptionType.NEW ? (
          <div className="mb-4">
            <SubscriptionLimitsReached numberOfPlans={values.subscriptionAvailable?.maximumNumberAgreements || 0} />
          </div>
        ) : null}

        {stakeholderOption === StakeholderOptionType.EXISTING ? (
          <ExistingStakeholder
            isDisabled={isDisabled}
            allowUpdate={allowUpdate}
            showSubscriptionLimitBanner={subscriptionLimitReached}
          />
        ) : (
          <NewStakeholder checkSeatsRequest={isAgreementsFlow} />
        )}
      </div>
    </StakeholderSectionContext.Provider>
  );
};

export default StakeholderSection;
