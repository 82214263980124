import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { MultiValue } from "react-select";
import axios from "axios";
import { useFormik } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import Button from "common/components/atoms/Button/Button";
import Check from "common/components/atoms/Checks/Check";
import FilledButton from "common/components/atoms/FilledButton/FilledButton";
import InfoAlert from "common/components/atoms/InfoAlert/InfoAlert";
import MultiSelect from "common/components/atoms/MultiSelect/MultiSelect";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import TextField from "common/components/atoms/TextField/TextField";
import { Ui } from "common/components/atoms/Typography";
import { DeleteIcon, MessageIcon, UserIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDropDownsData } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { URL_VALIDATION_PATTERN } from "common/utils/validators";
import { CommonObjectType } from "store/modelTypes";
import { useStoreActions } from "store/store";
import { TeamMember } from "store/types";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import SecurityPermissionsDetails from "./SecurityPermissionsDetails";

type CompanyTeamMemberFormProps = {
  open: boolean;
  companyId?: string;
  selectedMember?: TeamMember;
  currentMembers: TeamMember[];
  teamMemberRoleDropDown?: CommonObjectType[];
  onChange: (member?: TeamMember) => void;
  handleClose: (withModalOpen?: boolean) => void;
  isAddingEmployee?: boolean;
};

const translation = createTranslation(TranslationNS.pages, "companyProfile.team.companyTeamMemberForm");

const validationTranslation = createTranslation("validation");

const apiBase = "/api/attract";

const CompanyTeamMemberForm: FC<CompanyTeamMemberFormProps> = ({
  open,
  companyId,
  selectedMember,
  currentMembers,
  teamMemberRoleDropDown,
  onChange,
  handleClose,
  isAddingEmployee,
}) => {
  const { idFromRoute } = useParams<{ idFromRoute: string }>();
  const { getTeamThunk } = useStoreActions((state) => state.company);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [personAlreadyInList, setPersonAlreadyInList] = useState<TeamMember | null>();
  const [isSaveAndUpdateButtonClicked, setIsSaveAndUpdateButtonClicked] = useState<boolean>(false);
  const [isDeleteMemberClicked, setIsDeleteMemberClicked] = useState<boolean>(false);
  const [id, setId] = useState<string>();
  const [isSecurityDetailsOpen, setIsSecurityDetailsOpen] = useState<boolean>(false);
  const [invitePerson, setInvitePerson] = useState<boolean>(false);
  const [onlyAccountOwner, setOnlyAccountOwner] = useState<boolean>(false);

  useEffect(() => {
    if (R.isNil(idFromRoute) && !R.isNil(companyId)) {
      setId(companyId);
    }
  }, [companyId, idFromRoute]);

  useEffect(() => {
    setPersonAlreadyInList(null);
    if (selectedMember?.isLinkedToUser) {
      setOnlyAccountOwner(true);
    } else {
      setOnlyAccountOwner(false);
    }

    setId(companyId);
  }, [selectedMember, companyId]);

  function checkIfNameExistsInList() {
    if (values.firstName.length < 2 || values.lastName.length < 2) {
      return;
    }
    if (!R.isNil(personAlreadyInList)) {
      if (values.firstName !== personAlreadyInList.firstName || values.lastName !== personAlreadyInList.lastName) {
        setPersonAlreadyInList(null);
      }
    } else {
      const personFound = currentMembers?.find(
        (x) => x.firstName === values.firstName && x.lastName === values.lastName
      );
      setPersonAlreadyInList(personFound);
    }
  }

  const defaultAddRole = useMemo(() => {
    const defaultRole = teamMemberRoleDropDown?.length
      ? teamMemberRoleDropDown.filter((el) => {
          return isAddingEmployee ? el.name === "Employee" : el.name === "Board member";
        })
      : [];
    return selectedMember?.teamMemberRoles?.length
      ? selectedMember?.teamMemberRoles.filter((el) => el.name === (defaultRole as unknown as CommonObjectType).name)
      : defaultRole;
  }, [teamMemberRoleDropDown, selectedMember?.teamMemberRoles, isAddingEmployee]);

  const initialValues = useMemo(
    () => ({
      id: R.defaultTo(null, selectedMember?.id),
      firstName: R.defaultTo("", selectedMember?.firstName),
      lastName: R.defaultTo("", selectedMember?.lastName),
      teamMemberRoles: R.defaultTo([] as CommonObjectType[], [
        ...defaultAddRole,
        ...(selectedMember?.teamMemberRoles?.length
          ? (selectedMember?.teamMemberRoles as Array<CommonObjectType>)
          : []),
      ]),
      positionTitle: R.defaultTo("", selectedMember?.positionTitle),
      email: R.defaultTo("", selectedMember?.email),
      deleteText: "",
      isProfileInvisible: R.defaultTo(false, selectedMember?.dontShowProfile),
    }),
    [selectedMember, defaultAddRole]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(validationTranslation("required")),
        lastName: Yup.string().required(validationTranslation("required")),
        teamMemberRoles: Yup.array().min(1, validationTranslation("required")),
        linkedInUrl: Yup.string().matches(URL_VALIDATION_PATTERN, validationTranslation("invalidURL")),
        email: Yup.string().email(validationTranslation("invalidEmail")),
      }),
    []
  );

  const { values, errors, handleChange, setFieldValue, touched, resetForm, handleSubmit } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const isEditing = useMemo(() => !R.isNil(selectedMember), [selectedMember]);

  const closePanelHandler = useCallback(() => {
    resetForm();
    handleClose();
    setPersonAlreadyInList(null);
    setIsDeleteMemberClicked(false);
  }, [handleClose, resetForm]);

  async function submitHandler() {
    setIsLoading(true);
    setIsDeleteMemberClicked(false);

    const transformMemberRoles = values.teamMemberRoles.map((el) => el.id);

    const teamMemberData = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email || null,
      invitePerson: invitePerson,
      positionTitle: values.positionTitle || null,
      teamMemberRoles: transformMemberRoles,
      companyId: Number(id),
      dontShowProfile: values.isProfileInvisible,
    };

    try {
      const request = await axios.post(`${apiBase}/team-member`, teamMemberData);

      /* istanbul ignore next */
      if (request.status === 200) {
        notify(translation(`notify.teamMember.${isEditing ? "updated" : "created"}`), true, "success");

        getTeamThunk(Number(id)).then(() => {
          handleClose(isSaveAndUpdateButtonClicked);
        });
      }
    } catch (e) {
      /* istanbul ignore next */
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      const timer = setTimeout(() => {
        setIsLoading(false);
        setIsSaveAndUpdateButtonClicked(false);
        clearTimeout(timer);
      }, 500);
    }
  }

  const changeToEditPerson = useCallback(() => {
    if (!R.isNil(personAlreadyInList)) {
      onChange(personAlreadyInList);
    }
  }, [onChange, personAlreadyInList]);

  useEffect(() => {
    checkIfNameExistsInList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.firstName, values.lastName]);

  const isEmployeeSelected = values.teamMemberRoles.filter((el) => el.name === "Employee").length;

  const renderAddNewUserContent = useCallback(() => {
    return (
      <>
        <SlidePanel.Header
          title={translation("title.add", {
            user: isEmployeeSelected ? translation("title.teamMember") : translation("title.boardMemberOrAdvisor"),
          })}
          onHide={closePanelHandler}
        />

        <SlidePanel.Section title={translation("title.memberDetails")} isSingle>
          <Row>
            <Col>
              <TextField
                type="firstname"
                label={translation("firstName.label")}
                value={values.firstName}
                error={errors.firstName}
                isTouched={touched.firstName}
                onChange={handleChange("firstName")}
              />
            </Col>
            <Col>
              <TextField
                type="lastname"
                label={translation("lastName.label")}
                value={values.lastName}
                error={errors.lastName}
                isTouched={touched.lastName}
                onChange={handleChange("lastName")}
              />
            </Col>
          </Row>

          {personAlreadyInList && (
            <InfoAlert
              type="Warning"
              className="mt-5"
              content={translation.el("alreadyInList.warning", {
                values: {
                  firstName: values.firstName,
                  lastName: values.lastName,
                },
                components: [
                  <u
                    key="change-member-u-list-key"
                    data-testid="change-member-item-test-id"
                    style={{ cursor: "pointer" }}
                    onClick={changeToEditPerson}
                  />,
                ],
              })}
            />
          )}

          <MultiSelect
            className="my-5"
            error={errors.teamMemberRoles && touched.teamMemberRoles ? (errors.teamMemberRoles as string) : ""}
            label={translation("teamMemberRoles.label")}
            options={transformDropDownsData(teamMemberRoleDropDown)}
            defaultValues={transformDropDownsData(values.teamMemberRoles)}
            onChange={(e: MultiValue<{ id: number; value: string }>) => {
              /* istanbul ignore next */
              const transformedData = e.map((el) => ({
                id: el.id,
                name: el.value,
              }));

              /* istanbul ignore next */
              setFieldValue("teamMemberRoles", transformedData);
            }}
          />

          <TextField
            type="text"
            isOptional={true}
            value={values.positionTitle}
            label={translation("positionTitle.label")}
            onChange={handleChange("positionTitle")}
          />

          <TextField
            type="email"
            isOptional={true}
            className="mt-5"
            value={values.email}
            label={translation("email.label")}
            error={errors.email}
            isTouched={touched.email}
            onChange={handleChange("email")}
          />
          <Check
            className="my-7"
            checked={values.isProfileInvisible}
            onChange={(e) => {
              setFieldValue("isProfileInvisible", e.target.checked);
            }}
            label={translation("dontShowProfile")}
          />
        </SlidePanel.Section>

        <SlidePanel.Actions>
          <Button
            className="ms-2"
            variant="secondary"
            isDisabled={isLoading}
            isLoading={isLoading && isSaveAndUpdateButtonClicked}
            onClick={() => handleSubmit()}
          >
            {translation("addMemberNotInviteButton")}
          </Button>
        </SlidePanel.Actions>
        <p className="m-0 ui-xs mt-2 mb-5" style={{ color: scssVariables.foregroundHigh }}>
          {translation("addMemberInfo")}
        </p>
      </>
    );
  }, [
    isEmployeeSelected,
    closePanelHandler,
    values.firstName,
    values.lastName,
    values.teamMemberRoles,
    values.positionTitle,
    values.email,
    values.isProfileInvisible,
    errors.firstName,
    errors.lastName,
    errors.teamMemberRoles,
    errors.email,
    touched.firstName,
    touched.lastName,
    touched.teamMemberRoles,
    touched.email,
    handleChange,
    personAlreadyInList,
    changeToEditPerson,
    teamMemberRoleDropDown,
    isLoading,
    isSaveAndUpdateButtonClicked,
    setFieldValue,
    handleSubmit,
  ]);

  const renderEditUserContent = useCallback(() => {
    async function removeTeamMember() {
      setIsLoading(true);
      try {
        const request = await axios.delete(`${apiBase}/team-member/${selectedMember?.id}`);

        /* istanbul ignore next */
        if (request.status === 200) {
          notify(translation("notify.teamMember.deleted"), true, "success");

          getTeamThunk(Number(id)).then(() => {
            handleClose();
          });
        }
      } catch (e) {
        notify(translation("notify.teamMember.cantDelete"), true, "error");
      } finally {
        const timer = setTimeout(() => {
          /* istanbul ignore next */
          setIsLoading(false);
          /* istanbul ignore next */
          setIsDeleteMemberClicked(false);
          /* istanbul ignore next */
          setIsSaveAndUpdateButtonClicked(false);
          /* istanbul ignore next */
          clearTimeout(timer);
        }, 500);
      }
    }

    return (
      <>
        <SlidePanel.Header
          title={
            isDeleteMemberClicked
              ? translation("title.delete", {
                  firstName: selectedMember?.firstName,
                })
              : translation("title.edit", {
                  firstName: selectedMember?.firstName,
                  lastName: selectedMember?.lastName,
                })
          }
          onHide={closePanelHandler}
        />
        <SlidePanel.Section isSingle title={translation("title.memberDetails")}>
          {selectedMember?.isLinkedToUser && (
            <p className="m-0 ui-m mb-5" style={{ opacity: 0.8 }}>
              {translation("memberCreatedProfileOwn", {
                user: selectedMember?.firstName,
              })}
            </p>
          )}

          {isDeleteMemberClicked ? (
            <div data-testid="team-member-remove-section-container-test-id">
              <Ui.m className="mb-5" style={{ opacity: 0.8 }}>
                {translation("deleteMode.description", {
                  firstName: selectedMember?.firstName,
                })}
              </Ui.m>

              <Ui.m className="mb-5" style={{ opacity: 0.8 }}>
                {translation("deleteMode.description2", {
                  firstName: selectedMember?.firstName,
                })}
              </Ui.m>

              <TextField
                type="text"
                className="mb-10"
                label={translation("deleteMode.writeDelete", {
                  firstName: selectedMember?.firstName,
                })}
                value={values.deleteText}
                placeholder={translation("deleteMode.textBoxPlaceholder")}
                error={errors.deleteText}
                isTouched={touched.deleteText}
                onChange={handleChange("deleteText")}
              />

              <div className="d-flex">
                <FilledButton
                  btnWidth={201}
                  text={translation("deleteMode.no")}
                  className="success-btn"
                  onClick={() => setIsDeleteMemberClicked(false)}
                />

                <div style={{ width: 24, height: "100%" }} />

                <Button
                  style={{ width: "266px" }}
                  variant="secondary"
                  isLoading={isLoading}
                  data-testid="remove-member-button-test-id"
                  onClick={removeTeamMember}
                  isDisabled={values.deleteText !== "DELETE STAKEHOLDER"}
                >
                  {translation("deleteMode.yes")}
                </Button>
              </div>
            </div>
          ) : (
            <>
              {selectedMember?.isLinkedToUser ? (
                <div className="d-flex">
                  <Row>
                    <Col>
                      <div className="d-flex align-items-center" data-testid="security-team-member-container-test-id">
                        <Ui.m className="fw-500">{translation("name.label")}</Ui.m>
                        <div
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          <UserIcon color={scssVariables.foregroundMedium} />
                        </div>
                      </div>

                      <Ui.m className="mt-3">
                        {selectedMember.firstName} {selectedMember.lastName}
                      </Ui.m>
                    </Col>

                    <Col>
                      {selectedMember.email && (
                        <>
                          <div className="d-flex align-items-center">
                            <Ui.m className="fw-500">{translation("email.label")}</Ui.m>
                            <div
                              style={{
                                marginLeft: 10,
                              }}
                            >
                              <MessageIcon width={16} height={16} color={scssVariables.foregroundMedium} />
                            </div>
                          </div>

                          <Ui.m className="mt-3">{selectedMember.email}</Ui.m>
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              ) : (
                <div className="d-flex">
                  <div className="d-flex w-100 justify-content-between align-items-center">
                    <Row>
                      <Col>
                        <TextField
                          type="firstname"
                          label={translation("firstName.label")}
                          value={values.firstName}
                          error={errors.firstName}
                          isTouched={touched.firstName}
                          onChange={handleChange("firstName")}
                        />
                      </Col>

                      <Col>
                        <TextField
                          type="lastname"
                          label={translation("lastName.label")}
                          value={values.lastName}
                          onChange={handleChange("lastName")}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              )}

              <MultiSelect
                className="my-5"
                label={translation("teamMemberRoles.label")}
                options={transformDropDownsData(teamMemberRoleDropDown)}
                defaultValues={transformDropDownsData(values.teamMemberRoles)}
                onChange={(e: MultiValue<{ id: number; value: string }>) => {
                  /* istanbul ignore next */
                  const transformedData = e.map((el) => ({
                    id: el.id,
                    name: el.value,
                  }));

                  /* istanbul ignore next */
                  setFieldValue("teamMemberRoles", transformedData);
                }}
              />

              <TextField
                type="text"
                label={translation("positionTitle.label")}
                isOptional={true}
                value={values.positionTitle}
                onChange={handleChange("positionTitle")}
              />

              {!selectedMember?.isLinkedToUser && (
                <TextField
                  type="email"
                  className="mt-5"
                  label={translation("email.label")}
                  isOptional={true}
                  value={values.email}
                  error={errors.email}
                  isTouched={touched.email}
                  onChange={handleChange("email")}
                />
              )}
              <Check
                className="mt-7"
                checked={values.isProfileInvisible}
                onChange={(e) => {
                  setFieldValue("isProfileInvisible", e.target.checked);
                }}
                label={translation("dontShowProfile")}
              />

              <div className="d-flex mt-5">
                <Button
                  style={{ width: isEditing ? "185px" : "274px" }}
                  isDisabled={isLoading}
                  isLoading={isLoading && !isSaveAndUpdateButtonClicked}
                  onClick={() => handleSubmit()}
                >
                  {translation("saveChangesButton")}
                </Button>
              </div>

              {onlyAccountOwner ? (
                <>
                  <Ui.m
                    underline
                    className="fw-500 mt-5"
                    style={{
                      color: scssVariables.foregroundMedium,
                    }}
                  >
                    {translation("deleteMode.question", {
                      firstName: selectedMember?.firstName,
                    })}
                  </Ui.m>
                  <Ui.xs
                    className="mb-5"
                    style={{
                      color: scssVariables.foregroundMedium,
                    }}
                  >
                    {translation("deleteMode.cannotDelete", {
                      firstName: selectedMember?.firstName,
                    })}
                  </Ui.xs>
                </>
              ) : (
                <div
                  className="d-flex align-items-center mt-5 mb-5 cursor-pointer"
                  data-testid="team-member-delete-section-test-id"
                  onClick={() => setIsDeleteMemberClicked(true)}
                >
                  <DeleteIcon color={scssVariables.critical500} style={{ marginRight: 10 }} />
                  <Ui.m
                    className="fw-500"
                    style={{
                      color: scssVariables.critical500,
                    }}
                  >
                    {translation("deleteMode.question", {
                      firstName: selectedMember?.firstName,
                    })}
                  </Ui.m>
                </div>
              )}
            </>
          )}
        </SlidePanel.Section>
      </>
    );
  }, [
    isDeleteMemberClicked,
    selectedMember?.firstName,
    selectedMember?.lastName,
    selectedMember?.isLinkedToUser,
    selectedMember?.email,
    selectedMember?.id,
    closePanelHandler,
    values.deleteText,
    values.firstName,
    values.lastName,
    values.teamMemberRoles,
    values.positionTitle,
    values.email,
    values.isProfileInvisible,
    errors.deleteText,
    errors.firstName,
    errors.email,
    touched.deleteText,
    touched.firstName,
    touched.email,
    handleChange,
    isLoading,
    teamMemberRoleDropDown,
    isEditing,
    isSaveAndUpdateButtonClicked,
    onlyAccountOwner,
    getTeamThunk,
    id,
    handleClose,
    setFieldValue,
    handleSubmit,
  ]);

  return (
    <SlidePanel show={open} onHide={closePanelHandler}>
      <div data-testid="member-form-container-test-id">
        {isEditing ? renderEditUserContent() : renderAddNewUserContent()}
        <SecurityPermissionsDetails
          open={isSecurityDetailsOpen}
          handleClose={() => {
            setIsSecurityDetailsOpen(false);
          }}
        />
      </div>
    </SlidePanel>
  );
};

export default CompanyTeamMemberForm;
