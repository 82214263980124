import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { Form, useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import Table from "common/components/atoms/Table/Table";
import { H, P } from "common/components/atoms/Typography";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import AddEmailModal from "./AddEmailModal/AddEmailModal";
import GrantPlanModal from "./GranPlanModal/GrantPlanModal";
import ProgramDocument from "./ProgramDocument/ProgramDocument";
import classes from "./summary.module.scss";
import TableBodySummary from "./tableSummary/tableBody";
import TableHeadSummary from "./tableSummary/tableHead";
import { SummaryFormNewType } from "./types";

const t = createTranslation(TranslationNS.pages, "createPlan.summary");

type SummaryFormProps = {
  isDraftPlans: boolean;
  handleBack: () => void;
  summaryInfo: {
    totalDraftPlans: number;
    totalPlans: number;
    programName: string;
  };
};
const SummaryForm: FC<SummaryFormProps> = ({ isDraftPlans, handleBack, summaryInfo }) => {
  const { programId } = useParams<{ programId: string }>();
  const navigate = useNavigate();

  const [showGrantModal, setShowGrantModal] = useState(false);

  const { values, setFieldValue, handleSubmit, isSubmitting } = useFormikContext<SummaryFormNewType>();

  const handleSaveAsDraftAction = useCallback(() => {
    setFieldValue("saveAsDraft", true);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  const handleGrantPlan = useCallback(async () => {
    setFieldValue("saveAsDraft", false);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  const handleSetModal = useCallback(() => {
    setShowGrantModal(!showGrantModal);
  }, [showGrantModal]);

  const handleReturnToManage = useCallback(() => {
    navigate(getEMPath(["plans", "agreements"]));
  }, [navigate]);

  // const handleGrantDateChange = useCallback(
  //   (date: Date | string) => {
  //     setFieldValue("grantDate", date);
  //   },
  //   [setFieldValue]
  // );

  return (
    <Form>
      <div className={classes["wrap"]}>
        <H.xs className="mb-4">{t("title")}</H.xs>

        <P.m>
          {t.el("checkTeam", {
            components: [<strong key={1} />],
            tOptions: {
              numberInactivePlans: summaryInfo.totalDraftPlans,
              programName: summaryInfo.programName,
              totalPlans: summaryInfo.totalPlans,
            },
          })}
        </P.m>

        {!isDraftPlans && (
          <div className="mt-4">
            <Table
              className={classes.draftTable}
              headComponent={<TableHeadSummary />}
              bodyComponent={<TableBodySummary />}
            />

            <P.m className="mt-4 mb-7">{t("onboard")}</P.m>
            <AddEmailModal programId={programId ? +programId : 0} />
          </div>
        )}

        <div className={classNames(classes["divider"], "my-7")} />

        <H.s className="mb-2">{t("documentation")}</H.s>
        <P.m>
          {t("recommendDocuments")}
        </P.m>

        <ProgramDocument />

        {/* <Row className="my-5">
          <Col sm={6}>
            <DatePicker label="Grant date" className="w-half" isDateOnlyString date={values.grantDate} onChange={handleGrantDateChange} />
          </Col>
        </Row> */}

        <div className="d-flex justify-content-between">
          <WizardContent.Controls.BackButton isDisabled={isSubmitting} onClick={handleBack} />

          <div>
            <Button
              variant="tertiary"
              onClick={handleSaveAsDraftAction}
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {t("saveDraftBtn")}
            </Button>

            <Button
              type="button"
              onClick={!isDraftPlans ? handleSetModal : handleReturnToManage}
              variant="primary"
              className="ms-2"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              {!isDraftPlans ? t("grant", { count: values.plans.length }) : t("returnToManagePlans")}
            </Button>
          </div>
        </div>
        <GrantPlanModal
          totalPlans={values.plans.length}
          show={showGrantModal}
          handleCloseModal={handleSetModal}
          handleGrantPlan={handleGrantPlan}
        />
      </div>
    </Form>
  );
};

export default SummaryForm;
