import { createContext, FC, ReactNode, useCallback, useState } from "react";

import Answer from "./Answer/Answer";
import Question from "./Question/Question";

type HelperProps = {
  children?: ReactNode;
};

type ActiveQuestionType = {
  id: string;
  active: boolean;
};

type HelperContextType = {
  activeQuestions: ActiveQuestionType[];
  setActiveQuestion?: (id: string, active?: boolean) => void;
  answers: HelperAnswerProps;
  setQuestionAnswer?: (id: string, answer: HelperAnswer | string) => void;
};

type HelperComponent = FC<HelperProps> & {
  Question: typeof Question;
  Answer: typeof Answer;
};

export type HelperAnswer = {
  render: () => ReactNode;
};

type HelperAnswerProps = {
  [key: string]: HelperAnswer | string;
};

export const HelperContext = createContext<HelperContextType>({ activeQuestions: [], answers: {} });
const Helper: HelperComponent = ({ children }) => {
  const [activeQuestions, setActiveQuestions] = useState<ActiveQuestionType[]>([]);
  const [answers, setAnswers] = useState<HelperAnswerProps>({});

  const setQuestionAnswer = useCallback((id: string, answer: HelperAnswer | string) => {
    setAnswers((prevState) => {
      return { ...prevState, [id]: answer };
    });
  }, []);

  const setActiveQuestion = useCallback((id: string, active?: boolean) => {
    setActiveQuestions((prevState) => {
      const question = prevState.find((question) => question.id === id);
      if (question) {
        question.active = active ?? !question.active;
        return [...prevState];
      } else {
        return [...prevState, { id, active: active ?? true }];
      }
    });
  }, []);

  return (
    <HelperContext.Provider value={{ activeQuestions, setActiveQuestion, answers, setQuestionAnswer }}>
      {children}
    </HelperContext.Provider>
  );
};

Helper.Question = Question;
Helper.Answer = Answer;

export default Helper;
