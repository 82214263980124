import { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, ModalInfo } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleNote.deleteModal");

const DeleteNoteModal: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams<{ companyId: string }>();

  const { isOpenDeleteModalCN, noteId, stakeholderNameCN } = IssueEquityContext.useStoreState((state) => state);
  const { setIsOpenDeleteModalCN, deleteConvertibleNoteThunk, getIssueEquityThunk } =
    IssueEquityContext.useStoreActions((actions) => actions);

  const handleCancel = useCallback(() => {
    setIsOpenDeleteModalCN({ open: false });
  }, [setIsOpenDeleteModalCN]);

  const handleDelete = useCallback(async () => {
    if (noteId) {
      setIsLoading(true);
      try {
        await deleteConvertibleNoteThunk(noteId);
        if (companyId) {
          getIssueEquityThunk(+companyId);
        }
        setIsOpenDeleteModalCN({ open: false });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [companyId, deleteConvertibleNoteThunk, getIssueEquityThunk, noteId, setIsOpenDeleteModalCN]);

  return (
    <ModalInfo
      size={"sm"}
      show={isOpenDeleteModalCN}
      header={t("title", { name: stakeholderNameCN || "" })}
      footer={
        <>
          <Button isLoading={isLoading} isDisabled={isLoading} onClick={handleDelete}>
            {t("delete")}
          </Button>
          <Button isLoading={isLoading} isDisabled={isLoading} onClick={handleCancel} variant="secondary">
            {t("cancel")}
          </Button>
        </>
      }
    >
      {t("description")}
    </ModalInfo>
  );
};

export default DeleteNoteModal;
