import { useEffect } from "react";

import { useStoreActions, useStoreState } from "store/store";

const useCompanyRemainingStakeholders = (companyId?: string | number, renewData = true) => {
  const remainingStakeholders = useStoreState((state) => state.activeCompanyModel.remainingStakeholders);
  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);

  const getRemainingStakeholdersThunk = useStoreActions(
    (actions) => actions.activeCompanyModel.getRemainingStakeholdersThunk
  );

  const companyIdToUse = companyId || activeCompanyId;

  useEffect(() => {
    if (companyIdToUse && renewData) {
      getRemainingStakeholdersThunk(companyIdToUse).then();
    }
  }, [companyId, companyIdToUse, getRemainingStakeholdersThunk, renewData]);

  return remainingStakeholders;
};

export default useCompanyRemainingStakeholders;
