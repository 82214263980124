import { FC, useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { Formik, FormikConfig } from "formik";
import { omit } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, H, LoaderContainer, ModalInfo, P } from "common/components/atoms";
import TableBrowserStorage, { useTableBrowserStorage } from "common/components/atoms/ImportTable/TableBrowserStorage";
import UnsavedChangesModal, {
  SelectionItems,
  UnsavedChangesOptions,
} from "common/components/atoms/ImportTable/UnsavedChangesModal/UnsavedChangesModal";
import StakeholdersLimitBanner from "common/components/atoms/StakeholdersLimitBanner/StakeholdersLimitBanner";
import StakeholdersLimitModal from "common/components/atoms/StakeholdersLimitModal/StakeholdersLimitModal";
import StakeholdersLimitSubmitModal from "common/components/atoms/StakeholdersLimitSubmitModal/StakeholdersLimitSubmitModal";
import { BreggCompanyShareholders } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useCompanyRemainingStakeholders from "../../../../common/hooks/useCompanyRemainingStakeholders";
import { OwnershipFormValues } from "../import-ownership/useImportOwnershipForm";
import FoundersTable from "./FoundersTable/FoundersTable";
import classes from "./ImportFounders.module.scss";
import useFoundersForm from "./useFoundersForm";
import useImportFounders from "./useImportFounders";

const t = createTranslation(TranslationNS.pages, "onboard.company.importFounders");

const ImportFoundersForm = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId?: string }>();
  const { maxNumberOfStakeholders } = useCompanyRemainingStakeholders(Number(companyId));
  const [isStakeholdersLimitSubmitModalOpen, setIsStakeholdersLimitSubmitModalOpen] = useState(false);

  const [isWrongSharesModalVisible, setIsWrongSharesModalVisible] = useState<boolean>(false);

  const loading = useStoreState((state) => state.companyOnboardingModel.isGetCompanyLoading);
  const postLoading = useStoreState((state) => state.companyOnboardingModel.isPostCompanyLoading);
  const company = useStoreState((state) => state.companyOnboardingModel.company);
  const { bregg, uniMicro } = useStoreState((state) => state.companyOnboardingModel.integrations);
  const founders = useStoreState((state) => state.companyOnboardingModel.founders);
  const lastSavedAt = useStoreState((state) => state.companyOnboardingModel.lastSavedAt);
  const isStakeholdersLimitModalOpen = useStoreState(
    (state) => state.companyOnboardingModel.isStakeholderLimitModalOpen
  );

  const setFoundersThunk = useStoreActions((actions) => actions.companyOnboardingModel.setFoundersThunk);
  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);
  const setPostCompanyLoading = useStoreActions((actions) => actions.companyOnboardingModel.setPostCompanyLoading);
  const setCompanyType = useStoreActions((actions) => actions.companyOnboardingModel.setCompanyType);
  const setStakeholderLimitModalOpen = useStoreActions(
    (actions) => actions.companyOnboardingModel.setStakeholderLimitModalOpen
  );

  const { foundersData, setFoundersData } = useImportFounders();
  const { initialValues, validationSchema } = useFoundersForm(foundersData);
  const { removeImportTable } = useTableBrowserStorage<BreggCompanyShareholders[]>();

  const additionalItems = useMemo(() => {
    const items: SelectionItems<["saved", "bregg"]>[] = [];
    if (lastSavedAt) {
      items.push({
        title: t("savedVersion.title"),
        description: t("savedVersion.subtitle", { date: format(parseISO(lastSavedAt), "eee dd. MMM, HH:mm") }),
        id: "saved",
      });
    }
    if (bregg?.shareholders?.length || uniMicro?.shareholders?.length) {
      items.push({
        title: t("breggVersion.title"),
        description: t("breggVersion.subtitle", { date: "31.12.2022" }),
        id: "bregg",
      });
    }

    return items;
  }, [bregg?.shareholders?.length, lastSavedAt, uniMicro?.shareholders?.length]);

  const handleOpenStakeholderLimitSubmitModal = useCallback(() => {
    setIsStakeholdersLimitSubmitModalOpen(true);
  }, []);

  const handleOpenStakeholderLimitModal = useCallback(() => {
    setStakeholderLimitModalOpen(true);
  }, [setStakeholderLimitModalOpen]);

  const handleSelectData = useCallback(
    (type: UnsavedChangesOptions<["saved", "bregg"]>) => {
      if (type === "saved") {
        setFoundersData(founders);
      } else if (type === "remove") {
        setFoundersData([]);
      } else if (type === "bregg") {
        setFoundersData([]);
      }
    },
    [setFoundersData, founders]
  );

  const handleModalClose = () => {
    setIsWrongSharesModalVisible(false);
  };

  const handleSubmit = useCallback<
    FormikConfig<Omit<OwnershipFormValues, "dateOfOwnership" | "shareClassName">[]>["onSubmit"]
  >(
    async (values) => {
      setPostCompanyLoading(true);

      if (values.reduce((acc = 0, curr) => acc + Number(curr.numberOfShares), 0) !== company?.numberOfShares) {
        setIsWrongSharesModalVisible(true);
        setPostCompanyLoading(false);

        return;
      }

      let foundersToSubmit = values.map((stakeholder) =>
        omit(["checkbox"], {
          ...stakeholder,
          isCompanyOwned: Boolean(stakeholder.isCompanyOwned),
          countryId: stakeholder.countryId || null,
          numberOfShares: Number(stakeholder.numberOfShares),
        })
      ) as any[];

      if (maxNumberOfStakeholders && maxNumberOfStakeholders < foundersToSubmit.length) {
        foundersToSubmit = foundersToSubmit.slice(0, 25);
      }

      try {
        const data = {
          companyId: Number(companyId),
          founders: foundersToSubmit,
        };

        await setFoundersThunk(data);

        removeImportTable();
        setCompanyType(null);
        await getAccountThunk();
        navigate(getEMPath(["ownership", "capTable"], { companyId }));
      } catch (e) {
        setIsStakeholdersLimitSubmitModalOpen(false);
        console.error({ e });
      } finally {
        setPostCompanyLoading(false);
      }
    },
    [
      company?.numberOfShares,
      companyId,
      getAccountThunk,
      navigate,
      removeImportTable,
      setCompanyType,
      setFoundersThunk,
      setPostCompanyLoading,
      maxNumberOfStakeholders,
    ]
  );

  const { setImportTable } = useTableBrowserStorage();

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values }) => {
          const stakeholdersLimitReached = maxNumberOfStakeholders && maxNumberOfStakeholders < values.length;

          const submitCheck = () => {
            if (stakeholdersLimitReached) {
              handleOpenStakeholderLimitSubmitModal();
            } else {
              handleSubmit();
            }
          };

          const saveBeforeUpgrade = () => {
            setImportTable(values);
          };

          return (
            <div className={classes.wrap}>
              <div className="d-flex justify-content-between align-items-center">
                <div />

                <div className="d-flex flex-column align-items-center ms-5">
                  <H.l className="mb-1">{t("title")}</H.l>
                  <P.s color="foregroundLow">{t("subTitle")}</P.s>
                </div>

                <Button variant="primary" isLoading={postLoading} isDisabled={postLoading} onClick={submitCheck}>
                  {t("finishRegistration")}
                </Button>
              </div>

              <LoaderContainer loading={loading}>
                <FoundersTable
                  initialItems={initialValues}
                  showStakeholdersLimitModal={handleOpenStakeholderLimitModal}
                />
                <UnsavedChangesModal selectedTypeCallback={handleSelectData} additionalItems={additionalItems} />
              </LoaderContainer>

              <StakeholdersLimitSubmitModal
                isOpen={isStakeholdersLimitSubmitModalOpen}
                handleSubmit={handleSubmit}
                handleClose={() => setIsStakeholdersLimitSubmitModalOpen(false)}
              />

              <StakeholdersLimitModal
                isOpen={isStakeholdersLimitModalOpen}
                handleClose={() => setStakeholderLimitModalOpen(false)}
                onUpgrade={saveBeforeUpgrade}
              />

              {stakeholdersLimitReached && <StakeholdersLimitBanner asToast onUpgrade={saveBeforeUpgrade} />}
            </div>
          );
        }}
      </Formik>

      <ModalInfo
        show={isWrongSharesModalVisible}
        handleClose={handleModalClose}
        header={t("notMatchSharesTitle")}
        footer={
          <Button variant="secondary" onClick={handleModalClose}>
            {t("dismiss")}
          </Button>
        }
      >
        <P.m>{t("notMatchSharesDescription")}</P.m>
      </ModalInfo>
    </>
  );
};

const ImportFounders: FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  return (
    <TableBrowserStorage tableName={`founders-${companyId}`}>
      <ImportFoundersForm />
    </TableBrowserStorage>
  );
};

export default ImportFounders;
