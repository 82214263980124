import { PlanGetDTO, ProgramGetDTO } from "store/modelTypes";

export const availableSharesInProgram = (program: ProgramGetDTO | null, planToEdit: PlanGetDTO | null) => {
  const draftNumberOfShares = program?.draftPlans.reduce((acc, plan) => acc + plan.numberOfShares, 0) || 0;
  return (
    (program?.numberOfShares || 0) -
    (program?.totalSharesInPlans || 0) -
    draftNumberOfShares +
    (planToEdit?.numberOfShares || 0)
  );
};
