import { FC, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import InputFeedback from "common/components/atoms/input-blocks/input-feedback/input-feedback";
import { VestingTypesEnum } from "common/enums/enum";
import { CreateProgramFormData } from "pages/equity-management/plans/wizards/create-program/useProgramForm";

import { VestingDetailsProps } from "../VestingDetails";
import AddCondition from "./AddCondition";
import Condition from "./Condition/Condition";

type ProgramVestingCondition = {
  title: string;
  description: string;
  targetDate: string | Date;
};

export const emptyVestingConditions: ProgramVestingCondition = {
  title: "",
  description: "",
  targetDate: createDateString(new Date()),
};

type ConditionsProps = VestingDetailsProps;

const Conditions: FC<ConditionsProps> = ({ type, instrumentType }) => {
  const { values, setFieldValue, errors } =
    useFormikContext<Pick<CreateProgramFormData, "vestingTypeId" | "conditions">>();

  const isConditionBased = useMemo<boolean>(
    () =>
      !!values.vestingTypeId &&
      (+values.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING ||
        +values.vestingTypeId === VestingTypesEnum.COMBINE_VESTING),
    [values.vestingTypeId]
  );

  useEffect(() => {
    if (isConditionBased && !values.conditions?.length) {
      setFieldValue("conditions", [emptyVestingConditions]);
    }
  }, [values?.conditions, setFieldValue, isConditionBased]);

  if (!isConditionBased) {
    return null;
  }

  return (
    <>
      {values.conditions?.map((_, index) => (
        <Condition key={index} index={index} type={type} instrumentType={instrumentType} />
      ))}

      <InputFeedback isTouched error={typeof errors.conditions === "string" ? errors.conditions : undefined} />

      <AddCondition />
    </>
  );
};

export default Conditions;
