import { FC, MouseEvent, useCallback, useState } from "react";
import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { motion } from "framer-motion";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { Ui } from "common/components/atoms/Typography";
import { CheckIcon, SettingsIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { Company, InvestmentEntity } from "store/types";

import classes from "./ContextContent.module.scss";

type SingleCompanyItemProps = {
  disabled?: boolean;
  company: Company;
  onClick: () => void;
  setIsLoading: (val: boolean) => void;
};

const SingleCompanyItem: FC<SingleCompanyItemProps> = ({ disabled, company, onClick, setIsLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isHovering, setIsHovering] = useState<boolean>(false);

  const { setCompanyId } = useStoreActions((actions) => actions.activeCompanyModel);
  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);

  const selectCompanyHandler = useCallback(
    ({ id }: Company | InvestmentEntity) =>
      (): void => {
        setIsLoading(true);
        setCompanyId(id);
        onClick();
        setIsLoading(false);

        const companyUrlFits = matchRoutes(
          [
            { path: ROUTER_V2.equityManagement.full },
            { path: ROUTER_V2.attract.full },
            { path: ROUTER_V2.companySettings.full },
          ],
          location.pathname
        );
        if (companyUrlFits) {
          navigate(location.pathname.replace(`${activeCompanyId}`, `${id}`), { replace: true });
          return;
        }

        navigate(getEMPath(["settings", "companyInformation"], { companyId: id }));
      },
    [setIsLoading, setCompanyId, onClick, location.pathname, navigate, activeCompanyId]
  );

  const editCompanyHandler = useCallback(
    (entity: Company | InvestmentEntity) =>
      (e: MouseEvent<HTMLDivElement>): void => {
        e.preventDefault();
        e.stopPropagation();

        const redirectToEditPage = (): void => {
          navigate(
            getEMPath(["settings", "companyInformation"], {
              companyId: entity.id,
            })
          );
        };

        if (entity.id === activeCompanyId) {
          redirectToEditPage();
        } else {
          setCompanyId(entity.id);
          redirectToEditPage();
        }

        onClick();
      },
    [activeCompanyId, navigate, onClick, setCompanyId]
  );

  return (
    <motion.div
      key={company.id}
      className={classNames(classes["company"], {
        [classes["active"]]: company.id === activeCompanyId,
        [classes["disabled"]]: disabled,
      })}
      onHoverStart={() => {
        if (!disabled) {
          setIsHovering(true);
        }
      }}
      onHoverEnd={() => {
        if (!disabled) {
          setIsHovering(false);
        }
      }}
      onClick={disabled ? undefined : selectCompanyHandler(company)}
    >
      <div
        className="d-flex justify-content-between text-truncate"
        style={{
          opacity: disabled ? 0.7 : 1,
        }}
      >
        <Ui.xs className="text-truncate">{company.name}</Ui.xs>
        {company.id === activeCompanyId && <CheckIcon color={scssVariables.positive500} />}
      </div>

      <motion.div
        initial={{ opacity: 0, x: 50 }}
        animate={{
          x: isHovering ? 0 : 50,
          opacity: isHovering ? 1 : 0,
        }}
        style={{ marginLeft: 12 }}
        className={classes["settings"]}
        onClick={editCompanyHandler(company)}
      >
        <SettingsIcon color={scssVariables.foregroundMedium} />
      </motion.div>
    </motion.div>
  );
};

export default SingleCompanyItem;
