import { useMemo } from "react";
import { compareAsc } from "date-fns";
import { array, mixed, number, object, ref, string } from "yup";

import { DocumentStatusEnum, PoolTypesOfSource } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { documentStatuses } from "common/pool/components/PoolForm/PoolDocumentStatusField";
import { typesOfSource } from "common/pool/components/PoolForm/PoolShareSourceTypeField";
import { createTranslation, TranslationNS } from "translation";

import { fields } from "./usePoolForm";

const tValidation = createTranslation(TranslationNS.validation);

const usePoolFormValidation = (minNumberOfShares?: number) => {
  const fNumber = useFormatNumbers();

  return useMemo(() => {
    return object().shape({
      [fields.name]: string().required(tValidation("required")),
      [fields.description]: string().nullable(),
      [fields.shareSourceTypeId]: number().oneOf(typesOfSource, tValidation("required")),
      [fields.shareSourceStakeholderId]: number().when(fields.shareSourceTypeId, {
        is: PoolTypesOfSource.existing,
        then: (scheme) => scheme.required(tValidation("required")),
        otherwise: (scheme) => scheme.nullable(),
      }),
      [fields.shareClassId]: number()
        .transform((value) => +value)
        .required(tValidation("required")),
      [fields.numberOfShares]: number()
        .positive(tValidation("positive"))
        .integer(tValidation("integer"))
        .min(minNumberOfShares || 1, tValidation("minNumber", { number: fNumber(minNumberOfShares || 1, "amount") }))
        .max(ref(fields.allowedNumberOfShares), ({ max }: { max: number }) => {
          return tValidation("maxNumber", {
            number: fNumber(max, "amount"),
          });
        })
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .required(tValidation("required")),
      [fields.documentStatusId]: number().nullable().oneOf(documentStatuses),
      [fields.approvalDate]: string().required(tValidation("required")),
      [fields.expiryDate]: string().test("expiryDate", "No earlier than Approval date", (value, object) => {
        if (value && object.parent[fields.approvalDate]) {
          return (
            compareAsc(new Date(value), new Date(object.parent[fields.approvalDate])) === 0 ||
            compareAsc(new Date(value), new Date(object.parent[fields.approvalDate])) === 1
          );
        }
        return true;
      }),
      [fields.approvalBodyId]: number().nullable(),
      [fields.filesData]: object().when(fields.documentStatusId, {
        is: (documentValue: number) => +documentValue === DocumentStatusEnum.DOCUMENT_UPLOADED,
        then: object().shape({
          files: mixed().when("oldFiles", {
            is: (oldFiles: any) => !oldFiles.length,
            then: array().min(1, tValidation("required")),
          }),
        }),
      }),
    });
  }, [fNumber, minNumberOfShares]);
};

export default usePoolFormValidation;
