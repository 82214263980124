import axios from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { InvestmentEntity } from "store/types";

import { transactionsApiBase } from "../../equity-management/ownership/transactions/transactions-service";
import { OwnershipPlanDocumentFile } from "../components/receive.types";

export type IssueEquitySharesDTO = {
  id: number;
  companyName: string | null;
  numberOfShares: number;
  sharePurchaseDate: string | null;
  totalPurchasePrice: number;
  sharesTotalValue: number;
  usingHoldingCompany: boolean;
  holdingCompanyName: string | null;
  organisationNumber: string | null;
  documentsNeedsSignature: boolean;
  documentFiles: OwnershipPlanDocumentFile[];
  stakeholderCompanyName?: string;
};

interface IssueEquityWizardContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  companyName: string;
  setCompanyName: Action<this, this["companyName"]>;

  selectedCompany: InvestmentEntity | null;
  setSelectedCompany: Action<this, this["selectedCompany"]>;

  isRemoteCompanySelected: boolean;
  setIsRemoteCompanySelected: Action<this, this["isRemoteCompanySelected"]>;

  companyOrgNumber: string;
  setCompanyOrgNumber: Action<this, this["companyOrgNumber"]>;

  isHoldingCompanySelected: boolean;
  setIsHoldingCompanySelected: Action<this, this["isHoldingCompanySelected"]>;

  signature: File | null;
  setSignature: Action<this, this["signature"]>;

  issueEquityDetails: IssueEquitySharesDTO | null;
  setIssueEquityDetails: Action<this, this["issueEquityDetails"]>;
  getIssueEquityDetails: Thunk<this, string>;
  acceptEquityThunk: Thunk<this, FormData>;
}

const IssueEquityWizardContext = createContextStore<IssueEquityWizardContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    companyName: "",
    setCompanyName: action((state, payload) => {
      state.companyName = payload;
    }),

    selectedCompany: null,
    setSelectedCompany: action((state, payload) => {
      state.selectedCompany = payload;
    }),

    isRemoteCompanySelected: false,
    setIsRemoteCompanySelected: action((state, payload) => {
      state.isRemoteCompanySelected = payload;
    }),

    companyOrgNumber: "",
    setCompanyOrgNumber: action((state, payload) => {
      state.companyOrgNumber = payload;
    }),

    isHoldingCompanySelected: false,
    setIsHoldingCompanySelected: action((state, payload) => {
      state.isHoldingCompanySelected = payload;
    }),

    signature: null,
    setSignature: action((state, payload) => {
      state.signature = payload;
    }),

    issueEquityDetails: null,
    setIssueEquityDetails: action((state, payload) => {
      state.issueEquityDetails = payload;
    }),
    getIssueEquityDetails: thunk(async (actions, key) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<IssueEquitySharesDTO>(`${transactionsApiBase}/shares/details/${key}`);

        if (request.status === 200) {
          actions.setIssueEquityDetails(request.data);
        }
      } finally {
        actions.setIsLoading(false);
      }
    }),
    acceptEquityThunk: thunk(async (actions, payload) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.post(`${transactionsApiBase}/shares/accept-shares-issued`, payload);

        if (request.status === 200) {
          return true;
        }
      } finally {
        actions.setIsLoading(false);
      }
    }),
  },
  {
    name: "Receive issue shares context",
  }
);

export default IssueEquityWizardContext;
