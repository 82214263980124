import { FC, memo, useCallback, useMemo } from "react";
import { FormikConfig, useFormik } from "formik";

import Button from "common/components/atoms/Button/Button";
import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import FileUploader, { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ManagePlansContext from "../../../managePlansContext";
import classes from "./GrantPlan.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans.grantPlan");

type GrantPlanProps = {
  companyId: string;
};
const GrantPlan: FC<GrantPlanProps> = ({ companyId }) => {
  const planData = ManagePlansContext.useStoreState((state) => state.grantPlanInfo);
  const { setGrantPlanInfo } = ManagePlansContext.useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setGrantPlanInfo(null);
  }, [setGrantPlanInfo]);

  return (
    <ModalInfo
      className={classes.grantPlan}
      header={t("title", { name: planData?.representedBy })}
      show={!!planData}
      handleClose={handleClose}
    >
      <div className="mt-3 text-start">{t("description")}</div>
      {planData?.id && (
        <FormData
          email={planData.stakeholderEmail}
          companyId={companyId}
          planId={planData?.id}
          handleClose={handleClose}
        />
      )}
    </ModalInfo>
  );
};

type FormDataProps = {
  handleClose: () => void;
  planId: number;
  companyId: string;
  email?: string;
};
const FormData: FC<FormDataProps> = ({ handleClose, planId, companyId, email }) => {
  const { grantPlanThunk } = useStoreActions((actions) => actions.planModel);
  const { getOwnershipPlansThunk } = useStoreActions((actions) => actions.company);

  const initialValues = useMemo(
    () => ({
      newFiles: [],
      companyId: +companyId,
      plans: [{ planId, sendEmailInvitation: false }],
    }),
    [companyId, planId]
  );

  const submitHandler = useCallback<FormikConfig<typeof initialValues>["onSubmit"]>(
    async (values, { setSubmitting }) => {
      const res = await grantPlanThunk(values);
      if (res) {
        getOwnershipPlansThunk(values.companyId);
        notify(t("planGranted"), true, "success");
        handleClose();
      }
      setSubmitting(false);
    },
    [grantPlanThunk, getOwnershipPlansThunk, handleClose]
  );

  const { handleSubmit, isSubmitting, setFieldValue, values, handleChange } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    enableReinitialize: true,
  });

  const handleChangeFile = useCallback(
    (files: FilesDataMultiple) => {
      setFieldValue("newFiles", files.newFiles);
    },
    [setFieldValue]
  );

  return (
    <div className="mt-5">
      <form onSubmit={handleSubmit}>
        <FileUploader multiple isOptional label={t("uploadDocuments")} onChange={handleChangeFile} />

        {email && (
          <ChecksGroup className="mt-3">
            <ChecksGroup.Check
              checked={values.plans[0].sendEmailInvitation}
              label="Send email invitation"
              name="plans[0].sendEmailInvitation"
              onChange={handleChange}
            />
          </ChecksGroup>
        )}
        <div className="mt-5">
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="primary" type="submit" className="me-2">
            {t("grantPlan")}
          </Button>
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default memo(GrantPlan);
