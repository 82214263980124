import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import DocumentsStep from "../components/step/documents";
import SignatureStep from "../components/step/signature";
import StakeholderInformationStep from "../components/step/stakeholder-information";
import ConvertibleLoanWizardContext from "./ReceiveConvertibleLoanContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan.receiverSide");

const ConvertibleLoanFormData = () => {
  const navigate = useNavigate();
  useToastFormikValidator();

  const { convertibleLoanDetails } = ConvertibleLoanWizardContext.useStoreState((state) => state);

  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const { declineConvertibleLoanThunk } = ConvertibleLoanWizardContext.useStoreActions((state) => state);

  const { handleSubmit, isSubmitting, setSubmitting } = useFormikContext();

  const declineHandler = useCallback(async () => {
    setSubmitting(true);
    try {
      await declineConvertibleLoanThunk({
        companyId: Number(convertibleLoanDetails?.companyId),
        convertibleLoanId: Number(convertibleLoanDetails?.id),
      });
      setInvitedUser(null);

      navigate(getPath(["user", "dashboard"]), { replace: true });
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  }, [
    convertibleLoanDetails?.companyId,
    convertibleLoanDetails?.id,
    declineConvertibleLoanThunk,
    navigate,
    setInvitedUser,
    setSubmitting,
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <StakeholderInformationStep
        stakeholder={convertibleLoanDetails?.stakeholder}
        description={t("steps.reviewStakeholder.description")}
      />

      {convertibleLoanDetails?.documentsNeedsSignature && (
        <>
          <div className="mt-3" />

          <DocumentsStep
            documents={convertibleLoanDetails?.documentFiles || []}
            description={t("steps.acceptDocuments.description")}
          />

          <div className="mt-3" />

          <SignatureStep description={t("steps.acceptAgreement.description")} />
        </>
      )}

      <div className="d-flex mt-7">
        <Button isLoading={isSubmitting} type="submit" isDisabled={isSubmitting}>
          {t("accept")}
        </Button>
        {convertibleLoanDetails?.documentsNeedsSignature && (
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            className="ms-2"
            variant="secondary"
            onClick={declineHandler}
          >
            {t("decline")}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ConvertibleLoanFormData;
