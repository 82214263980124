import { FC, useMemo, useState } from "react";
import cn from "classnames";
import { defaultTo } from "ramda";

import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ChevronDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDetailsContext } from "../../stakeholder-details.context";
import classes from "../../stakeholder-details.module.scss";
import ShareClassTable from "../ShareClassTable/ShareClassTable";
import ShareSeriesTable from "../ShareSeriesTable/ShareSeriesTable";

const [t] = [
  createTranslation(TranslationNS.common, "organisms.stakeholderDetails.ownership"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const OwnershipTab: FC = () => {
  const fNumber = useFormatNumbers();
  const [selectedView, setSelectedView] = useState("shareSeries");

  const details = StakeholderDetailsContext.useStoreState((state) => state.details);

  const issuedShares = useMemo(() => defaultTo([], details?.issuedShares), [details?.issuedShares]);
  const shareClasses = useMemo(() => defaultTo([], details?.shareClasses), [details?.shareClasses]);

  const viewMenu = useMemo<ContextMenuProps["items"]>(() => {
    return [
      {
        key: "shareSeries",
        label: "Share series",
        forceHideDropdown: true,
        onClick: () => setSelectedView("shareSeries"),
        selected: selectedView === "shareSeries",
      },
      {
        key: "shareClass",
        label: "Share class",
        forceHideDropdown: true,
        onClick: () => setSelectedView("shareClass"),
        selected: selectedView === "shareClass",
      },
    ];
  }, [selectedView]);

  return (
    <div className={cn("m-4 pb-3", classes["details-container"])}>
      <div className={cn(classes["head"])} style={{ backgroundColor: scssVariables.element2 }}>
        <div className="d-flex justify-content-between align-items-center px-3">
          <div className="text-start">
            <Ui.m bold>{t("ownershipBreakdown")}</Ui.m>
            <Ui.xs color="foregroundLow">{t("description")}</Ui.xs>
          </div>
        </div>
      </div>

      <div className="px-3">
        {!!details?.shareClasses?.length && (
          <div className="d-flex justify-content-evenly mt-3" style={{ gap: 24 }}>
            <div className={classes["info-section"]}>
              <Ui.s color="foregroundLow">{t("title")}</Ui.s>
              <Ui.xxl bold className="mt-2">
                {fNumber(details?.sharesTotalPercentage, "percent")}
              </Ui.xxl>
            </div>
            <div className={classes["info-section"]}>
              <Ui.s color="foregroundLow">{t("shares")}</Ui.s>
              <Ui.xxl bold className="mt-2">
                {fNumber(
                  details?.shareClasses?.reduce((acc, curr) => acc + curr.shares, 0),
                  "amount"
                )}
              </Ui.xxl>
            </div>
            <div className={classes["info-section"]}>
              <Ui.s color="foregroundLow">{t("totalValue")}</Ui.s>
              <Ui.xxl bold className="mt-2">
                {fNumber(details?.totalSharesValue, "value")}
              </Ui.xxl>
            </div>
          </div>
        )}

        <div className="mt-4 d-flex justify-content-end">
          <ContextMenu items={viewMenu} chevronAnimation drop="down-centered">
            <Button
              size="s"
              variant="tertiary"
              className={classes["context-button"]}
              iconRight={<ChevronDownIcon className="chevron " />}
            >
              View: {selectedView === "shareSeries" ? "Share series" : "Share class"}
            </Button>
          </ContextMenu>
        </div>
        {selectedView === "shareClass" ? (
          <ShareClassTable data={shareClasses} className="mt-1" />
        ) : (
          <ShareSeriesTable className="mt-1" data={issuedShares} />
        )}
      </div>
    </div>
  );
};

export default OwnershipTab;
