import { FC, memo, ReactNode } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";

import classes from "./inputs.module.scss";

type TextInputProps = {
  type?: "text" | "number";
  placeholder?: string;
  className?: string;
  disabledWhen?: number;
  nameWhen?: string;
  iconRight?: ReactNode;
  columnId: string;
  rowIndex: number;
  isDisabled?: boolean;
};

type FormikValues = {
  [key: string]: string | number | undefined;
};

const TextInput: FC<TextInputProps> = ({
  placeholder = "",
  type = "text",
  className,
  disabledWhen,
  nameWhen,
  iconRight,
  columnId,
  rowIndex,
  isDisabled,
}) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<FormikValues[]>();

  const error = errors[rowIndex]?.[columnId];
  const value = values[rowIndex]?.[columnId];
  const touchedValue = touched?.[rowIndex]?.[columnId];

  const fieldIdDisabled = !!(nameWhen && disabledWhen === values?.[rowIndex]?.[nameWhen] && !value) || isDisabled;

  return (
    <>
      {error && touchedValue && (
        <div className={classes.errorIcon}>
          <Tooltip className={classes.errorTooltip} popupContent={error}>
            <span>
              <WarningExclamationMarkIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <TextField
        name={`[${rowIndex}].${columnId}`}
        placeholder={placeholder}
        className={cn(classes.textInput, className)}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        iconRight={iconRight}
        type={type}
        isDisabled={fieldIdDisabled}
      />
    </>
  );
};

export default memo(TextInput);
