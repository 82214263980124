import { FC, useCallback, useMemo } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";

import Button from "common/components/atoms/Button/Button";
import TextField from "common/components/atoms/TextField/TextField";
import { H } from "common/components/atoms/Typography";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import classes from "./updateOrgNumberModal.module.scss";

type PropsTypes = {
  companyId: number;
  onClose: () => void;
  onSubmit: () => void;
  updateReceiverOrgNumber?: (orgNumber: string) => void;
  companyName?: string;
  receiverCompanyName?: string;
  type?: "company" | "receiver";
};

type FormValues = {
  orgNumber: string;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar.generatePlanDocument.orgNumberModal");

const orgNumberName = "orgNumber";

const UpdateOrgNumberModal: FC<PropsTypes> = ({
  companyId,
  onClose,
  onSubmit,
  companyName,
  receiverCompanyName,
  type,
  updateReceiverOrgNumber,
}) => {
  const initialValues: FormValues = useMemo(() => ({ [orgNumberName]: "" }), []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [orgNumberName]: Yup.string().required(t("inputError")),
      }),
    []
  );

  const updateOrgNumber = useCallback(
    async (values: FormValues) => {
      try {
        let success = false;
        if (type === "company") {
          const response = await axios.post("/api/attract/update-organization-number", {
            companyId,
            organizationNumber: values.orgNumber,
          });
          if (response.status === 200) {
            success = true;
          }
        } else {
          updateReceiverOrgNumber?.(values.orgNumber);
          success = true;
        }

        if (success) {
          notify(t("successNotification"), true, "success");
          onSubmit();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, onSubmit, type, updateReceiverOrgNumber]
  );

  const { handleChange, handleSubmit, values, errors, touched, isSubmitting, handleBlur } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: updateOrgNumber,
  });

  return (
    <Modal centered className={classes["update-modal"]} show={true} onHide={onClose}>
      <div className={classes.content}>
        <H.xs className="mb-5">
          {t("title", { companyName: type === "company" ? companyName : receiverCompanyName })}
        </H.xs>

        <TextField
          className="mb-5"
          label={t("inputLabel")}
          name={orgNumberName}
          value={values.orgNumber}
          onChange={handleChange}
          error={errors.orgNumber}
          isTouched={touched.orgNumber}
          onBlur={handleBlur}
        />

        <div className="d-flex justify-content-center">
          <Button isLoading={isSubmitting} onClick={() => handleSubmit()}>
            {t("submitBtn")}
          </Button>
          <Button isLoading={isSubmitting} variant="secondary" className="ms-2" onClick={onClose}>
            {t("cancelBtn")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateOrgNumberModal;
