import { FC } from "react";

import WizardStepContent from "common/layout/WizardLayout/WizardStepContent/WizardStepContent";

import Basics from "./Basics/Basics";
import Specifics from "./Specifics/Specifics";

const Main: FC = () => {
  return (
    <>
      <WizardStepContent step={1}>
        <Basics />
      </WizardStepContent>

      <WizardStepContent step={2}>
        <Specifics />
      </WizardStepContent>
    </>
  );
};

export default Main;
