import { FC, useCallback, useMemo } from "react";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import { IssueEquityConvertibleStatuses } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CloseIcon, DeleteIcon, EditIcon, MenuTabBarVerticalIcon, TransactionsIcon } from "common/icons/svg";
import { setConveribleTagColorBasedOnId, transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import { IssueEquityWarrantsDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.table");

type PropsTypes = {
  item: IssueEquityWarrantsDTO;
};

const WarrantsTableRow: FC<PropsTypes> = ({ item }) => {
  const { hasFullAccess } = useFeatures(FEATURES.issueEquity);
  const fNumber = useFormatNumbers();

  const dropdowns = useStoreState((state) => state.common.dropdowns);

  const selectedValue = dropdowns?.convertibleStatuses?.find((el) => el.id === item.status);
  const tagColor = setConveribleTagColorBasedOnId(item.status);

  const {
    setIsSetExpiredWarrantEditPanelOpen,
    setIsDeleteWarrantEditPanelOpen,
    setIsWarrantsEditPanelOpen,
    setIsSettleWarrantEditPanelOpen,
  } = IssueEquityContext.useStoreActions((actions) => actions);

  const editWarrantHandler = useCallback(() => {
    setIsWarrantsEditPanelOpen({ open: true, warrantId: item.id });
  }, [item.id, setIsWarrantsEditPanelOpen]);

  const deleteWarrantHandler = useCallback(() => {
    setIsDeleteWarrantEditPanelOpen({ open: true, warrantId: item.id });
  }, [item.id, setIsDeleteWarrantEditPanelOpen]);

  const settleWarrantHandler = useCallback(() => {
    setIsSettleWarrantEditPanelOpen({ open: true, warrantId: item.id });
  }, [item.id, setIsSettleWarrantEditPanelOpen]);

  const expireWarrantHandler = useCallback(() => {
    setIsSetExpiredWarrantEditPanelOpen({ open: true, warrantId: item.id });
  }, [item.id, setIsSetExpiredWarrantEditPanelOpen]);

  const menuItems: ContextMenuProps["items"] = useMemo(() => {
    const options = [
      {
        key: "settle",
        label: t("actions.settleWarrant"),
        icon: <TransactionsIcon />,
        onClick: !hasFullAccess ? undefined : settleWarrantHandler,
        isDisabled: !hasFullAccess || item.status === IssueEquityConvertibleStatuses.Converting,
      },
      {
        key: "edit",
        label: t("actions.editWarrant"),
        icon: <EditIcon />,
        onClick: !hasFullAccess ? undefined : editWarrantHandler,
        isDisabled: !hasFullAccess,
      },
      {
        key: "setAsExpired",
        label: t("actions.setAsExpired"),
        icon: <CloseIcon />,
        onClick: !hasFullAccess ? undefined : expireWarrantHandler,
        isDisabled: !hasFullAccess || item.status !== IssueEquityConvertibleStatuses.Approved,
      },
      {
        key: "remove",
        label: t("actions.deleteWarrant"),
        icon: <DeleteIcon />,
        onClick: !hasFullAccess ? undefined : deleteWarrantHandler,
        type: "delete" as const,
        isDisabled: !hasFullAccess,
      },
    ];

    if (item.status === IssueEquityConvertibleStatuses.Expired) {
      return options.filter((option) => option.key !== "setAsExpired");
    }

    return options;
  }, [
    deleteWarrantHandler,
    editWarrantHandler,
    expireWarrantHandler,
    hasFullAccess,
    item.status,
    settleWarrantHandler,
  ]);

  return (
    <tr className={classes["table-row"]}>
      <td className={classes.truncate}>
        {item.representedBy !== item.stakholderName && <Ui.s bold>{item.stakholderName}</Ui.s>}
        {item.representedBy !== item.stakholderName ? (
          <Ui.s className={classes.representedBy}>
            {item.representedBy !== item.stakholderName ? item.representedBy : item.stakholderName}
          </Ui.s>
        ) : (
          <Ui.s bold>{item.representedBy !== item.stakholderName ? item.representedBy : item.stakholderName}</Ui.s>
        )}
      </td>

      <td className="text-end">
        <Ui.s title={item.shareClassName as string}>{item.shareClassName ? item.shareClassName : "-"}</Ui.s>
      </td>

      <td className="text-end">
        <Ui.s>{fNumber(item.warrantPrice, "unitPrice")}</Ui.s>
      </td>

      <td className="text-end">
        <Ui.s>{fNumber(item.strikePrice ? item.strikePrice * item.numberOfShares : 0, "unitPrice")}</Ui.s>
      </td>

      <td className="text-end">
        <Ui.s>{fNumber(item.numberOfShares, item.showPercentageOfShares ? "percent" : "amount")}</Ui.s>
      </td>

      <td className="text-end">
        <Ui.s>{item.expirationDate ? transformDateToCommonDateFormat(item.expirationDate) : "-"}</Ui.s>
      </td>

      <td className="text-end">
        <div
          className={classes["status-tag"]}
          style={{ backgroundColor: tagColor?.bgColor, color: tagColor?.textColor }}
        >
          <Ui.xs>
            {item.status === IssueEquityConvertibleStatuses.Converting
              ? "Settling"
              : item.status === IssueEquityConvertibleStatuses.Converted
              ? "Settled"
              : selectedValue?.name}
          </Ui.xs>
        </div>
      </td>
      <td style={{ width: 40 }}>
        {item.status !== IssueEquityConvertibleStatuses.Converted && (
          <ContextMenu items={menuItems}>
            <Button
              size="s"
              isOnlyIcon
              variant="tertiary"
              data-testid="plan-dropdown-btn"
              className={cn(classes["add-btn"], {
                [classes["dark-icon"]]: true,
              })}
            >
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        )}
      </td>
    </tr>
  );
};

export default WarrantsTableRow;
