import { FC, useMemo } from "react";
import classNames from "classnames";

import classes from "./ProgressCircle.module.scss";

export type ProgressCircleProps = JSX.IntrinsicElements["svg"] & {
  loading?: boolean;
  min?: number;
  max?: number;
  now?: number;
};

const ProgressCircle: FC<ProgressCircleProps> = ({
  className,
  loading,
  min = 0,
  max = 100,
  now = 0,
  ...props
}) => {
  const value = useMemo<number>(
    () =>
      ((now - min) * (0 - +classes.progressBarDasharray)) / (max - min) +
      +classes.progressBarDasharray,
    [max, min, now]
  );

  return (
    <svg
      className={classNames(classes["progress-circle"], className)}
      viewBox="0 0 24 24"
      {...props}
    >
      <circle
        className={classes["placeholder"]}
        cx="12"
        cy="12"
        r="8"
        strokeWidth="3"
      />
      <circle
        className={classNames(classes["progress-bar"], {
          [classes["loading"]]: loading,
        })}
        cx="12"
        cy="12"
        r="8"
        strokeWidth="3"
        strokeDashoffset={value}
      />
    </svg>
  );
};

export default ProgressCircle;
