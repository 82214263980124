import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import Step from "common/components/atoms/Step/Step";
import StepInfo from "common/components/atoms/Step/StepInfo";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { PieChartIcon } from "common/icons/svg";
import { Variant } from "store/GettingStartedGuideModel";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import InfoCart from "../InfoCard/InfoCard";
import classes from "./stepTwo.module.scss";

const t = createTranslation(TranslationNS.pages, "company.gettingStartedGuide.stepTwo");

const StepTwo: FC<{ status: Variant }> = ({ status }) => {
  const navigate = useNavigate();
  const capTable = useStoreState((state) => state.gettingStartedGuide.capTable);
  const fNumber = useFormatNumbers();

  const setupCapTable = !capTable.numberOfShareHolders;

  const handleEditCapTable = useCallback(() => {
    navigate(getEMPath(["ownership", "capTable"]));
  }, [navigate]);

  const isInactive = status === "waiting" || status === "undone";

  return (
    <Step.Item status={status}>
      <Step.Point icon={status !== "done" ? <PieChartIcon width="24px" height="24px" /> : undefined} />
      <StepInfo>
        {status === "undone" && <Step.Important className="mb-1" />}
        <div className="d-flex align-items-center">
          <div className={classes.capTableItem}>
            <b>{t("title")}</b>
            <p className="mt-1 mb-3">{t("description")}</p>
            {!isInactive && (
              <>
                {setupCapTable ? (
                  <Button onClick={handleEditCapTable} variant={"primary"} size="s">
                    {t("setupCapTable")}
                  </Button>
                ) : (
                  <Button onClick={handleEditCapTable} variant="secondary" size="s">
                    {t("manageCapTable")}
                  </Button>
                )}
              </>
            )}
          </div>
          <div className={classes.capTableItem}>
            <div className="d-flex align-items-center justify-content-center">
              {isInactive ? (
                <PieChartIcon className="ms-3" width="50" height="50" />
              ) : (
                <InfoCart
                  title={t("shareholders", {
                    value: fNumber(capTable.numberOfShareHolders, "amount") || "?",
                  })}
                  description={t("shares", {
                    value: capTable.numberOfShares ? fNumber(capTable.numberOfShares, "amount") : "?",
                  })}
                />
              )}
            </div>
          </div>
        </div>
      </StepInfo>
    </Step.Item>
  );
};

export default StepTwo;
