import { FC, useEffect, useState } from "react";
import { AxisTickProps } from "@nivo/axes";
import { ResponsiveBar } from "@nivo/bar";

import { Ui } from "common/components/atoms/Typography";
import { scssVariables } from "common/utils/constants";

import useFormatNumbers from "../../../hooks/useFormatNumbers";

type VerticalBarChartProps = {
  isEmpty?: boolean;
  data: { value: number; id: string; color: string }[];
};

const VerticalBarChart: FC<VerticalBarChartProps> = ({ data, isEmpty }) => {
  const fNumber = useFormatNumbers();
  const [mounted, setMounted] = useState<boolean>(false);

  const CustomTick = (tick: AxisTickProps<string>) => {
    const currentItem = data.find((el) => el.id === tick.value);

    return (
      <foreignObject width={130} height={data.length * 40} x={tick.x - 120} y={tick.y - 10}>
        {isEmpty ? (
          <div
            style={{
              width: 60,
              height: 16,
              marginTop: 10,
              borderRadius: 8,
              backgroundColor: scssVariables.element1,
            }}
          />
        ) : currentItem?.value && currentItem?.id ? (
          <div className="d-flex">
            <Ui.xs bold className="m-0">
              {fNumber(currentItem.value, "percent")}
            </Ui.xs>
            &nbsp;
            <Ui.xs className="m-0">{currentItem?.id}</Ui.xs>
          </div>
        ) : null}
      </foreignObject>
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMounted(true);

      clearTimeout(timer);
    }, 500);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
        height: data.length * 40,
      }}
    >
      <ResponsiveBar
        data={data}
        indexBy="id"
        padding={0.15}
        keys={["value"]}
        layout="horizontal"
        isInteractive={false}
        margin={{ top: 0, right: 0, bottom: 0, left: 120 }}
        colors={{ datum: "data.color" }}
        borderRadius={4}
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={{
          renderTick: CustomTick,
        }}
        enableGridY={false}
        enableLabel={false}
      />

      <div
        style={{
          top: 0,
          left: 120,
          width: 2,
          height: "100%",
          position: "absolute",
          backgroundColor: "white",
        }}
      />
      <div
        style={{
          top: 0,
          width: 1,
          left: 121,
          height: "100%",
          position: "absolute",
          backgroundColor: "black",
        }}
      />
    </div>
  );
};

export default VerticalBarChart;
