import { ReactNode } from "react";
import { Col, Row, RowProps } from "react-bootstrap";

import { Helper } from "common/components/atoms";

export type ProgramHelperProps<T extends ReactNode | ReactNode[]> = RowProps & {
  colInputSize?: number;
  colAnswerSize?: number;
  children: T;
  questionId: T extends ReactNode[] ? string[] : string;
  withRightMargin?: boolean;
};
type ProgramHelperPropsT = {
  <T extends ReactNode | ReactNode[]>(props: ProgramHelperProps<T>): ReactNode;
};
const ProgramHelper: ProgramHelperPropsT = ({
  colInputSize = 6,
  colAnswerSize = 12,
  questionId,
  children,
  withRightMargin = true,
  ...props
}) => {
  if (!children) {
    return null;
  }

  const allChildren = Array.isArray(children) ? (children as ReactNode[]) : [children as ReactNode];
  const allQuestionIds = Array.isArray(questionId) ? (questionId as string[]) : [questionId as string];

  return (
    <Row {...props}>
      {allChildren.map((child, index) => {
        if (!child) return null;
        return (
          <Col md={colInputSize} key={index}>
            {child}
          </Col>
        );
      })}
      {allQuestionIds.map((id, index) => {
        if (!id) return null;

        return (
          <Col md={colAnswerSize} key={index}>
            <Helper.Answer className={"mt-3"} answerId={id} withRightMargin={withRightMargin} />
          </Col>
        );
      })}
    </Row>
  );
};

export default ProgramHelper;
