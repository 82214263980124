import { useCallback, useMemo } from "react";
import { array, boolean, date, number, object, ref, string } from "yup";

import { DocumentStatusEnum, InstrumentTypesIdsEnum, PeriodTimeEnum, VestingTypesEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

import { unitOptions } from "./components/helpers";
import { fields } from "./useProgramForm";

export const cliffError = "Should be 0, more or equal to Vesting Interval and less than Vesting Period";

const tV = createTranslation(TranslationNS.validation);

const isTimeBased = (value: VestingTypesEnum): boolean =>
  value === VestingTypesEnum.TIME_VESTING || value === VestingTypesEnum.COMBINE_VESTING;

const isConditionBased = (value: VestingTypesEnum): boolean =>
  value === VestingTypesEnum.MILESTONE_VESTING || value === VestingTypesEnum.COMBINE_VESTING;

const useProgramFormValidation = () => {
  const terminationValue = useMemo(
    () =>
      number()
        .nullable()
        .when([fields.isExcludedClause, fields.programTypeId], {
          is: (isExcludedClause: string, programTypeId: number) => {
            return !isExcludedClause && programTypeId === InstrumentTypesIdsEnum.OPTION;
          },
          then: (scheme) =>
            scheme
              .positive(tV("minNumber", { number: 0 }))
              .integer(tV("integer"))
              .min(0)
              .required(tV("required")),
        }),
    []
  );

  const terminationUnit = useMemo(
    () =>
      string()
        .nullable()
        .when([fields.isExcludedClause, fields.programTypeId], {
          is: (isExcludedClause: string, programTypeId: number) => {
            return !isExcludedClause && programTypeId === InstrumentTypesIdsEnum.OPTION;
          },
          then: (scheme) =>
            scheme.oneOf([PeriodTimeEnum.DAY, PeriodTimeEnum.MONTH, PeriodTimeEnum.YEAR]).required(tV("required")),
        }),
    []
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      [fields.name]: string().required(tV("required")),
      [fields.purpose]: string().nullable(),
      [fields.poolId]: number().min(1, tV("required")).required(tV("required")),
      [fields.programTypeId]: number()
        .oneOf([InstrumentTypesIdsEnum.RSA, InstrumentTypesIdsEnum.OPTION])
        .required(tV("required")),
      [fields.numberOfShares]: number()
        .min(1, tV("minNumber", { number: 1 }))
        .integer(tV("integer"))
        .required(tV("required"))
        .when(fields.numberOfSharesLeft, (value: number, scheme) =>
          scheme.max(+value, tV("maxNumber", { number: value || 0 }))
        ),
      // RSA + Options
      [fields.purchasePrice]: number()
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .when(fields.programTypeId, {
          is: (value: InstrumentTypesIdsEnum): boolean => value === InstrumentTypesIdsEnum.RSA,
          then: (scheme) => scheme.min(0.000001, tV("minNumber", { number: "0,000001" })).required(tV("required")),
          otherwise: (scheme) => scheme.min(0, tV("minNumber", { number: "0" })),
        }),
      [fields.exercisePrice]: number()
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .when(fields.programTypeId, {
          is: InstrumentTypesIdsEnum.OPTION,
          then: (scheme) => scheme.min(0.000001, tV("minNumber", { number: "0,000001" })).required(tV("required")),
        }),
      [fields.optionsExpiration]: number()
        .nullable()
        .transform((value, originalValue) => (originalValue === "" ? undefined : value))
        .when(fields.programTypeId, {
          is: InstrumentTypesIdsEnum.OPTION,
          then: (scheme) =>
            scheme
              .min(0, tV("minNumber", { number: 0 }))
              .integer(tV("integer"))
              .required(tV("required")),
        }),
      [fields.optionsExpirationTimeUnit]: string()
        .nullable()
        .when(fields.programTypeId, {
          is: InstrumentTypesIdsEnum.OPTION,
          then: (scheme) => scheme.oneOf(unitOptions).required(tV("required")),
        }),
      // Vesting
      [fields.vestingTypeId]: number().oneOf([
        VestingTypesEnum.TIME_VESTING,
        VestingTypesEnum.COMBINE_VESTING,
        VestingTypesEnum.MILESTONE_VESTING,
      ]),
      //
      [fields.vestingPeriod]: number().when(fields.vestingTypeId, {
        is: isTimeBased,
        then: (schema) =>
          schema
            .moreThan(0, "required")
            .positive()
            .integer(tV("integer"))
            .min(ref("vestingInterval"), "Should be more or equal than Vesting Interval")
            .required(tV("required")),
      }),
      [fields.vestingInterval]: number().when(fields.vestingTypeId, {
        is: isTimeBased,
        then: (schema) =>
          schema
            .max(ref("vestingPeriod"), "Should be less or equal than Vesting Period")
            .positive()
            .integer(tV("integer"))
            .required(tV("required")),
      }),
      [fields.vestingCliff]: number().when(fields.vestingTypeId, {
        is: isTimeBased,
        then: (schema) =>
          schema.required(tV("required")).test("is cliff valid", cliffError, function (vestingCliff = 0) {
            if (vestingCliff === 0) return true;

            return vestingCliff >= this.parent["vestingInterval"] && vestingCliff <= this.parent["vestingPeriod"];
          }),
      }),
      [fields.conditions]: array().when(fields.vestingTypeId, {
        is: isConditionBased,
        then: (schema) =>
          schema.of(
            object().shape({
              title: string().required(tV("required")),
            })
          ),
      }),

      [fields.allowAcceleratedVesting]: boolean().required(tV("required")),

      [fields.retirementValue]: terminationValue,
      [fields.retirementUnit]: terminationUnit,
      [fields.disabilityValue]: terminationValue,
      [fields.disabilityUnit]: terminationUnit,
      [fields.terminationWithCauseValue]: terminationValue,
      [fields.terminationWithCauseUnit]: terminationUnit,
      [fields.involuntaryTerminationValue]: terminationValue,
      [fields.involuntaryTerminationUnit]: terminationUnit,
      [fields.voluntaryTerminationValue]: terminationValue,
      [fields.voluntaryTerminationUnit]: terminationUnit,
      [fields.terminationByPassingValue]: terminationValue,
      [fields.terminationByPassingUnit]: terminationUnit,

      [fields.documentStatusId]: number()
        .nullable()
        .oneOf([
          DocumentStatusEnum.DOCUMENT_UPLOADED,
          DocumentStatusEnum.NO_DOCUMENT_REQUIRED,
          DocumentStatusEnum.REVIEW_LATER,
        ])
        .required(tV("required")),
      [fields.approvalDate]: date().required(tV("required")),
      [fields.managerId]: number().required(tV("required")).typeError(tV("required")),
    });
  }, [terminationUnit, terminationValue]);

  const validationSchemaStep = useCallback((step: "main" | "sharesDetails" | "vesting" | "termination" | "summary") => {
    switch (step) {
      case "main":
        return ["name", "purpose"];
      case "sharesDetails":
        return [
          "poolId",
          "programTypeId",
          "numberOfShares",
          "purchasePrice",
          "exercisePrice",
          "optionsExpiration",
          "optionsExpirationTimeUnit",
        ];
      case "vesting":
        return [
          "vestingTypeId",
          "vestingPeriod",
          "vestingInterval",
          "vestingCliff",
          "allowAcceleratedVesting",
          "conditions",
        ];
      case "termination":
        return [
          "retirementValue",
          "retirementUnit",
          "disabilityValue",
          "disabilityUnit",
          "terminationWithCauseValue",
          "terminationWithCauseUnit",
          "involuntaryTerminationValue",
          "involuntaryTerminationUnit",
          "voluntaryTerminationValue",
          "voluntaryTerminationUnit",
          "terminationByPassingValue",
          "terminationByPassingUnit",
        ];
      case "summary":
        return ["documentStatusId", "approvalDate", "managerId"];
      default:
        return [];
    }
  }, []);

  return {
    validationSchema,
    validationSchemaStep,
  };
};

export default useProgramFormValidation;
