import { FC, useEffect, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import Helper from "common/components/atoms/Helper/Helper";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { P } from "common/components/atoms/Typography";
import ShareClassForm, { ShareClassFormProps } from "common/shareClass/components/ShareClassForm/ShareClassForm";
import ShareClassService from "common/shareClass/ShareClassService";
import { ShareClass, ShareClassFormData, ShareClassTypes } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassFormOffcanvas");

const LiquidationPreferences: FC = () => {
  const { values } = useFormikContext<Pick<ShareClassFormData, "shareClassTypeId" | "isParticipating">>();

  if (values.shareClassTypeId !== ShareClassTypes.preferred) {
    return null;
  }

  return (
    <SlidePanel.Section title={t("liquidationPreferences")} isDividerVisible={false}>
      <P.m>{t("liquidationPreferencesDescription")}</P.m>

      <Row className="my-4">
        <Col>
          <ShareClassForm.SeniorityLevelField />
        </Col>
        <Col>
          <ShareClassForm.SeniorityLevelMultipleField />
        </Col>
      </Row>

      <Row>
        <Col md={3}>
          <Helper.Question questionId="isParticipating" type="switcher">
            <ShareClassForm.IsParticipatingField />
          </Helper.Question>
        </Col>
        <Col md={12}>
          <Helper.Answer
            className={"mt-3"}
            answerId="isParticipating"
            text={t("isParticipatingAnswer")}
            linkText={t("isParticipatingReadMore")}
            link="/"
          />
        </Col>
      </Row>
      {values.isParticipating && (
        <Row className="mt-3">
          <Col md={6}>
            <Helper.Question questionId="participatingCap" type="input">
              <ShareClassForm.ParticipatingCapField />
            </Helper.Question>
          </Col>
          <Col md={12}>
            <Helper.Answer
              className={"mt-3"}
              answerId="participatingCap"
              text={t("participatingCapAnswer")}
              linkText={t("participatingCapReadMore")}
              link="/"
            />
          </Col>
        </Row>
      )}

      {/*<ShareClassForm.ShareClassAntiDilutionField />*/}
    </SlidePanel.Section>
  );
};

type ContentProps = {
  editing?: boolean;
  onCancel?(): void;
  onSubmit?(value1?: any, value2?: any): void;
};

const Content: FC<ContentProps> = ({ editing, onCancel }) => {
  const { values, setFieldValue, handleSubmit } = useFormikContext<any>();

  useEffect(() => {
    if (values.shareClassTypeId === ShareClassTypes.preferred && !values.seniorityLevel) {
      setFieldValue("seniorityLevel", 1);
    }

    if (values.shareClassTypeId === ShareClassTypes.preferred && !values.seniorityLevelMultiple) {
      setFieldValue("seniorityLevelMultiple", 1);
    }

    if (values.shareClassTypeId === ShareClassTypes.preferred && !values.participatingCap) {
      setFieldValue("participatingCap", 0);
    }
  }, [
    setFieldValue,
    values.participatingCap,
    values.seniorityLevel,
    values.seniorityLevelMultiple,
    values.shareClassTypeId,
  ]);

  return (
    <Helper>
      <SlidePanel.Section title={t("title")} isSingle>
        <ShareClassForm.Form>
          <Row className="mb-5">
            <Col md={6}>
              <Helper.Question questionId="shareClassName" type="input">
                <ShareClassForm.NameField />
              </Helper.Question>
              <Helper.Answer
                withRightMargin
                className="mt-3"
                answerId="shareClassName"
                text={t.el("nameAnswer")}
                linkText={t("nameReadMore")}
                link="/"
              />
            </Col>
          </Row>

          <Row className="mb-5">
            <Col>
              <Helper.Question questionId="shareClassDescription" type="input">
                <ShareClassForm.PurposeField />
              </Helper.Question>
            </Col>
            <Col md={12}>
              <Helper.Answer
                withRightMargin
                className="mt-3"
                answerId="shareClassDescription"
                text={t.el("descriptionAnswer")}
                linkText={t("descriptionReadMore")}
                link="/"
              />
            </Col>
          </Row>

          <ShareClassForm.ShareClassTypeField className="mb-5" />

          <div>
            <Row className="mb-3">
              <Col md={7}>
                <Helper.Question questionId="HasVotingRights" type="switcher">
                  <ShareClassForm.HasVotingRightsField />
                </Helper.Question>
              </Col>
              <Col md={12}>
                <Helper.Answer
                  answerId="HasVotingRights"
                  text={t("hasVotingRightsAnswer")}
                  linkText={t("hasVotingRightsReadMore")}
                  link="/"
                  className={"mt-3"}
                />
              </Col>
            </Row>

            <Row>
              <Col md={7}>
                <Helper.Question questionId="VotingRights" type="input">
                  <ShareClassForm.VotingRightsMultipleField />
                </Helper.Question>
              </Col>
              <Col md={12}>
                <Helper.Answer
                  className={"mt-3"}
                  answerId="VotingRights"
                  text={t("multipleVotingRightsAnswer")}
                  linkText={t("multipleVotingRightsReadMore")}
                  link="/"
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col md={7}>
                <Helper.Question questionId="HasDividends" type="switcher">
                  <ShareClassForm.HasDividendsField />
                </Helper.Question>
              </Col>
              <Col md={12}>
                <Helper.Answer
                  className={"mt-3"}
                  answerId="HasDividends"
                  text={t("hasDividendsAnswer")}
                  linkText={t("hasDividendsReadMore")}
                  link="/"
                />
              </Col>
            </Row>
          </div>

          <LiquidationPreferences />
        </ShareClassForm.Form>
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button
          className="me-3"
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
        >
          {t(editing ? "saveChanges" : "create")}
        </Button>

        <Button variant="secondary" onClick={onCancel}>
          {t("cancel")}
        </Button>
      </SlidePanel.Actions>
    </Helper>
  );
};

export type FormProps = Pick<ShareClassFormProps, "onSubmit"> &
  Omit<ContentProps, "editing"> & {
    shareClass?: ShareClass;
    onSave?(shareClass: ShareClass): void;
  };

const Form: FC<FormProps> = ({ shareClass, onSubmit, onCancel }) => {
  const initialValues = useMemo<ShareClassFormData>(
    () =>
      shareClass
        ? ShareClassService.responseToUpdateData(shareClass)
        : ({
            participatingCap: 0,
          } as ShareClassFormData),
    [shareClass]
  );

  return (
    <ShareClassForm initialValues={initialValues} onSubmit={onSubmit}>
      <Content editing={!!shareClass} onSubmit={onSubmit} onCancel={onCancel} />
    </ShareClassForm>
  );
};

export default Form;
