import { ChangeEventHandler, FC, useCallback, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";

import { Button } from "common/components/atoms";
import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import { ChevronDownIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext, { OwnershipEntities } from "../../../MyPortfolioContext";
import classes from "./Filter.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.filter");

const Filter: FC = () => {
  const { getPortfolioThunk } = MyPortfolioContext.useStoreActions((actions) => actions);
  const ownershipEntities = MyPortfolioContext.useStoreState((state) => state.portfolio?.ownershipEntities);

  const [items, setItems] = useState<OwnershipEntities[]>([]);

  useEffect(() => {
    setItems(ownershipEntities || []);
  }, [ownershipEntities]);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      let filterData: OwnershipEntities[] = [];
      const data = items.map((item) => {
        if (item.id === Number(e.target.value)) {
          return {
            ...item,
            isActive: !item.isActive,
          };
        }
        return item;
      });
      setItems(data);

      filterData = data.filter((item) => item.isActive);
      getPortfolioThunk(filterData).catch((e) => {
        console.log(e);
      });
    },
    [getPortfolioThunk, items]
  );

  if (!items.length) return null;

  return (
    <Dropdown align="end" id="filter">
      <Dropdown.Toggle as="div">
        <Button variant="tertiary" iconRight={<ChevronDownIcon />}>
          {t("title")}
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu className={classes.menu}>
        <ChecksGroup label={t("title")}>
          {items.map((item) => (
            <ChecksGroup.Check
              checked={item.isActive}
              key={item.id}
              label={item.name}
              value={item.id}
              onChange={handleChange}
            />
          ))}
        </ChecksGroup>
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default Filter;
