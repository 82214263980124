import { FC, useCallback, useState } from "react";
import axios from "axios";

import { Button, ModalInfo, P, Ui } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import classes from "./SendTaxableValueRequest.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.ownership.table");

type SendTaxableValueRequestProps = {
  request: "receivedRequest" | "requested" | "notRequested";
  value: string;
  companyName: string;
  companyId: number;
};

const SendTaxableValueRequest: FC<SendTaxableValueRequestProps> = ({ request, value, companyName, companyId }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleRequest = useCallback(() => {
    setShowModal((prev) => !prev);
  }, []);

  const handleSendRequest = useCallback(() => {
    setLoading(true);
    axios
      .post("/api/portfolio/request-taxable-value", { companyId })
      .then(() => {
        handleRequest();
        notify(t("sendTaxableValueRequest.requestSuccessfullySend"), true, "success");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [companyId, handleRequest]);

  return (
    <>
      {request === "receivedRequest" && (
        <Ui.s tag="span" color="primary">
          {value}
        </Ui.s>
      )}
      {request === "requested" && (
        <Ui.s tag="span" color="primary">
          {t("requested")}
        </Ui.s>
      )}
      {request === "notRequested" && (
        <Ui.s className={classes.link} tag="span" onClick={handleRequest}>
          {t("request")}
        </Ui.s>
      )}

      <ModalInfo
        show={showModal}
        header={t("sendTaxableValueRequest.title", { companyName })}
        footer={
          <>
            <Button isDisabled={loading} isLoading={loading} onClick={handleSendRequest}>
              {t("sendTaxableValueRequest.sendRequest")}
            </Button>
            <Button isDisabled={loading} variant="secondary" onClick={handleRequest}>
              {t("sendTaxableValueRequest.cancel")}
            </Button>
          </>
        }
      >
        <P.m>{t("sendTaxableValueRequest.description")}</P.m>
      </ModalInfo>
    </>
  );
};

export default SendTaxableValueRequest;
