import { FC, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationResult, EventMessage, EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import classNames from "classnames";
import { motion } from "framer-motion";
import * as R from "ramda";

import authProvider from "common/authentication/authProvider";
import UnlistedToastContainer from "common/components/atoms/UnlistedToastContainer/UnlistedToastContainer";
import { handleLogout } from "common/utils/functions";
import { useStoreActions, useStoreState } from "store/store";

import classes from "./App.module.scss";
import Router from "./Router";

const App: FC = () => {
  const { accounts, instance } = useMsal();

  const { isWizardLayout, isPublicLayout, isDarkTheme } = useStoreState((state) => state.app);
  const { isAuthenticated } = useStoreState((state) => state.authentication);
  const { setCacheChecked } = useStoreActions((actions) => actions.authentication);
  const getAccountThunk = useStoreActions((actions) => actions.account.getAccountThunk);
  const { setActiveAccount } = useStoreActions((actions) => actions.authentication);
  const setRedirectRoute = useStoreActions((state) => state.afterSignup.setPageForRedirect);

  useEffect(() => {
    authProvider.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        if (payload.account != null) {
          authProvider.setActiveAccount(payload.account);
          setActiveAccount(payload.account);
        }
      }
    });
  }, [setActiveAccount]);

  useEffect(() => {
    document.title = process.env.REACT_APP_TITLE || "Unlisted.ai";

    authProvider
      .handleRedirectPromise()
      .then(() => {
        authProvider.setActiveAccount(accounts[0]);
      })
      .then(() => {
        if (R.isEmpty(accounts)) {
          setCacheChecked(true);
        } else {
          setActiveAccount(accounts[0]);
        }
      })
      .catch(() => {
        setRedirectRoute(window.location.pathname);
        handleLogout(instance);
      });
  }, [setCacheChecked, setActiveAccount, accounts, setRedirectRoute, instance]);

  useEffect(() => {
    if (isAuthenticated) {
      getAccountThunk({ withAdditionalDataLoading: true });
    }
  }, [getAccountThunk, isAuthenticated]);

  return (
    <div
      className={classNames(classes["main-wrap"], {
        [classes["wizard"]]: isWizardLayout || isDarkTheme,
        [classes["public"]]: isPublicLayout,
      })}
    >
      {/* <NotificationProvider /> */}

      <motion.div className={classes["main-container"]}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </motion.div>

      <UnlistedToastContainer />
    </div>
  );
};

export default withTranslation()(App);
