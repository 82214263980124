import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import { defaultTo } from "ramda";

import { formatNumber } from "../common/hooks/useFormatNumbers";
import { config } from "./";

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    ...config,
  })
  .then();

i18next.services.formatter?.add("if", (value, lng, { trueValue, falseValue }): string =>
  value ? trueValue : falseValue
);

i18next.services.formatter?.add(
  "getFrom",
  // eslint-disable-next-line import/no-named-as-default-member
  (value, lng, { ns, from }): string => i18next.t(`${from}.${value}`, { ns })
);

i18next.services.formatter?.add("formatNumber", (value, lng, { thousandsSeparator }): string => {
  switch (thousandsSeparator) {
    case "space":
      thousandsSeparator = " ";
      break;
    case "comma":
      thousandsSeparator = ",";
      break;
    case "dot":
      thousandsSeparator = ".";
      break;
  }

  return formatNumber(value);
});

i18next.services.formatter?.add("defaultTo", (val, lng, { value }): string => defaultTo(value)(val));
