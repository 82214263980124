import { FC, useCallback, useEffect, useMemo } from "react";
import { Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import * as R from "ramda";

import { getAttractPath, getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H, Ui } from "common/components/atoms/Typography";
import useMediaQuery from "common/hooks/useMediaQuery";
import { ImageIcon, LinkIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { DEFAULT_CURRENCY_CODE, localStoragePublicCompanyKey, scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";

import classes from "./CompanyDetails.module.scss";
import CompanyInfoCard from "./components/CompanyInfoCard/CompanyInfoCard";
import SingleTeamItem from "./components/SingleTeamItem";
import SocialsSection, { Socials } from "./components/SocialsSection/SocialsSection";
import TeamInfoCard from "./components/TeamInfoCard/TeamInfoCard";

const CompanyDetails: FC = () => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery("(max-width: 1199px)");
  const { t } = useTranslation(TranslationNS.pages, {
    keyPrefix: "public.companyDetails",
  });
  const { id } = useParams<{ id: string }>();

  const { isAuthenticated } = useStoreState((state) => state.authentication);
  const { HubCompanyDetails, HubEvents } = useStoreState((state) => state.company);
  const { user } = useStoreState((state) => state.account);
  const { setIsPublicLayout } = useStoreActions((state) => state.app);
  const { getHubCompanyDetailsThunk, setHubCompanyDetails, getHubEventsThunk } = useStoreActions(
    (state) => state.company
  );

  const hubEventID = useMemo(() => R.defaultTo(1, HubEvents?.[0]?.id), [HubEvents]);

  const tags = useMemo(
    () => (!R.isNil(HubCompanyDetails) ? R.concat(HubCompanyDetails.verticals, HubCompanyDetails.industries) : []),
    [HubCompanyDetails]
  );

  const socials = useMemo(() => {
    return {
      [Socials.facebook]: HubCompanyDetails?.metaUrl,
      [Socials.twitter]: HubCompanyDetails?.twitterHandle,
      [Socials.medium]: HubCompanyDetails?.mediumUrl,
      [Socials.linkedIn]: HubCompanyDetails?.linkedInUrl,
      [Socials.instagram]: HubCompanyDetails?.instagramUrl,
    };
  }, [HubCompanyDetails]);

  const primaryBusinessModel = useMemo(() => {
    return HubCompanyDetails?.businessModels?.find((el) => el.sortingIndex === 0);
  }, [HubCompanyDetails]);

  const isDetailsSectionExist = useMemo(() => {
    return (
      Boolean(HubCompanyDetails?.companyPhaseName) ||
      Boolean(HubCompanyDetails?.headquartersCity) ||
      Boolean(primaryBusinessModel) ||
      Boolean(HubCompanyDetails?.headquartersCountryName)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [HubCompanyDetails]);

  const onUnAuthenticateHandler = () => {
    localStorage.setItem(localStoragePublicCompanyKey, String(id));
  };

  const hasAccessToTheCompany = useMemo(
    () => isAuthenticated && !R.isNil(user?.companies.find((el) => el.id === Number(id))),
    [isAuthenticated]
  );

  const renderTags = useCallback(() => {
    return !R.isEmpty(tags) ? (
      <div className="d-flex flex-wrap">
        {tags?.map((el, index) => {
          return (
            <div
              key={`Single key for company details tag is - ${el.id} ${index}`}
              className={cn("d-flex align-items-center justify-content-center mb-1", classes["single-detail-tag"])}
            >
              <Ui.xs style={{ color: scssVariables.information900 }}>{el.name}</Ui.xs>
            </div>
          );
        })}
      </div>
    ) : null;
  }, [tags]);

  const renderCompanyInfoColumn = useCallback(() => {
    return (
      <Col sm={12} xl={3} className="mt-6">
        <CompanyInfoCard
          goalMax={R.defaultTo(0, HubCompanyDetails?.funding?.fundingTargetAmount)}
          pitch={HubCompanyDetails?.pitchDeckFilePath}
          currencyCode={HubCompanyDetails?.funding?.currencyCode || DEFAULT_CURRENCY_CODE}
          calendlyLink={HubCompanyDetails?.calendlyUrl}
          closingDate={HubCompanyDetails?.funding?.closingDatePreferred}
          fundingStatus={R.defaultTo("-", HubCompanyDetails?.funding?.fundraisingStatusName)}
          fundingInstrument={R.defaultTo("-", HubCompanyDetails?.funding?.fundingInstrumentName)}
          stage={R.defaultTo("-", HubCompanyDetails?.funding?.fundingStageName)}
          onUnAuthClick={onUnAuthenticateHandler}
          companyId={id}
        />
        {!isMobile && (
          <TeamInfoCard
            contactPerson={HubCompanyDetails?.contactPerson ?? null}
            team={R.defaultTo([], HubCompanyDetails?.otherTeamMembers)}
            onUnAuthClick={onUnAuthenticateHandler}
          />
        )}
      </Col>
    );
  }, [isMobile, HubCompanyDetails]);

  const renderTeamMembers = useCallback(() => {
    return !R.isNil(HubCompanyDetails?.boardMembers) && !R.isEmpty(HubCompanyDetails?.boardMembers) ? (
      <div className="mt-9">
        <H.xxxs className="mb-3">{t("boardAndAdvisors")}</H.xxxs>

        <div className="d-flex flex-wrap">
          {HubCompanyDetails?.boardMembers?.map((el) => {
            return (
              <SingleTeamItem
                key={el.id}
                imgSize={96}
                linkedInUrl={el?.linkedInUrl}
                positionTitle={el?.positionTitle}
                name={el.firstName}
                lastName={el.lastName}
                style={{
                  marginRight: 64,
                  marginBottom: 50,
                }}
                img={
                  el?.profileImageFilePath
                    ? `${process.env.REACT_APP_BLOB_PUBLIC_URL}${el.profileImageFilePath}`
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    ) : null;
  }, [HubCompanyDetails?.boardMembers, t]);

  useEffect(() => {
    setIsPublicLayout(true);

    getHubCompanyDetailsThunk(Number(id));

    return () => {
      setHubCompanyDetails(null);
      setIsPublicLayout(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && id) {
      getHubEventsThunk(Number(id));
    }
  }, [id, isAuthenticated]);

  return (
    <>
      <PageContent className={classes["cmp-details"]} data-testid="company-details-public-page-test-id">
        {isMobile && (
          <Button
            size="s"
            iconLeft
            className="mt-3"
            variant="tertiary"
            data-testid="go-back-btn-test-id"
            onClick={() => {
              navigate(getPath(["event"], { id: hubEventID }));
            }}
          >
            {t("back")}
          </Button>
        )}
        {!R.isNil(HubCompanyDetails) ? (
          <>
            <div className={`d-flex justify-content-lg-between p-0 mt-${isMobile ? 3 : 8}`}>
              <Col sm={12} xl={8}>
                <div className="d-flex align-items-start justify-content-between">
                  {HubCompanyDetails?.logoImageFilePath ? (
                    <Image
                      className={classes["cmp-details-img"]}
                      src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${HubCompanyDetails?.logoImageFilePath}`}
                    />
                  ) : (
                    <div
                      className={cn(
                        "d-flex align-items-center justify-content-center",
                        classes["cmp-details-empty-img"]
                      )}
                    >
                      <ImageIcon />
                    </div>
                  )}

                  {hasAccessToTheCompany && (
                    <Button
                      variant="secondary"
                      className={classes["details-edit-btn"]}
                      onClick={() => {
                        navigate(getAttractPath(["general"], { companyId: id }));
                      }}
                    >
                      <Ui.s>{t("edit")}</Ui.s>
                      <LinkIcon style={{ marginLeft: 12 }} color={scssVariables.primary1} />
                    </Button>
                  )}
                </div>

                <H.xxxs className="mt-5 mb-2">{HubCompanyDetails?.name}</H.xxxs>

                {renderTags()}

                <Ui.xs className={cn("mt-1 mb-2", classes["cmp-details-oneliner"])}>
                  {HubCompanyDetails?.oneliner}
                </Ui.xs>

                <SocialsSection {...socials} />

                {HubCompanyDetails?.elevatorPitch ? (
                  <>
                    <H.xxxs className="mt-7 mb-2">{t("introduction")}</H.xxxs>
                    <Ui.xs className="mb-7">{HubCompanyDetails?.elevatorPitch}</Ui.xs>
                  </>
                ) : null}

                {isMobile && renderCompanyInfoColumn()}

                {!R.isNil(HubCompanyDetails?.introVideoUrl) ? (
                  <div className={cn(classes["cmp-details-video"], "my-7")}>
                    <ReactPlayer
                      controls
                      width="100%"
                      height="100%"
                      className={classes["cmp-details-video-player"]}
                      url={HubCompanyDetails?.introVideoUrl}
                    />
                  </div>
                ) : null}

                {!R.isNil(HubCompanyDetails?.mission) ? (
                  <>
                    <H.xxxs className="mt-7 mb-2">{t("mission")}</H.xxxs>
                    <Ui.xs className="mb-7">{HubCompanyDetails?.mission}</Ui.xs>
                  </>
                ) : null}

                {!R.isNil(HubCompanyDetails?.vision) ? (
                  <>
                    <H.xxxs className="mt-7 mb-2">{t("vision")}</H.xxxs>
                    <Ui.xs className="mb-7">{HubCompanyDetails?.vision}</Ui.xs>
                  </>
                ) : null}

                {!R.isNil(HubCompanyDetails?.problem) ? (
                  <>
                    <H.xxxs className="mt-7 mb-2">{t("problemSolve")}</H.xxxs>
                    <Ui.xs className="mb-7">{HubCompanyDetails?.problem}</Ui.xs>
                  </>
                ) : null}

                {!R.isNil(HubCompanyDetails?.solution) ? (
                  <>
                    <H.xxxs className="mt-7 mb-2">{t("ourSolution")}</H.xxxs>
                    <Ui.xs className="mb-7">{HubCompanyDetails?.solution}</Ui.xs>
                  </>
                ) : null}

                {!R.isNil(HubCompanyDetails?.competitiveAdvantage) ? (
                  <>
                    <H.xxxs className="mt-7 mb-2">{t("competitiveAdvantage")}</H.xxxs>
                    <Ui.xs className="mb-7">{HubCompanyDetails?.competitiveAdvantage}</Ui.xs>
                  </>
                ) : null}

                {isMobile && (
                  <TeamInfoCard
                    contactPerson={HubCompanyDetails?.contactPerson ?? null}
                    team={R.defaultTo([], HubCompanyDetails?.otherTeamMembers)}
                    onUnAuthClick={onUnAuthenticateHandler}
                  />
                )}

                {!R.isNil(HubCompanyDetails?.teamDescription) ? (
                  <>
                    <H.xxxs className="mt-7 mb-2">{t("teamDescription")}</H.xxxs>
                    <Ui.xs className="mb-7">{HubCompanyDetails?.teamDescription}</Ui.xs>
                  </>
                ) : null}

                {isDetailsSectionExist ? (
                  <div className={cn(classes["cmp-details-details"], "my-7")}>
                    <H.xxxs className="mb-3">{t("details")}</H.xxxs>

                    <div className="d-flex">
                      <div className={classes["first-subsection"]}>
                        <div className={classes["headquarters"]}>
                          <Ui.xs bold className="mb-1">
                            {t("headquarters")}
                          </Ui.xs>

                          <Ui.xs className="mb-4">{`${R.defaultTo("", HubCompanyDetails?.headquartersCity)}${
                            HubCompanyDetails?.headquartersCountryName
                              ? HubCompanyDetails?.headquartersCity
                                ? ", " + HubCompanyDetails.headquartersCountryName
                                : "" + HubCompanyDetails.headquartersCountryName
                              : ""
                          }`}</Ui.xs>
                        </div>

                        {HubCompanyDetails?.companyPhaseName ? (
                          <div className={classes["companyPhase"]}>
                            <Ui.xs bold className={cn("mt-4 mb-1", classes["phase"])}>
                              {t("companyPhase")}
                            </Ui.xs>

                            <Ui.xs>{HubCompanyDetails?.companyPhaseName}</Ui.xs>
                          </div>
                        ) : null}
                      </div>

                      <div
                        className={classes["vertical-line-container"]}
                        style={{
                          position: "relative",
                          margin: "0 144px",
                        }}
                      >
                        <div className={classes["vertical-line"]} />
                      </div>

                      <div>
                        {primaryBusinessModel ? (
                          <>
                            <Ui.xs bold className="mb-1">
                              {t("businessModel")}
                            </Ui.xs>

                            <Ui.xs>{primaryBusinessModel.name}</Ui.xs>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ) : null}

                {renderTeamMembers()}

                {!R.isNil(HubCompanyDetails?.boardDescription) ? (
                  <>
                    <H.xxxs className="mt-1 mb-2">{t("boardDescription")}</H.xxxs>
                    <Ui.xs className="mb-10">{HubCompanyDetails?.boardDescription}</Ui.xs>
                  </>
                ) : null}
              </Col>

              {!isMobile && renderCompanyInfoColumn()}
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </PageContent>
    </>
  );
};

export default CompanyDetails;
