import { useMemo } from "react";
import * as Yup from "yup";

import { createTranslation, TranslationNS } from "../../../../translation";

export type ThreeStepForm = {
  stakeholderInfoCorrect: boolean;
  documents: boolean[];
  signature: File | null;
  documentsNeedsSignature: boolean;
};

export type Stakeholder = {
  firstName: string;
  lastName: string;
  usingHoldingCompany: boolean;
  holdingCompanyName: string;
  organizationNumber: string;
  email: string;
  relationshipWithCompany: string;
  address?: string;
  country?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  businessPostAddress?: string;
};

const t = createTranslation(TranslationNS.common, "receive.stakeholder.stepValidation");

const useStepForm = <T = object,>(documentsLength: number, documentsNeedsSignature: boolean, otherValues?: T) => {
  const initialValues = useMemo<ThreeStepForm & T>(
    () => ({
      stakeholderInfoCorrect: false,
      documents: documentsLength ? new Array<boolean>(documentsLength).fill(false) : [],
      signature: null,
      documentsNeedsSignature,
      ...(otherValues || ({} as T)),
    }),
    [documentsLength, documentsNeedsSignature, otherValues]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        stakeholderInfoCorrect: Yup.boolean().oneOf([true], t("stakeholderCorrect")),
        documents: Yup.array().when("documentsNeedsSignature", {
          is: true,
          then: Yup.array().of(Yup.boolean().oneOf([true], t("documentsCorrect"))),
        }),
        signature: Yup.mixed().when("documentsNeedsSignature", {
          is: true,
          then: Yup.mixed().required(t("signatureCorrect")),
        }),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useStepForm;
