import { FC, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { DatePicker, Divider, Helper } from "common/components/atoms";
import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import FileUploader, { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { P } from "common/components/atoms/Typography";
import { DocumentStatusEnum } from "common/enums/enum";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { ConvertNoteFormValues } from "../useConvertNoteForm";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertNote.documentation");

const Documentation: FC = () => {
  const { values, errors, touched, handleChange, setFieldValue, handleBlur } =
    useFormikContext<ConvertNoteFormValues>();

  const handleChangeFile = useCallback(
    (data: FilesDataMultiple) => {
      setFieldValue("documents", data);
    },
    [setFieldValue]
  );

  const handleChangeDate = useCallback(
    (date: string | Date, name?: string) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  const errorFile = errors.documents as { newFiles?: string; oldFiles?: string };
  return (
    <div>
      <SlidePanel.Section title={t("title")} isDividerVisible={false}>
        <Helper>
          <P.m>{t("description")}</P.m>
          <InputHeader className="mt-4" label="Note conversion documentation" />
          <ChecksGroup.Check
            type="radio"
            label={t("upload.label")}
            description={t("upload.description")}
            checked={values.documentStatusId == DocumentStatusEnum.DOCUMENT_UPLOADED}
            name={f.documentStatusId}
            value={DocumentStatusEnum.DOCUMENT_UPLOADED}
            onChange={handleChange}
          />
          <ChecksGroup.Check
            type="radio"
            label={t("later.label")}
            description={t("later.description")}
            checked={values.documentStatusId == DocumentStatusEnum.REVIEW_LATER}
            name={f.documentStatusId}
            value={DocumentStatusEnum.REVIEW_LATER}
            onChange={handleChange}
          />
          <ChecksGroup.Check
            type="radio"
            label={t("notRequired.label")}
            description={t("notRequired.description")}
            checked={values.documentStatusId == DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
            name={f.documentStatusId}
            value={DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
            onChange={handleChange}
          />
          {values.documentStatusId == DocumentStatusEnum.DOCUMENT_UPLOADED && (
            <FileUploader
              className={"mt-3"}
              label={t("uploadAgreement.label")}
              multiple={true}
              onChange={handleChangeFile}
              prevFileData={values.documents?.oldFiles}
              error={(errorFile?.newFiles as string) || errorFile?.oldFiles}
              isTouched={touched.documents as unknown as boolean}
            />
          )}
          <Divider />
          <Row>
            <Col md={6}>
              <Helper.Question questionId={"shareIssueDate"} type="input">
                <DatePicker
                  isDateOnlyString
                  label={t("shareIssueDate.label")}
                  name={"shareIssueDate"}
                  date={values.shareIssueDate}
                  onChange={handleChangeDate}
                  onBlur={handleBlur}
                  error={errors.shareIssueDate}
                  isTouched={touched.shareIssueDate}
                />
              </Helper.Question>
            </Col>
            <Col md={12}>
              <Helper.Answer className="mt-4" answerId={"shareIssueDate"} text={t("shareIssueDate.answer")} />
            </Col>
          </Row>
          <P.m className="mt-5">{t("saveDescription")}</P.m>
        </Helper>
      </SlidePanel.Section>
    </div>
  );
};
export default Documentation;
