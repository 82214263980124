import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { format as formatFns } from "date-fns";
import { useFormikContext } from "formik";

import { Dropdown } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import { OptionsType } from "common/components/atoms/Dropdown/Dropdown";
import useFormatNumbers from "common/hooks/useFormatNumbers";

import { useTransactionsService } from "../../../../pages/equity-management/ownership/transactions/transactions-service";
import { AvailableShareClass } from "../../../../pages/equity-management/ownership/transactions/types";
import { PoolFormValues } from "../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { PoolTypesOfSource } from "../../../enums/enum";

type ExistingShareClassesFieldProps = {
  className?: string;
};
const PoolExistingShareClassesField: FC<ExistingShareClassesFieldProps> = ({ className }) => {
  const { companyId } = useParams<{ companyId: string }>();
  const [shareClasses, setShareClasses] = useState<OptionsType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableShareClasses, setAvailableShareClasses] = useState<AvailableShareClass[]>([]);
  const fNumber = useFormatNumbers();

  const { getAvailableShareClasses } = useTransactionsService(companyId || "0");

  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext<Pick<PoolFormValues, "shareSourceTypeId" | "shareClassId" | "shareSourceStakeholderId">>();

  useEffect(() => {
    if (!values.shareSourceStakeholderId) {
      setShareClasses([]);
    }
  }, [values.shareSourceStakeholderId]);

  useEffect(() => {
    if (values.shareSourceStakeholderId && availableShareClasses.length) {
      const item = availableShareClasses.find((shareClass) => shareClass.shareClassId === values.shareClassId);
      if (item) {
        setFieldValue("allowedNumberOfShares", item.sharesAvailable);
      }
    }
  }, [availableShareClasses, setFieldValue, values.shareClassId, values.shareSourceStakeholderId]);

  useEffect(() => {
    if (values.shareSourceTypeId === PoolTypesOfSource.existing && values.shareSourceStakeholderId) {
      setIsLoading(true);
      getAvailableShareClasses({
        companyId: Number(companyId),
        stakeholderId: values.shareSourceStakeholderId,
        transactionDate: formatFns(new Date(), dateFormatWithTime),
      })
        .then((response) => {
          const data = response.map((shareClass) => ({
            id: shareClass.shareClassId,
            name: `${shareClass.shareClassName} (${fNumber(shareClass.sharesAvailable, "amount")})`,
          }));
          setShareClasses(data);
          setAvailableShareClasses(response);
          setFieldValue("shareClassId", data[0]?.id);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [
    companyId,
    fNumber,
    getAvailableShareClasses,
    setFieldValue,
    values.shareSourceStakeholderId,
    values.shareSourceTypeId,
  ]);

  return (
    <Dropdown
      className={className}
      label="Share class"
      options={shareClasses}
      optionsIsObject
      selectedValue={values.shareClassId}
      name="shareClassId"
      onChange={handleChange}
      onBlur={handleBlur}
      isTouched={touched.shareClassId}
      error={errors.shareClassId}
      isLoading={isLoading}
      isDisabled={isLoading || !values.shareSourceStakeholderId}
    />
  );
};

export default PoolExistingShareClassesField;
