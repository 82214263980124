import { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import AdminRouter from "pages/admin/AdminRouter";
// import CompanyAdmin from "pages/admin/company/CompanyAdmin";
import AttractRouter from "pages/attract/attractRouter";
import AutoSignInPage from "pages/auto-sign-in/auto-sign-in";
import CreateShareClass from "pages/equity-management/ownership/share-classes/create-share-class/CreateShareClass";
import CreateGrantOneOffPlan from "pages/equity-management/plans/wizards/create-one-off-plans/CreateGrantOneOffPlan";
import CreatePlanWrapper from "pages/equity-management/plans/wizards/create-plan/create-plan-wrapper";
// import CreatePool from "pages/equity-management/plans/wizards/create-pool/CreatePool";
import CreateProgram from "pages/equity-management/plans/wizards/create-program/CreateProgram";
import InvitationsRouter from "pages/invitations/InvitationsRouter";
import ReceivingInvitationsRouter from "pages/receiving/ReceivingInvitationsRouter";
import UniMicroLandingPage from "pages/unimicro/unimicro-landing";
import UserRouter from "pages/user/userRouter";
import { useStoreState } from "store/store";

import LoaderContainer from "../common/components/atoms/LoaderContainer/LoaderContainer";
import EquityManagementRouter from "../pages/equity-management/EquityManagementRouter";
import CapitalIncreaseWizard from "../pages/equity-management/ownership/transactions/components/forms/capital-increase/capital-increase-wizard";
import CreatePool from "../pages/equity-management/plans/wizards/create-pool/CreatePool";
import MyPortfolioRouter from "../pages/my-porfolio/MyPortfolioRouter";
import OnboardRouter from "../pages/onboard/onboardRouter";
import CompanyDetails from "../pages/public/company-details/CompanyDetails";
import CompanyList from "../pages/public/company-list/CompanyList";
import SubscriptionAccepted from "../pages/public/subscription-accepted/SubscriptionAccepted";
import { Languages } from "../translation";
import CompanyCheckerRoute from "./CompanyCheckerRoute";
import AccessMiddlewareEM from "./middleware/AccessMiddleware/AccessMiddlewareEM";
import useAuthMiddleware from "./middleware/AuthMiddleware";
import useOnboardingMiddleware from "./middleware/OnboardingMiddleware";
import useCompanyDetailsPublic from "./middleware/UseCompanyDetailsPublic";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root/Root";
import { getPath } from "./Router/RouterHelper";
import { publicRouteList, ROUTER_V2 } from "./Router/RouterV2.types";

const Router: FC = () => {
  const navigate = useNavigate();

  const userStore = useStoreState((state) => state.account.user);

  useOnboardingMiddleware();
  const isLoading = useAuthMiddleware();

  useCompanyDetailsPublic();

  const CompanyOnboard = useCallback(() => {
    if (userStore?.companies[0]?.id) {
      navigate(getPath(["equityManagement", "gettingStartedGuide"], { companyId: userStore.companies[0].id }));
    } else {
      navigate(getPath(["user", "dashboard"]));
    }
    return null;
  }, [navigate, userStore?.companies]);

  const Storybook = useCallback(() => {
    window.location.href = `${process.env.REACT_APP_BLOB_STORYBOOK_URL}`;
    return null;
  }, []);

  // TODO Temporary force all users to use eng version since we removed language selector
  // and user can be end up locked with norwegian language
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(Languages.en);
  }, [i18n]);

  return (
    <LoaderContainer loading={isLoading}>
      <Routes>
        <Route path={publicRouteList.subscriptionAccepted} element={<SubscriptionAccepted />} />

        <Route path="/" element={<Root />}>
          <Route path={ROUTER_V2.signIn.base} element={<AutoSignInPage />} />
          <Route element={<PrivateRoute />}>
            <Route path={ROUTER_V2.uniMicroLanding.base} element={<UniMicroLandingPage />} />
            <Route path={ROUTER_V2.user.full} element={<UserRouter />} />
            <Route path={ROUTER_V2.onboard.base} element={<CompanyOnboard />} />
            <Route path={ROUTER_V2.invitation.full} element={<InvitationsRouter />} />
            <Route path={ROUTER_V2.onboard.full} element={<OnboardRouter />} />
            <Route path={ROUTER_V2.companyProfile.full} element={<CompanyCheckerRoute />}>
              <Route path="*" element={<AttractRouter />} />
            </Route>
            <Route path={ROUTER_V2.attract.full} element={<CompanyCheckerRoute />}>
              <Route path="*" element={<AttractRouter />} />
            </Route>
            <Route path={ROUTER_V2.equityManagement.full} element={<CompanyCheckerRoute />}>
              <Route element={<AccessMiddlewareEM />}>
                <Route path={ROUTER_V2.equityManagement.createPool.relativeFull} element={<CreatePool />} />
                <Route path={ROUTER_V2.equityManagement.createProgram.relativeFull} element={<CreateProgram />} />
                <Route path={ROUTER_V2.equityManagement.createShareClass.relativeFull} element={<CreateShareClass />} />
                <Route path={ROUTER_V2.equityManagement.createPlan.relativeFull} element={<CreatePlanWrapper />} />
                <Route
                  path={ROUTER_V2.equityManagement.createOneOffPlan.relativeFull}
                  element={<CreateGrantOneOffPlan />}
                />
                <Route
                  path={ROUTER_V2.equityManagement.createCapitalIncrease.relativeFull}
                  element={<CapitalIncreaseWizard />}
                />
              </Route>

              <Route path="*" element={<EquityManagementRouter />} />
            </Route>
            <Route path={ROUTER_V2.myPortfolio.full} element={<MyPortfolioRouter />} />
            <Route path={ROUTER_V2.receive.full} element={<ReceivingInvitationsRouter />} />
            <Route path={ROUTER_V2.storybook.base} element={<Storybook />} />
          </Route>
          <Route path={ROUTER_V2.admin.full} element={<AdminRouter />} />
          <Route path={ROUTER_V2.companyDetails.base} element={<CompanyDetails />} />
          <Route path={ROUTER_V2.event.base} element={<CompanyList />} />

          <Route path="*" element={<Navigate to="" />} />
        </Route>
      </Routes>
    </LoaderContainer>
  );
};

export default Router;
