import { FC, ReactNode, useMemo } from "react";
import { Datum, LineSvgProps, PointTooltip, ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";
import * as R from "ramda";

import theme from "common/components/atoms/Charts/theme";
import { Ui } from "common/components/atoms/Typography";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../../../../useProgramForm";
import classes from "./TimeBasedChart.module.scss";

export type TimeBasedChartProps = {
  formData: Partial<CreateProgramFormData>;
};

type TimeBasedChartDatum = Datum & {
  content?: ReactNode;
};

const t = createTranslation(TranslationNS.pages, "createProgram.summary.vestingConditions.timeBasedChart");

const CustomTooltip: PointTooltip = ({ point }) => {
  return (
    <Ui.xs className={classes["chart-tooltip"]}>
      {t("shares", {
        shares: Math.round(+point.data.y),
      })}
    </Ui.xs>
  );
};

const TimeBasedChart: FC<TimeBasedChartProps> = ({ formData }) => {
  const data = useMemo<LineSvgProps["data"]>(() => {
    const dateFormat = "MMM ‘yyyy";

    const data: TimeBasedChartDatum[] = R.defaultTo([], formData.dataPoints).map(({ x, y }) => ({
      x: format(new Date(x), dateFormat),
      y: y,
    }));

    return [
      {
        id: "Exercised",
        color: scssVariables.primary1,
        data,
      },
    ];
  }, [formData.dataPoints]);

  const bottomAxisValues = useMemo<ReactNode[]>(() => {
    const values = data[0].data.map(({ x }) => x) as string[];

    const tickValues: string[] = [values[0]];

    const step = Math.ceil((values.length - 2) / 4);

    for (let i = step; i < values.length - 1; i += step) {
      tickValues.push(values[i]);
    }

    tickValues.push(values[values.length - 1]);

    return tickValues;
  }, [data]);

  return (
    <div className={`p-3 ${classes["wrap"]}`}>
      <Ui.xs bold>{t("vestingScheduleForProgram")}</Ui.xs>

      <div className={`mb-1 ${classes["legend-wrap"]}`}>
        <div className={classes["legend"]}>
          <svg className="me-1" width="20" height="2" viewBox="0 0 20 2">
            <line y1="1" x2="20" y2="1" />
          </svg>

          <Ui.xs>{t("legend")}</Ui.xs>
        </div>

        <Ui.xs>{t.el("legendTargetVested")}</Ui.xs>
      </div>

      <div className="d-flex w-100">
        <div className={classes["y-axis"]}>
          <Ui.xs>0</Ui.xs>
        </div>

        <div className="flex-grow-1">
          <div className={classes["chart"]}>
            <ResponsiveLine
              theme={theme}
              data={data}
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: "auto",
                max: "auto",
                reverse: false,
              }}
              colors={{ datum: "color" }}
              useMesh
              crosshairType="y"
              curve="stepAfter"
              layers={["axes", "mesh", "slices", "lines", "crosshair"]}
              axisTop={null}
              axisLeft={null}
              axisRight={null}
              axisBottom={null}
              tooltip={CustomTooltip}
            />
          </div>

          <div className={classes["x-axis"]}>
            {bottomAxisValues.map((item, index) => (
              <Ui.xs key={index}>{item}</Ui.xs>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeBasedChart;
