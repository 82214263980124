import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { localStoragePublicCompanyKey } from "common/utils/constants";

import { useStoreState } from "../../store/store";
import { getPath } from "../Router/RouterHelper";

const useCompanyDetailsPublic = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useStoreState((state) => state.authentication);
  useEffect(() => {
    const publicCompanyId = JSON.parse(localStorage.getItem(localStoragePublicCompanyKey) as string);

    if (isAuthenticated && publicCompanyId) {
      localStorage.setItem(localStoragePublicCompanyKey, "null");

      navigate(getPath(["companyDetails"], { id: publicCompanyId }));
    }
  }, [isAuthenticated, navigate]);
};

export default useCompanyDetailsPublic;
