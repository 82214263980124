import { FC, useMemo } from "react";
import * as R from "ramda";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import CapTableStore from "../../store";
import classes from "./Table.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable");

const Foot: FC = () => {
  const fNumber = useFormatNumbers();
  const { dilutedData } = CapTableStore.useStoreState((state) => state);

  const isEmpty = useMemo(() => !R.isNil(dilutedData) && R.isEmpty(dilutedData.capTable), [dilutedData]);

  return (
    <tfoot className={classes.tableFoot}>
      <tr>
        <th className="ui-bold-s" colSpan={1}>
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            <Ui.s bold style={{ marginLeft: 36 }}>
              {t("totalStakeholdersActual", {
                totalStakeholders: R.defaultTo(0, dilutedData.capTable.length),
              })}
            </Ui.s>
          )}
        </th>
        <th colSpan={3} />
        <th className="ui-bold-s text-end">
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            fNumber(dilutedData.capTableSummary?.numberOfShares, "amount")
          )}
        </th>
        <th className="ui-bold-s text-end">
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            <>{fNumber(dilutedData.capTableSummary?.percentage, "percent")}</>
          )}
        </th>
        <th className="ui-bold-s text-end">
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            fNumber(dilutedData.capTableSummary?.sharesFullyDiluted, "amount")
          )}
        </th>
        <th className="ui-bold-s text-end">
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            <>{fNumber(dilutedData.capTableSummary?.ownershipFullyDiluted, "percent")}</>
          )}
        </th>
        <th className="ui-bold-s text-end">
          {isEmpty ? (
            <div className={classes["blank-item"]} />
          ) : (
            <Ui.s bold>
              {dilutedData.capTableSummary?.differenceFullyDiluted
                ? `+ ${fNumber(dilutedData.capTableSummary?.differenceFullyDiluted, "amount")}`
                : "-"}
            </Ui.s>
          )}
        </th>
      </tr>
    </tfoot>
  );
};

export default Foot;
