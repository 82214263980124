import { TabContainerProps } from "react-bootstrap/TabContainer";
import { Action, action, createContextStore, ThunkOn,thunkOn } from "easy-peasy";

export type WizardLayoutCurrentStep = number;
export type WizardLayoutSidebarContentActiveItem = Required<Pick<TabContainerProps, "activeKey">>["activeKey"];

interface WizardLayoutStoreModel {
  totalSteps: number;
  setTotalSteps: Action<this, this["totalSteps"]>;

  currentStep: WizardLayoutCurrentStep;
  setCurrentStep: Action<this, this["currentStep"]>;

  closeConfirmOpen: boolean;
  setCloseConfirmOpen: Action<this, this["closeConfirmOpen"]>;

  sidebarContentActiveItem?: WizardLayoutSidebarContentActiveItem;
  setSidebarContentActiveItem: Action<this, this["sidebarContentActiveItem"]>;

  showCloseModal: boolean;
  setShowCloseModal: Action<this, this["showCloseModal"]>;

  onSetCurrentStep: ThunkOn<this>;
}

const WizardLayoutStore = createContextStore<WizardLayoutStoreModel>(
  {
    totalSteps: 0,
    setTotalSteps: action((state, payload) => {
      state.totalSteps = payload;
    }),
    currentStep: 1,
    setCurrentStep: action((state, payload) => {
      state.currentStep = payload;
    }),

    closeConfirmOpen: false,
    setCloseConfirmOpen: action((state, payload) => {
      state.closeConfirmOpen = payload;
    }),

    showCloseModal: true,
    setShowCloseModal: action((state, payload) => {
      state.showCloseModal = payload;
    }),

    setSidebarContentActiveItem: action((state, payload) => {
      state.sidebarContentActiveItem = payload;
    }),

    onSetCurrentStep: thunkOn(
      (actions) => actions.setCurrentStep,
      (actions) => {
        actions.setSidebarContentActiveItem(undefined);
      }
    ),
  },
  {
    name: "Wizard Layout",
  }
);

export default WizardLayoutStore;
