import { FC } from "react";
import classNames from "classnames";
import { isNil } from "ramda";

import { P, Ui } from "common/components/atoms";
import { InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { getCurrentAgreementContext } from "../functions";
import classes from "./TerminationInfo.module.scss";

type PropsTypes = JSX.IntrinsicElements["div"] & {
  type: InstrumentTypesNamesEnum;
};

/**
 * Description: components to represent status for terminated agreement
 */

const t = createTranslation(TranslationNS.common, "organisms.agreement.terminationInfo");

const TerminationInfo: FC<PropsTypes> = ({ className, type, ...props }) => {
  const { agreementDetails } = getCurrentAgreementContext(type).useStoreState((state) => state);

  if (agreementDetails?.planStatusId !== PlanStatusesBasedOnAPIStatusId.terminated) {
    return null;
  }

  return (
    <div className={classNames(classes["container"], className)} {...props}>
      <Ui.l bold className="mb-2">
        {t("terminationDate", {
          terminationDate: transformDateToCommonDateFormat(agreementDetails?.terminatedAt),
        })}{" "}
        {agreementDetails?.terminatedByName ? `by ${agreementDetails?.terminatedByName}` : null}
      </Ui.l>
      <P.m>
        {t("terminationReason", {
          terminationReason: agreementDetails.terminationReason,
        })}
      </P.m>

      {!isNil(agreementDetails?.optionsExpirationDate) ? (
        <P.m className="mt-2">
          Expiry date: {transformDateToCommonDateFormat(agreementDetails?.optionsExpirationDate)}
        </P.m>
      ) : null}

      {/*  TODO: update this with API */}
      {agreementDetails?.expirationDate ? (
        <P.m className="my-1">
          {t("expirationDate", {
            date: transformDateToCommonDateFormat(agreementDetails?.expirationDate),
          })}
        </P.m>
      ) : null}

      {agreementDetails?.terminationReasonComment ? (
        <Ui.m italic className="mt-1">
          {t("terminationComment", {
            terminationComment: agreementDetails?.terminationReasonComment,
          })}
        </Ui.m>
      ) : null}
    </div>
  );
};

export default TerminationInfo;
