import { FC, useCallback, useEffect, useMemo } from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { Dropdown, H, P } from "common/components/atoms";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import PoolShareholderSearchField from "common/pool/components/PoolForm/PoolShareholderSearchField";
import { SOURCE_IS_EXISTING_SHARES_ID, SOURCE_IS_NEW_SHARES_ID } from "store/PoolModel";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";
import classes from "./SharesSource.module.scss";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.sharesSource");

const SharesSource: FC = () => {
  const navigate = useNavigate();

  const { getCompanyPoolsThunk, getPoolThunk } = useStoreActions((actions) => actions.poolModel);
  const { isGetPoolsLoading, isGetPoolLoading } = useStoreState((state) => state.poolModel);

  const companyPools = useStoreState((state) => state.poolModel.companyPools);

  const sources = useMemo(() => {
    const existingSharesSource = {
      id: SOURCE_IS_EXISTING_SHARES_ID,
      name: "Existing shares",
      numberOfSharesLeft: -1,
    };
    const newShares = {
      id: SOURCE_IS_NEW_SHARES_ID,
      name: "New shares (authorized)",
      numberOfSharesLeft: -1,
    };

    return [newShares, existingSharesSource, ...companyPools];
  }, [companyPools]);

  const handleStepBack = useCallback(() => {
    navigate(getEMPath(["createOneOffPlan", "planReceiver"]));
  }, [navigate]);

  const { values, handleSubmit, setFieldValue, errors, touched, handleChange, setFieldTouched } =
    useFormikContext<OwnershipPlanForm>();

  useEffect(() => {
    if (values.companyId) {
      getCompanyPoolsThunk(values.companyId).then();
    }
  }, [getCompanyPoolsThunk, setFieldValue, values.companyId]);

  useEffect(() => {
    if (values.poolId && values.poolId !== SOURCE_IS_NEW_SHARES_ID && values.poolId !== SOURCE_IS_EXISTING_SHARES_ID) {
      const poolId = values.poolId as number;
      getPoolThunk(poolId).then(({ data }) => {
        setFieldValue(f.shareClassId, data?.shareClassId || 0);
      });
    }
  }, [getPoolThunk, setFieldValue, values.poolId]);

  const onSubmit = () => {
    handleSubmit();
  };

  const sourceIsExistingShares = useMemo(() => {
    return values.poolId === SOURCE_IS_EXISTING_SHARES_ID;
  }, [values.poolId]);

  useEffect(() => {
    if (values.poolId !== SOURCE_IS_EXISTING_SHARES_ID) {
      setFieldValue(f.sharesFromStakeholderId, "");
      setFieldTouched(f.sharesFromStakeholderId, false, true);
    }
  }, [setFieldTouched, setFieldValue, touched, values.poolId]);

  return (
    <WizardContent.Content step={2}>
      <H.xs className="mb-4">{t("title")}</H.xs>

      <div className="d-flex">
        <div className={classNames(classes["single-section"])}>
          <P.m className="mt-1 text-medium">{t("selectFromPoolDescription")}</P.m>

          <Col xs={6}>
            <Dropdown
              className={`${classes["company-relationship"]} mt-3`}
              options={sources}
              selectedValue={values[f.poolId] as number}
              optionsIsObject
              error={errors[f.poolId]}
              isTouched={touched[f.poolId]}
              name={f.poolId}
              label={t("relationship")}
              isDisabled={isGetPoolsLoading}
              isLoading={isGetPoolsLoading}
              onChange={handleChange}
            />
          </Col>
          {sourceIsExistingShares && (
            <Col xs={6} className="mt-3">
              <PoolShareholderSearchField
                name={f.sharesFromStakeholderId}
                additionalSelectedName={f.sharesFromStakeholderName}
              />
            </Col>
          )}
        </div>
      </div>
      <div className="d-flex mt-8">
        <WizardContent.Controls.BackButton
          className="ms-auto me-2"
          onClick={handleStepBack}
          isLoading={isGetPoolLoading}
          isDisabled={isGetPoolLoading}
        />
        <WizardContent.Controls.ContinueButton
          onClick={onSubmit}
          isLoading={isGetPoolLoading}
          isDisabled={isGetPoolLoading}
        />
      </div>
    </WizardContent.Content>
  );
};

export default SharesSource;
