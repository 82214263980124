import { FC } from "react";

import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "companySettings.acceptInvitation");

const TableHeader: FC = () => {
  return (
    <thead>
      <tr>
        <th style={{ width: "85%" }}>
          <div className="ms-3">{t("accessTable.pages")}</div>
        </th>
        <th>
          <div className="me-3">{t("accessTable.access")}</div>
        </th>
      </tr>
    </thead>
  );
};

export default TableHeader;
