import { ComponentType } from "react";

import RSAAgreementContext from "common/components/organisms/agreements/contexts/RSAAgreementContext";
import SOAgreementContext from "common/components/organisms/agreements/contexts/SOAgreementContext";

import { AgreementFeatures } from "../components/organisms/agreements/types";

/**
 * Description: HOC, to wrap all the places where rsa, so and other agreements should be used
 * Main idea - is to separate logic from the place of usage as each of the agreements are independent organism
 * that accepts only @prop {agreementId} number  and @prop {type} string properties to use
 * This HOC addind additional provider/s, depends on the list you want to show into the current page/section.
 * Let's say you need just rsa agreement to show - than this hoc will add just RSA provider for your needs with next improvements your existing component
 * withAgreements(YourModuleOrPage, {
 * RSAAgreement: true
 * })
 *
 * Or you want to add 2 agreements
 *
 * withAgreements(YourModuleOrPage, {
 * RSAAgreement: true,
 * SOAgreement: true
 * })
 *
 */

// list of agreements (will be increased in future)
const AgreementsList = {
  SOAgreement: SOAgreementContext.Provider,
  RSAAgreement: RSAAgreementContext.Provider,
};

type AgreementsKeys = keyof typeof AgreementsList;

export default function withAgreements<T extends {}>(
  Component: ComponentType<T>,
  agreements?: {
    [key in AgreementsKeys]?: boolean;
  },
  features?: AgreementFeatures
): ComponentType<T> {
  return (props: T) => {
    let wrappedComponent = <Component {...props} />;

    Object.keys(agreements || {}).forEach((agreement) => {
      const currentAgreementKey = agreement as AgreementsKeys;

      if (agreements?.[currentAgreementKey]) {
        const AgreementProvider = AgreementsList[agreement as AgreementsKeys];

        if (AgreementProvider) {
          wrappedComponent = (
            <AgreementProvider
              // passing injections for runTime model
              // modifying initial state with effectOn function from easy-peasy, inside appropriate context provider
              injections={{
                features,
              }}
            >
              {wrappedComponent}
            </AgreementProvider>
          );
        } else {
          console.warn(`Organism '${agreement}' not found in OrganismsList.`);
        }
      }
    });

    return wrappedComponent;
  };
}
