import { ChangeEvent, FC, useCallback, useState } from "react";
import { Modal as BModal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, ChecksGroup, H, InfoAlert, P, Ui } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import CaptableImage from "./img/CaptableImage";
import OwnershipImage from "./img/OwnershipImage";
import useInviteShareholderService, { GetStakeholderInfoDTO } from "./InviteShareholderService";
import classes from "./Modal.module.scss";

type PropsTypes = {
  stakeholder: GetStakeholderInfoDTO;
  onClose: () => void;
};

const t = createTranslation(TranslationNS.pages, "company.capTable.inviteShareholderModal");

const Modal: FC<PropsTypes> = ({ onClose, stakeholder }) => {
  const { companyId = "0" } = useParams();
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDontShowLoading, setIsDontShowLoading] = useState(false);
  const [ownershipChecked, setOwnershipChecked] = useState(true);
  const [captableChecked, setCaptableChecked] = useState(true);
  const { postInviteShareholder, postNeverShowAgain } = useInviteShareholderService(companyId);

  const isNoUserFound = stakeholder.stakeholderId === 0;

  const handleChangeOwnershipCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setOwnershipChecked(event.target.checked);

    setCaptableChecked(false);
  }, []);

  const handleChangeCaptableCheck = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setCaptableChecked(event.target.checked);

    if (event.target.checked) {
      setOwnershipChecked(true);
    }
  }, []);

  const goToStakeholdersPage = () => {
    navigate(getEMPath(["ownership", "stakeholdersManagement"], { companyId: companyId }));
  };

  const handleSubmit = useCallback(async () => {
    setIsSubmitLoading(true);

    const success = await postInviteShareholder({
      companyInvitationId: stakeholder.companyInvitationId,
      stakeholderId: stakeholder.stakeholderId || 0,
      hasAccessToViewCapTable: captableChecked,
      // TODO: remove after api will be updated
      hasAccessToViewOwnership: ownershipChecked,
      doNotShowThisAgain: false,
    });
    if (success) {
      notify(t("successToast"), true, "success", 5000, false, "top-center");
      onClose();
    }
    setIsSubmitLoading(false);
  }, [
    captableChecked,
    onClose,
    ownershipChecked,
    postInviteShareholder,
    stakeholder.companyInvitationId,
    stakeholder.stakeholderId,
  ]);

  const handleDontShowAgain = useCallback(async () => {
    setIsDontShowLoading(true);
    const success = await postNeverShowAgain({
      companyInvitationId: stakeholder.companyInvitationId,
      doNotShowThisAgain: true,
      stakeholderId: Number(stakeholder.stakeholderId),
      hasAccessToViewOwnership: false,
      hasAccessToViewCapTable: false,
    });

    if (success) {
      onClose();
    }

    setIsDontShowLoading(false);
  }, [onClose, postNeverShowAgain, stakeholder.companyInvitationId, stakeholder.stakeholderId]);

  return (
    <BModal show onHide={onClose} centered size="lg" contentClassName="py-6 px-7">
      <H.l className="mb-3">{t("title", { firstName: stakeholder.firstName })}</H.l>
      <P.m className="mb-3">
        {t("description", {
          firstName: stakeholder.firstName,
          lastName: stakeholder.lastName,
          companyName: stakeholder.companyName,
        })}
      </P.m>

      {isNoUserFound ? (
        <div className="mb-8">
          <InfoAlert
            title={t("couldNotLocalUser")}
            content={t("unableToFindStakeholder", {
              fullName: `${stakeholder.firstName} ${stakeholder.lastName}`,
            })}
          />
        </div>
      ) : (
        <div className={cn("p-3 mb-4", classes.elementBackground)}>
          <Ui.m className="text-medium mb-4">
            {t("subtitle", {
              firstName: stakeholder.firstName,
              lastName: stakeholder.lastName,
            })}
          </Ui.m>
          <div className={cn("d-flex mb-4 pb-4", classes.borderBottom)}>
            <div className="me-2">
              <H.xs className="mb-1">{t("ownership.title")}</H.xs>
              <P.s className="mb-1">{t("ownership.description", { firstName: stakeholder.firstName })}</P.s>
              <ChecksGroup>
                <ChecksGroup.Check
                  checked={ownershipChecked}
                  label={t("ownership.checkbox")}
                  onChange={handleChangeOwnershipCheck}
                />
              </ChecksGroup>
            </div>
            <div className={classes.imageContainer}>
              <OwnershipImage />
            </div>
          </div>
          <div className="d-flex">
            <div className="me-2">
              <H.xs className="mb-1">{t("captable.title")}</H.xs>
              <P.s className="mb-1">{t("captable.description")}</P.s>
              <ChecksGroup>
                <ChecksGroup.Check
                  checked={captableChecked}
                  label={t("captable.checkbox")}
                  onChange={handleChangeCaptableCheck}
                />
              </ChecksGroup>
            </div>
            <div className={classes.imageContainer}>
              <CaptableImage />
            </div>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-between">
        <Button
          variant="tertiary"
          isDisabled={isSubmitLoading}
          isLoading={isDontShowLoading}
          onClick={handleDontShowAgain}
        >
          {t("dontShowAgainBtn")}
        </Button>
        <div>
          <Button
            onClick={onClose}
            isDisabled={isSubmitLoading || isDontShowLoading}
            variant="secondary"
            className="me-2"
          >
            {t("cancelBtn")}
          </Button>
          <Button
            isLoading={isSubmitLoading}
            isDisabled={!(ownershipChecked || captableChecked) || isDontShowLoading}
            onClick={isNoUserFound ? goToStakeholdersPage : handleSubmit}
          >
            {isNoUserFound ? t("goToStakeholders") : t("inviteBtn")}
          </Button>
        </div>
      </div>
    </BModal>
  );
};

export default Modal;
