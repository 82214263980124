import { Ref, useEffect, useRef } from "react";

const useCombinedRef = <T>(...refs: Ref<T | null>[]) => {
  const targetRef = useRef(null);

  useEffect(() => {
    refs.forEach((ref: any) => {
      if (!ref) return;
      if (typeof ref === "function") ref(targetRef.current);
      else ref.current = targetRef.current;
    });
  }, [refs]);

  return targetRef;
};

export default useCombinedRef;
