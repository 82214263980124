import { FC } from "react";
import { useFormikContext } from "formik";

import { P } from "common/components/atoms/Typography";
import { CreateProgramFormData } from "pages/equity-management/plans/wizards/create-program/useProgramForm";
import { useStoreState } from "store/store";

const VestingTypeDescription: FC = () => {
  const vestingTypesStore = useStoreState((state) => state.common)?.dropdowns?.vestingTypes || [];
  const { values } = useFormikContext<Pick<CreateProgramFormData, "vestingTypeId">>();

  const selectedType = vestingTypesStore.find((el) => el.id === values.vestingTypeId);

  return <P.m className="mt-4">{selectedType?.description}</P.m>;
};

export default VestingTypeDescription;
