import { FC } from "react";
import { AnimatePresence, motion } from "framer-motion";

import InfoAlert from "common/components/atoms/InfoAlert/InfoAlert";
import { P, Ui } from "common/components/atoms/Typography";
import { CloseIcon } from "common/icons/svg";

import classes from "../ReceivePlan.wizard.module.scss";

type PropsTypes = {
  isOpen: boolean;
  title: string;
  description: string;
  handleClose?: () => void;
};

const initialAnimation = {
  opacity: 0,
  height: 0,
  marginBottom: 0,
};

const animation = {
  opacity: 1,
  height: "auto",
  marginBottom: "24px",
};

const AnimatedExplanation: FC<PropsTypes> = ({ isOpen, title, description, handleClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={initialAnimation}
          animate={animation}
          exit={initialAnimation}
          className="position-relative"
        >
          <InfoAlert
            type="Info"
            customContent={
              <div>
                <Ui.m bold className="mb-1">
                  {title}
                </Ui.m>
                <P.m>{description}</P.m>
              </div>
            }
          />

          <CloseIcon className={classes["close-btn"]} onClick={handleClose} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AnimatedExplanation;
