import { ChangeEvent, FC, memo, useState } from "react";

import { Button, ModalInfo, TextField } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import ManagePlansContext from "../../../managePlansContext";
import classes from "./OverrideShares.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans.terminatePlan.overrideSharesModal");

type OverrideSharesModalProps = {
  handleClose: () => void;
  handleUpdate: (shares: number) => void;
};

const OverrideSharesModal: FC<OverrideSharesModalProps> = ({ handleUpdate, handleClose }) => {
  const { terminationPlanInfo } = ManagePlansContext.useStoreState((state) => state);
  const vestedShares = terminationPlanInfo?.vestedShares || 0;
  const notVestedShares = terminationPlanInfo?.notVestedShares || 0;
  const notExercisedShares = vestedShares + notVestedShares;

  const [value, setValue] = useState(notExercisedShares.toString());

  const updateHandler = () => {
    handleUpdate(+value);
    handleClose();
  };

  const inputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <ModalInfo
      show={true}
      header={t("title")}
      handleClose={handleClose}
      className={classes["container"]}
      footer={
        <div>
          <Button className="me-2" isDisabled={+value > notExercisedShares} onClick={updateHandler}>
            {t("update")}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      }
    >
      <TextField
        isTouched
        type="number"
        value={value}
        className="mt-3"
        label={t("enterNumberOfShares")}
        info={t("availableShares", {
          count: notExercisedShares,
        })}
        error={+value > notExercisedShares ? t("warningMessage", { count: notExercisedShares }) : undefined}
        onChange={inputChange}
      />
    </ModalInfo>
  );
};

export default memo(OverrideSharesModal);
