import { FC, useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { motion } from "framer-motion";

import { getEMPath } from "app/Router/RouterHelper";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { CloseIcon, InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { useStoreState } from "../../../../store/store";
import { Button, P, Ui } from "../index";
import classes from "./StakeholdersLimitBanner.module.scss";

type PropsTypes = {
  onClose(): void;
  className?: string;
  onUpgrade?(): void;
};

const t = createTranslation(TranslationNS.common, "components.stakeholdersLimitBanner");

const Content: FC<PropsTypes> = ({ onClose, onUpgrade, className }) => {
  const { subscription } = useStripeSubscription();
  const { user } = useStoreState((state) => state.account);

  const navigate = useNavigate();

  const handleUpgradeClick = async () => {
    if (onUpgrade) {
      onUpgrade();
    }

    if (user?.email.toLowerCase() === subscription?.buyerEmail?.toLowerCase()) {
      try {
        const currentUrl = window.location.href;

        // triggering API side session initialization to provide stripe customer portal for the user
        const billingRequest = await axios.post<{ sessionUrl: string }>("/api/stripe/billing-url", {
          customerId: subscription?.customerId || "",
          returnUrl: currentUrl,
        });

        if (billingRequest.status === 200 && billingRequest.data.sessionUrl) {
          const link = document.createElement("a");

          link.href = billingRequest.data.sessionUrl;
          link.rel = "noopener noreferrer";
          link.click();
        }
      } catch (e) {
        console.error({ e });
      }
    } else {
      navigate(generatePath(getEMPath(["settings", "companyInformation"])));
    }
  };

  return (
    <div
      className={cn(className, classes.blueBackground, "d-flex align-items-center justify-content-between w-100 p-3")}
    >
      <div className="d-flex me-1">
        <InformationCircleIcon fontSize={24} color={scssVariables.information700} />
        <div className="ms-2">
          <Ui.m bold className="mb-1">
            {t("title")}
          </Ui.m>
          <P.s>{t("content")}</P.s>
        </div>
      </div>
      <div className="d-flex align-items-start">
        <Button variant="primaryInformation" className="me-3" onClick={handleUpgradeClick}>
          {t("submit")}
        </Button>
        <Button variant="information" size="s" isOnlyIcon className="p-0" onClick={onClose}>
          <CloseIcon />
        </Button>
      </div>
    </div>
  );
};

type WrapperPropsTypes = {
  className?: string;
  asToast?: boolean;
  onUpgrade?(): void;
};

const StakeholdersLimitBanner: FC<WrapperPropsTypes> = ({ className, asToast, onUpgrade }) => {
  const [isOpen, setIsOpen] = useState(true);

  if (isOpen) {
    if (asToast) {
      return (
        <motion.div
          className={classes.container}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -20, opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Content onClose={() => setIsOpen(false)} onUpgrade={onUpgrade} className={className} />
        </motion.div>
      );
    } else {
      return <Content onClose={() => setIsOpen(false)} onUpgrade={onUpgrade} className={className} />;
    }
  }
};

export default StakeholdersLimitBanner;
