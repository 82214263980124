import { FC } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import { isNil } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { InformationCircleIcon } from "common/icons/svg";
import { CONTACT_US_LINK, scssVariables } from "common/utils/constants";

import { Button, P, Ui } from "../index";
import classes from "./SubscriptionLimitsReached.module.scss";

type PropsTypes = {
  numberOfPlans: number;
};

const SubscriptionLimitsReached: FC<PropsTypes> = ({ numberOfPlans }) => {
  const { subscription } = useStripeSubscription();

  return (
    <div className={cn("p-2 d-flex justify-content-between", classes.container)}>
      <InformationCircleIcon fontSize={24} color={scssVariables.information700} />

      <div className="mx-2">
        <Ui.m bold className="mb-1">
          You have reached your limit of stakeholders with incentive agreements
        </Ui.m>

        <P.s>
          Your current plan supports up to {numberOfPlans} stakeholders with incentive agreements. To add more, please
          update your plan settings.
        </P.s>
      </div>

      {isNil(subscription?.stripeSubscriptionId) ? (
        <Link to={CONTACT_US_LINK} className="mt-auto" target="_blank">
          <Button variant="information" size="s">
            Contact us
          </Button>
        </Link>
      ) : (
        <Link to={`${getEMPath(["settings", "companyInformation"])}?tab=billing`} className="mt-auto">
          <Button variant="information" size="s">
            Upgrade
          </Button>
        </Link>
      )}
    </div>
  );
};

export default SubscriptionLimitsReached;
