import { Configuration, PublicClientApplication } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "115ccc15-16ef-4762-bd89-5b1957dd1724",
    authority:
      process.env.REACT_APP_AUTHORITY_SIGN_IN ||
      "https://unlisteddev.b2clogin.com/unlisteddev.onmicrosoft.com/B2C_1_sisu_signin",
    redirectUri: process.env.REACT_APP_REDIRECT_URI || "/",
    knownAuthorities: [process.env.REACT_APP_KNOW_AUTHORITY || "unlisteddev.b2clogin.com"],
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URI || "/",
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_LOGIN_SCOPE || "https://unlisteddev.onmicrosoft.com/equityplatform/webapi"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};

const addDifferentMsqlConfiguration = () => {
  if (location.pathname.startsWith("/invitation")) {
    msalConfig.auth.authority =
      process.env.REACT_APP_AUTHORITY_SIGN_UP ||
      "https://unlisteddev.b2clogin.com/unlisteddev.onmicrosoft.com/B2C_1_sisu_signup";
  }

  return new PublicClientApplication(msalConfig);
};

const authProvider = addDifferentMsqlConfiguration();

export default authProvider;
