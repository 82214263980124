import { FC } from "react";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.hasVotingRightsField");

const HasVotingRightsField: FC<FormGroupProps> = (props) => {
  const { values, handleChange } = useFormikContext<Pick<ShareClassFormData, "hasVotingRights">>();

  return (
    <FormGroup controlId="hasVotingRights" {...props}>
      <div className="d-flex flex-column">
        <InputHeader label={t("label")} />

        <FormCheck
          type="switch"
          label={t(`switch.${+values.hasVotingRights}`)}
          checked={values.hasVotingRights}
          onChange={handleChange}
        />
      </div>
    </FormGroup>
  );
};

export default HasVotingRightsField;
