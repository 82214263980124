import React, { FC, useLayoutEffect } from "react";
import { equals } from "ramda";

import { StakeholderDetailsContext } from "../stakeholder-details.context";
import { ShareholderSelectedTabOptions } from "./Tabs";
import ContactsTab from "./TabsViews/ContactsTab";
import InstrumentsTab from "./TabsViews/InstrumentsTab";
import OwnershipTab from "./TabsViews/OwnershipTab";
import TransactionsTab from "./TabsViews/TransactionsTab";

// mode property might be changed
// for now we have only 2 places to display it - only contacts section, or active tabs view
type Props = {
  mode: "tabs-view" | "contacts-view";
};

const TabsViewUI = () => {
  const selectedTab = StakeholderDetailsContext.useStoreState((state) => state.selectedTab);
  const setSelectedTab = StakeholderDetailsContext.useStoreActions((actions) => actions.setSelectedTab);

  useLayoutEffect(() => {
    setSelectedTab(ShareholderSelectedTabOptions.ownership);
  }, [setSelectedTab]);

  return (
    <>
      {equals(selectedTab, ShareholderSelectedTabOptions.ownership) ? <OwnershipTab /> : null}
      {equals(selectedTab, ShareholderSelectedTabOptions.instruments) ? <InstrumentsTab /> : null}
      {equals(selectedTab, ShareholderSelectedTabOptions.transactions) ? <TransactionsTab /> : null}
      {equals(selectedTab, ShareholderSelectedTabOptions.contacts) ? <ContactsTab /> : null}
    </>
  );
};

const Content: FC<Props> = ({ mode }) => {
  return (
    <>
      {equals(mode, "tabs-view") ? <TabsViewUI /> : null}
      {equals(mode, "contacts-view") ? <ContactsTab /> : null}
    </>
  );
};

export default Content;
