import { FC, useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import OnboardUserRouter from "pages/onboard/user/OnboardUserRouter";
import { useStoreState } from "store/store";

import OnboardCompanyRouter from "./company/OnboardCompanyRouter";
import Onboard from "./Onboard";

const OnboardRouter: FC = () => {
  const location = useLocation();

  const [exitPath, setExitPath] = useState("/");

  useEffect(() => {
    if (location?.state?.prevPath && location?.state?.prevPath !== location.pathname) {
      setExitPath(location?.state?.prevPath);
    }
  }, [exitPath, location.pathname, location?.state]);

  const isOnboarded = useStoreState((state) => state.account.user?.isOnboarded);

  return (
    <Onboard>
      <Routes>
        {!isOnboarded && <Route path={ROUTER_V2.onboard.user} element={<OnboardUserRouter />} />}
        {isOnboarded && <Route path={ROUTER_V2.onboard.company.full} element={<OnboardCompanyRouter />} />}
        <Route path="*" element={<Navigate to={getPath(["onboard", isOnboarded ? "company" : "user"])} />} />
      </Routes>
    </Onboard>
  );
};

export default OnboardRouter;
