import { FC, MouseEvent, useCallback, useMemo } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { ShareClassServiceCompanyShareClassesResponse } from "common/shareClass/ShareClassService";
import { createTranslation, TranslationNS } from "translation";

import ShareClassDetails from "./ShareClassDetails";
import classes from "./ShareClassField.module.scss";

export type LabelExtraProps = {
  shareClassId?: ShareClassServiceCompanyShareClassesResponse[number]["id"];
};

const t = createTranslation(
  TranslationNS.common,
  "pool.components.poolForm.shareClassField"
);

const LabelExtra: FC<LabelExtraProps> = ({ shareClassId }) => {
  const classDetailsClickHandler = useCallback(
    (e: MouseEvent<HTMLAnchorElement>): void => {
      e.preventDefault();
    },
    []
  );

  const classDetailsOverlayTriggerPopperConfig = useMemo(
    () => ({
      modifiers: [
        {
          name: "offset",
          options: {
            offset: ({ popper }: any) => [-(popper.height / 2), 8],
          },
        },
      ],
    }),
    []
  );

  if (!shareClassId) {
    return null;
  }

  return (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="auto-end"
      popperConfig={classDetailsOverlayTriggerPopperConfig}
      overlay={
        <Popover
          id="class-details-popover"
          className={classes["details-popover"]}
        >
          <Popover.Body className="p-0">
            <ShareClassDetails shareClassId={shareClassId} />
          </Popover.Body>
        </Popover>
      }
    >
      <a
        className={classes["details"]}
        href="#"
        onClick={classDetailsClickHandler}
      >
        {t("classDetails")}
      </a>
    </OverlayTrigger>
  );
};

export default LabelExtra;
