import { ChangeEventHandler, FC, useCallback } from "react";
import FormGroup from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import Helper from "common/components/atoms/Helper/Helper";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../../../../../pages/equity-management/plans/wizards/create-program/useProgramForm";

const t = createTranslation(
  TranslationNS.common,
  "program.components.programForm.vestingDetails.allowAcceleratedVesting"
);

const AcceleratedVestingOnEvent: FC = () => {
  const { values, errors, touched, setFieldValue } =
    useFormikContext<Pick<CreateProgramFormData, "allowAcceleratedVesting">>();

  const radioChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setFieldValue(e.target.name, e.target.value === "yes");
    },
    [setFieldValue]
  );

  return (
    <FormGroup controlId="allowAcceleratedVesting">
      <Helper.Question questionId="allowAcceleratedVesting">
        <InputHeader label={t("label")}></InputHeader>
      </Helper.Question>
      <Helper.Answer
        className="mt-1 mb-1"
        answerId="allowAcceleratedVesting"
        text={t("answer")}
        linkText={t("readMore")}
        link="/"
      />
      <ChecksGroup error={errors.allowAcceleratedVesting} isTouched={touched.allowAcceleratedVesting}>
        <ChecksGroup.Check
          value="yes"
          type="radio"
          label={t("yes")}
          name="allowAcceleratedVesting"
          checked={values.allowAcceleratedVesting}
          isInvalid={touched.allowAcceleratedVesting && !!errors.allowAcceleratedVesting}
          onChange={radioChangeHandler}
        />
        <ChecksGroup.Check
          value="no"
          type="radio"
          label={t("no")}
          name="allowAcceleratedVesting"
          checked={!values.allowAcceleratedVesting}
          isInvalid={touched.allowAcceleratedVesting && !!errors.allowAcceleratedVesting}
          onChange={radioChangeHandler}
        />
      </ChecksGroup>
    </FormGroup>
  );
};

export default AcceleratedVestingOnEvent;
