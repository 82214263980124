import { ChangeEvent, FC, memo, useCallback } from "react";
import { useFormikContext } from "formik";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import FileUploader, { FilesDataMultiple, UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "molecules.documentUploadWithStatusesSignatureEmailInvitation");

type DocumentUploadWithStatusesProps = {
  documentStatusName?: string;
  newFilesName?: string;
  oldFilesName?: string;
  title?: string;
  documentLoaderSize?: "regular" | "large";
  disabled?: boolean;
};

const DocumentUploadWithStatuses: FC<DocumentUploadWithStatusesProps> = ({
  documentStatusName = "documentStatusId",
  newFilesName = "newFiles",
  oldFilesName = "oldFiles",
  title,
  documentLoaderSize = "regular",
  disabled,
}) => {
  const { errors, values, touched, setFieldValue } = useFormikContext<{ [key: string]: number | UploadedFile[] }>();

  const handleDocumentStatusChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(documentStatusName, +e.target.value).then();
    },
    [documentStatusName, setFieldValue]
  );

  const handleFileUploaderChange = useCallback(
    async (files: FilesDataMultiple) => {
      await setFieldValue(newFilesName, files.newFiles);
      await setFieldValue(oldFilesName, files.oldFiles);
    },
    [newFilesName, oldFilesName, setFieldValue]
  );

  return (
    <div>
      {
        <FileUploader
          multiple
          size={documentLoaderSize}
          isOptional={values[documentStatusName] !== DocumentStatusEnum.DOCUMENT_UPLOADED}
          prevFileData={values[oldFilesName] as UploadedFile[]}
          label={title ? title : t("uploadDocuments")}
          onChange={handleFileUploaderChange}
          isTouched={touched[newFilesName]}
          error={errors[newFilesName]}
        />
      }

      <ChecksGroup
        className="mt-4"
        label={t("documentsStatus")}
        error={errors[documentStatusName]}
        isTouched={touched[documentStatusName]}
        isDisabled={disabled}
      >
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          disabled={disabled}
          label={t("documentsUploaded")}
          name={documentStatusName}
          checked={values[documentStatusName] === DocumentStatusEnum.DOCUMENT_UPLOADED}
          value={DocumentStatusEnum.DOCUMENT_UPLOADED}
          onChange={handleDocumentStatusChange}
        />
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          disabled={disabled}
          label={t("willUploadLater")}
          name={documentStatusName}
          checked={values[documentStatusName] === DocumentStatusEnum.REVIEW_LATER}
          value={DocumentStatusEnum.REVIEW_LATER}
          onChange={handleDocumentStatusChange}
        />
        <ChecksGroup.Check
          isNoFocus
          type="radio"
          disabled={disabled}
          label={t("noDocsRequired")}
          value={DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
          checked={values[documentStatusName] === DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
          onChange={handleDocumentStatusChange}
        />
      </ChecksGroup>
    </div>
  );
};

export default memo(DocumentUploadWithStatuses);
