import axios, { AxiosResponse } from "axios";

import store from "store/store";

export type CompanyDetailsServiceGetResponse = {
  id: number;
  name: string;
  linkedInUrl: string;
  twitterHandle: string;
  metaUrl: string;
  calendlyUrl: string;
  mediumUrl: string;
  instagramUrl: string;
  websiteUrl: string;
  logoImageFilePath: string;
  oneliner: string;
  elevatorPitch: string;
  introVideoUrl: string;
  mission: string;
  vision: string;
  problem: string;
  solution: string;
  competitiveAdvantage: string;
  teamDescription: string;
  boardDescription: string;
  pitchDeckFilePath: string;
  headquartersCountryId: number;
  headquartersCountryName: string;
  headquartersCity: string;
  companyPhaseName: string;
  businessModels: Array<{
    id: number;
    name: string;
    sortingIndex: number;
  }>;
  industries: Array<{
    id: number;
    name: string;
    sortingIndex: number;
  }>;
  verticals: Array<{
    id: number;
    name: string;
    sortingIndex: number;
  }>;
  funding: {
    fundraisingStatusName: string;
    fundingStageName: string;
    fundingTargetAmount: number;
    closingDatePreferred: string;
    fundingInstrumentName: string;
    estimatedPreMoneyValuation: number;
    currencyCode: string;
  };
  contactPerson: {
    id: number;
    firstName: string;
    lastName: string;
    positionTitle: string;
    email: string;
    profileImageFilePath: string;
    linkedInUrl: string;
  };
  otherTeamMembers: Array<{
    id: number;
    firstName: string;
    lastName: string;
    positionTitle: string;
    email: string;
    profileImageFilePath: string;
    linkedInUrl: string;
  }>;
  boardMembers: Array<{
    id: number;
    firstName: string;
    lastName: string;
    positionTitle: string;
    email: string;
    profileImageFilePath: string;
    linkedInUrl: string;
  }>;
};

export type CompanyDetailsServiceEquityResponse = {
  totalNumberOfShares: number;
  totalNumberOfSharesInPools: number;
  totalNumberOfSharesIssued: number;
  totalNumberOfSharesApproved: number;
};

const getActiveContextId = (): number => store.getState().activeCompanyModel.companyId || 0;

class CompanyDetailsService {
  static apiBase = "/api/attract/company-details";

  static get(
    companyId: string | number = getActiveContextId()
  ): Promise<AxiosResponse<CompanyDetailsServiceGetResponse>> {
    return axios.get<CompanyDetailsServiceGetResponse>(`${CompanyDetailsService.apiBase}/${companyId}`);
  }

  static equity(
    companyId: string | number = getActiveContextId()
  ): Promise<AxiosResponse<CompanyDetailsServiceEquityResponse>> {
    return axios.get<CompanyDetailsServiceEquityResponse>(`${CompanyDetailsService.apiBase}/equity/${companyId}`);
  }
}

export default CompanyDetailsService;
