import { FC } from "react";
import Nav from "react-bootstrap/Nav";
import classNames from "classnames";

import Button from "common/components/atoms/Button/Button";
import { Ui } from "common/components/atoms/Typography";
import { UnlistedIcon } from "common/icons/svg";
import { FOOTER_ID, scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Footer.module.scss";

const t = createTranslation(TranslationNS.layout, "footer");

const DROP_EMAIL = "hello@unlisted.ai";
const EMAIL_SUBJECT = "Hello";

const Footer: FC = () => {
  const { isWizardLayout, isFooterShown } = useStoreState((state) => state.app);
  const { isPublicRouteActive } = useStoreState((state) => state.authentication);

  const year = new Date().getFullYear();

  const onEmailPressHandler = () => {
    const mail = document.createElement("a");
    mail.href = `mailto:${DROP_EMAIL}?subject=${EMAIL_SUBJECT}`;
    mail.click();
  };

  if (isWizardLayout || !isFooterShown) {
    return null;
  }

  return (
    <>
      {isPublicRouteActive ? (
        <div className={classNames(classes["additional-footer"], "d-flex", "flex-column")}>
          <div className={classNames(classes["additional-footer-logo-button-container"], "mb-5", "d-flex")}>
            <UnlistedIcon width={68} height={68} />
            <Button
              size="s"
              variant="secondary"
              data-testid="drop-us-btn-test-id"
              onClick={onEmailPressHandler}
              className="mt-5 mt-lg-0"
              style={{
                color: scssVariables.foregroundHigh,
                borderColor: scssVariables.foregroundHigh,
              }}
            >
              {t("dropEmail")}
            </Button>
          </div>

          <Ui.xs bold>{t("companyTitle")}</Ui.xs>

          <Ui.xs className="mb-3" style={{ width: 105 }}>
            {t("companyAddress")}
          </Ui.xs>

          <Ui.xs>{t("companyNumber")}</Ui.xs>
        </div>
      ) : null}

      <footer
        id={FOOTER_ID}
        className={classNames(classes["footer"], {
          [classes["wizard"]]: isWizardLayout,
        })}
      >
        <Ui.s>{t("copy", { year })}</Ui.s>

        <Nav className={classes["nav"]}>
          <a target="_blank" className="nav-link" rel="noreferrer" href="https://www.unlisted.ai/contact">
            {t("support")}
          </a>
          <a target="_blank" className="nav-link" rel="noreferrer" href="https://www.unlisted.ai/personvern-policy">
            {t("privacyPolicy")}
          </a>
        </Nav>
      </footer>
    </>
  );
};

export default Footer;
