import axios, { AxiosResponse } from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { InvestmentEntity } from "store/types";

import { OwnershipPlanDocumentFile, Stakeholder } from "../components/receive.types";

export type ConvertibleNoteDTO = {
  id: number;
  companyId: number;
  statusId: number;
  companyName: string | null;
  currencyId: number;
  discount: number;
  documentsNeedsSignature: boolean;
  documentFiles: OwnershipPlanDocumentFile[];
  expirationDate: string;
  investmentAmount: number;
  shareClass: string;
  shareClassComment: string | null;
  shareClassDecided: boolean;
  triggerAmount: number;
  valuationCap: number;
  stakeholder: Stakeholder;
};

export type AcceptConvertibleNote = {
  signature?: File;
  approvalKey: string;
};

interface ConvertibleNoteWizardContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  companyName: string;
  setCompanyName: Action<this, this["companyName"]>;

  isRemoteCompanySelected: boolean;
  setIsRemoteCompanySelected: Action<this, this["isRemoteCompanySelected"]>;

  companyOrgNumber: string;
  setCompanyOrgNumber: Action<this, this["companyOrgNumber"]>;

  selectedCompany: InvestmentEntity | null;
  setSelectedCompany: Action<this, this["selectedCompany"]>;

  isHoldingCompanySelected: boolean;
  setIsHoldingCompanySelected: Action<this, this["isHoldingCompanySelected"]>;

  signature: File | null;
  setSignature: Action<this, this["signature"]>;

  convertibleNoteDetails: ConvertibleNoteDTO | null;
  setConvertibleNoteDetails: Action<this, this["convertibleNoteDetails"]>;
  getConvertibleNoteDetails: Thunk<this, string>;
  acceptConvertibleNoteThunk: Thunk<this, AcceptConvertibleNote, null, {}, Promise<AxiosResponse<ConvertibleNoteDTO>>>;
  declineConvertibleNoteThunk: Thunk<this, { companyId: number; convertibleNoteId: number }>;
}

const ReceiveConvertibleNoteContext = createContextStore<ConvertibleNoteWizardContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    companyName: "",
    setCompanyName: action((state, payload) => {
      state.companyName = payload;
    }),

    selectedCompany: null,
    setSelectedCompany: action((state, payload) => {
      state.selectedCompany = payload;
    }),

    isRemoteCompanySelected: false,
    setIsRemoteCompanySelected: action((state, payload) => {
      state.isRemoteCompanySelected = payload;
    }),

    companyOrgNumber: "",
    setCompanyOrgNumber: action((state, payload) => {
      state.companyOrgNumber = payload;
    }),

    isHoldingCompanySelected: false,
    setIsHoldingCompanySelected: action((state, payload) => {
      state.isHoldingCompanySelected = payload;
    }),

    signature: null,
    setSignature: action((state, payload) => {
      state.signature = payload;
    }),

    convertibleNoteDetails: null,
    setConvertibleNoteDetails: action((state, payload) => {
      state.convertibleNoteDetails = payload;
    }),
    getConvertibleNoteDetails: thunk(async (actions, key) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<ConvertibleNoteDTO>(
          `/api/equity-management/issue-equity/convertible-note/details/${key}`
        );

        if (request.status === 200) {
          actions.setConvertibleNoteDetails(request.data);
        }
        return request;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        actions.setIsLoading(false);
      }
    }),
    acceptConvertibleNoteThunk: thunk(async (_, payload) => {
      try {
        const formData = new FormData();
        formData.append("approvalKey", String(payload.approvalKey));
        if (payload.signature) {
          formData.append("signature", payload.signature);
        }
        return await axios.post(
          "/api/equity-management/issue-equity/convertible-note/accept-convertible-note-issued",
          formData
        );
      } catch (e) {
        return await Promise.reject(e);
      }
    }),
    declineConvertibleNoteThunk: thunk(async (actions, payload) => {
      try {
        const formData = new FormData();
        formData.append("CompanyId", String(payload.companyId));
        formData.append("ConvertibleNoteId", String(payload.convertibleNoteId));

        return await axios.post("/api/equity-management/issue-equity/convertible-note/cancel", formData);
      } catch (e) {
        return await Promise.reject(e);
      }
    }),
  },
  {
    name: "Receive convertible note context.",
  }
);

export default ReceiveConvertibleNoteContext;
