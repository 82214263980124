import { Dispatch, FC, ReactNode, RefObject, SetStateAction, useMemo, useRef } from "react";
import Image from "react-bootstrap/Image";
import { matchRoutes, useLocation } from "react-router-dom";
import classNames from "classnames";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { useOnClickOutside } from "common/hooks/useOnClickOutside";
import { ChevronDownIcon } from "common/icons/svg";
import { useStoreState } from "store/store";

import classes from "./ContextToggle.module.scss";

type ContextToggleProps = {
  open: boolean;
  children: ReactNode;
  isHeaderExpanded: boolean;
  headerRef: RefObject<HTMLDivElement>;
  onClick: (val: boolean) => void;
  setIsHeaderExpanded: Dispatch<SetStateAction<boolean>>;
};

const ContextToggle: FC<ContextToggleProps> = ({
  open,
  children,
  headerRef,
  isHeaderExpanded,
  onClick,
  setIsHeaderExpanded,
}) => {
  const location = useLocation();
  const cardRef = useRef<HTMLDivElement>(null);

  const { user } = useStoreState((state) => state.account);
  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);

  // This hook will control user clicks and trigger context menu to close
  useOnClickOutside(cardRef, () => {
    onClick(false);

    if (open && isHeaderExpanded) {
      headerRef?.current?.click();
      setIsHeaderExpanded(false);
    }
  });

  const isCompanyFlowSelected = useMemo(() => {
    return !!matchRoutes(
      [
        { path: ROUTER_V2.equityManagement.full },
        { path: ROUTER_V2.attract.full },
        { path: ROUTER_V2.companySettings.full },
        { path: ROUTER_V2.companyProfile.full },
      ],
      location.pathname
    )?.length;
  }, [location]);

  const selectedEntity = useMemo(() => {
    if (isCompanyFlowSelected) {
      return user?.companies?.find(({ id }) => id === activeCompanyId);
    }
  }, [isCompanyFlowSelected, activeCompanyId, user?.companies]);

  return (
    <div ref={cardRef} className={classes["context-wrapper"]}>
      <div className={classNames(classes["toggle"], "toggleMenuSelenium")} onClick={onClick.bind(null, !open)}>
        <div>
          {user?.profileImage ? (
            <Image alt="profile" src={user.profileImage} className={classes["profile-img"]} />
          ) : (
            <svg width="33" height="33" fill="black" viewBox="0 0 16 16" className="bi bi-person-circle">
              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
              <path
                fillRule="evenodd"
                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
              />
            </svg>
          )}
        </div>

        <div className={classNames(classes["text-info"], "ms-1", "text-truncate")}>
          <p
            className={`m-0 ${isCompanyFlowSelected ? "ui-xs" : "ui-s"}`}
            style={{
              fontWeight: !isCompanyFlowSelected ? 500 : 300,
            }}
          >{`${user?.firstName}${isCompanyFlowSelected ? " at" : ""}`}</p>
          {isCompanyFlowSelected && (
            <p
              title={selectedEntity?.name}
              className={classNames("m-0", "ui-s", "text-truncate", classes["company-name"])}
            >
              {selectedEntity?.name}
            </p>
          )}
        </div>

        <ChevronDownIcon fontSize={20} className={classes["chevron"]} direction={open ? "top" : "bottom"} />
      </div>

      {children}
    </div>
  );
};

export default ContextToggle;
