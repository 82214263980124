import { FC, forwardRef, useCallback, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownToggleProps } from "react-bootstrap/esm/DropdownToggle";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { MenuTabBarVerticalIcon } from "common/icons/svg";
import { remoteDocumentType } from "common/types/documents";
import { downloadRemoteDocument, openRemoteDocument, removeRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import DropdownButton from "../DropdownButton/DropdownButton";
import Spinner from "../Spinner/Spinner";
import RemoveDocumentModal from "./components/RemoveDocumentModal";
import classes from "./DocumentToggleElement.module.scss";

const t = createTranslation(TranslationNS.common, "molecules.documentList");

const ToggleElement = forwardRef<HTMLButtonElement, DropdownToggleProps>((props, ref) => (
  <Dropdown.Toggle ref={ref} {...props} className={classes["toggle"]} variant="tertiary">
    <MenuTabBarVerticalIcon width={24} height={24} />
  </Dropdown.Toggle>
));

const DocumentToggleElement: FC<{
  el: remoteDocumentType;
  isViewVisible?: boolean;
  isDownloadVisible?: boolean;
  isDeleting?: boolean;
  onEdit?: (e?: any) => void;
  toggleClassName?: string;
  cbAfterAction?: () => void;
}> = ({ el, onEdit, cbAfterAction, isViewVisible = true, isDownloadVisible = true, isDeleting, toggleClassName }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [removeDocumentId, setRemoveDocumentId] = useState<number | null>(null);
  const { hasFullAccess } = useFeatures(FEATURES.documents);

  const handleOpen = useCallback(async (id: string) => {
    try {
      setIsLoading(true);
      await openRemoteDocument(id);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDownload = useCallback(async (id: string, name: string) => {
    try {
      setIsLoading(true);
      await downloadRemoteDocument(id, name);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const documentListOptions = useCallback(() => {
    const initialActionsList = [];

    if (isViewVisible) {
      initialActionsList.push({
        title: t("view"),
        handler: handleOpen.bind(null, el.downloadId),
      });
    }

    if (isDownloadVisible) {
      initialActionsList.push({
        title: t("download"),
        handler: handleDownload.bind(null, el.downloadId, el.fileName),
      });
    }

    if (onEdit) {
      initialActionsList.push({
        title: t("replace"),
        handler: onEdit as unknown as () => Promise<void>,
      });
    }

    if (isDeleting && el.companyFileId) {
      initialActionsList.push({
        title: t("delete"),
        handler: setRemoveDocumentId.bind(null, el.companyFileId) as unknown as () => Promise<void>,
        disabled: !hasFullAccess,
      });
    }

    return initialActionsList;
  }, [
    isViewVisible,
    isDownloadVisible,
    onEdit,
    isDeleting,
    el.companyFileId,
    el.downloadId,
    el.fileName,
    handleOpen,
    handleDownload,
    hasFullAccess,
  ]);

  const handleRemove = useCallback(
    async (id: number) => {
      try {
        setIsLoading(true);
        await removeRemoteDocument(id);

        cbAfterAction?.();
      } finally {
        setIsLoading(false);
      }
    },
    [cbAfterAction]
  );

  return (
    <div>
      <DropdownButton
        className={toggleClassName}
        title=""
        customToggleElement={ToggleElement}
        options={documentListOptions()}
      />
      <RemoveDocumentModal
        isOpen={Boolean(removeDocumentId)}
        setIsOpen={setRemoveDocumentId.bind(null, null)}
        onDelete={handleRemove.bind(null, removeDocumentId || 0)}
      />
      {isLoading ? <Spinner /> : null}
    </div>
  );
};

export default DocumentToggleElement;
