import { Navigate, Outlet } from "react-router-dom";

import { useStoreState } from "../store/store";
import { ROUTER_V2 } from "./Router/RouterV2.types";

const PrivateRoute = () => {
  const userStore = useStoreState((state) => state.account.user);
  const { isAuthenticated } = useStoreState((state) => state.authentication);

  return isAuthenticated && userStore ? <Outlet /> : <Navigate to={ROUTER_V2.signIn.base} />;
};

export default PrivateRoute;
