import { FC } from "react";

import { NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { SplitPlan, SplitShareholder, SplitWarrant } from "../../../../../types";

type PropsTypes = {
  list: SplitShareholder[] | SplitPlan[] | SplitWarrant[];
};

const t = createTranslation(TranslationNS.pages, "company.transactions.splitDetails");

const Shareholder: FC<{ data: SplitShareholder | SplitPlan | SplitWarrant }> = ({ data }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center me-3" style={{ minWidth: "150px", maxWidth: "150px" }}>
          <div className="d-flex align-items-center">
            <NewAvatar
              size="s"
              imageUrl={data.avatarFilePath ? data.avatarFilePath : undefined}
              initials={data.initials}
            />
            <div className="ms-2">
              {data.isCompanyOwned ? (
                <>
                  <Ui.m bold>{data.companyName}</Ui.m>
                  <Ui.s>{`${data.firstName} ${data.lastName}`}</Ui.s>
                </>
              ) : (
                <Ui.m bold>{`${data.firstName} ${data.lastName}`}</Ui.m>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SplitAccordionList: FC<PropsTypes> = ({ list }) => {
  const fNumber = useFormatNumbers();

  if (list instanceof Array) {
    if (list.length > 0) {
      if ("stakeholderId" in list[0]) {
        const stakeholdersList = [...list] as SplitShareholder[];

        return stakeholdersList.map((stakeholder) => (
          <div key={stakeholder.stakeholderId} className="d-flex justify-content-between p-3">
            <Shareholder data={stakeholder} />
            <div className="d-flex align-items-center">
              <Ui.s className="me-1">{fNumber(stakeholder.sharesBefore, "amount")}</Ui.s>
              <ArrowRightIcon width={20} height={14} />
              <Ui.s className="ms-1">
                {fNumber(stakeholder.sharesAfter, "amount")} {t("shares")}
              </Ui.s>
            </div>
          </div>
        ));
      } else if ("planId" in list[0]) {
        const agreementsList = [...list] as SplitPlan[];

        return agreementsList.map((plan) => (
          <div key={plan.planId} className="d-flex justify-content-between p-3">
            <div className="d-flex">
              <Shareholder data={plan} />
              <Ui.s className="ms-1">{plan.instrumentTypeName}</Ui.s>
            </div>
            <div className="d-flex align-items-center">
              <Ui.s className="me-1">{fNumber(plan.sharesBefore, "amount")}</Ui.s>
              <ArrowRightIcon width={20} height={14} />
              <Ui.s className="ms-1">
                {fNumber(plan.sharesAfter, "amount")} {t("shares")}
              </Ui.s>
            </div>
          </div>
        ));
      } else if ("warrantId" in list[0]) {
        const warrantList = [...list] as SplitWarrant[];

        return warrantList.map((warrant) => (
          <div key={warrant.warrantId} className="d-flex justify-content-between p-3">
            <Shareholder data={warrant} />
            <div className="d-flex align-items-center">
              <Ui.s className="me-1">{fNumber(warrant.sharesBefore, "amount")}</Ui.s>
              <ArrowRightIcon width={20} height={14} />
              <Ui.s className="ms-1">
                {fNumber(warrant.sharesAfter, "amount")} {t("shares")}
              </Ui.s>
            </div>
          </div>
        ));
      }
    }
  }

  return <Ui.s className="p-3 text-low">{t("emptyList")}</Ui.s>;
};

export default SplitAccordionList;
