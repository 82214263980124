import { FC } from "react";

import { AccessControlUserDTO } from "../types";
import UserCard from "./user-card/user-card";

type PropsTypes = {
  users: AccessControlUserDTO[];
  handleOpenEditAccessSidebar: (id: number) => void;
};

const CardView: FC<PropsTypes> = ({ users, handleOpenEditAccessSidebar }) => {
  return (
    <div className="d-flex flex-wrap gap-4">
      {users.map((user) => (
        <UserCard key={user.companyUserId} user={user} onEdit={handleOpenEditAccessSidebar} />
      ))}
    </div>
  );
};

export default CardView;
