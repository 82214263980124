import { FC } from "react";

import CreatePlanWizard from "./create-plan-wizard";
import createPlanStore from "./store";

const CreatePlanWrapper: FC = () => {

  return (
    <createPlanStore.Provider>
      <CreatePlanWizard />
    </createPlanStore.Provider>
  );
};

export default CreatePlanWrapper;
