import axios from "axios";
import { ActionOn, actionOn, Thunk, thunk } from "easy-peasy";

import { notify } from "common/utils/notify/notifyFunction";
import { getThunkTypes } from "store/storeThunk";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

export type Variant = "done" | "progress" | "waiting" | "undone" | "pending-transaction" | "committed-transaction" | "noSubscription";

interface IValuationAndShares {
  valuationSet: boolean;
  currentValuation: number;
  currency: string;
  shareClassNames: string[];
}

interface ICapTable {
  numberOfShareHolders: number;
  numberOfShares: number;
}

interface IPools {
  poolName: string;
  numberOfShares: number;
}

interface IPlansInPrograms {
  programs: { programName: string; numberOfPlans: number; numberOfShares: number }[];
  numberOfOneOffPlans: number;
  sharesInOneOffPlans: number;
}

export interface IGettingStartedGuideModel {
  loading: boolean;
  valuationAndShares: IValuationAndShares;
  capTable: ICapTable;
  pools: IPools[];
  plansInPrograms: IPlansInPrograms;
  numberOfUnGrantedPlans: number;
  numberOfGrantedPlans: number;
  getStartedGuideThunk: Thunk<this, number>;
  onGetStartedGuideAction: ActionOn<this>;
}

export const gettingStartedGuide: IGettingStartedGuideModel = {
  loading: false,
  valuationAndShares: {
    currency: "",
    valuationSet: false,
    currentValuation: 0,
    shareClassNames: [],
  },
  capTable: {
    numberOfShareHolders: 0,
    numberOfShares: 0,
  },
  pools: [],
  plansInPrograms: {
    programs: [],
    numberOfOneOffPlans: 0,
    sharesInOneOffPlans: 0,
  },
  numberOfUnGrantedPlans: 0,
  numberOfGrantedPlans: 0,
  getStartedGuideThunk: thunk(async (actions, companyId, { fail }) => {
    try {
      const response = await axios.get(`/api/getting-started-guide/${companyId}`);
      return response.data || [];
    } catch (e) {
      fail(e);
      return e;
    }
  }),
  onGetStartedGuideAction: actionOn(
    (actions) => getThunkTypes(actions.getStartedGuideThunk),
    (state, target) => {
      const [startType, successType, errorType] = target.resolvedTargets;
      state.loading = startType === target.type;
      if (successType === target.type) {
        state.valuationAndShares = target.result.valuationAndShares;
        state.capTable = target.result.capTable;
        state.pools = target.result.pools;
        state.plansInPrograms = target.result.plansInPrograms;
        state.numberOfUnGrantedPlans = target.result.numberOfUnGrantedPlans;
        state.numberOfGrantedPlans = target.result.numberOfGrantedPlans;
      }
      if (errorType === target.type) {
        notify(t("Error"), true, "error");
      }
    }
  ),
};
