import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import { isEmpty } from "ramda";
import * as Yup from "yup";

import { Button, InfoAlert, P, SlidePanel, Ui } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import DocumentUploadWithStatuses from "common/components/molecules/DocumentUploadWithStatuses/DocumentUploadWithStatuses";
import StakeholderSection from "common/components/molecules/StakeholderSection/StakeholderSection";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { InformationCircleIcon, WarningExclamationMarkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../transactions-service";
import {
  AvailableShareClass,
  BuySellCheckDTO,
  BuySellEditDTO,
  BuySellGetResponseDTO,
  Transaction,
} from "../../../types";
import DependentTransactions from "../../rollback-confirmed-transaction/dependent-transactions";
import { DocumentationDetailsFieldsType } from "../form-sections/documentation-details/types";
import { documentationDetailsValidationSchema } from "../form-sections/documentation-details/validation";
import { receiverDetailsValidationSchema } from "../form-sections/receiver-details/validation";
import SellerDetails from "../form-sections/seller-details/seller-details";
import { sellerDetailsValidationSchema } from "../form-sections/seller-details/validation";
import SharesDetailsV2 from "../form-sections/shares-details-v2/shares-details-v2";
import { sharesDetailsValidationSchema } from "../form-sections/shares-details-v2/validation";
import TransactionDetails from "../form-sections/transaction-details/transaction-details";
import { transactionDetailsValidationSchema } from "../form-sections/transaction-details/validation";
import { BuySellFields, EditBuySellFields } from "./form-fields";
import SubmitModal from "./submit-modal";

type PropsTypes = {
  onSubmit: (transaction: FormData, resetForm?: boolean) => Promise<boolean>;
  onClose: () => void;
  transactionToEdit: BuySellGetResponseDTO;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");
const tValidation = createTranslation(TranslationNS.validation);

const EditBuySellTransactionForm: FC<PropsTypes> = ({ onSubmit, onClose, transactionToEdit, isDisabled }) => {
  const formRef = useRef<HTMLDivElement>(null);
  const dataManagerRef = useRef<any>(null);
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const [selectedShareClass, setSelectedShareClass] = useState<AvailableShareClass | null>(null);
  const [isApproveSubmitModalOpen, setIsApproveSubmitModalOpen] = useState(false);
  const [dependentTransactions, setDependentTransactions] = useState<Transaction[]>([]);

  const { checkBuySellTransaction } = useTransactionsService(companyId);

  const openApproveSubmitModal = useCallback(() => {
    setIsApproveSubmitModalOpen(true);
  }, []);

  const closeApproveSubmitModal = useCallback(() => {
    setIsApproveSubmitModalOpen(false);
  }, []);

  const openDependentTransactionsModal = useCallback((transactions: Transaction[]) => {
    setDependentTransactions(transactions);
  }, []);

  const closeDependentTransactionsModal = useCallback(() => {
    setDependentTransactions([]);
  }, []);

  const formInitialValues: BuySellEditDTO = useMemo(
    () => ({
      [EditBuySellFields.transactionId]: transactionToEdit.transactionId,
      [EditBuySellFields.companyId]: Number(companyId),
      [EditBuySellFields.transactedAt]:
        transactionToEdit.transactedAt || format(new Date().setHours(12, 0, 0, 0), dateFormatWithTime),
      [EditBuySellFields.description]: transactionToEdit.description || "",

      [EditBuySellFields.sellerStakeholderId]: transactionToEdit.sellerDetails.stakeholderId,
      [EditBuySellFields.firstName]: transactionToEdit.firstName || "",
      [EditBuySellFields.lastName]: transactionToEdit.lastName || "",
      [EditBuySellFields.email]: transactionToEdit.email || "",
      [EditBuySellFields.isCompanyOwned]: transactionToEdit.isCompanyOwned,
      [EditBuySellFields.companyName]: transactionToEdit.companyName || "",
      [EditBuySellFields.organizationNumber]: transactionToEdit.organizationNumber || "",
      [EditBuySellFields.numberOfShares]: transactionToEdit.numberOfShares || 0,
      [EditBuySellFields.purchasePrice]: transactionToEdit.purchasePrice || 0,
      [EditBuySellFields.shareClassId]: transactionToEdit.shareClassId,
      [EditBuySellFields.documents]: [],
      [EditBuySellFields.relationshipTypeId]: transactionToEdit.relationshipTypeId,
      [EditBuySellFields.documentStatusId]: transactionToEdit.documentStatusId,
      [EditBuySellFields.address]: transactionToEdit.address || "",
      [EditBuySellFields.dateOfBirth]: transactionToEdit.dateOfBirth || undefined,
      [EditBuySellFields.businessPostAddress]: transactionToEdit.businessPostAddress || "",
      [EditBuySellFields.businessEmail]: transactionToEdit.businessEmail || "",
      [EditBuySellFields.countryId]: transactionToEdit.countryId || undefined,
      [EditBuySellFields.phoneNumber]: transactionToEdit.phoneNumber || "",
      [EditBuySellFields.stakeholderId]: transactionToEdit.stakeholderId,
      [EditBuySellFields.prevFiles]: transactionToEdit?.documentFiles || [],
      [BuySellFields.transactionTypeId]: transactionToEdit?.transactionTypeId,
    }),
    // eslint-disable-next-line
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...transactionDetailsValidationSchema({ tFunction: tValidation }),
        ...sellerDetailsValidationSchema(tValidation),
        ...receiverDetailsValidationSchema(tValidation),
        ...sharesDetailsValidationSchema(tValidation, selectedShareClass?.sharesAvailable || 0),
        ...documentationDetailsValidationSchema(tValidation),
      }),
    [selectedShareClass?.sharesAvailable]
  );

  const handleSubmit = useCallback(
    async (values: BuySellEditDTO, actions: FormikHelpers<BuySellEditDTO>) => {
      const { documentStatusId, documents, ...fieldsForCheck }: DocumentationDetailsFieldsType & BuySellCheckDTO =
        values;

      const { failedTransactions, hasErrorsAfterEdit } = await checkBuySellTransaction(fieldsForCheck);

      if (hasErrorsAfterEdit && failedTransactions.length) {
        closeApproveSubmitModal();
        openDependentTransactionsModal(failedTransactions);
        return false;
      }

      if (hasErrorsAfterEdit) {
        return false;
      }

      const formData = new FormData();

      Object.values(EditBuySellFields).forEach((key) => {
        const formValue = values[key];

        if (!formValue) return;

        if (key === EditBuySellFields.documents) {
          values.documents.forEach((el: any) => formData.append("FilesData.Files", el));
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      if (values["prevFiles"]?.length) {
        values.prevFiles.forEach((el: any) => formData.append("FilesData.OldFileIds", el.fileId));
      }

      const createdSuccessfully = await onSubmit(formData);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }
    },
    [checkBuySellTransaction, closeApproveSubmitModal, onSubmit, openDependentTransactionsModal]
  );

  return (
    <Formik onSubmit={handleSubmit} initialValues={formInitialValues} validationSchema={validationSchema}>
      {({ isSubmitting, validateForm, submitForm }) => {
        return (
          <Form>
            <SlidePanel show={true} customRef={formRef}>
              <SlidePanel.Header title={t("buySellForm.title")} onHide={onClose} isEdit />

              {isDisabled && (
                <InfoAlert
                  className="p-3 mt-7 mb-5"
                  type="Warning"
                  customContent={
                    <div className="d-flex">
                      <WarningExclamationMarkIcon height={24} width={56} color={scssVariables.warning900} />
                      <div className="ms-2">
                        <Ui.m bold className="mb-1">
                          {t("buySellForm.pendingTransactionTitle")}
                        </Ui.m>
                        <Ui.s>{t("buySellForm.pendingTransactionContent")}</Ui.s>
                      </div>
                    </div>
                  }
                />
              )}

              <InfoAlert
                customContent={
                  <div className="d-flex py-1 px-2">
                    <div className="me-1">
                      <InformationCircleIcon fontSize="24" color={scssVariables.information900} />
                    </div>
                    <P.s>{t("editInfo")}</P.s>
                  </div>
                }
                type="Info"
                className="mb-5"
              />

              <SlidePanel.Section title={t("transactionDetails.title")}>
                <TransactionDetails
                  hiddenFields={{ transactionTypeId: true, transactionTypeTransferId: false }}
                  isDisabled={isDisabled}
                />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("buySellForm.seller")}>
                <SellerDetails
                  setSelectedShareClass={setSelectedShareClass}
                  selectedShareClassId={selectedShareClass?.shareClassId || null}
                  isDisabled={isDisabled}
                  initialSeller={transactionToEdit.sellerDetails}
                />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("sharesDetails.title")}>
                <SharesDetailsV2 selectedShareClass={selectedShareClass} isDisabled={isDisabled} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("buySellForm.buyer")}>
                <StakeholderSection isDisabled={isDisabled} />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("documentationDetails.title")}>
                <P.s className="mb-4">{t("documentationDetails.description")}</P.s>

                <DocumentUploadWithStatuses
                  oldFilesName={EditBuySellFields.prevFiles}
                  newFilesName={EditBuySellFields.documents}
                  disabled={isDisabled}
                />
              </SlidePanel.Section>

              <SlidePanel.Actions>
                <Button
                  isLoading={isSubmitting}
                  onClick={async () => {
                    const errors = await validateForm();
                    if (isEmpty(errors) && !transactionToEdit.canEditConfirmed) {
                      openApproveSubmitModal();
                    } else {
                      submitForm();
                    }
                  }}
                  type="submit"
                  className="me-3"
                  isDisabled={isDisabled}
                >
                  {t("sharedFormButtons.editBtn")}
                </Button>
                <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                  {t("sharedFormButtons.cancelBtn")}
                </Button>
              </SlidePanel.Actions>
            </SlidePanel>
            {isApproveSubmitModalOpen && (
              <SubmitModal dataManagerRef={dataManagerRef} onClose={closeApproveSubmitModal} />
            )}
            {!!dependentTransactions.length && (
              <DependentTransactions transactions={dependentTransactions} onClose={closeDependentTransactionsModal} />
            )}
            <ToastFormikValidator />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditBuySellTransactionForm;
