import { CSSProperties, FC, ReactNode } from "react";
import cn from "classnames";

import { scssVariables, SCSSVariablesType } from "common/utils/constants";

const sizes = ["xxl", "xl", "l", "m", "s"] as const;

type ParagraphPropsTypes = {
  children?: ReactNode;
  className?: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  color?: keyof typeof SCSSVariablesType | CSSProperties["color"];
} & Omit<JSX.IntrinsicElements["p"], "color">;
type Sizes = (typeof sizes)[number];
type ParagraphGroup = Record<Sizes, FC<ParagraphPropsTypes>>;

const paragraphGenerator = (size: Sizes) => {
  const Paragraph: FC<ParagraphPropsTypes> = ({
    children,
    className,
    bold,
    italic,
    color,
    style,
    underline,
    ...props
  }) => {
    const currentColor = color
      ? scssVariables[color as unknown as SCSSVariablesType]
        ? scssVariables[color as unknown as SCSSVariablesType]
        : color
      : style?.color;

    return (
      <p
        style={{ color: currentColor, ...style }}
        className={cn(`paragraph-${size}`, className, { bold: bold, italic: italic, underline: underline })}
        {...props}
      >
        {children}
      </p>
    );
  };

  return Paragraph;
};

const P: ParagraphGroup = sizes.reduce((acc, size) => {
  return {
    ...acc,
    [size]: paragraphGenerator(size),
  };
}, {} as ParagraphGroup);

export default P;
