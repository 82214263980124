import { FunctionComponent, useCallback, useRef } from "react";
import { PieCustomLayerProps, PieTooltipProps, ResponsivePie } from "@nivo/pie";

import { scssVariables } from "common/utils/constants";

export type PieData = {
  id: string;
  value: number;
  color: string;
};

export type PieChartProps = {
  height?: number;
  data: PieData[];
  filled?: boolean;
  pieThickness?: number;
  isInteractive?: boolean;
  centeredContent?: string;
  centeredContentComponent?: JSX.Element;
  tooltip?: FunctionComponent<PieTooltipProps<PieData>>;
};

const PieChart = ({
  data,
  filled,
  tooltip,
  centeredContent,
  height = 250,
  centeredContentComponent,
  isInteractive = true,
  pieThickness = 0.65,
}: PieChartProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const CenteredMetric = useCallback(
    ({ centerX, centerY }: PieCustomLayerProps<PieData>) => {
      if (!centeredContent && !centeredContentComponent) {
        return null;
      }

      return centeredContentComponent ? (
        <foreignObject
          x={centerX}
          y={centerY}
          height={(ref?.current?.clientHeight || 1) / 2}
          width={(ref?.current?.clientWidth || 1) / 2}
          transform={`translate(${-(ref?.current?.clientWidth || 1) / 4}, ${-height / 4})`}
        >
          {centeredContentComponent}
        </foreignObject>
      ) : (
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fill: scssVariables.foregroundMedium,
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          {centeredContent}
        </text>
      );
    },
    [centeredContent, centeredContentComponent, height]
  );

  return (
    <div
      style={{
        height,
        width: "100%",
      }}
      ref={centeredContentComponent ? ref : undefined}
    >
      {data.length ? (
        <ResponsivePie
          data={data}
          tooltip={tooltip}
          enableArcLabels={false}
          arcLinkLabelsThickness={2}
          activeOuterRadiusOffset={8}
          enableArcLinkLabels={false}
          isInteractive={isInteractive}
          colors={{ datum: "data.color" }}
          margin={{ top: 10, bottom: 10 }}
          innerRadius={filled ? 0 : pieThickness}
          layers={["arcs", "arcLabels", "arcLinkLabels", CenteredMetric]}
        />
      ) : null}
    </div>
  );
};

export default PieChart;
