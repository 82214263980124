import { FC, useCallback, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useFormikContext } from "formik";

import { Button } from "common/components/atoms";
import Helper from "common/components/atoms/Helper/Helper";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H } from "common/components/atoms/Typography";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import HasDividendsField from "common/shareClass/components/ShareClassForm/HasDividendsField";
import HasVotingRightsField from "common/shareClass/components/ShareClassForm/HasVotingRightsField";
import ShareClassTypeField from "common/shareClass/components/ShareClassForm/ShareClassTypeField";
import VotingRightsMultipleField from "common/shareClass/components/ShareClassForm/VotingRightsMultipleField";
import { ShareClassTypes } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

import CreateShareClassStore from "../../store";
import Sidebar from "./Sidebar";
import { SpecificsFormData } from "./Specifics";

const t = createTranslation(TranslationNS.pages, "createShareClass.specifics");

const SpecificsForm: FC = () => {
  const { prevWizardStep, setTotalWizardSteps } = useWizard();

  const updateFormDataAction = CreateShareClassStore.useStoreActions((actions) => actions.updateFormData);

  const { isSubmitting, values, handleSubmit } = useFormikContext<SpecificsFormData>();

  const handleChangeShareClassType = useCallback(
    (value: string | number) => {
      updateFormDataAction({
        shareClassTypeId: +value,
      });
    },
    [updateFormDataAction]
  );

  useEffect(() => {
    if (values.shareClassTypeId === ShareClassTypes.common) {
      setTotalWizardSteps(2);
    } else {
      setTotalWizardSteps(3);
    }
  }, [setTotalWizardSteps, values.shareClassTypeId]);

  const buttonBackClickHandler = useCallback((): void => {
    updateFormDataAction(values);

    prevWizardStep();
  }, [prevWizardStep, updateFormDataAction, values]);

  return (
    <>
      <WizardContent.Content>
        <H.xs className="text-right mb-3">{t("title")}</H.xs>

        <Form onSubmit={handleSubmit}>
          <Helper>
            <ShareClassTypeField changeCallback={handleChangeShareClassType} className="mb-5" />

            <Row>
              <Col md={7}>
                <Helper.Question questionId="hasVotingRights" type="switcher">
                  <HasVotingRightsField />
                </Helper.Question>
              </Col>
            </Row>
            <Row>
              <Col>
                <Helper.Answer
                  answerId={"hasVotingRights"}
                  text={t("hasVotingRightsAnswer")}
                  linkText={t("hasVotingRightsReadMore")}
                  link="/"
                />
              </Col>
            </Row>

            {values.hasVotingRights && (
              <>
                <Row>
                  <Col className="mt-4" md={7}>
                    <Helper.Question questionId="multipleVotingRights" type="input">
                      <VotingRightsMultipleField />
                    </Helper.Question>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Helper.Answer
                      className="mt-3"
                      answerId={"multipleVotingRights"}
                      text={t("multipleVotingRightsAnswer")}
                      linkText={t("multipleVotingRightsReadMore")}
                      link="/"
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col className="mt-4" md={7}>
                <Helper.Question questionId="hasDividends" type="switcher">
                  <HasDividendsField />
                </Helper.Question>
              </Col>
            </Row>
            <Row>
              <Col>
                <Helper.Answer
                  answerId={"hasDividends"}
                  text={t("hasDividendsAnswer")}
                  linkText={t("hasDividendsReadMore")}
                  link="/"
                />
              </Col>
            </Row>

            <div className="d-flex mt-17">
              <WizardContent.Controls.BackButton className="ms-auto" onClick={buttonBackClickHandler} />
              {values.shareClassTypeId === ShareClassTypes.common ? (
                <Button className="ms-2" type="submit">
                  {t("controls.createShareClass")}
                </Button>
              ) : (
                <WizardContent.Controls.ContinueButton className="ms-2" type="submit" />
              )}
            </div>
          </Helper>
        </Form>

        {isSubmitting ? <Spinner /> : null}
      </WizardContent.Content>

      <Sidebar />
    </>
  );
};

export default SpecificsForm;
