import { P } from "common/components/atoms/Typography";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import WarrantsWizardContext from "../ReceiveWarrantsContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.receiverSide");

const Info = () => {
  const { warrantDetails } = WarrantsWizardContext.useStoreState((state) => state);
  const { currencySymbol } = useCurrencyById(warrantDetails?.currencyId);

  const fNumber = useFormatNumbers(currencySymbol);

  return (
    <>
      <P.l className="mt-6 mb-3">
        {t.el("description", {
          values: {
            companyName: warrantDetails?.companyName,
            shares: fNumber(warrantDetails?.numberOfShares, "amount"),
            strikePrice: fNumber(warrantDetails?.strikePrice, "unitPrice"),
          },
          components: [<strong key={1} />],
        })}{" "}
        {warrantDetails?.expirationDate
          ? t("expirationDate", {
              expirationDate: transformDateToCommonDateFormat(warrantDetails?.expirationDate),
            })
          : null}
        .
      </P.l>

      {warrantDetails?.documentsNeedsSignature ? (
        <>
          <P.l className="mt-3">{t("readAttachedAgreement")}</P.l>
          <P.l className="mt-3 mb-6">{t("descriptionBottom")}</P.l>
        </>
      ) : (
        <P.l className="mt-3 mb-6">{t("descriptionBottom")}</P.l>
      )}
    </>
  );
};

export default Info;
