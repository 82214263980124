import { FC } from "react";

import { GoverningDocumentsType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import FinancialInstrumentsTableRow from "./FinancialInstrumentsTableRow";

type FinancialInstrumentsTableBodyProps = {
  data: GoverningDocumentsType["documentCategories"];
};

const FinancialInstrumentsTableBody: FC<FinancialInstrumentsTableBodyProps> = ({ data }) => {
  return (
    <tbody className={classes.body}>
      {data.map((el, index) => (
        <FinancialInstrumentsTableRow item={el} key={`${el.name} ${index} - ${el.entityId}`} />
      ))}
    </tbody>
  );
};

export default FinancialInstrumentsTableBody;
