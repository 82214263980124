import { FC, ReactNode } from "react";

import { H, P } from "common/components/atoms";
import ReadMore from "common/components/molecules/ReadMore/ReadMore";
import SingleAgreementDetailSection from "common/components/organisms/agreements/components/common/details/SingleAgreementDetailSection";
import { ShareClassSingleType } from "store/types";

import classes from "./agreement-summary.module.scss";
import PostTerminationClause from "./post-termination-clause";

export type Item = {
  title?: string | ReactNode | JSX.Element;
  description: string;
  content: string;
  // this prop need to add for custom UI layout for share classes info section
  isShareClass?: boolean;
  shareClass?: ShareClassSingleType | null;
};

type PropsTypes = {
  agreementItems: Item[];
  postTerminationClauseItems?: Item[] | null;
  essentialTerms?: string;
};

const AgreementSummary: FC<PropsTypes> = ({ agreementItems, postTerminationClauseItems, essentialTerms }) => {
  return (
    <>
      <div className={classes["content"]}>
        {agreementItems.map((item, index) => (
          <SingleAgreementDetailSection
            key={index}
            title={item.title}
            subTitle={item.description}
            infoContent={item.content}
            isShareClass={item?.isShareClass}
            shareClassInfo={item?.shareClass}
          />
        ))}
      </div>

      {postTerminationClauseItems && postTerminationClauseItems.length > 0 && (
        <PostTerminationClause terminationItems={postTerminationClauseItems} />
      )}

      {essentialTerms && (
        <div className="mt-3">
          <H.xxxs className="mb-1">Essential terms from agreement</H.xxxs>
          <ReadMore description={essentialTerms} modalTitle="Essential terms">
            <P.s style={{ whiteSpace: "break-spaces" }}>{essentialTerms}</P.s>
          </ReadMore>
        </div>
      )}
    </>
  );
};

export default AgreementSummary;
