import { FC, KeyboardEventHandler, useCallback } from "react";
import { Dropdown as BDropdown } from "react-bootstrap";
import cn from "classnames";
import * as R from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { EditIcon, ProfileHeadIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./TeamMember.module.scss";

export type TeamMemberProps = {
  firstName: string;
  lastName: string;
  email?: string;
  jobRoles: string[];
  imgLink: string | null;
  withoutRoleSelection?: boolean;
  onEdit: () => void;
  onChange: (e: string) => void;
};

const translation = createTranslation(TranslationNS.common, "components.teamMember");

const TeamMember: FC<TeamMemberProps> = ({ firstName, lastName, email, jobRoles, imgLink, onEdit }) => {
  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === "Enter") {
      onEdit();
    }
  };

  const renderRoles = useCallback(
    () =>
      !R.isEmpty(jobRoles) &&
      jobRoles?.map((el, index) => {
        return (
          <Ui.xs key={`Team member single key is - ${el} - ${index}`} className={classes["team-member-role"]}>
            {el}
          </Ui.xs>
        );
      }),
    [jobRoles]
  );

  return (
    <>
      <BDropdown.Divider
        style={{
          padding: 0,
          opacity: 0.1,
          backgroundColor: "#131313",
        }}
      />

      <div className={cn("d-flex align-items-center justify-content-between", classes[" team-member-container"])}>
        <div className="d-flex align-items-center">
          <div>
            {!R.isNil(imgLink) ? (
              <img
                src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${imgLink}`}
                width={48}
                height={48}
                style={{ borderRadius: "50%" }}
                alt={translation("memberImageAlt", { firstName, lastName })}
              />
            ) : (
              <ProfileHeadIcon height={48} width={48} />
            )}
          </div>
          <div className="d-flex flex-column ms-2">
            <Ui.m>{!R.isNil(firstName) ? firstName + " " + lastName : email}</Ui.m>
            <div className="d-flex ms-1">{renderRoles()}</div>
          </div>
        </div>
        <div
          tabIndex={0}
          className="cursor-pointer"
          data-testid={`team-member-${firstName}-edit-icon-test-id`}
          onClick={onEdit}
          onKeyDown={handleKeyDown}
        >
          <EditIcon />
        </div>
      </div>
    </>
  );
};

export default TeamMember;
