import { useFormikContext } from "formik";

import Helper from "common/components/atoms/Helper/Helper";
import TextField from "common/components/atoms/TextField/TextField";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.sharesDetails");

const ProgramPurchasePriceField = () => {
  const { currencySymbol } = useCurrency();

  const { values, handleBlur, touched, errors, handleChange } = useFormikContext<CreateProgramFormData>();

  return (
    <Helper.Question questionId="purchasePrice" type="input" answer={t(`purchasePrice.answer.${values.programTypeId}`)}>
      <TextField
        step={0.01}
        type="number"
        error={errors.purchasePrice}
        isTouched={touched.purchasePrice}
        name="purchasePrice"
        value={values.purchasePrice}
        label={t(`purchasePrice.label.${values.programTypeId}`)}
        onChange={handleChange}
        onBlur={handleBlur}
        iconRight={currencySymbol}
      />
    </Helper.Question>
  );
};

export default ProgramPurchasePriceField;
