import { FC, Fragment, useCallback } from "react";
import cn from "classnames";

import { Button, LinkHelpText, P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { IconRight, QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { IExercisablePlan } from "pages/my-porfolio/exercising/ExercisingContext";
import { createTranslation, TranslationNS } from "translation";

import classes from "../OwnershipSinglePlanComponents/StockOptions/purchase/Purchase.module.scss";

type PropsTypes = {
  profit: boolean;
  profitLossValue: number;
  exercisePrice: number;
  markedSharePrice: number;
  currency: string;
};

const t = createTranslation(TranslationNS.common, "atoms.exercisingComponents.availableToExerciseItem");

const Profit: FC<PropsTypes> = ({ profit, currency, profitLossValue, exercisePrice, markedSharePrice }) => {
  const isPositive = profit;

  const fNumber = useFormatNumbers(currency);

  return (
    <div className={classes["profit-container"]}>
      <div
        className={cn(
          classes["profit-head"],
          {
            [classes["negative"]]: !isPositive,
          },
          "p-3"
        )}
      >
        <div className="d-flex justify-content-between align-items-center">
          <Ui.s color={isPositive ? "positive900" : "critical900"} className="fw-500">
            {t("unrealisedProfit")}
          </Ui.s>

          <LinkHelpText
            tooltipWithTitle
            title={t("unrealizedProfitInfoTitle")}
            content={t("unrealizedProfitInfoDescription")}
          >
            <QuestionCircleIcon
              fontSize={24}
              style={{ color: isPositive ? scssVariables.positive900 : scssVariables.critical900 }}
            />
          </LinkHelpText>
        </div>

        <Ui.xxl color={isPositive ? "positive900" : "critical900"} bold>
          {isPositive ? "+" : "-"} {fNumber(Math.abs(profitLossValue), "value")}
        </Ui.xxl>
      </div>

      <div className="py-2 px-3">
        {!isPositive ? (
          <Ui.s bold className="mb-4">
            {t("losingYourMoney")}
          </Ui.s>
        ) : null}

        <Ui.s color="foregroundMedium">
          {t.el("potentialProfitLossDescription", {
            components: [
              <strong key={1} />,
              <Fragment key={2}>
                <br />
                <br />
              </Fragment>,
            ],
            values: {
              sharePrice: fNumber(markedSharePrice, "sharePrice"),
              exercisePrice: fNumber(exercisePrice, "unitPrice"),
            },
          })}
        </Ui.s>
      </div>
    </div>
  );
};

const SingleAvailableToExerciseItem = (
  item: Omit<
    IExercisablePlan,
    "estimatedIncome" | "exercisedOptions" | "name" | "stockOptionPlanTotalShares" | "managerName"
  > & {
    handleSelect: (id: number) => void;
  }
) => {
  const fNumber = useFormatNumbers();

  const handleClick = useCallback(() => {
    item.handleSelect(item.planId);
  }, [item]);

  return (
    <div className={cn("mb-3 p-4", classes.row)}>
      <div className={cn("d-flex flex-column align-items-start w-100 me-3", classes.purchaseColumn)}>
        <div className="d-flex flex-column h-100">
          <div className="d-flex flex-wrap mb-4">
            {item?.companyName ? (
              <Ui.s color="foregroundLow" className="me-2 mb-1">{`${
                item?.companyName ? item.companyName + " • " : null
              }${t("soAgreement")}`}</Ui.s>
            ) : null}

            {item.terminated ? (
              <div className="d-flex align-items-center">
                <Tag variant="declined">Terminated</Tag>

                <Ui.xs color="critical700" className="ms-1 fw-500">
                  Expiry date: {transformDateToCommonDateFormat(item?.expirationDate)}
                </Ui.xs>
              </div>
            ) : null}
          </div>

          <Ui.xl bold>
            {t("sharesAvailable", {
              shares: fNumber(item.vestedNotExercised, "amount"),
            })}
          </Ui.xl>

          <P.m className="mt-1 mb-4">
            {t.el("sharesExpire", {
              components: [
                <Fragment key={1}>
                  <br />
                  <br />
                </Fragment>,
              ],
              values: {
                currency: item.currencySymbol,
                optionsVested: fNumber(item.vestedNotExercised, "amount"),
                companyName: item.companyName,
                exercisePrice: fNumber(item.exercisePrice, "unitPrice", { customCurrencySymbol: item.currencySymbol }),
                totalExercisePrice: fNumber(item.vestedNotExercised * item.exercisePrice, "unitPrice", {
                  customCurrencySymbol: item.currencySymbol,
                }),
              },
            })}
          </P.m>

          <Button
            variant="secondary"
            className="mt-auto me-auto"
            iconRight={<IconRight className="ms-1" />}
            onClick={handleClick}
          >
            {t("startExercising")}
          </Button>
        </div>
      </div>

      <Profit
        profit={item.profit}
        profitLossValue={item.profitLossValue}
        currency={item.currencySymbol}
        exercisePrice={item.exercisePrice}
        markedSharePrice={item.sharePrice}
      />
    </div>
  );
};

export default SingleAvailableToExerciseItem;
