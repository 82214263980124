import { FC, useCallback, useMemo } from "react";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import { IssueEquityConvertibleStatuses } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DeleteIcon, EditIcon, MenuTabBarVerticalIcon, TransactionsIcon } from "common/icons/svg";
import { setConveribleTagColorBasedOnId, transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import { IssueEquityConvertibleLoanDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";

type RowType = {
  item: IssueEquityConvertibleLoanDTO;
};

const t = createTranslation(TranslationNS.pages, "company.issueEquity");

const ConvertibleLoanTableRow: FC<RowType> = ({ item }) => {
  const { hasFullAccess } = useFeatures(FEATURES.issueEquity);

  const fNumber = useFormatNumbers();

  const { dropdowns } = useStoreState((state) => state.common);

  const selectedValue = dropdowns?.convertibleStatuses?.find((el) => el.id === item.status);
  const tagColor = setConveribleTagColorBasedOnId(
    item.status as (typeof IssueEquityConvertibleStatuses)[keyof typeof IssueEquityConvertibleStatuses]
  );

  const { setIsConvertibleLoanEditPanelOpen, setIsConvertLoanEditPanelOpen, setIsOpenDeleteModalCL } =
    IssueEquityContext.useStoreActions((actions) => actions);

  const handleConvertLoan = useCallback(() => {
    setIsConvertLoanEditPanelOpen({ open: true, loanId: item.id });
  }, [item.id, setIsConvertLoanEditPanelOpen]);

  const handleEditLoan = useCallback(() => {
    setIsConvertibleLoanEditPanelOpen({ open: true, loanId: item.id });
  }, [item.id, setIsConvertibleLoanEditPanelOpen]);

  const handleDeleteLoan = useCallback(() => {
    setIsOpenDeleteModalCL({ open: true, loanId: item.id, stakeholderName: item.stakholderName });
  }, [item.id, item.stakholderName, setIsOpenDeleteModalCL]);

  const menuItems: ContextMenuProps["items"] = useMemo(() => {
    const items = [
      {
        key: "edit",
        label: t("edit"),
        icon: <EditIcon />,
        onClick: !hasFullAccess ? undefined : handleEditLoan,
        isDisabled: !hasFullAccess,
      },
      {
        key: "convert",
        label: t("convert"),
        icon: <TransactionsIcon />,
        onClick: !hasFullAccess ? undefined : handleConvertLoan,
        isDisabled: !hasFullAccess,
      },
      {
        key: "remove",
        label: t("delete"),
        icon: <DeleteIcon />,
        onClick: !hasFullAccess ? undefined : handleDeleteLoan,
        type: "delete" as const,
        isDisabled: !hasFullAccess,
      },
    ];

    if (item.status === IssueEquityConvertibleStatuses.Draft || item.status === IssueEquityConvertibleStatuses.Sent) {
      return items.filter((item) => item.key !== "convert");
    }

    return items;
  }, [handleConvertLoan, handleDeleteLoan, handleEditLoan, hasFullAccess, item.status]);

  return (
    <tr className={classes["table-row"]}>
      <td className={classes["truncate"]}>
        {item.representedBy !== item.stakholderName && <Ui.s bold>{item.stakholderName}</Ui.s>}
        {item.representedBy !== item.stakholderName ? (
          <Ui.s className={classes.representedBy}>
            {item.representedBy !== item.stakholderName ? item.representedBy : item.stakholderName}
          </Ui.s>
        ) : (
          <Ui.s bold>{item.representedBy !== item.stakholderName ? item.representedBy : item.stakholderName}</Ui.s>
        )}
      </td>
      <td className="text-end">
        <Ui.s>{fNumber(item.loanAmount, "value")}</Ui.s>
      </td>
      <td className="text-end">
        <Ui.s>{fNumber(item.discount, "percent")}</Ui.s>
      </td>
      <td className="text-end">
        <Ui.s>{fNumber(item.interestRate, "percent")}</Ui.s>
      </td>
      <td className="text-end">
        <Ui.s>{item.expirationDate ? transformDateToCommonDateFormat(item.expirationDate) : "-"}</Ui.s>
      </td>
      <td className="text-end">
        <div
          className={classes["status-tag"]}
          style={{ backgroundColor: tagColor?.bgColor, color: tagColor?.textColor }}
        >
          <Ui.xs>{selectedValue?.name}</Ui.xs>
        </div>
      </td>
      <td style={{ width: 40 }}>
        {item.status !== IssueEquityConvertibleStatuses.Converted && (
          <ContextMenu items={menuItems}>
            <Button
              size="s"
              isOnlyIcon
              variant="tertiary"
              data-testid="plan-dropdown-btn"
              className={cn(classes["add-btn"], {
                [classes["dark-icon"]]: true,
              })}
            >
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        )}
      </td>
    </tr>
  );
};

export default ConvertibleLoanTableRow;
