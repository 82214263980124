import cn from "classnames";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CheckIcon, DocumentsIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { countDocumentsInCategories, countDocumentsInProgram } from "../../documents-filters";
import DocumentsContext from "../../DocumentsContext";
import classes from "./DocumentsCounter.module.scss";
const t = createTranslation(TranslationNS.pages, "company.documents.counter");

const DocumentsCounter = () => {
  const fNumber = useFormatNumbers();
  const { documents } = DocumentsContext.useStoreState((state) => state);

  const governingDocsCount = countDocumentsInCategories(documents?.governingDocuments?.documentCategories || []);
  const transactionDocsCount = countDocumentsInCategories(documents?.transactionDocuments?.transactions || []);
  const financialDocsCount = countDocumentsInCategories(documents?.financialInstrumentDocuments?.instruments || []);
  const poolDocsCount = countDocumentsInCategories(documents?.poolDocuments?.pools || []);
  const standaloneDocsCount = countDocumentsInCategories(documents?.standaloneAgreementDocuments?.agreements || []);
  const programDocsCount = countDocumentsInProgram(documents?.programDocuments?.programs || []);

  const totalMissing =
    governingDocsCount.missing +
    transactionDocsCount.missing +
    financialDocsCount.missing +
    poolDocsCount.missing +
    standaloneDocsCount.missing +
    programDocsCount.missing;

  const totalUploaded =
    governingDocsCount.uploaded +
    transactionDocsCount.uploaded +
    financialDocsCount.uploaded +
    poolDocsCount.uploaded +
    standaloneDocsCount.uploaded +
    programDocsCount.uploaded;

  return (
    <div className="d-flex gap-3 mb-3">
      <div
        className="d-flex align-items-center justify-content-between py-4 px-3 w-50"
        style={{ background: scssVariables.surface1 }}
      >
        <div>
          <Ui.s className="mb-2">{t("missing")}</Ui.s>
          <Ui.xxl bold>{fNumber(totalMissing, "amount")}</Ui.xxl>
        </div>
        {totalMissing === 0 ? (
          <div className={cn("d-flex justify-content-center align-items-center", classes.circleGreen)}>
            <CheckIcon fontSize={32} />
          </div>
        ) : (
          <div className={cn("d-flex justify-content-center align-items-center", classes.circleYellow)}>
            <WarningIcon fontSize={32} strokeWidth={0.5} />
          </div>
        )}
      </div>
      <div
        className="d-flex align-items-center justify-content-between py-4 px-3 w-50"
        style={{ background: scssVariables.surface1 }}
      >
        <div>
          <Ui.s className="mb-2">{t("total")}</Ui.s>
          <Ui.xxl bold>{fNumber(totalUploaded, "amount")}</Ui.xxl>
        </div>
        <div className={cn("d-flex justify-content-center align-items-center", classes.circleBlue)}>
          <DocumentsIcon fontSize={32} />
        </div>
      </div>
    </div>
  );
};

export default DocumentsCounter;
