import { FC, useCallback, useState } from "react";

import { ModalInfo } from "common/components/atoms";
import { useStoreActions } from "store/store";

import ManagePlansContext from "../../../managePlansContext";
import classes from "./MarkSharesAsSoldModal.module.scss";
import MarkSharesAsSoldModalForm from "./MarkSharesAsSoldModalForm";

type Props = {
  companyId: string;
};
const MarkSharesAsSoldModal: FC<Props> = ({ companyId }) => {
  const [allowGetAgreements, setAllowGetAgreements] = useState(false);
  const getOwnershipPlansThunk = useStoreActions((actions) => actions.company.getOwnershipPlansThunk);
  const plan = ManagePlansContext.useStoreState((state) => state.markSharesAsSoldInfo);

  const setMarkSharesAsSoldInfo = ManagePlansContext.useStoreActions((actions) => actions.setMarkSharesAsSoldInfo);

  const show = plan !== null;

  const fullName = plan?.stakholderCompanyName || plan?.representedBy || "-";

  const handleClose = useCallback(() => {
    setMarkSharesAsSoldInfo(null);
    setAllowGetAgreements(false);
  }, [setMarkSharesAsSoldInfo]);

  const handleOnExited = useCallback(() => {
    if (allowGetAgreements) {
      getOwnershipPlansThunk(+companyId);
    }
  }, [allowGetAgreements, companyId, getOwnershipPlansThunk]);

  const dataSaveCallback = useCallback(() => {
    setMarkSharesAsSoldInfo(null);
    setAllowGetAgreements(true);
  }, [setMarkSharesAsSoldInfo]);

  return (
    <ModalInfo
      dialogClassName={classes.dialog}
      show={show}
      onHide={handleClose}
      header="Mark shares as sold"
      size="lg"
      headerClassName="justify-content-start"
      onExited={handleOnExited}
    >
      <MarkSharesAsSoldModalForm
        handleClose={handleClose}
        planId={plan?.id}
        exercisedSharesSold={plan?.exercisedSharesSold}
        maxNumberOfShares={plan?.exercisedShares}
        fullName={fullName}
        dataSaveCallback={dataSaveCallback}
      />
    </ModalInfo>
  );
};

export default MarkSharesAsSoldModal;
