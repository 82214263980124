import { FC, MouseEventHandler, ReactNode, RefObject, useCallback } from "react";
import classNames from "classnames";
import { defaultTo } from "ramda";

import { Button, Collapsible, Ui } from "common/components/atoms";
import { ChevronDownIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ExercisingCollapsable.module.scss";

const t = createTranslation(TranslationNS.common, "noAccess");

type ExercisingCollapsableProps = {
  title: string;
  btnTitle?: string;
  description: string;
  countItems?: number;
  headButtonDisabled?: boolean;
  headButtonTooltipTitle?: string;
  headButtonTooltipMessage?: string;
  children: ReactNode;
  headerRef?: RefObject<HTMLDivElement>;
  onHeadBtnClick?: MouseEventHandler<HTMLButtonElement>;
};

const ExercisingCollapsable: FC<ExercisingCollapsableProps> = ({
  title,
  countItems,
  description,
  btnTitle,
  onHeadBtnClick,
  headerRef,
  children,
  headButtonDisabled,
  headButtonTooltipTitle,
  headButtonTooltipMessage,
}) => {
  const renderHead = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div className={classes["head"]} ref={headerRef} onClick={onClick}>
          <div>
            <div className="d-flex align-items-center">
              <Ui.xl bold>{title}</Ui.xl>

              <Ui.xs color="foregroundLow" className={classNames("ms-1 fw-500", classes["countItems"])}>
                {defaultTo(0, countItems)}
              </Ui.xs>
            </div>

            <Ui.xs color="foregroundLow" className="mt-1">
              {description}
            </Ui.xs>
          </div>

          <div className={classes["controls"]}>
            {btnTitle && onHeadBtnClick && (
              <Button
                size="s"
                className="me-2"
                variant="secondary"
                onClick={onHeadBtnClick}
                tooltipTitle={headButtonTooltipTitle ? headButtonTooltipTitle : t("viewOnly")}
                tooltipMessage={headButtonTooltipMessage ? headButtonTooltipMessage : t("tooltip")}
                isDisabled={headButtonDisabled}
              >
                {btnTitle}
              </Button>
            )}

            <ChevronDownIcon className={classes["chevron"]} direction={activeEventKey ? "top" : "bottom"} />
          </div>
        </div>
      );
    },
    [
      headerRef,
      title,
      countItems,
      description,
      btnTitle,
      onHeadBtnClick,
      headButtonTooltipTitle,
      headButtonTooltipMessage,
      headButtonDisabled,
    ]
  );

  return (
    <Collapsible defaultOpen withoutDivider Header={renderHead} className="mt-3">
      <div
        className={classNames(classes["content"], {
          [classes["empty"]]: !countItems,
        })}
      >
        {children}
      </div>
    </Collapsible>
  );
};

export default ExercisingCollapsable;
