import { Action, action } from "easy-peasy";

import { ReceiveRouteType } from "app/Router/RouterV2.types";

export interface InvitedUserModel {
  invitedUser: InvitedUser | null;

  setInvitedUser: Action<this, InvitedUser | null>;
}

export const invitedUser: InvitedUserModel = {
  invitedUser: null,

  setInvitedUser: action((state, payload) => {
    state.invitedUser = payload;
  }),
};

export type InvitedUser = {
  approvalKey: string;
  companyId?: number;
  invitationExpiry: string;
  entityId?: string;
  invitationType?: ReceiveRouteType;
  // this property need to determine - where user is purchasing the subscription - to show different UI on success purchasing page
  navigationFromApp?: boolean;
};
