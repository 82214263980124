import { FC, memo, useCallback, useMemo } from "react";
import { defaultTo } from "ramda";

import { Button, ContextMenu, ContextMenuProps, NewAvatar, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DeleteIcon, EditIcon, MenuTabBarVerticalIcon } from "common/icons/svg";
import { usePlanStatus } from "common/plan/planUtils";
import { scssVariables } from "common/utils/constants";
import { ProgramPlanDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import CreatePlanStore from "../../../store";
import classes from "../basics.module.scss";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.tableRows");

type TableRowProps = {
  row: ProgramPlanDTO;
};

const TableRow: FC<TableRowProps> = ({ row }) => {
  const fNumber = useFormatNumbers();
  const { setCreateOrEditPlan, setDeletePlanInfo } = CreatePlanStore.useStoreActions((actions) => actions);

  const status = usePlanStatus(row.statusId);

  const handleClickEdit = useCallback(() => {
    setCreateOrEditPlan({ planId: row.id, type: "other", stakeholderName: row.stakeholderName });
  }, [row, setCreateOrEditPlan]);

  const handleClickRemove = useCallback(() => {
    setDeletePlanInfo({ planId: row.id, stakeholderName: row.stakeholderName });
  }, [row.id, row.stakeholderName, setDeletePlanInfo]);

  const menuItems: ContextMenuProps["items"] = useMemo(() => {
    return [
      {
        key: "edit",
        label: t("edit"),
        icon: <EditIcon />,
        onClick: handleClickEdit,
      },
      {
        key: "remove",
        label: t("remove"),
        icon: <DeleteIcon />,
        onClick: handleClickRemove,
        type: "delete",
      },
    ];
  }, [handleClickEdit, handleClickRemove]);

  return (
    <tr>
      <td>
        <div className="d-flex">
          <NewAvatar
            className={classes["avatar"]}
            imageUrl={undefined}
            initials={defaultTo("", row.representedBy)
              .split(" ")
              .map((el) => el[0])
              .join("")}
          />
          <div className="ms-2">
            <Ui.m bold>{row.representedBy}</Ui.m>
            {row.stakholderCompanyName ? (
              <Ui.s style={{ color: scssVariables.foregroundLow }}>{row.stakholderCompanyName}</Ui.s>
            ) : null}
          </div>
        </div>
      </td>
      <td>
        <Tag variant={status.variant}>{status.label}</Tag>
      </td>
      <td style={{ textAlign: "end" }}>
        <Ui.s>{fNumber(row.numberOfShares, "amount")}</Ui.s>
      </td>

      {status.variant === "draft" ? (
        <td>
          <ContextMenu items={menuItems}>
            <Button size="s" isOnlyIcon variant="tertiary">
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </td>
      ) : null}
    </tr>
  );
};

export default memo(TableRow);
