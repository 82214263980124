import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import * as R from "ramda";

import Button from "common/components/atoms/Button/Button";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H, P, Ui } from "common/components/atoms/Typography";
import ReadAndAcceptDocuments, {
  ReadAndAcceptDocumentType,
} from "common/components/molecules/read-and-accept-documents/ReadAndAcceptDocuments";
import SignatureCollapsable from "common/components/molecules/signature-collapsible/SignatureCollapsible";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ReceiveOwnershipPlan.wizard.module.scss";
import ReceivePlanManagerContext from "./ReceivePlanManagerContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.receiveOwnershipPlanManager");

const Content = () => {
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updatedDocuments, setUpdatedDocuments] = useState<ReadAndAcceptDocumentType[]>([]);
  const [isReadDocuments, setSsReadDocuments] = useState(false);
  const invitedUser = useStoreState((state) => state.invitedUser);
  const { setIsFooterShown, setHeaderContextShown } = useStoreActions((actions) => actions.app);

  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);
  const { isLoading, planDetails, signature } = ReceivePlanManagerContext.useStoreState((state) => state);
  const { getPlanDetailsThunk, acceptPlanThunk, setSignature, setIsLoading } =
    ReceivePlanManagerContext.useStoreActions((actions) => actions);

  const { currencySymbol } = useCurrencyById(planDetails?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const isAcceptOfferButtonActive = useMemo(() => !R.isNil(signature) && isReadDocuments, [isReadDocuments, signature]);

  const submitPlanHandler = useCallback(async () => {
    setIsSubmitting(true);
    const request = await acceptPlanThunk({
      signature,
      approvalKey: invitedUser.invitedUser?.approvalKey || "",
      planIds: updatedDocuments.filter((document) => document.isSelected).map((document) => document.id as number),
    });

    if (request) {
      notify(t("success"), true, "success", 3000);
      setInvitedUser(null);

      navigate("/");
    }

    setIsSubmitting(false);
  }, [acceptPlanThunk, navigate, invitedUser.invitedUser?.approvalKey, setInvitedUser, signature, updatedDocuments]);

  const declinePlanHandler = useCallback(() => {
    setInvitedUser(null);

    navigate("/", { replace: true });
  }, [navigate, setInvitedUser]);

  useEffect(() => {
    setIsFooterShown(false);
    setHeaderContextShown(false);

    return () => {
      setIsFooterShown(true);
      setHeaderContextShown(true);
    };
  }, [setHeaderContextShown, setIsFooterShown]);

  useEffect(() => {
    if (!R.isNil(invitedUser?.invitedUser) && !R.isNil(invitedUser?.invitedUser?.approvalKey)) {
      getPlanDetailsThunk(R.defaultTo("", invitedUser?.invitedUser?.approvalKey));
    }
  }, [invitedUser, invitedUser?.invitedUser?.approvalKey, getPlanDetailsThunk]);

  const documentsCheckboxes = useMemo(() => {
    return planDetails?.documents?.map((document) => ({
      documentTypeId: 1,
      downloadId: document.downloadId,
      fileName: document.fileName,
      id: document.planId,
      isSelected: false,
      label: t("documentCheckboxLabel", {
        firstName: document?.firstName,
        lastName: document?.lastName,
        numberOfShares: document?.numberOfShares,
      }),
    }));
  }, [planDetails?.documents]);

  if (isLoading && R.isNil(planDetails)) {
    return (
      <Spinner
        style={{
          borderColor: scssVariables.element1,
          borderRightColor: "transparent",
        }}
      />
    );
  }

  return (
    planDetails && (
      <PageContent className={classes.container} data-testid="receive-plan-test-id">
        <H.s>{t("title")}</H.s>

        <P.l className={cn("mt-4", "mb-2")} color="foregroundMedium">
          {t.el("planMainInformation", {
            values: {
              companyName: planDetails?.companyName,
              grantedShares: fNumber(planDetails?.totalNumberOfShares, "amount"),
              valuedShares: fNumber(planDetails?.totalShareValuation, "value"),
            },
            components: [<strong className={classes.bold} key={1} />],
          })}
        </P.l>

        <P.l className={cn(classes.description)} color="foregroundMedium">
          {t.el("description", {
            components: [
              <strong key={0} />,
              <div key={1}>
                <br />
                <br />
              </div>,
            ],
          })}
        </P.l>

        <P.l className={cn(classes.description, "mt-2 mb-6")} color="foregroundMedium">
          {t.el("description1", {
            components: [<strong key={0} />],
          })}
        </P.l>

        <div className="mt-3" />

        <ReadAndAcceptDocuments
          title={t("blockDocumentsTitle")}
          subtitle={t("blockDocumentsSubtitle")}
          noticeText={t("blockDocumentNotice")}
          currentStep={1}
          documents={R.defaultTo([], documentsCheckboxes)}
          setIsReadDocuments={setSsReadDocuments}
          documentsCallback={setUpdatedDocuments}
          actionsStart={() => {
            setIsLoading(true);
          }}
          actionsEnd={() => {
            setIsLoading(false);
          }}
          isSingleSelection
        />

        <div className="mt-3" />

        <SignatureCollapsable
          title={"Approve and sign agreements"}
          subtitle={"A signature will be required to accept the ownership plans."}
          currentStep={2}
          signature={signature}
          setSignature={setSignature}
        />

        <div className="mt-7 d-flex align-items-center">
          <Button
            className="me-2"
            data-testid="submit-btn-test-id"
            isDisabled={!isAcceptOfferButtonActive}
            isLoading={isSubmitting}
            onClick={submitPlanHandler}
          >
            {t("acceptOffer")}
          </Button>
          <Button
            variant="secondary"
            isDisabled={isSubmitting}
            data-testid="decline-btn-test-id"
            onClick={declinePlanHandler}
          >
            {t("declineOffer")}
          </Button>
        </div>

        <Ui.xs italic className="mt-3" style={{ color: scssVariables.foregroundMedium }}>
          {t("approvingExplanation")}
        </Ui.xs>
      </PageContent>
    )
  );
};

const ReceivePlanManagerWizard = () => {
  return (
    <ReceivePlanManagerContext.Provider>
      <Content />
    </ReceivePlanManagerContext.Provider>
  );
};

export default ReceivePlanManagerWizard;
