export function highlightSubstring(fullString: string, substring: string) {
  // Create a regular expression pattern for the substring with case-insensitive matching
  const pattern = new RegExp(`(${substring})`, "gi");

  // Split the fullString into parts based on the substring pattern
  const parts = fullString.split(pattern);

  // Map each part to a React element, highlighting the matching parts
  const highlightedParts = parts.map((part, index) => {
    if (index % 2 === 1) {
      // Odd parts (matching substrings) are wrapped in <b> tags for highlighting
      return <b key={index}>{part}</b>;
    }
    return part; // Even parts (non-matching substrings) are returned as is
  });

  // Join the highlighted parts back into a single React element
  return <>{highlightedParts}</>;
}
