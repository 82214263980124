import { useCallback, useEffect, useMemo } from "react";

import WizardLayoutStore, { WizardLayoutCurrentStep, WizardLayoutSidebarContentActiveItem } from "./store";

type UseWizardData = {
  wizardStep: number;
  wizardTotalSteps: number;
  setWizardStep(step: number): void;
  nextWizardStep(): void;
  prevWizardStep(): void;
  setTotalWizardSteps(total: number): void;
};

export function useWizard(): UseWizardData {
  const currentStepState = WizardLayoutStore.useStoreState((state) => state.currentStep);
  const wizardTotalSteps = WizardLayoutStore.useStoreState((actions) => actions.totalSteps);
  const setTotalWizardSteps = WizardLayoutStore.useStoreActions((actions) => actions.setTotalSteps);
  const setCurrentStepAction = WizardLayoutStore.useStoreActions((actions) => actions.setCurrentStep);

  const setWizardStep = useCallback<UseWizardData["setWizardStep"]>(
    (step) => {
      setCurrentStepAction(step);
    },
    [setCurrentStepAction]
  );

  const nextWizardStep = useCallback<UseWizardData["nextWizardStep"]>(() => {
    setCurrentStepAction(currentStepState + 1);
  }, [currentStepState, setCurrentStepAction]);

  const prevWizardStep = useCallback<UseWizardData["prevWizardStep"]>(() => {
    if (currentStepState > 1) {
      setCurrentStepAction(currentStepState - 1);
    }
  }, [currentStepState, setCurrentStepAction]);

  return useMemo<UseWizardData>(
    () => ({
      wizardStep: currentStepState,
      setWizardStep,
      nextWizardStep,
      prevWizardStep,
      wizardTotalSteps,
      setTotalWizardSteps,
    }),
    [currentStepState, nextWizardStep, prevWizardStep, setTotalWizardSteps, setWizardStep, wizardTotalSteps]
  );
}

export function useWizardStepper(currentStep: WizardLayoutCurrentStep): void {
  const setCurrentStepAction = WizardLayoutStore.useStoreActions((actions) => actions.setCurrentStep);

  useEffect(() => {
    setCurrentStepAction(currentStep);
  }, [setCurrentStepAction, currentStep]);
}

type UseWizardSidebarData = {
  setContentActiveItem(activeItem: WizardLayoutSidebarContentActiveItem): void;
  handleFocus(activeItem: WizardLayoutSidebarContentActiveItem): () => void;
};

export function useWizardSidebar(): UseWizardSidebarData {
  const setSidebarContentActiveItemAction = WizardLayoutStore.useStoreActions(
    (actions) => actions.setSidebarContentActiveItem
  );

  const setContentActiveItem = useCallback<UseWizardSidebarData["setContentActiveItem"]>(
    (eventKey): void => {
      setSidebarContentActiveItemAction(eventKey);
    },
    [setSidebarContentActiveItemAction]
  );

  const handleFocus = useCallback<UseWizardSidebarData["handleFocus"]>(
    (eventKey) => (): void => {
      setContentActiveItem(eventKey);
    },
    [setContentActiveItem]
  );

  return useMemo<UseWizardSidebarData>(
    () => ({
      setContentActiveItem,
      handleFocus,
    }),
    [setContentActiveItem, handleFocus]
  );
}

export const useWizardCloseModal = (initialShow?: boolean) => {
  const setShowCloseModalStore = WizardLayoutStore.useStoreActions((actions) => actions.setShowCloseModal);

  const setShowCloseModal = useCallback<(show: boolean) => void>(
    (show) => {
      setShowCloseModalStore(show);
    },
    [setShowCloseModalStore]
  );

  useEffect(() => {
    if (initialShow !== undefined) {
      setShowCloseModal(initialShow);
    }
  }, [initialShow, setShowCloseModal]);

  return useMemo(
    () => ({
      setShowCloseModal,
    }),
    [setShowCloseModal]
  );
};
