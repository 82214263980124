import { FC, useMemo, useState } from "react";
import cn from "classnames";

import CTable, { TableProps as CTableProps } from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import { FullyDilutedCapTable } from "../../CapTable.types";
import CapTableStore from "../../store";
import Body from "./Body";
import Foot from "./Foot";
import Head from "./Head";
import classes from "./Table.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.table");

export type TableProps = Omit<CTableProps, "headComponent" | "bodyComponent"> & Record<string, unknown>;
export type HeaderRow = { key: keyof FullyDilutedCapTable; value: string; className?: string };

export type FullyDilutedCapTableSortValue = {
  field: keyof FullyDilutedCapTable;
  type: keyof typeof sortingParams;
};

const Table: FC<TableProps> = ({ className, ...props }) => {
  const [activeSortedValue, setActiveSortedValue] = useState<FullyDilutedCapTableSortValue>({
    field: "stakeholderName",
    type: sortingParams.inc,
  });

  const { actualData } = CapTableStore.useStoreState((state) => state);

  const rows: HeaderRow[] = useMemo(
    () => [
      {
        key: "stakeholderName",
        value: t("fullyDiluted.stakeholder"),
      },
      {
        key: "shareClasses",
        value: t("common.shareClass"),
      },
      {
        key: "instrument",
        value: t("fullyDiluted.instrument"),
      },
      {
        key: "shares",
        value: t("common.shares"),
        className: "ms-auto",
      },
      {
        key: "ownershipPercentage",
        value: t("common.ownership"),
        className: "ms-auto",
      },
      {
        key: "sharesFullyDiluted",
        value: t("fullyDiluted.sharesDiluted"),
        className: "ms-auto",
      },
      {
        key: "ownershipPercentageFullyDiluted",
        value: t("fullyDiluted.ownershipDiluted"),
        className: "ms-auto",
      },
      {
        key: "differenceFullyDiluted",
        value: t("fullyDiluted.difference"),
        className: "ms-auto",
      },
    ],
    []
  );

  return (
    <CTable
      className={cn(classes.table, className, {
        ["pe-none"]: !actualData.hasAccessToDetails,
        [classes.removeHoverEffects]: !actualData.hasAccessToDetails,
      })}
      headComponent={<Head rows={rows} sortedValue={activeSortedValue} setSortedValue={setActiveSortedValue} />}
      bodyComponent={<Body sortedValue={activeSortedValue} />}
      footComponent={<Foot />}
      {...props}
    />
  );
};

export default Table;
