import { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { defaultTo } from "ramda";

import { Button, H, P, TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import classes from "./ConvertNotePanel.module.scss";
import HelperHint from "./sections/components/HelperHint";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.issueEquity.convertNote.conversion.overrideSharePriceModal"),
  createTranslation(TranslationNS.validation),
];

const OverrideSharePriceModal = () => {
  const fNumber = useFormatNumbers();
  const calculatedConvertNote = IssueEquityContext.useStoreState((state) => state.calculateCN);
  const numberOfNoteShares = IssueEquityContext.useStoreState((state) => state.numberOfNoteShares);

  const isModalVisible = IssueEquityContext.useStoreState((state) => state.isOverrideSharesPriceModalVisible);
  const setIsOverrideModalVisible = IssueEquityContext.useStoreActions(
    (actions) => actions.setIsOverrideSharesPriceModalVisible
  );

  const noteSharePrice = IssueEquityContext.useStoreState((state) => state.noteSharePrice);
  const setNoteSharePrice = IssueEquityContext.useStoreActions((actions) => actions.setNoteSharePrice);

  const priceValue = +(defaultTo(0, calculatedConvertNote?.investmentAmount) / numberOfNoteShares).toFixed(2);

  const [value, setValue] = useState<number>(priceValue);
  const { formattedCurrency } = useCurrency();

  const handleClose = () => {
    setIsOverrideModalVisible(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isNaN(Number(e.target.value))) {
      setValue(Number(e.target.value));
    }
  };

  const handleSubmit = () => {
    setNoteSharePrice(value);
    handleClose();
  };

  useEffect(() => {
    setValue(noteSharePrice ? noteSharePrice : priceValue);
  }, [noteSharePrice, priceValue, isModalVisible]);

  return (
    <Modal show={isModalVisible} centered onHide={handleClose} dialogClassName={classes["modal"]}>
      <div className="py-7 px-9">
        <H.xs className="text-center mb-5">{t("title")}</H.xs>

        <P.m className="mb-3">{t("description")}</P.m>

        <HelperHint className="my-3" />

        <TextField
          min={1}
          isTouched
          value={value}
          type="number"
          className="my-5"
          label={t("inputLabel")}
          error={
            !(value > 0)
              ? tValidation("minNumber", {
                  number: 0,
                })
              : undefined
          }
          placeholder={fNumber(value, "sharePrice", { hideCurrencySymbol: true })}
          onChange={handleChange}
          iconRight={formattedCurrency}
        />

        <div className="d-flex justify-content-center">
          <Button onClick={handleSubmit} isDisabled={!value || !(value > 0)}>
            {t("update")}
          </Button>

          <Button variant="secondary" className="ms-2" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OverrideSharePriceModal;
