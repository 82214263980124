import { FC } from "react";
import { useFormikContext } from "formik";

import SubscriptionLimitsReached from "common/components/atoms/SubscriptionLimitsReached/SubscriptionLimitsReached";

import StakeholderSearchField from "../fields/StakeholderSearchField";
import SelectedStakeholder from "../SelectedStakeholder/SelectedStakeholder";
import { StakeholderFormValues } from "../Stakeholder.types";

type ExistingStakeholderProps = {
  isDisabled?: boolean;
  allowUpdate?: boolean;
  showSubscriptionLimitBanner?: boolean;
};
const ExistingStakeholder: FC<ExistingStakeholderProps> = ({
  isDisabled,
  allowUpdate,
  showSubscriptionLimitBanner,
}) => {
  const { values } = useFormikContext<StakeholderFormValues & { subscriptionAvailable?: any }>();

  return (
    <div>
      {!values.stakeholderId ? (
        <StakeholderSearchField />
      ) : (
        <>
          <SelectedStakeholder isDisabled={isDisabled} allowUpdate={allowUpdate} />
          {showSubscriptionLimitBanner && (
            <div className="mt-2 mb-4">
              <SubscriptionLimitsReached numberOfPlans={values.subscriptionAvailable?.maximumNumberAgreements || 0} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ExistingStakeholder;
