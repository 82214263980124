import * as Yup from "yup";

import { TFunction } from "translation/helpers";

import { SharesDetailsFields } from "./form-fields";

export const sharesDetailsValidationSchema = (tFunction: TFunction, sharesAvailable: number) => ({
  [SharesDetailsFields.numberOfShares]: Yup.number()
    .test("is-decimal", tFunction("integer"), (value) => !!String(value).match(/^\d+$/))
    .min(
      1,
      tFunction("minNumber", {
        number: 1,
      })
    )
    .max(
      sharesAvailable,
      tFunction("maxNumber", {
        number: sharesAvailable,
      })
    )
    .required(tFunction("required")),
  [SharesDetailsFields.purchasePrice]: Yup.number()
    .min(
      0.000001,
      tFunction("minNumber", {
        number: "0,000001",
      })
    )
    .required(tFunction("required")),
});
