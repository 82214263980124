export const ReceiverDetailsFields = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  isCompanyOwned: "isCompanyOwned",
  companyName: "companyName",
  organizationNumber: "organizationNumber",
  relationshipTypeId: "relationshipTypeId",
  address: "address",
  dateOfBirth: "dateOfBirth",
  businessPostAddress: "businessPostAddress",
  businessEmail: "businessEmail",
  countryId: "countryId",
  phoneNumber: "phoneNumber",
  stakeholderId: "stakeholderId",
} as const;
