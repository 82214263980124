import React, { FC } from "react";
import { take } from "ramda";

import { Tooltip } from "common/components/atoms";
import { Ui } from "common/components/atoms/Typography";

import classes from "../../stakeholders-management.module.scss";

type Props = {
  contacts: {
    name: string;
    isPrimary: boolean;
  }[];
};

export const StakeholderContactsRowText: FC<Props> = ({ contacts }) => {
  return (contacts?.length <= 2 ? contacts : take(2, contacts)).map((el, index, array) => (
    <Ui.s key={index} color="foregroundLow">
      {el.name}
      {index === 0 && array.length > 1 ? "," : ""}
      &nbsp;
    </Ui.s>
  ));
};

export const StakeholderContactsCounter: FC<Props> = ({ contacts }) => {
  return contacts?.length > 2 ? (
    <Tooltip
      popupContent={
        <div className="text-start">
          <Ui.xs className="mb-half" bold>
            All contacts
          </Ui.xs>

          {contacts.map((el, index) => (
            <Ui.xs color="foregroundLow" key={index}>
              {el.name} {el.isPrimary ? " (representative)" : ""}
            </Ui.xs>
          ))}
        </div>
      }
    >
      <div className={classes["contacts-counter"]}>+{contacts.length - 2}</div>
    </Tooltip>
  ) : null;
};
