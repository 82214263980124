import { FC, useState } from "react";
import cn from "classnames";
import { equals } from "ramda";

import { Ui } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";
import { CommonOwnershipPlanDetails } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import TabSwitcher from "../tab-switcher/TabSwitcher";
import VestingTable from "../vesting-table/VestingTable";
import classes from "./ReleaseScheduleChart.module.scss";
import ScheduleChartContent from "./ScheduleChartContent";

const t = createTranslation(TranslationNS.common, "organisms.agreement.releaseScheduleChart");

type ReleaseScheduleChartProps = {
  agreement: CommonOwnershipPlanDetails | null;
  isLosted?: boolean;
} & JSX.IntrinsicElements["div"];

const ReleaseScheduleChart: FC<ReleaseScheduleChartProps> = ({ agreement, isLosted, className, ...props }) => {
  const [activeTab, setActiveTab] = useState<"chart" | "table">("chart");

  return (
    <div className={cn(classes.container, className)} {...props}>
      <div className="d-flex justify-content-between align-items-center mb-5">
        <div>
          <Ui.l bold>{t("releaseSchedule")}</Ui.l>
          <Ui.xs style={{ color: scssVariables.foregroundMedium }}>{t("displayingPlanProcess")}</Ui.xs>
        </div>

        <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      {equals(activeTab, "chart") ? <ScheduleChartContent agreement={agreement} isLosted={isLosted} /> : null}
      {equals(activeTab, "table") ? <VestingTable agreement={agreement} /> : null}
    </div>
  );
};

export default ReleaseScheduleChart;
