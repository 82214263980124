import { ChangeEvent, FC, useCallback, useState } from "react";
import { Modal } from "react-bootstrap";

import Button from "common/components/atoms/Button/Button";
import TextArea from "common/components/atoms/TextArea/TextArea";
import { H } from "common/components/atoms/Typography";
import { createTranslation, TranslationNS } from "translation";

type PropsTypes = {
  isOpen: boolean;
  onModalClose: () => void;
  isExisting?: boolean;
  onSubmit: (declineReason: string) => void;
  isLoading: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.receivePlan.footer.declineModal");

const inputLimit = 100;

const DeclineModal: FC<PropsTypes> = ({ isExisting, isOpen, onModalClose, onSubmit, isLoading }) => {
  const [reason, setReason] = useState("");

  const handleChangeReason = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.length <= inputLimit) {
      setReason(event.target.value);
    }
  }, []);

  const handleSubmit = () => {
    onSubmit(reason.trim());
  };

  return (
    <Modal centered show={isOpen} onHide={onModalClose} contentClassName="py-7 px-9">
      <H.xs className="mb-5 text-center">{t(isExisting ? "titleExisting" : "title")}</H.xs>

      <TextArea
        className="mb-5"
        value={reason}
        onChange={handleChangeReason}
        maxLength={inputLimit}
        label={t("inputLabel")}
        meta={`${reason.length}/${inputLimit}`}
      />

      <div className="d-flex justify-content-center">
        <Button isLoading={isLoading} isDisabled={isExisting && !reason.trim()} onClick={handleSubmit} className="me-2">
          {t(isExisting ? "notifyManager" : "decline")}
        </Button>
        <Button isDisabled={isLoading} variant="secondary" onClick={onModalClose}>
          {t("cancel")}
        </Button>
      </div>
    </Modal>
  );
};

export default DeclineModal;
