import { FC, useCallback } from "react";
import classNames from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import NewAvatar from "common/components/atoms/NewAvatar/NewAvatar";
import Tag from "common/components/atoms/Tag/Tag";
import { Ui } from "common/components/atoms/Typography";
import { createTranslation, TranslationNS } from "translation";

import { AccessControlUserDTO } from "../../types";
import classes from "./user-card.module.scss";

type PropsTypes = {
  user: AccessControlUserDTO;
  onEdit: (id: number) => void;
};

const t = createTranslation(TranslationNS.pages, "companySettings.accessControlPage");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const UserCard: FC<PropsTypes> = ({ user, onEdit }) => {
  const { hasFullAccess } = useFeatures(FEATURES.userAccessManagement);

  const handleEditClick = useCallback(() => {
    onEdit(user.companyUserId);
  }, [onEdit, user.companyUserId]);

  return (
    <div className={classes.container} data-testid="user-card">
      {!user.hasAcceptedInvite && (
        <Tag className={classes["invited-tag"]} variant="draft">
          {t("invitedTag")}
        </Tag>
      )}
      {user.isAdmin && user.hasAcceptedInvite && (
        <Tag className={classes["admin-tag"]} variant="information">
          {t("adminTag")}
        </Tag>
      )}
      <div>
        <Ui.m className="mb-2" bold>{`${user.firstName} ${user.lastName}`}</Ui.m>
        <Ui.m className={classNames("mb-3", classes.email)}>{user.email}</Ui.m>
        <Button
          variant="secondary"
          size="s"
          onClick={handleEditClick}
          isDisabled={!hasFullAccess}
          data-testid="edit-btn"
          tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
          tooltipMessage={tCommon("tooltip")}
        >
          {t("editPermissionBtn")}
        </Button>
      </div>
      <NewAvatar imageUrl={user.avatarFilePath} initials={user.initials} />
    </div>
  );
};

export default UserCard;
