import { FC, useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useFormik } from "formik";
import { defaultTo } from "ramda";
import { object, string } from "yup";

import { Button, ModalInfo, TextArea } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDetailsContext } from "../../../stakeholder-details.context";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.shareSeriesTable.modals.setPledge");

type Props = {
  show: boolean;
  issuedSharesId?: number;
  pledgeNotes?: string;
  hasPledges?: boolean;
  handleClose?: () => void;
};
const SetPledgedModal: FC<Props> = (props) => {
  return (
    <ModalInfo size="sm" show={props.show} header={props.hasPledges ? t("editPledge") : t("markSharesAsPledged")}>
      <PledgeForm {...props} />
    </ModalInfo>
  );
};

type PledgeFormProps = Props;
type PledgeFormValues = {
  pledgeNotes?: string;
  issuedSharesId?: number;
};
const PledgeForm: FC<PledgeFormProps> = (props) => {
  const getDataFunction = StakeholderDetailsContext.useStoreState((state) => state.getDataFunction);

  const onSubmit = useCallback(
    async (values: PledgeFormValues) => {
      console.log(values);
      try {
        await axios.post("/api/equity-management/cap-table/mark-pledges", values);
        props?.handleClose?.();
        getDataFunction?.();
      } catch (e) {
        console.log(e);
      }
    },
    [getDataFunction, props]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        pledgeNotes: string().optional().nullable().max(256, t("validate")),
      }),
    []
  );

  const { values, handleChange, handleBlur, touched, errors, handleSubmit, isSubmitting } = useFormik<PledgeFormValues>(
    {
      initialValues: {
        pledgeNotes: props.pledgeNotes,
        issuedSharesId: props.issuedSharesId,
      },
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    }
  );

  return (
    <div>
      <TextArea
        name="pledgeNotes"
        label={t("pledgeNotes")}
        isOptional
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.pledgeNotes}
        isTouched={touched.pledgeNotes}
        value={values.pledgeNotes}
        rows={5}
        info={`${256 - defaultTo(0, values?.pledgeNotes?.length)} characters left`}
      />

      <Modal.Footer>
        <Button
          variant="primary"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("save")}
        </Button>
        <Button variant="secondary" isDisabled={isSubmitting} onClick={props.handleClose}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default SetPledgedModal;
