import { FC, useMemo, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import TextFied from "common/components/atoms/TextField/TextField";
import { notify } from "common/utils/notify/notifyFunction";
import { PASSWORD_VALIDATION_PATTERN } from "common/utils/validators";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

const translation = createTranslation(TranslationNS.pages, "user.settings.changePasswordFrom");

const validationTranslation = createTranslation("validation");

type ChangePasswordFromProps = {
  open: boolean;
  handleClose: (withModalOpen?: boolean) => void;
};

const ChangePasswordFrom: FC<ChangePasswordFromProps> = ({ open, handleClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      password: R.defaultTo("", ""),
      confirmPassword: R.defaultTo("", ""),
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        password: Yup.string()
          .label(translation("password.label"))
          .required()
          .min(8, validationTranslation("minimumCharacters", { number: 8 }))
          .matches(PASSWORD_VALIDATION_PATTERN, validationTranslation("passwordRequirements")),

        confirmPassword: Yup.string()
          .required()
          .label(translation("confirmPassword.label"))
          .test("passwords-match", validationTranslation("passwordMatch"), function (value) {
            return this.parent.password === value;
          }),
      }),
    []
  );

  const { values, errors, handleChange, touched, resetForm, handleSubmit } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  async function submitHandler() {
    setIsLoading(true);

    const changePasswordData = {
      password: values.password,
    };

    try {
      const request = await axios.post("/api/users/change-password", changePasswordData);
      if (request.status === 200) {
        notify(translation("notify.passwordChanged"), true, "success", 3000);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      const timer = setTimeout(() => {
        setIsLoading(false);
        resetForm();
        handleClose();

        clearTimeout(timer);
      }, 500);
    }
  }

  return (
    <SlidePanel
      show={open}
      onHide={() => {
        resetForm();
        handleClose();
      }}
    >
      <SlidePanel.Header
        title={translation("title")}
        onHide={() => {
          resetForm();
          handleClose();
        }}
      />
      <SlidePanel.Section title="" isSingle>
        <div className="form-floating mb-5">
          <TextFied
            style={{ width: "550px" }}
            type="password"
            label={translation("newPassword.label")}
            value={values.password}
            error={errors.password && touched.password ? String(errors.password) : undefined}
            onChange={handleChange("password")}
          />

          {!(errors.password && touched.password) && (
            <p className="m-0 position-absolute" style={{ bottom: -30 }}>
              {validationTranslation("minimumCharacters", { number: 8 })}
            </p>
          )}
        </div>
        <TextFied
          type="password"
          label={translation("confirmNewPassword.label")}
          value={values.confirmPassword}
          error={errors.confirmPassword && touched.confirmPassword ? String(errors.confirmPassword) : undefined}
          onChange={handleChange("confirmPassword")}
        />
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button isDisabled={isLoading} isLoading={isLoading} onClick={() => handleSubmit()}>
          {translation("changePassword")}
        </Button>
      </SlidePanel.Actions>
    </SlidePanel>
  );
};

export default ChangePasswordFrom;
