import { ChangeEvent, FC, KeyboardEvent, memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { isEmpty } from "ramda";

import { Button, LinkHelpText, TextField, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CloseIcon, GraphStatsIcon, IconRight, QuestionCircleIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import CapTableStore from "../../store";
import classes from "./valuation.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.actions.valuation");

type Props = {
  valuation: string;
  setValuation: (val: string) => void;
};

const Valuation: FC<Props & JSX.IntrinsicElements["div"]> = ({ valuation, setValuation, ...props }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const fNumber = useFormatNumbers();
  const { dilutedData, isValuationLoading } = CapTableStore.useStoreState((state) => state);
  const { getDilutedDataThunk } = CapTableStore.useStoreActions((actions) => actions);

  const handleValuationChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValuation(e.target.value.replace(/[^0-9]/g, ""));
    },
    [setValuation]
  );

  const handleCalculate = useCallback(() => {
    if (valuation) {
      getDilutedDataThunk({ companyId: Number(companyId), valuation: Number(valuation) });
    }
  }, [companyId, getDilutedDataThunk, valuation]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleCalculate();
      }
    },
    [handleCalculate]
  );

  const clearInput = useCallback(() => {
    setValuation("");

    getDilutedDataThunk({ companyId: Number(companyId), valuation: dilutedData.capTableSummary.valuation });
  }, [setValuation, getDilutedDataThunk, companyId, dilutedData.capTableSummary.valuation]);

  const renderCustomHelperInfoContent = useCallback(() => {
    return (
      <div className={cn(classes["helper"], "p-2")}>
        <Ui.s>
          {t.el("description", {
            components: [<br key={1} />],
            values: {
              valuation: fNumber(dilutedData.capTableSummary?.valuation),
            },
          })}
        </Ui.s>
      </div>
    );
  }, [dilutedData.capTableSummary?.valuation, fNumber]);

  return (
    <div className={cn(props.className, classes.valuation)}>
      <div className="d-flex flex-row align-items-end">
        <div className="position-relative">
          <TextField
            type="number"
            value={valuation}
            placeholder="Simulate valuation"
            className={cn(classes.input, "ms-3")}
            onKeyDown={handleKeyDown}
            onChange={handleValuationChange}
            iconLeft={<GraphStatsIcon className={classes["chart-icon"]} />}
          />

          {!isEmpty(valuation) ? <CloseIcon className={classes["input-remove-icon"]} onClick={clearInput} /> : null}
        </div>

        {isEmpty(valuation) ? (
          <div className="position-relative">
            <LinkHelpText trigger="click" title="" content="" CustomOverlay={renderCustomHelperInfoContent()}>
              <QuestionCircleIcon width={24} height={24} className={classes["mark"]} />
            </LinkHelpText>
          </div>
        ) : (
          <Button
            size="m"
            isOnlyIcon
            variant="secondary"
            isLoading={isValuationLoading}
            isDisabled={isValuationLoading}
            className={classes.buttonField}
            onClick={handleCalculate}
          >
            <IconRight />
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(Valuation);
