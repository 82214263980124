import { CSSProperties, FC, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";

import { SCSSVariablesType } from "../../../utils/constants";
import { Button, H, ModalInfo, P, Ui } from "../../atoms";
import classes from "./ReadMore.module.scss";

type ReadMoreProps = {
  description: string;
  children: ReactNode;
  className?: string;
  modalTitle?: string;
  readMoreColor?: keyof typeof SCSSVariablesType | CSSProperties["color"];
};

const ReadMore: FC<ReadMoreProps> = ({ description, children, className, modalTitle, readMoreColor }) => {
  const [isReadMoreVisible, setIsReadMoreVisible] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleManageModal = useCallback(() => {
    setIsModalVisible((prevState) => !prevState);
  }, []);

  useEffect(() => {
    if (ref.current) {
      const isOverflowing = ref?.current?.scrollHeight > ref.current?.clientHeight;

      if (isOverflowing) {
        setIsReadMoreVisible(true);
      } else {
        setIsReadMoreVisible(false);
      }
    }
  }, [description]);

  return (
    <div className={classNames(classes.wrap, className)}>
      <div className={classes.squeezed}>
        <div ref={ref}>{children}</div>
      </div>
      {isReadMoreVisible && (
        <Ui.s color={readMoreColor} className={classes["read-more"]} onClick={handleManageModal}>
          Read more
        </Ui.s>
      )}

      <ModalInfo centered show={isModalVisible} className={classes["description-modal"]} onHide={handleManageModal}>
        <div className={classes["content"]}>
          <H.xs className="mb-3 text-start">{modalTitle || "Description"}</H.xs>

          <P.m style={{ whiteSpace: "pre-line" }} className="text-start">
            {description}
          </P.m>
        </div>
        <Modal.Footer className={"justify-content-start"}>
          {
            <Button variant="secondary" onClick={handleManageModal}>
              Close
            </Button>
          }
        </Modal.Footer>
      </ModalInfo>

      {/*<Modal centered show={isModalVisible} className={classes["description-modal"]} onHide={handleManageModal}>
        <div className={classes["content"]}>
          <H.xs className="mb-3">{modalTitle || "Description"}</H.xs>

          <P.m style={{ whiteSpace: "pre-line" }}>{description}</P.m>

          <Button variant="secondary" className="mt-5" onClick={handleManageModal}>
            Close
          </Button>
        </div>
      </Modal>*/}
    </div>
  );
};

export default ReadMore;
