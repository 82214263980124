import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FormikValues, useFormik } from "formik";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Tag from "common/components/atoms/Tag/Tag";
import TextField from "common/components/atoms/TextField/TextField";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { TBankInformation } from "store/types";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import classes from "./BankDetails.module.scss";
import useBankDetailsForm from "./useBankDetailsForm";

const t = createTranslation(TranslationNS.pages, "companyProfile.bankInformation");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const BankDetailsPage: FC = () => {
  useDocumentTitleUpdate(t("title"));
  const { companyId } = useParams<{ companyId: string }>();

  const { hasFullAccess } = useFeatures(FEATURES.bankInformation);

  const countries = useStoreState((state) => state.common.dropdowns)?.countries;

  const [isBankInfoLoading, setIsBankInfoLoading] = useState(true);

  const { getBankInformationThunk, setBankInformationThunk } = useStoreActions((state) => state.company);

  const countriesDropDownList = useMemo(() => {
    const notSelected = { id: 0, name: "Not selected" };
    return countries ? [notSelected, ...countries] : [notSelected];
  }, [countries]);

  useEffect(() => {
    if (companyId) {
      getBankInformationThunk(+companyId).finally(() => setIsBankInfoLoading(false));
    }
  }, [companyId, getBankInformationThunk]);

  const { initialValues, validationSchema } = useBankDetailsForm();

  const onSubmit = useCallback(
    async (values: FormikValues) => {
      try {
        if (values.bankInformationId === null) {
          delete values.bankInformationId;
        }
        values.accountNumber = values.accountNumber.toString();
        await setBankInformationThunk(values as TBankInformation);
        await getBankInformationThunk(companyId ? +companyId : 0);
        notify(t("bankInfoSaved"), true, "success", 3000, false, "top-center");
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, getBankInformationThunk, setBankInformationThunk]
  );

  const { values, handleSubmit, handleChange, setFieldValue, errors, touched, handleBlur, isSubmitting } = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleChangeAccountNumber = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(/ /g, "");
      const newVal = value.replace(/^(\d{4})(\d{2})(\d{5}).*/, "$1 $2 $3");
      setFieldValue("accountNumber", newVal);
    },
    [setFieldValue]
  );

  return (
    <PageContent>
      <PageContent.Header>
        <div className="d-flex  align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag>{tCommon("viewOnly")}</Tag>}
        </div>
      </PageContent.Header>
      <LoaderContainer loading={isBankInfoLoading}>
        <div className={classes.wrap}>
          <form onSubmit={handleSubmit} className={classes["bank-information"]}>
            <TextField
              label={t("companyName.label")}
              value={values.accountCompanyName}
              isTouched={touched.accountCompanyName}
              error={errors.accountCompanyName}
              name="accountCompanyName"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              className="mt-4"
              label={t("bankAccountNumber.label")}
              placeholder="0000 00 0000"
              value={values.accountNumber}
              isTouched={touched.accountNumber}
              error={errors.accountNumber}
              name="accountNumber"
              onChange={handleChangeAccountNumber}
              onBlur={handleBlur}
            />
            <TextField
              name="swift"
              className="mt-4"
              label={t("swift.label")}
              placeholder="XXX0000"
              isOptional
              value={values.swiftBic}
              isTouched={touched.swiftBic}
              error={errors.swiftBic}
              onChange={handleChange("swiftBic")}
              onBlur={handleBlur}
            />
            <Dropdown
              className="mt-4"
              label={t("bankLocation.headquartersLocation.label")}
              error={errors.bankCountryId}
              isTouched={touched.bankCountryId}
              isSearchable
              optionsIsObject
              selectedValue={values.bankCountryId}
              options={countriesDropDownList}
              name="bankCountryId"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              className="mt-4"
              label={t("bankLocation.city.label")}
              value={values.bankCity}
              isTouched={touched.bankCity}
              error={errors.bankCity}
              name="bankCity"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <Button
              className="mt-9"
              isDisabled={!hasFullAccess || isSubmitting}
              isLoading={isSubmitting}
              type="submit"
              data-testid="save-btn"
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            >
              {t("save")}
            </Button>
          </form>
        </div>
      </LoaderContainer>
    </PageContent>
  );
};

export default BankDetailsPage;
