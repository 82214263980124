import { FC } from "react";
import cn from "classnames";

import { Button, H, ModalInfo, P } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./confirm.module.scss";

type PropsTypes = {
  onSubmit: () => void;
  onClose: () => void;
  isLoading: boolean;
  shareholderName: string;
  numberOfShares: number;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.rollbackTransactionModal");

const Confirm: FC<PropsTypes> = ({ onSubmit, onClose, shareholderName, numberOfShares, isLoading }) => {
  const fNumber = useFormatNumbers();

  return (
    <ModalInfo show={true} handleClose={isLoading ? undefined : onClose}>
      <div className="d-flex flex-column align-content-center">
        <H.xs className="mb-3">{t("title")}</H.xs>
        <P.m className="mb-3">
          {t("description", { shareholderName: shareholderName, numberOfShares: fNumber(numberOfShares, "amount") })}
        </P.m>
        <div className={cn("d-flex mb-5 py-2 px-2", classes.border)}>
          <div className="me-1">
            <InformationCircleIcon fontSize="24" color={scssVariables.foregroundLow} />
          </div>
          <P.s className="text-start">{t("info")}</P.s>
        </div>
        <div className="d-flex justify-content-center">
          <Button isLoading={isLoading} onClick={onSubmit} variant="danger" className="me-3">
            {t("confirmBtn")}
          </Button>
          <Button isDisabled={isLoading} onClick={onClose} variant="secondary">
            {t("cancelBtn")}
          </Button>
        </div>
      </div>
    </ModalInfo>
  );
};

export default Confirm;
