import { useCallback, useEffect, useMemo, useState } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import PieChart from "common/components/atoms/Charts/Pie.chart";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";

import classes from "./styles.module.scss";
const useGettingStartedGuide = () => {
  const gettingStartedAccess = useFeatures(FEATURES.gettingStartedGuide);
  const { gettingStartedGuide } = useStoreState((state) => state);
  const [isLoading, setIsLoading] = useState(true);

  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const { hasViewAccess } = useFeatures(FEATURES.gettingStartedGuide);

  const { getStartedGuideThunk } = useStoreActions((actions) => actions.gettingStartedGuide);

  useEffect(() => {
    if (activeCompanyId && hasViewAccess) {
      setIsLoading(true);
      getStartedGuideThunk(activeCompanyId).finally(() => setIsLoading(false));
    }
  }, [getStartedGuideThunk, activeCompanyId, hasViewAccess]);

  const progress = useMemo(() => {
    const valuationAndShares = gettingStartedGuide.valuationAndShares;
    const capTable = gettingStartedGuide.capTable;
    const pools = gettingStartedGuide.pools;
    const plans = gettingStartedGuide.plansInPrograms;
    const numberOfGrantedPlans = gettingStartedGuide.numberOfGrantedPlans;

    let percent = 5;
    percent += valuationAndShares.currentValuation ? 15 : 0;
    percent += !!capTable.numberOfShares && !!capTable.numberOfShareHolders ? 20 : 0;
    percent += pools.length ? 20 : 0;
    percent += plans.programs.length ? 20 : 0;
    percent += numberOfGrantedPlans ? 20 : 0;

    return percent;
  }, [
    gettingStartedGuide.capTable,
    gettingStartedGuide.numberOfGrantedPlans,
    gettingStartedGuide.plansInPrograms,
    gettingStartedGuide.pools,
    gettingStartedGuide.valuationAndShares,
  ]);

  const PieChartProgress = useCallback(() => {
    if (!gettingStartedAccess.hasFullAccess || isLoading) return undefined;
    return (
      <div className={classes.guide}>
        <PieChart
          height={40}
          pieThickness={0.6}
          isInteractive={false}
          data={[
            {
              color: scssVariables.foregroundMedium,
              value: progress,
              id: "completed",
            },
            {
              color: scssVariables.strokeHigh,
              value: 100 - progress,
              id: "not-completed",
            },
          ]}
        />
      </div>
    );
  }, [gettingStartedAccess.hasFullAccess, isLoading, progress]);

  return {
    PieChartProgress,
    progress,
  };
};

export default useGettingStartedGuide;
