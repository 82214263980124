import { FC } from "react";
import * as R from "ramda";

import ProgressChart from "common/components/atoms/Charts/Progress.chart";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { OwnershipPlans } from "store/types";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import classes from "./PlansOverview.module.scss";

const t = createTranslation(TranslationNS.common, "components.plansOverview");

export type PlansOverviewProps = {
  title: string;
  chartLabel: string;
  description: string;
  remainRowTitle: string;
  completedRowTitle: string;
  showChartLabels?: boolean;
  coloredSharePrice?: boolean;
  subTotalDescription1?: string;
  subTotalDescription2?: string;
  disableBottomBarAxisLabel?: boolean;
  plandata: Omit<
    OwnershipPlans,
    "issuedShares" | "oneOffPlans" | "ownershipPrograms" | "pools" | "valuation" | "totalSharesInCompany"
  > | null;
  withoutBottomVerticalLines?: boolean;
};

const PlansOverview: FC<PlansOverviewProps> = ({
  title,
  plandata,
  chartLabel,
  // description,
  remainRowTitle,
  showChartLabels,
  coloredSharePrice,
  completedRowTitle,
  subTotalDescription1,
  subTotalDescription2,
  disableBottomBarAxisLabel = false,
  withoutBottomVerticalLines,
}) => {
  const fNumber = useFormatNumbers();
  const allShares = (plandata?.sharesRestricted || 0) + (plandata?.sharesVested || 0);

  return (
    <div data-testid="plans-overview-component-test-id">
      <div className={`${classes["head"]} p-3`} style={{ position: "relative" }}>
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between mb-5">
            <div style={{ width: "45%" }}>
              <p className="m-0 ui-l fw-bold">{title}</p>
            </div>
            <div style={{ width: "45%" }}>
              <p className="m-0 fw-bold text-end">{fNumber(plandata?.totalPlansValue, "value")}</p>
              <p
                className="m-0 mt-1 ui-xxs text-end w-100"
                style={{ color: scssVariables.foregroundHigh, width: "250px" }}
              >
                {subTotalDescription1}
              </p>
              {subTotalDescription2 && (
                <p
                  className="m-0 ui-xxs text-end w-100"
                  style={{ color: scssVariables.foregroundHigh, width: "250px" }}
                >
                  {subTotalDescription2}
                </p>
              )}
            </div>
          </div>

          <ProgressChart
            withLeftBorder
            withoutLabel={!showChartLabels}
            total={allShares}
            data={[
              {
                id: chartLabel,
                withThumb: true,
                showTooltip: true,
                color: scssVariables.positive500,
                thumbColor: scssVariables.positive900,
                value: R.defaultTo(0, plandata?.sharesVested),
                labelColor: showChartLabels ? "white" : "transparent",
              },
            ]}
          />
          {disableBottomBarAxisLabel ? null : (
            <div className="d-flex justify-content-between ui-xxs mt-1">
              <p className="m-0"></p>
              <p className="m-0">{fNumber(allShares, "amount")}</p>
            </div>
          )}
        </div>
      </div>
      <div className={classes["footer"]}>
        <div
          className={classes["section"]}
          style={{
            color: scssVariables.positive900,
            border: withoutBottomVerticalLines ? "none" : undefined,
          }}
        >
          <p className="m-0 ui-s align-self-center">{completedRowTitle}</p>

          <div className="d-flex justify-content-evenly mt-2">
            <div className="text-center">
              <p className="m-0 ui-m fw-bold mb-1">{fNumber(plandata?.sharesVested, "amount")}</p>

              <p className="m-0 ui-xxs">{t("shares")}</p>
            </div>
            <div className="text-center">
              <p className="m-0 ui-m fw-bold mb-1">{fNumber(plandata?.sharesVestedValue, "value")}</p>

              <p className="m-0 ui-xxs">{t("value")}</p>
            </div>
          </div>
        </div>

        <div
          className={`${classes["section"]} ${classes["middle-section"]}`}
          style={{
            flexBasis: "50%",
            border: withoutBottomVerticalLines ? "none" : undefined,
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 8,
              backgroundColor: coloredSharePrice ? scssVariables.element1 : undefined,
            }}
          >
            <div className="d-flex justify-content-evenly">
              <div className="text-center">
                <p className="m-0 ui-m fw-bold mb-1">{fNumber(plandata?.sharePrice, "sharePrice")}</p>

                <p className="m-0 ui-xxs" style={{ color: scssVariables.foregroundHigh }}>
                  {t("sharePrice")}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={classes["section"]}
          style={{
            border: "none",
            color: scssVariables.primary1,
          }}
        >
          <p className="m-0 ui-s align-self-center mb-2">{remainRowTitle}</p>

          <div className="d-flex justify-content-evenly">
            <div className="text-center">
              <p className="m-0 ui-m fw-bold mb-1">{fNumber(plandata?.sharesRestricted, "amount")}</p>

              <p className="m-0 ui-xxs">{t("shares")}</p>
            </div>
            <div className="text-center">
              <p className="m-0 ui-m fw-bold mb-1">{fNumber(plandata?.sharesRestrictedValue, "value")}</p>

              <p className="m-0 ui-xxs">{t("value")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlansOverview;
