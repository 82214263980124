import { FC } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import { ChecksGroup, H, P, Ui } from "common/components/atoms";
import InputFeedback from "common/components/atoms/input-blocks/input-feedback/input-feedback";
import { InstrumentTypesNamesEnum } from "common/enums/enum";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import classes from "./step.module.scss";
import { ThreeStepForm } from "./useStepForm";

type InfoProps = {
  title: string;
  value?: string;
};

export type Stakeholder = {
  firstName: string;
  lastName: string;
  usingHoldingCompany: boolean;
  holdingCompanyName: string;
  organizationNumber: string;
  email: string;
  relationshipWithCompany: string;
  address?: string;
  country?: string;
  dateOfBirth?: string;
  phoneNumber?: string;
  businessPostAddress?: string;
};

const Info: FC<InfoProps> = ({ title, value = "-" }) => (
  <div className={cn(classes["info"])}>
    <Ui.s bold className="mb-1" style={{ color: scssVariables.foregroundLow }}>
      {title}
    </Ui.s>
    <Ui.m>{value}</Ui.m>
  </div>
);

type PropsTypes = {
  managerName?: string;
  className?: string;
  type?: InstrumentTypesNamesEnum;
  stakeholder?: Stakeholder;
  title?: string;
  description?: string;
  hideTitle?: boolean;
};

const t = createTranslation(TranslationNS.common, "receive.stakeholder");

const StakeholderInformationStep: FC<PropsTypes> = ({
  stakeholder,
  className,
  title,
  description,
  hideTitle = false,
}) => {
  const { values, errors, handleBlur, handleChange, touched } = useFormikContext<ThreeStepForm>();

  return (
    <>
      {!hideTitle && <H.xxs className="mb-3">{t("followSteps")}</H.xxs>}
      <div
        className={cn("d-flex py-3 px-4", classes["borders"], className, {
          "is-invalid": errors.stakeholderInfoCorrect && touched.stakeholderInfoCorrect,
        })}
      >
        <Ui.l className="pt-half me-3">1.</Ui.l>
        <div>
          <H.xxs className="mb-1">{title ? title : t("steps.reviewStakeholder.title")}</H.xxs>
          <P.s className="mb-4">{description}</P.s>

          {!stakeholder?.holdingCompanyName && (
            <div className="d-flex flex-wrap gap-3 mb-3">
              <Info title={t("firstName")} value={stakeholder?.firstName} />
              <Info title={t("lastName")} value={stakeholder?.lastName} />
              <Info title={t("email")} value={stakeholder?.email} />
              <Info
                title={t("dateOfBirth")}
                value={stakeholder?.dateOfBirth ? transformDateToCommonDateFormat(stakeholder.dateOfBirth) : "-"}
              />
              <Info title={t("phoneNumber")} value={stakeholder?.phoneNumber || "-"} />
              <Info title={t("relationship")} value={stakeholder?.relationshipWithCompany} />
              <Info title={t("country")} value={stakeholder?.country || "-"} />
              <Info title={t("address")} value={stakeholder?.address || "-"} />
            </div>
          )}
          {stakeholder?.holdingCompanyName && (
            <>
              <div className="d-flex flex-wrap gap-3 mb-3">
                <Info title={t("companyName")} value={stakeholder?.holdingCompanyName} />
                <Info title={t("businessEmail")} value={stakeholder?.email} />
                <Info title={t("organizationNumber")} value={stakeholder?.organizationNumber} />

                <Info title={t("relationship")} value={stakeholder?.relationshipWithCompany} />
                <Info title={t("businessAddress")} value={stakeholder?.address || "-"} />
                <Info title={t("postAddress")} value={stakeholder?.businessPostAddress || "-"} />

                <Info title={t("country")} value={stakeholder?.country || "-"} />
              </div>
              <Ui.m className="mb-3" bold style={{ color: scssVariables.foregroundLow }}>
                Shareholder representative
              </Ui.m>
              <div className="d-flex flex-wrap gap-3 mb-3">
                <Info title={t("firstName")} value={stakeholder?.firstName} />
                <Info title={t("lastName")} value={stakeholder?.lastName} />
                <Info title={t("phoneNumber")} value={stakeholder?.phoneNumber || "-"} />
              </div>
            </>
          )}
          <ChecksGroup>
            <ChecksGroup.Check
              className={cn({
                [classes.isGreen]: values.stakeholderInfoCorrect,
              })}
              checked={values.stakeholderInfoCorrect}
              onChange={handleChange}
              name="stakeholderInfoCorrect"
              onBlur={handleBlur}
              label={t("checkbox.title")}
            />
            <InputFeedback isTouched={touched.stakeholderInfoCorrect} error={errors.stakeholderInfoCorrect} />
          </ChecksGroup>
        </div>
      </div>
    </>
  );
};

export default StakeholderInformationStep;
