import axios from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { remoteDocumentType } from "common/types/documents";

import { filterCategoriesFactory, filterProgramDocuments } from "./documents-filters";

export enum DocumentType {
  agreement = "agreement",
  program = "program",
  governing = "governing",
  pool = "pool",
  transactions = "transactions",
  financialIntrument = "financialIntrument",
  standAloneDocuments = "standAloneDocuments",
}

export type SingleFinancialInstrumentDocumentType = {
  planId: number;
  stakeholderName: string;
  stakeholderCompanyName: string;
  programName: string;
  planStatus: number;
  hasUploadedDocument: boolean;
} & remoteDocumentType;

export type SingleCommonPlanDocumentType = remoteDocumentType & Record<"title", string | null>;

export type SinglePoolDocumentType = {
  poolId: number;
  poolName: string | null;
  documents: remoteDocumentType[];
};

export type SingleCommonDocumentType = {
  companyFileId: number;
  createdAt: string;
  documentTypeId: string;
  downloadId: string;
  fileName: string;
  fileId?: number;
};

export type SingleProgramDocumentType = {
  agreements: DocumentsCategory[];
  documentType: number;
  entityId: number;
  name: string;
  missingDocuments: boolean;
  programDocuments: SingleCommonDocumentType[];
};

export type GoverningDocumentsType = {
  documentCategories: DocumentsCategory[];
};

export type DocumentsCategory = {
  documentType: number;
  entityId: number;
  name: string;
  missingDocuments: boolean;
  documents: SingleCommonDocumentType[];
};

export type FinancialInstrumentsDocuments = {
  instruments: DocumentsCategory[];
};

export type TransactionInstrumentsDocumentsType = {
  transactions: DocumentsCategory[];
};

export type ProgramDocumentsType = {
  programs: SingleProgramDocumentType[];
};

export type PoolDocumentsType = {
  pools: DocumentsCategory[];
};

export enum editPanelTypes {
  generalGoverning = "generalGoverning",
  generalAssembly = "generalAssembly",
  boardProtocols = "boardProtocols",
}

export enum DocumentFilters {
  showAll = "showAll",
  missing = "missing",
}

interface DocumentsContextModel {
  // search
  searchValue: string;
  setSearchValue: Action<this, this["searchValue"]>;

  // filter
  filterValue: DocumentFilters;
  setFilterValue: Action<this, this["filterValue"]>;

  // loading state
  isLoading: boolean;
  setIsLoading: Action<this, boolean>;

  // common remove document ID - the reason is because we're using 1 API request to remove any document
  removalDocumentId: number | null;
  setRemovalDocumentId: Action<this, this["removalDocumentId"]>;

  isRemovalModalGoverning: boolean;
  setIsRemovalModalGoverning: Action<this, this["isRemovalModalGoverning"]>;

  //common response data
  documents: {
    hasPools: boolean;
    hasPrograms: boolean;

    // todo  update real data
    financialInstrumentDocuments: FinancialInstrumentsDocuments | null;

    governingDocuments: GoverningDocumentsType | null;

    transactionDocuments: TransactionInstrumentsDocumentsType | null;
    programDocuments: ProgramDocumentsType | null;

    poolDocuments: PoolDocumentsType | null;

    standaloneAgreementDocuments: SingleProgramDocumentType | null;
  } | null;
  setDocuments: Action<this, this["documents"]>;

  getCompanyDocumentsThunk: Thunk<this, number>;

  // filtered data
  filteredDocuments: {
    governingDocuments: DocumentsCategory[];
    financialInstrumentsDocuments: DocumentsCategory[];
    transactionDocuments: DocumentsCategory[];
    poolDocuments: DocumentsCategory[];
    programDocuments: SingleProgramDocumentType[];
    standaloneDocuments: DocumentsCategory[];
  };
  setFilteredDocuments: Action<this, this["documents"]>;

  // financial instruments
  selectedSingleFinancialInstrument: FinancialInstrumentsDocuments["instruments"][0] | null;
  setSelectedSingleFinancialInstrument: Action<this, this["selectedSingleFinancialInstrument"]>;

  // programs
  selectedSingleProgram: SingleProgramDocumentType | null;
  setSelectedSingleProgram: Action<this, this["selectedSingleProgram"]>;

  // agreements
  selectedSingleAgreement: SingleProgramDocumentType[][0]["agreements"][0] | null;
  setSelectedSingleAgreement: Action<this, this["selectedSingleAgreement"]>;

  isEditPanelOpen: {
    isOpen: boolean;
    documentType?: keyof typeof DocumentType;
    preSelected?: number;
    editPanel?: keyof typeof editPanelTypes;
  };
  setIsEditPanelOpen: Action<this, this["isEditPanelOpen"]>;

  selectedDocument: File | null;
  setSelectedDocument: Action<this, this["selectedDocument"]>;

  selectedSinglePool: PoolDocumentsType["pools"][0] | null;
  setSelectedSinglePool: Action<this, this["selectedSinglePool"]>;

  selectedSingleTransaction: TransactionInstrumentsDocumentsType["transactions"][0] | null;
  setSelectedSingleTransaction: Action<this, this["selectedSingleTransaction"]>;

  selectedSingleStandaloneDocument: SingleProgramDocumentType["agreements"][0] | null;
  setSelectedSingleStandaloneDocument: Action<this, this["selectedSingleStandaloneDocument"]>;
}

const DocumentsContext = createContextStore<DocumentsContextModel>({
  // search
  searchValue: "",
  setSearchValue: action((state, payload) => {
    state.searchValue = payload;
  }),

  // filter
  filterValue: DocumentFilters.showAll,
  setFilterValue: action((state, payload) => {
    state.filterValue = payload;
  }),

  // removal documents
  removalDocumentId: null,
  setRemovalDocumentId: action((state, payload) => {
    state.removalDocumentId = payload;
  }),

  isRemovalModalGoverning: false,
  setIsRemovalModalGoverning: action((state, payload) => {
    state.isRemovalModalGoverning = payload;
  }),

  // loading state
  isLoading: false,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),

  //common response data
  documents: null,
  setDocuments: action((state, payload) => {
    state.documents = payload;
  }),
  getCompanyDocumentsThunk: thunk(async (actions, id) => {
    try {
      actions.setIsLoading(true);

      const request = await axios.get<DocumentsContextModel["documents"]>(`/api/document/company/${id}`);

      if (request.status === 200) {
        actions.setDocuments(request.data);
        actions.setFilteredDocuments(request.data);
      }
    } finally {
      actions.setIsLoading(false);
    }
  }),

  // filtered data

  filteredDocuments: {
    governingDocuments: [],
    financialInstrumentsDocuments: [],
    transactionDocuments: [],
    poolDocuments: [],
    programDocuments: [],
    standaloneDocuments: [],
  },
  setFilteredDocuments: action((state, payload) => {
    const filter = filterCategoriesFactory(state.searchValue, state.filterValue);

    state.filteredDocuments = {
      governingDocuments: filter(payload?.governingDocuments?.documentCategories || []),
      financialInstrumentsDocuments: filter(payload?.financialInstrumentDocuments?.instruments || []),
      transactionDocuments: filter(payload?.transactionDocuments?.transactions || []),
      poolDocuments: filter(payload?.poolDocuments?.pools || []),
      programDocuments: filterProgramDocuments(
        payload?.programDocuments?.programs || [],
        state.searchValue,
        state.filterValue
      ),
      standaloneDocuments: filter(payload?.standaloneAgreementDocuments?.agreements || []),
    };

    // set counters
  }),

  selectedSingleAgreement: null,
  setSelectedSingleAgreement: action((state, payload) => {
    state.selectedSingleAgreement = payload;
  }),

  // transactions documents
  selectedSingleTransaction: null,
  setSelectedSingleTransaction: action((state, payload) => {
    state.selectedSingleTransaction = payload;
  }),

  // standalone docs
  selectedSingleStandaloneDocument: null,
  setSelectedSingleStandaloneDocument: action((state, payload) => {
    state.selectedSingleStandaloneDocument = payload;
  }),

  // side panel state
  isEditPanelOpen: {
    isOpen: false,
  },
  setIsEditPanelOpen: action((state, payload) => {
    state.isEditPanelOpen = payload;
  }),
  selectedDocument: null,
  setSelectedDocument: action((state, payload) => {
    state.selectedDocument = payload;
  }),

  selectedSingleProgram: null,
  setSelectedSingleProgram: action((state, payload) => {
    state.selectedSingleProgram = payload;
  }),

  selectedSinglePool: null,
  setSelectedSinglePool: action((state, payload) => {
    state.selectedSinglePool = payload;
  }),

  selectedSingleFinancialInstrument: null,
  setSelectedSingleFinancialInstrument: action((state, payload) => {
    state.selectedSingleFinancialInstrument = payload;
  }),
});

export default DocumentsContext;
