import { ComponentProps, createElement, ReactNode } from "react";
import { Trans } from "react-i18next";
import { t, TOptions as I18NextTOptions } from "i18next";

import { TranslationNS } from "./";

export type TOptions = I18NextTOptions;
export type TElOptions = ComponentProps<typeof Trans>;

export type TFunction = {
  (key: string, options?: TOptions): string;

  el(key: string, options?: TElOptions): ReactNode;
};

export function createTranslation(ns: TranslationNS | string, keyPrefix?: string): TFunction {
  keyPrefix = keyPrefix ? `${ns}:${keyPrefix}.` : `${ns}:`;

  const tFun: TFunction = (key, options) => t(`${keyPrefix}${key}`, options);

  tFun.el = (key, options = {}) => createElement<TElOptions>(Trans, { i18nKey: `${keyPrefix}${key}`, ...options });

  return tFun;
}
