import { ChangeEvent, FC, memo, useCallback, useMemo, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import cn from "classnames";
import { Form, useFormikContext } from "formik";

import { Button, Check, ChecksGroup, H, P, Ui } from "common/components/atoms";
import Helper from "common/components/atoms/Helper/Helper";
import SearchField from "common/components/atoms/SearchField/SearchField";
import { getOS } from "common/helpers";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { CheckIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../invite-stakeholder-modal/styles.module.scss";
import { FormValues } from "./invite-stakeholders-modal";
import StakeholderRow from "./stakeholder-row";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.multipleInvitationModal");

const InviteForm: FC = () => {
  const { closeMultipleInviteModal } = useStoreActions((actions) => actions.stakeholderInvitation);
  const { inviteModalType } = useStoreState((state) => state.stakeholderInvitation);

  const [searchValue, setSearchValue] = useState("");

  const { values, errors, touched, setFieldValue, isSubmitting, handleSubmit } = useFormikContext<FormValues>();

  const handleClose = useCallback(() => {
    closeMultipleInviteModal();
  }, [closeMultipleInviteModal]);

  const handleSelectAll = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      values.forEach((stakeholder, index) => {
        if (stakeholder.email) {
          setFieldValue(index.toString(), { ...stakeholder, isSelected: e.target.checked });
        }
      });
    },
    [setFieldValue, values]
  );

  const stakeholdersCounters = useMemo(() => {
    return values.reduce(
      (counters, stakeholder) => {
        if (stakeholder.isSelected) {
          counters.selected++;
        }

        if (stakeholder.email) {
          counters.withEmail++;
        }

        return counters;
      },
      {
        selected: 0,
        withEmail: 0,
      }
    );
  }, [values]);

  // We need to save length and indexes of elements to work correctly with formik setFieldValue by index
  // this is why we using map instead of filter method here
  const filteredStakeholders = useMemo(() => {
    return values.map((stakeholder) => {
      const lowerCasedSearchValue = searchValue.toLowerCase();

      if (
        stakeholder.name.toLocaleLowerCase().includes(lowerCasedSearchValue) ||
        stakeholder.email?.toLocaleLowerCase().includes(lowerCasedSearchValue) ||
        stakeholder.companyName?.toLocaleLowerCase().includes(lowerCasedSearchValue)
      ) {
        return stakeholder;
      }
      return null;
    });
  }, [values, searchValue]);

  const stakeholdersNotSelected = useMemo(() => {
    return !values.filter((stakeholder) => stakeholder.isSelected).length;
  }, [values]);

  return (
    <Form>
      <Modal show onHide={closeMultipleInviteModal} size="lg" contentClassName="pb-6 px-7" scrollable>
        <Helper>
          <Modal.Body className={cn({ [classes.customScroll]: getOS() === "Win" })}>
            <H.l className="mt-5 mb-3">{t(`header_${inviteModalType}`)}</H.l>
            <P.m className="mb-4">{t("description")}</P.m>

            <div className={cn("rounded-3", classes.elementBackground)}>
              <div className={cn("d-flex justify-content-between py-2 px-3", classes.borderBottom)}>
                <ChecksGroup className="w-50">
                  <Check
                    label={<Ui.s>{t("selectAllCheck")}</Ui.s>}
                    checked={stakeholdersCounters.selected === stakeholdersCounters.withEmail}
                    onChange={handleSelectAll}
                  />
                </ChecksGroup>
                <SearchField
                  className={classes.removeMinWidth}
                  placeholder={t("searchPlaceholder")}
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <div className="py-2 px-3">
                <Row className="mb-2 p-2 ps-4">
                  <Col>
                    <Ui.s color="foregroundMedium">{t("name")}</Ui.s>
                  </Col>
                  <Col>
                    <Ui.s color="foregroundMedium">{t("email")}</Ui.s>
                  </Col>
                  <Col>
                    <Ui.s color="foregroundMedium">{t("accessRole")}</Ui.s>
                  </Col>
                </Row>

                {filteredStakeholders.map((stakeholder, index) =>
                  stakeholder ? (
                    <StakeholderRow
                      key={stakeholder.stakeholderId}
                      index={index}
                      stakeholder={stakeholder}
                      error={errors?.[index]?.email}
                      isTouched={touched[index]?.email}
                      setFieldValue={setFieldValue}
                    />
                  ) : null
                )}
              </div>
            </div>
          </Modal.Body>
        </Helper>
        <Modal.Footer className="justify-content-between pt-2 border-0">
          <div className="d-flex align-items-center">
            <CheckIcon fontSize={24} className="me-2" />
            <Ui.m>{stakeholdersCounters.selected + " " + t("stakeholdersSelected")}</Ui.m>
          </div>
          <div>
            <Button onClick={handleClose} isDisabled={isSubmitting} variant="secondary" className="me-2">
              {t("cancelBtn")}
            </Button>
            <Button isLoading={isSubmitting} isDisabled={stakeholdersNotSelected} onClick={() => handleSubmit()}>
              {t("submitBtn")}
            </Button>
          </div>
        </Modal.Footer>
        <ToastFormikValidator />
      </Modal>
    </Form>
  );
};

export default memo(InviteForm);
