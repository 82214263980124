import { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import * as R from "ramda";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import { H, Ui } from "common/components/atoms/Typography";
import { LinkIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { openRemoteDocument, prepareAcceptDocuments } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ReadAndAcceptDocuments.module.scss";

const t = createTranslation(TranslationNS.common, "commonComponentsForDifferentModules.readAndAcceptDocuments");

export type ReadAndAcceptDocumentType = {
  id?: number;
  documentTypeId: number;
  downloadId: string;
  fileName: string;
  label?: string;
  isSelected?: boolean;
};

type ReadAcceptDocumentsProps = {
  documents: ReadAndAcceptDocumentType[];
  documentsCallback?: (documents: ReadAndAcceptDocumentType[]) => void;
  currentStep?: number;
  setIsReadDocuments: Dispatch<SetStateAction<boolean>>;
  actionsStart?: () => void;
  actionsEnd?: () => void;
  title?: string;
  subtitle?: string;
  noticeText?: string;
  isSingleSelection?: boolean;
};

const DOCUMENT_AGREEMENT_ID = 4;

const ReadAndAcceptDocuments: FC<ReadAcceptDocumentsProps> = ({
  documents,
  currentStep,
  actionsStart,
  actionsEnd,
  setIsReadDocuments,
  title,
  subtitle,
  noticeText,
  documentsCallback,
  isSingleSelection,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [modifiedDocuments, setModifiedDocuments] = useState(prepareAcceptDocuments(documents, DOCUMENT_AGREEMENT_ID));

  const isCheckboxesChecked = useMemo(() => {
    if (R.isEmpty(documents)) {
      return true;
    }

    if (R.isEmpty(modifiedDocuments.rest)) {
      return modifiedDocuments?.agreement?.isSelected;
    }

    if (isSingleSelection) {
      return modifiedDocuments?.agreement?.isSelected || !!modifiedDocuments?.rest?.find((el) => el?.isSelected);
    }
    return (
      (modifiedDocuments?.agreement ? modifiedDocuments?.agreement?.isSelected : true) &&
      modifiedDocuments?.rest?.every((el) => el?.isSelected)
    );
  }, [documents, modifiedDocuments.rest, modifiedDocuments?.agreement, isSingleSelection]);

  const remoteDocumentHandler = async (id?: string) => {
    try {
      if (actionsStart) {
        actionsStart();
      }

      await openRemoteDocument(id);
    } catch (error) {
      return { error };
    } finally {
      if (actionsEnd) {
        actionsEnd();
      }
    }
  };

  const renderHead = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div
          className={classNames(classes["head"], {
            [classes["opened"]]: Boolean(activeEventKey),
          })}
          onClick={(e) => {
            onClick(e);
            setIsOpen((prev) => !prev);
          }}
        >
          <div className={classes["title"]}>
            <span className={classNames(classes["point"], "ui-m")}>{currentStep ? `${currentStep}.` : "2."}</span>
            <H.xxxs>{title || t("title")}</H.xxxs>
          </div>
          <Ui.xs className={classNames(classes["subtitle"], "mt-1")}>{subtitle || t("subtitle")}</Ui.xs>
        </div>
      );
    },
    [currentStep, subtitle, title]
  );

  const renderAgreementCheckbox = useCallback(() => {
    const agreement = modifiedDocuments?.agreement;
    return agreement ? (
      <ChecksGroup.Check
        checked={agreement?.isSelected}
        className={classNames(classes["checkbox"], {
          [classes["checked"]]: isCheckboxesChecked,
        })}
        label={
          <div
            className={classNames(classes["checkbox-label"], {
              [classes["checked"]]: isCheckboxesChecked,
            })}
          >
            <a
              key={1}
              target="_blank"
              rel="noreferrer"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                remoteDocumentHandler(agreement.downloadId);
              }}
            >
              {agreement?.label || t.el("viewPlanAgreement")}
              &nbsp;
              <LinkIcon />
            </a>
          </div>
        }
        onChange={(e) => {
          setModifiedDocuments((prev) =>
            R.assoc("agreement", R.assoc("isSelected", e.target.checked, agreement) as ReadAndAcceptDocumentType, prev)
          );
        }}
      />
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckboxesChecked, modifiedDocuments?.agreement]);

  const renderRestDocuments = useCallback(() => {
    return !R.isNil(modifiedDocuments?.rest) && !R.isEmpty(modifiedDocuments?.rest)
      ? modifiedDocuments?.rest?.map((el, index, array) => {
          return (
            <ChecksGroup.Check
              key={el.downloadId}
              checked={array[index]?.isSelected}
              className={classNames(classes["checkbox"], {
                [classes["checked"]]: isCheckboxesChecked,
              })}
              label={
                <div
                  className={classNames(classes["checkbox-label"], {
                    [classes["checked"]]: isCheckboxesChecked,
                  })}
                >
                  <a
                    key={1}
                    target="_blank"
                    rel="noreferrer"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openRemoteDocument(el?.downloadId);
                    }}
                  >
                    {el.label || el.fileName}
                    <LinkIcon />
                  </a>
                  &nbsp;
                </div>
              }
              onChange={(e) => {
                setModifiedDocuments((prev) =>
                  R.assoc(
                    "rest",
                    R.update(
                      index,
                      R.assoc("isSelected", e.target.checked, el),
                      modifiedDocuments?.rest as ReadAndAcceptDocumentType[]
                    ),
                    prev
                  )
                );
              }}
            />
          );
        })
      : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifiedDocuments.rest, isCheckboxesChecked]);

  useEffect(() => {
    if (!R.isEmpty(documents)) {
      setIsReadDocuments(Boolean(isCheckboxesChecked));
    } else {
      setIsReadDocuments(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, isCheckboxesChecked]);

  useEffect(() => {
    if (documentsCallback) {
      const documents = modifiedDocuments.rest ? [...modifiedDocuments.rest] : [];
      if (modifiedDocuments.agreement) {
        documents.push(modifiedDocuments.agreement);
      }
      documentsCallback?.(documents);
    }
  }, [documents, documentsCallback, modifiedDocuments]);

  return (
    <CollapsibleContainer
      defaultOpen
      withoutDivider
      className={classNames(classes["container"], {
        [classes["opened"]]: isOpen,
        [classes["checked"]]: isCheckboxesChecked && isOpen,
      })}
      Header={renderHead}
    >
      <>
        <ChecksGroup className="my-3">
          {renderAgreementCheckbox() as JSX.Element}

          {renderRestDocuments() as unknown as JSX.Element}
        </ChecksGroup>

        <Ui.s className={classes["hint"]}>{noticeText || t("checkAllDocuments")}</Ui.s>
      </>
    </CollapsibleContainer>
  );
};

export default ReadAndAcceptDocuments;
