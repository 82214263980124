import * as R from "ramda";
import cn from "classnames";
import { useCallback, FC } from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router";

import Button from "common/components/atoms/Button/Button";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { TeamMember } from "store/types";
import { TranslationNS, createTranslation } from "translation";

import SingleTeamItem from "../SingleTeamItem";
import classes from "./TeamInfoCard.module.scss";

const t = createTranslation(TranslationNS.pages, "public.companyDetails");

type TeamInfoCardProps = {
  team: TeamMember[];
  contactPerson: TeamMember | null;
  onUnAuthClick: () => void;
};

const TeamInfoCard: FC<TeamInfoCardProps> = ({ team, contactPerson, onUnAuthClick }) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useStoreState((state) => state.authentication);

  const onContactPressHandler = () => {
    if (isAuthenticated) {
      const subject = "Hello";

      const mail = document.createElement("a");
      mail.href = `mailto:${contactPerson?.email}?subject=${subject}`;
      mail.click();

      return;
    }

    onUnAuthClick();
    navigate("/");
  };

  const renderTeamMembers = useCallback(() => {
    return team?.map((el) => (
      <SingleTeamItem
        textRight
        imgSize={64}
        emptyIconColor="#f0f2f4"
        style={{ marginBottom: 32 }}
        linkedInUrl={el?.linkedInUrl}
        positionTitle={el?.positionTitle}
        key={`${el?.firstName} ${el?.lastName}`}
        name={el?.firstName}
        lastName={el?.lastName}
        img={
          el?.profileImageFilePath ? `${process.env.REACT_APP_BLOB_PUBLIC_URL}${el.profileImageFilePath}` : undefined
        }
      />
    ));
  }, [team]);

  return (
    <div className={cn(classes["team-container"], "mb-3")} data-testid="company-team-info-card-test-id">
      <div className={classes["person"]}>
        <p className="m-0 head-xxxxs text-center mb-3">{t("meetTeam")}</p>

        {!R.isNil(contactPerson) ? (
          <>
            <SingleTeamItem
              imgSize={96}
              linkedInUrl={contactPerson?.linkedInUrl}
              emptyIconColor={scssVariables.information050}
              img={
                contactPerson?.profileImageFilePath
                  ? `${process.env.REACT_APP_BLOB_PUBLIC_URL}${contactPerson?.profileImageFilePath}`
                  : undefined
              }
              name={contactPerson?.firstName}
              lastName={contactPerson?.lastName}
              positionTitle={contactPerson?.positionTitle}
            />

            <Button
              className={cn(classes["person-btn"], "mt-2 mb-6")}
              data-testid="contact-user-btn-test-id"
              isDisabled={isAuthenticated && !contactPerson?.email}
              onClick={onContactPressHandler}
            >
              {t("contactUser", { user: contactPerson?.firstName })}
            </Button>
          </>
        ) : null}
      </div>

      <Accordion className={classes["accordion"]}>
        <Accordion.Item eventKey="0">
          <div className={classes["accordion-btn"]}>
            <Accordion.Header as="div">
              <p className="m-0">{t("viewWholeTeam")}</p>
            </Accordion.Header>
          </div>

          <Accordion.Body>
            <div className={classes["team"]}>{renderTeamMembers()}</div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className={cn(classes["team"], classes["team-descktop"])}>{renderTeamMembers()}</div>
    </div>
  );
};

export default TeamInfoCard;
