import { FC, useCallback } from "react";
import { useFormikContext } from "formik";

import DatePicker, { DatePickerProps } from "common/components/atoms/DatePicker/DatePicker";
import { createTranslation, TranslationNS } from "translation";

import { fields as f, StakeholderFormValues } from "../Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder.fields");

type StakeholderFirstNameFieldProps = Omit<DatePickerProps, "onChange"> & {};
const StakeholderDateOfBirthField: FC<StakeholderFirstNameFieldProps> = (props) => {
  const { values, errors, touched, handleBlur, setFieldValue } = useFormikContext<StakeholderFormValues>();

  const handleChangeBirthDate = useCallback(
    (date: string | Date) => {
      setFieldValue(f.dateOfBirth, date).then();
    },
    [setFieldValue]
  );

  const prefix = values.isCompanyOwned ? "isCompany." : "";

  return (
    <DatePicker
      {...props}
      isClearable
      isDateOnlyString
      label={t(`${prefix}dateOfBirth`)}
      date={values.dateOfBirth}
      name={f.dateOfBirth}
      error={errors.dateOfBirth}
      isTouched={touched.dateOfBirth}
      onChange={handleChangeBirthDate}
      onBlur={handleBlur}
    />
  );
};

export default StakeholderDateOfBirthField;
