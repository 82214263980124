import { Action, action, createContextStore } from "easy-peasy";

type PlanFormDataType = {
  planId?: number | null;
  type: "existing" | "other";
  stakeholderName?: string;
};

type DeletePlanInfoType = {
  planId: number;
  stakeholderName?: string;
};

type UpdatePlanEmailType = {
  planId: number;
  name: string;
};

export interface CreatePlanModel {
  // selectors
  isSidebarOpen: boolean;
  planForm: PlanFormDataType | null;
  deletePlanInfo: DeletePlanInfoType | null;
  updatePlanEmail: UpdatePlanEmailType | null;
  // actions
  setIsSidebarOpen: Action<this, boolean>;
  setCreateOrEditPlan: Action<this, PlanFormDataType>;
  setDeletePlanInfo: Action<this, DeletePlanInfoType | null>;
  setUpdatePlanEmail: Action<this, UpdatePlanEmailType | null>;
  //thunks
}

export const createPlanModel: CreatePlanModel = {
  // selectors
  isSidebarOpen: false,
  planForm: null,
  deletePlanInfo: null,
  updatePlanEmail: null,
  // actions
  setIsSidebarOpen: action((state, payload) => {
    state.isSidebarOpen = payload;
  }),
  setCreateOrEditPlan: action((state, payload) => {
    state.planForm = payload;
    state.isSidebarOpen = true;
  }),
  setDeletePlanInfo: action((state, payload) => {
    state.deletePlanInfo = payload;
  }),
  setUpdatePlanEmail: action((state, payload) => {
    state.updatePlanEmail = payload;
  }),
};

const CreatePlanStore = createContextStore<CreatePlanModel>(createPlanModel, {
  name: "Create plan wizard",
});

export default CreatePlanStore;
