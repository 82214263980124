import { FC, useCallback } from "react";
import cn from "classnames";
import { format, parseISO } from "date-fns";
import { defaultTo } from "ramda";

import { Collapsible, Label, P, Ui } from "common/components/atoms/index";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ChevronRightSmallIcon, ProcessIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { IOngoingExercises } from "pages/my-porfolio/exercising/ExercisingContext";
import { createTranslation, TranslationNS } from "translation";

import { CollapsibleHeaderProps } from "../../../types/Collapsible.types";
import { ExerciseRequestStatusEnum } from "../../../types/Exxercise.types";
import classes from "./styles.module.scss";

type PropsTypes = {
  ongoingExercises: IOngoingExercises[];
  setActiveExerciseRequest?: (item: IOngoingExercises) => void;
  currencyID?: number;
};

const t = createTranslation(TranslationNS.common, "atoms.exercisingComponents.ongoingExercises");

const OngoingExercises: FC<PropsTypes> = ({ currencyID, ongoingExercises, setActiveExerciseRequest }) => {
  const { currencySymbol } = useCurrencyById(currencyID);
  const fNumber = useFormatNumbers(currencySymbol);

  const renderHead = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div className={classes["head"]} onClick={onClick}>
          <div className="d-flex align-items-center">
            <Ui.xl bold>{t("title")}</Ui.xl>

            <div className={classes["count"]}>{defaultTo(0, ongoingExercises?.length)}</div>
          </div>

          <ChevronRightSmallIcon
            className={cn(classes["chevron"], {
              [classes["open"]]: Boolean(activeEventKey),
            })}
          />
        </div>
      );
    },
    [ongoingExercises?.length]
  );

  const handleClick = useCallback(
    (request: IOngoingExercises) => () => {
      setActiveExerciseRequest?.(request);
    },
    [setActiveExerciseRequest]
  );

  const renderStatusLabel = useCallback((status: ExerciseRequestStatusEnum) => {
    const items = {
      [ExerciseRequestStatusEnum.OrderPlaced]: <Label>{t("waitingApproval")}</Label>,
      [ExerciseRequestStatusEnum.AwaitingApproval]: <Label type="warning">{t("paymentRequired")}</Label>,
      [ExerciseRequestStatusEnum.PendingPayment]: <Label type="success">{t("paid")}</Label>,
      [ExerciseRequestStatusEnum.SharesIssued]: <Label type="success">{t("sharesIssued")}</Label>,
      [ExerciseRequestStatusEnum.Declined]: <Label type="danger">{t("sharesIssued")}</Label>,
    };
    return items[status] || null;
  }, []);

  return (
    <Collapsible defaultOpen withoutDivider={false} Header={renderHead} className={classes["collapsible"]}>
      <div className={classes["content"]}>
        {!ongoingExercises.length ? (
          <div className="p-4">
            <Ui.xs>{t("noExercises")}</Ui.xs>
          </div>
        ) : (
          ongoingExercises.map((item) => (
            <div key={item.exerciseRequestId} className={classes["request"]} onClick={handleClick(item)}>
              <ProcessIcon fontSize={24} color={scssVariables.foregroundMedium} />

              <P.m className="ms-3 me-2 fw-500" style={{ fontSize: 16 }}>
                {t("sentRequestInfo", {
                  options: item.numberOfOptions,
                  value: fNumber(item.payment?.amount || item?.amount, "value", {
                    customCurrencySymbol: item?.currencySymbol ? item.currencySymbol : currencySymbol,
                  }),
                  date: `${format(parseISO(item.requestedAt), "EEEE")} ${transformDateToCommonDateFormat(
                    parseISO(item.requestedAt)
                  )}`,
                })}
              </P.m>

              <div className="ms-auto">{renderStatusLabel(item.exerciseRequestStatus)}</div>
            </div>
          ))
        )}
      </div>
    </Collapsible>
  );
};

export default OngoingExercises;
