import { DocumentationDetailsFields } from "../form-sections/documentation-details/form-fields";
import { splitFields } from "../form-sections/split-details/form-fields";
import { TransactionDetailsFields } from "../form-sections/transaction-details/form-fields";

export const SplitFields = {
  companyId: "companyId",
  ...splitFields,
  ...DocumentationDetailsFields,
  description: TransactionDetailsFields.description,
  transactedAt: TransactionDetailsFields.transactedAt,
} as const;

export const EditSplitFields = {
  transactionId: "transactionId",
  companyId: "companyId",
  ...splitFields,
  ...DocumentationDetailsFields,
  description: TransactionDetailsFields.description,
  transactedAt: TransactionDetailsFields.transactedAt,
} as const;
