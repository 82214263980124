import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { isEmpty } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Spinner, Ui } from "common/components/atoms";
import SearchField from "common/components/atoms/SearchField/SearchField";
import Tag from "common/components/atoms/Tag/Tag";
import useDebounce from "common/hooks/useDebounce";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { ChevronDownIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import DocumentsCounter from "./components/DocumentsCounter/DocumentsCounter";
import EditDocument from "./components/EditDocument/EditDocument";
import FinancialInstrumentsDocuments from "./components/FinancialInstrumentsDocuments/FinancialInstrumentsDocuments";
import GoverningDocuments from "./components/GoverningDocuments/GoverningDocuments";
import PoolDocuments from "./components/PoolDocuments/PoolDocuments";
import ProgramDocuments from "./components/ProgramDocuments/ProgramDocuments";
import RemoveDocument from "./components/RemoveDocument/RemoveDocument";
import StandaloneDocuments from "./components/StandaloneDocuments/StandaloneDocuments";
import TransactionDocuments from "./components/TransactionDocuments/TransactionDocuments";
import classes from "./documents.module.scss";
import DocumentsContext, { DocumentFilters } from "./DocumentsContext";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.documents"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const Content: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId } = useParams<{ companyId: string }>();
  const { isLoading, filterValue, searchValue, documents } = DocumentsContext.useStoreState((state) => state);

  const { getCompanyDocumentsThunk, setSearchValue, setFilterValue, setFilteredDocuments } =
    DocumentsContext.useStoreActions((actions) => actions);

  const { hasFullAccess } = useFeatures(FEATURES.documents);

  // internal NOT debounced search value
  const [search, setSearch] = useState<string>("");

  // debounced value, that will update context value
  const debouncedValue = useDebounce(search, 1000);

  const changeSearchValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    [setSearch]
  );

  const filterBtnVariants: ContextMenuProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: t("filter.showAll"),
        onClick: () => setFilterValue(DocumentFilters.showAll),
        selected: filterValue === DocumentFilters.showAll,
        forceHideDropdown: true,
      },
      {
        key: "2",
        label: t("filter.missing"),
        onClick: () => setFilterValue(DocumentFilters.missing),
        selected: filterValue === DocumentFilters.missing,
        forceHideDropdown: true,
      },
    ],
    [filterValue, setFilterValue]
  );

  useEffect(() => {
    getCompanyDocumentsThunk(Number(companyId));
  }, [companyId, getCompanyDocumentsThunk]);

  useEffect(() => {
    setSearchValue(debouncedValue);
  }, [debouncedValue, setSearchValue]);

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [searchValue, filterValue, setFilteredDocuments, documents]);

  return (
    <PageContent data-testid="company-documents-page">
      <PageContent.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>

          <div className="d-flex align-items-center">
            <ContextMenu className="me-3" items={filterBtnVariants} chevronAnimation drop="down-centered">
              <Button
                size="s"
                variant="tertiary"
                className={classes["context-button"]}
                iconRight={<ChevronDownIcon className="chevron ms-half" />}
              >
                {t(`filter.${filterValue}`)}
              </Button>
            </ContextMenu>

            <SearchField value={search} placeholder={t("search.placeholder")} onChange={changeSearchValue} />
          </div>
        </div>
      </PageContent.Header>

      {!isEmpty(debouncedValue) ? (
        <Ui.m className="mb-4">
          {t.el("searchInfo", {
            components: [
              <span
                key={1}
                className={classes.link}
                onClick={() => {
                  setSearch("");
                  setFilterValue(DocumentFilters.showAll);
                }}
              />,
            ],
          })}
        </Ui.m>
      ) : null}

      <DocumentsCounter />

      <GoverningDocuments />

      <FinancialInstrumentsDocuments />

      <TransactionDocuments />

      <PoolDocuments />

      <ProgramDocuments />

      <EditDocument />

      <StandaloneDocuments />

      <RemoveDocument />

      {isLoading ? <Spinner /> : null}
    </PageContent>
  );
};

const CompanyDocumentsPage = () => {
  return (
    <DocumentsContext.Provider>
      <Content />
    </DocumentsContext.Provider>
  );
};

export default CompanyDocumentsPage;
