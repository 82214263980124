import { FC, useEffect } from "react";
import classNames from "classnames";

import { useWizard } from "../../hooks";
import classes from "./Content.module.scss";
import ControlButtons from "./ControlButtons/ControlButtons";

export type ContentProps = JSX.IntrinsicElements["div"] & {
  step?: number;
};

type ContentComponent = FC<ContentProps> & {
  ControlButtons: typeof ControlButtons;
};

const Content: ContentComponent = ({ className, children, step, ...props }) => {
  const { setWizardStep } = useWizard();

  useEffect(() => {
    if (step) {
      setWizardStep(step);
    }
  }, [setWizardStep, step]);
  return (
    <div className={classNames(classes["content"], className)} {...props}>
      {children}
    </div>
  );
};

Content.ControlButtons = ControlButtons;

export default Content;
