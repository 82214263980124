import { CSSProperties, FC } from "react";
import cn from "classnames";

import { Ui } from "../Typography";
import classes from "./NewAvatar.module.scss";

type PropsTypes = {
  initials?: string;
  imageUrl?: string;
  size?: "m" | "s" | "l";
  className?: string;
  style?: CSSProperties;
  company?: boolean;
  firstName?: string;
  lastName?: string;
  companyName?: string;
};

const NewAvatar: FC<PropsTypes> = ({
  initials,
  imageUrl,
  className,
  style,
  company,
  size = "l",
  firstName,
  lastName,
  companyName,
}) => {
  if (imageUrl)
    return (
      <img
        src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${imageUrl}`}
        alt={"Avatar"}
        className={cn(classes.rounded, className, {
          [classes.imgSmall]: size === "s",
          [classes.imgMedium]: size === "m",
          [classes.imgLarge]: size === "l",
        })}
        style={style}
      />
    );

  let newInitials = initials?.slice(0, 2);

  if (!newInitials) {
    newInitials = `${firstName?.charAt(0) || ""}${lastName?.charAt(0) || ""}`;
    if (companyName) {
      newInitials = companyName.slice(0, 2);
    }
  }

  if (newInitials)
    return (
      <>
        {size === "l" && (
          <Ui.xl
            className={cn(className, {
              [classes.initialsCompany]: company,
              [classes.initials]: !company,
            })}
            style={style}
          >
            {newInitials.toUpperCase()}
          </Ui.xl>
        )}

        {size === "m" && (
          <Ui.m
            className={cn(className, {
              [classes.initialsCompanyMedium]: company,
              [classes.initialsMedium]: !company,
            })}
            style={style}
          >
            {newInitials.toUpperCase()}
          </Ui.m>
        )}

        {size === "s" && (
          <Ui.xs
            className={cn(className, {
              [classes.initialsCompanySmall]: company,
              [classes.initialsSmall]: !company,
            })}
            style={style}
          >
            {newInitials.toUpperCase()}
          </Ui.xs>
        )}
      </>
    );

  return (
    <Ui.xs className={classes.initialsSmall} style={style}>
      {newInitials?.toUpperCase()}
    </Ui.xs>
  );
};

export default NewAvatar;
