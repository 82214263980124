import { FC, useCallback } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { useStoreActions } from "store/store";

import Invitations from "./Invitations";

const InvitationsRouter: FC = () => {
  const { setRedirectUrl } = useStoreActions((actions) => actions.app);
  const ClearRedirectUrl = useCallback(() => {
    setRedirectUrl("");

    return <Navigate to="/" />;
  }, [setRedirectUrl]);

  return (
    <Routes>
      <Route path={ROUTER_V2.invitation.invitationType} element={<Invitations />} />
      <Route path="*" element={<ClearRedirectUrl />} />
    </Routes>
  );
};

export default InvitationsRouter;
