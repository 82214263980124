import { defaultTo, isEmpty } from "ramda";

import { DocumentFilters, DocumentsCategory, SingleProgramDocumentType } from "./DocumentsContext";

export const isMatchWithSearch = (string: string, searchValue: string) =>
  string?.toLowerCase()?.includes(searchValue?.toLowerCase());

export const isMatchByFilter = (documentsCategory: DocumentsCategory, filterValue: DocumentFilters) => {
  if (filterValue === DocumentFilters.showAll) return true;

  if (filterValue === DocumentFilters.missing && documentsCategory.missingDocuments) return true;

  return false;
};

export const filterCategories = (
  categories: DocumentsCategory[],
  searchValue: string,
  filterValue: DocumentFilters
) => {
  // default, when search empty and filter is "show all"
  if (isEmpty(searchValue) && filterValue === DocumentFilters.showAll) {
    return categories.length ? categories : [];
  }

  const filteredGroup = categories.reduce((acc, category) => {
    const matchBySearch = isMatchWithSearch(category.name, searchValue);
    const matchByFilter = isMatchByFilter(category, filterValue);

    if (searchValue) {
      // search value match and filter - return
      if (matchBySearch && matchByFilter) {
        return acc.concat(category);
      }
    }

    // only filtering without search
    if (isEmpty(searchValue) && filterValue === DocumentFilters.missing) {
      if (matchByFilter) {
        return acc.concat(category);
      }

      return acc;
    }

    // if we can't find category with the search value in the name
    // we should check documents in the nested agreements for the names match
    const filteredAgreementDocuments = category.documents.filter((document) =>
      isMatchWithSearch(document.fileName, searchValue)
    );

    // we found some matches inside agreements - return those agreements
    if (filteredAgreementDocuments.length) {
      return acc.concat({ ...category, documents: filteredAgreementDocuments });
    }

    // skip category complete
    return acc;
  }, [] as DocumentsCategory[]);

  return filteredGroup.length ? filteredGroup : [];
};

export const filterProgramDocuments = (
  programs: SingleProgramDocumentType[],
  searchValue: string,
  filterValue: DocumentFilters
) => {
  // default
  if (isEmpty(searchValue) && filterValue === DocumentFilters.showAll) {
    return defaultTo([], programs);
  }

  const filteredPrograms = programs.reduce((acc, program) => {
    // if search match with program name and filter is "show all" -> show whole program
    if (isMatchWithSearch(program.name, searchValue) && filterValue === DocumentFilters.showAll) {
      return acc.concat(program);
    }

    // if search is empty and filter is "missing"
    if (filterValue === DocumentFilters.missing && isEmpty(searchValue)) {
      // check if program has missing documents
      if (program.missingDocuments) {
        // if yes - also filter nested agreements to display only agreements with missing documents
        const filteredAgreements = filterCategories(program.agreements, searchValue, filterValue);

        // return program with all documents and filtered agreements
        return acc.concat({ ...program, agreements: filteredAgreements });
      }

      // if program has not missing documents - filter agreements by missing documents
      const filteredAgreements = filterCategories(program.agreements, searchValue, filterValue);

      if (filteredAgreements.length) {
        // return program that contains agreements with missing documents and hide program documents
        return acc.concat({ ...program, programDocuments: [], agreements: filteredAgreements });
      }
    }

    // at this step we know that:
    // 1 - search value not empty
    // 2 - search value didn't match with program name

    // filter program documents to find by name
    const filteredProgramDocuments = program.programDocuments.filter((document) =>
      isMatchWithSearch(document.fileName, searchValue)
    );
    // filter agreement to find by name + by filter
    const filteredAgreements = filterCategories(program.agreements, searchValue, filterValue);

    // if we are using search only, without filtering
    if (filterValue === DocumentFilters.showAll) {
      // check if we have any matches by search in and program documents or agrements
      if (filteredAgreements.length || filteredProgramDocuments.length) {
        // return program with filtered programs and agreements
        return acc.concat({ ...program, programDocuments: filteredProgramDocuments, agreements: filteredAgreements });
      }
    }

    // at this step we know that:
    // 1 - filter value - missing documents
    // 2 - program has missing documents field - false
    // 3 - we filtered agreements by search + filter
    // 4 - even if program documents match by search value we should skip them because or filter value - missing only

    // if we found matches in agreements
    if (filteredAgreements.length) {
      return acc.concat({ ...program, programDocuments: [], agreements: filteredAgreements });
    }

    // now we sure that program, program documents and agreements doesn't match by anything
    // skip program
    return acc;
  }, [] as SingleProgramDocumentType[]);

  return filteredPrograms;
};

export const filterCategoriesFactory = (searchValue: string, filterValue: DocumentFilters) => {
  return (categories: DocumentsCategory[]) => filterCategories(categories, searchValue, filterValue);
};

export const countDocumentsInCategories = (categories: DocumentsCategory[]) => {
  return categories.reduce(
    (acc, category) => {
      if (category.missingDocuments) {
        acc.missing++;
      }

      acc.uploaded = acc.uploaded + category.documents.length;

      return acc;
    },
    { missing: 0, uploaded: 0 }
  );
};

export const countDocumentsInProgram = (programs: SingleProgramDocumentType[]) => {
  return programs.reduce(
    (acc, program) => {
      if (program.missingDocuments) {
        acc.missing++;
      }

      acc.uploaded = acc.uploaded + program.programDocuments.length;

      const countInAgreements = countDocumentsInCategories(program.agreements);

      return {
        missing: acc.missing + countInAgreements.missing,
        uploaded: acc.uploaded + countInAgreements.uploaded,
      };
    },
    { missing: 0, uploaded: 0 }
  );
};
