import { FC, SyntheticEvent, useCallback, useContext, useState } from "react";
import axios from "axios";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";
import { pick } from "ramda";

import { Button, Divider, H, Ui } from "common/components/atoms";
import { CloseIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import Company from "../Company/Company";
import { animationStakeholder } from "../fields/RenderStakeholderFields/RenderStakeholderFields";
import StakeholderCompanyField from "../fields/StakeholderIsCompanyField";
import Personal from "../Personal/Personal";
import { StakeholderFormValues, updatedStakeholderFields } from "../Stakeholder.types";
import { StakeholderSectionContext } from "../StakeholderSection";
import classes from "./SelectedStakeholder.module.scss";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder");

type UpdateStakeholderFormProps = {
  handleCancel: () => void;
  handleCloseAfterSafe: () => void;
  handleClear: () => void;
  stakeholderName?: string;
  show: boolean;
};

const UpdateStakeholderForm: FC<UpdateStakeholderFormProps> = ({
  handleCancel,
  handleCloseAfterSafe,
  handleClear,
  stakeholderName,
  show,
}) => {
  const { companyId } = useStoreState((state) => state.activeCompanyModel);

  const updatedFields = useContext(StakeholderSectionContext).updatedFields;
  const { values, validateForm, setFieldTouched } = useFormikContext<StakeholderFormValues>();

  const [isUpdating, setIsUpdating] = useState(false);

  const handleSwitchStakeholder = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      handleClear();
    },
    [handleClear]
  );

  const handleSave = useCallback(() => {
    validateForm(values).then((errors) => {
      let isErrors = false;
      updatedStakeholderFields.forEach((field) => {
        if (errors[field]) {
          setFieldTouched(field, true);
          isErrors = true;
        }
      });
      if (!isErrors) {
        const stakeholderValues = pick(updatedStakeholderFields, values);
        if (!stakeholderValues.countryId) {
          // @ts-ignore
          stakeholderValues.countryId = null;
        }

        if (!stakeholderValues.dateOfBirth) {
          // @ts-ignore
          stakeholderValues.dateOfBirth = null;
        }

        setIsUpdating(true);
        axios
          .post("/api/ownership/stakeholder", { ...stakeholderValues, companyId })
          .then(() => {
            notify(t("stakeholderUpdated"), true, "success", 3000);
            handleCloseAfterSafe();
          })
          .finally(() => {
            setIsUpdating(false);
          });
      }
    });
  }, [companyId, handleCloseAfterSafe, setFieldTouched, validateForm, values]);
  return (
    <motion.div initial="close" variants={animationStakeholder} animate={show ? "open" : "close"}>
      <div className="d-flex align-items-center justify-content-between mb-3">
        <H.xxs>{t("updateStakeholder")}</H.xxs>
        <Button size="s" variant="secondary" iconLeft={<CloseIcon />} onClick={handleCancel}>
          {t("cancel")}
        </Button>
      </div>
      <div className={classes.infoSection}>
        <Ui.s className="mb-1">You are currently updating the stakeholder details of {stakeholderName}.</Ui.s>
        <Ui.s>
          <a href="#" className={classes.link} onClick={handleSwitchStakeholder}>
            Click here
          </a>{" "}
          to switch to a different stakeholder.
        </Ui.s>
      </div>
      <div>
        <StakeholderCompanyField className="mb-3" />
        {values.isCompanyOwned ? <Company updatedFields={updatedFields} /> : <Personal updatedFields={updatedFields} />}
      </div>
      <Divider />

      <div>
        <Button
          size="s"
          variant="primary"
          className="me-3"
          onClick={handleSave}
          isLoading={isUpdating}
          isDisabled={isUpdating}
        >
          {t("update")}
        </Button>
        <Button size="s" variant="secondary" onClick={handleCancel} isDisabled={isUpdating}>
          {t("cancel")}
        </Button>
      </div>
    </motion.div>
  );
};

export default UpdateStakeholderForm;
