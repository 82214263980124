import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { Formik, useFormikContext } from "formik";
import * as R from "ramda";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import StakeholderSection, {
  StakeholderContextProps,
} from "common/components/molecules/StakeholderSection/StakeholderSection";
import { RelationshipTypesEnum } from "common/enums/enum";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import Documentation from "./sections/Documentation";
import Terms from "./sections/Terms";
import useWarrantsEditPanel from "./useWarrantsEditPanel";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.editPanel");

const FormContent = () => {
  useToastFormikValidator();

  const { initialValues, warrantDetails, closePanel } = useWarrantsEditPanel();

  const { values, isSubmitting, handleSubmit } = useFormikContext<typeof initialValues>();

  const updatedFields = useMemo<StakeholderContextProps["updatedFields"]>(
    () =>
      values.isNeedEmailInvitationChecked ? { email: { isTopField: true, meta: { isOptional: false } } } : undefined,
    [values.isNeedEmailInvitationChecked]
  );

  return (
    <>
      <SlidePanel.Section title={t("warrantHolderDetails")}>
        <div className="mt-1" />
        <StakeholderSection updatedFields={updatedFields} relationshipTypeId={RelationshipTypesEnum.INVESTOR} />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("terms")}>
        <Terms />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("documentation")} isDividerVisible={false}>
        <Documentation />
      </SlidePanel.Section>

      <SlidePanel.Actions
        secondaryTitle={t("cancel")}
        isLoading={isSubmitting}
        isDisabledPrimary={isSubmitting}
        primaryTitle={!R.isNil(warrantDetails) ? t("saveChanges") : t("issueWarrant")}
        secondaryAction={closePanel}
        primaryAction={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
      />
    </>
  );
};

const WarrantForm: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { warrantId } = IssueEquityContext.useStoreState((state) => state);
  const { getWarrantDetailsThunk } = IssueEquityContext.useStoreActions((actions) => actions);

  const { initialValues, validationSchema, submitHandler } = useWarrantsEditPanel();

  useEffect(() => {
    if (warrantId) {
      setIsLoading(true);
      getWarrantDetailsThunk(warrantId)
        .finally(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [getWarrantDetailsThunk, warrantId]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        <FormContent />
      </Formik>
    </SlidePanel.Body>
  );
};

const WarrantsEditPanel = () => {
  const { isWarrantsEditPanelOpen, warrantId } = IssueEquityContext.useStoreState((state) => state);
  const { setIsWarrantsEditPanelOpen } = IssueEquityContext.useStoreActions((actions) => actions);

  const closePanel = useCallback(() => {
    setIsWarrantsEditPanelOpen({ open: false });
  }, [setIsWarrantsEditPanelOpen]);

  return (
    <SlidePanel show={isWarrantsEditPanelOpen}>
      <SlidePanel.Header title={t("title")} isEdit={!!warrantId} onHide={closePanel} />
      <WarrantForm />
    </SlidePanel>
  );
};

export default WarrantsEditPanel;
