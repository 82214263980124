import { FC, ReactNode, useCallback, useMemo } from "react";
import classNames from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, P, Ui } from "common/components/atoms";
import { EditIcon } from "common/icons/svg";
import { ShareClassServiceGetResponse } from "common/shareClass/ShareClassService";
import { ShareClassTypes } from "common/shareClass/types";
import { scssVariables } from "common/utils/constants";
import { TranslationNS } from "translation";
import { createTranslation, TElOptions } from "translation/helpers";

import ReadMore from "../../../components/molecules/ReadMore/ReadMore";
import Possibilities from "../Possibilities/Possibilities";
import classes from "./ShareClassInfoCard.module.scss";

export type InfoCardProps = JSX.IntrinsicElements["div"] & {
  shareClass: ShareClassServiceGetResponse;
  showStatistics?: boolean;
  onEdit?(shareClass: ShareClassServiceGetResponse): void;
};

const t = createTranslation(TranslationNS.common, "shareClass.components.infoCard");

const ShareClassInfoCard: FC<InfoCardProps> = ({ className, shareClass, showStatistics, onEdit, ...props }) => {
  const { hasFullAccess } = useFeatures(FEATURES.shareClasses);

  const liquidationPreferencesList = useMemo<ReactNode[]>(() => {
    const list = t("liquidationPreferences.list", {
      shareClass,
      defaultValue: [],
      returnObjects: true,
    });

    const options: TElOptions = {
      components: [<strong key="1" />],
      values: { shareClass },
    };

    return (Array.isArray(list) ? list : [list]).map((_, index) =>
      t.el(`liquidationPreferences.list.${index}`, options)
    );
  }, [shareClass]);

  const buttonEditClickHandler = useCallback((): void => {
    onEdit?.(shareClass);
  }, [onEdit, shareClass]);

  return (
    <>
      <div className={classNames("p-3", classes["card"], className)} {...props}>
        {!!onEdit && hasFullAccess ? (
          <Button
            className={`p-0 mt-2 me-3 ${classes["action-button"]}`}
            variant="tertiary"
            onClick={buttonEditClickHandler}
          >
            <EditIcon className="icon-size-3" />
          </Button>
        ) : null}

        <Ui.l bold className="mb-1">
          {shareClass.name}
        </Ui.l>
        <Ui.s className="mb-2" style={{ color: scssVariables.foregroundMedium }}>
          {shareClass.hasPreferences ? t("preferred") : t("common")}
        </Ui.s>

        <ReadMore description={shareClass.purpose || ""}>
          <P.s style={{ whiteSpace: "break-spaces" }}>{shareClass.purpose}</P.s>
        </ReadMore>

        <hr className="mt-3" />

        <Possibilities
          hasVotingRights={shareClass.hasVotingRights}
          votingRightsMultiple={shareClass.votingRightsMultiple}
          hasDividends={shareClass.hasDividends}
          hasPreferences={shareClass.hasPreferences}
          isPreferred={shareClass.shareClassTypeId === ShareClassTypes.preferred}
        />

        {shareClass.shareClassTypeId === ShareClassTypes.preferred && (
          <>
            <hr className="mt-3" />

            <Ui.s bold className="mb-1">
              {t("liquidationPreferences.title")}
            </Ui.s>

            <div>
              {liquidationPreferencesList.map((item, index) => (
                <Ui.s className="mb-1" key={index}>
                  {item}
                </Ui.s>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ShareClassInfoCard;
