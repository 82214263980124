import { FC } from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import classNames from "classnames";

import Button from "common/components/atoms/Button/Button";
import { ChevronLeftIcon, ChevronRightIcon, DoubleArrowLeftIcon, DoubleArrowRightIcon } from "common/icons/svg";
import { months, scssVariables } from "common/utils/constants";

import { Ui } from "../Typography";
import classes from "./DatePicker.module.scss";

const DatePickerHeader: FC<ReactDatePickerCustomHeaderProps> = ({
  date,
  decreaseMonth,
  increaseMonth,
  increaseYear,
  decreaseYear,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
}) => {
  return (
    <div
      className={classNames("d-flex justify-content-between align-items-center", classes.datePikerHeader)}
      data-testid="datepicker-menu-header-test-id"
    >
      <div className="mx-1">
        <Button
          isOnlyIcon
          className="px-half py-quarter me-quarter"
          variant="tertiary"
          size="s"
          onClick={decreaseYear}
          isDisabled={prevYearButtonDisabled}
        >
          <DoubleArrowLeftIcon />
        </Button>
        <Button
          isOnlyIcon
          className="px-half py-quarter"
          variant="tertiary"
          size="s"
          onClick={decreaseMonth}
          isDisabled={prevMonthButtonDisabled}
        >
          <ChevronLeftIcon />
        </Button>
      </div>
      <Ui.s className={classes.dateTitle}>{`${months[date.getMonth()]} ${date.getFullYear()}`}</Ui.s>
      <div className="mx-1">
        <Button
          isOnlyIcon
          className="px-half py-quarter me-quarter"
          variant="tertiary"
          size="s"
          onClick={increaseMonth}
          isDisabled={nextMonthButtonDisabled}
        >
          <ChevronRightIcon />
        </Button>
        <Button
          isOnlyIcon
          className="px-half py-quarter"
          variant="tertiary"
          size="s"
          onClick={increaseYear}
          isDisabled={nextYearButtonDisabled}
        >
          <DoubleArrowRightIcon />
        </Button>
      </div>
    </div>
  );
};

export default DatePickerHeader;
