import { FC, useRef, useState } from "react";

import ContextContent from "./components/ContextContent/ContextContent";
import ContextToggle from "./components/ContextToggle/ContextToggle";

const ContextCard: FC = () => {
  const headerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHeaderExpanded, setIsHeaderExpanded] = useState<boolean>(false);

  return (
    <ContextToggle
      open={isOpen}
      headerRef={headerRef}
      isHeaderExpanded={isHeaderExpanded}
      onClick={setIsOpen}
      setIsHeaderExpanded={setIsHeaderExpanded}
    >
      <ContextContent
        open={isOpen}
        ref={headerRef}
        isHeaderExpanded={isHeaderExpanded}
        setIsOpen={setIsOpen}
      />
    </ContextToggle>
  );
};

export default ContextCard;
