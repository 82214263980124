import { FC, useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { parseISO } from "date-fns";

import { SlidePanel, Ui } from "common/components/atoms";
import { MoneyTransferWidget } from "common/components/molecules";
import { CheckIcon, CloseIcon, SandboxIcon } from "common/icons/svg";
import { ExerciseRequestStatusEnum } from "common/types/Exxercise.types";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext from "../../ExercisingContext";
import classes from "./exerciseRequestStatus.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.exercising.statusModal");

const RenderSteps: FC<{ status: boolean; declined?: boolean }> = ({ declined, status }) => {
  return (
    <div
      className={classNames("mt-2", classes.circle, {
        [classes["inProgress"]]: !status,
        [classes["decline"]]: declined,
      })}
    >
      {!declined ? (
        status ? (
          <CheckIcon fontSize={24} color={scssVariables.positive900} />
        ) : (
          <SandboxIcon fontSize={24} color={scssVariables.warning900} />
        )
      ) : (
        <CloseIcon fontSize={24} color={scssVariables.critical900} />
      )}
    </div>
  );
};

const ExerciseRequestContent: FC = () => {
  const [isTransferred, setIsTransferred] = useState(false);

  const loading = ExercisingContext.useStoreState((state) => state.loading);
  const request = ExercisingContext.useStoreState((state) => state.activeExerciseRequest);
  const setActiveExerciseRequest = ExercisingContext.useStoreActions((actions) => actions.setActiveExerciseRequest);
  const notifyExerciseFeePaidThunk = ExercisingContext.useStoreActions((actions) => actions.notifyExerciseFeePaidThunk);

  const isRequestDeclined = request?.exerciseRequestStatus === ExerciseRequestStatusEnum.Declined;

  const handleClose = useCallback(() => {
    setActiveExerciseRequest(null);
  }, [setActiveExerciseRequest]);

  const handleCheck = useCallback(() => {
    setIsTransferred(!isTransferred);
  }, [isTransferred]);

  const handleSendNotification = useCallback(() => {
    if (request?.exerciseRequestId) {
      notifyExerciseFeePaidThunk(request.exerciseRequestId).then(() => {
        handleCheck();
        handleClose();

        notify(t("notificationSent"), true, "success", 3000, false, "top-center");
      });
    }
  }, [handleCheck, handleClose, notifyExerciseFeePaidThunk, request?.exerciseRequestId]);

  const isCheckMark = useMemo(() => {
    const s = ExerciseRequestStatusEnum;
    return {
      orderPlaced: request?.exerciseRequestStatus
        ? [s.OrderPlaced, s.AwaitingApproval, s.PendingPayment, s.SharesIssued, s.Declined].includes(
            request.exerciseRequestStatus
          )
        : false,
      approval: request?.exerciseRequestStatus
        ? [s.AwaitingApproval, s.PendingPayment, s.SharesIssued].includes(request.exerciseRequestStatus)
        : false,
      payment: request?.exerciseRequestStatus
        ? [s.SharesIssued, s.PendingPayment].includes(request.exerciseRequestStatus)
        : false,
      issued: request?.exerciseRequestStatus ? [s.SharesIssued].includes(request.exerciseRequestStatus) : false,
    };
  }, [request]);

  return (
    <>
      <SlidePanel.Header title={t("title", { agreementName: request?.companyName })} onHide={handleClose} />

      <div className={classNames("mt-7", classes.pointContainer)}>
        <div className={classes.point}>
          <RenderSteps status={isCheckMark.orderPlaced} />

          <div className="ps-2">
            <Ui.s className="mb-1" color="foregroundMedium">
              {request?.requestedAt && transformDateToCommonDateFormat(parseISO(request.requestedAt))}
            </Ui.s>

            <Ui.m bold className="mb-1">
              {t("requestSent")}
            </Ui.m>

            <Ui.m color="foregroundMedium">
              {t("orderPlacedDescription", { value: request?.numberOfOptions || 0 })}
            </Ui.m>
          </div>
        </div>

        {!isRequestDeclined ? (
          <div className={classes.point}>
            <RenderSteps status={isCheckMark.approval} />

            <div className="ps-2">
              <Ui.s className="mb-1" color="foregroundMedium">
                {request?.approvedAt && transformDateToCommonDateFormat(parseISO(request.approvedAt))}
              </Ui.s>

              <Ui.m bold className="mb-1">
                {t("exerciseRequestApproval")}
              </Ui.m>

              <Ui.m color="foregroundMedium">
                {t(isCheckMark.approval ? "managerApprovedRequest" : "exerciseRequestApprovalDescription")}
              </Ui.m>
            </div>
          </div>
        ) : null}

        {!isRequestDeclined ? (
          <div className={classes.point}>
            <RenderSteps status={isCheckMark.payment} />

            <div className="ps-2">
              <Ui.s className="mb-1" color="foregroundMedium">
                {request?.moneyTransferredAt && transformDateToCommonDateFormat(parseISO(request.moneyTransferredAt))}
              </Ui.s>

              <Ui.m bold className="mb-1">
                {t(isCheckMark.payment ? "paymentSent" : "payment")}
              </Ui.m>

              <Ui.m color="foregroundMedium">
                {t(isCheckMark.payment ? "transferredExerciseAmount" : "payExerciseFeeDescription")}
              </Ui.m>

              {request?.exerciseRequestStatus === ExerciseRequestStatusEnum.AwaitingApproval && (
                <div className="mt-3">
                  <Ui.m color="foregroundMedium">{t("payExerciseFeeAdditionalDescription")}</Ui.m>

                  <MoneyTransferWidget
                    className="mt-2"
                    name={request?.payment?.accountCompanyName || "-"}
                    accountNr={request?.payment?.accountNumber || "-"}
                    swiftBic={request?.payment?.swiftBic || "-"}
                    message={request?.payment?.message || "-"}
                    amount={`${request?.payment?.amount} ${request?.currencySymbol}`}
                    onSubmit={handleSendNotification}
                    isLoading={loading}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}

        <div className={classes.point}>
          <RenderSteps status={isCheckMark.issued} declined={isRequestDeclined} />

          <div className="ps-2">
            <Ui.s className="mb-1" color="foregroundMedium">
              {request?.issuedAt && transformDateToCommonDateFormat(parseISO(request.issuedAt))}
            </Ui.s>

            <Ui.m bold className="mb-1">
              {t(isRequestDeclined ? "declined" : "sharesIssued")}
            </Ui.m>

            <Ui.m color="foregroundMedium">
              {isRequestDeclined
                ? t("managerDeclinedRequest", {
                    name: request?.managerName ? request.managerName : "Manager",
                  })
                : t(isCheckMark.issued ? "shareIssuedSuccess" : "sharesIssuedDescription")}
            </Ui.m>
          </div>
        </div>
      </div>

      <SlidePanel.Actions secondaryTitle={t("close")} secondaryAction={handleClose} />
    </>
  );
};

const ExerciseRequestStatus: FC<{ open: boolean }> = ({ open }) => {
  const setActiveExerciseRequest = ExercisingContext.useStoreActions((actions) => actions.setActiveExerciseRequest);

  const handleClose = useCallback(() => {
    setActiveExerciseRequest(null);
  }, [setActiveExerciseRequest]);

  return (
    <SlidePanel show={open} onHide={handleClose} className={classes.wrapper}>
      <ExerciseRequestContent />
    </SlidePanel>
  );
};

export default ExerciseRequestStatus;
