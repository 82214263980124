import { FC, ReactNode, useMemo } from "react";
import { AxisTick } from "@nivo/axes";
import { format } from "date-fns";
import * as R from "ramda";

import StepMonoChart from "common/components/atoms/Charts/StepMono.chart";
import { getFiveIndexes } from "common/helpers";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers, { formatNumber } from "common/hooks/useFormatNumbers";
import { CliffChartIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { DataPoint, ReceiveRsaPlan, ReceiveSoPlan } from "../types";
import classes from "./schedule-chart.module.scss";

type PropsTypes = {
  className?: string;
  planDetails: ReceiveRsaPlan | ReceiveSoPlan;
};

const t = createTranslation(TranslationNS.pages, "company.receivePlan");

const MONTHS_PER_YEAR = 12;

const ScheduleChart: FC<PropsTypes> = ({ className, planDetails }) => {
  const { currencySymbol } = useCurrencyById(planDetails?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const adjustedVestingPeriod = useMemo(
    () => Math.ceil(R.defaultTo(0, planDetails?.vestingPeriod) / MONTHS_PER_YEAR),
    [planDetails?.vestingPeriod]
  );

  const chartData = useMemo(() => {
    return R.defaultTo([], planDetails?.dataPoints) as DataPoint[];
  }, [planDetails?.dataPoints]);

  const data = useMemo(
    () => ({
      totalPoints: [
        {
          id: "ScheduleChart",
          color: scssVariables.information500,
          data: chartData.map((item, index) => {
            const additionalData: { additionalDot?: { title: string; icon?: ReactNode } } = {};
            if (
              planDetails?.vestingCliff &&
              Math.ceil(planDetails?.vestingCliff / (planDetails?.vestingCliff || 1)) + 1 === index
            ) {
              additionalData.additionalDot = {
                title: t("cliffChartExplanation", {
                  percentage: planDetails?.cliffSharePercentage,
                  cliff: t("cliffMonthValue", {
                    count: planDetails?.vestingCliff,
                  }),
                }),
                icon: <CliffChartIcon className={classes["cliff-icon"]} />,
              };
            }
            if (planDetails?.vestingInterval && index === Math.floor(chartData.length / 2)) {
              additionalData.additionalDot = {
                title: t("secondPointChartExplanation", {
                  percentage: planDetails?.percentVestingInterval,
                  monthValue: t("cliffMonthValue", {
                    count: planDetails?.vestingInterval,
                  }),
                  period:
                    adjustedVestingPeriod > 0
                      ? t("cliffYearValue", {
                          count: adjustedVestingPeriod,
                        })
                      : t("cliffMonthValue", {
                          count: planDetails?.vestingPeriod,
                        }),
                }),
              };
            }

            return { ...item, ...additionalData };
          }),
        },
      ],
    }),
    [
      chartData,
      planDetails?.cliffSharePercentage,
      planDetails?.vestingCliff,
      planDetails?.percentVestingInterval,
      planDetails?.vestingInterval,
      planDetails?.vestingPeriod,
      adjustedVestingPeriod,
    ]
  );

  const marginLeft =
    formatNumber(Math.max(...(R.pluck("y", planDetails?.dataPoints || []) as number[]))).length * 8 + 10;

  const tickBottomValues = useMemo(() => {
    const data = planDetails?.dataPoints || [];
    if (data.length < 6) {
      return planDetails?.dataPoints.map((el) => el.x);
    }
    return getFiveIndexes(data.length).map((index) => data[index].x);
  }, [planDetails?.dataPoints]);

  const dataNew = useMemo(() => {
    const otherData = tickBottomValues?.map((el, index) => {
      return {
        id: `other${index}`,
        color: "#A3A09D",
        strokeWidth: 1,
        strokeDasharray: "14, 8",
        data: [
          { x: el, y: 0 },
          { x: el, y: data.totalPoints[0].data.find((item) => item.x === el)?.y || 0 },
        ],
      };
    });

    return [...data.totalPoints, ...otherData];
  }, [data.totalPoints, tickBottomValues]);

  return (
    <div className={className}>
      <StepMonoChart
        height={300}
        withCustomPoints
        isInteractive={false}
        data={dataNew}
        margin={{ bottom: 60, left: marginLeft, right: marginLeft + 55, top: 35 }}
        axisTop={{
          legend: t("shares"),
          legendPosition: "start",
          tickSize: 0,
          tickValues: 0,
          format: () => "",
        }}
        axisLeft={{
          tickSize: 0,
          tickValues: 6,
          format: (value) => fNumber(value, "amount"),
        }}
        axisRight={{
          tickSize: 0,
          tickPadding: marginLeft + 50,
          tickValues: 6,
          format: (value) =>
            fNumber((value * (planDetails?.shareMarketValue || 0)) / (planDetails?.totalShares || 1), "value"),
          renderTick: (tick) => {
            return <AxisTick {...tick} textAnchor="end" />;
          },
        }}
        axisBottom={{
          tickSize: 0,
          tickValues: tickBottomValues,
          tickPadding: 40,
          format: (value) => format(new Date(value), "MMM. d, yyyy"),
          renderTick: (tick) => {
            return <AxisTick {...tick} textAnchor="middle" />;
          },
        }}
      />
    </div>
  );
};

export default ScheduleChart;
