import IMask, { FactoryArg } from "imask";

import { FormatNumberOptions, NumberType } from "./types";

export const getDecimals = (decimals?: NumberType): number => {
  switch (true) {
    case decimals === "sharePrice":
      return 6;
    case decimals === "unitPrice":
      return 6;
    case decimals === "value":
      return 2;
    case decimals === "percent":
      return 2;
    case decimals === "amount":
      return 0;
    default:
      return 2;
  }
};

export const getScale = (number: string, decimalsType: NumberType | undefined) => {
  if (decimalsType === "sharePrice" || decimalsType === "unitPrice") {
    const [integer, decimals] = number.split(".");

    const lastNotZeroNumberPosition = decimals
      .split("")
      .reduce((lastNonZero, number, currentIndex) => (number !== "0" ? currentIndex : lastNonZero), 0);

    if (lastNotZeroNumberPosition >= 0 && lastNotZeroNumberPosition <= 1) {
      return 2;
    }

    if (lastNotZeroNumberPosition >= 2 && lastNotZeroNumberPosition < 5) {
      return lastNotZeroNumberPosition + 1;
    }

    return 6;
  }

  return getDecimals("value");
};

export const getStartSymbol = (
  type: NumberType,
  hideCurrencySymbol: boolean,
  defaultSymbol?: string,
  globalSymbol?: string,
  localSymbol?: string
) => {
  if (type === "percent") {
    return "";
  }

  if (type === "amount") {
    return "";
  }

  if (hideCurrencySymbol) {
    return "";
  }

  if (localSymbol === "kr") {
    return "";
  }

  if (!!localSymbol && localSymbol !== "kr") {
    return localSymbol;
  }

  if (globalSymbol === "kr") {
    return "";
  }

  if (!!globalSymbol && globalSymbol !== "kr") {
    return globalSymbol;
  }

  if (defaultSymbol === "kr") {
    return "";
  }

  return defaultSymbol;
};

export const getEndSymbol = (
  type: NumberType,
  hideCurrencySymbol: boolean,
  defaultSymbol?: string,
  globalSymbol?: string,
  localSymbol?: string
) => {
  if (type === "percent") {
    return "%";
  }

  if (type === "amount") {
    return "";
  }

  if (hideCurrencySymbol) {
    return "";
  }

  if (localSymbol === "kr") {
    return localSymbol;
  }

  if (!!localSymbol && localSymbol !== "kr") {
    return "";
  }

  if (globalSymbol === "kr") {
    return globalSymbol;
  }

  if (!!globalSymbol && globalSymbol !== "kr") {
    return "";
  }

  if (defaultSymbol === "kr") {
    return defaultSymbol;
  }

  return "";
};

export const formatNumber = (
  number: string | number | undefined,
  config: FormatNumberOptions = { decimals: "amount" }
) => {
  if (number === undefined || number === null || isNaN(Number(number)) || number === Infinity) {
    return "-";
  }

  const decimals = getDecimals(config?.decimals);

  const roundedNumber = Number(number).toFixed(decimals);

  const combinedConfig: FactoryArg = {
    mask: Number,
    thousandsSeparator: config.separator || "\u00A0", // \u00A0 - NO-BREAK SPACE
    radix: ",",
    scale: getScale(roundedNumber, config?.decimals),
    mapToRadix: [",", "."],
    normalizeZeros: config.normalizeZeros || false,
    padFractionalZeros: config.allowZeros || false,
  };

  const masked = IMask.createMask(combinedConfig);
  masked.resolve(String(roundedNumber), { input: false });

  return masked.value;
};
