import { FC, useCallback, useState } from "react";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";

import EditProgramForm from "./EditProgramForm";

type EditProgramSlidePanelProps = {
  show: boolean;
  programId?: number;
  onHide: () => void;
  afterSave?: (isAfterSave?: boolean) => void;
};

const EditProgramSlidePanel: FC<EditProgramSlidePanelProps> = ({ show, onHide, programId, afterSave }) => {
  const [isCallAfterSave, setIsCallAfterSave] = useState<boolean>(false);

  const handleClose = useCallback(
    (isAfterSave?: boolean) => {
      setIsCallAfterSave(typeof isAfterSave === "boolean" ? isAfterSave : false);
      onHide();
    },
    [onHide]
  );

  const handleExit = useCallback(() => {
    if (isCallAfterSave) {
      afterSave?.();
    }
  }, [afterSave, isCallAfterSave]);

  return (
    <SlidePanel show={show} onHide={onHide} onExited={handleExit}>
      <EditProgramForm programId={programId} onClose={handleClose} />
    </SlidePanel>
  );
};

export default EditProgramSlidePanel;
