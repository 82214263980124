import { FC, useCallback, useEffect, useState } from "react";
import { useFormikContext } from "formik";

import { ModalInfo, P } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import { createTranslation, TranslationNS } from "translation";

import CapitalIncreaseContext from "../../../capital-increase-context";
import { ImportTransactionFormValues } from "../use-import-transaction-form";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.transactions.modal");

type OrgNumberModalProps = {
  show: boolean;
  handleClose: () => void;
};

const TransactionModal: FC<OrgNumberModalProps> = ({ show, handleClose }) => {
  const { handleSubmit, isSubmitting, values } = useFormikContext<ImportTransactionFormValues[]>();
  const { currentBundle } = CapitalIncreaseContext.useStoreState((state) => state);
  const [renderModal, setRenderModal] = useState(false);

  const handleClickSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    const totalSharesInTransactions = values.reduce(
      (sum, transaction) => sum + (Number(transaction?.numberOfShares) || 0),
      0
    );

    if (!renderModal && !isSubmitting && currentBundle.numberOfShares === totalSharesInTransactions) {
      handleSubmit();
    } else {
      setRenderModal(true);
    }
  }, [currentBundle.numberOfShares, handleSubmit, isSubmitting, renderModal, values]);

  const Footer = useCallback(() => {
    return (
      <>
        <Button isLoading={isSubmitting} isDisabled={isSubmitting} onClick={handleClickSubmit}>
          {t("confirm")}
        </Button>
        <Button isDisabled={isSubmitting} onClick={handleClose} variant="secondary">
          {t("cancel")}
        </Button>
      </>
    );
  }, [handleClickSubmit, handleClose, isSubmitting]);

  if (!renderModal) {
    return null;
  }

  return (
    <ModalInfo size="sm" show={show} handleClose={handleClose} header={t("title")} footer={<Footer />}>
      <P.m>{t("description")}</P.m>
    </ModalInfo>
  );
};

export default TransactionModal;
