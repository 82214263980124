import { FC, useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as R from "ramda";

import Button from "common/components/atoms/Button/Button";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { Ui } from "common/components/atoms/Typography";
import { MAX_FILE_SIZE } from "common/utils/constants";
import scssVariables from "scss/variables.module.scss";
import { createTranslation } from "translation/helpers";

import { FolderIcon } from "../../../icons/svg";
import InputFeedback from "../input-blocks/input-feedback/input-feedback";
import classes from "./DragAndDrop.module.scss";

export type DragDropProps = {
  label: string;
  content: string;
  isDisabled?: boolean;
  attachedFile?: string;
  isLabelOptional?: boolean;
  customEndLabelContent?: string;
  withoutBrowse?: boolean;
  onChange?: (e: File) => void;
  onChangeMultiple?: (files: File[]) => void;
  isUploadMultiple?: boolean;
  outputChangedParams?: any;
};

const translation = createTranslation("common", "components.drag&Drop");

const FILE_TYPE_PDF = "application/pdf";

const DragDrop: FC<DragDropProps> = ({
  label,
  content,
  isDisabled,
  attachedFile,
  withoutBrowse,
  isLabelOptional,
  isUploadMultiple,
  customEndLabelContent,
  onChange = () => undefined,
  onChangeMultiple = () => undefined,
}) => {
  const [error, setError] = useState<string>();
  const [isDragOver, setIsDragOver] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setIsDragOver(false);
      if (isUploadMultiple) {
        const isAllPdfs = acceptedFiles.find((file) => file.type.toLowerCase() !== FILE_TYPE_PDF);
        if (isAllPdfs) {
          setError(translation("wrongFormat"));
          return;
        }

        const isAllSizesFit = acceptedFiles.find((file) => file.size > MAX_FILE_SIZE);

        if (isAllSizesFit) {
          setError(translation("tooBig", { fileSize: MAX_FILE_SIZE / 1000_000 }));
          return;
        }

        onChangeMultiple(acceptedFiles);
        setError(undefined);
      }

      if (!isUploadMultiple) {
        const currentSelection = acceptedFiles[0];

        if (currentSelection.type.toLowerCase() !== FILE_TYPE_PDF) {
          setError(translation("wrongFormat"));
          return;
        }

        if (currentSelection.size > MAX_FILE_SIZE) {
          setError(translation("tooBig", { fileSize: MAX_FILE_SIZE / 1000_000 }));
          return;
        }

        onChange(currentSelection);
        setError(undefined);
      }
    },
    [isUploadMultiple, onChange, onChangeMultiple]
  );

  const onDragOver = () => {
    /* istanbul ignore next */
    if (error) {
      setError(undefined);
    }

    /* istanbul ignore next */
    if (!isDragOver) {
      setIsDragOver(true);
    }
  };

  const onDragLeave = () => {
    /* istanbul ignore next */
    if (isDragOver) {
      setIsDragOver(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragOver,
    onDragLeave,
  });

  return (
    <div>
      <InputHeader
        label={label}
        className="mb-2"
        isDisabled={isDisabled}
        isOptional={isLabelOptional}
        meta={customEndLabelContent}
      />

      <div {...getRootProps()} className={classes["container"]} data-testid="drag-drop-container-test-id">
        <input {...getInputProps()} data-testid="drag-drop-input-test-id" />
        <div className="d-flex align-items-center">
          <div style={{ marginRight: 15 }}>{<FolderIcon height={40} width={40} />}</div>
          {attachedFile ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <a
                target="_blank"
                rel="noreferrer"
                className="m-0 ui-m"
                href={attachedFile}
                style={{
                  color: scssVariables.foregroundHigh,
                }}
              >
                {translation("pitchPDFFile")}
              </a>
            </div>
          ) : (
            <>
              <Ui.s>{content}</Ui.s>
              &nbsp;
              {withoutBrowse ? null : (
                <Ui.s bold style={{ textDecoration: "underline", cursor: "pointer" }}>
                  {translation("browse")}
                </Ui.s>
              )}
            </>
          )}
        </div>

        {attachedFile && (
          <Button variant="tertiary" onClick={() => undefined}>
            {translation("replace")}
          </Button>
        )}
      </div>
      {!R.isNil(error) ? <InputFeedback className="mt-1" isTouched error={error} /> : null}
    </div>
  );
};

export default DragDrop;
