import { FC } from "react";

import RSAAgreement from "common/components/organisms/agreements/composition/RSAAgreement";
import SOAgreement from "common/components/organisms/agreements/composition/SOAgreement";
import { AgreementUIType } from "common/enums/enum";

const PlanOverview: FC = () => {
  return (
    <>
      <RSAAgreement type={AgreementUIType.slidePanel} />
      <SOAgreement type={AgreementUIType.slidePanel} />
    </>
  );
};

export default PlanOverview;
