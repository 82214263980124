import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Ui } from "common/components/atoms";
import Step from "common/components/atoms/Step/Step";
import StepInfo from "common/components/atoms/Step/StepInfo";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CompanyStatusIcon, LockIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { Variant } from "store/GettingStartedGuideModel";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import InfoCart from "../InfoCard/InfoCard";
import classes from "./stepFour.module.scss";

const t = createTranslation(TranslationNS.pages, "company.gettingStartedGuide");

const StepFour: FC<{ status: Variant }> = ({ status }) => {
  const navigate = useNavigate();
  const fNumber = useFormatNumbers();

  const { capTable, plansInPrograms } = useStoreState((state) => state.gettingStartedGuide);

  const hasPrograms = !!plansInPrograms.programs.length;
  const setupCapTable = !capTable.numberOfShareHolders;

  const isInactive = status === "waiting" || status === "undone";

  const handleCreateProgram = useCallback(() => {
    navigate(getEMPath(["createProgram", "main"]));
  }, [navigate]);

  const handleProgram = useCallback(() => {
    navigate(getEMPath(["plans", "poolsAndPrograms"]));
  }, [navigate]);

  const handleOneOffPlan = useCallback(() => {
    navigate(getEMPath(["createOneOffPlan", "planReceiver"]));
  }, [navigate]);

  return (
    <Step.Item status={status}>
      <Step.Point
        icon={
          status === "done" ? undefined : status === "noSubscription" ? (
            <LockIcon />
          ) : (
            <CompanyStatusIcon width="24px" height="24px" />
          )
        }
      />
      <StepInfo>
        {status === "undone" && <Step.Important className="mb-1" />}
        <div className="d-flex align-items-center">
          <div className={classes.poolItem}>
            <b>{t("stepFour.title")}</b>
            <p className="mt-1 mb-3">{t("stepFour.description")}</p>
            {!isInactive && !setupCapTable ? (
              <>
                {hasPrograms && (
                  <Button onClick={handleProgram} variant="secondary" size="s" isDisabled={status === "noSubscription"}>
                    {t("stepFour.managePrograms")}
                  </Button>
                )}
                {!hasPrograms && (
                  <div className="d-flex ">
                    <Button
                      onClick={handleCreateProgram}
                      size="s"
                      isDisabled={status === "noSubscription"}
                      style={{ fontSize: 12 }}
                    >
                      {t("stepFour.addProgram")}
                    </Button>
                    {status !== "noSubscription" ? (
                      <Button
                        size="s"
                        onClick={handleOneOffPlan}
                        className="ms-1"
                        variant="secondary"
                        style={{ fontSize: 12 }}
                      >
                        {t("stepFour.addOneOffPlan")}
                      </Button>
                    ) : null}
                  </div>
                )}
              </>
            ) : null}
          </div>
          <div className={classes.poolItem}>
            <div className="d-flex align-items-center justify-content-center" style={{ marginRight: "-20px" }}>
              {status === "noSubscription" ? (
                <>
                  <LockIcon color={scssVariables.foregroundDisabled} />
                  <Ui.m className="ms-1" style={{ color: scssVariables.foregroundDisabled }}>
                    {t("subscribeForAccess")}
                  </Ui.m>
                </>
              ) : (
                <div className={cn(classes.pool, "ms-3")}>
                  {isInactive || !hasPrograms ? (
                    <CompanyStatusIcon width="50" height="50" />
                  ) : (
                    <>
                      {plansInPrograms.programs.map((item, index) => (
                        <InfoCart
                          key={index}
                          className={classes.poolCard}
                          title={item.programName}
                          description={t("stepFour.shares", {
                            shares: fNumber(item.numberOfShares, "amount"),
                            plans: fNumber(item.numberOfPlans, "amount"),
                          })}
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={classes.blurSection}></div>
        </div>
      </StepInfo>
    </Step.Item>
  );
};

export default StepFour;
