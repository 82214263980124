import axios from "axios";
import { action, actionOn, thunk } from "easy-peasy";
import { isEmpty, isNil } from "ramda";

import { GrantPlanDraftPostDTO, GrantPlanPostDTO, PlanConditionDTO, PlanGetDTO, PlanModel } from "./modelTypes";
import { getThunkTypes } from "./storeThunk";

export const planModel: PlanModel = {
  plan: null,
  isGetPlanLoading: false,
  isPostPlanLoading: false,
  //actions
  setPlan: action((state, payload) => {
    state.plan = payload;
  }),
  // thunks
  getPlanThunk: thunk(async (_, planId, { fail }) => {
    return await axios.get<PlanGetDTO>(`/api/ownership/plan/${planId}`).catch(fail);
  }),
  setPlanThunk: thunk(async (_, payload, { fail }) => {
    const formData = generateFormDataPlan(payload);
    return await axios.post("/api/ownership/plan", formData).catch(fail);
  }),
  grantPlanThunk: thunk(async (_, payload, { fail }) => {
    const formDta = generateFormDataGrantPlan(payload);
    return await axios.post("/api/ownership/plan/grant-plans", formDta).catch(fail);
  }),
  setOneOffPlanRSAThunk: thunk(async (_, payload, { fail }) => {
    const formDta = generateFormDataPlan(payload);
    return await axios.post("/api/ownership/plan/one-off/rsa", formDta).catch(fail);
  }),
  setOneOffPlanSOThunk: thunk(async (_, payload, { fail }) => {
    const formDta = generateFormDataPlan(payload);
    return await axios.post("/api/ownership/plan/one-off/stock-options", formDta).catch(fail);
  }),
  setRegularPlanRSAThunk: thunk(async (_, payload, { fail }) => {
    const formDta = generateFormDataPlan(payload);

    return await axios.post("/api/ownership/plan/rsa", formDta).catch(fail);
  }),
  setRegularPlanSOThunk: thunk(async (_, payload, { fail }) => {
    const formDta = generateFormDataPlan(payload);
    return await axios.post("/api/ownership/plan/stock-options", formDta).catch(fail);
  }),
  grantPlanDraftThunk: thunk(async (action, payload, { fail }) => {
    const formDta = generateFormDataGrantPlan(payload, true);
    return await axios.post("/api/ownership/plan/save-draft", formDta).catch(fail);
  }),
  setUpdateEmailThunk: thunk(async (action, payload, { fail }) => {
    return await axios.post("/api/ownership/plan/update-email", payload).catch(fail);
  }),
  setDeletePlanThunk: thunk(async (action, planId, { fail }) => {
    return await axios.delete(`/api/ownership/plan/${planId}`).catch(fail);
  }),
  setConfirmAndSendEmailThunk: thunk(async (action, payload, { fail }) => {
    return await axios.post("/api/ownership/plan/confirm-and-send-email", payload).catch(fail);
  }),
  // actionOn
  onGetPlanThunk: actionOn(
    (actions) => getThunkTypes(actions.getPlanThunk),
    (state, target) => {
      const [startType, successType] = target.resolvedTargets;
      state.isGetPlanLoading = startType === target.type;
      switch (target.type) {
        case startType:
          state.plan = null;
          break;
        case successType:
          state.plan = target.result.data;
      }
    }
  ),
  onPostPlanThunk: actionOn(
    (actions) =>
      getThunkTypes(
        actions.setPlanThunk,
        actions.grantPlanThunk,
        actions.grantPlanDraftThunk,
        actions.setOneOffPlanRSAThunk,
        actions.setOneOffPlanSOThunk,
        actions.setRegularPlanRSAThunk,
        actions.setRegularPlanSOThunk,
        actions.setUpdateEmailThunk,
        actions.setDeletePlanThunk,
        actions.setConfirmAndSendEmailThunk
      ),
    (state, target) => {
      const startTypes = target.resolvedTargets.filter((type) => type.includes("(start)"));
      state.isPostPlanLoading = startTypes.includes(target.type);
    }
  ),
};

const generateFormDataGrantPlan = (payload: GrantPlanPostDTO | GrantPlanDraftPostDTO, isDraft?: boolean) => {
  const formDta = new FormData();
  // add array of plansIds
  if (!isDraft) {
    const plans = payload.plans as GrantPlanPostDTO["plans"];
    plans.forEach((plan, index) => {
      formDta.append(`plans[${index}].planId`, plan.planId.toString());
      formDta.append(`plans[${index}].sendEmailInvitation`, (plan.sendEmailInvitation || false).toString());
    });
  } else {
    const plans = payload.plans as GrantPlanDraftPostDTO["plans"];
    plans.forEach((plan, index) => {
      formDta.append(`plans[${index}]`, plan.toString());
    });
  }
  // add companyId
  formDta.append("companyId", payload.companyId.toString());
  // add documentStatusId
  if (payload.documentsStatusId) {
    formDta.append("documentsStatusId", payload.documentsStatusId.toString());
  }
  // add newFiles
  if (payload.newFiles) {
    payload.newFiles.forEach((file) => {
      formDta.append("newFiles", file);
    });
  }
  // add oldFiles
  if (payload.oldFileIds) {
    payload.oldFileIds.forEach((fileId) => {
      formDta.append("oldFileIds", fileId.toString());
    });
  }
  if (payload.programId) {
    formDta.append("programId", payload.programId.toString());
  }
  if (payload.grantDate) {
    formDta.append("grantDate", payload.grantDate);
  }
  return formDta;
};

export const generateFormDataPlan = <T extends object>(payload: T) => {
  // generate formData from payload object
  const formData = new FormData();
  Object.keys(payload).forEach((key) => {
    const value = payload[key as keyof T] as string | number | boolean | Blob | PlanConditionDTO[];
    if (typeof value === "boolean" || typeof value === "number" || typeof value === "string") {
      formData.append(key, value.toString());
    } else if (value instanceof Blob) {
      formData.append(key, value);
    } else if (key === "conditions" && Array.isArray(value)) {
      if (value.length === 0) {
        formData.append("conditions", "");
      } else {
        value.forEach((item, index) => {
          if (item?.id !== undefined) {
            formData.append(`conditions[${index}].id`, item?.id.toString());
          }
          formData.append(`conditions[${index}].title`, item?.title.toString());
          formData.append(`conditions[${index}].description`, item?.description || "");
          formData.append(`conditions[${index}].targetDate`, item?.targetDate);
          formData.append(`conditions[${index}].numberOfOptions`, (item?.numberOfOptions || 0).toString());
        });
      }
    }
    if (key === "filesData" && !isNil(value)) {
      const typedValue: { files?: File[]; oldFileIds: number[] } = value as any;

      if (!isNil(typedValue.files) && !isEmpty(typedValue.files)) {
        typedValue.files.forEach((file) => {
          formData.append("filesData.files", file);
        });
      }

      if (!isNil(typedValue.oldFileIds) && !isEmpty(typedValue.oldFileIds)) {
        typedValue.oldFileIds.forEach((id) => {
          formData.append("filesData.oldFileIds", String(id));
        });
      }
    }
  });
  return formData;
};
