import { FC } from "react";
import { useFormikContext } from "formik";
import { AnimatePresence } from "framer-motion";

import { SummaryFormNewType } from "../types";
import classes from "./table.module.scss";
import TableRow from "./tableRow";

const TableBody: FC = () => {
  const { values, handleBlur, handleChange } = useFormikContext<SummaryFormNewType>();

  return (
    <tbody className={classes["shareholders-table-body"]}>
      <AnimatePresence>
        {values.plans.map((plan, index: number) => {
          return (
            <TableRow key={plan.id} row={plan} index={index} handleBlur={handleBlur} handleChange={handleChange} />
          );
        })}
      </AnimatePresence>
    </tbody>
  );
};

export default TableBody;
