import { FC, useState } from "react";
import BTable from "react-bootstrap/Table";
import { flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import cn from "classnames";

import { SingleShareClassData } from "common/components/organisms/stakeholder-details/types";

import classes from "../../stakeholder-details.module.scss";
import HeadRow from "../ShareSeriesTable/HeadRow";
import useShareClassTable from "./useShareClassTable";

const ShareClassTable: FC<{ data: SingleShareClassData[]; className: string }> = ({ data, className }) => {
  const [sorting, setSorting] = useState<SortingState>([{ id: "shares", desc: false }]);

  const columns = useShareClassTable();

  const table = useReactTable({
    data: data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={className}>
      <BTable className={cn(classes.table, classes.ownershipTable)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <HeadRow key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className={cn(cell.column.columnDef.meta?.bodyClass)}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BTable>
    </div>
  );
};

export default ShareClassTable;
