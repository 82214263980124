import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { getPath } from "app/Router/RouterHelper";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { InvitedUser } from "store/InvitedUserModel";
import { useStoreActions, useStoreState } from "store/store";

const ReceiveTeam = () => {
  const navigate = useNavigate();

  const setInvitedUserAction = useStoreActions((actions) => actions.invitedUser.setInvitedUser);

  const invitedUserStore = useStoreState((state) => state.invitedUser.invitedUser);

  const acceptMembership = useCallback(
    async (invitedUserStore: InvitedUser | null) => {
      if (invitedUserStore && invitedUserStore?.invitationExpiry > new Date().toISOString()) {
        try {
          await axios.post("/api/attract/accept-team-invitation", {
            approvalKey: invitedUserStore.approvalKey,
            companyId: invitedUserStore.entityId,
          });
        } catch (e) {
          console.warn(JSON.parse(JSON.stringify(e)));
        }
      }

      setInvitedUserAction(null);
      navigate(getPath(["user", "dashboard"]), { replace: true });
    },
    [navigate, setInvitedUserAction]
  );

  useEffect(() => {
    if (invitedUserStore) {
      acceptMembership(invitedUserStore).then();
    }
  }, [acceptMembership, invitedUserStore]);

  return <Spinner />;
};

export default ReceiveTeam;
