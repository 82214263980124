import { FC, ReactNode, SyntheticEvent, useCallback } from "react";

type ToggleIgnoreProps = {
  children: ReactNode;
  className?: string;
};
const ToggleIgnore: FC<ToggleIgnoreProps> = ({ children, className }) => {
  const handleClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
  }, []);
  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  );
};

export default ToggleIgnore;
