import { FC } from "react";
import { useFormikContext } from "formik";
import * as R from "ramda";

import TextField from "common/components/atoms/TextField/TextField";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.seniorityLevelMultipleField");

type SeniorityLevelMultipleFieldProps = {
  className?: string;
};
const SeniorityLevelMultipleField: FC<SeniorityLevelMultipleFieldProps> = ({ className }) => {
  const { touched, errors, values, handleChange, handleBlur } =
    useFormikContext<Pick<ShareClassFormData, "seniorityLevelMultiple">>();

  return (
    <TextField
      className={className}
      min={0}
      type="number"
      label={t("label")}
      error={errors.seniorityLevelMultiple}
      isTouched={touched.seniorityLevelMultiple}
      onBlur={handleBlur}
      value={R.defaultTo("", values.seniorityLevelMultiple)}
      onChange={handleChange}
      name={"seniorityLevelMultiple"}
    />
  );
};

export default SeniorityLevelMultipleField;
