import { FC, memo } from "react";

import { ProgramPlanDTO } from "store/modelTypes";

import TableRow from "./table-row";

type DraftPlansTableBodyProps = {
  plans: ProgramPlanDTO[];
};

const TableBody: FC<DraftPlansTableBodyProps> = ({ plans }) => {
  return (
    <tbody>
      {plans.map((el) => {
        return <TableRow key={el.id} row={el} />;
      })}
    </tbody>
  );
};

export default memo(TableBody);
