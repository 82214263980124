import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CurrencyTypesEnum } from "common/enums/enum";
import { OnboardingCompanyPostDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const vt = createTranslation(TranslationNS.validation);

export type OnboardingCompanyBasicFormValues = OnboardingCompanyPostDTO;

export const fields = {
  name: "name",
  headquartersCountryId: "headquartersCountryId",
  city: "city",
  currencyId: "currencyId",
  valuation: "valuation",
  foundingDate: "foundingDate",
  companyId: "companyId",
  organizationNumber: "organizationNumber",
  nominalShareValue: "nominalShareValue",
  numberOfShares: "numberOfShares",
  companyWithFewOwners: "companyWithFewOwners",
} as const;

export const useCompanyInformationForm = () => {
  const { i18n } = useTranslation();
  const company = useStoreState((state) => state.companyOnboardingModel.company);
  const companyType = useStoreState((state) => state.companyOnboardingModel.companyType);
  const { bregg, uniMicro } = useStoreState((state) => state.companyOnboardingModel.integrations);

  const setCompanyType = useStoreActions((actions) => actions.companyOnboardingModel.setCompanyType);

  const companyInfo = company || bregg || uniMicro;

  const initialValues = useMemo<OnboardingCompanyBasicFormValues>(
    () => ({
      [fields.name]: companyInfo?.name || "",
      [fields.headquartersCountryId]: companyInfo?.headquartersCountryId || 0,
      [fields.city]: companyInfo?.city || "",
      [fields.currencyId]: companyInfo?.currencyId || CurrencyTypesEnum.NORWEGIAN_KRONER,
      [fields.valuation]: companyInfo?.valuation || undefined,
      [fields.foundingDate]: companyInfo?.foundingDate || undefined,
      [fields.companyId]: companyInfo?.companyId || undefined,
      [fields.organizationNumber]: companyInfo?.organizationNumber || undefined,
      [fields.nominalShareValue]: companyInfo?.nominalShareValue || undefined,
      [fields.companyWithFewOwners]: companyType === "few-owners",
      [fields.numberOfShares]: companyInfo?.numberOfShares || undefined,
    }),
    [companyType, companyInfo]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [fields.name]: Yup.string().required(vt("required")),
        [fields.headquartersCountryId]: Yup.number().min(1, vt("required")),
        [fields.currencyId]: Yup.number().min(1, vt("required")),
        [fields.valuation]: Yup.number()
          .nullable()
          .transform((_, val) => (+val === Number(+val) ? +val : null))
          .required(vt("required"))
          .min(1, vt("required")),
        [fields.foundingDate]: Yup.date().required(vt("required")),
        [fields.city]: Yup.string().required(vt("required")),
        [fields.nominalShareValue]: Yup.number().when(fields.companyWithFewOwners, {
          is: false,
          then: Yup.number()
            .required(vt("required"))
            .min(0.000001, vt("minNumber", { number: "0.000001" })),
          otherwise: Yup.number().notRequired(),
        }),
        [fields.numberOfShares]: Yup.number().when(fields.companyWithFewOwners, {
          is: true,
          then: Yup.number()
            .required(vt("required"))
            .integer(vt("integer"))
            .min(1, vt("minNumber", { number: "1" }))
            .typeError(vt("required")),
          otherwise: Yup.number().notRequired()?.nullable(),
        }),
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  useEffect(() => {
    if (companyInfo?.companyWithFewOwners) {
      setCompanyType("few-owners");
    }
  }, [setCompanyType, companyInfo?.companyWithFewOwners]);

  return { initialValues, validationSchema };
};
