import { FC, useCallback } from "react";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";

import DocumentsContext, { DocumentType } from "../../DocumentsContext";
import classes from "../CommonStyles.module.scss";
import AgreementsDocumentsPanelContent from "./components/AgreementsDocumentsPanelContent";
import FinancialInstrumentsPanelContext from "./components/FinancialInstrumentsPanelContext";
import GoverningPanelContent from "./components/GoverningPanelContent";
import PoolDocumentsPanelContent from "./components/PoolDocumentsPanelContent";
import ProgramDocumentsPanelContext from "./components/ProgramDocumentsPanelContext";
import StandaloneDocumentsPanelContent from "./components/StandaloneDocumentsPanelContent";
import TransactionDocumentsPanelContext from "./components/TransactionDocumentsPanelContext";

const EditDocument: FC = () => {
  const { isEditPanelOpen } = DocumentsContext.useStoreState((state) => state);
  const { setIsEditPanelOpen } = DocumentsContext.useStoreActions((actions) => actions);

  const closeOffCanvas = () => {
    setIsEditPanelOpen({
      isOpen: false,
      editPanel: undefined,
      documentType: undefined,
    });
  };

  const renderContent = useCallback(() => {
    switch (isEditPanelOpen.documentType) {
      case DocumentType.agreement: {
        return <AgreementsDocumentsPanelContent />;
      }

      case DocumentType.governing: {
        return <GoverningPanelContent />;
      }

      case DocumentType.financialIntrument: {
        return <FinancialInstrumentsPanelContext />;
      }

      case DocumentType.program: {
        return <ProgramDocumentsPanelContext />;
      }

      case DocumentType.pool: {
        return <PoolDocumentsPanelContent />;
      }

      case DocumentType.transactions: {
        return <TransactionDocumentsPanelContext />;
      }

      case DocumentType.standAloneDocuments: {
        return <StandaloneDocumentsPanelContent />;
      }

      default: {
        return <></>;
      }
    }
  }, [isEditPanelOpen.documentType]);

  return (
    <SlidePanel className={classes["slide-panel"]} show={isEditPanelOpen?.isOpen} onHide={closeOffCanvas}>
      {renderContent()}
    </SlidePanel>
  );
};

export default EditDocument;
