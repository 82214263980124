import { FC, useCallback, useMemo, useState } from "react";
import { OffcanvasProps } from "react-bootstrap/Offcanvas";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import classNames from "classnames";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { ShareClassFormProps } from "common/shareClass/components/ShareClassForm/ShareClassForm";
import { ShareClassTypes } from "common/shareClass/types";
import { notify } from "common/utils/notify/notifyFunction";
import { ShareClassGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { OnSubmit } from "../../../../../PostponedComponents/onboard-manager-wizard/types";
import Form, { FormProps } from "./Form/Form";
import classes from "./ShareClassFormOffcanvas.module.scss";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassFormOffcanvas");
const tWizard = createTranslation(TranslationNS.pages, "managerOnboarding.shareClassesStep");

type ContentProps = Pick<FormProps, "onCancel"> & {
  onSave: OnSubmit;
  companyId: number;
  shareClass: ShareClassGetDTO | null;
};

const Content: FC<ContentProps> = ({ onSave, onCancel, companyId, shareClass }) => {
  const [loading, setLoading] = useState(false);

  const formValues = useMemo<any>(
    () =>
      shareClass
        ? {
            companyId,
            ...shareClass,
          }
        : {
            companyId,
            hasVotingRights: true,
            hasDividends: true,
            isParticipating: true,
            seniorityLevel: 1,
            shareClassTypeId: ShareClassTypes.common,
          },
    [companyId, shareClass]
  );

  const submitHandler = useCallback<ShareClassFormProps["onSubmit"]>(
    async (values) => {
      setLoading(true);

      try {
        const response = await axios.post("/api/onboarding/share-class", values);
        if (response.status === 200) {
          notify(tWizard("createSuccessNotification"), true, "success");
          onSave(response.data);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [onSave]
  );

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <>
      <SlidePanel.Header
        title={shareClass ? t("editLabel") : t("createTitle")}
        onHide={onCancel}
        isEdit={!!shareClass}
      />
      <Form shareClass={formValues} onSubmit={submitHandler} onCancel={onCancel} />
    </>
  );
};

export type ShareClassFormOffcanvasProps = OffcanvasProps & ContentProps;

const CreateShareClass: FC<ShareClassFormOffcanvasProps> = ({
  className,
  shareClass,
  onSave,
  onCancel,
  companyId,
  ...props
}) => {
  return (
    <SlidePanel
      show={props.show}
      className={classNames(classes["off-canvas"], className)}
      scroll
      placement="end"
      onHide={onCancel as () => void}
      {...props}
    >
      <Content onSave={onSave} onCancel={onCancel} companyId={companyId} shareClass={shareClass} />
    </SlidePanel>
  );
};

export default CreateShareClass;
