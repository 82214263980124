import { useMemo } from "react";

import { useStoreState } from "../../store/store";

const useCurrencyById = (currencyId?: number) => {
  const dropdowns = useStoreState((state) => state.common?.dropdowns);

  const id = currencyId || 1; // default value for currencyId is 1 Norwegian Krone (NOK);

  const currency = useMemo(() => dropdowns?.currencies?.find((el) => el.id === id), [dropdowns?.currencies, id]);

  return {
    currencyName: currency?.name,
    currencySymbol: currency?.symbol,
    currencyCode: currency?.currencyCode,
  };
};

export default useCurrencyById;
