import { FC, MouseEventHandler, useCallback, useContext, useMemo } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import cn from "classnames";
import { assoc } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ProgressChart, Ui } from "common/components/atoms";
import { PoolStatuses } from "common/enums/enum";
import { ChevronDownIcon, DeleteIcon, EditIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { PoolType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import PoolsAndProgramsStore from "../../../../store";
import classes from "./PoolHeader.module.scss";

export type PoolHeaderProps = {
  pool: PoolType;
};

export type PoolHeaderComponent = FC<PoolHeaderProps>;

const tCommon = createTranslation(TranslationNS.common, "noAccess");

const PoolHeader: PoolHeaderComponent = ({ pool }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const { setEditingItem, setDeletingItem } = PoolsAndProgramsStore.useStoreActions((actions) => actions);
  const { hasFullAccess } = useFeatures(FEATURES.poolsAndPrograms);

  const isOpen = activeEventKey?.includes(String(pool?.id));

  const progressData = useMemo(() => {
    return [
      {
        id: `Vested - ${pool.id}`,
        value: pool.sharesUsedInAgreements,
        color: scssVariables.positive700,
        withThumb: true,
        thumbColor: scssVariables.positive700,
      },
    ];
  }, [pool.id, pool.sharesUsedInAgreements]);

  const statusColor = useMemo(() => {
    switch (pool.status) {
      case PoolStatuses.draft:
        return scssVariables.warning700;
      case PoolStatuses.active:
        return scssVariables.positive700;
      case PoolStatuses.expired:
        return scssVariables.critical700;
    }

    return undefined;
  }, [pool.status]);

  const openEditingModal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      const updatedPool = assoc("type", "Pool", pool) as PoolType;
      setEditingItem(updatedPool);
    },
    [pool, setEditingItem]
  );

  const openRemovalModal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      const updatedPool = assoc("type", "Pool", pool) as PoolType;
      setDeletingItem(updatedPool);
    },
    [pool, setDeletingItem]
  );

  const decoratedOnClick = useAccordionButton(pool.id.toString());

  return (
    <div className={cn(classes.header, "accordion-header")} onClick={decoratedOnClick}>
      {pool.status === PoolStatuses.draft ? (
        <div className={cn(classes.draft, "me-2")}>
          <EditIcon strokeWidth={2} />
        </div>
      ) : null}

      <div className="text-truncate">
        <Ui.xl title={pool.name} bold className="text-truncate">
          {pool.name}
        </Ui.xl>

        <Ui.xs className="d-flex gap-1" style={{ color: scssVariables.foregroundLow, marginTop: 5 }}>
          <span style={{ color: statusColor }}>{pool.statusName}</span>
        </Ui.xs>
      </div>
      <div className="d-flex ms-auto">
        {pool.status === PoolStatuses.draft ? null : (
          <div className="d-flex justify-content-center align-items-center">
            <div
              className={cn(classes["progress-chart"], "me-3", {
                [classes.hidden]: isOpen,
              })}
            >
              <ProgressChart withoutLabel withLeftBorder data={progressData} total={pool.authorisedOfShares} />
            </div>
          </div>
        )}
        <div className="mx-3">
          {pool.status !== PoolStatuses.active && (
            <Button
              size="s"
              isOnlyIcon
              variant="secondary"
              className={cn("me-2", classes["action-btn"])}
              onClick={openRemovalModal}
              isDisabled={!hasFullAccess}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            >
              <DeleteIcon />
            </Button>
          )}

          <Button
            size="s"
            isOnlyIcon
            variant="secondary"
            className={cn(classes["action-btn"], classes.edit)}
            onClick={openEditingModal}
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          >
            <EditIcon strokeWidth={1.5} />
          </Button>
        </div>
      </div>
      <ChevronDownIcon fontSize={24} color={scssVariables.foregroundLow} direction={isOpen ? "top" : "bottom"} />
    </div>
  );
};

export default PoolHeader;
