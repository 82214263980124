import { FC, useCallback } from "react";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { createTranslation, TranslationNS } from "translation";

type PropsType = {
  handleAddPlan?: () => void;
  handleAddAnother?: () => void;
  handleGrantPlan?: () => void;
  handleEdit?: () => void;
  handleClose?: (isOpen: boolean) => void;
  isSubmitting: boolean;
  grantPlanDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const Footer: FC<PropsType> = ({
  handleClose,
  handleEdit,
  isSubmitting,
  handleGrantPlan,
  grantPlanDisabled = false,
}) => {
  const handleCancel = useCallback(() => {
    handleClose?.(false);
  }, [handleClose]);

  return (
    <SlidePanel.Actions>
      <Button
        className="me-4"
        variant="primary"
        onClick={handleEdit}
        isDisabled={isSubmitting}
        isLoading={isSubmitting}
        data-testid="save-changes-btn"
      >
        {t("saveChanges")}
      </Button>
      {handleGrantPlan ? (
        <Button
          className="me-4"
          variant="secondary"
          isLoading={isSubmitting}
          isDisabled={isSubmitting || grantPlanDisabled}
          onClick={handleGrantPlan}
          data-testid="save-changes-btn"
        >
          {t("grantPlan")}
        </Button>
      ) : (
        <Button
          aria-label={t("cancel")}
          variant="secondary"
          onClick={handleCancel}
          isDisabled={isSubmitting}
          data-testid="handle=close-btn"
        >
          {t("cancel")}
        </Button>
      )}
    </SlidePanel.Actions>
  );
};

export default Footer;
