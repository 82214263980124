import { FC, useMemo } from "react";

import { sortingParams } from "common/types/Collapsible.types";

import { GetStakeholdersDTO, Stakeholder } from "../../types";
import Row from "./row";
import { SortOptions, SortSettings } from "./table-view";
import classes from "./table-view.module.scss";

type PropsTypes = {
  stakeholders: GetStakeholdersDTO;
  onEdit: (id: number) => void;
  onClick?: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
  sortSettings: SortSettings;
};

const getFieldValue = (stakeholder: Stakeholder, field: SortOptions) => {
  switch (field) {
    case SortOptions.Name:
      if (stakeholder.companyName) {
        return stakeholder.companyName;
      }
      return stakeholder.firstName;
    case SortOptions.Email:
      return stakeholder.email;
    case SortOptions.Relationship:
      return stakeholder.relationship;
    default:
      return "";
  }
};

const sortStakeholders = (stakeholders: GetStakeholdersDTO, sortSettings: SortSettings) => {
  return stakeholders.sort((a, b) => {
    const fieldA = String(getFieldValue(a, sortSettings.field)).toLowerCase(); // there is cases where email is "" or null, and it's mess up with sorting
    const fieldB = String(getFieldValue(b, sortSettings.field)).toLowerCase();

    if (fieldA === fieldB) return 0;
    return sortSettings.type === sortingParams.desc ? (fieldA < fieldB ? -1 : 1) : fieldA < fieldB ? 1 : -1;
  });
};

const TableBody: FC<PropsTypes> = ({ stakeholders, onEdit, onClick, handleRemoveStakeholder, sortSettings }) => {
  const sortedData: GetStakeholdersDTO = useMemo(() => {
    return sortStakeholders(stakeholders, sortSettings);
  }, [stakeholders, sortSettings]);

  return (
    <tbody>
      {sortedData.map((props) => (
        <Row
          key={props.stakeholderId}
          {...props}
          onEdit={onEdit}
          onClick={onClick}
          handleRemoveStakeholder={handleRemoveStakeholder}
          className={classes.row}
        />
      ))}
    </tbody>
  );
};

export default TableBody;
