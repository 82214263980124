import { forwardRef } from "react";
import AccordionItem from "react-bootstrap/AccordionItem";

import { PoolType } from "store/types";

import PoolBody from "./PoolBody/PoolBody";
import PoolHeader from "./PoolHeader/PoolHeader";
import classes from "./PoolItem.module.scss";

export type PoolItemProps = {
  pool: PoolType;
};

const PoolItem = forwardRef<HTMLDivElement, PoolItemProps>(({ pool }, ref) => {
  return (
    <AccordionItem ref={ref} className={classes["wrap"]} eventKey={String(pool.id)}>
      <PoolHeader pool={pool} />

      <PoolBody pool={pool} />
    </AccordionItem>
  );
});

PoolItem.displayName = "PoolSingleItem";

export default PoolItem;
