import { FC } from "react";
import cn from "classnames";

import CopyText from "common/components/atoms/CopyText/CopyText";
import { Ui } from "common/components/atoms/Typography";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CopyNewIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { PaymentInfo } from "../types";
import classes from "./payment-information.module.scss";

type InfoProps = {
  title: string;
  value?: string | number;
};

const Info: FC<InfoProps> = ({ title, value = "-" }) => (
  <div>
    <Ui.xs bold className="mb-1">
      {title}
    </Ui.xs>

    <div className="d-flex">
      <Ui.s className="me-1" style={{ color: scssVariables.foregroundMedium }}>
        {value}
      </Ui.s>

      <CopyText className={classes.copy} copyText={value.toString()}>
        <CopyNewIcon />
      </CopyText>
    </div>
  </div>
);

const t = createTranslation(TranslationNS.pages, "company.receivePlan.payment");

const PaymentInformation: FC<PaymentInfo & { stakeholderName: string }> = ({
  name,
  swiftBic,
  amount,
  accountNumber,
  stakeholderName,
  currencyId,
}) => {
  const { currencySymbol } = useCurrencyById(currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  return (
    <div className={cn("d-flex flex-column py-3 px-4 mt-4", classes.background)}>
      <div className="d-flex justify-content-between mb-4">
        <Info title={t("name")} value={name} />
        <Info title={t("swift")} value={swiftBic} />
        <Info title={t("amount")} value={fNumber(amount, "value")} />
        <Info title={t("accountNr")} value={`IBAN ${accountNumber}`} />
      </div>
      <Info title={t("message")} value={t("paymentMessage", { stakeholderName })} />
    </div>
  );
};

export default PaymentInformation;
