import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Tag } from "common/components/atoms";
import { RSAAgreement, RSAAgreementContext } from "common/components/organisms";
import { AgreementUIType } from "common/enums/enum";
import withAgreements from "common/HOC/withAgreements";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import Sidebar from "common/layout/MainLayout/Sidebar/Sidebar";
import { usePlanStatus } from "common/plan/planUtils";

const MyPortfolioRSAPlan: FC = () => {
  const location = useLocation();
  const { planId } = useParams<{ planId: string }>();
  const { getAgreementDetailsThunk } = RSAAgreementContext.useStoreActions((actions) => actions);
  const { agreementDetails } = RSAAgreementContext.useStoreState((state) => state);

  const planStatuses = usePlanStatus();

  useEffect(() => {
    getAgreementDetailsThunk(Number(planId));
  }, [getAgreementDetailsThunk, planId]);

  useDocumentTitleUpdate(
    `Restricted stock awards ${agreementDetails?.companyName ? `(${agreementDetails?.companyName})` : ""}`
  );

  return (
    <>
      <PageContent data-testid="portfolio-rsa-page">
        <PageContent.Header className="justify-content-start">
          <PageContent.Header.Title>
            {location.state?.programName || "Restricted stock awards"}
          </PageContent.Header.Title>

          <div className="mt-1">
            {agreementDetails?.planStatusId ? (
              <Tag className="mx-2" variant={planStatuses[agreementDetails?.planStatusId || 0].variant}>
                {planStatuses[agreementDetails?.planStatusId || 0].label}
              </Tag>
            ) : null}
          </div>
        </PageContent.Header>

        <RSAAgreement type={AgreementUIType.regular} />
      </PageContent>

      {/* TODO: continue after agreement status sidebar will be done from designs */}
      <Sidebar style={{ marginTop: 88, width: 320 }} />
    </>
  );
};

export default withAgreements(MyPortfolioRSAPlan, { RSAAgreement: true });
