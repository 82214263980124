import { FC } from "react";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.isParticipatingField");

const IsParticipatingField: FC<FormGroupProps> = (props) => {
  const { values, handleChange } = useFormikContext<Pick<ShareClassFormData, "isParticipating">>();

  return (
    <FormGroup controlId="isParticipating" {...props}>
      <InputHeader label={t("label")} />
      <FormCheck
        type="switch"
        className="ps-0"
        label={t(`switch.${+values.isParticipating}`)}
        checked={values.isParticipating}
        onChange={handleChange}
      />
    </FormGroup>
  );
};

export default IsParticipatingField;
