const MultiSelectStyles = {
  control: () => ({
    minHeight: 48,
    width: "100%",
    display: "flex",
    borderRadius: 4,
    border: "2px solid #131313",
  }),
  multiValue: (base: any, state: any) => {
    return {
      ...base,
      height: 32,
      fontSize: 16,
      marginRight: 8,
      display: "flex",
      borderRadius: 20,
      padding: "0 8px",
      alignItem: "center",
      backgroundColor: state.innerProps?.isFirstData ? "#D0DDFF" : "#C4C4C4",
    };
  },
  multiValueLabel: (base: any) => ({
    ...base,
    display: "flex",
    alignItems: "center",
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    marginLeft: 8,
    color: "#131313",
    transition: "all 0.2s",
    transform: "scale(1.1)",
    "&:hover": {
      color: "white",
      backgroundColor: "#59595a",
      transform: "scale(1)",
    },
  }),
  option: (base: any) => ({
    ...base,
    color: "#131313",
    backgroundColor: "white",
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    backgroundColor: "#131313",
  }),
};

export default MultiSelectStyles;
