import { FC, useMemo } from "react";

import { sortCaptableData } from "common/utils/functions";

import { IssueEquityConvertibleLoanDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import { ConvertibleLoanTableSortValue } from "./ConvertibleLoanTable";
import ConvertibleLoanTableRow from "./ConvertibleLoanTableRow";

type BodyProps = {
  data: IssueEquityConvertibleLoanDTO[];
  sortedValue: ConvertibleLoanTableSortValue;
};

const ConvertibleLoanTableBody: FC<BodyProps> = ({ data, sortedValue }) => {
  const sortedData = useMemo(() => {
    return sortCaptableData({
      data: data,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [sortedValue, data]);

  return (
    <tbody className={classes["body"]}>
      {sortedData.map((el) => (
        <ConvertibleLoanTableRow item={el} key={`Key for table inner single row is - ${el.id}`} />
      ))}
    </tbody>
  );
};

export default ConvertibleLoanTableBody;
