import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { useFormikContext } from "formik";
import { and, isEmpty, isNil } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import SlowRequestInfo from "common/components/atoms/SlowRequestInfo/SlowRequestInfo";
import { H, Ui } from "common/components/atoms/Typography";
import { InstrumentTypesIdsEnum, VestingTypesEnum } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { PlanFormType } from "../../../create-plan/steps/basics/sidebar/forms/plan-form";
import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";
import StandaloneMilestones from "./Milestones/Milestones";
import classes from "./Summary.module.scss";
import TimeBaseVestingChart from "./TimeBaseVestingChart/TimeBaseVestingChart";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.summary");

const Summary: FC = () => {
  const { wizardTotalSteps } = useWizard();
  const navigate = useNavigate();
  const fNumber = useFormatNumbers();

  const { values, handleSubmit, setFieldValue, isSubmitting } = useFormikContext<OwnershipPlanForm>();

  const planTypes = useStoreState((state) => state.common.dropdowns)?.instrumentTypes;
  const pools = useStoreState((state) => state.poolModel.companyPools);
  const pool = useStoreState((state) => state.poolModel.pool);
  const loading = useStoreState((state) => state.planModel.isPostPlanLoading);

  const handleStepBack = useCallback(() => {
    if (values.planTypeId === InstrumentTypesIdsEnum.OPTION) {
      navigate(getEMPath(["createOneOffPlan", "exerciseClause"]));
      return;
    }

    navigate(getEMPath(["createOneOffPlan", "vestingConditions"]));
  }, [navigate, values.planTypeId]);

  const handleSaveDraft = useCallback(() => {
    setFieldValue(f.saveAsDraft, true);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  const handleGrantPlan = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const topContent = useMemo(() => {
    const planName = planTypes?.find((plan) => plan.id === values.planTypeId)?.name;
    const poolName = pools?.find((plan) => plan.id === values.poolId)?.name;

    return t.el("description", {
      values: {
        planReceiver: `${values.firstName} ${values.lastName}`,
        planType: planName,
        shares: fNumber(values.numberOfShares, "amount"),
        shareClass: pool?.shareClassName || "-",
        pool: poolName || values.sharesFromStakeholderName,
      },
      components: [<strong key={1} />],
    });
  }, [
    fNumber,
    planTypes,
    pool?.shareClassName,
    pools,
    values.firstName,
    values.lastName,
    values.numberOfShares,
    values.planTypeId,
    values.poolId,
    values.sharesFromStakeholderName,
  ]);

  return (
    <WizardContent.Content step={wizardTotalSteps}>
      <div className={cn("pb-5", classes["info-container"])}>
        <H.xs className="mb-3">{t("title")}</H.xs>

        <Ui.l className="mb-5" style={{ color: scssVariables.foregroundMedium }}>
          {topContent}
        </Ui.l>

        {(values?.vestingTypeId === VestingTypesEnum.COMBINE_VESTING ||
          values?.vestingTypeId === VestingTypesEnum.TIME_VESTING) && <TimeBaseVestingChart />}

        {and(!isNil(values.conditions), !isEmpty(values.conditions)) ? (
          <StandaloneMilestones milestones={values?.conditions} />
        ) : null}
      </div>

      <H.xs className="my-5">Documentation</H.xs>

      <Ui.m color="foregroundMedium">{t("recommendations")}</Ui.m>

      <GeneratePlanDocument
        showReceiverEmailInput
        withSigningManagerEmailField={false}
        managerSignature={!values.existingPlan}
        autoGenerateAgreement={values.planTypeId === InstrumentTypesIdsEnum.OPTION && !values.existingPlan}
        planFormType={PlanFormType.OneOffRSAPlan}
      />

      <SlowRequestInfo isSubmitting={isSubmitting} />

      <div className="d-flex mt-8">
        <WizardContent.Controls.BackButton onClick={handleStepBack} isDisabled={loading} isLoading={loading} />

        <div className="ms-auto">
          <Button
            variant="tertiary"
            className="me-2"
            onClick={handleSaveDraft}
            isDisabled={loading}
            isLoading={loading}
          >
            {t("saveDraft")}
          </Button>

          <Button onClick={handleGrantPlan} isDisabled={loading} isLoading={loading}>
            {!values.sendEmailInvitation
              ? t("grantShareholder")
              : t.el("inviteShareholder", {
                  values: {
                    shareholder: values.firstName,
                  },
                })}
          </Button>
        </div>
      </div>
    </WizardContent.Content>
  );
};

export default Summary;
