import { ChangeEvent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { Button, H, TextField, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import classes from "./ConvertNotePanel.module.scss";
import HelperHint from "./sections/components/HelperHint";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.issueEquity.convertNote.conversion.overrideModal"),
  createTranslation(TranslationNS.validation),
];

const OverrideSharesModal = () => {
  const fNumber = useFormatNumbers();
  const calculatedConvertNote = IssueEquityContext.useStoreState((state) => state.calculateCN);
  const setNoteSharePrice = IssueEquityContext.useStoreActions((actions) => actions.setNoteSharePrice);

  const isModalVisible = IssueEquityContext.useStoreState((state) => state.isOverrideModalVisible);
  const setIsOverrideModalVisible = IssueEquityContext.useStoreActions((actions) => actions.setIsOverrideModalVisible);

  const numberOfNoteShares = IssueEquityContext.useStoreState((state) => state.numberOfNoteShares);
  const setNumberOfNoteShares = IssueEquityContext.useStoreActions((actions) => actions.setNumberOfNoteShares);

  const [value, setValue] = useState<number>(numberOfNoteShares);

  const handleClose = () => {
    setIsOverrideModalVisible(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (Number((e.nativeEvent as any).data) >= 0 && Number((e.nativeEvent as any).data) <= 9) {
      setValue(Math.round(Number(e.target.value)));
    }
  };

  const handleSubmit = () => {
    setNumberOfNoteShares(value);
    handleClose();
    setNoteSharePrice(undefined);
  };

  useEffect(() => {
    setValue(numberOfNoteShares);
  }, [numberOfNoteShares, isModalVisible]);

  return (
    <Modal show={isModalVisible} centered onHide={handleClose} dialogClassName={classes["modal"]}>
      <div className="py-7 px-9">
        <H.xs className="text-center mb-5">{t("title")}</H.xs>

        <Ui.m className="mb-3">{t("descrFirst")}</Ui.m>

        <Ui.m className="mb-3">
          {t.el(calculatedConvertNote?.useDiscount ? "sharePriceWithDiscount" : "sharePriceWithValuationCap", {
            components: [<span className="fw-500" key={1} />, <br key={2} />],
          })}
        </Ui.m>

        <Ui.m>
          {t.el(calculatedConvertNote?.useDiscount ? "numberOfSharesWithDiscount" : "numberOfSharesWithValuationCap", {
            components: [<span className="fw-500" key={1} />, <br key={2} />],
          })}
        </Ui.m>

        <HelperHint className="my-3" />

        <TextField
          min={0}
          isTouched
          value={value}
          type="number"
          className="mb-5"
          label={t("inputLabel")}
          error={
            value < 0
              ? tValidation("minNumber", {
                  number: 0,
                })
              : undefined
          }
          placeholder={fNumber(numberOfNoteShares, "amount")}
          onChange={handleChange}
        />

        <div className="d-flex justify-content-center">
          <Button onClick={handleSubmit} isDisabled={!value || value < 0}>
            {t("update")}
          </Button>

          <Button variant="secondary" className="ms-2" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default OverrideSharesModal;
