import { FC, useCallback, useState } from "react";

import { Button, H, ModalInfo, P, Ui } from "common/components/atoms";
import { CloseIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import InviteStakeholderModal from "../../stakeholders-management/components/invite-stakeholder-modal/invite-stakeholder-modal";
import InvitedStakeholdersIndicator from "../Actual/InvitedStakeholdersIndicator/InvitedStakeholdersIndicator";
import { CapTableVariantsType } from "../CapTable";
import classes from "../CapTable.module.scss";
import CapTableActions from "../common/actions/CapTableActions";
import CapTableStore from "../store";
import Charts from "./Charts/Charts";
import Table from "./Table/Table";

const t = createTranslation(TranslationNS.pages, "company.capTable.actions");

type Props = {
  capTableVariant: CapTableVariantsType;
  setCapTableVariant: (val: CapTableVariantsType) => void;
};
const FullyDiluted: FC<Props> = ({ capTableVariant, setCapTableVariant }) => {
  const [howCalculateModalOpen, setHowCalculateModalOpen] = useState<boolean>(false);
  const setSelectedRowData = CapTableStore.useStoreActions((actions) => actions.setSelectedRowData);

  const handleCalculateModalClose = () => {
    setHowCalculateModalOpen?.(false);
  };

  const handleCloseInviteModal = useCallback(() => {
    setSelectedRowData(undefined);
  }, [setSelectedRowData]);

  return (
    <>
      <Charts className={classes["charts"]} />

      <InvitedStakeholdersIndicator className="mb-3" />

      <CapTableActions
        type={capTableVariant}
        setCapTableVariant={setCapTableVariant}
        className="mb-4"
        setHowCalculateModalOpen={setHowCalculateModalOpen}
      />

      <div>
        <Table className={classes["scrollable-table"]} />
      </div>

      <ModalInfo
        show={howCalculateModalOpen}
        dialogClassName={classes["dialog-modal"]}
        handleClose={handleCalculateModalClose}
        footer={
          <div className="ms-auto">
            <Button variant="tertiary" as="a" href="https://en.unlisted.ai/kontakt-oss" target="_blank">
              {t("calculation.contactUs")}
            </Button>
            <Button className="ms-3" onClick={handleCalculateModalClose}>
              {t("calculation.ok")}
            </Button>
          </div>
        }
      >
        <div className="text-start position-relative">
          <H.l className="mb-2">{t("calculation.title")}</H.l>

          <P.m className="mb-2">{t("calculation.subtitle1")}</P.m>
          <Ui.m className="mb-2 fw-500">{t("calculation.subtitle2")}</Ui.m>

          <ol className="mb-2">
            <li>
              <P.m>{t("calculation.subtitle3")}</P.m>
            </li>
            <li>
              <P.m>{t("calculation.subtitle4")}</P.m>
            </li>
          </ol>

          <P.m className="mb-2">{t("calculation.subtitle5")}</P.m>
          <P.m className="mb-2">{t("calculation.subtitle6")}</P.m>
          <P.m className="mb-2">{t("calculation.subtitle7")}</P.m>

          <CloseIcon fontSize={24} className={classes["close-btn"]} onClick={handleCalculateModalClose} />
        </div>
      </ModalInfo>

      <InviteStakeholderModal onClose={handleCloseInviteModal} />
    </>
  );
};

export default FullyDiluted;
