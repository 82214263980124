import React from "react";
import { Button, Spinner } from "react-bootstrap";

import classes from "./FilledButton.module.scss";

type FilledButtonProps = {
  text: string;
  icon?: string;
  btnWidth?: number;
  disabled?: boolean;
  className?: string;
  textItalic?: string;
  isLoading?: boolean;
  onClick: (event: any) => void;
};

const FilledButton: React.FC<FilledButtonProps> = ({
  text,
  icon,
  className,
  textItalic,
  isLoading,
  btnWidth,
  disabled = false,
  onClick,
}) => {
  const btnStyles = {
    width: btnWidth ? btnWidth : undefined,
    backgroundColor: disabled ? "#EDEEEF" : undefined,
    borderColor: disabled ? "#EDEEEF" : undefined,
  };

  const dataTestAttributes = {
    container: "filled-button-container-test-id",
    icon: "filled-button-icon-test-id",
  };

  const buttonClassnames = `d-flex flex-row btn border-2 ${classes["button-text"]} justify-content-center p-1 ${
    className ? className : ""
  }`;

  if (icon) {
    return (
      <Button
        type="submit"
        variant="info"
        disabled={disabled}
        aria-disabled={disabled}
        data-testid={dataTestAttributes.container}
        className={buttonClassnames}
        style={btnStyles}
        onClick={onClick}
      >
        <div className="me-2">
          <i className={icon} style={{ fontSize: 32 }} data-testid={dataTestAttributes.icon}></i>
        </div>
        <div className="align-center" style={{ alignSelf: "center" }}>
          {text}
          {textItalic && (
            <i className="ms-2" style={{ fontWeight: 100 }}>
              {textItalic}
            </i>
          )}
        </div>
      </Button>
    );
  }

  return (
    <Button
      type="submit"
      variant="info"
      disabled={disabled}
      aria-disabled={disabled}
      data-testid={dataTestAttributes.container}
      className={buttonClassnames}
      style={btnStyles}
      onClick={onClick}
    >
      {isLoading ? (
        <Spinner animation="border" data-testid="spinner-container-test-id" style={{ color: "white" }} />
      ) : (
        <div
          className="align-center"
          style={{
            fontWeight: 500,
            alignSelf: "center",
            color: disabled ? "#6B7176" : "white",
          }}
        >
          {text}
          {textItalic && (
            <i className="ms-2" style={{ fontWeight: 100 }}>
              {textItalic}
            </i>
          )}
        </div>
      )}
    </Button>
  );
};

export default FilledButton;
