import { FC } from "react";
import cn from "classnames";
import { and, equals } from "ramda";

import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";

import { IssueEquityWarrantsDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import { WarrantsTableSortValue } from "./WarrantsTable";

type Props = {
  sortedValue: WarrantsTableSortValue;
  rows: { key: string; value: string; className?: string }[];
  setSortedValue: (e: WarrantsTableSortValue) => void;
};

const WarrantsTableHead: FC<Props> = ({ rows, sortedValue, setSortedValue }) => {
  return (
    <thead className={classes.head}>
      <tr>
        {rows.map((el, i) => {
          return (
            <th
              key={el.key}
              className={cn("cursor-pointer", classes["head-item"])}
              onClick={() => {
                setSortedValue({
                  field: el.key as keyof IssueEquityWarrantsDTO,
                  type:
                    sortedValue.field !== el.key
                      ? sortingParams.inc
                      : sortedValue.type === sortingParams.inc
                      ? sortingParams.desc
                      : sortingParams.inc,
                });
              }}
            >
              <div
                className={cn("d-flex fw-400 align-items-center", {
                  "justify-content-end": i > 0,
                })}
              >
                <Ui.xs>{el.value}</Ui.xs>

                <span
                  className={cn(classes.sort, {
                    [classes.active]: equals(sortedValue.field, el.key),
                  })}
                >
                  <FilledArrowDownIcon
                    style={{
                      transition: "all 0.2s",
                      transform: and(equals(sortedValue.field, el.key), equals(sortedValue.type, sortingParams.inc))
                        ? "rotate(0deg)"
                        : "rotate(180deg)",
                    }}
                  />
                </span>
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default WarrantsTableHead;
