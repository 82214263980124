import { FC, useMemo } from "react";

import { sortCaptableData } from "common/utils/functions";

import { IssueEquityConvertibleNotesDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import { ConvertibleNoteTableSortValue } from "./ConvertibleNoteTable";
import ConvertibleNoteTableRow from "./ConvertibleNoteTableRow";

type BodyProps = {
  data: IssueEquityConvertibleNotesDTO[];
  sortedValue: ConvertibleNoteTableSortValue;
};

const ConvertibleNoteTableBody: FC<BodyProps> = ({ data, sortedValue }) => {
  const sortedData = useMemo(() => {
    return sortCaptableData({
      data: data,
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [sortedValue, data]);

  return (
    <tbody className={classes.body}>
      {sortedData.map((el) => (
        <ConvertibleNoteTableRow item={el} key={`Key for table inner single row is - ${el.id}`} />
      ))}
    </tbody>
  );
};

export default ConvertibleNoteTableBody;
