import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";

import { Dropdown } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CompanyBuildingIcon, UserIcon } from "common/icons/svg";
import { highlightSubstring } from "common/utils/HighliteText";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.shareholderSearchField");

export type SearchStakeholderResult = Partial<{
  stakeholderId: number;
  firstName: string;
  lastName: string;
  isCompanyOwned: boolean;
  companyName: string;
  numberOfShares: number;
}>;

export type SearchStakeholderResponse = {
  results: SearchStakeholderResult[];
};
type ItemElementProps = {
  data: SearchStakeholderResult;
  value: string;
};
const ItemElement: FC<ItemElementProps> = ({ data, value }) => {
  const fNumber = useFormatNumbers();
  return (
    <div className="d-flex justify-content-between" style={{ color: "#6b4d62" }}>
      <span>
        {highlightSubstring(
          `${data.isCompanyOwned ? `${data.companyName} • ` : ""}${data.firstName} ${data.lastName} (${fNumber(
            data.numberOfShares,
            "amount"
          )})`,
          value
        )}
      </span>
      <span>{data.isCompanyOwned ? <CompanyBuildingIcon fontSize={20} /> : <UserIcon fontSize={20} />}</span>
    </div>
  );
};
type SearchField<T extends string> = {
  [key in T]: number;
};

type ShareholderSearchFieldProps = {
  name: string;
  className?: string;
  additionalSelectedName?: string;
};
const PoolShareholderSearchField: FC<ShareholderSearchFieldProps> = ({ name, additionalSelectedName }) => {
  const fNumber = useFormatNumbers();
  const { companyId } = useParams<{ companyId: string }>();

  const [listCompanies, setListCompanies] = useState<{ id: string | number; name: string; element: ReactNode }[]>([]);
  const [fullList, setFullList] = useState<SearchStakeholderResult[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [timestamp, setTimestamp] = useState<boolean>(true);

  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext<SearchField<typeof name>>();

  const fieldValue = values[name];

  const isGetCompanyLoading = false;

  const debounceChange = useMemo(
    () =>
      debounce((value: string) => {
        axios
          .post<SearchStakeholderResponse>("api/equity-management/shareholder/search-having-shares", {
            companyId,
            searchString: value,
          })
          .then((res) => {
            setListCompanies(
              res.data.results.map((data) => ({
                id: data.stakeholderId || "",
                name: `${data.firstName} ${data.lastName} (${fNumber(data.numberOfShares, "amount")})`,
                element: <ItemElement data={data} value={value} />,
              }))
            );
            setFullList(res.data?.results || []);
          })
          .catch((e) => {
            console.log(e);
          })
          .finally(() => {
            setIsSearchLoading(false);
          });
      }, 1000),
    [companyId, fNumber]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      if (value.length < 2) {
        setIsSearchLoading(false);
        setListCompanies([]);
        debounceChange.cancel();
        return;
      }

      setIsSearchLoading(true);
      debounceChange(value);
    },
    [debounceChange]
  );

  const handleBlurAction = useCallback(
    (e: any) => {
      handleBlur(e);
      setTimeout(() => {
        setTimestamp(true);
      }, 100);
    },
    [handleBlur]
  );

  useEffect(() => {
    if (timestamp && fieldValue && !listCompanies.find((el) => el.id === fieldValue)) {
      axios
        .get<SearchStakeholderResult>(`/api/equity-management/shareholder/number-of-shares/${fieldValue}`)
        .then(({ data }) => {
          setTimestamp(false);
          const item = {
            id: data.stakeholderId || "",
            name: `${data.firstName} ${data.lastName} (${fNumber(data.numberOfShares, "amount")})`,
            element: (
              <ItemElement
                data={data}
                value={`${data.firstName} ${data.lastName} (${fNumber(data.numberOfShares, "amount")})`}
              />
            ),
          };
          setListCompanies([item]);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setTimestamp(false);
    }
  }, [fNumber, fieldValue, listCompanies, listCompanies.length, name, timestamp]);

  useEffect(() => {
    if (additionalSelectedName && fieldValue) {
      const stakeholder = fullList.find((el) => el.stakeholderId === +fieldValue);
      if (stakeholder) {
        setFieldValue(
          additionalSelectedName,
          stakeholder.isCompanyOwned
            ? `${stakeholder.companyName} (${stakeholder.firstName} ${stakeholder.lastName})`
            : `${stakeholder.firstName} ${stakeholder.lastName}`
        ).then();
      }
    }
  }, [additionalSelectedName, fieldValue, fullList, setFieldValue, values]);

  return (
    <Dropdown
      label={t("label")}
      placeholder={t("placeholder")}
      isSearchable
      optionsIsObject
      selectedValue={fieldValue}
      name={name}
      options={listCompanies}
      onChange={handleChange}
      onBlur={handleBlurAction}
      isTouched={touched[name]}
      error={errors[name]}
      searchChangeCallback={handleSearchChange}
      isLoading={isSearchLoading}
      isDisabled={isGetCompanyLoading}
      withoutToggleChevron
      showSearchIcon
      isClearable
      filterOff
      isSearchComponent
    />
  );
};

export default PoolShareholderSearchField;
