import { FC, useMemo, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";

import { useStoreState } from "store/store";

import PoolItem from "./PoolItem/PoolItem";

export type PoolsComponent = FC;

const Pools: PoolsComponent = () => {
  const refs = useRef<HTMLDivElement[]>([]);

  const { poolsAndPrograms } = useStoreState((state) => state.company);

  const activePools = useMemo(() => poolsAndPrograms?.pools?.sort((a, b) => a.status - b.status), [poolsAndPrograms]);

  const openedPoolSection = useMemo(() => {
    return activePools?.map((pool) => String(pool.id));
  }, [activePools]);

  const addToRefs = (el: HTMLDivElement) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };

  if (activePools) {
    return (
      <Accordion alwaysOpen defaultActiveKey={openedPoolSection}>
        {activePools?.map((pool) => (
          <PoolItem pool={pool} key={pool.id} ref={addToRefs} />
        ))}
      </Accordion>
    );
  }
};

export default Pools;
