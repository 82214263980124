import { FC } from "react";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";

import { createTranslation } from "translation/helpers";

import Button from "../Button/Button";
import { H } from "../Typography";
import classes from "./DiscardModal.module.scss";

export type DiscardModalProps = {
  isUser?: boolean;
  visible: boolean;
  handleSubmit(): void;
  handleClose(): void;
  handleDiscard(): void;
};

const translation = createTranslation("common", "components.discardModal");

const DiscardModal: FC<DiscardModalProps> = ({ isUser, visible, handleSubmit, handleClose, handleDiscard }) => (
  <Modal
    show={visible}
    onHide={handleClose}
    dialogClassName={classNames(classes["dialog-container"])}
    data-testid="discard-modal-test-id"
  >
    <Modal.Header closeButton className={classNames(classes["modal-header"], "mt-3", "ps-3")}>
      <H.xs>{translation("wantSaveBeforeLeave")}</H.xs>
    </Modal.Header>

    <Modal.Footer className={classNames(classes["modal-body"], "my-2", "ps-3")}>
      <div className={classes["controls"]}>
        <Button data-testid="discard-btn-test-id" className="me-1" variant="secondary" onClick={handleDiscard}>
          {translation("discard")}
        </Button>

        <Button data-testid="save-btn-test-id" onClick={handleSubmit}>
          {isUser ? translation("saveChanges") : translation("saveAsDraft")}
        </Button>
      </div>
    </Modal.Footer>
  </Modal>
);

export default DiscardModal;
