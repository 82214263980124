import { FC, useCallback, useRef } from "react";
import cn from "classnames";
import { and, isEmpty, isNil } from "ramda";

import { CloseIcon, SearchIcon } from "common/icons/svg";

import TextField, { TextFieldProps } from "../TextField/TextField";
import classes from "./SearchField.module.scss";

type PropsTypes = {
  wrapperClassName?: string;
  customClear?: () => void;
} & TextFieldProps;

const SearchField: FC<PropsTypes> = ({ className, value, wrapperClassName, customClear, ...props }) => {
  const ref = useRef<HTMLInputElement>(null);

  const clearInput = useCallback(() => {
    if (customClear) {
      customClear();
      return;
    }

    // @ts-ignore
    props?.onChange?.({ target: { value: "" } });
  }, [customClear, props]);

  return (
    <div className={cn("position-relative d-flex align-items-center", wrapperClassName)}>
      <TextField
        ref={ref}
        value={value}
        iconLeft={<SearchIcon />}
        className={cn(classes["search-input"], className)}
        {...props}
      />

      {and(!isEmpty(value), !isNil(value)) ? (
        <CloseIcon className={classes["input-remove-icon"]} onClick={clearInput} />
      ) : null}
    </div>
  );
};

export default SearchField;
