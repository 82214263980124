import { FC, ReactNode } from "react";
import cn from "classnames";
import { isEmpty, isNil } from "ramda";

import LinkHelpText from "common/components/atoms/LinkHelpText/LinkHelpText";
import { H, Ui } from "common/components/atoms/Typography";
import { InformationCircleIcon, QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./agreement-summary.module.scss";

export type Item = {
  title?: string | ReactNode;
  description: string;
  content: string;
};

type PropsTypes = {
  terminationItems?: Item[] | null;
};

const t = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

const PostTerminationClause: FC<PropsTypes> = ({ terminationItems }) => {
  return (
    <div>
      <div className={cn("d-flex", classes["container"])}>
        <H.xxs>{t("SO.postTerminationTitle")}</H.xxs>
        <LinkHelpText title={t("SO.postTerminationTitle")} content={t("infoWidget.SO.postTermination")}>
          <InformationCircleIcon />
        </LinkHelpText>
      </div>

      <div
        className={cn(classes["content"], {
          "py-0": isNil(terminationItems) || isEmpty(terminationItems),
        })}
      >
        {isNil(terminationItems) || isEmpty(terminationItems) ? (
          <div>
            <Ui.m style={{ color: scssVariables.foregroundMedium }}>{t("exercisingAfterTerminationProhibited")}</Ui.m>
          </div>
        ) : (
          terminationItems?.map((item, index) => (
            <div className={cn(classes["single"], "text-truncate")} key={index}>
              <div className="d-flex flex-column">
                <Ui.m className="text-truncate">{item.title || "-"}</Ui.m>
                <Ui.xs style={{ color: scssVariables.foregroundLow }}>{item.description}</Ui.xs>
              </div>
              <LinkHelpText title={t("infoWidget.title", { infoTitle: item.description })} content={item.content}>
                <QuestionCircleIcon className={classes["question"]} />
              </LinkHelpText>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
export default PostTerminationClause;
