import { FC, useCallback, useMemo } from "react";
import { Form } from "react-bootstrap";
import axios from "axios";
import { FormikConfig, useFormik } from "formik";
import * as Yup from "yup";

import { Button, DatePicker, ModalInfo, TextField } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { useStoreActions } from "../../../../../../store/store";

const tv = createTranslation(TranslationNS.validation);

type TaxableValueModalProps = {
  companyId?: string;
  show: boolean;
  handleClose: () => void;
};
const TaxableValueModal: FC<TaxableValueModalProps> = ({ companyId, show, handleClose }) => {
  return (
    <ModalInfo header="Update taxable value" show={show}>
      <TaxableValueForm companyId={companyId} handleClose={handleClose} />
    </ModalInfo>
  );
};

type TaxableValueFormProps = {
  companyId?: string;
  handleClose: () => void;
};
const TaxableValueForm: FC<TaxableValueFormProps> = ({ companyId, handleClose }) => {
  const { getValuationThunk } = useStoreActions((actions) => actions.company);

  type ValuesForm = {
    taxableValue?: number;
    valuationDate: string;
  };

  const handleSubmitValuation = useCallback<FormikConfig<ValuesForm>["onSubmit"]>(
    async (values) => {
      try {
        await axios.post("/api/company/valuation/taxable-value", { companyId, ...values });
        getValuationThunk(Number(companyId));
        handleClose();
      } catch (error) {
        console.log(error);
      }
    },
    [companyId, getValuationThunk, handleClose]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        taxableValue: Yup.number()
          .required(tv("required"))
          .min(0.000001, tv("minNumber", { number: "0.000001" })),
        valuationDate: Yup.string().required(tv("required")),
      }),
    []
  );

  const { values, handleChange, handleBlur, errors, touched, handleSubmit, setFieldValue, isSubmitting } =
    useFormik<ValuesForm>({
      initialValues: {
        taxableValue: undefined,
        valuationDate: "",
      },
      onSubmit: handleSubmitValuation,
      validationSchema,
    });

  const handleDateChange = useCallback(
    (date: Date | string, name?: string) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  return (
    <div className="w-75 m-auto">
      <TextField
        className="mt-2"
        name="taxableValue"
        label="Enter taxable value per share"
        type="number"
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.taxableValue}
        error={errors.taxableValue}
        isTouched={touched.taxableValue}
      />
      <DatePicker
        className="mt-4"
        name="valuationDate"
        label="Registration date"
        date={values.valuationDate}
        onChange={handleDateChange}
        onBlur={handleBlur}
        error={errors.valuationDate}
        isTouched={touched.valuationDate}
        isDateOnlyString
      />
      <div className="mt-5">
        <Form onSubmit={handleSubmit}>
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="primary" type="submit">
            Update Value
          </Button>
          <Button onClick={handleClose} isDisabled={isSubmitting} className="ms-2" variant="secondary">
            Cancel
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default TaxableValueModal;
