import { useCallback, useMemo, useState } from "react";
import axios from "axios";
import * as Yup from "yup";

import { fields } from "common/components/molecules/StakeholderSection/Stakeholder.types";
import { createTranslation, TranslationNS } from "translation";

import { EditStakeholderDTO, GetStakeholderDTO, GetStakeholdersDTO, PostStakeholderDTO } from "./types";

const apiBase = "/api/ownership/stakeholder";

const tValidation = createTranslation(TranslationNS.validation);

export const useStakeholdersService = (companyId: string) => {
  const [stakeholderToEdit, setStakeholderToEdit] = useState<GetStakeholderDTO | null>(null);
  const [isStakeholdersLoading, setIsStakeholdersLoading] = useState<boolean>(true);
  const [selectedContactIndex, setSelectedContactIndex] = useState<number | undefined>();

  const formInitialValues: (PostStakeholderDTO | EditStakeholderDTO) &
    Record<"addContactFormOpen", boolean> &
    Record<"editingContact", boolean> &
    Record<"internalFormSubmitted", boolean> = useMemo(
    () =>
      stakeholderToEdit
        ? {
            ...stakeholderToEdit,
            [fields.companyName]: stakeholderToEdit.companyName || "",
            [fields.organizationNumber]: stakeholderToEdit.organizationNumber || "",
            companyId: +companyId,
            contacts: stakeholderToEdit?.contacts || [],
            addContactFormOpen: false,
            editingContact: false,
            internalFormSubmitted: false,
          }
        : {
            companyId: +companyId,
            [fields.firstName]: "",
            [fields.lastName]: "",
            [fields.dateOfBirth]: "",
            [fields.address]: "",
            [fields.countryId]: undefined,
            [fields.isCompanyOwned]: false,
            [fields.companyName]: "",
            [fields.organizationNumber]: "",
            [fields.businessPostAddress]: "",
            [fields.email]: "",
            [fields.phoneNumber]: "",
            [fields.relationshipTypeId]: 1,
            contacts: [],
            addContactFormOpen: false,
            editingContact: false,
            internalFormSubmitted: false,
          },
    [companyId, stakeholderToEdit]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [fields.email]: Yup.string().email(tValidation("invalidEmail")).nullable(),
        [fields.relationshipTypeId]: Yup.number().required(tValidation("required")),
        [fields.firstName]: Yup.string()
          .required(tValidation("required"))
          .max(100, tValidation("maxCharacters", { number: "100" })),
        [fields.lastName]: Yup.string()
          .required(tValidation("required"))
          .max(100, tValidation("maxCharacters", { number: "100" })),
        [fields.isCompanyOwned]: Yup.boolean(),
        [fields.companyName]: Yup.string()
          .nullable(true)
          .when("isCompanyOwned", {
            is: true,
            then: Yup.string()
              .nullable(false)
              .required(tValidation("required"))
              .min(3, tValidation("minimumCharacters", { number: "3" }))
              .max(100, tValidation("maxCharacters", { number: "100" })),
          }),
        [fields.organizationNumber]: Yup.string().when("isCompanyOwned", {
          is: false,
          then: Yup.string().nullable(true),
        }),
        contacts: Yup.array().when("addContactFormOpen", {
          is: true,
          then: Yup.array().of(
            Yup.object().shape({
              [fields.email]: Yup.string().email(tValidation("invalidEmail")).nullable(),
              [fields.firstName]: Yup.string()
                .required(tValidation("required"))
                .max(100, tValidation("maxCharacters", { number: "100" })),
              [fields.lastName]: Yup.string()
                .required(tValidation("required"))
                .max(100, tValidation("maxCharacters", { number: "100" })),
            })
          ),
        }),
      }),
    []
  );

  const getStakeholdersRequest = useCallback(async () => {
    setIsStakeholdersLoading(true);
    try {
      const response = await axios.get<GetStakeholdersDTO>(`${apiBase}/${companyId}`);
      if (response.status === 200) return response.data;
      return [];
    } catch (e) {
      console.log(e);
      return [];
    } finally {
      setIsStakeholdersLoading(false);
    }
  }, [companyId]);

  const getStakeholderRequest = useCallback(async (stakeholderId: number) => {
    try {
      const response = await axios.get<GetStakeholderDTO>(`${apiBase}/details/${stakeholderId}`);
      if (response.status === 200) {
        setStakeholderToEdit(response.data);
        return response.data;
      }
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, []);

  const postStakeholderRequest = useCallback(async (stakeholder: PostStakeholderDTO) => {
    try {
      const response = await axios.post<{ stakeholderId: number }>("/api/ownership/stakeholder/stakeholders-page-form", stakeholder);
      if (response.status === 200) return response.data.stakeholderId;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  // Same endpoint as POST, provide stakeholderId field to edit
  const editStakeholderRequest = useCallback(async (stakeholder: EditStakeholderDTO) => {
    try {
      const response = await axios.post("/api/ownership/stakeholder/stakeholders-page-form", stakeholder);
      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  const removeStakeholderRequest = useCallback(async (id: number) => {
    try {
      const response = await axios.delete(`/api/ownership/stakeholder/${id}`);
      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  return {
    getStakeholdersRequest,
    getStakeholderRequest,
    postStakeholderRequest,
    editStakeholderRequest,
    validationSchema,
    formInitialValues,
    isStakeholdersLoading,
    removeStakeholderRequest,

    selectedContactIndex,
    setSelectedContactIndex,
  };
};
