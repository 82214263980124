import { FC } from "react";

import { GetStakeholdersDTO } from "../../types";
import StakeholderCard from "./stakeholder-card/stakeholder-card";

type PropsTypes = {
  stakeholders: GetStakeholdersDTO;
  handleOpenEditSidebar: (id: number) => void;
  handleRemoveStakeholder?: (value: number) => void;
  handleOpenDetailModal?: (stakeholderId: number) => void;
};

const CardView: FC<PropsTypes> = ({
  stakeholders,
  handleRemoveStakeholder,
  handleOpenEditSidebar,
  handleOpenDetailModal,
}) => {
  return (
    <div className="d-flex flex-wrap gap-4">
      {stakeholders.map((stakeholder) => (
        <StakeholderCard
          key={stakeholder.stakeholderId}
          stakeholder={stakeholder}
          onEdit={handleOpenEditSidebar}
          onClick={handleOpenDetailModal}
          handleRemoveStakeholder={handleRemoveStakeholder}
        />
      ))}
    </div>
  );
};

export default CardView;
