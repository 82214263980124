import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import authProvider, { loginRequest } from "common/authentication/authProvider";
import { useStoreState } from "store/store";

const AutoSignInPage: React.FC = () => {
  const navigate = useNavigate();
  const msalInstance = useRef<any>(null);

  const instanceFinishedLoading = useStoreState((state) => state.authentication.localCacheChecked);
  const authenticated = useStoreState((state) => state.authentication.isAuthenticated);

  useEffect(() => {
    if (!authenticated && instanceFinishedLoading) {
      if (!msalInstance.current) {
        msalInstance.current = authProvider;
        msalInstance.current.loginRedirect(loginRequest);
      }
    } else if (authenticated) {
      navigate("/");
    }
  }, [authenticated, instanceFinishedLoading, navigate]);

  return <div></div>;
};

export default AutoSignInPage;
