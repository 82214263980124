import { Action, action } from "easy-peasy";

export interface AfterSignupModel {
  pageForRedirect: string | null;
  wasBookAMeetingClicked: boolean;

  setPageForRedirect: Action<this, string | null>;
  setBookAMeetingClicked: Action<this, boolean>;
}

export const afterSignup: AfterSignupModel = {
  pageForRedirect: null,
  wasBookAMeetingClicked: false,

  setPageForRedirect: action((state, payload) => {
    state.pageForRedirect = payload;
  }),
  setBookAMeetingClicked: action((state, payload) => {
    state.wasBookAMeetingClicked = payload;
  }),
};
