import { FC, useCallback } from "react";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { isNil } from "ramda";

import { P, Ui } from "common/components/atoms";
import Helper from "common/components/atoms/Helper/Helper";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { CreateProgramFormData } from "pages/equity-management/plans/wizards/create-program/useProgramForm";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { AvailableAmountAgreementsType } from "../../../../types/Common.types";
import classes from "./VestingDetails.module.scss";

const t = createTranslation(TranslationNS.common, "program.components.programForm.vestingDetails.vestingTypeId");

const VestingTypeField: FC = () => {
  const vestingTypesStore = useStoreState((state) => state.common)?.dropdowns?.vestingTypes || [];

  const { values, setFieldValue } = useFormikContext<
    Pick<CreateProgramFormData, "vestingTypeId"> &
      Partial<Record<"subscriptionAvailable", AvailableAmountAgreementsType>>
  >();

  const isFormDisabled = !isNil(values.subscriptionAvailable) && !values.subscriptionAvailable.remainingAgreements;

  const handleChange = useCallback(
    (e: any) => {
      setFieldValue(e.target.name, +e.target.value);
    },
    [setFieldValue]
  );

  return (
    <>
      <Helper.Question questionId="vestingTypeId">
        <InputHeader label={t("label")}></InputHeader>
      </Helper.Question>
      <Helper.Answer
        className="mt-1 mb-1"
        answerId="vestingTypeId"
        text={t("answer")}
        linkText={t("readMore")}
        link="/"
      />
      {vestingTypesStore.map((el) => (
        <div
          className={classNames(classes.vestingType, { [classes.selected]: values.vestingTypeId === el.id })}
          key={el.id}
        >
          <Form.Check className={classes.vestingTypeRadio} type="radio" id={`vestType_${el.id}`}>
            <Form.Check.Input
              type="radio"
              name="vestingTypeId"
              value={el.id}
              onChange={handleChange}
              checked={values.vestingTypeId === el.id}
              disabled={isFormDisabled}
            />
            <div>
              <Form.Check.Label>
                <Ui.m tag="span" color="foregroundHigh" style={{ fontWeight: 500 }}>
                  {el.name}
                </Ui.m>
              </Form.Check.Label>
              <P.s color="foregroundLow" style={{ marginTop: 2, cursor: "default" }}>
                {el.description}
              </P.s>
            </div>
          </Form.Check>
        </div>
      ))}
    </>
  );
};

export default VestingTypeField;
