import { FC } from "react";
import { Modal as BModal } from "react-bootstrap";

import { createTranslation, TranslationNS } from "translation";

import { Button, H, P } from "../index";

type PropsTypes = {
  isOpen: boolean;
  handleSubmit(): void;
  handleClose(): void;
  isLoading?: boolean;
};

const t = createTranslation(TranslationNS.common, "components.stakeholdersLimitOnboardingModal");

const Modal: FC<PropsTypes> = ({ isOpen, handleSubmit, handleClose, isLoading }) => {
  return (
    <BModal
      show={isOpen}
      onHide={handleClose}
      centered
      contentClassName="d-flex flex-column align-items-center text-center p-5"
    >
      <H.xs className="mb-3">{t("title")}</H.xs>
      <P.m className="mb-5">{t("content")}</P.m>
      <div>
        <Button className="me-2" onClick={handleSubmit} isLoading={isLoading}>
          {t("submit")}
        </Button>
        <Button variant="secondary" onClick={handleClose} isDisabled={isLoading}>
          {t("cancel")}
        </Button>
      </div>
    </BModal>
  );
};

const StakeholdersLimitSubmitModal: FC<PropsTypes> = (props) => {
  if (props.isOpen) {
    return <Modal {...props} />;
  }
};

export default StakeholdersLimitSubmitModal;
