import axios, { AxiosResponse } from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { ProgramDocumentFileDTO } from "store/modelTypes/programModel.types";
import { InvestmentEntity } from "store/types";

import { Stakeholder } from "../components/receive.types";

export type WarrantSettlementDetailsDTO = {
  accountCompanyName: string;
  accountNumber: number;
  amount: number;
  companyId: number;
  companyName: string;
  documentFiles: ProgramDocumentFileDTO[];
  documentsNeedsSignature: boolean;
  expirationDate: string;
  id: number;
  investmentEntityId: number;
  investmentEntityName: string;
  message: string;
  notablePoints: string;
  numberOfShares: number;
  organisationNumber: string;
  shareClass: string;
  shareClassComment: string;
  shareClassDecided: boolean;
  strikePrice: number;
  swiftBic: string;
  usingHoldingCompany: boolean;
  warrantPrice: number;
  totalPrice: number;
  stakeholder: Stakeholder;
};

export type AcceptWarrant = {
  signature?: File;
  approvalKey: string;
};

interface WarrantsSettlementWizardContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  companyName: string;
  setCompanyName: Action<this, this["companyName"]>;

  isRemoteCompanySelected: boolean;
  setIsRemoteCompanySelected: Action<this, this["isRemoteCompanySelected"]>;

  companyOrgNumber: string;
  setCompanyOrgNumber: Action<this, this["companyOrgNumber"]>;

  selectedCompany: InvestmentEntity | null;
  setSelectedCompany: Action<this, this["selectedCompany"]>;

  isHoldingCompanySelected: boolean;
  setIsHoldingCompanySelected: Action<this, this["isHoldingCompanySelected"]>;

  signature: File | null;
  setSignature: Action<this, this["signature"]>;

  warrantSettlementDetails: null | WarrantSettlementDetailsDTO;
  setWarrantSettlementDetailsDetails: Action<this, this["warrantSettlementDetails"]>;
  getWarrantSettlementDetailsDetailsThunk: Thunk<
    this,
    string,
    {},
    {},
    Promise<AxiosResponse<WarrantSettlementDetailsDTO>>
  >;
  acceptWarrantSettlementDetailsThunk: Thunk<this, AcceptWarrant>;
  declineWarrantThunk: Thunk<this, { companyId: number; warrantId: number }>;
}

const WarrantsSettlementWizardContext = createContextStore<WarrantsSettlementWizardContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    companyName: "",
    setCompanyName: action((state, payload) => {
      state.companyName = payload;
    }),

    selectedCompany: null,
    setSelectedCompany: action((state, payload) => {
      state.selectedCompany = payload;
    }),

    isRemoteCompanySelected: false,
    setIsRemoteCompanySelected: action((state, payload) => {
      state.isRemoteCompanySelected = payload;
    }),

    companyOrgNumber: "",
    setCompanyOrgNumber: action((state, payload) => {
      state.companyOrgNumber = payload;
    }),

    isHoldingCompanySelected: false,
    setIsHoldingCompanySelected: action((state, payload) => {
      state.isHoldingCompanySelected = payload;
    }),

    signature: null,
    setSignature: action((state, payload) => {
      state.signature = payload;
    }),

    warrantSettlementDetails: null,
    setWarrantSettlementDetailsDetails: action((state, payload) => {
      state.warrantSettlementDetails = payload;
    }),
    getWarrantSettlementDetailsDetailsThunk: thunk(async (actions, key) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<WarrantSettlementDetailsDTO>(
          `/api/equity-management/issue-equity/warrant/details-settlement/${key}`
        );

        if (request.status === 200) {
          actions.setWarrantSettlementDetailsDetails(request.data);
          actions.setIsHoldingCompanySelected(request.data.usingHoldingCompany);
        }

        return request;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        actions.setIsLoading(false);
      }
    }),
    acceptWarrantSettlementDetailsThunk: thunk(async (_, payload) => {
      try {
        const formData = new FormData();
        formData.append("approvalKey", String(payload.approvalKey));
        if (payload.signature) formData.append("signature", payload.signature);

        return await axios.post("/api/equity-management/issue-equity/warrant/accept-warrant-settlement", formData);
      } catch (e) {
        return Promise.reject(e);
      }
    }),

    declineWarrantThunk: thunk(async (actions, payload) => {
      try {
        const formData = new FormData();
        formData.append("CompanyId", String(payload.companyId));
        formData.append("WarrantId", String(payload.warrantId));

        const request = await axios.post("/api/equity-management/issue-equity/warrant/cancel", formData);

        if (request.status === 200) {
          return true;
        }
        return request;
      } catch (e) {
        return Promise.reject(e);
      }
    }),
  },
  {
    name: "Receive warrant context",
  }
);

export default WarrantsSettlementWizardContext;
