import { FC, useCallback } from "react";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";
import { isEmpty } from "ramda";

import DatePicker, { DatePickerProps } from "common/components/atoms/DatePicker/DatePicker";
import { fields, PoolFormValues } from "pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { createTranslation, TranslationNS } from "translation";

export type ExpiryDateFieldProps = FormGroupProps;

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.expiryDateField");

const PoolExpiryDateField: FC<ExpiryDateFieldProps> = (props) => {
  const { values, setFieldValue, errors, touched, handleBlur } = useFormikContext<Pick<PoolFormValues, "expiryDate">>();

  const handleChange = useCallback<DatePickerProps["onChange"]>(
    (date) => {
      setFieldValue(fields.expiryDate, isEmpty(date) ? undefined : date);
    },
    [setFieldValue]
  );

  return (
    <FormGroup controlId="expiryDate" {...props}>
      <DatePicker
        isDateOnlyString
        label={t("label")}
        date={values?.expiryDate}
        className="w-50 me-1"
        name={fields.expiryDate}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.expiryDate}
        isTouched={touched.expiryDate}
        isClearable
        isOptional
      />
    </FormGroup>
  );
};

export default PoolExpiryDateField;
