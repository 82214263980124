import { FC, useEffect, useMemo, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { Image } from "react-bootstrap";
import debounce from "lodash.debounce";

import FileUploadButton from "../FileUploadButton/FileUploadButton";
import DefaultImage from "../FileUploadSection/DefaultImage/DefaultImage";
import InputFeedback from "../input-blocks/input-feedback/input-feedback";
import { Ui } from "../Typography";

const AvatarEditorUpdated = AvatarEditor as unknown as any;

type PropsType = {
  buttonText: string;
  descriptionText: string;
  defaultPreviewImageType: "profile" | "company-logo";
  setFileToUpload: (value: File | undefined) => void;
  circularImage?: boolean;
  previewUrl?: string;
  width?: number;
  height?: number;
};

// This component based on FileUploadSection component

const AvatarUploader: FC<PropsType> = ({
  buttonText,
  descriptionText,
  previewUrl,
  setFileToUpload,
  defaultPreviewImageType,
  width = 100,
  height = 100,
  circularImage,
}) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [error, setError] = useState("");

  const editorRef = useRef(null);

  const imageChangeHandler = useMemo(
    () =>
      debounce(
        () => {
          // @ts-ignore
          editorRef.current?.canvas.toBlob((blob) => {
            const editedImageFile = new File([blob], imageFile?.name || "", {
              type: imageFile?.type,
            });

            setFileToUpload(editedImageFile);
          });
        },
        300,
        { maxWait: 600 }
      ),
    [imageFile, setFileToUpload]
  );

  useEffect(() => {
    setImageFile(null);
  }, [previewUrl]);

  useEffect(() => {
    return () => {
      imageChangeHandler.cancel();
    };
  }, [imageChangeHandler]);

  useEffect(() => {
    if (error) {
      setImageFile(null);
      setFileToUpload(undefined);
    }
  }, [error, setFileToUpload, setImageFile]);

  return (
    <>
      <div className="d-flex flex-row">
        {imageFile && (
          <AvatarEditorUpdated
            ref={editorRef}
            width={width}
            height={height}
            borderRadius={circularImage ? 150 : 0}
            border={0}
            scale={1}
            rotate={0}
            position={imagePosition}
            onPositionChange={setImagePosition}
            onImageChange={imageChangeHandler}
            onImageReady={imageChangeHandler}
            image={imageFile || ""}
          />
        )}
        {!imageFile && previewUrl && (
          <Image
            src={previewUrl}
            width={width}
            height={height}
            roundedCircle={circularImage}
            alt={defaultPreviewImageType}
          />
        )}
        {!imageFile && !previewUrl && (
          <DefaultImage defaultImageType={defaultPreviewImageType} imageWidth={width} imageHeight={height} />
        )}

        <div className="d-flex flex-column mx-4">
          <FileUploadButton label={buttonText} onSelectedFile={setImageFile} setError={setError} />
          <Ui.xs className="mt-2">{descriptionText}</Ui.xs>
        </div>
      </div>

      {error ? <InputFeedback isTouched error={error} /> : null}
    </>
  );
};

export default AvatarUploader;
