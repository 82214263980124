import { FC } from "react";
import { ComputedDatum } from "@nivo/pie";
import cn from "classnames";

import useFormatNumbers from "common/hooks/useFormatNumbers";

import classes from "./Charts.module.scss";

export type PieDataCustom = {
  id: string;
  value: number;
  color: string;
  amount: number;
  label: string;
};

type PropsTypes = {
  pieTooltipData: ComputedDatum<PieDataCustom>;
};

const ShareholdersTooltip: FC<PropsTypes> = ({ pieTooltipData }) => {
  const fNumber = useFormatNumbers();

  const text = `${pieTooltipData.data.label}: ${fNumber(pieTooltipData.data.amount, "amount")} shares (${fNumber(
    pieTooltipData.data.value,
    "percent"
  )})`;

  const width = text.length * 8;

  return (
    <div className={classes["chart-tooltip"]} style={{ width: width }}>
      <div className="d-flex">
        <div
          className={cn(classes["circle"], "me-1")}
          style={{ marginTop: 2, backgroundColor: pieTooltipData.data.color }}
        />
        <p className="m-0 ui-xs">{pieTooltipData.data.label}: </p>
        <p className="m-0 ui-xs fw-bold ms-1">
          {fNumber(pieTooltipData.data.amount, "amount")} shares ({fNumber(pieTooltipData.data.value, "percent")})
        </p>
      </div>
    </div>
  );
};

export default ShareholdersTooltip;
