import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Tag from "common/components/atoms/Tag/Tag";
import ToggleButton from "common/components/atoms/ToggleButton/ToggleButton";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { ViewCardsIcon, ViewListIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { createTranslation, TranslationNS } from "translation";

import CardView from "./card-view/card-view";
import EditUserAccessSidebar from "./sidebars/edit-user-access-sidebar/edit-user-access-sidebar";
import InviteUserSidebar from "./sidebars/invite-user-sidebar/invite-user-sidebar";
import TableView from "./table-view/table-view";
import { AccessControlUserDTO, EditUserAccessGetDTO, FeaturesListGetDTO } from "./types";

type ViewModeOptions = "Table" | "Cards";

export const apiBase = "/api/company/user-access";

const t = createTranslation(TranslationNS.pages, "companySettings.accessControlPage");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const AccessControl: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId } = useParams<{ companyId: string }>();

  const [users, setUsers] = useState<AccessControlUserDTO[]>([]);
  const [userToEdit, setUserToEdit] = useState<EditUserAccessGetDTO | null>(null);
  const [features, setFeatures] = useState<FeaturesListGetDTO>({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedViewMode, setSelectedViewMode] = useState<ViewModeOptions>("Cards");
  const [selectedSort, setSelectedSort] = useState(t("sort.notSelected"));
  const [isInviteSidebarOpen, setIsInviteSidebarOpen] = useState(false);
  const [isEditAccessSidebarOpen, setIsEditAccessSidebarOpen] = useState(false);

  const { hasFullAccess } = useFeatures(FEATURES.userAccessManagement);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${apiBase}/users/${companyId}`);
        if (response.status === 200) setUsers(response.data);
      } catch (e) {
        console.log(e);
      }
    };

    const fetchFeatures = async () => {
      try {
        const response = await axios.get<FeaturesListGetDTO>(`${apiBase}/all-features`);
        if (response.status === 200) setFeatures(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    setIsLoading(true);
    Promise.all([fetchUsers(), fetchFeatures()]).finally(() => setIsLoading(false));
  }, [companyId]);

  const handleOpenInviteSidebar = useCallback(() => setIsInviteSidebarOpen(true), [setIsInviteSidebarOpen]);
  const handleCloseInviteSidebar = useCallback(() => setIsInviteSidebarOpen(false), [setIsInviteSidebarOpen]);

  const handleOpenEditAccessSidebar = useCallback(
    async (id: number) => {
      try {
        const response = await axios.get<EditUserAccessGetDTO>(`${apiBase}/user/${id}`);
        if (response.status === 200) {
          setUserToEdit(response.data);
          setIsEditAccessSidebarOpen(true);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [setIsEditAccessSidebarOpen]
  );

  const handleCloseEditAccessSidebar = useCallback(() => {
    setUserToEdit(null);
    setIsEditAccessSidebarOpen(false);
  }, [setIsEditAccessSidebarOpen]);

  const handleChangeSort = useCallback((selectedSort: string) => {
    setSelectedSort(selectedSort);
  }, []);

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${apiBase}/users/${companyId}`);
      if (response.status === 200) setUsers(response.data);
    } catch (e) {
      console.log(e);
    }
  }, [companyId]);

  const isSelectedUserLastAdmin = useMemo(() => {
    if (!userToEdit?.isAdmin) return false;

    const adminsInCompany = users.filter((user) => user.isAdmin && user.hasAcceptedInvite);

    return adminsInCompany.length <= 1;
  }, [userToEdit?.isAdmin, users]);

  const sortOptions = useMemo(
    () => [t("sort.notSelected"), t("sort.role"), t("sort.firstName"), t("sort.lastName"), t("sort.email")],
    []
  );

  const sortedUsers = useMemo(() => {
    if (selectedSort === t("sort.notSelected")) {
      return users;
    }

    if (selectedSort === t("sort.role")) {
      return [...users].sort((a, b) => {
        if (a.isAdmin === b.isAdmin) {
          // @ts-ignore
          return b.hasAcceptedInvite - a.hasAcceptedInvite;
        }
        // @ts-ignore
        return b.isAdmin - a.isAdmin;
      });
    }

    if (selectedSort === t("sort.firstName")) {
      return [...users].sort((a, b) => {
        return a.firstName.localeCompare(b.firstName);
      });
    }
    if (selectedSort === t("sort.lastName")) {
      return [...users].sort((a, b) => {
        return a.lastName.localeCompare(b.lastName);
      });
    }
    if (selectedSort === t("sort.email")) {
      return [...users].sort((a, b) => {
        return a.email.localeCompare(b.email);
      });
    }

    return users;
  }, [selectedSort, users]);

  return (
    <PageContent data-testid="access-control-page-test-id">
      <PageContent.Header>
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>

        <Button
          onClick={handleOpenInviteSidebar}
          isDisabled={!hasFullAccess}
          data-testid="invite-btn"
          tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
          tooltipMessage={tCommon("tooltip")}
        >
          {t("inviteBtn")}
        </Button>
      </PageContent.Header>
      <div className="d-flex justify-content-between align-items-center">
        <Dropdown
          label={t("sort.sortBy")}
          options={sortOptions}
          onChange={handleChangeSort}
          selectedValue={selectedSort}
          className="mb-4"
          style={{ width: "300px" }}
        />
        <ToggleButton
          firstVariant={{ title: <ViewCardsIcon />, value: "Cards" }}
          secondVariant={{
            title: <ViewListIcon />,
            value: "Table",
          }}
          selected={selectedViewMode}
          onClick={(selectedValue) => setSelectedViewMode(selectedValue as ViewModeOptions)}
          dataTestId="toggle-view-btn"
        />
      </div>

      <LoaderContainer loading={isLoading}>
        {selectedViewMode === "Cards" && (
          <CardView users={sortedUsers} handleOpenEditAccessSidebar={handleOpenEditAccessSidebar} />
        )}
        {selectedViewMode === "Table" && (
          <TableView users={sortedUsers} handleOpenEditAccessSidebar={handleOpenEditAccessSidebar} />
        )}
      </LoaderContainer>

      {/* Prevent creation of initial values before features are pulled from API */}
      {isInviteSidebarOpen && (
        <InviteUserSidebar
          isOpen={isInviteSidebarOpen}
          onSubmit={fetchUsers}
          onClose={handleCloseInviteSidebar}
          features={features}
        />
      )}

      {userToEdit && (
        <EditUserAccessSidebar
          isOpen={isEditAccessSidebarOpen}
          onSubmit={fetchUsers}
          onClose={handleCloseEditAccessSidebar}
          isLastAdmin={isSelectedUserLastAdmin}
          userToEdit={userToEdit}
        />
      )}
    </PageContent>
  );
};

export default AccessControl;
