import { FC, useEffect } from "react";
import { toast } from "react-toastify";

import Message, { MessageProps } from "./Message";

export type UnfinishedChangesProviderProps = MessageProps & {
  localStoreKey: string
}

const UnfinishedChangesProvider: FC<UnfinishedChangesProviderProps> = (
  { localStoreKey, message, onContinue, onDiscard, children },
) => {
  useEffect(() => {
    const localStoreItem = localStorage.getItem(localStoreKey);

    if (localStoreItem) {
      const toastId = toast.info(<Message message={message} onContinue={onContinue} onDiscard={onDiscard} />, {
        autoClose: false,
      });

      return () => {
        toast.dismiss(toastId);
      };
    }
  }, [localStoreKey, message, onContinue, onDiscard]);

  return <>{children}</>;
};

export default UnfinishedChangesProvider;
