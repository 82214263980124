import { FC } from "react";

import Helper from "common/components/atoms/Helper/Helper";
import PeriodPickerFormik from "common/components/atoms/PeriodPickerFormik/PeriodPickerFormik";
import { createTranslation, TranslationNS } from "translation";

import { fields } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.sharesDetails");
const periodVariants = ["Year", "Month", "Day"];
const ProgramOptionsExpirationDateFields: FC = () => {
  return (
    <Helper.Question questionId="optionsExpiration" type="input" answer={t("optionsExpiration.answer")}>
      <PeriodPickerFormik
        label={t("optionsExpiration.label")}
        periodVariants={periodVariants}
        nameAmount={fields.optionsExpiration}
        namePeriod={fields.optionsExpirationTimeUnit}
      />
    </Helper.Question>
  );
};
export default ProgramOptionsExpirationDateFields;
