import { FC, SyntheticEvent, useCallback, useMemo, useState } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, H, ModalInfo, NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CalendarIcon, EmailActionSend, MenuTabBarVerticalIcon, RemoveIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext, { IExercisingItem } from "../../ExercisingContext";
import ExercisingCollapsable from "../ExercisingCollapsable/ExercisingCollapsable";
import classes from "../styles.module.scss";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.exercising.waitingForPayment"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const WaitingForPayment: FC<{ companyId: number }> = ({ companyId }) => {
  const fNumber = useFormatNumbers();
  const { hasFullAccess } = useFeatures(FEATURES.exercise);

  const [remindAll, setRemindAll] = useState(false);
  const [remindSingle, setRemindSingle] = useState<IExercisingItem | null>(null);
  const [markAsPaid, setMarkAsPaid] = useState<IExercisingItem | null>(null);

  const waitingForPayment = ExercisingContext.useStoreState((state) => state.waitingForPayment);
  const requestLoading = ExercisingContext.useStoreState((state) => state.requestLoading);
  const missingBankInformation = ExercisingContext.useStoreState((state) => state.missingBankInformation);
  const waitingForPaymentTotal = ExercisingContext.useStoreState((state) => state.overview.waitingForPaymentTotal);
  const getMenageExercisingThunk = ExercisingContext.useStoreActions((actions) => actions.getMenageExercisingThunk);
  const sendAllRemindersThunk = ExercisingContext.useStoreActions((actions) => actions.sendAllRemindersThunk);
  const sendSingleReminderThunk = ExercisingContext.useStoreActions((actions) => actions.sendSingleReminderThunk);
  const markAsPaidThunk = ExercisingContext.useStoreActions((actions) => actions.markAsPaidThunk);
  const setDeclineExercising = ExercisingContext.useStoreActions((actions) => actions.setDeclineExercising);

  const tooltipsContent = useMemo(() => {
    return {
      title: missingBankInformation ? tCommon("bankDetailsRequried") : !hasFullAccess ? tCommon("viewOnly") : undefined,
      message: missingBankInformation
        ? tCommon("addBankDetailsToAccess")
        : !hasFullAccess
        ? tCommon("tooltip")
        : undefined,
    };
  }, [hasFullAccess, missingBankInformation]);

  const handleOpenRemindAllModal = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();
      setRemindAll(!remindAll);
    },
    [remindAll]
  );

  const handleOpenRemindSingleModal = useCallback(
    (item?: IExercisingItem) => () => {
      setRemindSingle(item ? item : null);
    },
    []
  );

  const handleOpenMarkAsPaidModal = useCallback(
    (item?: IExercisingItem) => () => {
      setMarkAsPaid(item ? item : null);
    },
    []
  );

  const handleRemindSingle = useCallback(() => {
    if (remindSingle?.exerciseRequestId) {
      sendSingleReminderThunk(remindSingle.exerciseRequestId).then(() => {
        notify(t("success"), true, "success", 2000);
        handleOpenRemindSingleModal()();
        getMenageExercisingThunk(companyId);
      });
    }
  }, [
    companyId,
    getMenageExercisingThunk,
    handleOpenRemindSingleModal,
    remindSingle?.exerciseRequestId,
    sendSingleReminderThunk,
  ]);

  const handleRemindAll = useCallback(() => {
    sendAllRemindersThunk(companyId).then(() => {
      notify(t("successReminders"), true, "success", 2000);
      handleOpenRemindAllModal();
      getMenageExercisingThunk(companyId);
    });
  }, [companyId, getMenageExercisingThunk, handleOpenRemindAllModal, sendAllRemindersThunk]);

  const handleMarkAsPaid = useCallback(() => {
    if (markAsPaid?.exerciseRequestId) {
      markAsPaidThunk(markAsPaid.exerciseRequestId).then(() => {
        notify(t("successMarkAsPaid"), true, "success", 2000);
        handleOpenMarkAsPaidModal()();
        getMenageExercisingThunk(companyId);
      });
    }
  }, [companyId, getMenageExercisingThunk, handleOpenMarkAsPaidModal, markAsPaid?.exerciseRequestId, markAsPaidThunk]);

  const RemindAll = useCallback(() => {
    return (
      <>
        <Button
          onClick={handleRemindAll}
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          isLoading={requestLoading}
        >
          {t("modalRemindAll.remindAll")}
        </Button>
        <Button
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          onClick={handleOpenRemindAllModal}
          variant="secondary"
        >
          {t("modalRemindAll.cancel")}
        </Button>
      </>
    );
  }, [
    handleOpenRemindAllModal,
    handleRemindAll,
    hasFullAccess,
    missingBankInformation,
    requestLoading,
    tooltipsContent.message,
    tooltipsContent.title,
  ]);

  const RemindSingle = useCallback(() => {
    return (
      <>
        <Button
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          isLoading={requestLoading}
          onClick={handleRemindSingle}
        >
          {t("modalRemindSingle.sendReminder")}
        </Button>
        <Button
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          onClick={handleOpenRemindSingleModal()}
          variant="secondary"
        >
          {t("modalRemindSingle.cancel")}
        </Button>
      </>
    );
  }, [
    handleOpenRemindSingleModal,
    handleRemindSingle,
    hasFullAccess,
    missingBankInformation,
    requestLoading,
    tooltipsContent.message,
    tooltipsContent.title,
  ]);

  const MarkAsPaid = useCallback(() => {
    return (
      <>
        <Button
          isLoading={requestLoading}
          onClick={handleMarkAsPaid}
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
        >
          {t("modalMarkAsPaid.sendReminder")}
        </Button>
        <Button
          variant="secondary"
          onClick={handleOpenMarkAsPaidModal()}
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
        >
          {t("modalMarkAsPaid.cancel")}
        </Button>
      </>
    );
  }, [
    handleMarkAsPaid,
    handleOpenMarkAsPaidModal,
    hasFullAccess,
    missingBankInformation,
    requestLoading,
    tooltipsContent.message,
    tooltipsContent.title,
  ]);

  const menuItems = useCallback(
    (item: IExercisingItem) => {
      return [
        {
          key: "remind",
          icon: <EmailActionSend />,
          isDisabled: missingBankInformation || !hasFullAccess,
          label: t("sendReminder"),
          onClick: () => {
            handleOpenRemindSingleModal(item)();
          },
          forceHideDropdown: true,
        },
        {
          key: "delete",
          type: "delete",
          icon: <RemoveIcon />,
          label: t("decline"),
          onClick: !hasFullAccess
            ? undefined
            : () => {
                setDeclineExercising(item);
              },
          forceHideDropdown: true,
          isDisabled: missingBankInformation || !hasFullAccess,
        },
      ] as ContextMenuProps["items"];
    },
    [handleOpenRemindSingleModal, hasFullAccess, missingBankInformation, setDeclineExercising]
  );

  return (
    <div>
      <ModalInfo
        show={remindAll}
        handleClose={handleOpenRemindAllModal}
        header={<H.xxxs>{t("modalRemindAll.title")}</H.xxxs>}
        footer={<RemindAll />}
      >
        {t("modalRemindAll.description")}
      </ModalInfo>
      <ModalInfo
        show={!!remindSingle}
        handleClose={handleOpenRemindSingleModal()}
        header={<H.xxxs>{t("modalRemindSingle.title")}</H.xxxs>}
        footer={<RemindSingle />}
      >
        {t("modalRemindSingle.description", { name: remindSingle?.requestorName })}
      </ModalInfo>
      <ModalInfo
        show={!!markAsPaid}
        handleClose={handleOpenMarkAsPaidModal()}
        header={<H.xxxs>{t("modalMarkAsPaid.title")}</H.xxxs>}
        footer={<MarkAsPaid />}
      >
        {t("modalMarkAsPaid.description", { name: markAsPaid?.requestorName })}
      </ModalInfo>

      <ExercisingCollapsable
        title={t("title")}
        btnTitle={t("remindAll")}
        description={t("description")}
        countItems={waitingForPayment.length}
        headButtonDisabled={!hasFullAccess || missingBankInformation}
        headButtonTooltipTitle={tooltipsContent.title}
        headButtonTooltipMessage={tooltipsContent.message}
        onHeadBtnClick={waitingForPaymentTotal > 1 ? handleOpenRemindAllModal : undefined}
      >
        {!waitingForPayment.length ? (
          <div className="p-4">
            <Ui.s color="foregroundLow">{t("noHistory")}</Ui.s>
          </div>
        ) : (
          waitingForPayment.map((item) => (
            <div key={item.exerciseRequestId} className={classes["row"]}>
              <NewAvatar
                company={item?.requestorIsCompanyOwned}
                imageUrl={item?.avatarFilePath}
                initials={item?.requestorIsCompanyOwned ? item?.requestorCompanyName : item.requestorName}
              />

              <div className="ms-2">
                <Ui.m bold className="mb-1">
                  {item?.requestorIsCompanyOwned
                    ? `${item?.requestorCompanyName} (${item.requestorName})`
                    : item.requestorName}{" "}
                  • {fNumber(item.numberOfOptions, "amount")} options
                </Ui.m>
                <div className="d-flex align-items-center">
                  <CalendarIcon fontSize={16} color={scssVariables.foregroundLow} />
                  <Ui.xs color="foregroundLow" className="ms-1">
                    {transformDateToCommonDateFormat(item.requestedAt, "d. MMM yyyy").toLowerCase()}
                    &nbsp;•&nbsp;
                  </Ui.xs>

                  <Ui.xs color="foregroundLow">
                    {t("item.description", {
                      exercisingOptions: fNumber(item.numberOfOptions, "amount"),
                      totalOptions: fNumber(item.vestedNotExercised, "amount"),
                      price: fNumber(item.convertPrice),
                    })}
                  </Ui.xs>
                </div>
              </div>

              <div className={classes["actions"]}>
                <div>
                  <Button
                    size="s"
                    variant="tertiary"
                    tooltipTitle={tooltipsContent.title}
                    tooltipMessage={tooltipsContent.message}
                    isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
                    onClick={handleOpenMarkAsPaidModal(item)}
                  >
                    {t("markAsPaid")}
                  </Button>

                  <ContextMenu items={menuItems(item)} className="ms-1">
                    <Button isOnlyIcon variant="tertiary" className={classes["menu-button"]}>
                      <MenuTabBarVerticalIcon />
                    </Button>
                  </ContextMenu>
                </div>
              </div>
            </div>
          ))
        )}
      </ExercisingCollapsable>
    </div>
  );
};

export default WaitingForPayment;
