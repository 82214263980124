import { FC, useCallback, useMemo } from "react";
import { PointTooltip } from "@nivo/line";
import cn from "classnames";
import * as R from "ramda";

import { LinearChart, LinkHelpText, Ui } from "common/components/atoms";
import { sortValuationData } from "common/helpers";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { axisXChartFormatter, transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../valuation.module.scss";

const t = createTranslation(TranslationNS.pages, "company.valuation");

const CustomTooltip: PointTooltip = ({ point }) => {
  const fNumber = useFormatNumbers();

  return (
    <div className={classes["chart-tooltip"]}>
      <Ui.xs className="d-flex align-items-center">
        <div className={classes.circle} />
        {t("title")} {transformDateToCommonDateFormat(point.data.x as string)}:&nbsp;
        <strong className="d-flex align-items-center">{fNumber(point.data.y as number, "value")}</strong>
      </Ui.xs>
    </div>
  );
};

const ValuationOverview: FC = () => {
  const valuation = useStoreState((state) => state.company.valuation);
  const fNumber = useFormatNumbers();

  const valuationTransformData = useMemo(
    () => ({
      chartPoints: [
        {
          id: t("title"),
          color: scssVariables.foregroundOnSecondary,
          chartBgColor: scssVariables.additional1200,
          data: sortValuationData(valuation?.chartDataPoints || []),
        },
      ],
    }),
    [valuation]
  );

  const axisXFormatter = useCallback(
    (value: string) => {
      return axisXChartFormatter(value, valuationTransformData.chartPoints?.[0].data, "dd.MM.yyyy");
    },
    [valuationTransformData.chartPoints]
  );

  const items = useMemo(
    () => [
      {
        title: t("sharePrice"),
        direction: "left",
        value: <>{fNumber(valuation?.sharePrice, "sharePrice")}</>,
        description: t("sharePriceDescription"),
      },
      {
        title: t("totalShares"),
        direction: "top",
        value: fNumber(valuation?.issuedShares, "amount"),
        description: t("totalSharesDescription"),
      },
      {
        direction: "right",
        title: t("taxableValuePerShare"),
        value: fNumber(valuation?.taxableValue, "unitPrice"),
        description: t("taxableValuePerShareDescription"),
      },
      {
        direction: "left",
        title: t("nominalShareValue"),
        value: fNumber(valuation?.nominalValue, "unitPrice"),
        description: t("nominalShareValueDescription"),
      },
      {
        direction: "bottom",
        title: t("shareCapital"),
        value: (
          <>{fNumber(R.defaultTo(0, valuation?.issuedShares) * R.defaultTo(0, valuation?.nominalValue), "value")}</>
        ),
        description: t("shareCapitalDescription"),
      },
      {
        direction: "right",
        title: t("totalTaxableValue"),
        value: <>{fNumber(valuation?.totalTaxableValue, "value")}</>,
        description: t("totalTaxableValueDescription"),
      },
    ],
    [
      fNumber,
      valuation?.issuedShares,
      valuation?.nominalValue,
      valuation?.sharePrice,
      valuation?.taxableValue,
      valuation?.totalTaxableValue,
    ]
  );

  return (
    <div>
      <div className={classes["overtview-chart"]}>
        <Ui.l bold className="mb-8" style={{ color: scssVariables.primary1 }}>
          {t("valuationToday", {
            valuation: fNumber(valuation?.latestValuation || 0),
          })}
        </Ui.l>

        <LinearChart
          dashedLines
          height={190}
          gridYValues={4}
          axisRight={null}
          chartType="linear"
          tooltip={CustomTooltip}
          withCustomPoint
          pointSize={8}
          selectedPointLabel={R.pluck("x", valuationTransformData.chartPoints[0].data).map((el) => el.toString())}
          data={valuationTransformData.chartPoints}
          margin={{ bottom: 30, left: 5, right: 10, top: 4 }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            format: axisXFormatter,
            renderTick: (e) => {
              const isLastOneTick = e.tickIndex === valuationTransformData.chartPoints[0].data.length - 1;

              return (
                <text
                  className="ui-xs"
                  style={{ fill: scssVariables.foregroundMedium }}
                  transform={`translate(${e.x - (isLastOneTick ? 55 : 0)}, 20)`}
                >
                  {e?.format?.(e?.value)}
                </text>
              );
            },
          }}
        />
      </div>

      <div className={cn(classes["overview-details"], "mt-3")}>
        {items.map((item) => (
          <div key={item.title} className={cn("position-relative p-3", classes.item)}>
            <Ui.s>{item.title}</Ui.s>

            <Ui.xxl bold className={cn(classes.value, "mt-1")}>
              {item.value}
            </Ui.xxl>

            <LinkHelpText
              type="modal"
              placement="left"
              title={item.title}
              content={item.description}
              className={classes.tooltip}
            >
              <QuestionCircleIcon width={24} height={24} />
            </LinkHelpText>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ValuationOverview;
