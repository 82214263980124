import React, { ChangeEvent, FC, useCallback, useMemo, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { assocPath, defaultTo, isNil } from "ramda";

import { Button, Check, Dropdown as CDropdwn, Ui } from "common/components/atoms";
import { ButtonProps } from "common/components/atoms/Button/Button";
import { useOnClickOutside } from "common/hooks/useOnClickOutside";
import { ChevronDownIcon } from "common/icons/svg";

import ManagePlansContext, { initialFiltersState } from "../../../managePlansContext";
import classes from "../AgreementsAppliedFilters.module.scss";

const StatusHeadSection = () => {
  const { companyId = "0" } = useParams();
  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const setSavedAppliedFilters = ManagePlansContext.useStoreActions((actions) => actions.setSavedAppliedFilters);

  const handleResetFilters = useCallback(() => {
    setSavedAppliedFilters(assocPath([companyId, "statuses"], initialFiltersState.statuses, savedAppliedFilters));
  }, [companyId, savedAppliedFilters, setSavedAppliedFilters]);

  return (
    <div className="d-flex justify-content-between">
      <Ui.xl bold>Filter by status</Ui.xl>
      <Button variant="tertiary" size="s" onClick={handleResetFilters}>
        Reset
      </Button>
    </div>
  );
};

const StatusDropdownItem: FC<{ checked: boolean; label: string }> = ({ checked, label }) => {
  return (
    <div className="d-flex align-items-center">
      <Check checked={checked} className="me-2 pe-none" />

      <Ui.m style={{ textTransform: "capitalize" }}>{label}</Ui.m>
    </div>
  );
};
const Toggle: FC<
  ButtonProps & {
    open: boolean;
    setOpen?: (val: boolean) => void;
  }
> = ({ open, setOpen, ...props }) => {
  const { companyId = "0" } = useParams();
  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);

  const activeFilters = useMemo(() => {
    return Object.values(defaultTo({}, savedAppliedFilters?.[companyId]?.statuses)).filter((el) => !el)?.length;
  }, [companyId, savedAppliedFilters]);

  return (
    <Button
      {...props}
      size="s"
      variant="tertiary"
      className={classNames(
        classNames(classes["toggle"], {
          [classes["active"]]: open,
        })
      )}
      id={classes["toggle"]}
      onClick={() => {
        setOpen?.(!open);
      }}
    >
      <div className="d-flex align-items-center">
        <Ui.s className="me-semihalf">Status</Ui.s>

        {activeFilters ? <Ui.s className={classes["count"]}>{activeFilters}</Ui.s> : null}

        <ChevronDownIcon
          fontSize={16}
          className={classNames({
            [classes["open"]]: open,
          })}
        />
      </div>
    </Button>
  );
};

const StatusFiltersDropdown = () => {
  const { companyId } = useParams();

  const ref = useRef<HTMLDivElement>(null);
  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const setSavedAppliedFilters = ManagePlansContext.useStoreActions((actions) => actions.setSavedAppliedFilters);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useOnClickOutside(ref, () => {
    setIsMenuOpen(false);
  });

  const dropdownOptions = useMemo(() => {
    if (companyId) {
      const stateStatuses = initialFiltersState.statuses;

      return Object.keys(stateStatuses).map((el, index) => {
        const currentStatusFilter = !isNil(savedAppliedFilters?.[companyId]?.statuses?.[el as any])
          ? savedAppliedFilters?.[companyId]?.statuses?.[el as any]
          : (stateStatuses as any)[el];

        return {
          name:
            el === "waitingForManagersSignature"
              ? "Waiting for manager"
              : el === "waitingForReceiversSignature"
              ? "Waiting for receiver"
              : el,
          id: index,
          element: (
            <StatusDropdownItem
              checked={currentStatusFilter}
              label={
                el === "waitingForManagersSignature"
                  ? "Waiting for manager"
                  : el === "waitingForReceiversSignature"
                  ? "Waiting for receiver"
                  : el
              }
            />
          ),
        };
      });
    }
  }, [companyId, savedAppliedFilters]);

  const handleCheckChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (companyId) {
        const currentCheck = Object.keys(
          defaultTo(initialFiltersState.statuses, savedAppliedFilters?.[companyId]?.statuses)
        )[e.target.value as unknown as number];

        const currentValue = savedAppliedFilters?.[companyId]?.statuses?.[currentCheck as any];

        if (
          Object.keys(savedAppliedFilters?.[companyId]?.statuses || {}).length !==
          Object.keys(initialFiltersState.statuses).length
        ) {
          const filtersWithAllStatuses = assocPath(
            [companyId, "statuses"],
            initialFiltersState.statuses,
            savedAppliedFilters
          );

          setSavedAppliedFilters(assocPath([companyId, "statuses", currentCheck], false, filtersWithAllStatuses));
        } else {
          setSavedAppliedFilters(assocPath([companyId, "statuses", currentCheck], !currentValue, savedAppliedFilters));
        }
      }
    },
    [companyId, savedAppliedFilters, setSavedAppliedFilters]
  );

  return (
    <div ref={ref}>
      <CDropdwn
        optionsIsObject
        menuOpen={isMenuOpen}
        options={dropdownOptions || []}
        className={classNames(classes["dropdown"], {
          [classes["statuses"]]: true,
        })}
        MenuHeadSection={<StatusHeadSection />}
        CustomToggleElement={<Dropdown.Toggle as={Toggle} open={isMenuOpen} setOpen={setIsMenuOpen} />}
        onChange={handleCheckChange}
      />
    </div>
  );
};

export default StatusFiltersDropdown;
