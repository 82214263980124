import { FC } from "react";
import Ratio, { RatioProps } from "react-bootstrap/Ratio";
import classNames from "classnames";

export type MediaProps = RatioProps & {};

const Media: FC<MediaProps> = ({ className, children, ...props }) => (
  <Ratio className={classNames(className)} aspectRatio="16x9" {...props}>
    {children}
  </Ratio>
);

export default Media;
