import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { compareAsc } from "date-fns";
import { FormikHelpers } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum, RelationshipTypesEnum } from "common/enums/enum";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";

const [t, tv] = [
  createTranslation(TranslationNS.pages, "company.issueEquity.convertibleNote.editPanel"),
  createTranslation(TranslationNS.validation),
];

const useConvertibleNoteEditPanel = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const shareClassesCompany = useStoreState((state) => state.shareClassModel.shareClassesCompany);
  const isConvertibleNoteEditPanelOpen = IssueEquityContext.useStoreState(
    (state) => state.isConvertibleNoteEditPanelOpen
  );
  const convertibleNote = IssueEquityContext.useStoreState((state) => state.convertibleNote);

  const setIsConvertibleNoteEditPanelOpen = IssueEquityContext.useStoreActions(
    (actions) => actions.setIsConvertibleNoteEditPanelOpen
  );
  const getIssueEquityThunk = IssueEquityContext.useStoreActions((actions) => actions.getIssueEquityThunk);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      noteId: R.defaultTo(undefined, convertibleNote?.id),
      firstName: R.defaultTo("", convertibleNote?.firstName),
      lastName: R.defaultTo("", convertibleNote?.lastName),
      email: R.defaultTo("", convertibleNote?.email),
      companyName: R.defaultTo("", convertibleNote?.stakeholderCompanyName),
      organizationNumber: R.defaultTo("", convertibleNote?.organizationNumber),
      investmentAmount: R.defaultTo(0, convertibleNote?.investmentAmount),
      discount: R.defaultTo(0, convertibleNote?.discount),
      valuationCap: R.defaultTo(0, convertibleNote?.valuationCap),
      triggerAmount: R.defaultTo(0, convertibleNote?.triggerAmount),
      expirationDate: R.defaultTo(undefined, convertibleNote?.expirationDate),
      agreementDate: R.defaultTo(undefined, convertibleNote?.agreementDate),
      isCompanyOwned: R.defaultTo(false, convertibleNote?.isCompanyOwned),
      relationshipTypeId: R.defaultTo(RelationshipTypesEnum.EMPLOYEE, convertibleNote?.relationshipTypeId),
      documentStatusId: R.defaultTo(undefined, convertibleNote?.documentStatusId),
      documentsNeedsSignature: R.defaultTo(false, convertibleNote?.documentsNeedsSignature),
      sendInvitationEmail: R.defaultTo(false, convertibleNote?.sendInvitationEmail),
      isShareClassRequired: convertibleNote?.shareClassId ? "yes" : "no",
      shareClassComment: R.defaultTo("", convertibleNote?.shareClassComment),
      shareClassId: R.defaultTo(undefined, convertibleNote?.shareClassId),
      interestRate: R.defaultTo(0, convertibleNote?.interestRate),
      dateOfBirth: R.defaultTo(undefined, convertibleNote?.dateOfBirth),
      address: R.defaultTo("", convertibleNote?.address),
      countryId: R.defaultTo(undefined, convertibleNote?.countryId),
      businessPostAddress: R.defaultTo("", convertibleNote?.businessPostAddress),
      phoneNumber: R.defaultTo("", convertibleNote?.phoneNumber),
      stakeholderId: R.defaultTo(undefined, convertibleNote?.stakeholderId),
      documents: (convertibleNote?.documentFiles
        ? { newFiles: [], oldFiles: convertibleNote?.documentFiles }
        : { newFiles: [], oldFiles: [] }) as FilesDataMultiple,
      saveAsDraft: false,
      statusId: R.defaultTo(undefined, convertibleNote?.statusId),
    }),
    [convertibleNote]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(tv("required")),
        lastName: Yup.string().required(tv("required")),
        email: Yup.string()
          .email(tv("invalidEmail"))
          .when("sendInvitationEmail", {
            is: true,
            then: Yup.string().required(tv("required")),
          }),
        companyName: Yup.string().when("isCompanyOwned", {
          is: true,
          then: Yup.string().required(tv("required")),
        }),
        shareClassId: Yup.number().when("isShareClassRequired", {
          is: "yes",
          then: Yup.number().required(tv("required")),
        }),
        triggerAmount: Yup.number().nullable().min(0, tv("notNegative")),
        discount: Yup.number()
          .required(tv("required"))
          .min(0, tv("minNumber", { number: "0%" }))
          .max(100, tv("maxNumber", { number: "100%" })),
        interestRate: Yup.number()
          .required(tv("required"))
          .min(0, tv("minNumber", { number: "0%" }))
          .max(100, tv("maxNumber", { number: "100%" })),
        valuationCap: Yup.number().nullable().min(0, tv("notNegative")),
        investmentAmount: Yup.number()
          .required(tv("required"))
          .min(1, tv("minNumberMore", { number: 1 })),
        agreementDate: Yup.date().required(tv("required")),
        expirationDate: Yup.date()
          .optional()
          // .required(tv("required"))
          .test("agreementDate", "Must be after agreement date", (value, object) => {
            return value ? compareAsc(value || new Date(), object.parent.agreementDate) === 1 : true;
          }),
        documentStatusId: Yup.number().required(tv("requiredSection")),
        documents: Yup.object().when("documentStatusId", {
          is: (documentValue: number) => +documentValue === DocumentStatusEnum.DOCUMENT_UPLOADED,
          then: Yup.object().shape({
            newFiles: Yup.mixed().when("oldFiles", {
              is: (oldFiles: any) => !oldFiles.length,
              then: Yup.array().min(1, tv("required")),
            }),
          }),
        }),
      }),
    []
  );

  const closePanel = useCallback(() => {
    setIsConvertibleNoteEditPanelOpen({ open: false });
  }, [setIsConvertibleNoteEditPanelOpen]);

  const onSubmit = useCallback(
    async (formValues: typeof initialValues, actions?: FormikHelpers<typeof initialValues>) => {
      try {
        setIsLoading(true);

        const formData = new FormData();

        Object.keys(formValues).forEach((key) => {
          const item = key as keyof typeof initialValues;
          const allowTypes = ["number", "string", "boolean"];
          if (allowTypes.includes(typeof formValues?.[item])) {
            if (item === "triggerAmount" || item === "valuationCap") {
              formData.append(key, !formValues[item] ? "0" : String(formValues[item]));
            } else {
              formData.append(key, String(formValues[item]));
            }
          }
        });

        if (formValues?.documents?.newFiles?.length) {
          formValues.documents.newFiles.forEach((file) => {
            formData.append("filesData.files", file);
          });
        }
        if (formValues?.documents?.oldFiles?.length) {
          formValues.documents.oldFiles.forEach((file) => {
            formData.append("filesData.oldFileIds", String(file.fileId));
          });
        }

        formData.append("CompanyId", String(companyId));

        const request = await axios.post("/api/equity-management/issue-equity/convertible-note", formData);

        if (request.status === 200) {
          notify(
            formValues?.sendInvitationEmail && !formValues.saveAsDraft
              ? t("successMessage")
              : t("successMessageWithoutEmail"),
            true,
            "success",
            5000,
            false,
            "top-center"
          );
          await getIssueEquityThunk(Number(companyId));

          setIsConvertibleNoteEditPanelOpen({ open: false });
          actions?.resetForm();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [companyId, getIssueEquityThunk, setIsConvertibleNoteEditPanelOpen]
  );

  return {
    isLoading,
    initialValues,
    validationSchema,
    shareClassesCompany,
    isConvertibleNoteEditPanelOpen,
    onSubmit,
    closePanel,
  };
};

export default useConvertibleNoteEditPanel;
