import {
    ChangeEvent,
    forwardRef,
    JSX,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useEffect,
    useRef
} from "react";
import { Spinner } from "react-bootstrap";
import cn from "classnames";
import { defaultTo } from "ramda";

import { ChevronDownIcon, CloseIcon, SearchIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import classes from "../Dropdown/Dropdown.module.scss";
import TextField, { TextFieldProps } from "../TextField/TextField";

type ToggleProps = {
  children: JSX.Element;
  onClick: (e: MouseEvent) => void;
  isSearchable: boolean;
  isMenuOpen: boolean;
  searchPlaceholder?: string;
  searchValue?: string;
  withoutToggleChevron?: boolean;
  onSearchChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  withCustomInputClick?: boolean;
  handleClear?: () => void;
  isLoading?: boolean;
  showSearchIcon?: boolean;
  isClearable?: boolean;
  valueIsSelected?: boolean;
  CustomToggleElement?: ReactNode;
};

type PropsTypes = TextFieldProps & ToggleProps;

const DropdownToggle = forwardRef<TextFieldProps, PropsTypes>(
  (
    {
      children,
      onClick,
      label,
      isSearchable,
      searchPlaceholder,
      searchValue,
      withCustomInputClick,
      onSearchChange,
      isMenuOpen,
      placeholder,
      value,
      name,
      meta,
      withoutToggleChevron,
      handleClear,
      className,
      isLoading,
      showSearchIcon,
      isClearable,
      valueIsSelected,
      CustomToggleElement,
      ...props
    },
    ref
  ) => {
    const isTabActive = useRef(false);

    const handleClick: MouseEventHandler = useCallback(
      (event) => {
        if (isSearchable && isMenuOpen) {
          event.stopPropagation();
          return;
        }

        onClick(event);
      },
      [isMenuOpen, isSearchable, onClick]
    );

    const IconRight = useCallback(
      ({ isMenuOpen }: { isMenuOpen: boolean }) => {
        if ((props.isOptional || isClearable) && value) {
          return <CloseIcon width={24} height={24} />;
        }

        if (withoutToggleChevron) {
          return undefined;
        }

        if ((isMenuOpen && !isSearchable) || !isMenuOpen) {
          return <ChevronDownIcon id="dropdown-chevron-icon" fontSize={24} direction={isMenuOpen ? "top" : "bottom"} />;
        }

        return undefined;
      },
      [isSearchable, props.isOptional, value, withoutToggleChevron, isClearable]
    );

    const handleFocus = useCallback(
      (e: any) => {
        if (isTabActive.current) {
          onClick(e);
        }
      },
      [onClick]
    );

    useEffect(() => {
      const handleTabUp = (event: any) => {
        if (event.key === "Tab") {
          isTabActive.current = false;
        }
      };

      const handleTabPress = (event: any) => {
        if (event.key === "Tab") {
          isTabActive.current = true;
        }
      };

      document.addEventListener("keydown", handleTabPress);
      document.addEventListener("keyup", handleTabUp);

      // Clean up event listeners when component unmounts
      return () => {
        document.removeEventListener("keydown", handleTabUp);
        document.removeEventListener("keyup", handleTabUp);
      };
    }, []);

    const handleOnChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        if (onSearchChange) {
          onSearchChange(e);
        }
      },
      [onSearchChange]
    );

    return CustomToggleElement ? (
      CustomToggleElement
    ) : (
      <TextField
        tabIndex={0}
        role="listbox"
        type="text" // don't modify this type !!! (it will break tab logic in safari)
        label={label}
        ref={ref as any}
        value={defaultTo("", value || (isSearchable && isMenuOpen ? value : ""))}
        onClick={withCustomInputClick ? onClick : handleClick}
        meta={meta}
        onChange={handleOnChange}
        iconRight={isLoading ? <Spinner size="sm" /> : <IconRight isMenuOpen={isMenuOpen} />}
        iconRightOnClick={(props.isOptional || isClearable) && value ? handleClear : undefined}
        iconLeft={
          ((isSearchable && isMenuOpen) || showSearchIcon) && (
            <SearchIcon width={24} height={24} color={scssVariables.foregroundLow} />
          )
        }
        data-testid={`dropdown-toggle-${name || label}-test-id`}
        placeholder={isMenuOpen && searchPlaceholder && !valueIsSelected ? searchPlaceholder : placeholder}
        name={name}
        onFocus={handleFocus}
        className={cn(className, {
          [classes.notSelected]: !value,
        })}
        {...props}
      />
    );
  }
);

DropdownToggle.displayName = "DropDownToggle";

export default DropdownToggle;
