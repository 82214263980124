import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { ChecksGroup, H, Helper, PeriodPickerFormik } from "common/components/atoms";
import { useWizardStepper } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { newPlanFieldsNames as f, periodVariants } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";
import classes from "./PostTerminationExerciseClause.module.scss";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.terminationExercise");
const tHelpers = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const PostTerminationExerciseClause: FC = () => {
  useWizardStepper(5);
  const navigate = useNavigate();

  const handleStepBack = useCallback(() => {
    navigate(getEMPath(["createOneOffPlan", "vestingConditions"]));
  }, [navigate]);

  const { values, setFieldValue, handleSubmit } = useFormikContext<OwnershipPlanForm>();

  const submitHandler = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <WizardContent.Content>
      <Helper>
        <H.xxs className="text-center">{t("title")}</H.xxs>

        <ChecksGroup className="my-5">
          <ChecksGroup.Check
            type="radio"
            label={t("includeClauses")}
            checked={!values.isExcludedClause}
            onChange={() => {
              setFieldValue("isExcludedClause", false);
            }}
          />

          <ChecksGroup.Check
            type="radio"
            label={t("excludeClauses")}
            checked={values.isExcludedClause}
            onChange={() => {
              setFieldValue("isExcludedClause", true);
            }}
          />
        </ChecksGroup>
        {values.isExcludedClause ? null : (
          <div className={classes["block-wrapper"]}>
            <Helper.Question questionId={f.retirement} type="input">
              <PeriodPickerFormik
                label={t("retirement")}
                periodVariants={periodVariants}
                className={`${classes["one-off-optins"]} mb-3`}
                nameAmount={f.retirement}
                namePeriod={f.retirementTimeUnit}
              />
            </Helper.Question>
            <Helper.Answer answerId={f.retirement} className="mb-3" text={tHelpers("retirementAnswer")} />

            <Helper.Question questionId={f.disability} type="input">
              <PeriodPickerFormik
                label={t("disability")}
                periodVariants={periodVariants}
                className={`${classes["one-off-optins"]} mb-3`}
                nameAmount={f.disability}
                namePeriod={f.disabilityTimeUnit}
              />
            </Helper.Question>
            <Helper.Answer answerId={f.disability} className="mb-3" text={tHelpers("disabilityAnswer")} />

            <Helper.Question questionId={f.terminationWithCause} type="input">
              <PeriodPickerFormik
                label={t("terminationWithCause")}
                periodVariants={periodVariants}
                className={`${classes["one-off-optins"]} mb-3`}
                nameAmount={f.terminationWithCause}
                namePeriod={f.terminationWithCauseTimeUnit}
              />
            </Helper.Question>
            <Helper.Answer
              answerId={f.terminationWithCause}
              className="mb-3"
              text={tHelpers("terminationWithCauseAnswer")}
            />

            <Helper.Question questionId={f.involuntaryTermination} type="input">
              <PeriodPickerFormik
                label={t("involuntaryTermination")}
                periodVariants={periodVariants}
                className={`${classes["one-off-optins"]} mb-3`}
                nameAmount={f.involuntaryTermination}
                namePeriod={f.involuntaryTerminationTimeUnit}
              />
            </Helper.Question>
            <Helper.Answer
              answerId={f.involuntaryTermination}
              className="mb-3"
              text={tHelpers("involuntaryTerminationAnswer")}
            />

            <Helper.Question questionId={f.voluntaryTermination} type="input">
              <PeriodPickerFormik
                label={t("voluntaryTermination")}
                periodVariants={periodVariants}
                className={`${classes["one-off-optins"]} mb-3`}
                nameAmount={f.voluntaryTermination}
                namePeriod={f.voluntaryTerminationTimeUnit}
              />
            </Helper.Question>
            <Helper.Answer
              answerId={f.voluntaryTermination}
              className="mb-3"
              text={tHelpers("voluntaryTerminationAnswer")}
            />

            <Helper.Question questionId={f.byPassingAway} type="input">
              <PeriodPickerFormik
                label={t("passingAway")}
                periodVariants={periodVariants}
                className={`${classes["one-off-optins"]} mb-3`}
                nameAmount={f.byPassingAway}
                namePeriod={f.byPassingAwayTimeUnit}
              />
            </Helper.Question>
            <Helper.Answer answerId={f.byPassingAway} className="mt-3" text={tHelpers("byPassingAwayAnswer")} />
          </div>
        )}

        <div className="d-flex mt-8">
          <WizardContent.Controls.BackButton className="ms-auto me-2" onClick={handleStepBack} />
          <WizardContent.Controls.ContinueButton onClick={submitHandler} />
        </div>
      </Helper>
    </WizardContent.Content>
  );
};

export default PostTerminationExerciseClause;
