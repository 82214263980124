import { FC } from "react";
import Image from "react-bootstrap/Image";
import classNames from "classnames";

import { H, Ui } from "common/components/atoms/Typography";
import Card from "common/components/molecules/Card/Card";
import SearchIcon from "common/icons/searching-with-telescope.png";
import StartupIcon from "common/icons/startup.png";
import StepWrapper, { StepWrapperProps } from "common/layout/WizardSinglePage/StepWrapper/StepWrapper";
import stepWrapperClasses from "common/layout/WizardSinglePage/StepWrapper/StepWrapper.module.scss";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./service.module.scss";
import { ServiceTypes } from "./types";

type PropsTypes = {
  serviceTypes: ServiceTypes | null;
  onSubmit: (service: ServiceTypes) => void;
} & StepWrapperProps;

const t = createTranslation(TranslationNS.pages, "managerOnboarding.serviceStep");

export const ServiceTypesEnum = {
  Equity: "Equity management",
  Attract: "Attract investors",
} as const;

const Service: FC<PropsTypes> = ({ serviceTypes, stepRef, isDisabled, onSubmit }) => {
  return (
    <StepWrapper className={classes.service} stepRef={stepRef} isDisabled={isDisabled}>
      <div>
        <Ui.l bold className="mb-1">
          {t("subtitle")}
        </Ui.l>
        <H.m className="mb-3">{t("title")}</H.m>
        <Ui.s className="text-grey mb-3">{t("paragraph")}</Ui.s>

        <div className={classNames("d-flex justify-content-center p-2", classes.container)}>
          <Card
            className={classes.card}
            circleBg={scssVariables.secondary1}
            bgColor={scssVariables.element1}
            cardTitle={t("equityCard.title")}
            isCardSelected={serviceTypes === ServiceTypesEnum.Equity}
            icon={
              <Image
                width={140}
                height={140}
                src={SearchIcon}
                style={{
                  position: "absolute",
                  transform: "translate(-10%, -10%)",
                }}
              />
            }
            cardDescription={t("equityCard.content")}
            btnTitle={t("selectBtn")}
            onBtnClick={() => onSubmit(ServiceTypesEnum.Equity)}
          />

          {/* TODO temporary disabled */}
          <Card
            className={classNames(classes.card, stepWrapperClasses["disabled"])}
            circleBg={scssVariables.secondary1}
            bgColor={scssVariables.element1}
            cardTitle={t("attractCard.title")}
            isCardSelected={serviceTypes === ServiceTypesEnum.Attract}
            icon={
              <Image
                width={140}
                height={140}
                src={StartupIcon}
                style={{
                  position: "absolute",
                  transform: "translate(-10%, -10%)",
                }}
              />
            }
            cardDescription={t("attractCard.content")}
            btnTitle={t("selectBtn")}
            isBtnDisabled={true}
            onBtnClick={() => {
              // onSubmit(ServiceTypesEnum.Attract);
              // notify(t("successNotification"), true, "success");
            }}
          />
        </div>
      </div>
    </StepWrapper>
  );
};

export default Service;
