import { format } from "date-fns";
import cn from "classnames";
import { FC, useCallback, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import Button from "common/components/atoms/Button/Button";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import RocketIcon from "../../assets/rocket.png";
import Accordion from "react-bootstrap/Accordion";
import classes from "./CompanyInfoCard.module.scss";
import useFormatNumbers from "common/hooks/useFormatNumbers";

const t = createTranslation(TranslationNS.pages, "public.companyDetails");

type CompanyInfoCardProps = {
  stage: string;
  goalMax: number;
  pitch?: string | null;
  fundingStatus: string;
  currencyCode?: string;
  fundingInstrument: string;
  closingDate?: string | null;
  calendlyLink?: string;
  onUnAuthClick: () => void;
  companyId?: string;
};

const CompanyInfoCard: FC<CompanyInfoCardProps> = ({
  closingDate,
  fundingInstrument,
  fundingStatus,
  goalMax,
  stage,
  pitch,
  currencyCode,
  calendlyLink,
  onUnAuthClick,
  companyId,
}) => {
  const fNumber = useFormatNumbers(currencyCode);
  const navigate = useNavigate();

  const [width] = useState(() => window.innerWidth);
  const { isAuthenticated } = useStoreState((state) => state.authentication);

  const wasBookAMeetingClicked = useStoreState((state) => state.afterSignup.wasBookAMeetingClicked);
  const { setBookAMeetingClicked, setPageForRedirect } = useStoreActions((state) => state.afterSignup);

  const renderDetails = useCallback(() => {
    return (
      <>
        <p className="m-0 ui-s fw-bold mt-3 mb-1">{t("goal")}</p>

        <p className="m-0 ui-s mb-4">{fNumber(goalMax, "value")}</p>

        <p className="m-0 ui-s fw-bold mb-1">{t("fundingStatus")}</p>

        <p className="m-0 ui-s mb-4">{fundingStatus}</p>

        <p className="m-0 ui-s fw-bold mb-1">{t("fundingInstrument")}</p>

        <p className="m-0 ui-s mb-4">{fundingInstrument}</p>

        <p className="m-0 ui-s fw-bold mb-1">{t("closingDate")}</p>

        <p className="m-0 ui-s">{closingDate ? format(new Date(closingDate), "dd-MM-yyy") : "-"}</p>
      </>
    );
  }, []);

  useEffect(() => {
    if (isAuthenticated && wasBookAMeetingClicked) {
      window.open(calendlyLink, "_blank");
    }
    setBookAMeetingClicked(false);
  }, []);

  const openPitchHandler = () => {
    if (isAuthenticated) {
      window.open(`${process.env.REACT_APP_BLOB_PUBLIC_URL}${pitch}`, "_blank");

      return;
    }

    navigate("/");
  };

  const onBookMeetingHandler = () => {
    if (isAuthenticated) {
      window.open(calendlyLink, "_blank");
      return;
    }
    setBookAMeetingClicked(true);
    companyId && setPageForRedirect(`/company-details/${companyId}`);
    onUnAuthClick();
    navigate("/");
  };

  return (
    <div className={classes["company-card-container"]} data-testid="company-info-card-test-id">
      <div className={classes["company-card-container-main-info"]}>
        <div className={classes["company-card-container-circle"]}>
          <Image className={classes["company-card-container-circle-image"]} src={RocketIcon} />
        </div>
        <div className={classes["company-card-container-main-info-text"]}>
          <p className="m-0 head-xxxxs mb-1">{t("investmentStage")}</p>

          <p className="m-0 head-xxxs">{stage}</p>
        </div>
      </div>

      <div className={cn(classes["company-card-container-hr"], "my-4")} />

      <div className={classes["company-card-container-details"]}>{renderDetails()}</div>

      <Accordion className={cn(classes["company-card-container-accordion"], "my-3")} style={{ order: 2 }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header as="div">{t("viewDetails")}</Accordion.Header>

          <Accordion.Body style={{ textAlign: "center" }}>{renderDetails()}</Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className={cn(classes["company-card-container-hr"], "my-4")} />

      <div className={classes["company-card-container-buttons"]}>
        {calendlyLink && width > 500 && (
          <>
            <Button
              className="mb-3"
              data-testid="book-meeting-btn-test-id"
              onClick={onBookMeetingHandler}
              style={{ maxWidth: "100%", minWidth: "140px" }}
            >
              {t("bookMeeting")}
            </Button>
          </>
        )}
        {calendlyLink && width < 500 && (
          <>
            <Button
              size="s"
              className="mb-3"
              data-testid="book-meeting-btn-test-id"
              onClick={onBookMeetingHandler}
              style={{ maxWidth: "80%", minWidth: "140px" }}
            >
              {t("bookMeeting")}
            </Button>
          </>
        )}

        {!pitch && width > 500 ? (
          <p className="m-0 fst-italic ui-s" style={{ color: scssVariables.foregroundHigh }}>
            {t("pitchNotAvailable")}
          </p>
        ) : width > 500 ? (
          <Button
            variant="secondary"
            className="mb-3"
            data-testid="open-pitch-deck-btn-test-id"
            onClick={openPitchHandler}
            style={{ maxWidth: "100%", minWidth: "140px" }}
          >
            {t("openPitchDeck")}
          </Button>
        ) : null}

        {!pitch && width < 500 ? (
          <p className="m-0 fst-italic ui-s" style={{ color: scssVariables.foregroundHigh }}>
            {t("pitchNotAvailable")}
          </p>
        ) : width < 500 ? (
          <Button
            variant="secondary"
            size="s"
            className="mb-3"
            data-testid="open-pitch-deck-btn-test-id"
            onClick={openPitchHandler}
            style={{ maxWidth: "80%", minWidth: "140px" }}
          >
            {t("openPitchDeck")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default CompanyInfoCard;
