import { FC, useState } from "react";
import { Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { isNil } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, P, Ui } from "common/components/atoms";
import { StripePricingTable } from "common/components/molecules";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import lockImage from "common/icons/lock.png";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { ArrowLeftIcon } from "../../icons/svg";
import { CONTACT_US_LINK, VIEW_PRICES_LINK } from "../../utils/constants";
import classes from "./NoSubscription.module.scss";
import SubscriptionManagerInfo from "./SubscriptionManagerInfo";

const t = createTranslation(TranslationNS.common, "noSubscription");

const NoSubscription: FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();
  const [currentView, setCurrentView] = useState<"no-sub" | "pricing-table">("no-sub");

  const { subscription } = useStripeSubscription();
  const user = useStoreState((state) => state.account.user);

  const selectedCompany = user?.companies?.find((el) => el.id === Number(companyId));
  const isOnboardingCompleted = !isNil(selectedCompany) && !selectedCompany?.notCompletedOnboarding;

  const isSubscriptionOwner = user?.email === subscription?.buyerEmail;
  const isSubscriptionExist = !isNil(subscription?.stripeSubscriptionId);

  return (
    <PageContent>
      {currentView === "no-sub" ? (
        <div className={classes.container}>
          <div className="d-flex justify-content-center">
            <div>
              {isSubscriptionExist ? (
                <Ui.xl bold className="mb-3">
                  {t("title")}
                </Ui.xl>
              ) : (
                <Ui.xl bold className="mb-3">
                  {t("titleNoSubscription")}
                </Ui.xl>
              )}

              <P.m>{t("description")}</P.m>

              {subscription?.stripeSubscriptionId && !isSubscriptionOwner ? (
                <P.m className="mt-2">{t("upgradeInfo")}</P.m>
              ) : null}

              {isSubscriptionOwner || !subscription?.stripeSubscriptionId ? null : (
                <SubscriptionManagerInfo
                  buyerAvatarFilePath={subscription?.buyerAvatarFilePath}
                  buyerFirstName={subscription?.buyerFirstName}
                  buyerEmail={subscription?.buyerEmail}
                  buyerInitials={subscription?.buyerInitials}
                  buyerLastName={subscription?.buyerLastName}
                />
              )}

              <P.m className="mt-4">
                {t.el("curiousNoSubscriptionText", {
                  components: [
                    <Link key={1} to={VIEW_PRICES_LINK} className={classes["link"]} target="_blank" rel="noreferrer" />,
                  ],
                })}
              </P.m>
            </div>

            <Image className={classNames("ms-5", classes.image)} alt="lock image" src={lockImage} />
          </div>

          <div className={classNames("mt-5", classes["actions"])}>
            {isSubscriptionExist && isSubscriptionOwner ? (
              <Link to={`${getEMPath(["settings", "companyInformation"])}?tab=billing`}>
                <Button size="s" className="me-2">
                  {t("upgradeSubscription")}
                </Button>
              </Link>
            ) : null}

            {isOnboardingCompleted && !isSubscriptionExist ? (
              <Button
                size="s"
                className="me-2"
                onClick={() => {
                  setCurrentView("pricing-table");
                }}
              >
                Subscribe
              </Button>
            ) : null}

            <Button as="a" size="s" target="_blank" rel="noreferrer" variant="secondary" href={CONTACT_US_LINK}>
              {t("contactUs")}
            </Button>
          </div>
        </div>
      ) : (
        <div className={classes["pricing-container"]}>
          <Button
            variant="tertiary"
            isOnlyIcon
            iconLeft={<ArrowLeftIcon />}
            onClick={() => {
              setCurrentView("no-sub");
            }}
          />

          <StripePricingTable companyId={String(companyId)} />
        </div>
      )}
    </PageContent>
  );
};

export default NoSubscription;
