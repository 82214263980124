import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Form, useFormikContext } from "formik";
import { defaultTo } from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { ChecksGroup, Collapsible, P } from "common/components/atoms";
import InputFeedback from "common/components/atoms/input-blocks/input-feedback/input-feedback";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import DocumentsStep from "../../components/step/documents";
import DocumentsViewOnlyStep from "../../components/step/documets-view-only";
import SignatureStep from "../../components/step/signature";
import StakeholderInformationStep from "../../components/step/stakeholder-information";
import StakeholderInformationViewOnlyStep from "../../components/step/stakeholder-information-view-only";
import classes from "../../components/step/step.module.scss";
import { ThreeStepForm } from "../../components/step/useStepForm";
import ReceivePlanFooter from "../common/receive-plan-footer/receive-plan-footer";
import ReceiveSoPlanContext from "./receive-so-plan-context.wizard";

const t = createTranslation(TranslationNS.pages, "company.receivePlan");
const ReceiveSOPlanFormData = () => {
  useToastFormikValidator();

  const navigate = useNavigate();

  const { values, errors, handleBlur, handleChange, touched, isSubmitting, setSubmitting } =
    useFormikContext<ThreeStepForm>();
  const { planDetails } = ReceiveSoPlanContext.useStoreState((state) => state);

  const { invitedUser } = useStoreState((state) => state);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const planId = useMemo(() => Number(invitedUser.invitedUser?.entityId), [invitedUser.invitedUser?.entityId]);

  const { declinePlanThunk } = ReceiveSoPlanContext.useStoreActions((actions) => actions);

  const isExisting = Boolean(planDetails?.existingPlan);

  const declinePlanHandler = useCallback(
    async (declineReason: string) => {
      setSubmitting(true);
      try {
        const req = await declinePlanThunk({
          planId,
          approvalKey: String(invitedUser.invitedUser?.approvalKey),
          comment: declineReason,
        });

        if (req) {
          notify(t(isExisting ? "notifications.managerNotified" : "notifications.declineSuccess"), true, "success");

          setInvitedUser(null);
          navigate(getPath(["myPortfolio", "portfolio"]), { replace: true });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    },
    [
      declinePlanThunk,
      invitedUser.invitedUser?.approvalKey,
      isExisting,
      navigate,
      planId,
      setInvitedUser,
      setSubmitting,
    ]
  );

  return (
    <Form>
      <Collapsible defaultOpen headTitle={t("stepsToAccept.title")} withoutDivider>
        <div className="mt-3" />

        {isExisting ? (
          <StakeholderInformationViewOnlyStep
            title={t("stepsToAccept.stakeholderInformation.titleExisting")}
            stakeholder={planDetails?.stakeholder}
            description={t("RSA.steps.reviewStakeholder.description", { manager: planDetails?.planCreatorName })}
          />
        ) : (
          <StakeholderInformationStep
            hideTitle
            stakeholder={planDetails?.stakeholder}
            description={t("SO.steps.reviewStakeholder.description", { manager: planDetails?.planCreatorName })}
          />
        )}

        <div className="mt-3" />

        {isExisting || !values.documentsNeedsSignature ? (
          <DocumentsViewOnlyStep
            withStepShown={!isExisting && !values.documentsNeedsSignature}
            title={t("stepsToAccept.documents.titleExisting")}
            documents={defaultTo([], planDetails?.documentFiles)}
            description={t("SO.steps.acceptDocuments.descriptionExisting")}
          />
        ) : (
          <DocumentsStep
            documents={defaultTo([], planDetails?.documentFiles)}
            description={t("SO.steps.acceptDocuments.description")}
          />
        )}

        <div className="mt-3" />

        {!planDetails?.documentsNeedsSignature ? null : (
          <SignatureStep description={t("SO.steps.acceptAgreement.description")} />
        )}
      </Collapsible>

      {isExisting ? (
        <ChecksGroup>
          <ChecksGroup.Check
            className={cn({
              [classes.isGreen]: values.stakeholderInfoCorrect,
            })}
            checked={values.stakeholderInfoCorrect}
            onChange={handleChange}
            name="stakeholderInfoCorrect"
            onBlur={handleBlur}
            label={t("stepsToAccept.confirmingInformationIsCorrect")}
          />
          <InputFeedback isTouched={touched.stakeholderInfoCorrect} error={errors.stakeholderInfoCorrect} />
        </ChecksGroup>
      ) : (
        <P.l className="mt-3">{t("approvingExplanation")}</P.l>
      )}

      <ReceivePlanFooter isExisting={isExisting} isLoading={isSubmitting} onDecline={declinePlanHandler} />
    </Form>
  );
};

export default ReceiveSOPlanFormData;
