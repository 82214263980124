import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Collapsible, LinkHelpText, P, Table, Ui } from "common/components/atoms";
import { ChevronDownIcon, PlusIcon, QuestionCircleIcon } from "common/icons/svg";
import { CollapsibleHeaderProps, sortingParams } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import IssueEquityContext from "pages/equity-management/ownership/financial-instruments/IssueEquityContext";
import { createTranslation, TranslationNS } from "translation";

import { IssueEquityConvertibleLoanDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import ConvertibleLoanTableBody from "./ConvertibleLoanTableBody";
import ConvertibleLoanTableFooter from "./ConvertibleLoanTableFooter";
import ConvertibleLoanTableHead from "./ConvertibleLoanTableHead";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan"),
  createTranslation(TranslationNS.common, "noAccess"),
];

export type ConvertibleLoanTableSortValue = {
  field: keyof IssueEquityConvertibleLoanDTO;
  type: keyof typeof sortingParams;
};

const ConvertibleLoanTable = () => {
  const { hasFullAccess } = useFeatures(FEATURES.issueEquity);

  const issueEquity = IssueEquityContext.useStoreState((state) => state.issueEquity);
  const setIsConvertibleLoanEditPanelOpen = IssueEquityContext.useStoreActions(
    (actions) => actions.setIsConvertibleLoanEditPanelOpen
  );

  const [activeSortedValue, setActiveSortedValue] = useState<ConvertibleLoanTableSortValue>({
    field: "stakholderName",
    type: sortingParams.inc,
  });

  const rows = useMemo(
    () => [
      {
        key: "stakholderName",
        value: t("table.head.stakeholders"),
      },
      {
        key: "loanAmount",
        value: t("table.head.loanAmount"),
      },

      {
        key: "discount",
        value: t("table.head.discount"),
      },
      {
        key: "interestRate",
        value: t("table.head.interestRate"),
      },
      {
        key: "expirationDate",
        value: t("table.head.convertionDate"),
      },
      {
        key: "status",
        value: t("table.head.status"),
      },
    ],
    []
  );

  const handleCreate = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setIsConvertibleLoanEditPanelOpen({ open: true });
    },
    [setIsConvertibleLoanEditPanelOpen]
  );

  const CollapsibleHeader = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div className={classes["collapsible"]} onClick={onClick}>
          <div className="d-flex align-items-center">
            <Ui.m bold className="me-1">
              {t("table.title")}
            </Ui.m>

            <LinkHelpText title="" content={t("table.infoDescription")}>
              <QuestionCircleIcon width={20} height={20} color={scssVariables.foregroundLow} />
            </LinkHelpText>
          </div>
          <div className="d-flex">
            <Button
              size="s"
              isOnlyIcon
              variant="secondary"
              isDisabled={!hasFullAccess}
              className={cn(classes["add-btn"], "me-5")}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : ""}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              onClick={handleCreate}
            >
              <PlusIcon />
            </Button>

            <div className={classes.icon}>
              <ChevronDownIcon
                fontSize={24}
                color={scssVariables.foregroundMedium}
                direction={activeEventKey ? "top" : "bottom"}
              />
            </div>
          </div>
        </div>
      );
    },
    [handleCreate, hasFullAccess]
  );

  return (
    <Collapsible defaultOpen className="mb-5" headTitle={t("table.title")} Header={CollapsibleHeader}>
      {R.or(R.isNil(issueEquity?.convertibleLoans), !issueEquity?.convertibleLoans?.length) ? (
        <div className={classes["empty-container"]}>
          <div className={classes["empty"]}>
            <P.s>{t("empty")}</P.s>
          </div>
        </div>
      ) : (
        <Table
          headComponent={
            <ConvertibleLoanTableHead
              rows={rows}
              sortedValue={activeSortedValue}
              setSortedValue={setActiveSortedValue}
            />
          }
          bodyComponent={
            <ConvertibleLoanTableBody
              sortedValue={activeSortedValue}
              data={R.defaultTo([], issueEquity?.convertibleLoans)}
            />
          }
          footComponent={
            <ConvertibleLoanTableFooter
              amount={(issueEquity?.convertibleLoans || []).reduce((acc, curr) => acc + curr.loanAmount, 0)}
            />
          }
        />
      )}
    </Collapsible>
  );
};

export default ConvertibleLoanTable;
