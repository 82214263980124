import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { defaultTo } from "ramda";
import * as Yup from "yup";

import { BreggCompanyShareholders } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { LegalEntityEnum } from "../components/importOwnership.types";
import { fields, OwnershipFormValues } from "../import-ownership/useImportOwnershipForm";

const t = createTranslation(TranslationNS.validation);

const useFoundersForm = (founders: BreggCompanyShareholders[]) => {
  const { i18n } = useTranslation();

  const initialValues = useMemo<Omit<OwnershipFormValues, "dateOfOwnership" | "shareClassName">[]>(() => {
    if (founders.length > 0) {
      return founders.map((item) => ({
        [fields.firstName]: defaultTo("", item?.firstName),
        [fields.lastName]: defaultTo("", item?.lastName),
        [fields.companyName]: defaultTo("", item?.companyName),
        [fields.organizationNumber]: defaultTo("", item?.organizationNumber),
        [fields.email]: defaultTo("", item?.email),
        [fields.address]: defaultTo("", item?.address),
        [fields.numberOfShares]: defaultTo(undefined, item?.numberOfShares),
        [fields.countryId]: defaultTo(166, item?.countryId),
        [fields.isCompanyOwned]: item.isCompanyOwned ? LegalEntityEnum.COMPANY : LegalEntityEnum.PERSON,
        [fields.stakeholderId]: defaultTo(undefined, item?.stakeholderId),
      }));
    }

    return [
      {
        [fields.firstName]: "",
        [fields.lastName]: "",
        [fields.companyName]: "",
        [fields.organizationNumber]: "",
        [fields.email]: "",
        [fields.address]: "",
        [fields.numberOfShares]: undefined,
        [fields.countryId]: 166,
        [fields.isCompanyOwned]: LegalEntityEnum.PERSON,
        [fields.stakeholderId]: undefined,
      },
    ];
  }, [founders]);

  const validationSchema = useMemo(() => {
    return Yup.array().of(
      Yup.object().shape({
        [fields.firstName]: Yup.string().when(fields.isCompanyOwned, {
          is: (legalEntityType: number) => legalEntityType === LegalEntityEnum.PERSON,
          then: Yup.string().required(t("required")),
        }),
        [fields.lastName]: Yup.string().when(fields.isCompanyOwned, {
          is: (legalEntityType: number) => legalEntityType === LegalEntityEnum.PERSON,
          then: Yup.string().required(t("required")),
        }),
        [fields.companyName]: Yup.string().when(fields.isCompanyOwned, {
          is: (legalEntityType: number) => legalEntityType === LegalEntityEnum.COMPANY,
          then: Yup.string().required(t("required")),
          otherwise: Yup.string().equals([""], t("required")),
        }),
        [fields.isCompanyOwned]: Yup.number().min(0, t("required")),
        [fields.numberOfShares]: Yup.number()
          .nullable(true)
          .transform((_, val) => (+val === Number(+val) ? +val : null))
          .integer(t("integer"))
          .min(1, t("required"))
          .required(t("required")),
        [fields.email]: Yup.string().email(t("invalidEmail")),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return { initialValues, validationSchema };
};

export default useFoundersForm;
