import { FC } from "react";
import classNames from "classnames";

import { P } from "common/components/atoms";
import { InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertNote.conversion");

const HelperHint: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      style={{ padding: "12px 16px", backgroundColor: scssVariables.element2, color: scssVariables.foregroundMedium }}
      className={classNames(className, "d-flex")}
    >
      <InformationCircleIcon
        width={24}
        height={24}
        className="flex-sm-shrink-0 me-1"
        color={scssVariables.foregroundMedium}
      />
      <P.s>{t("herlperHintDescription")}</P.s>
    </div>
  );
};

export default HelperHint;
