import { FC, useMemo } from "react";
import { defaultTo, isEmpty } from "ramda";

import { ExercisingHistory, OngoingExercises, SingleAvailableToExerciseItem } from "common/components/atoms";
import { DocumentList, EventHistory } from "common/components/molecules";
import {
  AgreementUIType,
  InstrumentTypesNamesEnum,
  PlanStatusesBasedOnAPIStatusId,
  VestingTypesEnum,
} from "common/enums/enum";
import useCurrencyById from "common/hooks/useCurrencyById";
import { ExerciseRequestStatusEnum } from "common/types/Exxercise.types";

import SOAgreementContext from "../../../contexts/SOAgreementContext";
import MilestonesModal from "../../common/agreement-actions/milestones-modal/MilestonesModal";
import ExpiredInfo from "../../common/expired-info/ExpiredInfo";
import Milestones from "../../common/milestones/Milestones";
import TerminationInfo from "../../common/termination-info/TerminationInfo";
import ValuationChart from "../../common/valuation-chart/ValuationChart";
import VestedAndExercisedChart from "../../common/vested-and-exercised-chart/VestedAndExercisedChart";
import MarkedSharesAsSold from "../MarkedSharesAsSold/MarkedSharesAsSold";
import Overview from "../overview/Overview";
import SOPlanDetails from "../so-agreement-details/SOAgreementDetails";

/**
 * Description: so agreement main template that's using into slide and regular UI types.
 * Contains all information for  stock options agreement type
 */

const SOAgreementTemplate: FC<{ type?: AgreementUIType; onRequestClick?: (val?: any) => void }> = ({
  type,
  onRequestClick,
}) => {
  const { agreementDetails, features } = SOAgreementContext.useStoreState((state) => state);
  const { getAgreementDetailsThunk } = SOAgreementContext.useStoreActions((actions) => actions);

  const { currencySymbol } = useCurrencyById(agreementDetails?.currencyId);

  const isAgreementTerminated = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.terminated;
  const isAgreementExpired = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.expired;

  const exercisingData = useMemo(
    () => ({
      ongoing: defaultTo([], agreementDetails?.exerciseRequests).filter(
        (el) =>
          el.exerciseRequestStatus === ExerciseRequestStatusEnum.AwaitingApproval ||
          el.exerciseRequestStatus === ExerciseRequestStatusEnum.OrderPlaced ||
          el.exerciseRequestStatus === ExerciseRequestStatusEnum.PendingPayment
      ),
      history: defaultTo([], agreementDetails?.exerciseRequests).filter(
        (el) =>
          el.exerciseRequestStatus === ExerciseRequestStatusEnum.SharesIssued ||
          el.exerciseRequestStatus === ExerciseRequestStatusEnum.Declined
      ),
    }),
    [agreementDetails]
  );

  return (
    <>
      <ExpiredInfo className="mb-3" type={InstrumentTypesNamesEnum.OPTION} />

      <TerminationInfo className="mb-3" type={InstrumentTypesNamesEnum.OPTION} />

      <MarkedSharesAsSold />

      <Overview className="mb-3" />

      {features?.exercising && !isAgreementExpired && defaultTo(0, agreementDetails?.availableForPurchaseShares) > 0 ? (
        <SingleAvailableToExerciseItem
          companyName={defaultTo("", agreementDetails?.companyName)}
          exercisePrice={defaultTo(0, agreementDetails?.exercisePrice)}
          planId={defaultTo(0, agreementDetails?.id)}
          sharePrice={defaultTo(0, agreementDetails?.sharePrice)}
          vestedNotExercised={defaultTo(0, agreementDetails?.availableForPurchaseShares)}
          currencySymbol={defaultTo("", currencySymbol)}
          profit={defaultTo(true, agreementDetails?.profit)}
          profitLossValue={defaultTo(0, agreementDetails?.profitLossValue)}
          terminated={isAgreementTerminated}
          expirationDate={agreementDetails?.optionsExpirationDate}
          handleSelect={() => {
            onRequestClick?.(agreementDetails);
          }}
        />
      ) : null}

      {features?.ongoingExercises && !isAgreementExpired && !isEmpty(exercisingData.ongoing) ? (
        <OngoingExercises ongoingExercises={exercisingData.ongoing} currencyID={agreementDetails?.currencyId} />
      ) : null}

      {features?.exercising && !isEmpty(exercisingData.history) ? (
        <ExercisingHistory history={exercisingData.history} />
      ) : null}

      {agreementDetails?.vestingTypeId !== VestingTypesEnum.MILESTONE_VESTING && (
        <VestedAndExercisedChart
          className="my-3"
          stockOptionsPlan={agreementDetails}
          isLosted={isAgreementTerminated || isAgreementExpired}
        />
      )}

      <Milestones className="my-3" type={InstrumentTypesNamesEnum.OPTION} />

      <ValuationChart agreement={agreementDetails} />

      <EventHistory collapsible data={agreementDetails?.eventHistory} />

      <SOPlanDetails />

      <DocumentList
        className="mt-3"
        documents={agreementDetails?.documentFiles || []}
        isDeleting={agreementDetails?.planStatusId !== PlanStatusesBasedOnAPIStatusId.active}
        cbAfterAction={() => {
          getAgreementDetailsThunk(Number(agreementDetails?.id));
        }}
      />

      <MilestonesModal type={InstrumentTypesNamesEnum.OPTION} />
    </>
  );
};

export default SOAgreementTemplate;
