import { DocumentationDetailsFields } from "../form-sections/documentation-details/form-fields";
import { ReceiverDetailsFields } from "../form-sections/receiver-details/form-fields";
import { SharesDetailsFields } from "../form-sections/shares-details/form-fields";
import { TransactionDetailsFields } from "../form-sections/transaction-details/form-fields";

export const IssueSharesFields = {
  companyId: "companyId",
  ...ReceiverDetailsFields,
  ...SharesDetailsFields,
  ...TransactionDetailsFields,
  ...DocumentationDetailsFields,
} as const;

export const EditIssueSharesFields = {
  transactionId: "transactionId",
  ...IssueSharesFields,
} as const;
