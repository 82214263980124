import { FC } from "react";
import cn from "classnames";

import { P, Ui } from "common/components/atoms/Typography";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { scssVariables } from "../../../../../common/utils/constants";
import { Milestone as MilestoneProps } from "../types";
import classes from "./milestone.module.scss";

const t = createTranslation(TranslationNS.pages, "company.receivePlan.milestones");

const Milestone: FC<MilestoneProps> = ({ title, description, targetDate, numberOfShares }) => {
  return (
    <div className={cn(classes.milestone, "p-3")}>
      <Ui.l bold style={{ color: scssVariables.primary1 }} className="mb-1">
        {title}
      </Ui.l>
      {description && <P.s>{description}</P.s>}
      <div className="d-flex my-3">
        <Ui.m className="me-half">{t("targetDate") + ":"}</Ui.m>
        <Ui.m bold style={{ color: scssVariables.additional8500 }}>
          {transformDateToCommonDateFormat(targetDate)}
        </Ui.m>
      </div>
      <div className="d-flex">
        <Ui.m className="me-half">{t("numberOfOptions") + ":"}</Ui.m>
        <Ui.m bold style={{ color: scssVariables.additional8500 }}>
          {numberOfShares}
        </Ui.m>
      </div>
    </div>
  );
};

export default Milestone;
