import { Action, action, createContextStore } from "easy-peasy";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";

import { CapitalIncreaseFormGetDTO } from "../../../types";
import { ImportTransactionFormValues } from "./steps/capital-increase-transactions-table/use-import-transaction-form";

interface CapitalIncreaseContextModel {
  isLoading: boolean;
  currentBundle: CapitalIncreaseFormGetDTO;
  transactionsInProgress: ImportTransactionFormValues[];
  step: number;
  maxStep: number;
  defaultShareClassId?: number;
  defaultEventTypeId?: number;
  setIsLoading: Action<this, boolean>;
  setCurrentBundle: Action<this, CapitalIncreaseFormGetDTO>;
  setTransactionsInProgress: Action<this, ImportTransactionFormValues[]>;
  setStep: Action<this, number>;
  setMaxStep: Action<this, number>;
  setDefaultShareClassId: Action<this, number>;
  setDefaultEventTypeId: Action<this, number>;
}

const CapitalIncreaseContext = createContextStore<CapitalIncreaseContextModel>(
  {
    isLoading: false,
    currentBundle: {
      transactionId: undefined,
      transactionBundleId: undefined,
      transactedAt: createDateString(new Date(), true),
      description: "",
      eventName: "",
      sharePrice: 0,
      numberOfShares: 0,
      statusId: 1,
      documentFiles: [],
      transactions: [],
    },
    transactionsInProgress: [],
    step: 1,
    maxStep: 2,
    defaultShareClassId: undefined,
    defaultEventTypeId: undefined,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),
    setCurrentBundle: action((state, payload) => {
      state.currentBundle = payload;
    }),
    setTransactionsInProgress: action((state, payload) => {
      state.transactionsInProgress = payload;
    }),
    setStep: action((state, payload) => {
      state.step = payload;
    }),
    setMaxStep: action((state, payload) => {
      state.maxStep = payload;
    }),
    setDefaultShareClassId: action((state, payload) => {
      state.defaultShareClassId = payload;
    }),
    setDefaultEventTypeId: action((state, payload) => {
      state.defaultEventTypeId = payload;
    }),
  },
  {
    name: "capitalIncrease",
  }
);
export default CapitalIncreaseContext;
