import axios, { AxiosResponse } from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { InvestmentEntity } from "store/types";

import { OwnershipPlanDocumentFile, Stakeholder } from "../components/receive.types";

export type ConvertibleLoanDTO = {
  id: number;
  companyId: number;
  companyName: string | null;
  currencyId: number;
  discount: number;
  documentsNeedsSignature: boolean;
  documentFiles: OwnershipPlanDocumentFile[];
  expirationDate: string;
  interestRate: number;
  conversionPrice: number;
  loanAmount: number;
  shareClass: string;
  shareClassComment: string | null;
  shareClassDecided: boolean;
  valuationCap: number;
  stakeholder: Stakeholder;
};

export type AcceptConvertibleLoan = {
  signature?: File;
  approvalKey: string;
};
interface ConvertibleNoteWizardContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  companyName: string;
  setCompanyName: Action<this, this["companyName"]>;

  selectedCompany: InvestmentEntity | null;
  setSelectedCompany: Action<this, this["selectedCompany"]>;

  isRemoteCompanySelected: boolean;
  setIsRemoteCompanySelected: Action<this, this["isRemoteCompanySelected"]>;

  companyOrgNumber: string;
  setCompanyOrgNumber: Action<this, this["companyOrgNumber"]>;

  isHoldingCompanySelected: boolean;
  setIsHoldingCompanySelected: Action<this, this["isHoldingCompanySelected"]>;

  signature: File | null;
  setSignature: Action<this, this["signature"]>;

  convertibleLoanDetails: ConvertibleLoanDTO | null;
  setConvertibleLoanDetails: Action<this, this["convertibleLoanDetails"]>;
  getConvertibleLoanDetails: Thunk<this, string, any, {}, Promise<AxiosResponse<ConvertibleLoanDTO>>>;
  acceptConvertibleLoanThunk: Thunk<this, AcceptConvertibleLoan>;
  declineConvertibleLoanThunk: Thunk<this, { companyId: number; convertibleLoanId: number }>;
}

const ConvertibleLoanWizardContext = createContextStore<ConvertibleNoteWizardContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    companyName: "",
    setCompanyName: action((state, payload) => {
      state.companyName = payload;
    }),

    selectedCompany: null,
    setSelectedCompany: action((state, payload) => {
      state.selectedCompany = payload;
    }),

    isRemoteCompanySelected: false,
    setIsRemoteCompanySelected: action((state, payload) => {
      state.isRemoteCompanySelected = payload;
    }),

    companyOrgNumber: "",
    setCompanyOrgNumber: action((state, payload) => {
      state.companyOrgNumber = payload;
    }),

    isHoldingCompanySelected: false,
    setIsHoldingCompanySelected: action((state, payload) => {
      state.isHoldingCompanySelected = payload;
    }),

    signature: null,
    setSignature: action((state, payload) => {
      state.signature = payload;
    }),

    convertibleLoanDetails: null,
    setConvertibleLoanDetails: action((state, payload) => {
      state.convertibleLoanDetails = payload;
    }),
    getConvertibleLoanDetails: thunk(async (actions, key) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<ConvertibleLoanDTO>(
          `/api/equity-management/issue-equity/convertible-loan/details/${key}`
        );

        if (request.status === 200) {
          actions.setConvertibleLoanDetails(request.data);
        }
        return request;
      } catch (e) {
        return await Promise.reject(e);
      } finally {
        actions.setIsLoading(false);
      }
    }),
    acceptConvertibleLoanThunk: thunk(async (_, payload) => {
      try {
        const formData = new FormData();
        formData.append("approvalKey", String(payload.approvalKey));
        if (payload.signature) {
          formData.append("signature", payload.signature);
        }

        return await axios.post(
          "/api/equity-management/issue-equity/convertible-loan/accept-convertible-loan-issued",
          formData
        );
      } catch (e) {
        return await Promise.reject(e);
      }
    }),
    declineConvertibleLoanThunk: thunk(async (actions, payload) => {
      try {
        const formData = new FormData();
        formData.append("CompanyId", String(payload.companyId));
        formData.append("ConvertibleLoanId", String(payload.convertibleLoanId));

        return await axios.post("/api/equity-management/issue-equity/convertible-loan/cancel", formData);
      } catch (e) {
        return await Promise.reject(e);
      }
    }),
  },
  {
    name: "Convertible loan context.",
  }
);

export default ConvertibleLoanWizardContext;
