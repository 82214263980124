import { FC } from "react";

import { Ui } from "common/components/atoms/Typography";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import classes from "./WarrantDetails.module.scss";

type WarrantDetailsProps = {
  title: string;
  numberOfShares: number;
  expirationDate?: string | null;
  strikePrice: number;
  warrantPrice: number;
  companyPercentage?: number;
  representedBy: string;
  email?: string;
};

const t = createTranslation(TranslationNS.common, "molecules.warrantDetails");

const WarrantDetails: FC<WarrantDetailsProps> = ({
  title,
  strikePrice,
  warrantPrice,
  numberOfShares,
  companyPercentage,
  expirationDate,
  representedBy,
  email,
}) => {
  const fNumber = useFormatNumbers();

  return (
    <div className={classes.container}>
      <Ui.m bold className="mb-3">
        {title}
      </Ui.m>

      <Ui.m>
        {t("numberOfShares")} <strong>{fNumber(numberOfShares, "amount")}</strong>
      </Ui.m>

      {expirationDate ? (
        <Ui.m className="my-2">
          {t("expirationDate")} <strong>{transformDateToCommonDateFormat(expirationDate)}</strong>
        </Ui.m>
      ) : null}

      <Ui.m className="d-flex my-2">
        {t("strikePrice")}&nbsp;
        <strong className="d-flex">{fNumber(strikePrice, "unitPrice")}</strong>
      </Ui.m>

      <Ui.m className="d-flex my-2">
        {t("warrantPrice")}&nbsp;
        <strong className="d-flex">{fNumber(warrantPrice, "unitPrice")}</strong>
      </Ui.m>

      {companyPercentage ? (
        <Ui.m className="my-2">
          {t("percentageCompany")}&nbsp;
          <strong>{fNumber(companyPercentage, "percent")}</strong>
        </Ui.m>
      ) : null}

      <Ui.m className="my-2">
        {t("representedBy")}&nbsp;
        <strong>{representedBy}</strong>
      </Ui.m>

      {email ? (
        <Ui.m className="mt-2">
          {t("email")}&nbsp;
          <strong>{email}</strong>
        </Ui.m>
      ) : null}
    </div>
  );
};

export default WarrantDetails;
