import { ChangeEvent, FC, KeyboardEventHandler, useId, useRef } from "react";
import { FormControl, FormGroup, FormLabel } from "react-bootstrap";
import cn from "classnames";

import { MAX_IMAGE_FILE_SIZE } from "common/utils/constants";

import Button from "../Button/Button";
import classes from "./FileUploadButton.module.scss";

export type FileUploadButtonProps = {
  label: string;
  onSelectedFile(file: File): void;
  variant?: "secondary" | "tertiary";
  icon?: JSX.Element;
  acceptedFormats?: string;
  size?: "s" | "m";
  isDisabled?: boolean;
  setError?: (error: string) => void;
};

const FileUploadButton: FC<FileUploadButtonProps> = ({
  label,
  onSelectedFile,
  variant = "secondary",
  icon,
  acceptedFormats,
  size = "m",
  isDisabled,
  setError,
}) => {
  const uId = useId();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const selectFile = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      e.preventDefault();
      const file = e?.target?.files?.[0] as File;

      if (file.size > MAX_IMAGE_FILE_SIZE) {
        setError?.("Size is too big. You can upload files up to 5 MB.");

        if (inputRef?.current) {
          inputRef.current.value = null as unknown as any;
        }
        return;
      }

      if (file) {
        setError?.("");
        onSelectedFile(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLabelKeyDown: KeyboardEventHandler<HTMLLabelElement> = (event) => {
    if (event.key === "Enter") {
      inputRef.current?.click();
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };

  return (
    <div className={cn("d-flex", classes.fileUploadButton)} data-testid="fileupload-button-container-test-id">
      <FormGroup>
        <FormControl
          ref={inputRef}
          type="file"
          id={`picture-upload-${uId}`}
          data-testid="fileupload-input-test-id"
          onChange={selectFile}
          accept={acceptedFormats}
          style={{ display: "none" }}
          disabled={isDisabled}
        />
        <FormLabel
          htmlFor={`picture-upload-${uId}`}
          className="d-flex w-100 m-0"
          tabIndex={0}
          onKeyDown={handleLabelKeyDown}
        >
          <Button
            type="button"
            variant={variant}
            iconRight={icon}
            onClick={handleButtonClick}
            size={size}
            isDisabled={isDisabled}
          >
            {label}
          </Button>
        </FormLabel>
      </FormGroup>
    </div>
  );
};

export default FileUploadButton;
