import { FC, ReactNode, useCallback } from "react";
import { Offcanvas } from "react-bootstrap";
import classNames from "classnames";
import { isNil } from "ramda";

import Button from "common/components/atoms/Button/Button";
import { InstrumentTypesNamesEnum } from "common/enums/enum";
import { CloseIcon, UnlistedIcon } from "common/icons/svg";

import AgreementHeader from "../agreement-header/AgreementHeader";
import { getCurrentAgreementContext } from "../functions";
import classes from "./AgreementSlideContainer.module.scss";

/**
 * Description: common slide component for any agreement type, just open and show children info
 * with specific styles
 * @param { type } InstrumentTypesNamesEnum Type for specific agreement. Will choose the necessary context for agreement
 * @param { children } ReactNode Internal layout for slide panel
 */

type AgreementSlideContainerProps = {
  type: InstrumentTypesNamesEnum;
  children?: ReactNode;
};

const AgreementSlideContainer: FC<AgreementSlideContainerProps> = ({ type, children }) => {
  const { agreementDetails } = getCurrentAgreementContext(type).useStoreState((state) => state);
  const { setAgreementDetails } = getCurrentAgreementContext(type).useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setAgreementDetails(null);
  }, [setAgreementDetails]);

  return (
    <Offcanvas
      scroll
      placement="end"
      show={!isNil(agreementDetails)}
      className={classes["container"]}
      backdropClassName={classes["backdrop"]}
      onHide={handleClose}
    >
      <Offcanvas.Body className="m-0 p-0">
        <div className={classNames("ps-3 pe-1", classes["header"])}>
          <UnlistedIcon width={68} height={68} />

          <Button variant="dark" size="s" isOnlyIcon className="p-0" onClick={handleClose}>
            <CloseIcon />
          </Button>
        </div>

        <div className={classes["content"]}>
          <AgreementHeader type={type} />

          <div style={{ maxWidth: 920 }}>{children}</div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AgreementSlideContainer;
