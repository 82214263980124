import { FC, useCallback, useMemo } from "react";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";

import { SlidePanel } from "common/components/atoms";
import { SOAgreementContext } from "common/components/organisms";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import RequestToExerciseForm from "./RequestToExerciseForm/RequestToExerciseForm";
import classes from "./RequestToExerciseOffCanvas.module.scss";

export interface RequestToExerciseFormType {
  amountOptions: number;
  exerciseAllOption: number;
  isTermAndCondition?: boolean;
  signature?: string;
}

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "myPortfolio.exercising.requestToExerciseOffCanvas"),
  createTranslation("validation"),
];

const RequestToExerciseContent: FC = () => {
  const plan = SOAgreementContext.useStoreState((state) => state.activeExercisablePlan);
  const setActiveExercisablePlan = SOAgreementContext.useStoreActions((actions) => actions.setActiveExercisablePlan);
  const addUserExercisingThunk = SOAgreementContext.useStoreActions((actions) => actions.addUserExercisingThunk);
  const getAgreementDetailsThunk = SOAgreementContext.useStoreActions((actions) => actions.getAgreementDetailsThunk);

  const formikInitialValues = useMemo(() => {
    return {
      amountOptions: plan?.availableForPurchaseShares,
      isTermAndCondition: false,
    };
  }, [plan?.availableForPurchaseShares]);

  const validationRequestSchema = useMemo(
    () =>
      Yup.object().shape({
        amountOptions: Yup.number()
          .min(1, tValidation("minNumber", { number: 1 }))
          .max(
            plan?.availableForPurchaseShares || 0,
            tValidation("maxNumber", { number: plan?.availableForPurchaseShares || 0 })
          )
          .integer()
          .required(tValidation("required")),
        isTermAndCondition: Yup.boolean().required(tValidation("required")).isTrue(),
      }),
    [plan?.availableForPurchaseShares]
  );

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      if (plan?.id && values.isTermAndCondition) {
        addUserExercisingThunk({
          planId: plan.id,
          numberOfOptions: values.amountOptions,
        }).then(() => {
          notify(t("yourRequestHasBeenSent"), true, "success", 3000, false, "top-center");
          setActiveExercisablePlan(null);
          getAgreementDetailsThunk(Number(plan?.id));
        });
      }
    },
    [addUserExercisingThunk, getAgreementDetailsThunk, plan?.id, setActiveExercisablePlan]
  );

  return (
    <Formik initialValues={formikInitialValues} validationSchema={validationRequestSchema} onSubmit={handleSubmit}>
      <Form>{plan && <RequestToExerciseForm plan={plan} />}</Form>
    </Formik>
  );
};

const RequestToExerciseOffCanvas: FC<{ open: boolean }> = ({ open }) => {
  const setActiveExercisablePlan = SOAgreementContext.useStoreActions((actions) => actions.setActiveExercisablePlan);
  const handleClose = () => {
    setActiveExercisablePlan(null);
  };

  return (
    <SlidePanel show={open} onHide={handleClose} className={classes.wrapper}>
      <RequestToExerciseContent />
    </SlidePanel>
  );
};

export default RequestToExerciseOffCanvas;
