import { DocumentationDetailsFields } from "../../../form-sections/documentation-details/form-fields";

export const CapitalIncreaseGeneralFields = {
  transactionBundleId: "transactionBundleId",
  companyId: "companyId",
  eventName: "eventName",
  numberOfShares: "numberOfShares",
  sharePrice: "sharePrice",
  transactedAt: "transactedAt",
  description: "description",
  ...DocumentationDetailsFields,
} as const;
