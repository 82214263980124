import React, { FC, useMemo } from "react";
import { PointTooltip } from "@nivo/line";
import cn from "classnames";
import { format } from "date-fns";
import * as R from "ramda";

import { P, StepChart, Ui } from "common/components/atoms";
import { splitExercisedAndAvailableForPurchaseDataPoints, transformChartAxisToYearsOrQuarters } from "common/helpers";
import useFormatNumbers, { formatNumber } from "common/hooks/useFormatNumbers";
import EmptyChart from "common/icons/empty_chart.png";
import EmptyContainer from "common/icons/empty_container.png";
import { FilledArrowDownIcon } from "common/icons/svg";
import { stepPoint } from "common/types/Charts.types";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { CommonOwnershipPlanDetails } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "./VestedAndExercisedChart.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.agreement.vestedAndExercisedChart");

const renderTooltip: PointTooltip = ({ point }) => {
  return (
    <div className={classes.tooltip}>
      <Ui.xs bold color="foregroundMedium">
        {point?.data?.y as number} options
      </Ui.xs>
      <Ui.xs color="foregroundLow">
        {transformDateToCommonDateFormat(point?.data?.xFormatted as string, "MMM dd, yyyy")}
      </Ui.xs>

      <div className={classes.triangle}>
        <FilledArrowDownIcon width={25} height={25} color="white" direction="bottom" />
      </div>
    </div>
  );
};

const VestedLinearChartContent: FC<{
  data?: { id: string; color: string; data: stepPoint[] }[];
  agreement: CommonOwnershipPlanDetails | null;
  isLosted?: boolean;
}> = ({ data, agreement, isLosted }) => {
  const fNumber = useFormatNumbers();

  const bottomValues = useMemo(() => {
    if (!data) {
      return [];
    }

    return transformChartAxisToYearsOrQuarters({
      data: R.concat(data?.[0]?.data, data?.[1]?.data) || [],
    });
  }, [data]);

  const marginLeft =
    formatNumber(Math.max(...(R.pluck("y", agreement?.restrictedDataPoints || []) as number[]))).length * 8 + 10;

  return (
    <div>
      {R.isNil(data) || !data.length ? null : (
        <Ui.xs className={classes["label"]} color="foregroundLow">
          {t("shares")}
        </Ui.xs>
      )}

      {R.isNil(data) || !data.length ? (
        <>
          <div className={classes["empty-image-container"]}>
            <img alt="" src={EmptyContainer} />
            <img alt="" src={EmptyChart} />
          </div>

          <P.m className="mt-5">{t("empty")}</P.m>
        </>
      ) : (
        <StepChart
          height={200}
          disableLegend
          gridYValues={4}
          gridXValues={5}
          showPointOnHover
          tooltip={renderTooltip}
          data={splitExercisedAndAvailableForPurchaseDataPoints(
            R.defaultTo([], data),
            R.defaultTo(0, agreement?.availableForPurchaseShares)
          )}
          axisLeft={{
            tickSize: 0,
            tickValues: 4,
            tickPadding: 10,
            format: (value) => fNumber(value, "amount"),
          }}
          margin={{ left: marginLeft, right: 10, top: 20, bottom: 30 }}
          axisBottom={{
            tickSize: 0,
            renderTick: (tick) => {
              const isTickVisible = bottomValues?.includes(tick?.value);

              return isTickVisible ? (
                <foreignObject width={80} height={15} x={tick?.x - 40} y={tick?.y + 16}>
                  <Ui.xs color="foregroundMedium">{format(new Date(tick.value), "MMM dd, yyyy")}</Ui.xs>
                </foreignObject>
              ) : (
                <></>
              );
            },
          }}
        />
      )}

      {R.isNil(data) || !data.length ? null : (
        <div
          className={cn("ms-2 mt-4", classes["legend"], {
            [classes["is-losted"]]: isLosted,
          })}
        >
          <div>
            <span className="me-1" />
            <Ui.xs color="foregroundLow">{t("vestedAndExercised")}</Ui.xs>
          </div>

          <div className="ms-4">
            <span className="me-1" />
            <Ui.xs color="foregroundLow">{t("availableForPurchase")}</Ui.xs>
          </div>

          <div className="ms-4">
            <span className="me-1" />
            <Ui.xs color="foregroundLow">{t("waitingToVest")}</Ui.xs>
          </div>
        </div>
      )}
    </div>
  );
};

export default VestedLinearChartContent;
