import { FC } from "react";
import classNames from "classnames";

import { H, LinkHelpText, P } from "common/components/atoms";
import { QuestionCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import ReadMore from "../../../../../../molecules/ReadMore/ReadMore";
import SingleAgreementDetailSection from "../../../common/details/SingleAgreementDetailSection";
import useSOAgreementDetails, { SOPlanDetails } from "../useSOAgreementDetails";
import classes from "./PlanDetailsContent.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.agreement.agreementDetails");

type TerminationClauses = {
  retirement: number;
  retirementTimeUnit: string;
  terminationWithCause: number;
  terminationWithCauseTimeUnit: string;
  disability: number;
  disabilityTimeUnit: string;
  involuntaryTermination: number;
  involuntaryTerminationTimeUnit: string;
  voluntaryTermination: number;
  voluntaryTerminationTimeUnit: string;
  byPassingAway: number;
  byPassingAwayTimeUnit: string;
};

type PlanDetailsContentProps = {
  excludePostTerminationClauses: boolean;

  agreementDetails: (SOPlanDetails & TerminationClauses) | null;
};

const PlanDetailsContent: FC<PlanDetailsContentProps> = ({ excludePostTerminationClauses, agreementDetails }) => {
  const { agreementItems, postTerminationClauseItems } = useSOAgreementDetails(agreementDetails);

  return (
    <div className={classes["container"]}>
      <div className={classes["content"]}>
        {agreementItems.map((item, index) => (
          <SingleAgreementDetailSection
            key={index}
            title={item.title}
            subTitle={item.description}
            infoContent={item.content}
            isShareClass={item?.isShareClass}
            shareClassInfo={item?.shareClass}
          />
        ))}
      </div>

      <div className="d-flex my-4">
        <H.xxxs>{t("postTermination")}</H.xxxs>

        <LinkHelpText
          title={t("infoWidget.title", {
            infoTitle: t("agreementType"),
          })}
          content={t("infoWidget.SO.postTerminationCause")}
        >
          <QuestionCircleIcon color={scssVariables.foregroundMedium} style={{ marginLeft: 6 }} />
        </LinkHelpText>
      </div>

      <div className={classNames("pb-4", classes["content"])}>
        {excludePostTerminationClauses ? (
          <P.s style={{ color: scssVariables.foregroundMedium }}>{t("exercisingAfterTerminationProhibited")}</P.s>
        ) : (
          postTerminationClauseItems.map((el, index) => (
            <SingleAgreementDetailSection
              key={index}
              title={el.title}
              subTitle={el.description}
              infoContent={el.content}
            />
          ))
        )}
      </div>
      {agreementDetails?.essentialTerms && (
        <div className="mt-3">
          <H.xxxs className="mb-1">Essential terms from agreement</H.xxxs>
          <ReadMore description={agreementDetails?.essentialTerms} modalTitle="Essential terms">
            <P.s style={{ whiteSpace: "break-spaces" }}>{agreementDetails?.essentialTerms}</P.s>
          </ReadMore>
        </div>
      )}
    </div>
  );
};

export default PlanDetailsContent;
