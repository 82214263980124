import { FC, useCallback } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import InputFeedback from "common/components/atoms/input-blocks/input-feedback/input-feedback";
import Signature from "common/components/atoms/Signature/Signature";
import { H, P, Ui } from "common/components/atoms/Typography";
import { createTranslation, TranslationNS } from "translation";

import classes from "./step.module.scss";
import { ThreeStepForm } from "./useStepForm";

const t = createTranslation(TranslationNS.common, "receive.stakeholder");

type SignatureStepProps = {
  title?: string;
  description?: string;
};

const SignatureStep: FC<SignatureStepProps> = ({ title, description }) => {
  const { setFieldValue, errors, touched } = useFormikContext<ThreeStepForm>();

  const handleChange = useCallback(
    (e: File | null) => {
      setFieldValue("signature", e);
    },
    [setFieldValue]
  );
  return (
    <div
      className={cn("d-flex py-3 px-4", classes["borders"], {
        "is-invalid": errors.signature && touched.signature,
      })}
    >
      <Ui.l className="pt-half me-3">3.</Ui.l>
      <div>
        <H.xxs className="mb-1">{title ? title : t("steps.signAgreements.title")}</H.xxs>
        <P.s className="mb-4">{description}</P.s>

        <Signature onSubmit={handleChange} />
        <InputFeedback isTouched={touched.signature} error={errors.signature} />

        <P.s className="mt-1">{t("steps.signAgreements.signText")}</P.s>
      </div>
    </div>
  );
};

export default SignatureStep;
