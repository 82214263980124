import { FC, ReactNode, SyntheticEvent, useCallback, useEffect } from "react";
import classNames from "classnames";

import { QuestionCircleIcon } from "common/icons/svg";

import { HelperAnswer } from "../Helper";
import { useHelperQuestion } from "../helperHooks";
// import { useHelperQuestion } from "../hooks";
import classes from "./Question.module.scss";

type HelperBannerQuestionProps = {
  children?: ReactNode;
  questionId: string;
  className?: string;
  questionMarkClassName?: string;
  labelLeft?: string | ReactNode;
  labelRight?: string | ReactNode;
  isActive?: boolean;
  type?: "input" | "switcher";
  answer?: HelperAnswer | string;
};
const Question: FC<HelperBannerQuestionProps> = ({
  questionId,
  className,
  questionMarkClassName,
  labelLeft,
  labelRight,
  isActive,
  children,
  type,
  answer,
}) => {
  const { active, setActive, setAnswer } = useHelperQuestion(questionId);

  const handleClick = useCallback(
    (e: SyntheticEvent) => {
      e?.stopPropagation();
      setActive(!active);
    },
    [active, setActive]
  );

  useEffect(() => {
    if (answer) {
      setAnswer(answer);
    }
  }, [active, answer, setAnswer]);

  useEffect(() => {
    if (typeof isActive === "boolean") {
      setActive(isActive);
    }
  }, [isActive, setActive]);

  const QuestionMark = useCallback(() => {
    return (
      <>
        {labelLeft && `${labelLeft} `}
        <span
          className={classNames(questionMarkClassName, classes.wrap, { [classes.active]: active })}
          data-queston={questionId}
        >
          <QuestionCircleIcon onClick={handleClick} />
        </span>
        {labelRight && `${labelRight} `}
      </>
    );
  }, [active, handleClick, labelLeft, labelRight, questionId, questionMarkClassName]);

  return (
    <>
      {children ? (
        <div
          className={classNames(classes.wrapContainer, className, {
            [classes.input]: type === "input",
            [classes.switcher]: type === "switcher",
          })}
        >
          {children}
          <QuestionMark />
        </div>
      ) : (
        <QuestionMark />
      )}
    </>
  );
};

export default Question;
