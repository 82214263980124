import { FC, useCallback, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, P, Ui } from "common/components/atoms";
import { InformationCircleIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CapTableStore from "../../store";
import classes from "./AddMoreShareholders.module.scss";
const t = createTranslation(TranslationNS.pages, "company.capTable.addMoreShareholders");

const AddMoreShareholders: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const [loading, setLoading] = useState(false);
  const { hasFullAccess } = useFeatures(FEATURES.capTable);

  const user = useStoreState((state) => state.account?.user);
  const showAddShareholderBanner = CapTableStore.useStoreState((state) => state.actualData?.showAddShareholderBanner);

  const { setShowAddShareholderBanner } = CapTableStore.useStoreActions((actions) => actions);

  const showRegistrationBanner = useMemo(() => {
    return user?.companies?.find((company) => company.id === Number(companyId))?.notCompletedOnboarding;
  }, [user?.companies, companyId]);

  const handleDontShowAgain = useCallback(() => {
    setLoading(true);
    axios
      .post("api/equity-management/cap-table/hide-add-shareholder", { companyId })
      .then(() => {
        setShowAddShareholderBanner(!showAddShareholderBanner);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [companyId, setShowAddShareholderBanner, showAddShareholderBanner]);

  if (showRegistrationBanner || !showAddShareholderBanner) return null;

  return (
    <div className={classes.wrap}>
      <div className="d-flex">
        <InformationCircleIcon className={classes.infoIcon} />
        <div>
          <Ui.m bold>{t("title")}</Ui.m>
          <P.s className="mt-1">
            {t.el("description", {
              components: [
                <Link key={1} to={getEMPath(["ownership", "transactions"])} />,
                <Link key={2} to={getEMPath(["plans", "agreements"])} />,
              ],
            })}
          </P.s>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          isDisabled={loading || !hasFullAccess}
          isLoading={loading}
          onClick={handleDontShowAgain}
          variant="tertiary"
          className={classes.btnDontShowAgain}
        >
          {t("dontShowAgain")}
        </Button>
      </div>
    </div>
  );
};

export default AddMoreShareholders;
