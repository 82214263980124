import { FC, ReactNode, useEffect } from "react";
import axios from "axios";
import { useFormikContext } from "formik";

import { PlanForm } from "../../../create-plan/types";

type ShareDetailsWrapperProps = {
  children: ReactNode;
};

const ShareDetailsWrapper: FC<ShareDetailsWrapperProps> = ({ children }) => {
  const { values, setFieldValue } = useFormikContext<PlanForm>();

  useEffect(() => {
    if (values.poolId) {
      axios.get<{ numberOfSharesLeft: number }>(`/api/pool/${values.poolId}`).then((response) => {
        setFieldValue("numberOfSharesLeft", response?.data?.numberOfSharesLeft || 0);
      });
    } else {
      setFieldValue("numberOfSharesLeft", -1);
    }
  }, [setFieldValue, values.poolId]);
  return <div>{children}</div>;
};

export default ShareDetailsWrapper;
