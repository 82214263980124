import { FC, useEffect, useMemo, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { Image } from "react-bootstrap";
import classNames from "classnames";
import debounce from "lodash.debounce";

import { Ui } from "common/components/atoms";
import FileUploadButton from "common/components/atoms/FileUploadButton/FileUploadButton";
import InputFeedback from "common/components/atoms/input-blocks/input-feedback/input-feedback";
import { ImagePreview } from "common/icons/svg";

import classes from "./UserAvatarUploader.module.scss";

const AvatarEditorUpdated = AvatarEditor as unknown as any;

type PropsTypes = {
  onUploadImage: (image: File | undefined) => void;
  title: string;
  btnText: string;
  previewImageUrl?: string | null;
  description?: string;
};

// TODO fix this component lifecycle and sizing

const UserAvatarUploader: FC<PropsTypes> = ({ previewImageUrl, title, btnText, onUploadImage }) => {
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [error, setError] = useState("");

  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const editorRef = useRef(null);

  const imageChangeHandler = useMemo(
    () =>
      debounce(
        () => {
          // @ts-ignore
          editorRef.current?.canvas.toBlob((blob) => {
            const editedImageFile = new File([blob], imageFile?.name || "", {
              type: imageFile?.type,
            });
            onUploadImage(editedImageFile);
          });
        },
        300,
        { maxWait: 600 }
      ),
    [onUploadImage, imageFile]
  );

  useEffect(() => {
    return () => {
      imageChangeHandler.cancel();
    };
  }, [imageChangeHandler]);

  useEffect(() => {
    if (error) {
      setImageFile(null);
      onUploadImage(undefined);
    }
  }, [error, onUploadImage, setImageFile]);

  return (
    <div className={classNames("d-flex flex-column align-items-center p-4", classes.avatarUploader)}>
      <Ui.m className={classNames("mb-1", classes.title)}>{title}</Ui.m>

      {imageFile && (
        <AvatarEditorUpdated
          ref={editorRef}
          borderRadius={150}
          border={0}
          scale={1}
          rotate={0}
          width={124}
          height={124}
          position={imagePosition}
          onPositionChange={setImagePosition}
          onImageChange={imageChangeHandler}
          onImageReady={imageChangeHandler}
          image={imageFile || ""}
        />
      )}

      {!imageFile && previewImageUrl && (
        <Image
          alt="image"
          width={124}
          height={124}
          roundedCircle
          src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${previewImageUrl}`}
        />
      )}
      {!imageFile && !previewImageUrl && <ImagePreview className={classes["preview"]} width={124} height={124} />}

      <div className="mt-2">
        <FileUploadButton label={btnText} onSelectedFile={setImageFile} size="s" setError={setError} />
      </div>

      {error ? <InputFeedback isTouched error={error} /> : null}
    </div>
  );
};

export default UserAvatarUploader;
