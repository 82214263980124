import { FC, useCallback, useRef } from "react";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, H } from "common/components/atoms";
import { useRedirectTo } from "common/hooks/useRedirectTo";
import WizardCloseConfirm, {
  WizardCloseConfirmRef,
} from "common/layout/WizardLayout/WizardCloseConfirm/WizardCloseConfirm";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "createShareClass.closeConfirm");

const CloseConfirm: FC = () => {
  const redirectTo = useRedirectTo();

  const wizardCloseConfirmRef = useRef<WizardCloseConfirmRef>(null);

  const buttonContinueClickHandler = useCallback((): void => {
    wizardCloseConfirmRef.current?.close();
  }, []);

  const buttonDiscardClickHandler = useCallback((): void => {
    redirectTo(getEMPath(["ownership", "shareClasses"]));
  }, [redirectTo]);

  return (
    <WizardCloseConfirm ref={wizardCloseConfirmRef}>
      <H.xxs className="mb-5 text-center">{t("message")}</H.xxs>

      <div className="d-flex justify-content-center">
        <Button variant="danger" className="w-max-content me-2" onClick={buttonDiscardClickHandler}>
          {t("continue")}
        </Button>

        <Button variant="secondary" className="w-max-content" onClick={buttonContinueClickHandler}>
          {t("leave")}
        </Button>
      </div>
    </WizardCloseConfirm>
  );
};

export default CloseConfirm;
