import { useEffect, useState } from "react";

import { useTableBrowserStorage } from "common/components/atoms/ImportTable/TableBrowserStorage";
import { BreggCompanyShareholders } from "store/modelTypes";
import { useStoreState } from "store/store";

const useImportStakeholders = () => {
  const [stakeholdersData, setStakeholdersData] = useState<BreggCompanyShareholders[]>([]);

  const stakeholders = useStoreState((state) => state.companyOnboardingModel.shareholders);
  const { bregg, uniMicro } = useStoreState((state) => state.companyOnboardingModel.integrations);

  const { getImportTableData } = useTableBrowserStorage<BreggCompanyShareholders[]>();

  useEffect(() => {
    const browserData = getImportTableData()?.data;

    const holders =
      (browserData && browserData.length && browserData) || // if browserData is not empty
      (stakeholders.length && stakeholders) || // if shareholders is not empty
      bregg?.shareholders || // if bregg?.shareholders is not empty
      uniMicro?.shareholders || // if uniMicro?.shareholders is not empty
      [];

    if (holders.length) {
      setStakeholdersData(holders);
    }
  }, [bregg?.shareholders, getImportTableData, stakeholders, uniMicro?.shareholders]);

  return { stakeholdersData, setStakeholdersData };
};

export default useImportStakeholders;
