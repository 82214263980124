import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router";
import cn from "classnames";
import { and, isEmpty, isNil } from "ramda";

import {
  Button,
  Collapsible,
  ContextMenu,
  ContextMenuProps,
  Table,
  Tag,
  TagVariant,
  Ui,
} from "common/components/atoms";
import {
  AgreementMilestoneStatuses,
  AgreementUIType,
  InstrumentTypesNamesEnum,
  PlanStatusesBasedOnAPIStatusId,
  VestingTypesEnum,
} from "common/enums/enum";
import { CheckIcon, CloseIcon, MenuTabBarVerticalIcon, TimeIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreActions } from "store/store";
import { AgreementMilestone } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import { createDateString } from "../../../../../atoms/DatePicker/DatePicker";
import { getCurrentAgreementContext } from "../functions";
import classes from "./Milestones.module.scss";

/**
 * Description: milestones container, will display all milestones that agreement has (if vesting conditions type for agreement set was set to Milestone-based vesting)
 * @param { type } InstrumentTypesNamesEnum Type for specific agreement. Will choose the necessary context for agreement
 */

const t = createTranslation(TranslationNS.common, "organisms.agreement.milestones");

type MilestonesProps = {
  type: InstrumentTypesNamesEnum;
  className?: string;
};

const Milestones: FC<MilestonesProps> = ({ type, className }) => {
  const { agreementDetails } = getCurrentAgreementContext(type).useStoreState((state) => state);

  return and(!isNil(agreementDetails?.milestones), !isEmpty(agreementDetails?.milestones)) &&
    agreementDetails?.vestingTypeId !== VestingTypesEnum.TIME_VESTING ? (
    <Collapsible
      defaultOpen
      withoutDivider
      headTitle={t("title")}
      classNames={cn(className, classes["collapsible-head"], "pb-2")}
    >
      <Ui.s className={classes["description"]}>{t("description")}</Ui.s>
      <Table
        className={cn("mt-5", classes["table"])}
        headComponent={<Head type={type} />}
        bodyComponent={<Body milestones={agreementDetails?.milestones} type={type} />}
      />
    </Collapsible>
  ) : null;
};

const Head: FC<{ type: InstrumentTypesNamesEnum }> = ({ type }) => {
  return (
    <thead className={classes["table-head"]}>
      <tr>
        <th>
          <Ui.s>{t("table.title")}</Ui.s>
        </th>
        <th style={{ textAlign: "end" }}>
          <Ui.s>{type === InstrumentTypesNamesEnum.RSA ? t("table.shares") : t("table.options")}</Ui.s>
        </th>
        <th style={{ textAlign: "end" }}>
          <Ui.s>{t("table.targetDate")}</Ui.s>
        </th>
        <th style={{ textAlign: "end" }}>
          <Ui.s>{t("table.completionDate")}</Ui.s>
        </th>
        <th>
          <Ui.s>{t("table.status")}</Ui.s>
        </th>
      </tr>
    </thead>
  );
};

const Body: FC<{ milestones?: AgreementMilestone[] | null; type: InstrumentTypesNamesEnum }> = ({
  milestones,
  type,
}) => {
  return (
    <tbody className={classes["body"]}>
      {milestones?.map((el, index) => (
        <Row key={index} item={el} type={type} />
      ))}
    </tbody>
  );
};

const Row: FC<{ item?: AgreementMilestone | null; type: InstrumentTypesNamesEnum }> = ({ item, type }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const { agreementDetails, UIType } = getCurrentAgreementContext(type).useStoreState((state) => state);
  const { setMilestoneModal, updateMilestoneStatus } = getCurrentAgreementContext(type).useStoreActions(
    (actions) => actions
  );

  const isAgreementExpired = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.expired;
  const isAgreementTerminated = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.terminated;

  const { getOwnershipPlansThunk } = useStoreActions((actions) => actions.company);

  const completeHandler = useCallback(() => {
    setMilestoneModal({
      type,
      milestone: item ? item : null,
      action: AgreementMilestoneStatuses.complete,
    });
  }, [item, setMilestoneModal, type]);

  const inprocessHandler = useCallback(async () => {
    await updateMilestoneStatus({
      completedAt: createDateString(new Date()),
      milestoneId: Number(item?.id),
      statusId: AgreementMilestoneStatuses.inProgress,
      planId: Number(agreementDetails?.id),
    });

    await getOwnershipPlansThunk(Number(companyId));
  }, [agreementDetails?.id, companyId, getOwnershipPlansThunk, item?.id, updateMilestoneStatus]);

  const incompleteHandler = useCallback(async () => {
    await updateMilestoneStatus({
      completedAt: createDateString(new Date()),
      milestoneId: Number(item?.id),
      statusId: AgreementMilestoneStatuses.incomplete,
      planId: Number(agreementDetails?.id),
    });

    await getOwnershipPlansThunk(Number(companyId));
  }, [agreementDetails?.id, companyId, getOwnershipPlansThunk, item?.id, updateMilestoneStatus]);

  const menuItems: ContextMenuProps["items"] = useMemo(() => {
    let items: ContextMenuProps["items"] = [];

    if (item?.statusId === AgreementMilestoneStatuses.complete) {
      items = [
        {
          key: "inProcess",
          label: t("table.setAsProcess"),
          icon: <TimeIcon width={24} height={24} />,
          onClick: inprocessHandler,
        },
        {
          key: "incomplete",
          label: t("table.setAsIncomplete"),
          icon: <CloseIcon width={24} height={24} />,
          onClick: incompleteHandler,
        },
      ];
    }

    if (item?.statusId === AgreementMilestoneStatuses.incomplete) {
      items = [
        {
          key: "inProcess",
          label: t("table.setAsProcess"),
          icon: <TimeIcon width={24} height={24} />,
          onClick: inprocessHandler,
        },
        {
          key: "complete",
          label: t("table.setComplete"),
          icon: <CheckIcon width={24} height={24} />,
          onClick: completeHandler,
        },
      ];
    }

    if (item?.statusId === AgreementMilestoneStatuses.inProgress) {
      items = [
        {
          key: "complete",
          label: t("table.setComplete"),
          icon: <CheckIcon width={24} height={24} />,
          onClick: completeHandler,
        },
        {
          key: "incomplete",
          label: t("table.setAsIncomplete"),
          icon: <CloseIcon width={24} height={24} />,
          onClick: incompleteHandler,
        },
      ];
    }

    return items;
  }, [completeHandler, incompleteHandler, inprocessHandler, item?.statusId]);

  const tagVariant = useMemo(() => {
    // TODO: check this section with expired case for agreement milestone item

    if (item?.statusId === AgreementMilestoneStatuses.complete) {
      return {
        status: "complete",
        title: t("table.complete"),
      };
    }

    if (item?.statusId === AgreementMilestoneStatuses.inProgress) {
      return {
        status: "description",
        title: t("table.inProcess"),
      };
    }

    if (item?.statusId === AgreementMilestoneStatuses.incomplete) {
      return {
        status: "declined",
        title: t("table.incomplete"),
      };
    }

    return {
      status: "complete",
      title: t("table.complete"),
    };
  }, [item?.statusId]);

  return (
    <tr className={classes["table-row"]}>
      <td className={classes["truncate"]}>
        <Ui.m bold title={item?.title} className="text-truncate">
          {item?.title}
        </Ui.m>

        <Ui.s className="text-truncate" style={{ color: scssVariables.foregroundLow }}>
          {item?.description}
        </Ui.s>
      </td>

      <td style={{ textAlign: "end" }}>
        <Ui.s>{item?.numberOfOptions}</Ui.s>
      </td>

      <td style={{ textAlign: "end" }}>
        <Ui.s>{item?.targetDate ? transformDateToCommonDateFormat(item?.targetDate) : "-"}</Ui.s>
      </td>

      <td style={{ textAlign: "end" }}>
        <Ui.s>{item?.completedAt ? transformDateToCommonDateFormat(item?.completedAt) : "-"}</Ui.s>
      </td>

      <td>
        <Tag variant={tagVariant.status as unknown as TagVariant}>{tagVariant.title}</Tag>
      </td>

      <td>
        {UIType === AgreementUIType.regular || isAgreementTerminated || isAgreementExpired ? null : (
          <ContextMenu items={menuItems}>
            <Button size="s" isOnlyIcon variant="tertiary">
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        )}
      </td>
    </tr>
  );
};

export default Milestones;
