import * as Yup from "yup";

export const companyFields = {
  name: "name",
  headquartersCountryId: "headquartersCountryId",
  city: "city",
  websiteUrl: "websiteUrl",
};

export const companySchema = Yup.object().shape({
  [companyFields.name]: Yup.string().required("Required"),
  [companyFields.headquartersCountryId]: Yup.number().typeError("Required").required("Required"),
  [companyFields.city]: Yup.string().required("Required"),
  [companyFields.websiteUrl]: Yup.string().url("Must be valid URL").nullable(),
});
