import { FC, useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";

import { Button, H } from "common/components/atoms";
import { CloseIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import TransactionsList from "../../../../../equity-management/ownership/transactions/components/transactions-list/transactions-list";
import { useTransactionsService } from "../../../../../equity-management/ownership/transactions/transactions-service";
import { Transaction } from "../../../../../equity-management/ownership/transactions/types";
import MyPortfolioContext from "../../../../MyPortfolioContext";

const t = createTranslation(TranslationNS.pages, "myPortfolio.transactions");

const Content: FC = () => {
  const companyData = MyPortfolioContext.useStoreState((state) => state.previewCompanyData);
  const closeModal = MyPortfolioContext.useStoreActions((actions) => actions.closeTransactionsPreview);

  const { getUserTransactions } = useTransactionsService(String(companyData?.companyId));

  const [isLoading, setIsLoading] = useState(false);

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const result = await getUserTransactions();
      setTransactions(result || []);
      setIsLoading(false);
    })();
  }, [getUserTransactions]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <H.xs>{t("header", { name: companyData?.companyName })}</H.xs>
        <Button isOnlyIcon size="s" variant="dark" onClick={() => closeModal()}>
          <CloseIcon />
        </Button>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <TransactionsList transactions={transactions || []} itemIdToOpen={"0"} portfolioView />
      )}
    </>
  );
};

const TransactionsPreview: FC = () => {
  const isOpen = MyPortfolioContext.useStoreState((store) => store.isTransactionsPreviewOpen);
  const closeModal = MyPortfolioContext.useStoreActions((actions) => actions.closeTransactionsPreview);

  return (
    <Modal size="xl" centered onHide={closeModal} show={isOpen} contentClassName="py-6 px-7">
      {isOpen && <Content />}
    </Modal>
  );
};

export default TransactionsPreview;
