import { FC, useMemo } from "react";
import { format } from "date-fns";
import { ActionCreator } from "easy-peasy";
import { motion } from "framer-motion";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DeleteIcon, MenuTabBarVerticalIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { ValuationSingle } from "store/types";

import classes from "../../../documents/components/CommonStyles.module.scss";

const ValuationTableRow: FC<
  ValuationSingle & { coloredRow?: number; onValuationRemove: ActionCreator<ValuationSingle | null> }
> = (item) => {
  const { valuation } = useStoreState((state) => state.company);
  const fNumber = useFormatNumbers();
  const { hasFullAccess } = useFeatures(FEATURES.valuation);
  const isValuationSingle = useMemo(() => R.defaultTo(0, valuation?.valuations.length) <= 1, [valuation]);

  const items: ContextMenuProps["items"] = useMemo(() => {
    return [
      {
        key: "delete",
        type: "delete",
        label: "Delete",
        icon: <DeleteIcon />,
        noBorder: true,
        isDisabled: !hasFullAccess,
        onClick: item.onValuationRemove.bind(null, item),
      },
    ];
  }, [hasFullAccess, item]);

  return (
    <motion.tr
      animate={{
        backgroundColor: item.id === item.coloredRow ? scssVariables.positive050 : "rgba(255, 255, 255, 0)",
      }}
    >
      <td width="35%">
        <Ui.s>{item.title}</Ui.s>
      </td>
      <td width="10%">
        <Ui.s>{item.validFrom ? format(new Date(item.validFrom), "dd.MM.yy") : null}</Ui.s>
      </td>
      <td width="30%">
        <Ui.s>{item.editor}</Ui.s>
      </td>
      <td width="20%" style={{ paddingLeft: 0 }}>
        <Ui.s
          bold
          style={{
            textAlign: "end",
          }}
        >
          {fNumber(item.valuation)}
        </Ui.s>
      </td>

      {isValuationSingle || !hasFullAccess ? null : (
        <td width="5%">
          <ContextMenu items={items} drop="down">
            <Button isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn" className={classes["menu-button"]}>
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </td>
      )}
    </motion.tr>
  );
};

export default ValuationTableRow;
