import { FC, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Ui } from "common/components/atoms";
import { ArrowLeftIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { TransactionStatus } from "../../../../../../types";
import CapitalIncreaseContext from "../../../capital-increase-context";
import { ImportTransactionFormValues } from "../use-import-transaction-form";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.transactions");

const TransactionActions: FC = () => {
  const navigate = useNavigate();
  const { companyId, transactionId } = useParams<{ companyId?: string; transactionId: string }>();
  const { currentBundle } = CapitalIncreaseContext.useStoreState((state) => state);
  const { setTransactionsInProgress } = CapitalIncreaseContext.useStoreActions((actions) => actions);
  const { values } = useFormikContext<ImportTransactionFormValues[]>();

  const stepBackHandler = useCallback(() => {
    setTransactionsInProgress(values);
    navigate(getEMPath(["createCapitalIncrease", "general"], { companyId, transactionId }));
  }, [companyId, navigate, setTransactionsInProgress, transactionId, values]);

  const { handleSubmit } = useFormikContext();

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md={3}>
          <Button as="span" variant="tertiary" isOnlyIcon onClick={stepBackHandler}>
            <ArrowLeftIcon />
          </Button>
        </Col>
        <Col className="d-flex justify-content-center align-items-center">
          <Ui.l className="text-medium">
            {currentBundle.eventName} • {"Step 2/2"}
          </Ui.l>
        </Col>
        <Col md={3} className="text-end">
          <Button type="submit">
            {currentBundle.statusId === TransactionStatus.Pending ? t("saveAsPending") : t("save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TransactionActions;
