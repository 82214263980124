import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ACCESS_LEVELS, AccessLevels } from "common/access-control/types";
import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import classes from "common/components/atoms/SlidePanel/SlidePanel.module.scss";
import TextField from "common/components/atoms/TextField/TextField";
import { H } from "common/components/atoms/Typography";
import { CloseIcon } from "common/icons/svg";
import { scrollToHeaderTop } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { apiBase } from "../../AccessControl";
import { FeaturesListGetDTO, InviteUserFormValues, InviteUserPostDTO } from "../../types";
import FeaturesTable from "../common/features-table/features-table";

type PropsTypes = {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  features: FeaturesListGetDTO;
};

export const InviteUserFieldsNames = {
  FirstName: "firstName",
  LastName: "lastName",
  Email: "email",
  IsAdmin: "isAdmin",
} as const;

const t = createTranslation(TranslationNS.pages, "companySettings.accessControlPage.inviteEditForm");
const validationTranslation = createTranslation("validation");

const PANEL_HEADER_ID = "HEADER_ID";

const InviteUserSidebar: FC<PropsTypes> = ({ isOpen, onSubmit, onClose, features }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const handleSubmit = useCallback(
    async (values: InviteUserFormValues) => {
      try {
        const features = Object.values(values.features)
          .flatMap((features) => Object.entries(features))
          .map(([featureId, feature]) => ({ featureId: Number(featureId), accessLevel: feature.accessLevel }));

        const inviteDTO: InviteUserPostDTO = {
          ...values,
          companyId: Number(companyId),
          features,
        };

        const response = await axios.post(`${apiBase}/send-invite`, inviteDTO);

        if (response.status === 200) {
          onClose();
          onSubmit();
          notify(t("notificationSuccess"), true, "success", 2000);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, onSubmit, onClose]
  );

  const formInitialValues: InviteUserFormValues = useMemo(
    () => ({
      [InviteUserFieldsNames.FirstName]: "",
      [InviteUserFieldsNames.LastName]: "",
      [InviteUserFieldsNames.Email]: "",
      [InviteUserFieldsNames.IsAdmin]: false,
      features,
    }),
    // eslint-disable-next-line
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [InviteUserFieldsNames.FirstName]: Yup.string().required(validationTranslation("required")),
        [InviteUserFieldsNames.LastName]: Yup.string().required(validationTranslation("required")),
        [InviteUserFieldsNames.Email]: Yup.string()
          .email(validationTranslation("invalidEmail"))
          .required(validationTranslation("required")),
      }),
    []
  );

  return (
    <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, touched, handleChange, setFieldValue, isSubmitting, submitForm, submitCount, isValid }) => {
        const handleChangeAccessLevel = (featureCategory: string, featureId: number, accessLevel: AccessLevels) => {
          if (accessLevel !== ACCESS_LEVELS.FULL) {
            setFieldValue(InviteUserFieldsNames.IsAdmin, false);
          }

          setFieldValue("features", {
            ...values.features,
            [featureCategory]: {
              ...values.features[featureCategory],
              [featureId]: { ...values.features[featureCategory][featureId], accessLevel },
            },
          });
        };

        if (isSubmitting && submitCount > 0 && !isValid) {
          notify(validationTranslation("formValidationError"), true, "error", 3000);
          scrollToHeaderTop(PANEL_HEADER_ID);
        }

        return (
          <SlidePanel show={isOpen} onHide={onClose}>
            <SlidePanel.Header>
              <H.xs>{t("inviteTitle")}</H.xs>
              <CloseIcon className={classes["header-icon"]} onClick={onClose} />
            </SlidePanel.Header>
            <Form id={PANEL_HEADER_ID}>
              <TextField
                id={InviteUserFieldsNames.FirstName}
                label={t("firstName")}
                className="mb-3 mt-7"
                value={values.firstName}
                isTouched={touched.firstName}
                error={errors.firstName}
                onChange={handleChange}
              />
              <TextField
                id={InviteUserFieldsNames.LastName}
                label={t("lastName")}
                className="mb-5"
                value={values.lastName}
                isTouched={touched.lastName}
                error={errors.lastName}
                onChange={handleChange}
              />
              <TextField
                id={InviteUserFieldsNames.Email}
                label={t("email")}
                className="mb-7"
                value={values.email}
                isTouched={touched.email}
                error={errors.email}
                onChange={handleChange}
              />
              <H.xxxs className="mb-4">{t("accessControl")}</H.xxxs>

              <ChecksGroup className="mb-4" isTouched={touched.isAdmin} info={t("adminCheckboxDescription")}>
                <ChecksGroup.Check
                  label={t("adminCheckboxLabel")}
                  checked={values.isAdmin}
                  onChange={(event) => {
                    setFieldValue(InviteUserFieldsNames.IsAdmin, event.target.checked);

                    if (event.target.checked) {
                      const allFeaturesLevel2 = Object.fromEntries(
                        Object.entries(features).map(([key, featureCategory]) => [
                          key,
                          Object.fromEntries(
                            Object.entries(featureCategory).map(([id, feature]) => [id, { ...feature, accessLevel: 2 }])
                          ),
                        ])
                      );

                      setFieldValue("features", allFeaturesLevel2);
                    }
                  }}
                />
              </ChecksGroup>
              <FeaturesTable features={values.features} onChangeAccessLevel={handleChangeAccessLevel} />
            </Form>
            <SlidePanel.Actions
              primaryTitle={t("inviteBtn")}
              secondaryTitle={t("cancelBtn")}
              primaryAction={submitForm}
              secondaryAction={onClose}
              isLoading={isSubmitting}
            />
          </SlidePanel>
        );
      }}
    </Formik>
  );
};

export default InviteUserSidebar;
