import { FC, ReactNode } from "react";
import classNames from "classnames";

import classes from "./InfoCard.module.scss";

type InfoCardProps = {
  title: ReactNode;
  subtitle: ReactNode;
  icon: ReactNode;
  type?: "success";
};
const InfoCard: FC<InfoCardProps> = ({ title, icon, subtitle, type }) => {
  return (
    <div
      className={classNames(classes.wrap, {
        [classes.success]: type === "success",
      })}
    >
      <div className={classes.icon}>{icon}</div>
      <div className={classes.title}>{title}</div>
      <div className={classes.subtitle}>{subtitle}</div>
    </div>
  );
};
export default InfoCard;
