import { FC } from "react";
import classNames from "classnames";

import { createTranslation, TranslationNS } from "translation";

import classes from "./step.module.scss";

const t = createTranslation(TranslationNS.pages, "company.gettingStartedGuide");

const StepImportant: FC<{ className?: string }> = ({ className }) => {
  return <div className={classNames(classes.stepImportant, className)}>{t("importantStep")}</div>;
};

export default StepImportant;
