import { FC } from "react";

import WSidebar from "common/layout/WizardLayout/WizardContent/Sidebar/Sidebar";
import { createTranslation, TranslationNS } from "translation";

import { PreferredPreferencesFormData } from "./PreferredPreferences";

const t = createTranslation(
  TranslationNS.pages,
  "createShareClass.preferredPreferences.sidebar"
);

const contentItems: (keyof PreferredPreferencesFormData)[] = [
  "seniorityLevel",
  "seniorityLevelMultiple",
  "isParticipating",
  "participatingCap",
  "shareClassAntiDilutionId",
];

const Sidebar: FC = () => (
  <WSidebar>
    <WSidebar.ContentItems>
      {contentItems.map((name) => (
        <WSidebar.ContentItems.Item key={name} name={name}>
          <WSidebar.Title>{t(`${name}.title`)}</WSidebar.Title>

          <WSidebar.Description>{t(`${name}.text`)}</WSidebar.Description>
        </WSidebar.ContentItems.Item>
      ))}
    </WSidebar.ContentItems>
  </WSidebar>
);

export default Sidebar;
