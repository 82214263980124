import { FC } from "react";
import { useFormikContext } from "formik";

import TextField, { TextFieldProps } from "common/components/atoms/TextField/TextField";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.nameField");

const NameField: FC<TextFieldProps> = (props) => {
  const { touched, errors, values, handleChange, handleBlur } = useFormikContext<Pick<ShareClassFormData, "name">>();

  return (
    <TextField
      label={t("label")}
      isTouched={touched.name}
      error={errors.name}
      value={values.name}
      placeholder={t("placeholder")}
      name="name"
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  );
};

export default NameField;
