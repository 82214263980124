import {
  FC,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from "react";
import classNames from "classnames";

import classes from "./DropdownMega.module.scss";

export type FormDropdownProps = JSX.IntrinsicElements["div"] & {};

const Title: FC<JSX.IntrinsicElements["div"]> = ({
  className,
  children,
  ...props
}) => (
  <div className={classNames(classes["title"], className)} {...props}>
    {children}
  </div>
);

const Description: FC<JSX.IntrinsicElements["div"]> = ({
  className,
  children,
  ...props
}) => (
  <div className={classNames(classes["description"], className)} {...props}>
    {children}
  </div>
);

type DropdownMegaComponent = ForwardRefExoticComponent<
  PropsWithoutRef<FormDropdownProps> & RefAttributes<HTMLDivElement>
> & {
  Title: typeof Title;
  Description: typeof Description;
};

// eslint-disable-next-line react/display-name
const DropdownMega = forwardRef(({ className, children, ...props }, ref) => (
  <div ref={ref} className={classNames(classes["card"], className)} {...props}>
    <div>{children}</div>
  </div>
)) as DropdownMegaComponent;

DropdownMega.Title = Title;
DropdownMega.Description = Description;

export default DropdownMega;
