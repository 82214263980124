import { FC, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { PeriodTimeEnum } from "common/enums/enum";

import Dropdown from "../Dropdown/Dropdown";
import InputHeader from "../input-blocks/input-header/input-header";
import TextField from "../TextField/TextField";

type PropsType = {
  label?: string;
  className?: string;
  periodVariants?: string[];
  nameAmount: string;
  namePeriod: string;
  placeholderAmount?: string;
  isDisabled?: boolean;
};

type FormValues<NameAmount extends string, NamePeriod extends string> = {
  [key in NameAmount | NamePeriod]: string;
};
export const periodOptions = [PeriodTimeEnum.YEAR, PeriodTimeEnum.MONTH, PeriodTimeEnum.DAY];

const PeriodPickerFormik: FC<PropsType> = ({
  label,
  className,
  periodVariants,
  nameAmount,
  namePeriod,
  placeholderAmount,
  isDisabled,
}) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext<FormValues<PropsType["nameAmount"], PropsType["namePeriod"]>>();

  const handleChangePeriod = useCallback(
    (period: string) => {
      setFieldValue(namePeriod, period);
    },
    [namePeriod, setFieldValue]
  );

  return (
    <Row className={className}>
      {label && <InputHeader label={label} />}
      <Col>
        <TextField
          type="number"
          isTouched={touched[nameAmount]}
          error={errors[nameAmount]}
          value={values[nameAmount]}
          name={nameAmount}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholderAmount}
          isDisabled={isDisabled}
        />
      </Col>
      <Col>
        <Dropdown
          selectedValue={values[namePeriod]}
          options={periodVariants ? periodVariants : periodOptions}
          name={namePeriod}
          error={errors[namePeriod]}
          isTouched={touched[namePeriod]}
          onChange={handleChangePeriod}
          isDisabled={isDisabled}
        />
      </Col>
    </Row>
  );
};

export default PeriodPickerFormik;
