import { FC } from "react";
import classNames from "classnames";

import classes from "./Header.module.scss";
import Title from "./Title/Title";

type PageHeaderComponent = FC<JSX.IntrinsicElements["div"]> & {
  Title: typeof Title;
};

const Header: PageHeaderComponent = ({ className, children, ...props }) => (
  <div className={classNames(classes["header"], className)} {...props}>
    {children}
  </div>
);

Header.Title = Title;

export default Header;
