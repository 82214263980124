import { FC, useCallback } from "react";

import ShareClassFormOffcanvas from "common/shareClass/components/ShareClassFormOffcanvas/ShareClassFormOffcanvas";

import { ShareClassesStore } from "../store";

const EditShareClass: FC = () => {
  const fetchShareClassesWithDetailsThunk = ShareClassesStore.useStoreActions(
    (actions) => actions.fetchShareClassesWithDetails
  );

  const editingShareClassIdState = ShareClassesStore.useStoreState((state) => state.editingShareClassId);
  const setEditingShareClassIdAction = ShareClassesStore.useStoreActions((actions) => actions.setEditingShareClassId);
  const closeOffcanvas = useCallback((): void => {
    setEditingShareClassIdAction(undefined);
  }, [setEditingShareClassIdAction]);

  const saveHandler = useCallback((): void => {
    fetchShareClassesWithDetailsThunk().finally(() => {
      closeOffcanvas();
    });
  }, [closeOffcanvas, fetchShareClassesWithDetailsThunk]);

  return (
    <ShareClassFormOffcanvas
      show={!!editingShareClassIdState}
      shareClassId={editingShareClassIdState}
      onSave={saveHandler}
      onCancel={closeOffcanvas}
      onHide={closeOffcanvas}
    />
  );
};

export default EditShareClass;
