import { FC, useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";
import classNames from "classnames";

import Button from "common/components/atoms/Button/Button";
import { H, P } from "common/components/atoms/Typography";
import { notify } from "common/utils/notify/notifyFunction";
import IssueEquityContext from "pages/equity-management/ownership/financial-instruments/IssueEquityContext";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ExpiredWarrantModal.module.scss";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.conversion.expired");

const ExpiredWarrantContent: FC<{ handleClose: () => void }> = ({ handleClose }) => {
  const { warrantDetails, warrantId } = IssueEquityContext.useStoreState((state) => state);
  const { getIssueEquityThunk, getWarrantDetailsThunk } = IssueEquityContext.useStoreActions((actions) => actions);

  const [isLoading, setIsLoading] = useState(false);
  const setExpiredHandler = async () => {
    try {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("CompanyId", String(warrantDetails?.companyId));
      formData.append("WarrantId", String(warrantDetails?.warrantId));

      const request = await axios.post("/api/equity-management/issue-equity/warrant/set-expired", formData);

      if (request.status === 200) {
        getIssueEquityThunk(Number(warrantDetails?.companyId));
        notify(t("successMessage"), true, "success", 10000);
        handleClose();
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (warrantId) {
      getWarrantDetailsThunk(warrantId);
    }
  }, [getWarrantDetailsThunk, warrantId]);

  return (
    <div className={classes["container"]}>
      <H.xs className="mb-3">{t("title")}</H.xs>

      <P.s className={classNames("mb-5", classes["modal-description"])}>{t("continuing")}</P.s>

      <div>
        <Button isDisabled={isLoading} isLoading={isLoading} onClick={setExpiredHandler}>
          {t("setExpired")}
        </Button>
        <Button isDisabled={isLoading} isLoading={isLoading} variant="secondary" className="ms-2" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
};

const ExpiredWarrantModal: FC = () => {
  const { isSetExpiredWarrantEditPanelOpen } = IssueEquityContext.useStoreState((state) => state);
  const { setIsSetExpiredWarrantEditPanelOpen } = IssueEquityContext.useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setIsSetExpiredWarrantEditPanelOpen({ open: false });
  }, [setIsSetExpiredWarrantEditPanelOpen]);

  return (
    <Modal centered show={isSetExpiredWarrantEditPanelOpen} onHide={handleClose}>
      <ExpiredWarrantContent handleClose={handleClose} />
    </Modal>
  );
};

export default ExpiredWarrantModal;
