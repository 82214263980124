import { ChangeEvent, FC, useCallback, useMemo } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import cn from "classnames";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";

import { Check, NewAvatar, Ui } from "common/components/atoms";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import Helper from "common/components/atoms/Helper/Helper";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import TextArea from "common/components/atoms/TextArea/TextArea";
import TextField from "common/components/atoms/TextField/TextField";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DiscountIcon, GraphStatsIcon, LocallyGrownIcon, PieChartIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../../IssueEquityContext";
import classes from "../ConvertLoanPanel.module.scss";
import { ConvertLoanFormValues } from "../useConvertLoanForm";
import InfoCard from "./components/InfoCard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertLoan.conversion");

const Conversion: FC = () => {
  const fNumber = useFormatNumbers();
  const shareClasses = useStoreState((state) => state.shareClassModel.shareClassesCompany);
  const conversionReasons = useStoreState((state) => state.common?.dropdowns?.conversionReasons);
  const conversionDetails = IssueEquityContext.useStoreState((state) => state.conversionDetailsCL);
  const calculatedCL = IssueEquityContext.useStoreState((state) => state.calculateCL);
  const { convertLoanLoading } = IssueEquityContext.useStoreState((state) => state);
  const { getCalculateCLThunk } = IssueEquityContext.useStoreActions((actions) => actions);
  const { currencySymbol } = useCurrency();

  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<ConvertLoanFormValues>();

  const debounceChangeValuation = useMemo(
    () =>
      debounce((values: ConvertLoanFormValues) => {
        getCalculateCLThunk({
          valuationAtConversion: values.valuationAtConversion || 0,
          valuationCap: values?.valuationCap || 0,
          loanAmount: values?.loanAmount || 0,
          agreementDate: values?.agreementDate || "",
          loanSettleDate: values.loanSettleDate,
          discount: values?.discount || 0,
          interestRate: values?.interestRate || 0,
          sharesInCompanyBeforeConversion: values?.sharesInCompanyBeforeConversion || 0,
          loanAmountRepaid: values?.loanAmountRepaid || 0,
          interestRepaid: values?.interestRepaid || false,
          interestOverride: values?.interestOverride ? values?.interestOverride : undefined,
        }).catch((e) => {
          console.log("error", e);
        });
      }, 1000),
    [getCalculateCLThunk]
  );

  const isValid =
    !errors.valuationAtConversion && !errors.loanAmountRepaid && !errors.interestOverride && !errors.loanSettleDate;
  if (!isValid) {
    debounceChangeValuation.cancel();
  }

  const handleChangeDate = useCallback(
    (date: Date | string, name?: string) => {
      if (name) {
        setFieldValue(name, date);
        debounceChangeValuation({ ...values, [name]: date });
      }
    },
    [debounceChangeValuation, setFieldValue, values]
  );

  const handleChangeCalculateValues = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value, type, checked } = e.target;

      const finalValue = type === "checkbox" ? checked : value;

      setFieldValue(name, finalValue);

      debounceChangeValuation({ ...values, [name]: finalValue });
    },
    [debounceChangeValuation, setFieldValue, values]
  );

  const shares = fNumber(calculatedCL?.numberOfNewShares, "amount");
  const percentageOfCompany = fNumber(calculatedCL?.percentageOfCompany, "percent");
  const discount = fNumber(calculatedCL?.discount, "percent");
  const investmentAmount = fNumber(calculatedCL?.investmentAmount, "amount");
  const investmentAmountBlock = fNumber(calculatedCL?.investmentAmount, "amount");
  const loanAmountRepaid = fNumber(calculatedCL?.loanAmountRepaid, "value");
  const interestToRepay = fNumber(calculatedCL?.interestToRepay, "value");
  const loanAmount = fNumber(calculatedCL?.loanAmount, "value");
  const valuationCap = fNumber(calculatedCL?.valuationCap, "value");

  const handleChangeInterestRepaid = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setFieldValue("interestOverride", 0);
    }

    if (!e.target.checked) {
      setFieldValue("interestOverride", values.interest);
    }

    handleChangeCalculateValues(e);
  };

  return (
    <div className={classes.conversion}>
      <SlidePanel.Section title={t("title")}>
        <Helper>
          <Row>
            <Col>
              <Helper.Question questionId={"conversionReasonId"} type="input">
                <Dropdown
                  placeholder={"Select Reason"}
                  options={conversionReasons || []}
                  name={"conversionReasonId"}
                  label={t("conversionReason.label")}
                  selectedValue={values.conversionReasonId}
                  optionsIsObject
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.conversionReasonId}
                  isTouched={touched.conversionReasonId}
                />
              </Helper.Question>
            </Col>
            <Col>
              <Helper.Question questionId={"companyValuation"} type="input">
                <TextField
                  type="number"
                  label={t("companyValuation.label")}
                  name={"valuationAtConversion"}
                  value={values.valuationAtConversion}
                  onChange={handleChangeCalculateValues}
                  onBlur={handleBlur}
                  error={errors.valuationAtConversion}
                  isTouched={touched.valuationAtConversion}
                  iconRight={<span>{convertLoanLoading ? <Spinner size="sm" /> : currencySymbol}</span>}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Row>
            <Col>
              <Helper.Answer
                className="mt-3"
                answerId={"conversionReasonId"}
                text={t("conversionReason.answer")}
                withRightMargin
              />
              <Helper.Answer
                className="mt-3"
                answerId={"companyValuation"}
                text={t("companyValuation.answer")}
                withRightMargin
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Helper.Question questionId={"loanSettleDate"} type="input">
                <DatePicker
                  isDateOnlyString
                  date={values.loanSettleDate}
                  name={"loanSettleDate"}
                  label={t("loanSettleDate.label")}
                  isTouched={touched.loanSettleDate}
                  error={errors.loanSettleDate}
                  onChange={handleChangeDate}
                  onBlur={handleBlur}
                />
              </Helper.Question>
            </Col>
            <Col>
              <Helper.Question questionId={"interestRate"} type="input">
                <TextField
                  type="number"
                  label={t("interest.label")}
                  name={"interestOverride"}
                  value={values.interestOverride}
                  iconRight={<span>{convertLoanLoading ? <Spinner size="sm" /> : currencySymbol}</span>}
                  onChange={handleChangeCalculateValues}
                  onBlur={handleBlur}
                  error={errors.interestOverride}
                  isTouched={touched.interestOverride}
                  isDisabled={values.interestRepaid}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Row>
            <Col>
              <Helper.Answer
                className="mt-3"
                answerId={"loanSettleDate"}
                text={t("loanSettleDate.answer")}
                withRightMargin
              />
              <Helper.Answer withRightMargin className="mt-3" answerId={"interestRate"} text={t("interest.answer")} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Helper.Question questionId={"interestRepaid"}>
                <Check
                  label={t("interestRepaid.label")}
                  name="interestRepaid"
                  checked={values.interestRepaid}
                  onChange={handleChangeInterestRepaid}
                />
              </Helper.Question>
            </Col>
            <Col md={12}>
              <Helper.Answer
                withRightMargin
                className="mt-3"
                answerId={"interestRepaid"}
                text={t("interestRepaid.answer")}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Helper.Question questionId={"loanAmountRepaid"} type={"input"}>
                <TextField
                  type="number"
                  label={t("loanAmountRepaid.label")}
                  name={"loanAmountRepaid"}
                  value={values.loanAmountRepaid}
                  onChange={handleChangeCalculateValues}
                  onBlur={handleBlur}
                  error={errors.loanAmountRepaid}
                  isTouched={touched.loanAmountRepaid}
                  iconRight={<span>{convertLoanLoading ? <Spinner size="sm" /> : currencySymbol}</span>}
                />
              </Helper.Question>
            </Col>
            <Col className="content">
              <div className={classes.remainInvestment}>
                {t("remainingInvestment", { count: calculatedCL?.remainingInvestment || 0, currency: currencySymbol })}
              </div>
            </Col>
            <Col md={12}>
              <Helper.Answer
                withRightMargin
                className="mt-3"
                answerId={"loanAmountRepaid"}
                text={t("loanAmountRepaid.answer")}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Helper.Question questionId={"shareClassId"} type="input">
                <Dropdown
                  options={shareClasses}
                  name={"shareClassId"}
                  label={t("shareClass.label")}
                  placeholder={t("shareClass.placeholder")}
                  selectedValue={values.shareClassId}
                  optionsIsObject
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.shareClassId}
                  isTouched={touched.shareClassId}
                />
              </Helper.Question>
            </Col>
            <Col md={12}>
              <Helper.Answer withRightMargin className="mt-3" answerId={"shareClassId"} text={t("shareClass.answer")} />
            </Col>
          </Row>
          <div>
            <Row className="mt-3">
              <Col md={6}>
                <Ui.m bold> {t("calculation.loan")}</Ui.m>
              </Col>
              <Col md={6} className="text-end">
                {loanAmount} {currencySymbol}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={9}>
                <Ui.m bold>{t("calculation.interest")}</Ui.m>
              </Col>

              <Col md={3} className={classes.calculate}>
                <Ui.m>+</Ui.m>
                <Ui.m>
                  {interestToRepay} {currencySymbol}
                </Ui.m>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={9}>
                <Ui.m bold>{t("calculation.loanRepayment")}</Ui.m>
              </Col>
              <Col md={3} className={classes.calculate}>
                <Ui.m>-</Ui.m>
                <Ui.m>
                  {loanAmountRepaid} {currencySymbol}
                </Ui.m>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={9}>
                <Ui.m bold>{t("calculation.investmentAmount")}</Ui.m>
              </Col>
              <Col md={3} className={classes.calculate}>
                <Ui.m bold>=</Ui.m>
                <Ui.m bold>
                  {investmentAmount} {currencySymbol}
                </Ui.m>
              </Col>
            </Row>
          </div>

          <Row className="mt-4">
            <Col>
              <Helper.Question questionId={"comment"} type="input">
                <TextArea
                  isOptional
                  label={t("comment.label")}
                  name={"comment"}
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.comment}
                  isTouched={touched.comment}
                />
              </Helper.Question>
            </Col>
            <Col md={12}>
              <Helper.Answer className="mt-3" answerId={"comment"} text={t("comment.answer")} withRightMargin />
            </Col>
          </Row>
        </Helper>
        <div className={cn(classes.infoCards, "mt-4")}>
          <InfoCard
            type="success"
            subtitle={t("block.shares")}
            title={convertLoanLoading ? <Spinner /> : shares}
            icon={
              <NewAvatar
                size="m"
                company={!!conversionDetails?.stakeholderCompanyName}
                firstName={conversionDetails?.firstName}
                lastName={conversionDetails?.lastName}
                imageUrl={conversionDetails?.imageUrl}
                companyName={conversionDetails?.stakeholderCompanyName}
              />
            }
          />
          <InfoCard
            title={convertLoanLoading ? <Spinner /> : percentageOfCompany}
            subtitle={t("block.ofTheCompany")}
            icon={<PieChartIcon />}
          />
          {calculatedCL?.useDiscount ? (
            <InfoCard
              title={convertLoanLoading ? <Spinner /> : discount}
              subtitle={t("block.discount")}
              icon={<DiscountIcon />}
            />
          ) : (
            <InfoCard
              title={convertLoanLoading ? <Spinner /> : valuationCap}
              subtitle={t("block.ValuationCap")}
              icon={<GraphStatsIcon />}
            />
          )}
          <InfoCard
            title={convertLoanLoading ? <Spinner /> : investmentAmountBlock}
            subtitle={t("block.investment")}
            icon={<LocallyGrownIcon />}
          />
        </div>
      </SlidePanel.Section>
    </div>
  );
};

export default Conversion;
