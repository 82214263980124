import * as Yup from "yup";

export const valuationFields = {
  valuation: "valuation",
  validFrom: "validFrom",
};

export const valuationSchema = Yup.object().shape({
  [valuationFields.valuation]: Yup.number().positive("Required").required("Required"),
  [valuationFields.validFrom]: Yup.string().required("Required"),
});
