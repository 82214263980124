import { FC } from "react";

import { IExercisablePlan } from "pages/my-porfolio/exercising/ExercisingContext";

import SingleAvailableToExerciseItem from "../../../ExercisingComponents/SingleAvailableToExerciseItem";

interface PurchaseProps {
  data: IExercisablePlan[];
  requestCallback: (planId: number) => void;
}

const Purchase: FC<PurchaseProps> = ({ data, requestCallback }) => {
  return (
    <div>
      {data.map((item) => (
        <SingleAvailableToExerciseItem handleSelect={requestCallback} key={item.planId} {...item} />
      ))}
    </div>
  );
};

export default Purchase;
