import {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useState,
} from "react";
import FormControl, { FormControlProps } from "react-bootstrap/FormControl";
import classNames from "classnames";

import { createTranslation } from "translation/helpers";

import { FolderIcon } from "../../../icons/svg";
import { Ui } from "../Typography";
import classes from "./FileInput.module.scss";

export type FileInputProps = Omit<FormControlProps, "content" | "size"> & {
  accept?: string;
  multiple?: boolean;
  error?: string;
  isTouched?: boolean;
  hidden?: boolean;
  content?: ReactNode;
  size?: "regular" | "large";
};

const t = createTranslation("common", "components.fileInput");

const FileInput: ForwardRefRenderFunction<HTMLInputElement, FileInputProps> = (
  { className, content, error, isTouched, hidden, size = "regular", ...props },
  ref
) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  useEffect(() => {
    (ref as MutableRefObject<HTMLInputElement>)?.current?.addEventListener("dragenter", handleDragStart);
    (ref as MutableRefObject<HTMLInputElement>)?.current?.addEventListener("dragleave", handleDragEnd);
    (ref as MutableRefObject<HTMLInputElement>)?.current?.addEventListener("change", handleDragEnd);

    return () => {
      (ref as MutableRefObject<HTMLInputElement>)?.current?.removeEventListener("dragenter", handleDragStart);
      (ref as MutableRefObject<HTMLInputElement>)?.current?.removeEventListener("dragleave", handleDragEnd);
      (ref as MutableRefObject<HTMLInputElement>)?.current?.removeEventListener("change", handleDragEnd);
    };
  }, [handleDragEnd, handleDragStart, ref]);

  if (hidden) return null;
  return (
    <>
      <div
        className={classNames(classes["wrap"], {
          [classes["large"]]: size === "large",
        })}
      >
        <FormControl
          ref={ref}
          className={classNames(
            classes["form-control"],
            {
              [classes["large"]]: size === "large",
            },
            className,
            isTouched && error ? classes.error : ""
          )}
          {...props}
          type="file"
        />
        <div
          className={classNames(classes["content"], {
            [classes["dragging"]]: isDragging,
            [classes["large"]]: size === "large",
          })}
        >
          <FolderIcon className={classes["icon"]} />

          <Ui.m className={classNames("ms-3", classes["label"])}>
            {content || t.el("content", { components: [<span key={1} />] })}
          </Ui.m>
        </div>
      </div>
    </>
  );
};

export default forwardRef(FileInput);
