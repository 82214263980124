import { FC, useCallback } from "react";
import cn from "classnames";
import { flatten, isEmpty } from "ramda";

import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import Table from "common/components/atoms/Table/Table";
import { Ui } from "common/components/atoms/Typography";
import { ChevronRightSmallIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import DocumentsContext, { DocumentFilters } from "../../DocumentsContext";
import classes from "../CommonStyles.module.scss";
import GoverningDocumentsTableBody from "./GoverningDocumentsTable/GoverningDocumentsTableBody";

const t = createTranslation(TranslationNS.pages, "company.documents");

const GoverningDocuments: FC = () => {
  const { searchValue, filterValue, filteredDocuments } = DocumentsContext.useStoreState((state) => state);

  const TableHeader = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div className={classes.header} onClick={onClick}>
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center">
              <Ui.xl bold>{t("governingDocuments.title")}</Ui.xl>
              {!isEmpty(filteredDocuments) && (
                <div className={classes["documents-rounded-container"]}>
                  <Ui.xs>
                    {
                      flatten(
                        filteredDocuments.governingDocuments.map((el) =>
                          el.documents.filter((item) =>
                            item.fileName?.toLocaleLowerCase()?.includes(searchValue?.toLowerCase())
                          )
                        )
                      ).length
                    }
                  </Ui.xs>
                </div>
              )}
            </div>

            <Ui.xs style={{ color: scssVariables.foregroundLow }}>{t("governingDocuments.description")}</Ui.xs>
          </div>

          <div data-testid="company-documents-open-table-test-id" className="d-flex align-items-center">
            <ChevronRightSmallIcon
              className={cn(classes.icon, {
                [classes.open]: Boolean(activeEventKey),
              })}
            />
          </div>
        </div>
      );
    },
    [filteredDocuments, searchValue]
  );

  if (isEmpty(filteredDocuments.governingDocuments) && (searchValue || filterValue !== DocumentFilters.showAll)) {
    return null;
  }

  return (
    <CollapsibleContainer defaultOpen className="mb-3" Header={TableHeader}>
      <div className={cn("d-flex flex-column", classes["documents-content"])}>
        <Table
          className={classes.table}
          headComponent={null}
          bodyComponent={<GoverningDocumentsTableBody data={filteredDocuments.governingDocuments} />}
        />
      </div>
    </CollapsibleContainer>
  );
};

export default GoverningDocuments;
