import { FC } from "react";

import { GoverningDocumentsType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import GoverningDocumentsTableRow from "./GoverningDocumentsTableRow";

type GoverningDocumentsTableBodyProps = {
  data: GoverningDocumentsType["documentCategories"];
};

const GoverningDocumentsTableBody: FC<GoverningDocumentsTableBodyProps> = ({ data }) => {
  return (
    <tbody className={classes.body}>
      {data.map((el, index) => (
        <GoverningDocumentsTableRow key={`${el.name} ${index}`} data={el} />
      ))}
    </tbody>
  );
};

export default GoverningDocumentsTableBody;
