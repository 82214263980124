import { FC } from "react";
import { Image } from "react-bootstrap";

import CompanyLogoPlaceholder from "common/icons/company-logo-placeholder.png";
import { ProfileHeadIcon } from "common/icons/svg";

type DefaultImageProps = {
  defaultImageType: string;
  imageHeight?: number;
  imageWidth: number;
};

const DefaultImage: FC<DefaultImageProps> = ({ defaultImageType, imageHeight, imageWidth }) => {
  const imageId = "default-image-upload-section-test-id";

  switch (defaultImageType) {
    case "profile":
      return <ProfileHeadIcon width={imageWidth} height={imageHeight} color="currentColor" />;
    case "company-logo":
      return (
        <Image
          className={"mb-2"}
          width={imageWidth}
          height={imageHeight}
          data-testid={imageId}
          src={CompanyLogoPlaceholder}
          style={{ objectFit: "cover" }}
        />
      );
    default:
      return <div data-testid={imageId} style={{ width: imageWidth, height: imageHeight }}></div>;
  }
};

export default DefaultImage;
