import { FC, useMemo } from "react";
import { ComputedDatum } from "@nivo/pie";
import cn from "classnames";
import * as R from "ramda";

import PieChart from "common/components/atoms/Charts/Pie.chart";
import VerticalBarChart from "common/components/atoms/Charts/VerticalBar.chart";
import { Ui } from "common/components/atoms/Typography";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import classes from "../../common/Charts/Charts.module.scss";
import IssuedUnissuedDistributionTooltip from "../../common/Charts/IssuedUnissuedDistributionTooltip";
import ShareClassesTooltip from "../../common/Charts/ShareClassesTooltip";
import ShareholdersTooltip, { PieDataCustom } from "../../common/Charts/ShareholdersTooltip";
import CapTableStore from "../../store";

const t = createTranslation(TranslationNS.pages, "company.capTable.charts");

export type ChartsProps = JSX.IntrinsicElements["div"] & Record<string, unknown>;

const colorsPalette = [
  scssVariables.additional11500,
  scssVariables.additional7500,
  scssVariables.additional8500,
  scssVariables.additional9500,
  scssVariables.additional10500,
  scssVariables.additional12500,
  scssVariables.additional2500,
  scssVariables.additional6500,
  scssVariables.additional5500,
  scssVariables.additional13500,
];

const getColor = (index: number): string => colorsPalette[index];

const Charts: FC<ChartsProps> = ({ className, ...props }) => {
  const fNumber = useFormatNumbers();
  const dilutedDataStore = CapTableStore.useStoreState((state) => state.dilutedData);

  const shareholdersTotal = useMemo(
    () => dilutedDataStore.capTable.reduce((curr, { sharesFullyDiluted }) => curr + sharesFullyDiluted, 0),
    [dilutedDataStore]
  );

  const issuedUnissuedDistributionTotal = useMemo(
    () => dilutedDataStore.issuedUnissuedDistribution.reduce((curr, { shares }) => curr + shares, 0),
    [dilutedDataStore]
  );

  const issuedUnissuedDistribution = useMemo(() => {
    const emptyData = [
      {
        id: "empty_1",
        value: 70,
        color: scssVariables.foregroundMedium,
      },
      {
        id: "empty_2",
        value: 30,
        color: scssVariables.element1,
      },
    ];

    if (issuedUnissuedDistributionTotal === 0) {
      return emptyData;
    }

    return dilutedDataStore.issuedUnissuedDistribution.map((item, index) => {
      return {
        id: item.name,
        value: item.shares,
        color: getColor(index),
      };
    });
  }, [issuedUnissuedDistributionTotal, dilutedDataStore]);

  const shareholders: PieDataCustom[] = useMemo(() => {
    const emptyData = [
      {
        id: "empty_1",
        value: 70,
        color: scssVariables.foregroundMedium,
        amount: 70,
        label: "Empty",
      },
      {
        id: "empty_2",
        value: 10,
        color: scssVariables.element1,
        amount: 10,
        label: "Empty",
      },
      {
        id: "empty_3",
        value: 10,
        color: scssVariables.foregroundMedium,
        amount: 10,
        label: "Empty",
      },
      {
        id: "empty_4",
        value: 10,
        color: scssVariables.foregroundHigh,
        amount: 70,
        label: "Empty",
      },
    ];

    if (shareholdersTotal === 0) {
      return emptyData;
    }

    return dilutedDataStore.topStakeholders.map((item, index) => {
      return {
        id: item.name,
        value: item.percentage,
        color: getColor(index),
        label: item.name,
        amount: item.numberOfShares,
      };
    });
  }, [shareholdersTotal, dilutedDataStore]);

  const relationship = useMemo(() => {
    const relationship = dilutedDataStore.relationshipOverview.length
      ? dilutedDataStore.relationshipOverview.map((item) => ({
          id: item.name,
          value: item.percentage,
          color: "",
        }))
      : ["Founders", "Employees", "Investor", "Board", "Others"].map((item, index) => {
          const value = 100 - index * 20;
          const emptyColors = [
            scssVariables.foregroundMedium,
            scssVariables.foregroundMedium,
            scssVariables.element1,
            scssVariables.foregroundHigh,
            scssVariables.element1,
          ];

          return { id: item, value, color: emptyColors[index] };
        });

    relationship.sort((a, b) => a.value - b.value);

    if (!R.isEmpty(dilutedDataStore.relationshipOverview)) {
      relationship.forEach((item, index) => {
        item.color = getColor(index);
      });
    }

    return relationship;
  }, [dilutedDataStore]);

  const shareClassData = useMemo(() => {
    const isDataEmpty = R.isEmpty(dilutedDataStore.shareClassDistribution);
    const emptyData = [
      {
        id: "empty",
        value: 70,
        color: scssVariables.foregroundHigh,
      },
      {
        id: "empty1",
        value: 15,
        color: scssVariables.foregroundMedium,
      },
      {
        id: "empty2",
        value: 15,
        color: scssVariables.foregroundMedium,
      },
    ];

    return isDataEmpty
      ? emptyData
      : dilutedDataStore.shareClassDistribution.map((item, index) => ({
          id: item.shareClassName,
          value: item.percentage,
          color: getColor(index),
        }));
  }, [dilutedDataStore]);

  return (
    <div className={cn("mt-3", classes.wrap, className)} {...props}>
      <div className={cn(classes.section, classes.diluted)}>
        <div className={classes["pie-chart-wrap"]}>
          <PieChart
            height={110}
            pieThickness={0.75}
            data={issuedUnissuedDistribution}
            isInteractive={issuedUnissuedDistributionTotal > 0}
            tooltip={(props) => (
              <IssuedUnissuedDistributionTooltip
                pieTooltipData={props}
                shareholdersTotal={issuedUnissuedDistributionTotal}
              />
            )}
          />
        </div>

        <Ui.s className="mt-1" style={{ color: scssVariables.foregroundLow }}>
          {t("authorizedShares.title")}
        </Ui.s>

        <Ui.l bold className="mt-1" style={{ color: scssVariables.foregroundMedium }}>
          {t("authorizedShares.issued")} vs {t("authorizedShares.unissued")}
        </Ui.l>
      </div>

      <div className={cn(classes.section, classes.diluted)}>
        <div className={classes["pie-chart-wrap"]}>
          <PieChart
            height={110}
            pieThickness={0.75}
            data={shareholders}
            tooltip={(props) => <ShareholdersTooltip pieTooltipData={props.datum as ComputedDatum<PieDataCustom>} />}
            isInteractive={shareholdersTotal > 0}
          />
        </div>

        <Ui.s className="mt-1" style={{ color: scssVariables.foregroundLow }}>
          {t("shareholders.titleDiluted")}
        </Ui.s>

        <Ui.l bold className="mt-1" style={{ color: scssVariables.foregroundMedium }}>
          {t("pieChart.totalDiluted", {
            shares: fNumber(shareholdersTotal, "amount"),
          })}
        </Ui.l>
      </div>

      <div className={cn(classes.section, classes.diluted)}>
        <div className={classes["pie-chart-wrap"]}>
          <PieChart
            height={110}
            pieThickness={0.75}
            data={shareClassData}
            tooltip={(props) => <ShareClassesTooltip pieTooltipData={props} />}
            isInteractive={!R.isEmpty(dilutedDataStore.shareClassDistribution)}
          />
        </div>

        <Ui.s className="mt-1" style={{ color: scssVariables.foregroundLow }}>
          {t("distribution.title")}
        </Ui.s>

        <Ui.l bold className="mt-1" style={{ color: scssVariables.foregroundMedium }}>
          {t("pieChart.shareClasses", {
            amount: shareClassData.length,
          })}
        </Ui.l>
      </div>

      <div className={cn(classes.section, classes.diluted)}>
        <div style={{ width: 200 }}>
          <VerticalBarChart data={relationship} isEmpty={R.isEmpty(dilutedDataStore.relationshipOverview)} />
        </div>
      </div>
    </div>
  );
};

export default Charts;
