import { FC, MouseEvent,useCallback } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";

const LinkWithTooltip: FC<JSX.IntrinsicElements["a"]> = ({
  children,
  ...props
}) => {
  const linkClickHandler = useCallback(
    (e: MouseEvent<HTMLAnchorElement>): void => {
      e.preventDefault();
    },
    []
  );

  return (
    <OverlayTrigger overlay={<Tooltip id="test">{props.title}</Tooltip>}>
      <a href="#" {...props} onClick={linkClickHandler}>
        {children}
      </a>
    </OverlayTrigger>
  );
};

export default LinkWithTooltip;
