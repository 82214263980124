import { FC, ReactNode, useCallback } from "react";
import cn from "classnames";

import { Ui } from "common/components/atoms";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { ArrowRightIcon } from "common/icons/svg";

import classes from "./add-transaction-modal.module.scss";

type AddTransactionItemProps = {
  title: string;
  description: string[];
  icon: ReactNode;
  onClick: () => void;
  className?: string;
  isDisabled?: boolean;
  tooltipTitle?: string;
  tooltipDescription?: string;
};

const AddTransactionItem: FC<AddTransactionItemProps> = ({
  title,
  description,
  icon,
  onClick,
  className,
  isDisabled,
  tooltipTitle,
  tooltipDescription,
}) => {
  const handleClick = useCallback(() => {
    if (!isDisabled) {
      onClick();
    }
  }, [isDisabled, onClick]);

  const TooltipData = useCallback(() => {
    return (
      <div className={classes.tooltip}>
        <Ui.xs bold>{tooltipTitle}</Ui.xs>
        <Ui.xs>{tooltipDescription}</Ui.xs>
      </div>
    );
  }, [tooltipDescription, tooltipTitle]);

  return (
    <Tooltip popupContent={<TooltipData />} show={isDisabled ? undefined : false}>
      <div
        className={cn("d-flex align-items-center mb-2", classes.wrap, className, {
          [classes.disabled]: isDisabled,
        })}
        onClick={handleClick}
      >
        <div className={cn("me-3", classes.icon)}>{icon}</div>
        <div className="flex-grow-1">
          <Ui.m bold>{title}</Ui.m>
          <div className={classes.description}>
            {description.map((item, index) => (
              <Ui.xs key={index} tag="span">
                {index !== 0 && " • "}
                {item}
              </Ui.xs>
            ))}
          </div>
        </div>
        <div className={cn("ms-3", classes.arrow)}>
          <ArrowRightIcon fontSize={24} />
        </div>
      </div>
    </Tooltip>
  );
};

export default AddTransactionItem;
