import { FC } from "react";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

export const votingRightsMultipleFieldSettings = {
  min: 0,
  max: 1000,
};

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.votingRightsMultipleField");

const VotingRightsMultipleField: FC<FormGroupProps> = (props) => {
  const { touched, errors, values, setFieldValue } =
    useFormikContext<Pick<ShareClassFormData, "hasVotingRights" | "votingRightsMultiple">>();

  return (
    <FormGroup controlId="votingRightsMultiple" {...props}>
      <TextField
        step={0.1}
        type="number"
        label={t("label")}
        error={errors.votingRightsMultiple}
        value={values.votingRightsMultiple}
        isTouched={touched.votingRightsMultiple}
        min={votingRightsMultipleFieldSettings.min}
        max={votingRightsMultipleFieldSettings.max}
        onChange={(e) => {
          setFieldValue("votingRightsMultiple", Number(e.target.value));
        }}
      />
    </FormGroup>
  );
};

export default VotingRightsMultipleField;
