import { FC } from "react";

import Helper from "common/components/atoms/Helper/Helper";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { createTranslation, TranslationNS } from "translation";

import CloseConfirm from "./components/CloseConfirm/CloseConfirm";
import CreatePoolForm from "./CreatePoolForm";

const t = createTranslation(TranslationNS.pages, "createPool");

const CreatePool: FC = () => (
  <WizardLayout className="h-100">
    <Helper>
      <WizardLayout.Header title={<Helper.Question questionId="createEquityPool" labelLeft={t("title")} />} />
      <div style={{ margin: "0 auto", maxWidth: 638 }}>
        <Helper.Answer link="/" answerId="createEquityPool" text={t("titleAnswer")} linkText={t("titleReadMore")} />
      </div>
    </Helper>

    <WizardContent maxStep={3}>
      <CreatePoolForm />
    </WizardContent>
    <CloseConfirm />
  </WizardLayout>
);

export default CreatePool;
