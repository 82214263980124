import { UploadedFile } from "../../atoms/FileUploader/FileUploader";

export const StakeholderInvitationStatus = {
  notInvited: 0,
  invited: 1,
  active: 2,
} as const;

export type StakeholderInvitationStatusIds =
  (typeof StakeholderInvitationStatus)[keyof typeof StakeholderInvitationStatus];

export type CapTableIssueShares = {
  issuedSharesId: number;
  shares: number;
  shareClassId: number;
  shareClassName: string;
  firstShareNumber: number;
  lastShareNumber: number;
  ownership: number;
  hasPledges: boolean;
  pledgeNotes: string;
  description: string;
  transactionCategoryName: string;
  transactionTypeName: string;
  transactedAt: string;
  files: UploadedFile[];
};

export type SingleShareClassData = {
  sharesId?: number;
  pledged?: boolean;
  pledgedNotes?: boolean;
  description?: boolean;
  documents?: boolean;
  ownership: number;
  shareClassId: number;
  shareClassName: string;
  shares: number;
  shareNumbers: {
    firstShareNumber: number;
    lastShareNumber: number;
    hasPledges: boolean;
  }[];
};

export type CapTableStakeholderDetailsType = {
  address?: string;
  avatarFilePath?: string;
  businessPostAddress: string | null;
  companyName: string | null;
  countryId: number;
  email?: string;
  firstName: string;
  hasPledges: boolean;
  initials: string;
  isCompanyOwned: boolean;
  lastName: string;
  organizationNumber: string | null;
  phoneNumber?: string;
  pledgesDescription: string | null;
  relationshipTypeId: number;
  stakeholderId: number;
  dateOfBirth?: string;
  issuedShares: CapTableIssueShares[];
  invitationStatus: StakeholderInvitationStatusIds;
  shareClasses: SingleShareClassData[];
  totalSharesValue: number;
  sharesTotalPercentage: number;
  financialInstruments: {
    typeId: number;
    amount: number;
    expiryDate: string | null;
    financialInstrumentId: number;
    instrumentName: string;
    numberOfShares: number;
    stake: number;
  }[];
  transactions: {
    categoryId: number;
    categoryName: string;
    currencySymbol: string;
    description: string | null;
    numberOfShares: number;
    sharePrice: number;
    stakeholderAvatar: string | null;
    stakeholderInitials: string;
    stakeholderIsBuying: boolean;
    stakeholderIsCompanyOwned: boolean;
    stakeholderName: string;
    stakeholderRepresentativeName: string;
    statusId: number;
    transactedAt: string;
    transactedAvatar: string | null;
    transactedInitials: string;
    transactedIsCompanyOwned: boolean;
    transactedName: string;
    transactedRepresentativeName: string;
    transactionId: number;
    transactionTotal: number;
    transactionTypeId: number;
    typeName: string;
  }[];
  contacts: {
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
    stakeholderContactId?: number;
    stakeholderContactRoleName?: string;
  }[];
};
