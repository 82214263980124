import { FC } from "react";

import { Tag } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ExclamationMarkCircle } from "common/icons/svg";

import { SingleShareClassData } from "../../types";

type Props = {
  tags: SingleShareClassData["shareNumbers"];
};
const ShareNumbersCell: FC<Props> = ({ tags }) => {
  const fNumber = useFormatNumbers();

  return (
    <div>
      {tags.map((tag, index) => {
        return (
          <div key={index} className={"mb-1"}>
            <Tag variant="closed">
              {tag.hasPledges && <ExclamationMarkCircle fontSize={14} height={16} className="me-1" />}
              {fNumber(tag.firstShareNumber, "amount")}-{fNumber(tag.lastShareNumber, "amount")}
            </Tag>
          </div>
        );
      })}
    </div>
  );
};

export default ShareNumbersCell;
