import { FC, useCallback, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { omit } from "ramda";

import Helper from "common/components/atoms/Helper/Helper";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import StakeholderSection, {
  StakeholderContextProps,
} from "common/components/molecules/StakeholderSection/StakeholderSection";
import { PlanDocumentStatusEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { useOneOffPlanValidation } from "common/plan/planValidationSchema";
import usePlanInitialValues from "common/plan/usePlanInitialValues";
import { notify } from "common/utils/notify/notifyFunction";
import { PlanGetDTO, PlanPostDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ExerciseClause from "../../create-plan/steps/basics/sidebar/form-parts/exercise-clause/exercise-clause";
import VestingPlanDetails from "../../create-plan/steps/basics/sidebar/form-parts/VestingPlanDetails/VestingPlanDetails";
import { PlanFormType } from "../../create-plan/steps/basics/sidebar/forms/plan-form";
import ShareDetailsWrapper from "../components/share-details-wrapper/share-details-wrapper";
import OneOffStockOption from "../steps/SharesDetails/OneOffStockOptions/OneOffStockOptions";
import { PlanForm } from "../types";
import Footer from "./footer/footer";

type PropsType = {
  setIsSidebarOpen: (isOpen: boolean) => void;
  planToEdit: PlanGetDTO | null;
  onSave?: () => void;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

const OneOffStockOptionsPlan: FC<PropsType> = ({ setIsSidebarOpen, planToEdit, onSave = () => undefined }) => {
  const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const isPostPlanLoading = useStoreState((state) => state.planModel.isPostPlanLoading);
  const { getProgramThunk } = useStoreActions((actions) => actions.programModel);
  const { setOneOffPlanSOThunk } = useStoreActions((actions) => actions.planModel);

  useEffect(() => {
    if (planToEdit && planToEdit.programId) {
      getProgramThunk(planToEdit.programId).then();
    }
  }, [getProgramThunk, planToEdit]);

  const initialValues = usePlanInitialValues(companyId, planToEdit);

  const onSubmit = useCallback(
    async (values: PlanForm, helpers: FormikHelpers<PlanForm>) => {
      const createPlanDTO: PlanPostDTO = {
        ...omit(["isExcludedClause"], values),
        countryId: values.countryId || null,
        file: values?.fileAgreement?.newFile,
        oldFileId: values?.fileAgreement?.oldFile?.fileId,
        excludePostTerminationClauses: values.isExcludedClause,
      };

      const res = await setOneOffPlanSOThunk(createPlanDTO);

      if (res?.status) {
        helpers.resetForm();
        onSave?.();
        notify(t("notificationSuccess"), true, "success");
        setIsSidebarOpen(false);
      }
    },
    [onSave, setIsSidebarOpen, setOneOffPlanSOThunk]
  );

  const valSchema = useOneOffPlanValidation();

  return (
    <div data-testid="new-options-plan-form">
      <Formik initialValues={initialValues} validationSchema={valSchema} onSubmit={onSubmit} enableReinitialize={true}>
        <FormData handleClose={setIsSidebarOpen} isLoading={isPostPlanLoading} planStatus={planToEdit?.statusId} />
      </Formik>
    </div>
  );
};

type FormDataProps = {
  handleClose: (isOpen: boolean) => void;
  isLoading: boolean;
  planStatus?: PlanStatusesBasedOnAPIStatusId;
};
const FormData: FC<FormDataProps> = ({ handleClose, isLoading, planStatus }) => {
  const { handleSubmit, setFieldValue, values } = useFormikContext<PlanForm>();

  const handleEditPlan = useCallback(() => {
    setFieldValue(f.saveAsDraft, true);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  const handleGrantPlanAction = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const updatedFields = useMemo<StakeholderContextProps["updatedFields"]>(() => {
    let fields: StakeholderContextProps["updatedFields"] = undefined;
    if (values.sendEmailInvitation) {
      fields = { email: { isTopField: true, meta: { isOptional: false } } };
    }

    if (Number(values.documentStatusId) === PlanDocumentStatusEnum.GENERATE && values.isCompanyOwned) {
      fields = { ...fields, organizationNumber: { isTopField: true, meta: { isOptional: false } } };
    }

    return fields;
  }, [values.documentStatusId, values.isCompanyOwned, values.sendEmailInvitation]);

  return (
    <Form>
      <ToastFormikValidator />
      <Helper>
        <SlidePanel.Section title={t("receiverBasics")}>
          <StakeholderSection updatedFields={updatedFields} />
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="stockOptionsCollapsible" title={t("stockOptionsDetails")}>
          <ShareDetailsWrapper>
            <OneOffStockOption />
          </ShareDetailsWrapper>
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="vestingDetailsCollapsible" title={t("vestingDetails")}>
          <VestingPlanDetails />
        </SlidePanel.Section>

        <SlidePanel.Section
          isDividerVisible={false}
          data-testid="exerciseClauseCollapsible"
          title={t("exerciseClause")}
        >
          <Row className="mb-5">
            <Col>
              <ExerciseClause />
            </Col>
            <Col />
          </Row>
        </SlidePanel.Section>

        <SlidePanel.Section isDividerVisible={false} data-testid="exerciseDocumentCollapsible" title={t("document")}>
          <GeneratePlanDocument
            autoGenerateAgreement
            managerSignature={!values.existingPlan}
            planFormType={PlanFormType.OneOffSOPlan}
          />
        </SlidePanel.Section>

        <Footer
          handleEdit={handleEditPlan}
          handleClose={handleClose}
          handleGrantPlan={planStatus === PlanStatusesBasedOnAPIStatusId.draft ? handleGrantPlanAction : undefined}
          isSubmitting={isLoading}
        />
      </Helper>
    </Form>
  );
};

export default OneOffStockOptionsPlan;
