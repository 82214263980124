import { useCallback, useEffect, useMemo } from "react";
import debounce from "lodash.debounce";

import { TableBrowserContext } from "./index";

const useTableBrowserStorage = <T extends object>() => {
  const { tableName, timestamp } = TableBrowserContext.useStoreState((state) => state);

  const { setTimestamp } = TableBrowserContext.useStoreActions((actions) => actions);

  useEffect(() => {
    if (tableName) {
      const data = localStorage.getItem(tableName);
      setTimestamp(data ? JSON.parse(data).timestamp : "");
    }
  }, [tableName, setTimestamp]);

  const setImportTable = useCallback(
    (data: T) => {
      const timestamp = Date.now();
      localStorage.setItem(tableName, JSON.stringify({ data, timestamp }));
      setTimestamp(timestamp.toString());
    },
    [setTimestamp, tableName]
  );

  const getImportTableData = useCallback((): { data: T; timestamp: string } | null => {
    const storage = localStorage.getItem(tableName);

    return storage ? JSON.parse(storage) : null;
  }, [tableName]);

  const setImportTableDebounce = useMemo(
    () =>
      debounce((data: T) => {
        const timestamp = Date.now();
        if (JSON.stringify(data) !== JSON.stringify(getImportTableData()?.data)) {
          localStorage.setItem(tableName, JSON.stringify({ data, timestamp }));
          setTimestamp(timestamp.toString());
        }
      }, 10000),
    [getImportTableData, setTimestamp, tableName]
  );

  const removeImportTable = useCallback(() => {
    localStorage.removeItem(tableName);
  }, [tableName]);

  return { setImportTable, removeImportTable, getImportTableData, setImportTableDebounce, timestamp };
};

export default useTableBrowserStorage;
