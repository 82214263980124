import { FC } from "react";

import { Ui } from "common/components/atoms/Typography";
import { createTranslation,TranslationNS } from "translation";

import classes from "./table.module.scss";

const t = createTranslation(TranslationNS.pages, "createPlan.summary.tableSummary.head");

const TableHead: FC = () => {
  return (
    <thead
      className={classes["shareholders-table-head"]}
      style={{ height: 48, verticalAlign: "middle", backgroundColor: "transparent" }}
    >
      <tr>
        <th>
          <Ui.s>{t("individual")}</Ui.s>
        </th>
        <th>
          <Ui.s>{t("shares")}</Ui.s>
        </th>
        <th className="text-center">
          <Ui.s>{t("planAgreement")}</Ui.s>
        </th>
        <th className="text-center">
          <Ui.s>{t("sendInvite")}</Ui.s>
        </th>
      </tr>
    </thead>
  );
};

export default TableHead;
