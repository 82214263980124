import { CSSProperties, FC, useCallback } from "react";
import { Image } from "react-bootstrap";

import { LinkedInIcon, UserIcon } from "common/icons/svg";

type SingleTeamItemPropr = {
  img?: string;
  name: string;
  lastName: string;
  textRight?: boolean;
  style?: CSSProperties;
  positionTitle: string | null;
  imgSize?: number;
  emptyIconColor?: string;
  linkedInUrl?: string;
};

const SingleTeamItem: FC<SingleTeamItemPropr> = ({
  img,
  name,
  textRight,
  positionTitle,
  style,
  lastName,
  imgSize = 120,
  emptyIconColor,
  linkedInUrl,
}) => {
  const renderImageSection = useCallback(() => {
    return img ? (
      <Image src={img} roundedCircle width={imgSize} height={imgSize} style={{ objectFit: "cover" }} />
    ) : (
      <div
        style={{ width: imgSize, height: imgSize }}
        className="d-flex justify-content-center align-items-center single-team-member"
      >
        <UserIcon
          color={emptyIconColor}
          style={{ marginTop: 15 }}
          width={textRight ? 38 : 57}
          height={textRight ? 44 : 66}
        />
      </div>
    );
  }, [emptyIconColor, img, imgSize, textRight]);

  return textRight ? (
    <div className="d-flex align-items-center" style={style ? style : undefined}>
      <div>{renderImageSection()}</div>
      <div className="ms-3 position-relative single-team-member-horizontal">
        <div className="d-flex flex-wrap">
          <span className="m-0 ui-s fw-bold mb-1 text-truncate" title={name}>
            {name}
          </span>
          <span className="m-0 ui-s fw-bold mb-1 text-truncate">{lastName}</span>
        </div>
        <p className="m-0 ui-s">{positionTitle}</p>

        {linkedInUrl ? (
          <div
            className="single-team-member-linkedIn single-team-member-horizontal-linked cursor-pointer"
            onClick={() => {
              window.open(linkedInUrl, "_blank");
            }}
          >
            <LinkedInIcon width={12} height={12} />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <div className="d-flex flex-column align-items-center" style={style}>
      <div>{renderImageSection()}</div>
      <div className="mt-2 position-relative single-team-member-info text-center">
        <p className="m-0 ui-xs fw-bold mb-1" title={name}>
          {name}
        </p>
        {positionTitle ? <p className="m-0 ui-xs">{positionTitle}</p> : null}
        {linkedInUrl ? (
          <div
            className="single-team-member-linkedIn single-team-member-abs cursor-pointer"
            onClick={() => {
              window.open(linkedInUrl, "_blank");
            }}
          >
            <LinkedInIcon width={12} height={12} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SingleTeamItem;
