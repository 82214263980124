import { useMemo } from "react";
import * as R from "ramda";
import * as Yup from "yup";

import { URL_VALIDATION_PATTERN } from "common/utils/validators";
import { useStoreState } from "store/store";
import { createTranslation } from "translation";

const vt = createTranslation("validation");

const useGeneralForm = () => {
  const { profile } = useStoreState((state) => state.account);

  const initialValues = useMemo(
    () => ({
      firstName: R.defaultTo("", profile?.firstname),
      lastName: R.defaultTo("", profile?.lastname),
      linkedInUrl: R.defaultTo("", profile?.linkedInUrl),
      oneliner: R.defaultTo("", profile?.oneliner),
      twitter: R.defaultTo("", profile?.twitterHandle),
      youtube: R.defaultTo("", profile?.streamingPlatformUrl),
      blog: R.defaultTo("", profile?.blogUrl),
      cityOfResidence: R.defaultTo("", profile?.cityOfResidence),
      countryOfResidenceId: R.defaultTo(undefined, profile?.countryOfResidenceId),
      website: R.defaultTo("", profile?.personalWebsiteUrl),
      sex: R.defaultTo(
        "Female",
        /* istanbul ignore next */
        profile?.sex === "NotDisclosed" ? "Not disclosed" : profile?.sex
      ),
      dateOfBirth: profile?.dateOfBirth || undefined,
      isProfileExist: !R.isNil(profile),
    }),
    [profile]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        linkedIn: Yup.string().matches(URL_VALIDATION_PATTERN, vt("invalidURL")),
        firstName: Yup.string().required(vt("required")),
        lastName: Yup.string().required(vt("required")),
      }),
    []
  );

  return {
    initialValues,
    validationSchema,
  };
};

export default useGeneralForm;
