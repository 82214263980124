import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import { H, P } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../../useProgramForm";
import FormContent from "./components/FormContent";
import LinkWithTooltip from "./components/LinkWithTooltip";
import classes from "./Summary.module.scss";
import VestingConditions from "./VestingConditions/VestingConditions";

const t = createTranslation(TranslationNS.pages, "createProgram.summary");

const Summary: FC = () => {
  const { values } = useFormikContext<CreateProgramFormData>();

  const recommendationsList = useMemo<string[]>(() => {
    const list = t("recommendations.list", {
      defaultValue: [],
      returnObjects: true,
    });

    return Array.isArray(list) ? list : [list];
  }, []);

  return (
    <WizardContent.Content step={values?.programTypeId === InstrumentTypesIdsEnum.OPTION ? 5 : 4}>
      <H.xs className="mb-4">{t("title")}</H.xs>

      <P.l className="mb-4">
        {t.el(`summary.${values.vestingTypeId}`, {
          values: { program: values },
        })}
      </P.l>

      <VestingConditions className="mb-4" />

      <P.m className="mb-1">{t("recommendations.text")}</P.m>

      <ul className={`paragraph-m mb-6 ${classes["recommendations-list"]}`}>
        {recommendationsList.map((_, index) => (
          <li key={index}>
            {t.el(`recommendations.list.${index}`, {
              components: {
                a: <LinkWithTooltip />,
                sup: <sup />,
              },
            })}
          </li>
        ))}
      </ul>

      <FormContent programId={values?.programId ? String(values.programId) : ""} />
    </WizardContent.Content>
  );
};

export default Summary;
