import axios, { AxiosResponse } from "axios";

export function downloadRequest<T = any>(downloadId: string, fileName: string): Promise<AxiosResponse<T>> {
  return axios.get(`/api/download/${downloadId}`, { responseType: "blob" }).then((data) => {
    const url = window.URL.createObjectURL(data.data);

    const link = document.createElement("a");

    link.href = url;

    if (data.data.type === "application/pdf") {
      link.target = "_blank";
    } else {
      link.download = fileName;
    }

    link.click();

    window.URL.revokeObjectURL(url);

    return data;
  });
}

export function downloadExcelFile(api: string, fileName: string): Promise<AxiosResponse> {
  return axios.get(api).then((response) => {
    const decodedData = atob(response.data.file);

    // Convert the decoded data to a Uint8Array
    const arrayBuffer = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; i++) {
      arrayBuffer[i] = decodedData.charCodeAt(i);
    }

    const file = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // console.log(response.data);
    const href = URL.createObjectURL(file);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${fileName}.xlsx`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);

    return response;
  });
}
