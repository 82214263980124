import { FC, useEffect, useState } from "react";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from "ramda";

import { Ui } from "common/components/atoms";
import { FilledArrowDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import DocumentsContext, { SingleProgramDocumentType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import NestedTableRow from "./NestedTableRow";

type AgreementsHeadingTableRowProps = {
  data: SingleProgramDocumentType["agreements"];
};

const AgreementsHeadingTableRow: FC<AgreementsHeadingTableRowProps> = ({ data }) => {
  const { searchValue } = DocumentsContext.useStoreState((state) => state);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const rowClickHandler = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (searchValue.trim() && !isOpen) {
      setIsOpen(true);
    }
  }, [searchValue, isOpen]);

  return (
    <>
      <motion.tr
        data-testid="company-documents-table-single-row-test-id"
        className={cn(classes["table-row"], {
          [classes.hovered]: isHovered,
        })}
        onHoverEnd={setIsHovered.bind(null, false)}
        onHoverStart={setIsHovered.bind(null, true)}
        onClick={rowClickHandler}
      >
        <td className={classes["nested"]}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center position-relative">
              <motion.div
                initial={{ transform: "rotate(0deg)" }}
                className="me-2"
                animate={{
                  transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              >
                <FilledArrowDownIcon className={classes["arrow"]} color={scssVariables.foregroundLow} />
              </motion.div>

              <Ui.m className="ms-3 fw-500">Agreements</Ui.m>
            </div>
          </div>
        </td>
      </motion.tr>

      <AnimatePresence>
        {!isEmpty(data) && isOpen ? data.map((el) => <NestedTableRow key={el.entityId + el.name} data={el} />) : null}
      </AnimatePresence>
    </>
  );
};

export default AgreementsHeadingTableRow;
