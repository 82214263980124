import { FC, useCallback, useState } from "react";
import axios from "axios";

import Button from "common/components/atoms/Button/Button";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext, { SuggestedCompaniesType } from "../../MyPortfolioContext";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.suggestedCompanies");

type ActionsProps = {
  data: SuggestedCompaniesType;
};
const Actions: FC<ActionsProps> = ({ data }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const setShowInviteCompanyModal = MyPortfolioContext.useStoreActions((actions) => actions.setShowInviteCompanyModal);
  const getPortfolioThunk = MyPortfolioContext.useStoreActions((actions) => actions.getPortfolioThunk);

  const handleResendInvitation = useCallback(() => {
    setShowInviteCompanyModal({
      show: true,
      data: {
        companyName: data.name,
        reciverName: "",
        email: data.email,
        companyInvitationId: data.companyInvitationId,
      },
    });
  }, [data.companyInvitationId, data.email, data.name, setShowInviteCompanyModal]);

  const handleRemove = useCallback(() => {
    setIsDeleting(true);
    axios
      .post("/api/portfolio/remove-suggested-company", { companyName: data.name })
      .then(() => {
        getPortfolioThunk(null).catch((e) => {
          console.log(e);
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  }, [data.name, getPortfolioThunk]);

  return (
    <div>
      {data.invitationSent ? (
        <Button variant="tertiary" size="s" isDisabled={isDeleting} onClick={handleResendInvitation}>
          {t("table.resendInvite")}
        </Button>
      ) : (
        <Button variant="secondary" size="s" isDisabled={isDeleting} onClick={handleResendInvitation}>
          {t("table.invite")}
        </Button>
      )}
      <Button
        className="ms-1"
        isLoading={isDeleting}
        isDisabled={isDeleting}
        size="s"
        variant="tertiary"
        onClick={handleRemove}
      >
        {t("table.remove")}
      </Button>
    </div>
  );
};

export default Actions;
