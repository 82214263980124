import {
  ChangeEvent,
  FocusEventHandler,
  forwardRef,
  memo,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormControl, FormControlProps } from "react-bootstrap";
import cn from "classnames";
import IMask, { FactoryArg } from "imask";
import { defaultTo } from "ramda";

import useCombinedRef from "common/hooks/useCombinedRef";

import InputFeedback, { InputFeedbackProps } from "../input-blocks/input-feedback/input-feedback";
import InputHeader, { InputHeaderProps } from "../input-blocks/input-header/input-header";
import classes from "./TextField.module.scss";

export type TextFieldSizes = "s" | "m";

export type TextFieldCustomProps = {
  size?: TextFieldSizes;
  inputRef?: RefObject<any>;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  iconRightOnClick?: () => void;
  infoElement?: ReactNode;
  withoutInfoIcon?: boolean;
  name?: string;
  "data-testid"?: string;
  autoComplete?: FormControlProps["aria-autocomplete"];
  mask?: FactoryArg;
  maskNumber?: {
    thousandsSeparator?: string;
    scale?: number;
    radix?: string;
    mapToRadix?: string[];
    normalizeZeros?: boolean;
  };
};

export type TextFieldProps = Omit<FormControlProps, "size" | "disabled"> &
  Partial<Record<"min", number>> &
  Partial<Record<"max", number>> &
  Partial<Record<"step", number | string>> &
  InputHeaderProps &
  InputFeedbackProps &
  TextFieldCustomProps;

const maskConfig = {
  mask: Number,
  thousandsSeparator: " ",
  scale: 8,
  radix: ",",
  mapToRadix: [",", "."],
  normalizeZeros: false,
};

const TextField = memo(
  forwardRef<HTMLInputElement, TextFieldProps>(
    (
      {
        size = "m",
        label,
        isDisabled = false,
        isOptional = false,
        isTouched = false,
        meta,
        error,
        info,
        iconLeft,
        iconRight,
        type,
        children,
        className,
        infoElement,
        withoutInfoIcon,
        inputRef,
        autoComplete = "none",
        iconRightOnClick,
        mask,
        maskNumber,
        ...props
      },
      ref
    ) => {
      const config = useMemo(() => mask || { ...maskConfig, ...maskNumber }, [mask, maskNumber]);

      const innerRef = useRef<HTMLInputElement>(null);
      const combinedRef = useCombinedRef(ref, innerRef) as unknown as RefObject<HTMLInputElement>;
      const [maskValue, setMaskValue] = useState<string>("");

      const masked = useMemo(() => IMask.createMask(config), [config]);

      const isNumberInput = useMemo(() => type === "number", [type]);

      const inputValue = useMemo(() => {
        if (isNumberInput) {
          return defaultTo("", maskValue);
        }
        return defaultTo("", props.value);
      }, [isNumberInput, maskValue, props.value]);

      useEffect(() => {
        if (isNumberInput && props.value !== masked.unmaskedValue) {
          masked.resolve(String(props.value), { input: false });
          setMaskValue(masked.value);
        }
      }, [isNumberInput, masked, props.value]);

      const dataTest = props["data-testid"] ? props["data-testid"] : `text-input-${props?.name || type}`;

      const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
          if (isNumberInput) {
            const { value } = e.target;
            const originalCaretPosition = combinedRef.current?.selectionStart || 0;

            masked.resolve(String(value), { input: false });

            setMaskValue(masked.value);
            e.target.value = masked.unmaskedValue;
            let newCaretPosition = originalCaretPosition - (value.length - masked.value.length);
            props.onChange?.(e);
            if (
              (e?.nativeEvent as InputEvent)?.inputType === "deleteContentBackward" &&
              newCaretPosition > originalCaretPosition
            ) {
              newCaretPosition -= 1;
            }
            setTimeout(() => {
              combinedRef.current?.setSelectionRange(newCaretPosition, newCaretPosition);
            }, 10);
          } else {
            props.onChange?.(e);
          }
        },
        [combinedRef, isNumberInput, masked, props]
      );

      const handleFocus: FocusEventHandler<HTMLInputElement> = useCallback(
        (e) => {
          e.target.autocomplete = "off";
          if (isNumberInput) {
            masked.resolve(String(props.value), { input: false });
            setMaskValue(masked.value);
          }

          props?.onFocus?.(e);
        },
        [isNumberInput, masked, props]
      );

      const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
        (e) => {
          if (isNumberInput) {
            masked.resolve(String(props.value), { input: false });
            setMaskValue(masked.value);
          }
          props?.onBlur?.(e);
        },
        [isNumberInput, masked, props]
      );

      return (
        <div className={cn(classes.field, className, { [classes.disabled]: isDisabled })}>
          <InputHeader
            className={classes.inputLabel}
            label={label}
            isDisabled={isDisabled}
            isOptional={isOptional}
            meta={meta}
          />

          <div className="d-flex position-relative">
            {iconLeft && <div className={cn(classes.icon, classes["icon-left"])}>{iconLeft}</div>}

            <FormControl
              {...props}
              onChange={handleChange}
              ref={combinedRef}
              aria-label={label}
              aria-autocomplete={autoComplete}
              value={inputValue}
              size={size === "s" ? "sm" : undefined}
              type="text"
              // type={isNumberInput ? (isInputNumberActive ? "number" : "text") : type}
              disabled={isDisabled}
              isInvalid={isTouched && !!error}
              className={cn(classes["input"], {
                [classes["with-left-icon"]]: iconLeft,
                [classes["with-right-icon"]]: iconRight,
              })}
              data-testid={dataTest}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {iconRight && (
              <div
                className={cn(classes.icon, classes["icon-right"], {
                  [classes.icon__clickable]: !!iconRightOnClick,
                })}
                onClick={iconRightOnClick}
              >
                {iconRight}
              </div>
            )}
            <div className={classes["info-container"]}>
              {infoElement ? (
                infoElement
              ) : (
                <InputFeedback
                  isDisabled={isDisabled}
                  isTouched={isTouched}
                  error={error}
                  info={info}
                  withoutInfoIcon={withoutInfoIcon}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  )
);

export default TextField;
