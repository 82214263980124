import { Navigate, Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import AdminContext from "pages/admin/AdminContext";
import CompanyAdmin from "pages/admin/company/CompanyAdmin";

const AdminRouter = () => {
  return (
    <AdminContext.Provider>
      <Routes>
        <Route path={ROUTER_V2.admin.company} element={<CompanyAdmin />} />
        <Route path="*" element={<Navigate to={ROUTER_V2.admin.company} />} />
      </Routes>
    </AdminContext.Provider>
  );
};

export default AdminRouter;
