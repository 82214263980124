import { Action, action, createContextStore } from "easy-peasy";

interface GrantOneOffRSAPlanContextModel {
  step: number;
  maxStep: number;
  oneOffPlanType: number;
  setStep: Action<this, number>;
  setMaxStep: Action<this, number>;
  setStepBack: Action<this, (step: number) => void>;
  setOneOffPlanType: Action<this, number>;
}

const GrantOneOffPlanContext = createContextStore<GrantOneOffRSAPlanContextModel>(
  {
    step: 0,
    maxStep: 6,
    oneOffPlanType: 0,
    setStep: action((state, payload) => {
      state.step = payload;
    }),
    setMaxStep: action((state, payload) => {
      state.maxStep = payload;
    }),
    setStepBack: action((state, payload) => {
      if (state.oneOffPlanType != 3 && state.step == 5) {
        state.step = state.step - 2;
      } else {
        state.step = state.step - 1;
      }
      payload(state.step);
    }),
    setOneOffPlanType: action((state, payload) => {
      state.oneOffPlanType = payload;
    }),
  },
  {
    name: "oneOfPlan",
  }
);
export default GrantOneOffPlanContext;
