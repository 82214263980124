import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatRelative } from "date-fns";
import enGB from "date-fns/locale/en-GB";
import * as R from "ramda";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H, P } from "common/components/atoms/Typography";
import PersonalOrCompanyRoleSelection from "common/components/molecules/personal-or-company-role-sleection/PersonalOrCompanyRoleSelection";
import ReadAndAcceptDocuments from "common/components/molecules/read-and-accept-documents/ReadAndAcceptDocuments";
import SignatureCollapsable from "common/components/molecules/signature-collapsible/SignatureCollapsible";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ReceiveIssueShares.wizard.module.scss";
import IssueEquityWizardContext from "./ReceiveIssueSharesContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.issueEquity.receiverSide");

const Context = () => {
  const navigate = useNavigate();
  const { currency } = useStoreState((state) => state.company);

  const [isReadDocuments, setIsReadDocuments] = useState<boolean>(false);

  const { invitedUser /*, account*/ } = useStoreState((state) => state);
  const { setIsDarkTheme, setIsFooterShown, setHeaderContextShown } = useStoreActions((actions) => actions.app);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const {
    isLoading,
    signature,
    companyName,
    selectedCompany,
    issueEquityDetails,
    companyOrgNumber,
    isRemoteCompanySelected,
    isHoldingCompanySelected,
  } = IssueEquityWizardContext.useStoreState((state) => state);
  const {
    setSignature,
    setCompanyName,
    setSelectedCompany,
    setCompanyOrgNumber,
    setIsRemoteCompanySelected,
    setIsHoldingCompanySelected,
    getIssueEquityDetails,
    acceptEquityThunk,
  } = IssueEquityWizardContext.useStoreActions((state) => state);

  /*const existedInvestmentEntity = useMemo(() => {
    return account?.user?.investmentEntities?.find(
      (item) =>
        item?.name?.toLowerCase() === companyName?.toLowerCase() &&
        item?.organizationNumber?.toLowerCase() === companyOrgNumber?.toLowerCase()
    );
  }, [account?.user?.investmentEntities, companyName, companyOrgNumber]);*/

  const valuedDate = useMemo(() => {
    const formatRelativeLocale = {
      lastWeek: "'last' eeee",
      yesterday: "'yesterday'",
      today: "'today'",
      tomorrow: "'tomorrow'",
      nextWeek: "'next' eeee",
      other: "dd.MM.yyyy",
    };

    return formatRelative(
      new Date(),
      issueEquityDetails?.sharePurchaseDate ? new Date(issueEquityDetails.sharePurchaseDate) : new Date(),
      {
        locale: {
          ...enGB,
          formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
        },
      }
    );
  }, [issueEquityDetails?.sharePurchaseDate]);

  const submitHandler = async () => {
    const formData = new FormData();

    formData.append("ApprovalKey", String(invitedUser.invitedUser?.approvalKey));

    if (isHoldingCompanySelected) {
      if (!R.isNil(selectedCompany)) {
        formData.append("InvestmentEntityId", String(selectedCompany?.id));
      } else {
        formData.append("CreateNewEntity", String(true));
        formData.append("CompanyName", companyName);

        if (companyOrgNumber) {
          formData.append("OrganizationNumber", companyOrgNumber);
        }
      }
    }

    if (!R.isNil(signature)) {
      formData.append("Signature", signature as File);
    }

    const request = await acceptEquityThunk(formData);

    if (request) {
      setInvitedUser(null);

      navigate(getPath(["user", "dashboard"]), { replace: true });
    }
  };

  const declineHandler = () => {
    setInvitedUser(null);

    navigate(getPath(["user", "dashboard"]), { replace: true });
  };

  useEffect(() => {
    if (!R.isNil(invitedUser) && !R.isNil(invitedUser.invitedUser) && !R.isNil(invitedUser?.invitedUser?.approvalKey)) {
      getIssueEquityDetails(invitedUser.invitedUser.approvalKey);
    }
  }, [invitedUser, getIssueEquityDetails]);

  useEffect(() => {
    if (!R.isNil(issueEquityDetails) && !R.isNil(issueEquityDetails?.stakeholderCompanyName)) {
      setCompanyName(issueEquityDetails.stakeholderCompanyName);
    }
    if (!R.isNil(issueEquityDetails) && !R.isNil(issueEquityDetails?.organisationNumber)) {
      setCompanyOrgNumber(issueEquityDetails.organisationNumber);
    }
    setIsHoldingCompanySelected(Boolean(issueEquityDetails?.usingHoldingCompany));
  }, [issueEquityDetails, setCompanyName, setCompanyOrgNumber, setIsHoldingCompanySelected]);

  useEffect(() => {
    setIsDarkTheme(true);
    setIsFooterShown(false);
    setHeaderContextShown(false);

    return () => {
      setIsDarkTheme(false);
      setIsFooterShown(true);
      setHeaderContextShown(true);
    };
  }, [setIsDarkTheme, setIsFooterShown, setHeaderContextShown]);

  if (isLoading) {
    return (
      <Spinner
        style={{
          borderColor: scssVariables.element1,
          borderRightColor: "transparent",
        }}
      />
    );
  }

  return (
    <PageContent className={classes["container"]} data-testid="receive-issued-equity-test-id">
      <H.s>{t("title")}</H.s>

      <P.l className="mt-6 mb-3">
        {t.el(issueEquityDetails?.documentsNeedsSignature ? "descriptionWithSignature" : "descriptionNoSignature", {
          values: {
            valuedDate,
            shares: issueEquityDetails?.numberOfShares,
            companyName: issueEquityDetails?.companyName,
            totalPrice: issueEquityDetails?.sharesTotalValue,
            currency: currency.symbol,
            totalPurchasePrice: issueEquityDetails?.totalPurchasePrice,
          },
          components: [
            <React.Fragment key={1}>
              <br />
              <br />
            </React.Fragment>,
            <strong key={2} />,
          ],
        })}
      </P.l>

      <PersonalOrCompanyRoleSelection
        name={companyName}
        orgNumber={companyOrgNumber}
        isRemote={isRemoteCompanySelected}
        isCollapsible={issueEquityDetails?.documentsNeedsSignature}
        isHoldingSelected={isHoldingCompanySelected}
        currentStep={issueEquityDetails?.documentsNeedsSignature ? 1 : undefined}
        setName={setCompanyName}
        setOrgNumber={setCompanyOrgNumber}
        setIsRemote={setIsRemoteCompanySelected}
        setIsHoldingSelected={setIsHoldingCompanySelected}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />

      {issueEquityDetails?.documentsNeedsSignature && (
        <>
          <div className="mt-3" />

          <ReadAndAcceptDocuments
            documents={issueEquityDetails?.documentFiles}
            setIsReadDocuments={setIsReadDocuments}
          />

          <div className="mt-3" />

          <SignatureCollapsable signature={signature} setSignature={setSignature} />
        </>
      )}

      <div className="d-flex mt-7">
        <Button
          isDisabled={
            issueEquityDetails?.documentsNeedsSignature
              ? !isReadDocuments || !signature || (isHoldingCompanySelected && !companyName && !selectedCompany)
              : isHoldingCompanySelected
              ? !companyName && !selectedCompany
              : false
          }
          onClick={submitHandler}
        >
          {t("accept")}
        </Button>
        <Button className="ms-2" variant="secondary" onClick={declineHandler}>
          {t("decline")}
        </Button>
      </div>
    </PageContent>
  );
};

const ReceiveIssueSharesWizard = () => {
  return (
    <IssueEquityWizardContext.Provider>
      <Context />
    </IssueEquityWizardContext.Provider>
  );
};

export default ReceiveIssueSharesWizard;
