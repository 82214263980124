import { FC } from "react";
import classNames from "classnames";

import classes from "./Widget.module.scss";

const Header: FC<JSX.IntrinsicElements["div"]> = ({
  className,
  children,
  ...props
}) => (
  <div className={classNames(classes["header"], className)} {...props}>
    {children}
  </div>
);

const Body: FC<JSX.IntrinsicElements["div"]> = ({ children, ...props }) => (
  <div {...props}>{children}</div>
);

type WidgetComponent = FC<JSX.IntrinsicElements["div"]> & {
  Header: typeof Header;
  Body: typeof Body;
};

const Widget: WidgetComponent = ({ className, children, ...props }) => (
  <div className={classNames(classes["widget"], className)} {...props}>
    {children}
  </div>
);

Widget.Header = Header;
Widget.Body = Body;

export default Widget;
