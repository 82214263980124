import { FC, SyntheticEvent, useCallback, useMemo, useState } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, H, ModalInfo, NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CalendarIcon, MenuTabBarVerticalIcon, RemoveIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext, { IExercisingItem } from "../../ExercisingContext";
import ExercisingCollapsable from "../ExercisingCollapsable/ExercisingCollapsable";
import classes from "../styles.module.scss";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.exercising.pendingApproval"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const PendingApproval: FC<{ companyId: number }> = ({ companyId }) => {
  const fNumber = useFormatNumbers();
  const { hasFullAccess } = useFeatures(FEATURES.exercise);

  const [approvalAll, setApprovalAll] = useState(false);
  const [approveSingle, setApproveSingle] = useState<IExercisingItem | null>(null);

  const pendingApproval = ExercisingContext.useStoreState((state) => state.pendingApproval);
  const requestLoading = ExercisingContext.useStoreState((state) => state.requestLoading);
  const missingBankInformation = ExercisingContext.useStoreState((state) => state.missingBankInformation);
  const pendingApprovalTotal = ExercisingContext.useStoreState((state) => state.overview.pendingApprovalTotal);
  const approveSinglePendingThunk = ExercisingContext.useStoreActions((actions) => actions.approveSinglePendingThunk);
  const getMenageExercisingThunk = ExercisingContext.useStoreActions((actions) => actions.getMenageExercisingThunk);
  const approveAllPendingThunk = ExercisingContext.useStoreActions((actions) => actions.approveAllPendingThunk);
  const setDeclineExercising = ExercisingContext.useStoreActions((actions) => actions.setDeclineExercising);

  const tooltipsContent = useMemo(() => {
    return {
      title: missingBankInformation ? tCommon("bankDetailsRequried") : !hasFullAccess ? tCommon("viewOnly") : undefined,
      message: missingBankInformation
        ? tCommon("addBankDetailsToAccess")
        : !hasFullAccess
        ? tCommon("tooltip")
        : undefined,
    };
  }, [hasFullAccess, missingBankInformation]);

  const menuItems = useCallback(
    (item: IExercisingItem) => {
      return [
        {
          key: "delete",
          type: "delete",
          noBorder: true,
          icon: <RemoveIcon />,
          label: t("decline"),
          onClick: !hasFullAccess
            ? undefined
            : () => {
                setDeclineExercising(item);
              },
          forceHideDropdown: true,
          isDisabled: missingBankInformation || !hasFullAccess,
        },
      ] as ContextMenuProps["items"];
    },
    [hasFullAccess, missingBankInformation, setDeclineExercising]
  );

  const handleOpenApprovalAllModal = useCallback(
    (e?: SyntheticEvent) => {
      e?.stopPropagation();
      setApprovalAll(!approvalAll);
    },
    [approvalAll]
  );

  const handleOpenApprovalSingleModal = useCallback(
    (item?: IExercisingItem) => () => {
      setApproveSingle(item ? item : null);
    },
    []
  );

  const handleApproveSingle = useCallback(() => {
    if (approveSingle?.exerciseRequestId) {
      approveSinglePendingThunk(approveSingle.exerciseRequestId).then(() => {
        notify(t("success"), true, "success", 2000);
        handleOpenApprovalSingleModal()();
        getMenageExercisingThunk(companyId);
      });
    }
  }, [
    approveSingle?.exerciseRequestId,
    approveSinglePendingThunk,
    companyId,
    getMenageExercisingThunk,
    handleOpenApprovalSingleModal,
  ]);

  const handleApproveAll = useCallback(() => {
    approveAllPendingThunk(companyId).then(() => {
      notify(t("success"), true, "success", 2000);
      handleOpenApprovalAllModal();
      getMenageExercisingThunk(companyId);
    });
  }, [approveAllPendingThunk, companyId, getMenageExercisingThunk, handleOpenApprovalAllModal]);

  const ApprovalAll = useCallback(() => {
    return (
      <>
        <Button
          isLoading={requestLoading}
          onClick={handleApproveAll || !hasFullAccess}
          isDisabled={missingBankInformation || requestLoading}
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
        >
          {t("modalApproveAll.approveAll")}
        </Button>
        <Button
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          onClick={handleOpenApprovalAllModal}
          variant="secondary"
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
        >
          {t("modalApproveAll.cancel")}
        </Button>
      </>
    );
  }, [
    handleApproveAll,
    handleOpenApprovalAllModal,
    hasFullAccess,
    missingBankInformation,
    requestLoading,
    tooltipsContent.message,
    tooltipsContent.title,
  ]);

  const ApprovalSingle = useCallback(() => {
    return (
      <>
        <Button
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          isLoading={requestLoading}
          onClick={handleApproveSingle}
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
        >
          {t("modalApprove.approveNow")}
        </Button>
        <Button
          isDisabled={requestLoading || !hasFullAccess || missingBankInformation}
          onClick={handleOpenApprovalSingleModal()}
          variant="secondary"
          tooltipTitle={tooltipsContent.title}
          tooltipMessage={tooltipsContent.message}
        >
          {t("modalApprove.cancel")}
        </Button>
      </>
    );
  }, [
    handleApproveSingle,
    handleOpenApprovalSingleModal,
    hasFullAccess,
    missingBankInformation,
    requestLoading,
    tooltipsContent.message,
    tooltipsContent.title,
  ]);

  return (
    <div>
      <ModalInfo
        show={approvalAll}
        handleClose={handleOpenApprovalAllModal}
        header={<H.xxxs>{t("modalApproveAll.title")}</H.xxxs>}
        footer={<ApprovalAll />}
      >
        {t("modalApproveAll.description")}
      </ModalInfo>
      <ModalInfo
        show={!!approveSingle}
        handleClose={handleOpenApprovalSingleModal()}
        header={<H.xxxs>{t("modalApprove.title")}</H.xxxs>}
        footer={<ApprovalSingle />}
      >
        {t("modalApprove.description", {
          name: approveSingle?.requestorName,
          options: approveSingle?.numberOfOptions,
          companyName: approveSingle?.companyName,
        })}
      </ModalInfo>

      <ExercisingCollapsable
        title={t("title")}
        btnTitle={t("approveAll")}
        description={t("description")}
        countItems={pendingApproval.length}
        headButtonDisabled={!hasFullAccess || missingBankInformation}
        headButtonTooltipTitle={tooltipsContent.title}
        headButtonTooltipMessage={tooltipsContent.message}
        onHeadBtnClick={pendingApprovalTotal > 0 ? handleOpenApprovalAllModal : undefined}
      >
        {!pendingApproval.length ? (
          <div className="p-4">
            <Ui.s color="foregroundLow">{t("noHistory")}</Ui.s>
          </div>
        ) : (
          pendingApproval.map((item) => (
            <div key={item.exerciseRequestId} className={classes["row"]}>
              <NewAvatar
                company={item?.requestorIsCompanyOwned}
                imageUrl={item?.avatarFilePath}
                initials={item?.requestorIsCompanyOwned ? item?.requestorCompanyName : item.requestorName}
              />

              <div className="ms-2">
                <Ui.m bold className="mb-1">
                  {item?.requestorIsCompanyOwned
                    ? `${item?.requestorCompanyName} (${item.requestorName})`
                    : item.requestorName}{" "}
                  • {fNumber(item.numberOfOptions, "amount")} options
                </Ui.m>

                <div className="d-flex align-items-center">
                  <CalendarIcon fontSize={16} color={scssVariables.foregroundLow} />
                  <Ui.xs color="foregroundLow" className="ms-1">
                    {transformDateToCommonDateFormat(item.requestedAt, "d. MMM yyyy").toLowerCase()}
                    &nbsp;•&nbsp;
                  </Ui.xs>

                  <Ui.xs color="foregroundLow">
                    {t("item.requestToExercise", {
                      exercisingOptions: fNumber(item.numberOfOptions, "amount"),
                      totalOptions: fNumber(item.vestedNotExercised, "amount"),
                      price: fNumber(item.convertPrice),
                    })}
                  </Ui.xs>
                </div>
              </div>

              <Button
                size="s"
                variant="tertiary"
                className="ms-auto me-1"
                isDisabled={!hasFullAccess || missingBankInformation}
                onClick={handleOpenApprovalSingleModal(item)}
                tooltipTitle={tooltipsContent.title}
                tooltipMessage={tooltipsContent.message}
              >
                {t("approveNow")}
              </Button>

              <ContextMenu items={menuItems(item)}>
                <Button isOnlyIcon variant="tertiary" className={classes["menu-button"]}>
                  <MenuTabBarVerticalIcon />
                </Button>
              </ContextMenu>
            </div>
          ))
        )}
      </ExercisingCollapsable>
    </div>
  );
};

export default PendingApproval;
