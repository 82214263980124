import { FC, Fragment, memo, useMemo } from "react";
import cn from "classnames";
import { isEmpty, isNil } from "ramda";

import { Ui } from "common/components/atoms";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";

import ProgramContainer from "../ProgramContainer/ProgramContainer";
import classes from "./PlansPrograms.module.scss";

const defaultExpanded = ["one-off"];

type PlanProgramsProps = {
  onGrantAllPlansPress?: (programId: number) => void;
  isOneOffIndividualPlan?: boolean;
};

const PlansPrograms: FC<PlanProgramsProps> = ({ onGrantAllPlansPress, isOneOffIndividualPlan = false }) => {
  const { ownershipPlans } = useStoreState((state) => state.company);

  const oneOffPlans = useMemo(() => ownershipPlans?.oneOffPlans || [], [ownershipPlans?.oneOffPlans]);

  const initialSavedProgramsKeys = useMemo(
    // all programs should be expanded by default
    () => ownershipPlans?.ownershipPrograms?.map((el) => String(el.id)),
    [ownershipPlans?.ownershipPrograms]
  );

  if (isOneOffIndividualPlan) {
    return (
      <div className={cn("mb-4", classes["one-off-plans"])}>
        <ExpansionPanel name="one-off" saveExpandedKeys defaultActiveKey={defaultExpanded}>
          <ProgramContainer defaultOpen isOneOffIndividualPlan={isOneOffIndividualPlan} data={oneOffPlans} />
        </ExpansionPanel>
      </div>
    );
  }

  return (
    <>
      {!isNil(ownershipPlans?.ownershipPrograms) &&
      !isNil(ownershipPlans?.oneOffPlans) &&
      isEmpty(ownershipPlans?.ownershipPrograms) &&
      isEmpty(ownershipPlans?.oneOffPlans) &&
      !isOneOffIndividualPlan ? (
        <div className="d-flex align-items-center">
          <InformationCircleIcon fontSize={24} className="me-1" color={scssVariables.foregroundLow} />
          <Ui.m color="foregroundLow">No agreements found. Try adjusting the filters to broaden your search.</Ui.m>
        </div>
      ) : null}
      <ExpansionPanel name="programs" saveExpandedKeys defaultActiveKey={initialSavedProgramsKeys}>
        {ownershipPlans?.ownershipPrograms?.map((el, index) => {
          if (isNil(el)) {
            return null;
          }

          return (
            <Fragment key={el.id}>
              <ProgramContainer
                programID={el.id}
                title={el.name}
                cliff={el.cliff}
                data={el.plans}
                status={el.statusName}
                description={el.description}
                isOneOffIndividualPlan={isOneOffIndividualPlan}
                vestingPeriod={el.vestingPeriod}
                vestingPeriodTimeUnit={el.vestingPeriodTimeUnit}
                cliffTimeUnit={el.cliffTimeUnit}
                stockType={el.instrumentTypeName}
                stockTypeId={el.instrumentTypeId}
                index={index}
                onGrantAllPlansPress={onGrantAllPlansPress}
              />

              <div className="mt-4" />
            </Fragment>
          );
        })}
      </ExpansionPanel>
    </>
  );
};

export default memo(PlansPrograms);
