import { FC, useMemo } from "react";
import cn from "classnames";

import { NewAvatar, Table, Ui } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";
import { StandaloneAgreement } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "./StandaloneTable.module.scss";

type PropsTypes = {
  agreements: StandaloneAgreement[];
};

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.standaloneTable.header");

const Header = () => {
  const rows = useMemo(
    () => [
      {
        key: "stakeholder",
        value: t("stakeholder"),
      },
      {
        key: "agreement type",
        value: t("agreementType"),
      },

      {
        key: "issuedShares",
        value: t("issuedShares"),
      },
      {
        key: "vestedShares",
        value: t("vestedShares"),
      },
      {
        key: "totalShares",
        value: t("totalShares"),
      },
    ],
    []
  );

  return (
    <thead>
      <tr>
        {rows.map((el, i) => (
          <th key={el.key} className="py-2 px-3">
            <Ui.xs
              className={cn("d-flex align-items-center", {
                "justify-content-end": i >= 2,
              })}
              color="foregroundLow"
            >
              {el.value}
            </Ui.xs>
          </th>
        ))}
      </tr>
    </thead>
  );
};

type BodyProps = {
  agreements: StandaloneAgreement[];
};

const Body: FC<BodyProps> = ({ agreements }) => {
  return (
    <tbody>
      {agreements.map((agreement) => (
        <tr key={agreement.id}>
          <td className="py-1 px-3">
            <div className="d-flex align-items-center">
              <NewAvatar
                size="m"
                imageUrl={agreement.avatarFilePath}
                company={agreement.isCompanyOwned}
                initials={agreement.initials}
                className={classes.avatar}
              />

              <div className="ms-2">
                <div className="d-flex align-items-center">
                  <Ui.s bold className="me-1">
                    {agreement.name || "-"}
                  </Ui.s>
                </div>

                {agreement.isCompanyOwned ? (
                  <Ui.s style={{ color: scssVariables.foregroundLow }}>{agreement.companyName}</Ui.s>
                ) : null}
              </div>
            </div>
          </td>
          <td className="py-1 px-3">
            <Ui.s>{agreement.agreementType}</Ui.s>
          </td>
          <td className="py-1 px-3">
            <Ui.s className="d-flex align-items-center justify-content-end">{agreement.issuedShares}</Ui.s>
          </td>
          <td className="py-1 px-3">
            <Ui.s className="d-flex align-items-center justify-content-end">{agreement.vestedShares}</Ui.s>
          </td>
          <td className="py-1 px-3">
            <Ui.s className="d-flex align-items-center justify-content-end">{agreement.totalShares}</Ui.s>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const StandaloneTable: FC<PropsTypes> = ({ agreements }) => {
  if (agreements.length === 0) {
    return (
      <Ui.s className="mt-3 ms-3" color="foregroundLow">
        No standalone agreements available
      </Ui.s>
    );
  }

  return (
    <Table
      className={cn(classes.table, "mt-2")}
      headComponent={<Header />}
      bodyComponent={<Body agreements={agreements} />}
    />
  );
};

export default StandaloneTable;
