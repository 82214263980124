import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

import { PostStakeholdersInviteDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import InviteForm from "./invite-form";

const tValidation = createTranslation(TranslationNS.validation);

export type FormValues = {
  email: string;
  roleId: number;
  features: Record<number, boolean>;
};

type PropsTypes = {
  onClose?: () => void;
};

const Modal: FC<PropsTypes> = ({ onClose }) => {
  const { companyId = "0" } = useParams();

  const { inviteStakeholdersThunk, closeInviteModal, openInviteSentModalOpen } = useStoreActions(
    (actions) => actions.stakeholderInvitation
  );
  const { stakeholderToInvite } = useStoreState((store) => store.stakeholderInvitation);

  const inviteStakeholder = useCallback(
    async (values: FormValues) => {
      if (stakeholderToInvite?.stakeholderId) {
        const postBody: PostStakeholdersInviteDTO = {
          companyId: Number(companyId),
          accessInvitations: [
            {
              stakeholderId: stakeholderToInvite.stakeholderId,
              accessRoleId: values.roleId,
              email: values.email,
              accessRoleFeatureIds: Object.entries(values.features).reduce((acc: number[], [key, value]) => {
                if (value) {
                  return [...acc, Number(key)];
                }
                return acc;
              }, []),
            },
          ],
        };

        const success = await inviteStakeholdersThunk({ data: postBody, onClose, companyId: Number(companyId) });

        if (success) {
          closeInviteModal();
          openInviteSentModalOpen();
        }
      }
    },
    [
      closeInviteModal,
      companyId,
      inviteStakeholdersThunk,
      onClose,
      openInviteSentModalOpen,
      stakeholderToInvite?.stakeholderId,
    ]
  );

  const formInitialValues: FormValues = useMemo(
    () => ({
      email: stakeholderToInvite?.email || "",
      roleId: 0,
      features: {},
    }),
    [stakeholderToInvite?.email]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email().required(tValidation("required")),
        roleId: Yup.number().min(1, tValidation("required")).required(tValidation("required")),
      }),
    []
  );

  return (
    <Formik onSubmit={inviteStakeholder} initialValues={formInitialValues} validationSchema={validationSchema}>
      <InviteForm />
    </Formik>
  );
};

const InviteStakeholderModal: FC<PropsTypes> = (props) => {
  const { isInviteModalOpen } = useStoreState((store) => store.stakeholderInvitation);

  if (isInviteModalOpen) {
    return <Modal {...props} />;
  }
};

export default InviteStakeholderModal;
