import { P } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { InformationCircleIcon } from "common/icons/svg";

import SOAgreementContext from "../../../contexts/SOAgreementContext";
import classes from "./MarkedSharesAsSold.module.scss";

const MarkedSharesAsSold = () => {
  const agreementDetails = SOAgreementContext.useStoreState((state) => state.agreementDetails);
  const fNumber = useFormatNumbers();
  if (!agreementDetails?.exercisedSharesSold) {
    return null;
  }

  return (
    <div className={classes.wrap}>
      <div className="d-flex align-items-center" style={{ gap: 12 }}>
        <InformationCircleIcon fontSize={24} />
        <P.s color="foregroundHigh">
          {fNumber(agreementDetails?.exercisedSharesSold, "amount")} shares were sold from this agreement. The total
          agreement value reflects this.
        </P.s>
      </div>
    </div>
  );
};

export default MarkedSharesAsSold;
