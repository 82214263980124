import { useMemo } from "react";
import * as Yup from "yup";

import { createTranslation, TranslationNS } from "translation";

export const userFields = {
  firstName: "firstName",
  lastName: "lastName",
  email: "email",
  hasAcceptedTermsOfService: "hasAcceptedTermsOfService",
  hasAcceptedPrivacyPolicy: "hasAcceptedPrivacyPolicy",
  profileImage: "profileImage",
};
const t = createTranslation(TranslationNS.validation);
export const useUserSchema = () => {
  return useMemo(
    () =>
      Yup.object().shape({
        [userFields.firstName]: Yup.string().required(t("required")),
        [userFields.lastName]: Yup.string().required(t("required")),
        [userFields.email]: Yup.string().email().required(t("required")),
        [userFields.hasAcceptedTermsOfService]: Yup.boolean().isTrue(t("required")).required(t("required")),
        [userFields.hasAcceptedPrivacyPolicy]: Yup.boolean().isTrue(t("required")).required(t("required")),
      }),
    []
  );
};
