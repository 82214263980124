import { FC } from "react";

import { SingleProgramDocumentType } from "../../DocumentsContext";
import classes from "../CommonStyles.module.scss";
import StandaloneDocumentsTableRow from "./StandaloneDocumentsTableRow";

type StandaloneDocumentsTableRowProps = {
  data: SingleProgramDocumentType["agreements"];
};

const StandaloneDocumentsTableBody: FC<StandaloneDocumentsTableRowProps> = ({ data }) => {
  return (
    <tbody className={classes.body}>
      {data.map((el, index) => (
        <StandaloneDocumentsTableRow item={el} key={`${el.name} ${index} ${el.entityId}`} />
      ))}
    </tbody>
  );
};

export default StandaloneDocumentsTableBody;
