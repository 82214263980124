import { FC } from "react";
import { useFormikContext } from "formik";

import TextArea from "common/components/atoms/TextArea/TextArea";
import { createTranslation, TranslationNS } from "translation";

import { PoolFormValues } from "../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";

export type DescriptionFieldProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.descriptionField");

const PoolDescriptionField: FC<DescriptionFieldProps> = ({ className }) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<Pick<PoolFormValues, "description">>();

  return (
    <TextArea
      name="description"
      label={t("label")}
      rows={8}
      value={values.description || ""}
      isOptional
      onChange={handleChange}
      error={errors.description}
      isTouched={touched.description}
      onBlur={handleBlur}
      className={className}
    />
  );
};

export default PoolDescriptionField;
