import { FC } from "react";
import classNames from "classnames";

import Header from "./Header/Header";
import classes from "./PageContent.module.scss";

export type PageContentProps = JSX.IntrinsicElements["div"];

type PageContentComponent = FC<PageContentProps> & {
  Header: typeof Header;
};

const PageContent: PageContentComponent = ({ className, children, ...props }) => (
  <article className={classNames(classes["page-content"], className)} {...props}>
    {children}
  </article>
);

PageContent.Header = Header;

export default PageContent;
