import { FC, useCallback } from "react";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import { openRemoteDocument } from "common/utils/functions";

import classes from "../../stakeholder-details.module.scss";

const ViewDocument: FC<{ downloadId?: string; fileName: string }> = ({ downloadId, fileName }) => {
  const handleDownload = useCallback(async () => {
    await openRemoteDocument(downloadId);
  }, [downloadId]);

  return (
    <Ui.s onClick={handleDownload} className={classNames("mt-1 mb-1", classes.viewDocument)}>
      {fileName}
    </Ui.s>
  );
};
export default ViewDocument;
