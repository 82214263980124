import { useMemo } from "react";

import StakeholderAddressField from "../fields/StakeholderAddressField";
import StakeholderCountryField from "../fields/StakeholderCountryField";
import StakeholderDateOfBirthField from "../fields/StakeholderDateOfBirthField";
import StakeholderEmailField from "../fields/StakeholderEmailField";
import StakeholderFirstNameField from "../fields/StakeholderFirstNameField";
import StakeholderLastNameField from "../fields/StakeholderLastNameField";
import StakeholderPhoneNumberField from "../fields/StakeholderPhoneNumberField";
import StakeholderRelationField from "../fields/StakeholderRelationField";
import { StakeholderField, StakeholderFieldsParamsType } from "../Stakeholder.types";

const usePerson = (updatedFields?: StakeholderFieldsParamsType) => {
  return useMemo<{ topFields: StakeholderField[]; otherFields: StakeholderField[] }>(() => {
    const fields: StakeholderField[] = [
      {
        key: "firstName",
        Component: StakeholderFirstNameField,
        meta: {},
        isTopField: true,
      },
      {
        key: "lastName",
        Component: StakeholderLastNameField,
        meta: {},
        isTopField: true,
      },
      {
        key: "email",
        Component: StakeholderEmailField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "relationshipTypeId",
        Component: StakeholderRelationField,
        meta: { isOptional: false },
        isTopField: true,
      },
      {
        key: "phoneNumber",
        Component: StakeholderPhoneNumberField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "countryId",
        Component: StakeholderCountryField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "address",
        Component: StakeholderAddressField,
        meta: { isOptional: true },
        isTopField: false,
      },
      {
        key: "dateOfBirth",
        Component: StakeholderDateOfBirthField,
        meta: { isOptional: true },
        isTopField: false,
      },
    ];

    const listOfFields = updatedFields ? updatedFields : {};

    const combinedFields = fields.map((field) => ({ ...field, ...listOfFields[field.key] }));

    const topFields = combinedFields.filter((field) => field.isTopField);
    const otherFields = combinedFields.filter((field) => !field.isTopField);

    return { topFields, otherFields };
  }, [updatedFields]);
};

export default usePerson;
