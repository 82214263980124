import { addQuarters, differenceInQuarters, differenceInYears } from "date-fns";
import * as R from "ramda";
import { defaultTo } from "ramda";

import { ValuationSingle } from "../store/types";
import { createDateString } from "./components/atoms/DatePicker/DatePicker";
import { stepPoint } from "./types/Charts.types";
import { scssVariables } from "./utils/constants";

export const convertDotToComma = (number: number, digits = 2) => {
  return number.toFixed(digits).replace(".", ",");
};

export function transformChartAxisToYearsOrQuarters({
  data,
  isMoreThanYear = true,
}: {
  data: Omit<stepPoint, "description">[];
  isMoreThanYear?: boolean;
}) {
  const axesPoints = [data?.[0]?.x];

  return data?.reduce((acc, curr, i) => {
    if (isMoreThanYear) {
      const firstDate = new Date(curr.x);
      const secondDate = new Date(axesPoints?.[axesPoints.length - 1]);

      const diffInYears = differenceInYears(
        new Date(firstDate.getFullYear(), firstDate.getMonth(), 1),
        new Date(secondDate.getFullYear(), secondDate.getMonth(), 1)
      );

      if (i > 0 && diffInYears > 0) {
        acc.push(curr.x);
      }
      return acc;
    }

    const diffInQuarters = differenceInQuarters(new Date(curr.x), new Date(axesPoints?.[axesPoints.length - 1]));

    if (i > 0 && diffInQuarters > 0) {
      acc.push(curr.x);
    }

    return acc;
  }, axesPoints);
}

export function setMissedQuarters(data: stepPoint[]) {
  const copyData = R.clone(data)
    ?.filter((item) => !R.isNil(item))
    .map((el) => R.assoc("x", new Date(el.x).toDateString(), el));

  const transformedData = copyData.reduce((acc, curr, index, array) => {
    const currentValue = new Date(array[index]?.x);

    if (array[index + 1]) {
      const nextValue = new Date(array[index + 1]?.x);

      const difference = differenceInQuarters(nextValue, currentValue);

      if (difference > 1) {
        acc.push({
          x: createDateString(new Date(curr.x)),
          y: curr?.y,
        });

        new Array(difference - 1).fill(1).forEach((_, index) => {
          acc.push({
            x: createDateString(addQuarters(currentValue, index + 1)),
            y: curr?.y,
          });
        });
      } else {
        acc.push({
          x: createDateString(new Date(curr.x)),
          y: curr?.y,
        });
      }
    } else {
      acc.push({
        x: createDateString(new Date(curr.x)),
        y: curr?.y,
      });
    }

    return acc;
  }, [] as stepPoint[]);

  return transformedData;
}

export function splitExercisedAndAvailableForPurchaseDataPoints(
  data: {
    id: string;
    color: string;
    data: stepPoint[];
  }[],
  availableForPurchaseValue: number
) {
  if (R.or(R.isEmpty(data), R.isNil(data))) {
    return [];
  }

  const [vestedData, restrictedData] = data;
  const restrictedDataNonNullable = {
    ...restrictedData,
    data: R.defaultTo([], restrictedData.data).map((el) => ({ ...el, y: R.defaultTo(0, el.y) })),
  };
  const maxVestedValue = R.defaultTo(0, R.last(vestedData.data)?.y) as number;

  // Reverting this logic because of issue -
  // https://www.notion.so/unlistedeq/Vesting-graph-is-not-drawn-on-details-of-Stock-options-agreement-with-Vesting-start-date-today-d38159275f96435bb391747a39680113
  // to revert logic back just uncomment if statements

  // // if no purchase - return existing data
  // if (availableForPurchaseValue === 0) {
  //   return data;
  // }
  //
  // // if purchase is 100% - replace shares (green one) chart with purchase price (blue one) chart
  // if (availableForPurchaseValue === maxVestedValue) {
  //   return R.update(
  //     0,
  //     {
  //       id: "Available for purchase",
  //       color: scssVariables.positive500,
  //       data: vestedData.data,
  //     },
  //     data
  //   );
  // }

  // we need to find a range where purchase price might be
  const purchaseFirstPointDivider = R.last(
    vestedData.data.filter((el) => +el.y <= maxVestedValue - availableForPurchaseValue)
  );

  let firstDataRange = vestedData.data.filter((el) => +el.y <= maxVestedValue - availableForPurchaseValue);
  let secondDataRange = vestedData.data.filter((el) => +el.y > maxVestedValue - availableForPurchaseValue);

  if (!R.isNil(purchaseFirstPointDivider) && Number(purchaseFirstPointDivider.y) > 0) {
    firstDataRange = R.append(
      {
        x: purchaseFirstPointDivider?.x as string,
        y: maxVestedValue - availableForPurchaseValue,
      },
      firstDataRange
    );
  }

  if (!R.isNil(purchaseFirstPointDivider)) {
    secondDataRange = R.insert(
      0,
      {
        x: purchaseFirstPointDivider?.x || 0,
        y: maxVestedValue - availableForPurchaseValue,
      },
      secondDataRange
    );
  }

  return [
    {
      id: vestedData.id,
      color: vestedData.color,
      data: firstDataRange,
    },
    {
      id: "Available for purchase",
      color: scssVariables.positive500,
      data: secondDataRange,
    },
    restrictedDataNonNullable,
  ];
}

export function sortHistoryValuation(data: ValuationSingle[]) {
  return data.sort((a, b) => {
    if (a.validFrom && b.validFrom) {
      if (new Date(b.validFrom) > new Date(a.validFrom)) {
        return 1;
      }
    }

    return -1;
  });
}

export function sortValuationData(data: Omit<stepPoint, "content">[]) {
  return data.sort((a, b) => {
    if (new Date(a.x) > new Date(b.x)) {
      return 1;
    }

    return -1;
  });
}

export const getFiveIndexes = (length: number) => {
  const third = Math.floor(length / 2);
  const second = Math.floor(third / 2);
  const fourth = Math.floor((third + length) / 2);

  return [0, second, third, fourth, length - 1];
};

type OSs = "Win" | "Mac" | "Linux" | "Unknown";

export const getOS = (): OSs => {
  const ua = navigator.userAgent;

  if (ua.indexOf("Win") != -1) {
    return "Win";
  }
  if (ua.indexOf("Mac") != -1) {
    return "Mac";
  }
  if (ua.indexOf("Linux") != -1) {
    return "Linux";
  }

  return "Unknown";
};
