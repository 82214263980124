import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { Formik, useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { Ui } from "common/components/atoms/Typography";
import StakeholderSection, {
  StakeholderContextProps,
} from "common/components/molecules/StakeholderSection/StakeholderSection";
import { IssueEquityConvertibleStatuses, RelationshipTypesEnum } from "common/enums/enum";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import Documentation from "./sections/Documentation";
import Terms from "./sections/Terms";
import useConvertibleNoteEditPanel from "./useConvertibleNoteEditPanel";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleNote.editPanel");

const FormContent: FC = () => {
  useToastFormikValidator();
  const { initialValues, closePanel } = useConvertibleNoteEditPanel();

  const { values, isSubmitting, handleSubmit, setFieldValue } = useFormikContext<typeof initialValues>();

  const updatedFields = useMemo<StakeholderContextProps["updatedFields"]>(
    () => (values.sendInvitationEmail ? { email: { isTopField: true, meta: { isOptional: false } } } : undefined),
    [values.sendInvitationEmail]
  );

  const handleSaveAsDraft = useCallback(() => {
    setFieldValue("saveAsDraft", true);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  return (
    <>
      <SlidePanel.Section title={t("receiver")}>
        <StakeholderSection
          updatedFields={updatedFields}
          placeholder="Select the stakeholder to receive this convertible note, or create a new one."
          relationshipTypeId={RelationshipTypesEnum.INVESTOR}
        />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("terms")}>
        <Terms />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("documentation")} isDividerVisible={false}>
        <Documentation />
      </SlidePanel.Section>

      <Ui.m italic className="mt-3">
        {t("updatedCapTableDiluted")}
      </Ui.m>

      <SlidePanel.Actions>
        <div className="flex-grow-1">
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
            className="me-3"
          >
            {t("issueConvertibleNote")}
          </Button>

          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="secondary" onClick={closePanel}>
            {t("cancel")}
          </Button>
        </div>
        {values?.statusId !== IssueEquityConvertibleStatuses.Converting &&
          values?.statusId !== IssueEquityConvertibleStatuses.Approved && (
            <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="tertiary" onClick={handleSaveAsDraft}>
              {t("saveAsDraft")}
            </Button>
          )}
      </SlidePanel.Actions>
    </>
  );
};

const ConvertibleNoteForm: FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { noteId } = IssueEquityContext.useStoreState((state) => state);
  const { getConvertibleNoteThunk } = IssueEquityContext.useStoreActions((actions) => actions);

  const { initialValues, validationSchema, onSubmit } = useConvertibleNoteEditPanel();

  useEffect(() => {
    if (noteId) {
      setIsLoading(true);
      getConvertibleNoteThunk(noteId).finally(() => {
        setIsLoading(false);
      });
    }
  }, [getConvertibleNoteThunk, noteId]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <FormContent />
      </Formik>
    </SlidePanel.Body>
  );
};

const ConvertibleNoteEditPanel: FC = () => {
  const { isConvertibleNoteEditPanelOpen, noteId } = IssueEquityContext.useStoreState((state) => state);
  const { setIsConvertibleNoteEditPanelOpen } = IssueEquityContext.useStoreActions((actions) => actions);

  const closePanel = useCallback(() => {
    setIsConvertibleNoteEditPanelOpen({ open: false });
  }, [setIsConvertibleNoteEditPanelOpen]);

  return (
    <SlidePanel show={isConvertibleNoteEditPanelOpen}>
      <SlidePanel.Header title={t("titleCreate")} isEdit={!!noteId} onHide={closePanel} />
      <ConvertibleNoteForm />
    </SlidePanel>
  );
};

export default ConvertibleNoteEditPanel;
