import { FC } from "react";

import { P } from "common/components/atoms/Typography";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import ReceiveConvertibleNoteContext from "../ReceiveConvertibleNoteContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleNote.receiverSide");

const Info: FC = () => {
  const { convertibleNoteDetails } = ReceiveConvertibleNoteContext.useStoreState((state) => state);
  const { currencySymbol } = useCurrencyById(convertibleNoteDetails?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  return (
    <>
      <P.l className="mt-6 mb-3">
        {t.el("description", {
          values: {
            companyName: convertibleNoteDetails?.companyName,
            invesmentAmount: fNumber(convertibleNoteDetails?.investmentAmount, "value"),
          },
          components: [<strong key={1} />],
        })}{" "}
        {convertibleNoteDetails?.discount
          ? t("discount", {
              discount: fNumber(convertibleNoteDetails?.discount, "percent"),
            })
          : null}
        .
      </P.l>

      {convertibleNoteDetails?.triggerAmount ? (
        <P.l className="mt-2">
          {t.el("triggerAmount", {
            values: {
              triggerAmount: fNumber(convertibleNoteDetails?.triggerAmount, "value"),
              expirationDate: transformDateToCommonDateFormat(convertibleNoteDetails?.expirationDate),
            },
            components: [<strong key={1} />],
          })}
        </P.l>
      ) : null}

      {convertibleNoteDetails?.valuationCap ? (
        <P.l>
          {t.el("valuationCap", {
            values: {
              valuationCap: fNumber(convertibleNoteDetails?.valuationCap, "value"),
            },
            components: [<strong key={1} />],
          })}
        </P.l>
      ) : null}

      {convertibleNoteDetails?.shareClass ? (
        <P.l className="mt-2">
          {t("shareClass", {
            shareClassName: convertibleNoteDetails?.shareClass,
          })}
        </P.l>
      ) : null}

      <P.l className="mt-3 mb-6">
        {t(
          convertibleNoteDetails?.documentsNeedsSignature
            ? "descriptionBottomWithSignature"
            : "descriptionBottomNoSignature"
        )}
      </P.l>
    </>
  );
};

export default Info;
