import { FC, useCallback, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, Check } from "common/components/atoms";
import Helper from "common/components/atoms/Helper/Helper";
import { H } from "common/components/atoms/Typography";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { PlusIcon } from "common/icons/svg";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import { newPlanFieldsNames as f } from "../../../../../../../common/plan/planFormFields";
import ProgramHelper from "../../components/ProgramHelper";
import SpentProgressBar from "../../components/SpentProgressBar/SpentProgressBar";
import ProgramEssentialTermsField from "../../programFields/ProgramEssentialTermsField";
import ProgramExercisePriceField from "../../programFields/ProgramExercisePriceField";
import ProgramNumberOfShares from "../../programFields/ProgramNumberOfShares";
import ProgramOptionsExpirationDateFields from "../../programFields/ProgramOptionsExpirationDateFields";
import ProgramPoolField from "../../programFields/ProgramPoolField";
import ProgramPurchasePriceField from "../../programFields/ProgramPurchasePriceField";
import ProgramTypeField from "../../programFields/ProgramTypeField";
import { CreateProgramFormData, fields } from "../../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.sharesDetails");

const SharesDetails: FC = () => {
  const { setTotalWizardSteps } = useWizard();
  const { companyId } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { values, setFieldValue, isSubmitting, handleChange } = useFormikContext<CreateProgramFormData>();

  useEffect(() => {
    if (!values.poolId && state?.poolId) {
      setFieldValue(fields.poolId, state.poolId).then();
    }
  }, [state?.poolId, setFieldValue, values.poolId]);

  const handleClickBack = useCallback(() => {
    navigate(getEMPath(["createProgram", "main"]), { state });
  }, [navigate, state]);

  const addPoolButtonClickHandler = useCallback((): void => {
    navigate(getEMPath(["createPool", "basic"]));
  }, [navigate]);

  useEffect(() => {
    setTotalWizardSteps(values.programTypeId === InstrumentTypesIdsEnum.OPTION ? 5 : 4);
  }, [setTotalWizardSteps, values.programTypeId]);

  return (
    <WizardContent.Content step={2}>
      <H.xs className=" text-left mb-5">{t("title")}</H.xs>
      <Form>
        <Helper>
          <ProgramHelper colInputSize={6} questionId={["poolId"]}>
            <ProgramPoolField companyId={companyId} />
            <Button
              variant="tertiary"
              size="s"
              className="mt-4"
              onClick={addPoolButtonClickHandler}
              iconLeft={<PlusIcon />}
            >
              {t("createNewPool")}
            </Button>
          </ProgramHelper>

          <ProgramTypeField />

          <ProgramHelper className="mt-4" colInputSize={6} questionId={["numberOfShares"]}>
            <ProgramNumberOfShares />
            <SpentProgressBar
              className="mt-1"
              title={t("poolStatus.title")}
              valueText={t("poolStatus.valueText")}
              now={values.numberOfShares || 0}
              max={values?.numberOfSharesLeft || 0}
            />
          </ProgramHelper>

          <ProgramHelper className="mt-4" colInputSize={6} questionId={["exercisePrice", "purchasePrice"]}>
            {values.programTypeId === InstrumentTypesIdsEnum.OPTION ? <ProgramExercisePriceField /> : null}
            {values.programTypeId === InstrumentTypesIdsEnum.RSA ? <ProgramPurchasePriceField /> : null}
          </ProgramHelper>

          {values.programTypeId === InstrumentTypesIdsEnum.OPTION && (
            <ProgramHelper className="mt-4" colInputSize={8} questionId={"optionsExpiration"}>
              <ProgramOptionsExpirationDateFields />
            </ProgramHelper>
          )}
          {values.programTypeId === InstrumentTypesIdsEnum.OPTION ? (
            <Row className="mt-3">
              <Col>
                <Helper.Question questionId={f.vestingRelativeExpiry}>
                  <Check
                    name="vestingRelativeExpiry"
                    label="The expiry date is relative to vesting date"
                    checked={values.vestingRelativeExpiry}
                    onChange={handleChange}
                  />
                </Helper.Question>
                <Helper.Answer
                  answerId={f.vestingRelativeExpiry}
                  text="When checked, the expiry date for stock options will be calculated based on stock options exercise period after each vesting date, rather than after the vesting period begins."
                  className="mt-1"
                />
              </Col>
            </Row>
          ) : null}

          <ProgramHelper className="mt-4" colInputSize={16} questionId={"essentialTerms"}>
            <ProgramEssentialTermsField />
          </ProgramHelper>

          <div className="mt-8 d-flex">
            <WizardContent.Controls.BackButton
              className="ms-auto"
              onClick={handleClickBack}
              isDisabled={isSubmitting}
            />
            <WizardContent.Controls.ContinueButton className="ms-2" isLoading={isSubmitting} />
          </div>
        </Helper>
      </Form>
    </WizardContent.Content>
  );
};

export default SharesDetails;
