import { FC, useMemo } from "react";
import { equals, last } from "ramda";

import { Ui } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "createPlan.basics");

const TableHead: FC = () => {
  const rows = useMemo(() => {
    return [t("tableRows.stakeholder"), t("tableRows.status"), t("tableRows.shares")];
  }, []);

  return (
    <thead style={{ height: 48, verticalAlign: "middle" }}>
      <tr>
        {rows.map((el) => (
          <th
            key={el}
            style={{
              borderBottomWidth: 0,
              textAlign: equals(el, last(rows)) ? "end" : undefined,
            }}
          >
            <Ui.s
              style={{
                fontWeight: 400,
                color: scssVariables.foregroundMedium,
              }}
            >
              {el}
            </Ui.s>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
