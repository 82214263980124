import { FC } from "react";
import classNames from "classnames";

import Button, { ButtonProps } from "common/components/atoms/Button/Button";
import { ArrowLeftIcon, ArrowRightIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

type CommonButtonProps = ButtonProps & {
  isLoading?: boolean;
};

const t = createTranslation(TranslationNS.layout, "wizardLayout.controlButtons");

const BackButton: FC<CommonButtonProps> = ({ className, children, ...props }) => (
  <Button isOnlyIcon className={className} variant="secondary" {...props}>
    <ArrowLeftIcon />
  </Button>
);

const ContinueButton: FC<CommonButtonProps> = ({ className, children, ...props }) => (
  <Button type="submit" isOnlyIcon className={className} {...props}>
    <ArrowRightIcon />
  </Button>
);

export type ControlButtonsProps = JSX.IntrinsicElements["div"] & {
  isLoading?: boolean;
  backLabel?: string;
  onBack?: ButtonProps["onClick"];
  continueLabel?: string;
  onContinue?: ButtonProps["onClick"];
};

type ControlButtonsComponent = FC<ControlButtonsProps> & {
  BackButton: typeof BackButton;
  ContinueButton: typeof ContinueButton;
};

const ControlButtons: ControlButtonsComponent = ({
  className,
  backLabel,
  onBack,
  continueLabel,
  onContinue,
  children,
  isLoading,
  ...props
}) => (
  <div className={classNames("d-flex justify-content-center align-items-center mt-6", className)} {...props}>
    {children || (
      <>
        {!!onBack && (
          <BackButton isLoading={isLoading} isDisabled={isLoading} onClick={onBack}>
            {backLabel || t("back")}
          </BackButton>
        )}
        <ContinueButton isLoading={isLoading} isDisabled={isLoading} onClick={onContinue}>
          {continueLabel || t("continue")}
        </ContinueButton>
      </>
    )}
  </div>
);

ControlButtons.BackButton = BackButton;
ControlButtons.ContinueButton = ContinueButton;

export default ControlButtons;
