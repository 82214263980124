import { FC } from "react";
import { default as BTSpinner, SpinnerProps } from "react-bootstrap/Spinner";

const Spinner: FC<Omit<SpinnerProps, "animation">> = ({ className, style, ...props }) => {
  return (
    <div
      data-testid="spinner-container-test-id"
      className={className ? className : "position-fixed top-50 start-50"}
      style={
        style
          ? style
          : {
              transform: "translate(-50%, -50%)",
            }
      }
    >
      <BTSpinner animation="border" {...props} />
    </div>
  );
};

export default Spinner;
