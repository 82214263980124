import { FC } from "react";
import cn from "classnames";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { Ui } from "common/components/atoms/Typography";
import { PlusIcon } from "common/icons/svg";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ProgramContainer.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

type OneOffPlansHeaderProps = {
  data: OwnershipProgramTableData[];
  handleAddPlan?: () => void;
};
const OneOffPlansHeader: FC<OneOffPlansHeaderProps> = (props) => {
  const { data, handleAddPlan } = props;
  const { hasFullAccess } = useFeatures(FEATURES.managePlans);

  return (
    <div className={cn("d-flex justify-content-between align-items-center", classes["program-header"])}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <Ui.xl bold>{t("oneOffPlans.title")}</Ui.xl>
          <div className={classes["rounded-container"]}>
            <Ui.xs>{R.defaultTo(0, R.length(data))}</Ui.xs>
          </div>
        </div>

        <Ui.xs
          style={{
            color: scssVariables.foregroundMedium,
          }}
        >
          {t("oneOffPlans.description")}
        </Ui.xs>
      </div>

      <div className="d-flex">
        <div className="d-flex flex-column">
          <ExpansionPanel.ToggleIgnore>
            <Button
              size="s"
              isOnlyIcon
              variant="primary"
              data-testid="event-button"
              onClick={handleAddPlan}
              isDisabled={!hasFullAccess}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              className={classes["add-btn"]}
            >
              <PlusIcon />
            </Button>
          </ExpansionPanel.ToggleIgnore>
        </div>
      </div>
    </div>
  );
};

export default OneOffPlansHeader;
