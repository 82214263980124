import { FC, ReactNode } from "react";
import { InputGroup, InputGroupProps } from "react-bootstrap";

import InputFeedback, { InputFeedbackProps } from "../input-blocks/input-feedback/input-feedback";
import InputHeader, { InputHeaderProps } from "../input-blocks/input-header/input-header";
import Check from "./Check";

export type CheckboxGroupProps = Pick<InputGroupProps, "className"> &
  InputHeaderProps &
  InputFeedbackProps & { children?: ReactNode };

type CheckboxGroupComponent = FC<CheckboxGroupProps> & {
  Check: typeof Check;
};

const ChecksGroup: CheckboxGroupComponent = ({
  label,
  isDisabled,
  isOptional,
  isTouched,
  meta,
  error,
  info,
  children,
  className,
}) => {
  return (
    <InputGroup className={`d-flex flex-column ${className}`}>
      <InputHeader className="mb-1" label={label} isDisabled={isDisabled} isOptional={isOptional} meta={meta} />

      {children}

      <InputFeedback className="ms-1" isDisabled={isDisabled} isTouched={isTouched} error={error} info={info} />
    </InputGroup>
  );
};

ChecksGroup.Check = Check;

export default ChecksGroup;
