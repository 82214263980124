import { FC, useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";

import InfoCard from "common/shareClass/components/InfoCard/ShareClassInfoCard";
import ShareClassService, {
  ShareClassServiceGetResponse,
} from "common/shareClass/ShareClassService";

import classes from "./ShareClassField.module.scss";

export type ShareClassDetailsProps = {
  shareClassId: ShareClassServiceGetResponse["id"];
};

const ShareClassDetails: FC<ShareClassDetailsProps> = ({ shareClassId }) => {
  const [shareClass, setShareClass] = useState<ShareClassServiceGetResponse>();

  useEffect(() => {
    ShareClassService.get(shareClassId).then(({ data }) => {
      setShareClass(data);
    });
  }, [shareClassId]);

  if (!shareClass) {
    return (
      <div className="d-flex justify-content-center align-items-center p-5">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <InfoCard
      className={classes["info-card"]}
      showStatistics
      shareClass={shareClass}
    />
  );
};

export default ShareClassDetails;
