import axios from "axios";
import { action, thunk } from "easy-peasy";

import { AccountModel } from "./modelTypes";

export const account: AccountModel = {
  // selectors
  user: null,
  profile: null,
  investorProfile: null,
  professionalProfile: null,
  //actions
  setUserName: action((state, payload) => {
    if (state.user) {
      state.user.firstName = payload;
    }
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  setUserProfileImage: action((state, payload) => {
    if (state.user) {
      state.user.profileImage = payload;
    }
  }),
  setProfile: action((state, payload) => {
    state.profile = payload;
  }),
  setInvestorProfile: action((state, payload) => {
    state.investorProfile = payload;
  }),
  setProfessionalProfile: action((state, payload) => {
    state.professionalProfile = payload;
  }),
  // thunks
  getAccountThunk: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    try {
      const companyId = getStoreState().activeCompanyModel.companyId;
      const request = await axios.get("/api/users/current");
      if (request.status === 200) {
        actions.setUser(request.data);
        if (!companyId && request.data.companies.length > 0) {
          getStoreActions().activeCompanyModel.setCompanyId(request.data.companies[0].id);
        }

        if (payload?.withAdditionalDataLoading || request.data?.isOnboarded) {
          getStoreActions().common.getDropdownsThunk();
        }

        if (request.data.profileImageFilePath) {
          actions.setUserProfileImage(process.env.REACT_APP_BLOB_PUBLIC_URL + request.data.profileImageFilePath);
        }
      }
    } catch (e) {
      // handleLogout(authProvider);
    }
  }),
  getUserProfileThunk: thunk(async (actions) => {
    try {
      const request = await axios.get("/api/users/user-profile");
      if (request.status === 200) {
        actions.setProfile(request.data);
      }
    } catch (e) {
      // TODO: process errors
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getInvestorProfileThunk: thunk(async (actions) => {
    try {
      const request = await axios.get("/api/users/investor-profile");
      if (request.status === 200) {
        actions.setInvestorProfile(request.data);
      }
      if (request.status === 204) {
        actions.setInvestorProfile({
          shortBio: "",
          businessSkills: [],
          industries: [],
          verticals: [],
          companiesInvestedIn: [],
          investmentEntites: [],
        });
      }
    } catch (e) {
      // TODO: process errors
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getUserProfessionalProfileThunk: thunk(async (actions) => {
    try {
      const request = await axios.get("/api/users/professional-profile");
      if (request.status === 200) {
        actions.setProfessionalProfile(request.data);
      }
      if (request.status === 204) {
        actions.setProfessionalProfile({
          id: null,
          shortBio: "",
          businessSkills: [],
          industries: [],
          verticals: [],
          educationLevelId: 0,
          yearsOfExperience: 0,
          additionalTrainingInformation: "",
          degreeTitleAndSchool: "",
          teamMemberships: [],
        });
      }
    } catch (e) {
      // TODO: process errors
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
};
