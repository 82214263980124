import { useMemo } from "react";

import { useStoreState } from "../../store/store";
import { ACCESS_LEVELS, AccessLevels, CompanyFeatures, FEATURES, FeaturesAccessLevel, FeaturesIds } from "./types";

export const useFeatures = (featureToCheck?: FeaturesIds, subscriptionCheck?: CompanyFeatures[]) => {
  const { user } = useStoreState((state) => state.account);
  const currentCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);

  const currentCompany = useMemo(() => {
    return user?.companies.find((company) => company.id === currentCompanyId);
  }, [user?.companies, currentCompanyId]);

  return useMemo(() => {
    return {
      hasViewAccess: featureToCheck ? (currentCompany?.features?.[featureToCheck] || 0) >= ACCESS_LEVELS.VIEW : false,
      hasFullAccess: featureToCheck
        ? user?.isSysAdmin || (currentCompany?.features?.[featureToCheck] || 0) >= ACCESS_LEVELS.FULL
        : false,
      hasSysAdminAccess: user?.isSysAdmin,
      hasSubscriptionAccess:
        currentCompany?.accessibleFeatures?.some(
          (feature) => subscriptionCheck?.includes(feature.id) && feature.hasAccess
        ) || false,
    };
  }, [
    currentCompany?.accessibleFeatures,
    currentCompany?.features,
    featureToCheck,
    subscriptionCheck,
    user?.isSysAdmin,
  ]);
};

// TESTS
const populateFeatures = (accessLevel: AccessLevels): FeaturesAccessLevel => {
  const features: any = {};

  for (let i = 1; i <= Object.keys(FEATURES).length; i++) {
    features[i] = accessLevel;
  }

  return features;
};

export const mockFeaturesForUser = {
  noAccess: populateFeatures(0),
  view: populateFeatures(1),
  full: populateFeatures(2),
};
