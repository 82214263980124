import { FC, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { defaultTo } from "ramda";

import { Dropdown, Helper } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData, fields } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.sharesDetails");

type ProgramPoolFieldProps = {
  companyId?: string;
  isDisabled?: boolean;
};
const ProgramPoolField: FC<ProgramPoolFieldProps> = ({ companyId, isDisabled = false }) => {
  const { values, handleChange, handleBlur, touched, errors, setFieldValue } =
    useFormikContext<CreateProgramFormData>();

  const pools = useStoreState((state) => state.poolModel.companyPools);
  const { isGetPoolsLoading } = useStoreState((state) => state.poolModel);

  const getCompanyPoolsThunk = useStoreActions((actions) => actions.poolModel.getCompanyPoolsThunk);

  useEffect(() => {
    if (companyId) {
      getCompanyPoolsThunk(+companyId).then();
    }
  }, [companyId, getCompanyPoolsThunk]);

  const poolDropdownItems = useMemo(
    () =>
      pools.map((pool) => ({
        id: pool.id,
        name: t("poolId.optionLabel", { pool }),
      })),
    [pools]
  );

  useEffect(() => {
    if (values.poolId) {
      const pool = pools.find(({ id }) => id === values.poolId);

      const programNumberOfShares = isDisabled ? values?.programNumberOfShares || 0 : 0;
      const numberOfSharesLeft =
        pool?.numberOfSharesLeft !== undefined ? pool?.numberOfSharesLeft + programNumberOfShares : 0;

      setFieldValue(fields.numberOfSharesLeft, numberOfSharesLeft).then();
    }
  }, [isDisabled, pools, setFieldValue, values.poolId, values?.programNumberOfShares]);

  return (
    <Helper.Question questionId="poolId" type="input" answer={t("poolId.answer")}>
      <Dropdown
        isLoading={isGetPoolsLoading}
        isDisabled={isGetPoolsLoading || isDisabled}
        label={t("poolId.label")}
        name={fields.poolId}
        optionsIsObject
        options={poolDropdownItems}
        onBlur={handleBlur}
        onChange={handleChange}
        selectedValue={defaultTo(0, values.poolId)}
        error={errors.poolId}
        isTouched={touched.poolId}
      />
    </Helper.Question>
  );
};
export default ProgramPoolField;
