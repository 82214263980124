import { FC, useMemo } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";

import { CompanyRouterItem } from "../EquityManagementRouter";
import CapTableRouter from "./cap-table/CapTableRouter";
import CompanyDocumentsPage from "./documents/documents";
import IssueEquity from "./financial-instruments/IssueEquity";
import ShareClasses from "./share-classes/ShareClasses";
import StakeholdersManagement from "./stakeholders-management/stakeholders-management";
import Transactions from "./transactions/transactions";
import CompanyValuationPage from "./valuation/Valuation";

const OwnershipRouter: FC = () => {
  const companyRouters = useMemo<CompanyRouterItem[]>(
    () => [
      {
        path: ROUTER_V2.equityManagement.ownership.valuation,
        component: <CompanyValuationPage />,
      },
      {
        path: ROUTER_V2.equityManagement.ownership.capTable.relativeFull,
        component: <CapTableRouter />,
      },
      {
        path: ROUTER_V2.equityManagement.ownership.stakeholdersManagement,
        component: <StakeholdersManagement />,
      },
      {
        path: ROUTER_V2.equityManagement.ownership.shareClasses,
        component: <ShareClasses />,
      },
      {
        path: ROUTER_V2.equityManagement.ownership.documents,
        component: <CompanyDocumentsPage />,
      },
      {
        path: ROUTER_V2.equityManagement.ownership.financialInstruments,
        component: <IssueEquity />,
      },
      {
        path: ROUTER_V2.equityManagement.ownership.transactions,
        component: <Transactions />,
      },
    ],
    []
  );

  return (
    <Routes>
      {companyRouters.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Routes>
  );
};

export default OwnershipRouter;
