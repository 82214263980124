import { useEffect, useRef, useState } from "react";
import cn from "classnames";

import { H, LoaderContainer, LoaderContent, P, Tag } from "common/components/atoms";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext from "../MyPortfolioContext";
import Filter from "./components/Filter/Filter";
import Convertibles from "./Convertibles/Convertibles";
import InfoData from "./InfoData/InfoData";
import TransactionsPreview from "./Ownership/components/transactions-preview/transactions-preview";
import Ownership from "./Ownership/Ownership";
import classes from "./Portfolio.module.scss";
import StockOptions from "./StockOptions/StockOptions";
import CompanyInvitationModal from "./SuggestedCompanies/CompanyInvitationModal/CompanyInvitationModal";
import SuggestedCompanies from "./SuggestedCompanies/SuggestedCompanies";
import Warrants from "./Warrants/Warrants";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview");

const Portfolio = () => {
  useDocumentTitleUpdate(t("title"));

  const tempRef = useRef(false);
  const { getPortfolioThunk } = MyPortfolioContext.useStoreActions((actions) => actions);
  const isPortfolioLoading = MyPortfolioContext.useStoreState((state) => state.isPortfolioLoading);
  const [isContentLoading, setIsContentLoading] = useState(false);

  useEffect(() => {
    if (!tempRef.current) {
      tempRef.current = true;
      setIsContentLoading(true);
      getPortfolioThunk(null)
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsContentLoading(false);
        });
    }
  }, [getPortfolioThunk]);

  return (
    <div className={classes.wrap}>
      <LoaderContainer loading={isContentLoading}>
        <div className={cn("d-flex align-items-center justify-content-between", classes.header)}>
          <div className="d-flex align-items-center">
            <H.l>{t("title")}</H.l>
            <div className="ms-2">
              <Tag className={classes.newTag}>Beta</Tag>
            </div>
          </div>
          <Filter />
        </div>
        <div className={classes.wrapper}>
          <LoaderContent show={isPortfolioLoading}>
            <div className={classes.container}>
              <InfoData />
              <Ownership />
              <SuggestedCompanies />
              <StockOptions />
              <Convertibles />
              <Warrants />
              <P.s className={classes.mailTo}>
                Investment portfolio is currently in beta. We would love to hear what you think! Help us improve by
                sharing your thoughts and suggestions through <a href="mailto:hello@unlisted.ai">hello@unlisted.ai</a>.
              </P.s>
            </div>
            <CompanyInvitationModal />
          </LoaderContent>
          {/*<div className={classes.sideBar}>
            <div className={classes.welcome}>
              <Ui.m style={{ fontWeight: 500 }}> {t("welcome.title")}</Ui.m>
              <P.s className="mt-2">{t("welcome.description_1")}</P.s>
              <P.s className="mt-3">{t("welcome.description_2")}</P.s>
              <P.s className="mt-3">{t("welcome.description_3")}</P.s>
              <Link to={getPath(["onboard", "company", "getStarted"])}>
                <Button as="span" className="mt-2" size="s" variant="secondary">
                  {t("welcome.addCompany")}
                </Button>
              </Link>
            </div>
          </div>*/}
        </div>
      </LoaderContainer>
      <TransactionsPreview />
    </div>
  );
};

export default Portfolio;
