import { FC, useCallback, useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import classNames from "classnames";

import { CheckIcon, DeleteIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import Button from "../Button/Button";
import { Ui } from "../Typography";
import classes from "./Signature.module.scss";

const SignatureCanvasUpdated = SignatureCanvas as unknown as any;

const t = createTranslation(TranslationNS.common, "components.signature");

type SignatureProps = {
  signature?: string;
  onSubmit: (e: File | null) => void;
};

const Signature: FC<SignatureProps> = ({ signature, onSubmit }) => {
  const signatureRef = useRef<SignatureCanvas>(null);

  const [isSignatureExist, setIsSignatureExist] = useState<boolean>(false);

  const signatureClearHandler = useCallback(() => {
    if (signatureRef.current) {
      signatureRef.current.clear();
      setIsSignatureExist(false);
      onSubmit(null);
    }
  }, [signatureRef, onSubmit]);

  const onSubmitHandler = useCallback(async () => {
    if (signatureRef.current?.isEmpty()) {
      notify("You can't submit empty signature field", true, "error", 3000);

      return;
    }

    const res: Response = await fetch(signatureRef.current?.toDataURL() as string);
    const blob: Blob = await res.blob();

    onSubmit(
      new File([blob], "unlisted_ownership_plan_e_signature", {
        type: "image/png",
      })
    );
    setIsSignatureExist(true);
  }, [signatureRef, onSubmit]);

  useEffect(() => {
    if (signature && signatureRef.current) {
      signatureRef.current.fromDataURL(signature);
    }
  }, [signature]);

  return (
    <div data-testid="signature-component-test-id">
      <Ui.m className="mb-1"> {t("title")}</Ui.m>
      <div className="d-flex">
        <div
          className={classNames(classes["signature"], {
            [classes["valid"]]: isSignatureExist,
          })}
        >
          <SignatureCanvasUpdated
            penColor="black"
            ref={signatureRef}
            clearOnResize={false}
            velocityFilterWeight={0.8}
            canvasProps={{
              className: classes["canvas"],
            }}
          />
        </div>

        <div className={classNames("ms-2", classes["actions"])}>
          <div className={classes["remove"]} data-testid="clear-signature-test-id" onClick={signatureClearHandler}>
            <DeleteIcon height={24} width={24} />
          </div>

          {isSignatureExist ? null : (
            <Button
              size="s"
              className={classes["done-btn"]}
              data-testid="submit-signature-test-id"
              onClick={onSubmitHandler}
            >
              <CheckIcon /> {t("done")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Signature;
