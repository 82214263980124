import * as Yup from "yup";

import { TFunction } from "translation/helpers";

import { SplitFields } from "../../split/form-fields";

export const splitDetailsValidationSchema = (tFunction: TFunction) => ({
  [SplitFields.multiplier]: Yup.number()
    .min(0.000001, tFunction("minNumber", { number: 0.000001 }))
    .required(tFunction("required")),
});
