import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.seniorityLevelField");

type SeniorityLevelFieldProps = {
  className?: string;
};

const SeniorityLevelField: FC<SeniorityLevelFieldProps> = ({ className }) => {
  const { touched, errors, values, handleChange, handleBlur } =
    useFormikContext<Pick<ShareClassFormData, "seniorityLevel">>();

  const options = useMemo(
    () =>
      Array(5)
        .fill(null)
        .map((_, index) => ({
          id: index + 1,
          name: String(index + 1),
        })),
    []
  );

  return (
    <Dropdown
      className={className}
      label={t("label")}
      optionsIsObject
      selectedValue={values.seniorityLevel || 1}
      onChange={handleChange}
      name="seniorityLevel"
      onBlur={handleBlur}
      options={options}
      error={errors.seniorityLevel}
      isTouched={touched.seniorityLevel}
    />
  );
};

export default SeniorityLevelField;
