import { FC, useCallback } from "react";
import { useFormikContext } from "formik";
import { defaultTo, isNil } from "ramda";

import { Check, H } from "common/components/atoms";
import StakeholderSection from "common/components/molecules/StakeholderSection/StakeholderSection";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";
import classes from "./PlanReciver.module.scss";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.details");

const PlanReceiver: FC = () => {
  const { values, handleChange, handleSubmit } = useFormikContext<OwnershipPlanForm>();

  const isFormDisabled =
    !isNil(values.subscriptionAvailable) && defaultTo(0, values.subscriptionAvailable.remainingAgreements) <= 0;

  const submitHandler = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <WizardContent.Content step={1}>
      <H.xs className="mb-4">{t("title")}</H.xs>

      <StakeholderSection isAgreementsFlow />

      <Check
        className={`${classes.checkbox} my-3`}
        label={t("existingPlan")}
        checked={values[f.existingPlan]}
        name={f.existingPlan}
        onChange={handleChange}
        disabled={isFormDisabled}
      />

      <div className="d-flex mt-8">
        <WizardContent.Controls.ContinueButton
          className="ms-auto"
          onClick={submitHandler}
          isDisabled={isFormDisabled}
        />
      </div>
    </WizardContent.Content>
  );
};

export default PlanReceiver;
