import { FC } from "react";
import { useFormikContext } from "formik";

import Dropdown, { DropdownPropsNew } from "common/components/atoms/Dropdown/Dropdown";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { fields as f, StakeholderFormValues } from "../Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.sharedDetails.personal");

type StakeholderFirstNameFieldProps = Omit<DropdownPropsNew, "onChange" | "options"> & {};
const StakeholderRelationField: FC<StakeholderFirstNameFieldProps> = (props) => {
  const relationshipList = useStoreState((state) => state.common.dropdowns)?.relationshipTypes;

  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<StakeholderFormValues>();

  return (
    <Dropdown
      {...props}
      isSearchable
      label={t("relation")}
      placeholder={t("notSelected")}
      options={relationshipList || []}
      optionsIsObject
      selectedValue={values.relationshipTypeId}
      error={errors.relationshipTypeId}
      isTouched={touched.relationshipTypeId}
      name={f.relationshipTypeId}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default StakeholderRelationField;
