import { FC, useEffect, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import ShareClassForm, { ShareClassFormProps } from "common/shareClass/components/ShareClassForm/ShareClassForm";
import ShareClassService from "common/shareClass/ShareClassService";
import { ShareClass, ShareClassFormData, ShareClassTypes } from "common/shareClass/types";
import { scrollToHeaderTop } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Form.module.scss";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassFormOffcanvas");
const tValidation = createTranslation(TranslationNS.common, "notify");

const PANEL_HEADER_ID = "HEADER_ID";

const LiquidationPreferences: FC = () => {
  const { values } = useFormikContext<Pick<ShareClassFormData, "shareClassTypeId">>();

  if (values.shareClassTypeId !== ShareClassTypes.preferred) {
    return null;
  }

  return (
    <>
      <div className={`mt-7 mb-5 ${classes["liquidation-preferences-divider"]}`}>
        <hr className="m-0" />
        <span className="ui-xs px-3 py-1">{t("preferredTypeDivider")}</span>
      </div>

      <div className="head-xxxs mb-5">{t("liquidationPreferences")}</div>

      <ShareClassForm.SeniorityLevelField className="mb-5" />

      <ShareClassForm.SeniorityLevelMultipleField className="mb-5" />

      <Row>
        <Col className="mb-5">
          <ShareClassForm.IsParticipatingField />
        </Col>

        <Col className="mb-5">
          <ShareClassForm.ParticipatingCapField />
        </Col>
      </Row>

      {/*<ShareClassForm.ShareClassAntiDilutionField />*/}
    </>
  );
};

type ContentProps = {
  editing?: boolean;
  onCancel?(): void;
};

const Content: FC<ContentProps> = ({ editing, onCancel }) => {
  const { submitCount, isValid } = useFormikContext();

  useEffect(() => {
    if (submitCount > 0 && !isValid) {
      notify(tValidation("formValidationError"), true, "error", 3000);
      scrollToHeaderTop(PANEL_HEADER_ID);
    }
  }, [submitCount, isValid]);

  return (
    <SlidePanel.Section title="Share class details" id={PANEL_HEADER_ID} isSingle>
      <ShareClassForm.Form>
        <ShareClassForm.NameField className="mb-4" />

        <ShareClassForm.PurposeField className="mb-4" />

        <ShareClassForm.ShareClassTypeField className="mb-4" />

        <ShareClassForm.HasVotingRightsField className="mb-4" />

        <Row>
          <Col>
            <ShareClassForm.VotingRightsMultipleField />
          </Col>

          <Col />
        </Row>

        <ShareClassForm.HasDividendsField className="mt-11" />

        <LiquidationPreferences />

        <SlidePanel.Actions>
          <Button className="me-3" type="submit">
            {t(editing ? "saveChanges" : "create")}
          </Button>

          <Button variant="secondary" onClick={onCancel}>
            {t("cancel")}
          </Button>
        </SlidePanel.Actions>
      </ShareClassForm.Form>
    </SlidePanel.Section>
  );
};

export type FormProps = Pick<ShareClassFormProps, "onSubmit"> &
  Omit<ContentProps, "editing"> & {
    shareClass?: any;
    onSave?(shareClass: ShareClass): void;
  };

const Form: FC<FormProps> = ({ shareClass, onSubmit, onCancel }) => {
  const initialValues = useMemo<any>(
    () => (shareClass ? ShareClassService.responseToUpdateData(shareClass) : {}),
    [shareClass]
  );

  return (
    <ShareClassForm initialValues={{ ...initialValues, companyId: shareClass.companyId }} onSubmit={onSubmit}>
      <Content editing={!!shareClass} onCancel={onCancel} />
    </ShareClassForm>
  );
};

export default Form;
