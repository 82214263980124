import { useCallback } from "react";
import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData, fields } from "../useProgramForm";
const t = createTranslation(TranslationNS.common, "program.components.programForm.approvalDate");
const tS = createTranslation(TranslationNS.pages, "createProgram.summary");

const ProgramApprovalDateField = () => {
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext<CreateProgramFormData>();

  const handleChange = useCallback(
    (date: Date | string, name?: string): void => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  return (
    <Helper.Question questionId={"approvalDate"} type="input" answer={tS("approvalDate.answer")}>
      <DatePicker
        label={t("exerciseDate")}
        date={values.approvalDate}
        name={fields.approvalDate}
        isDateOnlyString={true}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.approvalDate}
        isTouched={touched.approvalDate}
        className="me-1"
      />
    </Helper.Question>
  );
};

export default ProgramApprovalDateField;
