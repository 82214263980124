import { FC, memo, useEffect, useMemo } from "react";

import { Spinner } from "common/components/atoms";
import { AgreementUIType, InstrumentTypesNamesEnum } from "common/enums/enum";

import AgreementSlideContainer from "../components/common/slide-container/AgreementSlideContainer";
import RSAAgreementTemplate from "../components/rsa/rsa-template/RSATemplate";
import RSAAgreementContext from "../contexts/RSAAgreementContext";

/**
 * Description: restricted stock awards agreement layout component
 * Usage: for all cases static and slide panel usage for all the places for rsa information
 *
 * Props:
 * @param { type } string Type of UI we need to show, default or slide-panel, depends on this prop
 * the template for displaying will be changed with setting own styles for slide-panel case.
 *
 * Usage: to use agreement type - we need to wrap page or module that should use this panel withAgreements HOC
 * and provide the necessary property from this list
 *
 * withAgreements(Module, {
 *  RSAAgreement: true
 * })
 *
 * to have the possibility to retrieve details data into this organism
 */

type Props = {
  type: AgreementUIType;
};

const RSAAgreement: FC<Props> = ({ type = AgreementUIType.regular }) => {
  const { isLoading } = RSAAgreementContext.useStoreState((state) => state);
  const { setUIType } = RSAAgreementContext.useStoreActions((actions) => actions);

  // Component that will be displayed depends on type of SO agreement
  const ComponentToRender = useMemo(() => (type === AgreementUIType.regular ? RegularTemplate : SlideTemplate), [type]);

  useEffect(() => {
    setUIType(type);
  }, [setUIType, type]);

  if (isLoading) {
    return <Spinner />;
  }

  return <ComponentToRender type={type} />;
};

// Template for regular RSA view (static div container with all info inside)
const RegularTemplate: FC<Props> = () => {
  return (
    <section>
      <RSAAgreementTemplate />
    </section>
  );
};

// Template for slide-panel RSA view
const SlideTemplate: FC<Props> = () => {
  return (
    <AgreementSlideContainer type={InstrumentTypesNamesEnum.RSA}>
      <RSAAgreementTemplate />
    </AgreementSlideContainer>
  );
};

export default memo(RSAAgreement);
