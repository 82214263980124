import { useFormikContext } from "formik";
import * as R from "ramda";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { FormGeneralValues } from "../general";

const translation = createTranslation(TranslationNS.pages, "companyProfile.general");

const ContactPersonPart = () => {
  const { contactPersonDropDown } = useStoreState((state) => state.company);

  const { values, errors, touched, setFieldValue } = useFormikContext<FormGeneralValues>();
  return (
    <div>
      <div className="d-flex flex-column mb-5">
        <Dropdown
          className="mb-1"
          label={translation("contactPerson")}
          error={errors.contactPersonTeamMemberId}
          isTouched={touched?.contactPersonTeamMemberId}
          data-testid="dropdown-toggle-contact-person-test-id"
          selectedValue={
            !R.isNil(contactPersonDropDown)
              ? contactPersonDropDown?.find(
                  (el) => el.teamMemberId === R.defaultTo(0, values.contactPersonTeamMemberId)
                )?.name
              : ""
          }
          options={
            !R.isNil(contactPersonDropDown) && contactPersonDropDown.length
              ? contactPersonDropDown?.filter((el) => el.name !== "null null")?.map((el) => el.name)
              : []
          }
          onChange={(name: string) => {
            if (!R.isNil(contactPersonDropDown)) {
              const selectedPerson = contactPersonDropDown?.find((el) => el.name == name);

              setFieldValue("contactPersonTeamMemberId", selectedPerson?.teamMemberId);
            }
          }}
        />

        <p className="m-0 ui-xs" style={{ opacity: 0.8 }}>
          {translation("addContactPersonLater")}
        </p>
      </div>
      <p className="m-0 mb-3 ui-xs" style={{ opacity: 0.8 }}>
        {translation("requiredFields")}
      </p>
    </div>
  );
};

export default ContactPersonPart;
