import { FC } from "react";

import Table from "common/components/atoms/Table/Table";

import { AccessControlUserDTO } from "../types";
import TableBody from "./body";
import TableHead from "./header";
import classes from "./table-view.module.scss";

type PropsTypes = {
  users: AccessControlUserDTO[];
  handleOpenEditAccessSidebar: (id: number) => void;
};

const TableView: FC<PropsTypes> = ({ users, handleOpenEditAccessSidebar }) => {
  return (
    <Table
      className={classes["table"]}
      headComponent={<TableHead />}
      bodyComponent={<TableBody users={users} handleOpenEditAccessSidebar={handleOpenEditAccessSidebar} />}
    />
  );
};

export default TableView;
