import { FC, useCallback, useMemo } from "react";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import { object, string } from "yup";

import { Button, ModalInfo, TextField } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.resendEmailInvite");
const tv = createTranslation(TranslationNS.validation);

type Props = {
  sentCallback?: () => void;
};
const ResendEmailInviteModal: FC<Props> = (props) => {
  const { show, firstName, lastName } = useStoreState(
    (state) => state.stakeholderInvitation.resendEmailInvitationModal
  );
  return (
    <ModalInfo size="sm" show={show} header={t("title", { name: `${firstName} ${lastName}` })}>
      <ResendEmailInviteForm {...props} />
    </ModalInfo>
  );
};

type PledgeFormProps = Props;
type ResendFormValues = {
  stakeholderId?: number;
  email?: string;
  firstName?: string;
  lastName?: string;
};
const ResendEmailInviteForm: FC<PledgeFormProps> = ({ sentCallback }) => {
  const { resendInviteStakeholderThunk, manageResendEmailInviteModal } = useStoreActions(
    (actions) => actions.stakeholderInvitation
  );

  const { email, stakeholderId } = useStoreState((state) => state.stakeholderInvitation.resendEmailInvitationModal);

  const handleClose = useCallback(() => {
    manageResendEmailInviteModal({ show: false });
  }, [manageResendEmailInviteModal]);

  const onSubmit = useCallback(
    async (values: ResendFormValues) => {
      try {
        await resendInviteStakeholderThunk(values);
        handleClose?.();
        sentCallback?.();
      } catch (e) {
        console.log(e);
      }
    },
    [handleClose, resendInviteStakeholderThunk, sentCallback]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        email: string().required(tv("required")).email(tv("invalidEmail")),
      }),
    []
  );

  const { values, handleChange, handleBlur, touched, errors, handleSubmit, isSubmitting } = useFormik<ResendFormValues>(
    {
      initialValues: {
        stakeholderId,
        email,
      },
      validationSchema,
      onSubmit,
      enableReinitialize: true,
    }
  );

  return (
    <div>
      <TextField
        name="email"
        label={t("label")}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.email}
        isTouched={touched.email}
        value={values.email}
      />

      <Modal.Footer>
        <Button
          variant="primary"
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={() => {
            handleSubmit();
          }}
        >
          {t("resendInvite")}
        </Button>
        <Button variant="secondary" isDisabled={isSubmitting} onClick={handleClose}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default ResendEmailInviteModal;
