import { useFormikContext } from "formik";

import TextField from "common/components/atoms/TextField/TextField";
import { createTranslation, TranslationNS } from "translation";

import { FormGeneralValues } from "../general";

const translation = createTranslation(TranslationNS.pages, "companyProfile.general");

const SocialMediaPart = () => {
  const { values, errors, touched, handleChange } = useFormikContext<FormGeneralValues>();
  return (
    <div>
      <h4 className="head-xxs mb-3">{translation("socialLinks")}</h4>

      <TextField
        isOptional
        data-testid="text-input-companyWebsite"
        className="mb-5"
        type="companyWebsite"
        value={String(values.websiteUrl)}
        label={translation("websiteUrl.label")}
        error={errors.websiteUrl}
        isTouched={touched.websiteUrl}
        onChange={handleChange("websiteUrl")}
      />

      <TextField
        isOptional
        className="mb-5"
        type="calendly"
        value={String(values.calendlyLink)}
        label={translation("calendlyLink")}
        error={errors.calendlyLink}
        isTouched={touched.calendlyLink}
        onChange={handleChange("calendlyLink")}
      />

      <TextField
        isOptional
        className="mb-5"
        type="facebook"
        value={String(values.facebookLink)}
        label={translation("facebookLink")}
        error={errors.facebookLink}
        isTouched={touched.facebookLink}
        onChange={handleChange("facebookLink")}
      />

      <TextField
        isOptional
        className="mb-5"
        type="linkedIn"
        label={translation("linkedInLink")}
        value={String(values.linkedInLink)}
        error={errors.linkedInLink}
        isTouched={touched.linkedInLink}
        onChange={handleChange("linkedInLink")}
      />

      <TextField
        isOptional
        className="mb-5"
        type="twitter"
        label={translation("twitterLink")}
        value={String(values.twitterLink)}
        error={errors.twitterLink}
        isTouched={touched.twitterLink}
        onChange={handleChange("twitterLink")}
      />

      <TextField
        isOptional
        className="mb-5"
        type="medium"
        label={translation("mediumLink")}
        value={String(values.mediumLink)}
        error={errors.mediumLink}
        isTouched={touched.mediumLink}
        onChange={handleChange("mediumLink")}
      />

      <TextField
        isOptional
        className="mb-10"
        type="instagram"
        label={translation("instagramLink")}
        value={String(values.instagramLink)}
        error={errors.instagramLink}
        isTouched={touched.instagramLink}
        onChange={handleChange("instagramLink")}
      />
    </div>
  );
};

export default SocialMediaPart;
