import { FC } from "react";
import classNames from "classnames";

import { NoAccessIcon } from "common/icons/svg";

// import { createTranslation, TranslationNS } from "translation";
import classes from "./page404.module.scss";

// const t = createTranslation(TranslationNS.pages, "company.noAccess");

const Page404: FC = () => {
  return (
    <>
      <div className={classNames(classes["overview-head"], "pb-2")}>
        <div className={classes["no-data-block"]}>
          <div className={classes["block-icon"]}>
            <NoAccessIcon />
          </div>
          <div className={classes["info-block"]}>
            <div className={classes["header"]}>404</div>
            <div className={classes["body"]}>Page not found!</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page404;
