import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CommonFileIcon, ExclamationMarkCircle, FolderIcon, PledgesNotesIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { CapTableIssueShares } from "../../types";
import ActionsCell from "./ActionsCell";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.shareSeriesTable.head");

const useShareSeriesTable = () => {
  const fNumber = useFormatNumbers();

  const capTableActualAccess = useFeatures(FEATURES.capTable);

  return useMemo<ColumnDef<Partial<CapTableIssueShares>>[]>(
    () => [
      {
        accessorKey: "shares",
        header: () => <Ui.xs tag="span">{t("shares")}</Ui.xs>,
        cell: (info) => <span>{fNumber(info.getValue() as number, "amount")}</span>,
        meta: { allowClick: true },
      },
      {
        accessorKey: "firstShareNumber",
        header: () => <Ui.xs tag="span">{t("sharesNumbers")}</Ui.xs>,
        enableSorting: false,
        cell: (info) => (
          <Tag variant="closed">
            {fNumber(info.getValue() as number, "amount")}-{fNumber(info.row.original.lastShareNumber, "amount")}
          </Tag>
        ),
        meta: { allowClick: true },
      },
      {
        accessorKey: "shareClassName",
        header: () => <Ui.xs tag="span">{t("shareClass")}</Ui.xs>,
        cell: (info) => <span>{info.getValue() as string}</span>,
        meta: { allowClick: true },
      },
      {
        accessorKey: "ownership",
        header: () => <Ui.xs tag="span">{t("ownership")}</Ui.xs>,
        cell: (info) => <span>{fNumber(info.getValue() as number, "percent")}</span>,
        meta: { allowClick: true },
      },

      {
        accessorKey: "hasPledges",
        header: () => <Ui.xs tag="span">{t("pledges")}</Ui.xs>,
        enableSorting: false,
        cell: (info) => <span>{info.getValue() ? <ExclamationMarkCircle fontSize={24} /> : "-"}</span>,
        meta: { allowClick: true, bodyClass: "text-center" },
      },
      {
        accessorKey: "pledgeNotes",
        header: () => <Ui.xs tag="span">{t("pledgeNotes")}</Ui.xs>,
        enableSorting: false,
        cell: (info) => <span>{info.getValue() ? <PledgesNotesIcon fontSize={24} /> : "-"}</span>,
        meta: { allowClick: true, bodyClass: "text-center" },
      },
      {
        accessorKey: "description",
        header: () => <Ui.xs tag="span">{t("description")}</Ui.xs>,
        enableSorting: false,
        cell: (info) => <span>{info.getValue() ? <CommonFileIcon fontSize={24} /> : "-"}</span>,
        meta: { allowClick: true, bodyClass: "text-center" },
      },
      {
        accessorKey: "files",
        header: () => <Ui.xs tag="span">{t("documents")}</Ui.xs>,
        enableSorting: false,
        cell: (info) => <span>{(info.getValue() as []).length ? <FolderIcon fontSize={24} /> : "-"}</span>,
        meta: { allowClick: true, bodyClass: "text-center" },
      },
      {
        accessorKey: "issuedSharesId",
        header: "",
        enableSorting: false,
        cell: (info) => (
          <ActionsCell
            hasAccess={capTableActualAccess.hasFullAccess}
            id={info.getValue() as number}
            pledged={info.row.original.hasPledges}
            pledgeNotes={info.row.original.pledgeNotes}
            onSelect={info.table.options.meta?.actionCallback}
          />
        ),
        meta: {
          bodyClass: "text-end",
        },
      },
    ],
    [capTableActualAccess.hasFullAccess, fNumber]
  );
};

export default useShareSeriesTable;
