import { useCallback } from "react";
import { useFormikContext } from "formik";

import FileUploader, { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.editProgram");

const ProgramFileDataField = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext<CreateProgramFormData>();

  const handleDocumentFilesChange = useCallback(
    (filesData: FilesDataMultiple) => {
      setFieldValue("filesData", {
        files: filesData.newFiles,
        oldFiles: filesData.oldFiles,
        oldFileIds: filesData.oldFiles.map((data) => data.fileId),
      });
    },
    [setFieldValue]
  );

  return (
    <FileUploader
      multiple
      label={t("documentsFiled.label")}
      prevFileData={values.filesData?.oldFiles}
      error={errors?.filesData}
      isTouched={touched?.filesData}
      onChange={handleDocumentFilesChange}
      isOptional={Number(values.documentStatusId) !== Number(DocumentStatusEnum.DOCUMENT_UPLOADED)}
    />
  );
};

export default ProgramFileDataField;
