import React, { FC } from "react";
import { equals } from "ramda";

import { Table, Ui } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { CheckIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { CommonOwnershipPlanDetails, VestingTableItem } from "store/types";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "organisms.agreement.vestingTable");

const Head: FC<{ agreementType?: number }> = ({ agreementType }) => {
  const isRSA = equals(agreementType, InstrumentTypesIdsEnum.RSA);
  const isSO = equals(agreementType, InstrumentTypesIdsEnum.OPTION);

  return (
    <thead>
      <tr>
        <th className="text-center">{t("head.vested")}</th>

        <th style={{ width: isRSA ? "70%" : "35%" }}>{t("head.date")}</th>

        {isSO ? <th style={{ width: "35%" }}>{t("head.expiryDate")}</th> : null}

        <th className="text-end">{isRSA ? t("head.shares") : t("head.options")}</th>

        <th className="text-end">{t("head.vestedTotal")}</th>
      </tr>
    </thead>
  );
};

const Body: FC<{ agreementType?: number; data: VestingTableItem[] }> = ({ agreementType, data }) => {
  const isSO = equals(agreementType, InstrumentTypesIdsEnum.OPTION);

  return (
    <tbody>
      {data.length ? (
        data.map((el, index) => {
          return (
            <tr key={index}>
              <td className="text-center">
                {el?.hasVested ? <CheckIcon fontSize={24} color={scssVariables.positive500} /> : null}
              </td>
              <td>
                <Ui.s color={!el.hasVested ? "foregroundLow" : "foregroundHigh"}>
                  {transformDateToCommonDateFormat(el?.vestingDate as string, "MMM dd, yyyy")}
                </Ui.s>
              </td>
              {isSO ? (
                <td>
                  <Ui.s color={!el.hasVested ? "foregroundLow" : "foregroundHigh"}>
                    {transformDateToCommonDateFormat(el?.expiryDate as string, "MMM dd, yyyy")}
                  </Ui.s>
                </td>
              ) : null}

              <td className="text-end">
                <Ui.s color={!el.hasVested ? "foregroundLow" : "foregroundHigh"}>{el?.numberOfShares}</Ui.s>
              </td>

              <td className="text-end">
                <Ui.s color={!el.hasVested ? "foregroundLow" : "foregroundHigh"}>{el?.totalShares}</Ui.s>
              </td>
            </tr>
          );
        })
      ) : (
        <>
          <tr>
            <td style={{ width: "100%", borderBottom: "none" }} colSpan={4}>
              <Ui.s color="foregroundLow">{t("empty")}</Ui.s>
            </td>
          </tr>

          <tr>
            <td style={{ width: "100%", borderBottom: "none" }} colSpan={4}>
              <Ui.m color="foregroundLow" style={{ marginLeft: -16 }}>
                {t("emptyDescription")}
              </Ui.m>
            </td>
          </tr>
        </>
      )}
    </tbody>
  );
};

const VestingTable: FC<{ agreement: CommonOwnershipPlanDetails | null }> = ({ agreement }) => {
  return (
    <Table
      headComponent={<Head agreementType={agreement?.instrumentTypeId} />}
      bodyComponent={<Body data={agreement?.vestingTable || []} agreementType={agreement?.instrumentTypeId} />}
    />
  );
};

export default VestingTable;
