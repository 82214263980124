import { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { SingleShareClassData } from "../../types";
import ShareNumbersCell from "./ShareNumbersCell";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.shareClassesTable.head");

const useShareSeriesTable = () => {
  const fNumber = useFormatNumbers();

  return useMemo<ColumnDef<Partial<SingleShareClassData>>[]>(
    () => [
      {
        accessorKey: "shareClassName",
        header: () => <Ui.xs tag="span">{t("shareClass")}</Ui.xs>,
        cell: (info) => <span>{info.getValue() as string}</span>,
      },
      {
        accessorKey: "shares",
        header: () => <Ui.xs tag="span">{t("shares")}</Ui.xs>,
        cell: (info) => <span>{fNumber(info.getValue() as number, "amount")}</span>,
        meta: { bodyClass: "text-end", headClass: "justify-content-end" },
      },
      {
        accessorKey: "ownership",
        header: () => <Ui.xs tag="span">{t("ownership")}</Ui.xs>,
        cell: (info) => <span>{fNumber(info.getValue() as number, "percent")}</span>,
        meta: { bodyClass: "text-end", headClass: "justify-content-end" },
      },
      {
        accessorKey: "shareNumbers",
        header: () => <Ui.xs tag="span">{t("shareNumbers")}</Ui.xs>,
        cell: (info) => <ShareNumbersCell tags={info.getValue() as SingleShareClassData["shareNumbers"]} />,
        enableSorting: false,
      },
    ],
    [fNumber]
  );
};

export default useShareSeriesTable;
