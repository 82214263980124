import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import classNames from "classnames";
import { useFormikContext } from "formik";
import debounce from "lodash.debounce";
import { defaultTo, isNil } from "ramda";

import {
  Button,
  DatePicker,
  Dropdown,
  Helper,
  NewAvatar,
  SlidePanel,
  TextArea,
  TextField,
} from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DiscountIcon, DollarCircleIcon, GraphStatsIcon, LocallyGrownIcon, PieChartIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../../IssueEquityContext";
import classes from "../ConvertNotePanel.module.scss";
import { ConvertNoteFormValues } from "../useConvertNoteForm";
import InfoCard from "./components/InfoCard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertNote.conversion");

const Conversion: FC = () => {
  const fNumber = useFormatNumbers();
  const shareClasses = useStoreState((state) => state.shareClassModel.shareClassesCompany);
  const conversionReasons = useStoreState((state) => state.common?.dropdowns?.conversionReasons);
  const conversionDetails = IssueEquityContext.useStoreState((state) => state.conversionDetailsCN);
  const calculatedConvertNote = IssueEquityContext.useStoreState((state) => state.calculateCN);
  const convertNoteLoading = IssueEquityContext.useStoreState((state) => state.convertNoteLoading);
  const getCalculateCNThunk = IssueEquityContext.useStoreActions((actions) => actions.getCalculateCNThunk);

  // overriding section
  const noteSharePrice = IssueEquityContext.useStoreState((state) => state.noteSharePrice);
  const setNoteSharePrice = IssueEquityContext.useStoreActions((actions) => actions.setNoteSharePrice);

  const numberOfNoteShares = IssueEquityContext.useStoreState((state) => state.numberOfNoteShares);
  const setNumberOfNoteShares = IssueEquityContext.useStoreActions((actions) => actions.setNumberOfNoteShares);

  const setIsOverrideModalVisible = IssueEquityContext.useStoreActions((actions) => actions.setIsOverrideModalVisible);
  const setIsOverrideSharesPriceModalVisible = IssueEquityContext.useStoreActions(
    (actions) => actions.setIsOverrideSharesPriceModalVisible
  );

  // create this variable to set first request to get share price in the beginning before start edit something
  const [dataRetrieved, setDataRetrieved] = useState<boolean>(false);
  const { formattedCurrency } = useCurrency();

  const { values, errors, touched, handleChange, handleBlur, setFieldValue } =
    useFormikContext<ConvertNoteFormValues>();

  const debounceChange = useMemo(
    () =>
      debounce((values: ConvertNoteFormValues) => {
        getCalculateCNThunk({
          valuationAtConversion: values.valuationAtConversion || 0,
          valuationCap: values?.valuationCap || 0,
          investmentAmount: values?.investmentAmount || 0,
          agreementDate: values?.agreementDate || "",
          noteSettleDate: values?.noteSettleDate || "",
          interestRate: values?.interestRate || 0,
          discount: values?.discount || 0,
          sharesInCompanyBeforeConversion: values?.sharesInCompanyBeforeConversion || 0,
          noteId: values?.noteId,
        })
          .then((res) => {
            setNumberOfNoteShares(res.data.numberOfNewShares);
            setNoteSharePrice(undefined);
          })
          .catch((e) => {
            console.log("error", e);
          });
      }, 1000),
    [getCalculateCNThunk, setNoteSharePrice, setNumberOfNoteShares]
  );

  const handleChangeDate = useCallback(
    (date: Date | string, name?: string) => {
      if (name) {
        setFieldValue(name, date);
        debounceChange({ ...values, [name]: date });
      }
    },
    [setFieldValue, debounceChange, values]
  );

  const handleChangeValuation = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFieldValue(name, value);

      debounceChange({ ...values, [name]: value });
    },
    [debounceChange, setFieldValue, values]
  );

  const shares = "+" + fNumber(numberOfNoteShares, "amount");
  const percentageOfCompany = fNumber(calculatedConvertNote?.percentageOfCompany, "percent");
  const discount = fNumber(calculatedConvertNote?.discount, "percent");
  const investmentAmountWithInterest = fNumber(calculatedConvertNote?.investmentAmountWithInterest, "amount");
  const valuationCap = fNumber(calculatedConvertNote?.valuationCap, "value");

  const openOverrideSharesModal = () => {
    setIsOverrideModalVisible(true);
  };

  const openOverrideSharePriceModal = () => {
    setIsOverrideSharesPriceModalVisible(true);
  };

  const resetSharesHandler = () => {
    setNumberOfNoteShares(defaultTo(0, calculatedConvertNote?.numberOfNewShares));
  };

  const resetSharePriceHandler = () => {
    setNoteSharePrice(undefined);
  };

  useEffect(() => {
    if (!isNil(values.conversionReasonId) && !dataRetrieved) {
      getCalculateCNThunk({
        valuationAtConversion: values.valuationAtConversion || 0,
        valuationCap: values?.valuationCap || 0,
        investmentAmount: values?.investmentAmount || 0,
        agreementDate: values?.agreementDate || "",
        noteSettleDate: values?.noteSettleDate || "",
        interestRate: values?.interestRate || 0,
        discount: values?.discount || 0,
        sharesInCompanyBeforeConversion: values?.sharesInCompanyBeforeConversion || 0,
        noteId: values?.noteId,
      }).then(() => {
        setDataRetrieved(true);
      });
    }
  }, [
    dataRetrieved,
    getCalculateCNThunk,
    values?.agreementDate,
    values.conversionReasonId,
    values?.discount,
    values?.interestRate,
    values?.investmentAmount,
    values?.noteId,
    values?.noteSettleDate,
    values?.sharesInCompanyBeforeConversion,
    values.valuationAtConversion,
    values?.valuationCap,
  ]);

  useEffect(() => {
    if (calculatedConvertNote?.numberOfNewShares) {
      setNumberOfNoteShares(calculatedConvertNote.numberOfNewShares);
    }
  }, [calculatedConvertNote?.numberOfNewShares, setNumberOfNoteShares]);

  return (
    <div className={classes.conversion}>
      <SlidePanel.Section title={t("title")}>
        <Helper>
          <input type="hidden" name="agreementDate" value={values.agreementDate} />
          <Row>
            <Col>
              <Helper.Question questionId={"conversionReasonId"} type="input">
                <Dropdown
                  placeholder={"Select Reason"}
                  options={conversionReasons || []}
                  name={"conversionReasonId"}
                  label={t("conversionReason.label")}
                  selectedValue={values.conversionReasonId}
                  optionsIsObject
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.conversionReasonId}
                  isTouched={touched.conversionReasonId}
                />
              </Helper.Question>
            </Col>
            <Col>
              <Helper.Question questionId={"companyValuation"} type="input">
                <TextField
                  type="number"
                  label={t("companyValuation.label")}
                  name={"valuationAtConversion"}
                  value={values.valuationAtConversion}
                  onChange={handleChangeValuation}
                  onBlur={handleBlur}
                  error={errors.valuationAtConversion}
                  isTouched={touched.valuationAtConversion}
                  iconRight={<span>{convertNoteLoading ? <Spinner size="sm" /> : formattedCurrency}</span>}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Row>
            <Col>
              <Helper.Answer
                className="mt-3"
                answerId={"conversionReasonId"}
                text={t("conversionReason.answer")}
                withRightMargin
              />
              <Helper.Answer
                className="mt-3"
                answerId={"companyValuation"}
                text={t("companyValuation.answer")}
                withRightMargin
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Helper.Question questionId={"noteSettleDate"} type="input">
                <DatePicker
                  isDateOnlyString
                  date={values.noteSettleDate}
                  name={"noteSettleDate"}
                  label={t("noteSettleDate.label")}
                  isTouched={touched.noteSettleDate}
                  error={errors.noteSettleDate}
                  onChange={handleChangeDate}
                  onBlur={handleBlur}
                />
              </Helper.Question>
            </Col>
            <Col>
              <Helper.Question questionId={"shareClassId"} type="input">
                <Dropdown
                  options={shareClasses}
                  name={"shareClassId"}
                  label={t("shareClass.label")}
                  placeholder={t("shareClass.placeholder")}
                  selectedValue={values.shareClassId}
                  optionsIsObject
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.shareClassId}
                  isTouched={touched.shareClassId}
                />
              </Helper.Question>
            </Col>
          </Row>
          <Row>
            <Col>
              <Helper.Answer
                className="mt-3"
                answerId={"noteSettleDate"}
                text={t("noteSettleDate.answer")}
                withRightMargin
              />
              <Helper.Answer withRightMargin className="mt-3" answerId={"shareClassId"} text={t("shareClass.answer")} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Helper.Question questionId={"comment"} type="input">
                <TextArea
                  isOptional
                  label={t("comment.label")}
                  name={"comment"}
                  value={values.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors.comment}
                  isTouched={touched.comment}
                />
              </Helper.Question>
            </Col>
            <Col md={12}>
              <Helper.Answer className="mt-3" answerId={"comment"} text={t("comment.answer")} withRightMargin />
            </Col>
          </Row>
        </Helper>
        <div className={classNames(classes.infoCards, "mt-4")}>
          <div className="w-100 d-flex gap-2">
            <InfoCard
              title={convertNoteLoading ? <Spinner /> : shares}
              subtitle={t("block.shares")}
              icon={
                <NewAvatar
                  size="m"
                  company={!!conversionDetails?.stakeholderCompanyName}
                  firstName={conversionDetails?.firstName}
                  lastName={conversionDetails?.lastName}
                  imageUrl={conversionDetails?.imageUrl}
                  companyName={conversionDetails?.stakeholderCompanyName}
                />
              }
              type="success"
              customElement={
                <div className="d-flex">
                  <Button size="s" variant="secondary" onClick={openOverrideSharesModal}>
                    {t("edit")}
                  </Button>

                  {numberOfNoteShares !== defaultTo(0, calculatedConvertNote?.numberOfNewShares) ? (
                    <Button size="s" className="ms-2" variant="tertiary" onClick={resetSharesHandler}>
                      Reset
                    </Button>
                  ) : null}
                </div>
              }
            />

            <InfoCard
              title={
                convertNoteLoading ? (
                  <Spinner />
                ) : noteSharePrice ? (
                  fNumber(noteSharePrice, "sharePrice")
                ) : (
                  fNumber(
                    Number((defaultTo(0, calculatedConvertNote?.investmentAmount) / numberOfNoteShares).toFixed(2)),
                    "sharePrice"
                  )
                )
              }
              subtitle={t("block.sharePrice")}
              icon={<DollarCircleIcon strokeWidth={0.5} width={40} height={40} />}
              customElement={
                <div>
                  <Button size="s" variant="secondary" onClick={openOverrideSharePriceModal}>
                    {t("edit")}
                  </Button>

                  {!isNil(noteSharePrice) ? (
                    <Button size="s" className="ms-2" variant="tertiary" onClick={resetSharePriceHandler}>
                      Reset
                    </Button>
                  ) : null}
                </div>
              }
            />
          </div>

          {calculatedConvertNote?.useDiscount ? (
            <InfoCard
              title={convertNoteLoading ? <Spinner /> : discount}
              subtitle={t("block.discount")}
              icon={<DiscountIcon />}
            />
          ) : (
            <InfoCard
              title={convertNoteLoading ? <Spinner /> : valuationCap}
              subtitle={t("block.ValuationCap")}
              icon={<GraphStatsIcon />}
            />
          )}

          <InfoCard
            title={convertNoteLoading ? <Spinner /> : percentageOfCompany}
            subtitle={t("block.ofTheCompany")}
            icon={<PieChartIcon />}
          />

          <InfoCard
            title={convertNoteLoading ? <Spinner /> : investmentAmountWithInterest}
            subtitle={t("block.investment")}
            icon={<LocallyGrownIcon />}
          />
        </div>
      </SlidePanel.Section>
    </div>
  );
};

export default Conversion;
