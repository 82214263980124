import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikHelpers } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { useRedirectTo } from "common/hooks/useRedirectTo";
import { useWizardStepper } from "common/layout/WizardLayout/hooks";
import ShareClassService, { ShareClassServiceUpdateData } from "common/shareClass/ShareClassService";
import { getShareClassValidationObject } from "common/shareClass/validationSchema";

import CreateShareClassStore from "../store";
import PreferredPreferencesForm from "./PreferredPreferencesForm";

export type PreferredPreferencesFormData = Pick<
  ShareClassServiceUpdateData,
  | "shareClassTypeId"
  | "seniorityLevel"
  | "seniorityLevelMultiple"
  | "isParticipating"
  | "participatingCap"
  | "shareClassAntiDilutionId"
>;

const PreferredPreferences: FC = () => {
  const redirectTo = useRedirectTo();
  const { shareClassId } = useParams<{ shareClassId: string }>();

  const [isParticipating, setIsParticipating] = useState(false);

  useWizardStepper(3);

  const formDataState = CreateShareClassStore.useStoreState((state) => state.formData);
  const updateFormDataAction = CreateShareClassStore.useStoreActions((actions) => actions.updateFormData);

  const initialValues = useMemo<PreferredPreferencesFormData>(
    () => ({
      shareClassTypeId: formDataState.shareClassTypeId,
      seniorityLevel: formDataState.seniorityLevel || 1,
      seniorityLevelMultiple: formDataState.seniorityLevelMultiple || 1,
      isParticipating: formDataState.isParticipating || isParticipating,
      participatingCap: formDataState.participatingCap || 1,
      shareClassAntiDilutionId: formDataState.shareClassAntiDilutionId,
    }),
    [
      formDataState.shareClassTypeId,
      formDataState.seniorityLevel,
      formDataState.seniorityLevelMultiple,
      formDataState.isParticipating,
      formDataState.participatingCap,
      formDataState.shareClassAntiDilutionId,
      isParticipating,
    ]
  );

  const validationSchema = useMemo(() => {
    const fields: (keyof PreferredPreferencesFormData)[] = ["shareClassTypeId"];

    fields.push("seniorityLevel", "seniorityLevelMultiple");

    if (isParticipating) {
      fields.push("isParticipating", "participatingCap");
    }

    return getShareClassValidationObject().pick(fields);
  }, [isParticipating]);

  const goToShareClassesPage = useCallback((): void => {
    redirectTo(getEMPath(["ownership", "shareClasses"]));
  }, [redirectTo]);

  const submitHandler = useCallback(
    (values: PreferredPreferencesFormData, { setSubmitting }: FormikHelpers<PreferredPreferencesFormData>) => {
      return ShareClassService.update({
        ...formDataState,
        ...values,
        shareClassId: shareClassId ? +shareClassId : 0,
      })
        .then(() => {
          updateFormDataAction(values);
          goToShareClassesPage();
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [formDataState, goToShareClassesPage, shareClassId, updateFormDataAction]
  );

  useEffect(() => {
    if (shareClassId) {
      updateFormDataAction({ shareClassId: +shareClassId });
    }
  }, [shareClassId, updateFormDataAction]);

  return (
    <Formik<PreferredPreferencesFormData>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      <PreferredPreferencesForm setIsParticipating={setIsParticipating} />
    </Formik>
  );
};

export default PreferredPreferences;
