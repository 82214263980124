import { FC, memo } from "react";
import cn from "classnames";
import { differenceInDays, isAfter, isBefore } from "date-fns";

import { InfoAlert, P } from "common/components/atoms";
import { WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

type PropsTypes = {
  expiryDate?: string;
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.poolItem.expiryBanner");

export const getExpirationState = (expiryDate?: string) => {
  const currentDate = new Date();
  const expiryDateObj = expiryDate ? new Date(expiryDate) : null;

  const isPoolExpired = (() => {
    if (expiryDateObj) {
      return isBefore(expiryDateObj, currentDate);
    }

    return false;
  })();

  const isPoolCloseToExpiry = (() => {
    if (expiryDateObj) {
      if (isAfter(expiryDateObj, currentDate) && differenceInDays(expiryDateObj, currentDate) <= 91) {
        return true;
      }
    }

    return false;
  })();

  return {
    isPoolExpired,
    isPoolCloseToExpiry,
  };
};

const ExpiryBanner: FC<PropsTypes> = memo(({ expiryDate, className }) => {
  const { isPoolExpired, isPoolCloseToExpiry } = getExpirationState(expiryDate);

  if (!isPoolExpired && !isPoolCloseToExpiry) {
    return null;
  }

  return (
    <InfoAlert
      type={isPoolExpired ? "Critical" : "Warning"}
      closable
      className={cn("py-1 px-2", className)}
      customContent={
        <div className="d-flex w-100">
          <WarningIcon
            fontSize={24}
            color={isPoolExpired ? scssVariables.critical900 : scssVariables.warning900}
            className="me-3"
          />
          <P.s>
            {isPoolExpired && t("expired", { date: transformDateToCommonDateFormat(expiryDate) })}
            {isPoolCloseToExpiry && t("closeToExpiry", { date: transformDateToCommonDateFormat(expiryDate) })}
          </P.s>
        </div>
      }
    />
  );
});

export default ExpiryBanner;
