import { FC } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import { H } from "common/components/atoms/Typography";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import ProgramHelper from "../../components/ProgramHelper";
import ProgramDescriptionField from "../../programFields/ProgramDescriptionField";
import ProgramNameField from "../../programFields/ProgramNameField";
import { CreateProgramFormData } from "../../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.basics");

const Basics: FC = () => {
  const { handleSubmit, isSubmitting } = useFormikContext<CreateProgramFormData>();

  return (
    <WizardContent.Content step={1}>
      <Form onSubmit={handleSubmit}>
        <H.xs className="text-right mb-5">{t("title")}</H.xs>

        <Helper>
          <ProgramHelper questionId="name">
            <ProgramNameField />
          </ProgramHelper>

          <ProgramHelper colInputSize={12} className="mt-4" questionId="description">
            <ProgramDescriptionField />
          </ProgramHelper>
        </Helper>

        <div className="mt-6 d-flex">
          <WizardContent.Controls.ContinueButton className="ms-auto" isLoading={isSubmitting} />
        </div>
      </Form>
    </WizardContent.Content>
  );
};

export default Basics;
