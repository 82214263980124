import { FC, useCallback, useMemo } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classNames from "classnames";
import { FormikHelpers, FormikValues, useFormik } from "formik";
import * as Yup from "yup";

import { Button, Check, CopyText } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";
import { createTranslation } from "translation";

import { CopyNewIcon } from "../../../icons/svg";
import classes from "./MoneyTransferWidget.module.scss";

const [t, tValidation] = [
  createTranslation("common", "components.moneyTransferWidget"),
  createTranslation("validation"),
];

type TransactionDataElementProps = {
  title: string;
  value: string;
};

const TransactionDataElement: FC<TransactionDataElementProps> = ({ title, value }) => {
  return (
    <div className={classes["transaction-data-element"]}>
      <p className={classNames("m-0 fw-bold", classes.title)} style={{ color: scssVariables.foregroundMedium }}>
        {title}
      </p>
      <div className="d-flex align-items-center mt-1">
        <p className={classNames("ui-s m-0", classes.value)}>{value}</p>
        <CopyText className={classes.copyButton} copyText={value}>
          <CopyNewIcon />
        </CopyText>
      </div>
    </div>
  );
};

type MoneyTransferWidgetProps = {
  name: string;
  title?: string;
  description?: string;
  accountNr: string;
  swiftBic: string;
  message: string;
  amount: string;
  imageUrl?: string;
  className?: string;
  isLoading?: boolean;
  withoutSubmit?: boolean;
  onSubmit?: () => void;
};

const MoneyTransferWidget: FC<MoneyTransferWidgetProps> = ({
  title,
  description,
  name,
  accountNr,
  swiftBic,
  message,
  amount,
  imageUrl,
  onSubmit,
  className,
  isLoading,
  withoutSubmit,
}) => {
  const submitHandler = useCallback(
    (_: FormikValues, { resetForm }: FormikHelpers<any>) => {
      onSubmit?.();
      resetForm();
    },
    [onSubmit]
  );

  const initialValues = useMemo(
    () => ({
      transferredMoneyChecked: false,
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        transferredMoneyChecked: Yup.boolean().required().oneOf([true], tValidation("required")),
      }),
    []
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <Container
      fluid
      className={classNames(className, classes["money-transfer-widget"])}
      data-testid="money-transfer-widget-test-id"
    >
      <Row className="p-0 m-0">
        <Col className="p-0">
          {title && <p className="m-0 ui-l fw-bold mb-3">{title}</p>}

          {description && <p className="mb-3 pe-4">{description}</p>}

          <div className={classNames(classes["transaction-data"], "px-4 py-3 mb-4 d-flex flex-wrap")}>
            <TransactionDataElement value={name} title={t("transactionData.name")} />
            <TransactionDataElement title="Swift/Bic" value={swiftBic} />
            <TransactionDataElement value={accountNr} title={t("transactionData.accountNr")} />
            <TransactionDataElement title={t("transactionData.amount")} value={amount} />
            <TransactionDataElement value={message} title={t("transactionData.includeThisAsMessage")} />
          </div>

          {withoutSubmit ? null : (
            <div className="p-3 pt-0">
              <Check
                label={t("checkboxLabel")}
                checked={values.transferredMoneyChecked}
                data-testid="transferred-money-checkbox-test-id"
                onChange={(e) => {
                  setFieldValue("transferredMoneyChecked", e.target.checked);
                }}
                required={true}
              />
              <Button
                className="mt-1"
                isLoading={isLoading}
                isDisabled={!values.transferredMoneyChecked}
                size="s"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("notifyManager")}
              </Button>
            </div>
          )}
        </Col>

        {imageUrl && (
          <Col xs="auto" className={classNames(classes["image-column"], "p-0")}>
            <img width={199} src={imageUrl} alt="Money transfer image" />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default MoneyTransferWidget;
