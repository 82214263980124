import axios from "axios";
import { Action, action, ActionOn, actionOn, createContextStore, Thunk, thunk } from "easy-peasy";

import { ExerciseRequestStatusEnum } from "common/types/Exxercise.types";
import { getThunkTypes } from "store/storeThunk";

export interface IExercisablePlan {
  estimatedIncome: number;
  exercisePrice: number;
  exercisedOptions: number;
  name: string;
  planId: number;
  sharePrice: number;
  stockOptionPlanTotalShares: number;
  vestedNotExercised: number;
  currencySymbol: string;
  companyName: string;
  profit: boolean;
  profitLossValue: number;
  managerName: string;
  terminated?: boolean;
  expirationDate?: string;
}

export interface IPaymentExercise {
  accountCompanyName: string;
  accountNumber: string;
  swiftBic: string;
  amount: number;
  message: string;
}

export interface IOngoingExercises {
  approvedAt: string;
  comment?: string | null;
  convertPrice: number;
  exerciseRequestId: number;
  programName: string;
  exerciseRequestStatus: ExerciseRequestStatusEnum;
  payment: IPaymentExercise | null;
  issuedAt: string;
  moneyTransferredAt: string;
  numberOfOptions: number;
  requestedAt: string;
  currencySymbol: string;
  companyName: string;
  planId: number;
  managerName?: string;
  amount?: number;
}

interface ExercisingContextModel {
  isModalOpen: boolean;
  exercisingStatus: number | null;
  loading: boolean;
  activeExercisablePlan: IExercisablePlan | null | undefined;
  activeExerciseRequest: IOngoingExercises | null;
  exercisablePlans: IExercisablePlan[];
  ongoingExercises: IOngoingExercises[];
  history: IOngoingExercises[];
  setActiveExercisablePlan: Action<this, number | null>;
  setActiveExerciseRequest: Action<this, IOngoingExercises | null>;
  setIsModalOpen: Action<this, boolean>;
  setLoading: Action<this, boolean>;
  setExercisingStatus: Action<this, number | null>;
  getUserExercisingThunk: Thunk<this, string | undefined>;
  onGetUserExercisingActon: ActionOn<this>;
  addUserExercisingThunk: Thunk<this, { planId: number; numberOfOptions: number }>;
  notifyExerciseFeePaidThunk: Thunk<this, number>;
}

const ExercisingContext = createContextStore<ExercisingContextModel>(
  {
    loading: false,
    exercisablePlans: [],
    ongoingExercises: [],
    history: [],
    activeExercisablePlan: null,
    activeExerciseRequest: null,
    isModalOpen: false,
    exercisingStatus: 1,
    setLoading: action((state, payload) => {
      state.loading = payload;
    }),
    setActiveExerciseRequest: action((state, payload) => {
      state.activeExerciseRequest = payload;
    }),
    setActiveExercisablePlan: action((state, payload) => {
      state.activeExercisablePlan = payload ? state.exercisablePlans.find((plan) => plan.planId === payload) : null;
    }),
    setIsModalOpen: action((state, payload) => {
      state.isModalOpen = payload;
    }),
    setExercisingStatus: action((state, payload) => {
      state.exercisingStatus = payload;
    }),
    getUserExercisingThunk: thunk(async (actions, payload, { fail }) => {
      try {
        const response = await axios.get("/api/ownership/user-exercising");
        return response.data;
      } catch (e) {
        fail(e);
        throw e;
      }
    }),
    addUserExercisingThunk: thunk(async (actions, payload, { fail }) => {
      try {
        actions.setLoading(true);
        const response = await axios.post("/api/ownership/user-exercising", payload);
        return response.data;
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoading(false);
      }
    }),
    notifyExerciseFeePaidThunk: thunk(async (actions, exerciseRequestId, { fail }) => {
      try {
        actions.setLoading(true);
        const response = await axios.post("/api/ownership/user-exercising/notify-exercise-fee-paid", {
          exerciseRequestId,
        });
        return response.data;
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoading(false);
      }
    }),
    onGetUserExercisingActon: actionOn(
      (actions) => getThunkTypes(actions.getUserExercisingThunk),
      (state, target) => {
        const successType = target.resolvedTargets[1];
        if (successType === target.type) {
          state.exercisablePlans = target.result.exercisablePlans;
          state.ongoingExercises = target.result.activeExerciseRequests;
          state.history = target.result.historicExerciseRequests;

          if (target.payload) {
            const ongoingExercise = state.ongoingExercises.find((item) => item.exerciseRequestId === +target.payload);
            state.activeExerciseRequest = ongoingExercise || null;
          }
        }
      }
    ),
  },
  { name: "Exercising" }
);

export default ExercisingContext;
