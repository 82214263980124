import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../../../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.summary");

const FormButtons: FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { values, isSubmitting, setFieldValue } = useFormikContext<CreateProgramFormData>();

  const buttonBackClickHandler = useCallback((): void => {
    setFieldValue("submitter", undefined);
    navigate(
      getEMPath(["createProgram", values?.programTypeId === InstrumentTypesIdsEnum.OPTION ? "termination" : "vesting"]),
      { state }
    );
  }, [navigate, setFieldValue, state, values?.programTypeId]);

  const handleSaveDraft = useCallback(() => {
    setFieldValue("submitter", "draft");
  }, [setFieldValue]);

  const handelSaveAndApprove = useCallback(() => {
    setFieldValue("submitter", "create");
  }, [setFieldValue]);

  return (
    <div className="d-flex align-items-center">
      <WizardContent.Controls.BackButton onClick={buttonBackClickHandler} />

      <div className="ms-auto">
        <Button
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
          variant="tertiary"
          className="me-2"
          type="submit"
          onClick={handleSaveDraft}
        >
          {t("button.saveDraft")}
        </Button>

        <Button isDisabled={isSubmitting} isLoading={isSubmitting} type="submit" onClick={handelSaveAndApprove}>
          {t("button.create")}
        </Button>
      </div>
    </div>
  );
};

export default FormButtons;
