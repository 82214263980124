import React, { FC, useMemo } from "react";
import cn from "classnames";
import { defaultTo } from "ramda";

import { Table, Ui } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDetailsContext } from "../../stakeholder-details.context";
import classes from "../../stakeholder-details.module.scss";
import { CapTableStakeholderDetailsType } from "../../types";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.contacts");

const Head = () => {
  const rows = useMemo(
    () => [
      {
        key: "contactPerson",
        value: t("head.contactPerson"),
      },
      {
        key: "email",
        value: t("head.email"),
      },

      {
        key: "phoneNumber",
        value: t("head.phoneNumber"),
      },
      {
        key: "role",
        value: t("head.role"),
      },
    ],
    []
  );

  return (
    <thead className={classes["head-row"]}>
      <tr>
        {rows.map((el, i) => (
          <th key={el.key} className={cn(classes["head-item"], classes["no-border"])}>
            <div
              className={cn("d-flex align-items-center fw-500", {
                "justify-content-end": i === 2 || i === 3,
              })}
            >
              <Ui.xs>{el.value}</Ui.xs>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Body: FC<{ data: CapTableStakeholderDetailsType["contacts"] }> = ({ data }) => {
  return (
    <tbody>
      {data.length ? (
        data.map((el, index) => (
          <tr key={index} className={cn(classes.tableBorder, classes["no-border"])}>
            <td>
              <Ui.s bold>
                {el.firstName} {el.lastName}
              </Ui.s>
            </td>

            <td>
              <Ui.s>{el.email}</Ui.s>
            </td>

            <td className="text-end">
              <Ui.s>{el.phoneNumber}</Ui.s>
            </td>

            <td className="text-end">
              <Ui.s>{el.stakeholderContactRoleName}</Ui.s>
            </td>
          </tr>
        ))
      ) : (
        <tr className={cn(classes.tableBorder, classes["no-border"])} style={{ height: 80 }}>
          <td width="100%" colSpan={10}>
            <Ui.s color="foregroundLow">There are no contacts</Ui.s>
          </td>
        </tr>
      )}
    </tbody>
  );
};

const ContactsTab = () => {
  const details = StakeholderDetailsContext.useStoreState((state) => state.details);

  return details?.isCompanyOwned ? (
    <div className={cn("m-4", classes["details-container"])}>
      <div className={cn(classes["head"])} style={{ backgroundColor: scssVariables.element2 }}>
        <div className="d-flex justify-content-between align-items-center px-3">
          <div className="text-start">
            <div className="d-flex align-items-center">
              <Ui.m bold>{t("title")}</Ui.m>
            </div>

            <Ui.xs color="foregroundLow">{t("description")}</Ui.xs>
          </div>
        </div>
      </div>

      <Table
        className={classes["table"]}
        headComponent={<Head />}
        bodyComponent={<Body data={defaultTo([], details?.contacts)} />}
      />
    </div>
  ) : null;
};

export default ContactsTab;
