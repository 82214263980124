import { FC, useMemo } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";

import { CompanyRouterItem } from "../EquityManagementRouter";
import CompanyExercisingPage from "./exercising/Exercising";
import CompanyManagePlansPage from "./manage-plans/manage-plans";
import PoolsAndPrograms from "./pools-and-programs/PoolsAndPrograms";

const PlansRouter: FC = () => {
  const companyRouters = useMemo<CompanyRouterItem[]>(
    () => [
      {
        path: ROUTER_V2.equityManagement.plans.poolsAndPrograms,
        component: <PoolsAndPrograms />,
      },
      {
        path: ROUTER_V2.equityManagement.plans.agreements,
        component: <CompanyManagePlansPage />,
      },
      {
        path: ROUTER_V2.equityManagement.plans.exercising,
        component: <CompanyExercisingPage />,
      },
    ],
    []
  );

  return (
    <Routes>
      {companyRouters.map((item, index) => (
        <Route key={index} path={item.path} element={item.component} />
      ))}
    </Routes>
  );
};

export default PlansRouter;
