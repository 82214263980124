import { FC } from "react";
import classNames from "classnames";

import Button from "common/components/atoms/Button/Button";
import { ChevronRightIcon } from "common/icons/svg";
import StepWrapper, { StepWrapperProps } from "common/layout/WizardSinglePage/StepWrapper/StepWrapper";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./summary.module.scss";

type PropsTypes = StepWrapperProps & {
  companyId?: number | null;
};
const t = createTranslation(TranslationNS.pages, "managerOnboarding.summaryStep");

const Summary: FC<PropsTypes> = ({ stepRef, isDisabled }) => {
  const { setIsOnboardingInProgress } = useStoreActions((state) => state.app);

  return (
    <StepWrapper
      className={classNames(classes.summary, "d-flex align-items-center")}
      stepRef={stepRef}
      isDisabled={isDisabled}
    >
      <div className="me-4">
        <h2 className="mb-3">{t("title")}</h2>
        <p className={classNames("ui-s mb-3", classes.paragraph)}>{t("paragraph")}</p>
      </div>
      <div style={{ minWidth: "220px" }}>
        <Button
          onClick={() => {
            setIsOnboardingInProgress(false);
          }}
          iconRight={<ChevronRightIcon />}
          variant="primary"
        >
          {t("submitBtn")}
        </Button>
      </div>
    </StepWrapper>
  );
};

export default Summary;
