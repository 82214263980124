import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button } from "common/components/atoms";
import { FilledArrowDownIcon, PlusIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import DocumentsContext, { FinancialInstrumentsDocuments } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import EmptyDocumentsRow from "../../EmptyDocumentsRow";
import NestedTableRow from "./NestedTableRow";

const t = createTranslation(TranslationNS.pages, "company.documents");

type FinancialInstrumentsTableRowProps = {
  item: FinancialInstrumentsDocuments["instruments"][0];
};

const FinancialInstrumentsTableTableRow: FC<FinancialInstrumentsTableRowProps> = ({ item }) => {
  const { hasFullAccess } = useFeatures(FEATURES.documents);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { searchValue } = DocumentsContext.useStoreState((state) => state);
  const { setIsEditPanelOpen, setSelectedSingleFinancialInstrument } = DocumentsContext.useStoreActions(
    (actions) => actions
  );

  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const rowClickHandler = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const uploadHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      setSelectedSingleFinancialInstrument(item);
      setIsEditPanelOpen({
        isOpen: true,
        documentType: "financialIntrument",
      });
    },
    [item, setIsEditPanelOpen, setSelectedSingleFinancialInstrument]
  );

  useEffect(() => {
    if (searchValue.trim() && !isOpen) {
      rowRef?.current?.click();
    }
  }, [searchValue, isOpen]);

  return (
    <>
      <motion.tr
        ref={rowRef}
        data-testid="company-documents-table-single-row-test-id"
        className={cn(classes["table-row"], {
          [classes.hovered]: isHovered,
          [classes.selected]: isOpen,
        })}
        onHoverEnd={setIsHovered.bind(null, false)}
        onHoverStart={setIsHovered.bind(null, true)}
        onClick={rowClickHandler}
      >
        <td>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center position-relative">
              <motion.div
                className="me-1"
                initial={{ transform: "rotate(0deg)" }}
                animate={{
                  transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              >
                <FilledArrowDownIcon color={scssVariables.foregroundLow} />
              </motion.div>

              <Highlighter
                className="ms-3"
                autoEscape={true}
                textToHighlight={item.name}
                searchWords={[searchValue as string]}
                highlightClassName={classes.highligted}
                unhighlightClassName={classes.unhighlited}
              />

              {isHovered ? (
                <Button
                  variant="tertiary"
                  iconLeft={<PlusIcon />}
                  size="s"
                  className={classes["upload-btn"]}
                  isDisabled={!hasFullAccess}
                  onClick={uploadHandler}
                >
                  Upload
                </Button>
              ) : null}
            </div>

            {item.missingDocuments ? (
              <WarningIcon fontSize={24} color={scssVariables.warning700} className="ms-auto me-1" />
            ) : null}
          </div>
        </td>
      </motion.tr>

      <AnimatePresence>
        {isOpen &&
          (item.documents.length ? (
            item.documents.map((el, index) => <NestedTableRow key={`Inner table row index - ${index}`} data={el} />)
          ) : (
            <EmptyDocumentsRow
              iconClassName="ms-6"
              isRequired={item.missingDocuments}
              label={searchValue?.trim() ? t("noResults") : undefined}
            />
          ))}
      </AnimatePresence>
    </>
  );
};

export default FinancialInstrumentsTableTableRow;
