import { useFormikContext } from "formik";
import * as R from "ramda";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { FormGeneralValues } from "../general";

const translation = createTranslation(TranslationNS.pages, "companyProfile.general");

const DemographicsPart = () => {
  const currenciesDropDown = useStoreState((state) => state.common.dropdowns)?.currencies;

  const { values, errors, touched, setFieldValue } = useFormikContext<FormGeneralValues>();
  return (
    <div>
      <div className="d-flex flex-column mb-10">
        <h4 className="m-0 mb-3 head-xxs">{translation("demographics")}</h4>

        <Dropdown
          label={translation("currency")}
          error={errors.currencyId}
          isTouched={touched?.currencyId}
          selectedValue={
            !R.isNil(currenciesDropDown)
              ? currenciesDropDown?.find((el) => el.id === R.defaultTo(0, values.currencyId))?.name
              : ""
          }
          options={
            !R.isNil(currenciesDropDown) ? currenciesDropDown?.filter((el) => el.name)?.map((el) => el.name) : []
          }
          onChange={(name: string) => {
            if (!R.isNil(currenciesDropDown)) {
              const selectedCurrency = currenciesDropDown?.find((el) => el.name === name);

              setFieldValue("currencyId", selectedCurrency?.id);
            }
          }}
        />
      </div>
    </div>
  );
};

export default DemographicsPart;
