import * as Yup from "yup";

import { TFunction } from "translation/helpers";

import { CapitalIncreaseGeneralFields } from "./form-fields";

export const capitalIncreaseValidationSchema = (tFunction: TFunction) => ({
  [CapitalIncreaseGeneralFields.eventName]: Yup.string().required(tFunction("required")),
  [CapitalIncreaseGeneralFields.sharePrice]: Yup.number()
    .min(
      0.000001,
      tFunction("minNumber", {
        number: "0,000001",
      })
    )
    .required(tFunction("required")),
  [CapitalIncreaseGeneralFields.numberOfShares]: Yup.number()
    .test("is-decimal", tFunction("integer"), (value) => !!String(value).match(/^\d+$/))
    .min(
      1,
      tFunction("minNumber", {
        number: "1",
      })
    )
    .required(tFunction("required")),
  [CapitalIncreaseGeneralFields.transactedAt]: Yup.string().required(tFunction("required")),
  [CapitalIncreaseGeneralFields.description]: Yup.string(),
});
