import { FC } from "react";
import { FormGroup, FormGroupProps } from "react-bootstrap";
import { useFormikContext } from "formik";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { PoolFormValues } from "pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.approvalByField");

const PoolApprovalByDropdown: FC<FormGroupProps> = (props) => {
  const options = useStoreState((state) => state.common.dropdowns)?.approvalBodies || [];

  const { values, handleChange } = useFormikContext<Pick<PoolFormValues, "approvalBodyId">>();

  return (
    <FormGroup {...props}>
      <Dropdown
        isOptional
        className="w-50 mb-3"
        label={t("label")}
        options={options}
        placeholder={"Not selected"}
        optionsIsObject
        name="approvalBodyId"
        selectedValue={values.approvalBodyId}
        onChange={handleChange}
        isSearchable
      />
    </FormGroup>
  );
};

export default PoolApprovalByDropdown;
