import { FC, RefObject, useCallback, useMemo } from "react";
import axios from "axios";
import classNames from "classnames";
import { Formik } from "formik";

import { H, P } from "common/components/atoms/Typography";
import { notify } from "common/utils/notify/notifyFunction";
import { OnboardingUserPostDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { EquityManagementOnboardingDTO, OnSubmit } from "../../../PostponedComponents/onboard-manager-wizard/types";
import { userFields, useUserSchema } from "./shema";
import { UserPostDTO } from "./types";
import classes from "./user.module.scss";
import UserFormFields from "./UserFormFields";

type PropsTypes = {
  onSubmit: OnSubmit;
  isDisabled?: boolean;
  stepRef?: RefObject<HTMLFieldSetElement>;
  prefillInitialValues?: any;
};

const t = createTranslation(TranslationNS.pages, "managerOnboarding.userStep");

const User: FC<PropsTypes> = ({ onSubmit }) => {
  const getAccount = useStoreActions((state) => state.account.getAccountThunk);

  const userDetails = useStoreState((state) => state.account.user);

  const initialValues = useMemo(() => {
    const object: UserPostDTO = {
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
      email: userDetails?.email || "",
      hasAcceptedTermsOfService: !!userDetails?.isOnboarded,
      hasAcceptedPrivacyPolicy: !!userDetails?.isOnboarded,
      profileImage: userDetails?.profileImageFilePath || null,
    };
    return object;
  }, [userDetails]);

  const userSchema = useUserSchema();

  const handleUserSubmit = useCallback(
    async (values: OnboardingUserPostDTO) => {
      const formData = new FormData();

      Object.entries(values).forEach(([key, value]) => {
        formData.set(key, String(value));
      });

      formData.set(userFields.profileImage, values?.profileImage || "");

      try {
        const response = await axios.post<EquityManagementOnboardingDTO>("/api/onboarding/user", formData);

        if (response.status === 200) {
          await getAccount();

          notify(t("successNotification"), true, "success");
          onSubmit(response.data);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [getAccount, onSubmit]
  );

  return (
    <div className={classes.wrap}>
      <H.s className="mb-1">{t("title")}</H.s>
      <P.l className={classNames(classes.subTitle, "mb-6")}>{t("subtitle")}</P.l>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={userSchema}
        onSubmit={handleUserSubmit}
      >
        <UserFormFields isOnboarded={!!userDetails?.isOnboarded} />
      </Formik>
    </div>
  );
};

export default User;
