import { CSSProperties, FC, ReactNode } from "react";
import cn from "classnames";

import { scssVariables, SCSSVariablesType } from "common/utils/constants";

const sizes = ["xl", "xl", "l", "m", "s", "xs", "xxs", "xxxs"] as const;

type HeadPropsTypes = {
  children?: ReactNode;
  className?: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  color?: keyof typeof SCSSVariablesType | CSSProperties["color"];
} & Omit<JSX.IntrinsicElements["h1"], "color">;

type Sizes = (typeof sizes)[number];
type HeadGroup = Record<Sizes, FC<HeadPropsTypes>>;

const xl: FC<HeadPropsTypes> = ({ children, className, color, style, bold, italic, underline, ...props }) => (
  <h1
    style={{
      color: color
        ? scssVariables[color as unknown as SCSSVariablesType]
          ? scssVariables[color as unknown as SCSSVariablesType]
          : color
        : style?.color,
      ...style,
    }}
    className={cn("head-xl", className, { bold: bold, italic: italic, underline: underline })}
    {...props}
  >
    {children}
  </h1>
);
const l: FC<HeadPropsTypes> = ({ children, className, color, style, bold, italic, underline, ...props }) => (
  <h1
    style={{
      color: color
        ? scssVariables[color as unknown as SCSSVariablesType]
          ? scssVariables[color as unknown as SCSSVariablesType]
          : color
        : style?.color,
      ...style,
    }}
    className={cn("head-l", className, { bold: bold, italic: italic, underline: underline })}
    {...props}
  >
    {children}
  </h1>
);
const m: FC<HeadPropsTypes> = ({ children, className, style, color, bold, italic, underline, ...props }) => (
  <h1
    style={{
      color: color
        ? scssVariables[color as unknown as SCSSVariablesType]
          ? scssVariables[color as unknown as SCSSVariablesType]
          : color
        : style?.color,
      ...style,
    }}
    className={cn("head-m", className, { bold: bold, italic: italic, underline: underline })}
    {...props}
  >
    {children}
  </h1>
);
const s: FC<HeadPropsTypes> = ({ children, color, style, className, bold, italic, underline, ...props }) => (
  <h2
    style={{
      color: color
        ? scssVariables[color as unknown as SCSSVariablesType]
          ? scssVariables[color as unknown as SCSSVariablesType]
          : color
        : style?.color,
      ...style,
    }}
    className={cn("head-s", className, { bold: bold, italic: italic, underline: underline })}
    {...props}
  >
    {children}
  </h2>
);
const xs: FC<HeadPropsTypes> = ({ children, color, style, className, bold, italic, underline, ...props }) => (
  <h3
    style={{
      color: color
        ? scssVariables[color as unknown as SCSSVariablesType]
          ? scssVariables[color as unknown as SCSSVariablesType]
          : color
        : style?.color,
      ...style,
    }}
    className={cn("head-xs", className, { bold: bold, italic: italic, underline: underline })}
    {...props}
  >
    {children}
  </h3>
);
const xxs: FC<HeadPropsTypes> = ({ children, className, color, style, bold, italic, underline, ...props }) => (
  <h4
    style={{
      color: color
        ? scssVariables[color as unknown as SCSSVariablesType]
          ? scssVariables[color as unknown as SCSSVariablesType]
          : color
        : style?.color,
      ...style,
    }}
    className={cn("head-xxs", className, { bold: bold, italic: italic, underline: underline })}
    {...props}
  >
    {children}
  </h4>
);
const xxxs: FC<HeadPropsTypes> = ({ children, className, color, style, bold, italic, underline, ...props }) => (
  <h5
    style={{
      color: color
        ? scssVariables[color as unknown as SCSSVariablesType]
          ? scssVariables[color as unknown as SCSSVariablesType]
          : color
        : style?.color,
      ...style,
    }}
    className={cn("head-xxxs", className, { bold: bold, italic: italic, underline: underline })}
    {...props}
  >
    {children}
  </h5>
);

const H: HeadGroup = {
  xl,
  l,
  m,
  s,
  xs,
  xxs,
  xxxs,
};

export default H;
