import { FC } from "react";

import RenderStakeholderFields from "../fields/RenderStakeholderFields/RenderStakeholderFields";
import { StakeholderFieldsParamsType } from "../Stakeholder.types";
import usePerson from "./usePerson";

export type PersonProps = {
  updatedFields?: StakeholderFieldsParamsType;
  isDisabled?: boolean;
};

const Personal: FC<PersonProps> = ({ updatedFields, isDisabled }) => {
  const { topFields, otherFields } = usePerson(updatedFields);

  return <RenderStakeholderFields otherFields={otherFields} topFields={topFields} isDisabled={isDisabled} />;
};
export default Personal;
