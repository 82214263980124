import { FC } from "react";
import { useFormikContext } from "formik";

import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { P, Ui } from "common/components/atoms/Typography";
import DocumentUploadWithStatusesSignatureEmailInvitation from "common/components/molecules/DocumentUploadWithStatusesSignatureEmailInvitation/DocumentUploadWithStatusesSignatureEmailInvitation";
import { createTranslation, TranslationNS } from "translation";

import useWarrantsEditPanel from "../useWarrantsEditPanel";
const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.editPanel");

const Documentation: FC = () => {
  const { initialValues, warrantDetails } = useWarrantsEditPanel();

  const { values, errors, touched, setFieldValue } = useFormikContext<typeof initialValues>();

  const handleDocumentChange = (data: FilesDataMultiple) => {
    setFieldValue("documents", data.newFiles);
  };
  return (
    <>
      <P.m className="mb-4">{t("docsDescription")}</P.m>

      <DocumentUploadWithStatusesSignatureEmailInvitation
        error={errors.documentStatus}
        touched={touched.documentStatus}
        documentStatus={values.documentStatus as unknown as number}
        prevData={warrantDetails?.filesData?.fileProperties}
        isNeedSignatureChecked={values.isNeedSignatureChecked}
        isNeedEmailInvitationChecked={values.isNeedEmailInvitationChecked}
        onFileUploaderChange={handleDocumentChange}
        setDocumentStatus={(statusId) => {
          setFieldValue("documentStatus", statusId);
        }}
        setIsNeedEmailInvitationChecked={(isChecked) => {
          setFieldValue("isNeedEmailInvitationChecked", isChecked);
        }}
        setIsNeedSignatureChecked={(isChecked) => {
          setFieldValue("isNeedSignatureChecked", isChecked);
        }}
      />

      <Ui.m className="mt-3" italic>
        {t("warrantsUpdateFullyCapTable")}
      </Ui.m>
    </>
  );
};

export default Documentation;
