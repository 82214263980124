import { FC } from "react";

import { P } from "common/components/atoms/Typography";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import ConvertibleLoanWizardContext from "../ReceiveConvertibleLoanContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan.receiverSide");

const Info: FC = () => {
  const { convertibleLoanDetails } = ConvertibleLoanWizardContext.useStoreState((state) => state);
  const { currencySymbol } = useCurrencyById(convertibleLoanDetails?.currencyId);

  const fNumber = useFormatNumbers(currencySymbol);

  return (
    <>
      <P.l className="mt-6 mb-3">
        {t.el("description", {
          values: {
            companyName: convertibleLoanDetails?.companyName,
            amount: fNumber(convertibleLoanDetails?.loanAmount, "value"),
          },
          components: [<strong key={1} />],
        })}{" "}
        {convertibleLoanDetails?.discount
          ? t("discount", {
              discount: fNumber(convertibleLoanDetails?.discount, "percent"),
            })
          : null}
        {", "}
        {convertibleLoanDetails?.interestRate
          ? t("interestRate", {
              interestRate: fNumber(convertibleLoanDetails?.interestRate, "percent"),
            })
          : null}
      </P.l>

      {convertibleLoanDetails?.conversionPrice ? (
        <P.l className="mt-2">
          {t.el("conversionPrice", {
            values: {
              price: fNumber(convertibleLoanDetails?.conversionPrice, "unitPrice"),
              expirationDate: convertibleLoanDetails?.expirationDate,
            },
            components: [<strong key={1} />],
          })}
        </P.l>
      ) : null}

      {convertibleLoanDetails?.valuationCap ? (
        <P.l>
          {t.el("valuationCap", {
            values: {
              valuationCap: fNumber(convertibleLoanDetails?.valuationCap, "value"),
            },
            components: [<strong key={1} />],
          })}
        </P.l>
      ) : null}

      <P.l className="my-3">{t("loanMayPayBack")}</P.l>

      <P.l className="mb-6">
        {t(
          convertibleLoanDetails?.documentsNeedsSignature
            ? "descriptionBottomWithSignature"
            : "descriptionBottomNoSignature"
        )}
      </P.l>
    </>
  );
};
export default Info;
