import { FC, useCallback, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormikContext } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { H, InfoAlert, Ui } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { InformationCircleIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { SOURCE_IS_EXISTING_SHARES_ID } from "store/PoolModel";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import PlanTypeDropDown from "../../components/PlanTypeDropDown/PlanTypeDropDown";
import { OwnershipPlanForm } from "../../CreateGrantOneOffPlan";
import grantOneOffPlanContext from "../../OneOffPlanContext";
import OneOffRSA from "./OneOffRSA/OneOffRSA";
import OneOffStockOption from "./OneOffStockOptions/OneOffStockOptions";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.sharesDetails");

const SharesDetails: FC = () => {
  const navigate = useNavigate();

  const { getPoolThunk } = useStoreActions((actions) => actions.poolModel);
  const { setOneOffPlanType } = grantOneOffPlanContext.useStoreActions((actions) => actions);
  const planTypes = useStoreState((state) => state.common.dropdowns)?.instrumentTypes;

  const handleStepBack = useCallback(() => {
    navigate(getEMPath(["createOneOffPlan", "sharesSource"]));
  }, [navigate]);

  const { values, handleSubmit, setFieldValue } = useFormikContext<OwnershipPlanForm>();

  const isStakeholderSourceOfShares = useMemo(() => {
    return !!values.sharesFromStakeholderId;
  }, [values.sharesFromStakeholderId]);

  const submitHandler = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleChangeDropdown = useCallback(
    (id: number) => {
      setFieldValue(f.planTypeId, id);
      setOneOffPlanType(id);
    },
    [setFieldValue, setOneOffPlanType]
  );

  useEffect(() => {
    if (values.poolId && values.poolId !== SOURCE_IS_EXISTING_SHARES_ID) {
      getPoolThunk(values.poolId).then(({ data: pool }) => {
        setFieldValue(f.numberOfSharesLeft, pool?.numberOfSharesLeft || 0);
      });
    } else {
      setFieldValue(f.numberOfSharesLeft, -1);
    }
  }, [getPoolThunk, setFieldValue, values.poolId]);

  useEffect(() => {
    if (values.planTypeId === 3 && isStakeholderSourceOfShares) {
      setFieldValue(f.planTypeId, 1);
    }
  }, [values.planTypeId, isStakeholderSourceOfShares, setFieldValue]);

  return (
    <WizardContent.Content step={3}>
      <H.xxs className="mb-3">{t("title")}</H.xxs>

      <PlanTypeDropDown
        selectedValue={values.planTypeId}
        optionValues={planTypes || []}
        label={t("planTypes.title")}
        onChange={handleChangeDropdown}
        isStockOptionsDisabled={isStakeholderSourceOfShares}
      />

      {isStakeholderSourceOfShares && (
        <InfoAlert
          className="mt-1"
          type="Neutral"
          customContent={
            <div className="d-flex p-2">
              <InformationCircleIcon height={24} width={56} />
              <Ui.m className="ms-2">
                {t.el("optionsDisabledInfo", { components: [<Link key={1} to="#"></Link>] })}
              </Ui.m>
            </div>
          }
        />
      )}

      <div className="mb-3" />

      {values.planTypeId === InstrumentTypesIdsEnum.OPTION && <OneOffStockOption hideShareDetails />}
      {values.planTypeId === InstrumentTypesIdsEnum.RSA && <OneOffRSA hideShareDetails />}

      <div className="d-flex mt-8">
        <WizardContent.Controls.BackButton className="ms-auto me-2" onClick={handleStepBack} />
        <WizardContent.Controls.ContinueButton onClick={submitHandler} />
      </div>
    </WizardContent.Content>
  );
};

export default SharesDetails;
