import { FC, JSX } from "react";
import classNames from "classnames";

import Helper from "common/components/atoms/Helper/Helper";
import { InstrumentTypesIdsEnum } from "common/enums/enum";

import AcceleratedVestingOnEvent from "./AcceleratedVestingOnEvent";
import Conditions from "./Conditions/Conditions";
import PlanAllocationMessage from "./PlanAllocationMessage/PlanAllocationMessage";
import TimeBasedFields from "./TimeBasedFields/TimeBasedFields";
import classes from "./VestingDetails.module.scss";
import VestingTypeDescription from "./VestingTypeDescription";
import VestingTypeField from "./VestingTypeField";

const FieldsWrap: FC<JSX.IntrinsicElements["div"]> = ({ className, children, ...props }) => (
  <div className={classNames("p-3 mb-5", classes["fields-wrap"], className)} {...props}>
    {children}
  </div>
);

export type VestingDetailsProps = {
  type: "plan" | "program";
  instrumentType?: InstrumentTypesIdsEnum;
};

type VestingDetailsComponent = FC<VestingDetailsProps> & {
  FieldsWrap: typeof FieldsWrap;
  VestingTypeField: typeof VestingTypeField;
  VestingTypeDescription: typeof VestingTypeDescription;
  TimeBasedFields: typeof TimeBasedFields;
  Conditions: typeof Conditions;
  PlanAllocationMessage: typeof PlanAllocationMessage;
  AcceleratedVestingOnEvent: typeof AcceleratedVestingOnEvent;
};

const VestingDetails: VestingDetailsComponent = ({ type, instrumentType }) => (
  <FieldsWrap>
    <Helper>
      <VestingTypeField />
      {/*<VestingTypeDescription />*/}

      <TimeBasedFields type={type} instrumentType={instrumentType} />
      <Conditions type={type} instrumentType={instrumentType} />
      <PlanAllocationMessage />
      <AcceleratedVestingOnEvent />
    </Helper>
  </FieldsWrap>
);

VestingDetails.FieldsWrap = FieldsWrap;
VestingDetails.VestingTypeField = VestingTypeField;
VestingDetails.VestingTypeDescription = VestingTypeDescription;
VestingDetails.TimeBasedFields = TimeBasedFields;
VestingDetails.Conditions = Conditions;
VestingDetails.PlanAllocationMessage = PlanAllocationMessage;
VestingDetails.AcceleratedVestingOnEvent = AcceleratedVestingOnEvent;

export default VestingDetails;
