import { FC, useMemo, useState } from "react";
import cn from "classnames";
import * as R from "ramda";
import { equals } from "ramda";

import { Ui } from "common/components/atoms";
import { setMissedQuarters } from "common/helpers";
import { stepPoint } from "common/types/Charts.types";
import { scssVariables } from "common/utils/constants";
import { CommonOwnershipPlanDetails } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import TabSwitcher from "../tab-switcher/TabSwitcher";
import VestingTable from "../vesting-table/VestingTable";
import classes from "./VestedAndExercisedChart.module.scss";
import VestedLinearChartContent from "./VestedLinearChartContent";

type PropsTypes = {
  stockOptionsPlan: CommonOwnershipPlanDetails | null;
  className?: string;
  isLosted?: boolean;
};

const t = createTranslation(TranslationNS.common, "organisms.agreement.vestedAndExercisedChart");

const VestedAndExercisedChart: FC<PropsTypes> = ({ isLosted = false, className, stockOptionsPlan }) => {
  const [activeTab, setActiveTab] = useState<"chart" | "table">("chart");

  const data = useMemo(() => {
    if (
      !R.isNil(stockOptionsPlan) &&
      (!R.isEmpty(stockOptionsPlan.restrictedDataPoints) || !R.isEmpty(stockOptionsPlan.vestedDataPoints))
    ) {
      let restrictedValues = [];

      if (stockOptionsPlan.restrictedDataPoints?.[0]?.x) {
        restrictedValues.push({
          x: stockOptionsPlan.restrictedDataPoints[0]?.x,
          y: stockOptionsPlan.vestedDataPoints?.[stockOptionsPlan.vestedDataPoints?.length - 1]?.y,
        });
      }

      restrictedValues.push(...stockOptionsPlan.restrictedDataPoints);

      if (
        stockOptionsPlan.restrictedDataPoints?.[0]?.x &&
        stockOptionsPlan.restrictedDataPoints?.[0]?.x !== R.last(stockOptionsPlan.vestedDataPoints)?.x
      ) {
        restrictedValues = R.insert(0, R.last(stockOptionsPlan.vestedDataPoints), restrictedValues) as stepPoint[];
      }

      return [
        {
          id: "Shares vested",
          color: scssVariables.positive050,
          data: setMissedQuarters(stockOptionsPlan.vestedDataPoints),
        },
        {
          id: "Total",
          color: isLosted ? scssVariables.critical700 : scssVariables.primary1,
          data: setMissedQuarters(restrictedValues),
        },
      ];
    }
  }, [isLosted, stockOptionsPlan]);

  return (
    <div className={cn(className, classes.container)}>
      <div className="d-flex justify-content-between mb-5">
        <div>
          <Ui.l bold>{t("optionsVestedAndExercised")}</Ui.l>

          <Ui.xs style={{ color: scssVariables.foregroundMedium }}>{t("disaplyingTheProcess")}</Ui.xs>
        </div>

        <TabSwitcher activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>

      {equals(activeTab, "chart") ? (
        <VestedLinearChartContent data={data} agreement={stockOptionsPlan} isLosted={isLosted} />
      ) : null}

      {equals(activeTab, "table") ? <VestingTable agreement={stockOptionsPlan} /> : null}
    </div>
  );
};

export default VestedAndExercisedChart;
