import { FC, useCallback } from "react";
import { useFormikContext } from "formik";
import { isNil } from "ramda";

import Button from "common/components/atoms/Button/Button";
import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { PlusIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../../../../../../pages/equity-management/plans/wizards/create-program/useProgramForm";
import { AvailableAmountAgreementsType } from "../../../../../types/Common.types";

type ProgramVestingCondition = {
  title: string;
  description: string;
  targetDate: string | Date;
};

export const emptyVestingConditions: ProgramVestingCondition = {
  title: "",
  description: "",
  targetDate: createDateString(new Date()),
};

const t = createTranslation(TranslationNS.common, "program.components.programForm.vestingDetails.vestingConditions");

const AddCondition: FC = () => {
  const { values, setFieldValue } = useFormikContext<
    Pick<CreateProgramFormData, "conditions"> & Partial<Record<"subscriptionAvailable", AvailableAmountAgreementsType>>
  >();

  const isFormDisabled =
    !isNil(values.subscriptionAvailable) && !values.subscriptionAvailable.remainingAgreements;

  const addClickHandler = useCallback((): void => {
    setFieldValue(
      "conditions",
      values.conditions ? [...values.conditions, emptyVestingConditions] : [emptyVestingConditions]
    );
  }, [setFieldValue, values.conditions]);

  return (
    <Button
      className="my-3"
      variant="tertiary"
      iconLeft={<PlusIcon />}
      onClick={addClickHandler}
      isDisabled={isFormDisabled}
    >
      {t("addCondition")}
    </Button>
  );
};

export default AddCondition;
