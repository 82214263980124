import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import ShareClassService from "common/shareClass/ShareClassService";
import { ShareClass } from "common/shareClass/types";

interface ShareClassesStoreModel {
  shareClasses: ShareClass[];
  shareClassesWithDetails: ShareClass[];
  setShareClasses: Action<this, this["shareClasses"]>;
  setShareClassesWithDetails: Action<this, this["shareClassesWithDetails"]>;
  fetchShareClasses: Thunk<this, undefined, any, {}, Promise<ShareClass[]>>;
  fetchShareClassesWithDetails: Thunk<this, number | string | undefined>;
  editingShareClassId?: ShareClass["id"];
  setEditingShareClassId: Action<this, this["editingShareClassId"]>;
}

export const ShareClassesStore = createContextStore<ShareClassesStoreModel>({
  shareClasses: [],
  shareClassesWithDetails: [],
  setShareClasses: action((state, payload) => {
    state.shareClasses = payload;
  }),
  setShareClassesWithDetails: action((state, payload) => {
    state.shareClassesWithDetails = payload;
  }),
  fetchShareClasses: thunk(async (actions) => {
    try {
      const { data } = await ShareClassService.companyShareClasses();
      const dataRequests = data.map((el) => ShareClassService.get(el.id));
      const dataPromiceResponces = (await Promise.all(dataRequests)).map((el) => el.data);

      actions.setShareClasses(dataPromiceResponces);

      return dataPromiceResponces;
    } catch {
      actions.setShareClasses([]);

      return [];
    }
  }),
  fetchShareClassesWithDetails: thunk(async (actions, companyId) => {
    try {
      const response = await ShareClassService.companyShareClassesWithDetails(companyId);
      actions.setShareClassesWithDetails(response.data);
    } catch (e) {
      actions.setShareClassesWithDetails([]);
    }
  }),

  setEditingShareClassId: action((state, payload) => {
    state.editingShareClassId = payload;
  }),
});
