import { CSSProperties, FC, forwardRef, ForwardRefExoticComponent, useState } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import cn from "classnames";

import Button, { ButtonProps, ButtonVariants } from "common/components/atoms/Button/Button";
import classes from "common/components/atoms/Dropdown/Dropdown.module.scss";
import { ChevronDownIcon } from "common/icons/svg";

import DropdownMenu, { MenuOption } from "./DropdownMenu";

type PropsTypes = {
  options: MenuOption[];
  title: string;
  className?: string;
  style?: CSSProperties;
  testId?: string;
  alignToRight?: boolean;
  customToggleElement?: ForwardRefExoticComponent<any>;
  isDisabled?: boolean;
  dataTestId?: string;
  dropdownIconColor?: string;
  size?: "s" | "m";
  variant?: ButtonVariants;
};

const ButtonRef = forwardRef<HTMLButtonElement, ButtonProps>((props, _ref) => <Button isFocusDisabled {...props} />);

/**
 * @deprecated
 * Rune asked to not use this one.
 * He prefer dropdown button like in captable (TODO extract as ATOM)
 */
const DropdownButton: FC<PropsTypes> = ({
  options,
  title,
  customToggleElement,
  className,
  style,
  testId,
  alignToRight = false,
  isDisabled,
  dataTestId,
  dropdownIconColor,
  size = "s",
  variant,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cn(className)} style={style} data-testid={testId}>
      <BDropdown>
        <div className={cn({ [classes.alignRight]: alignToRight })}>
          <BDropdown.Toggle
            variant={variant}
            dataTestId={dataTestId}
            disabled={isDisabled}
            as={customToggleElement ? customToggleElement : ButtonRef}
            // @ts-ignore
            size={size}
            iconRight={
              <ChevronDownIcon
                // fontSize={16}
                direction={isOpen ? "top" : "bottom"}
                color={dropdownIconColor || "currentColor"}
              />
            }
          >
            {title}
          </BDropdown.Toggle>

          <BDropdown.Menu
            as={DropdownMenu}
            options={options}
            data-testid="dropdown-menu-container"
            className={alignToRight ? classes["menu_align-right"] : classes.menu}
            handleToggleMenu={(isOpen: boolean) => setIsOpen(isOpen)}
          />
        </div>
      </BDropdown>
    </div>
  );
};

export default DropdownButton;
