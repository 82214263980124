import { FC, useState } from "react";
import { useParams } from "react-router";
import { isNil } from "ramda";

import { Button, DatePicker, ModalInfo } from "common/components/atoms";
import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { AgreementMilestoneStatuses, InstrumentTypesNamesEnum } from "common/enums/enum";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { getCurrentAgreementContext } from "../../functions";
import classes from "./MilestonesModal.module.scss";

/**
 * Description: milestonemodal, using for the case if user need to complete a milestone he has. It will show a modal
 * with datepicker.
 * @param { type } InstrumentTypesNamesEnum Type for specific agreement. Will choose the necessary context for agreement
 */

const t = createTranslation(TranslationNS.common, "organisms.agreement.milestones");

const MilestonesModal: FC<{ type: InstrumentTypesNamesEnum }> = ({ type }) => {
  const { companyId } = useParams<{ companyId: string }>();

  const [date, setDate] = useState<string | Date>(new Date());

  const { agreementDetails, milestoneModal } = getCurrentAgreementContext(type).useStoreState((state) => state);
  const { setMilestoneModal, updateMilestoneStatus } = getCurrentAgreementContext(type).useStoreActions(
    (actions) => actions
  );
  const { getOwnershipPlansThunk } = useStoreActions((actions) => actions.company);

  const updateHandler = async () => {
    await updateMilestoneStatus({
      completedAt: createDateString(new Date(date)),
      milestoneId: Number(milestoneModal?.milestone?.id),
      statusId: milestoneModal?.action || AgreementMilestoneStatuses.complete,
      planId: Number(agreementDetails?.id),
    });

    await getOwnershipPlansThunk(Number(companyId));
    handleClose();
  };

  const handleClose = () => {
    setMilestoneModal(null);
    setDate(new Date());
  };

  return (
    <ModalInfo
      show={!isNil(milestoneModal)}
      header={t("modalTitle")}
      handleClose={handleClose}
      contentClassName={classes["container"]}
      footer={
        <div>
          <Button className="me-2" onClick={updateHandler}>
            {t("table.setComplete")}
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      }
    >
      <DatePicker
        isDateOnlyString
        date={date}
        label={t("label")}
        onChange={(selectedDate) => {
          setDate(selectedDate);
        }}
      />
    </ModalInfo>
  );
};

export default MilestonesModal;
