import { FC, memo, ReactElement, ReactNode } from "react";
import { OverlayTrigger, Tooltip as TooltipBootstrap } from "react-bootstrap";
import { OverlayTriggerProps } from "react-bootstrap/OverlayTrigger";
import classNames from "classnames";

import classes from "./Tooltip.module.scss";

type TooltipProps = Omit<OverlayTriggerProps, "overlay"> & {
  popupContent: ReactNode;
  className?: string;
  children: ReactElement;
};
const Tooltip: FC<TooltipProps> = ({ children, popupContent, className, ...props }) => {
  return (
    <OverlayTrigger
      overlay={<TooltipBootstrap className={classNames(className, classes.tooltip)}>{popupContent}</TooltipBootstrap>}
      {...props}
    >
      {children}
    </OverlayTrigger>
  );
};

export default memo(Tooltip);
