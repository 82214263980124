import { FC, Fragment, useCallback, useEffect } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";
import { defaultTo } from "ramda";

import { Check, P, ProgressChart, SlidePanel, TextField, Ui } from "common/components/atoms";
import { SOAgreementContext } from "common/components/organisms";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { CommonOwnershipPlanDetails } from "../../../../../store/types";
import { RequestToExerciseFormType } from "../RequestToExerciseOffCanvas";
import classes from "../RequestToExerciseOffCanvas.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.exercising.requestToExerciseOffCanvas");

interface RequestToExerciseFormProps {
  plan: CommonOwnershipPlanDetails;
}

const RequestToExerciseForm: FC<RequestToExerciseFormProps> = ({ plan }) => {
  const loading = SOAgreementContext.useStoreState((state) => state.loading);
  const setActiveExercisablePlan = SOAgreementContext.useStoreActions((actions) => actions.setActiveExercisablePlan);

  const { currencySymbol } = useCurrencyById(plan?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const { isValid, values, handleChange, touched, errors, handleSubmit, handleBlur, validateForm } =
    useFormikContext<RequestToExerciseFormType>();

  const currentProfit =
    defaultTo(0, Number(values?.amountOptions)) !== 0
      ? (values?.amountOptions * 1e7 * (plan.sharePrice * 1e7 - plan.exercisePrice * 1e7)) / (1e7 * 1e7)
      : plan?.profitLossValue;

  const handleSubmitForm = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleClose = useCallback(() => {
    setActiveExercisablePlan(null);
  }, [setActiveExercisablePlan]);

  useEffect(() => {
    validateForm(values).then();
  }, [validateForm, values]);

  return (
    <div className={classes["off-canvas"]}>
      <SlidePanel.Header title={t("title")} onHide={handleClose} />
      <SlidePanel.Section title={t("stockOptions")}>
        <Ui.s color="foregroundMedium" className="mb-2">
          {t("reviewTheSummary")}
        </Ui.s>

        <ProgressChart
          total={plan.vestedAndExercisedShares + defaultTo(0, plan.availableForPurchaseShares)}
          withoutLabel
          data={[
            {
              value: defaultTo(0, plan.availableForPurchaseShares),
              id: "Options",
              withThumb: false,
              showTooltip: false,
              color: scssVariables.positive500,
            },
            {
              value: plan.vestedAndExercisedShares,
              id: "ExercisedOptions",
              withThumb: false,
              showTooltip: false,
              color: scssVariables.positive050,
            },
          ]}
        />

        <div className={cn(classes["chart-legend"], "mt-2")}>
          <div className="d-flex me-4 align-items-center">
            <div className={cn(classes["circle"], "me-1")} style={{ backgroundColor: scssVariables.positive500 }} />
            <Ui.m>
              {t("availableForPurchase", {
                value: plan?.availableForPurchaseShares,
              })}
            </Ui.m>
          </div>

          <div className="d-flex me-4 align-items-center">
            <div className={cn(classes["circle"], "me-1")} style={{ backgroundColor: scssVariables.positive050 }} />
            <Ui.m>
              {t("excersiedOptions", {
                value: defaultTo(0, plan?.vestedAndExercisedShares),
              })}
            </Ui.m>
          </div>
        </div>
      </SlidePanel.Section>

      <SlidePanel.Section
        title={t("availableToExercise", {
          value: plan?.availableForPurchaseShares,
        })}
      >
        <TextField
          type="number"
          value={values.amountOptions}
          isTouched={touched.amountOptions}
          error={errors.amountOptions}
          label={t("enterAmountOfOptions")}
          placeholder={t("numberOfOptions")}
          name="amountOptions"
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("profitLoss")} isDividerVisible>
        <Ui.m className="mb-5">
          {t.el("incomeDescription", {
            components: [
              <strong
                key={1}
                style={{ color: currentProfit >= 0 ? scssVariables.positive700 : scssVariables.critical900 }}
              />,
              <Fragment key={2}>
                <br />
                <br />
              </Fragment>,
            ],
            values: {
              value: `${currentProfit >= 0 ? "+" : "-"}${fNumber(Math.abs(currentProfit), "value")}`,
              companyName: plan?.companyName,
            },
          })}
        </Ui.m>

        <div className={classes.info}>
          <div className={cn(classes.row, "mb-3")}>
            <Ui.m className="fw-500">{t("numberOfOptions")}</Ui.m>
            <Ui.m>{fNumber(values?.amountOptions, "amount")} options</Ui.m>
          </div>

          <div className={cn(classes.row, "mb-3")}>
            <Ui.m className="fw-500">{t("exercisePrice")}</Ui.m>
            <Ui.m>{fNumber(plan.exercisePrice, "unitPrice")}</Ui.m>
          </div>

          <div className={cn(classes.row, "mb-3")}>
            <Ui.m className="fw-500">{t("totalExerciseAmount")}</Ui.m>
            <Ui.m>{fNumber(defaultTo(0, values?.amountOptions) * defaultTo(0, plan.exercisePrice), "value")}</Ui.m>
          </div>

          <div className={cn(classes.row, "mb-3")}>
            <Ui.m className="fw-500">{t("currentFairMarketValue")}</Ui.m>
            <Ui.m>{fNumber(plan?.sharePrice, "sharePrice")}</Ui.m>
          </div>

          <div className={cn(classes.row, "mb-3")}>
            <Ui.m className="fw-500">{t("valueYourAboutBuy")}</Ui.m>
            <Ui.m>{fNumber((plan.sharePrice * defaultTo(0, values?.amountOptions) * 1e7) / 1e7, "value")}</Ui.m>
          </div>

          <div className={classes.row}>
            <Ui.m className="fw-500">{t("unrealisedProfitLoss")}</Ui.m>
            <Ui.m bold color={currentProfit >= 0 ? "positive700" : "critical900"}>
              {currentProfit >= 0 ? "+" : ""}
              {fNumber(currentProfit, "value")}
            </Ui.m>
          </div>
        </div>
      </SlidePanel.Section>

      <Check
        onChange={handleChange}
        checked={values.isTermAndCondition}
        name="isTermAndCondition"
        className={cn("mt-5 ms-n1", classes.checkbox)}
        label={t("iReadTerms", { companyName: plan?.companyName })}
      />

      <P.m className="my-5">
        {t("requestWillBeSentToManager", {
          name: "manager",
          companyName: plan?.companyName,
        })}
      </P.m>

      <SlidePanel.Actions
        isLoading={loading}
        isDisabledPrimary={!isValid || loading}
        primaryAction={handleSubmitForm}
        primaryTitle={t("signAndRequest")}
        secondaryTitle={t("cancel")}
        secondaryAction={handleClose}
        className="mt-8"
      />
    </div>
  );
};

export default RequestToExerciseForm;
