import { ReactNode, useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";

import AccessMiddlewareAttract from "app/middleware/AccessMiddleware/AccessMiddlewareAttract";
import { getAttractPath, getPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import {
  CompanyIcon,
  GraphStatsIcon,
  InformationCircleOutlinedIcon,
  OfficeOutdoorsIcon,
  PresentationBoardIcon,
  UsersIcon,
} from "common/icons/svg";
import MainLayout from "common/layout/MainLayout/MainLayout";
import Menu, { MenuItem } from "common/layout/MainLayout/Menu/Menu";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CompanyProfileFundingPage from "./funding/funding";
import CompanyProfileGeneralPage from "./general/general";
import CompanyProfilePitchPage from "./pitch/pitch";
import CompanyProfileTeamPage from "./team/team";

type RouterItem = {
  path: string;
  component: ReactNode;
};

type CompanyProfileMenus = {
  companyPitch: MenuItem[];
  quickLinks: MenuItem[];
};

const t = createTranslation(TranslationNS.pages, "companyProfile.sideBar");

const AttractRouter = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { HubEvents } = useStoreState((state) => state.company);

  const { getHubEventsThunk } = useStoreActions((state) => state.company);

  const companyProfileMenus = useMemo<CompanyProfileMenus>(() => {
    return {
      companyPitch: [
        {
          to: getAttractPath(["general"], { companyId }),
          icon: <InformationCircleOutlinedIcon />,
          label: t("myAccount.routes.general"),
        },
        {
          to: getAttractPath(["pitch"], { companyId }),
          icon: <PresentationBoardIcon />,
          label: t("myAccount.routes.pitch"),
        },
        {
          to: getAttractPath(["team"], { companyId }),
          icon: <UsersIcon />,
          label: t("myAccount.routes.team"),
        },
        {
          to: getAttractPath(["fundingStatus"], { companyId }),
          icon: <GraphStatsIcon />,
          label: t("myAccount.routes.fundingStatus"),
        },
      ],
      quickLinks: HubEvents?.length
        ? [
            {
              to: getPath(["companyDetails"], { id: companyId }),
              label: t("quickLinks.routes.viewCompany"),
              icon: <CompanyIcon />,
            },
            ...HubEvents.map((event) => ({
              to: getPath(["event"], { id: event.id }),
              label: t("quickLinks.routes.viewCompany"),
              icon: <OfficeOutdoorsIcon />,
            })),
          ]
        : [
            {
              to: getPath(["companyDetails"], { id: companyId }),
              label: t("quickLinks.routes.viewCompany"),
              icon: <CompanyIcon />,
            },
          ],
    };
  }, [HubEvents, companyId]);

  const companyProfileRouters = useMemo<RouterItem[]>(() => {
    return [
      {
        path: ROUTER_V2.attract.general,
        component: <CompanyProfileGeneralPage />,
      },
      {
        path: ROUTER_V2.attract.pitch,
        component: <CompanyProfilePitchPage />,
      },
      {
        path: ROUTER_V2.attract.team,
        component: <CompanyProfileTeamPage />,
      },
      {
        path: ROUTER_V2.attract.fundingStatus,
        component: <CompanyProfileFundingPage />,
      },
    ];
  }, []);

  useEffect(() => {
    getHubEventsThunk(Number(companyId));
  }, [companyId, getHubEventsThunk]);

  return (
    <MainLayout>
      <MainLayout.Sidebar>
        <Menu title={t("myAccount.title")} items={companyProfileMenus.companyPitch} />
        <Menu title={t("quickLinks.title")} items={companyProfileMenus.quickLinks} />
      </MainLayout.Sidebar>

      <MainLayout.Content>
        <Routes>
          <Route element={<AccessMiddlewareAttract />}>
            {companyProfileRouters.map((item, index) => (
              <Route key={index} path={item.path} element={item.component} />
            ))}

            <Route path="*" element={<Navigate to={ROUTER_V2.attract.general} />} />
          </Route>
        </Routes>
      </MainLayout.Content>
    </MainLayout>
  );
};

export default AttractRouter;
