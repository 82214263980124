import React, { FC, useMemo } from "react";
import classNames from "classnames";
import { isNil } from "ramda";

import { InformationCircleIcon, WarningIcon } from "../../../icons/svg";
import { P, Ui } from "../index";
import classes from "./Banner.module.scss";

export type BannerVariants = "information" | "warning" | "error";

type Props = {
  icon?: JSX.Element;
  title?: string;
  description?: string;
  variant?: BannerVariants;
} & JSX.IntrinsicElements["div"];

const Banner: FC<Props> = ({ className, variant = "information", description, title, icon, ...props }) => {
  const Icon = useMemo(() => {
    if (!isNil(icon)) {
      return icon;
    }

    if (variant === "information") {
      return <InformationCircleIcon />;
    }

    if (variant === "error") {
      return <WarningIcon />;
    }

    if (variant === "warning") {
      return <WarningIcon />;
    }
  }, [icon, variant]);

  return (
    <div
      className={classNames(className, "d-flex p-2", classes["container"], {
        [classes[variant]]: true,
      })}
      {...props}
    >
      {Icon}

      <div className={classNames("ms-2", classes["content"])}>
        {title ? <Ui.m bold>{title}</Ui.m> : null}

        {description ? <P.s>{description}</P.s> : null}
      </div>
    </div>
  );
};

export default Banner;
