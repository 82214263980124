import { ChangeEvent, FC, Fragment, useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { DatePicker, Dropdown, Helper, TextArea, TextField } from "common/components/atoms";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { TransactionDetailsFields } from "./form-fields";
import { TransactionDetailsFieldsType } from "./types";

type PropsTypes = {
  hiddenFields?: {
    [TransactionDetailsFields.transactedAt]?: boolean;
    [TransactionDetailsFields.transactionTypeId]?: boolean;
    transactionTypeTransferId?: boolean;
    [TransactionDetailsFields.description]?: boolean;
    [TransactionDetailsFields.eventName]?: boolean;
  };
  minTransactionDate?: string;
  isDisabled?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionDetails");

export const SHARE_ISSUES = 2;
const SECONDARY_SALE_TRANSFER_TYPE = 9;

const TransactionDetails: FC<PropsTypes> = ({ hiddenFields, minTransactionDate, isDisabled }) => {
  const { values, errors, touched, setFieldValue, handleChange, handleBlur } =
    useFormikContext<TransactionDetailsFieldsType>();
  const transactionTypesIssuing = useStoreState((state) => state.common.dropdowns)?.transactionTypesIssuing;
  const transactionTypesTransfer = useStoreState((state) => state.common.dropdowns)?.transactionTypesTransfer;

  const transactionTypesList = useMemo(() => {
    if (!transactionTypesIssuing) return [];
    return transactionTypesIssuing.filter((type) => ![SHARE_ISSUES].includes(type.id));
  }, [transactionTypesIssuing]);

  const transactionTypesTransferList = useMemo(() => {
    if (!transactionTypesTransfer) return [];
    return transactionTypesTransfer
      .filter((type) => ![SHARE_ISSUES].includes(type.id))
      .map((el) =>
        el.id === SECONDARY_SALE_TRANSFER_TYPE
          ? {
              id: el.id,
              name: `${el.name} (${t("mostUsed")})`,
            }
          : el
      );
  }, [transactionTypesTransfer]);

  const handleTypeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(TransactionDetailsFields.transactionTypeId, e.target.value === "" ? undefined : e.target.value);
    },
    [setFieldValue]
  );

  const handleTypeTransferChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(TransactionDetailsFields.transactionTypeId, e.target.value === "" ? undefined : e.target.value);
    },
    [setFieldValue]
  );

  const minDateInfo = useMemo(() => {
    if (minTransactionDate) {
      return t("minDateInfo", { minDate: transformDateToCommonDateFormat(minTransactionDate) });
    }
  }, [minTransactionDate]);

  return (
    <div data-testid="transaction-details-from-id">
      <Helper>
        {hiddenFields?.eventName && (
          <Row className="mb-4">
            <Col md={6}>
              <TextField
                isOptional
                name={TransactionDetailsFields.eventName}
                label={t("eventName")}
                value={values.eventName}
                error={errors.eventName}
                isTouched={touched.eventName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
        )}
        <Row className=" mb-4">
          {!hiddenFields?.transactionTypeId && (
            <Col md={6}>
              <Dropdown
                isOptional
                isClearable
                name={TransactionDetailsFields.transactionTypeId}
                label={t("type")}
                options={transactionTypesList}
                selectedValue={values?.transactionTypeId}
                optionsIsObject
                isTouched={touched.transactionTypeId}
                error={errors.transactionTypeId}
                onChange={handleTypeChange}
                placeholder={t("typePlaceholder")}
                isDisabled={isDisabled}
              />
            </Col>
          )}
          {!hiddenFields?.transactedAt && (
            <Col md={6}>
              <Helper.Question questionId={TransactionDetailsFields.transactedAt} type="input">
                <DatePicker
                  name={TransactionDetailsFields.transactedAt}
                  label={t("date")}
                  date={values.transactedAt}
                  error={errors.transactedAt}
                  isTouched={touched.transactedAt}
                  minDate={minTransactionDate ? new Date(minTransactionDate) : undefined}
                  isDateOnlyString
                  isWithTimeSelect
                  onChange={(date) => {
                    setFieldValue(TransactionDetailsFields.transactedAt, date);
                  }}
                  info={minDateInfo}
                  isDisabled={isDisabled}
                />
              </Helper.Question>
              <Helper.Answer
                className="mt-3"
                withRightMargin
                answerId={TransactionDetailsFields.transactedAt}
                text={t("dateHelp")}
              />
            </Col>
          )}
        </Row>

        {!hiddenFields?.transactionTypeTransferId && (
          <div className="mb-5">
            <Helper.Question questionId="transactionTypeTransferId" type="input">
              <Dropdown
                isOptional
                isClearable
                name={TransactionDetailsFields.transactionTypeId}
                label={t("type")}
                options={transactionTypesTransferList}
                selectedValue={values?.transactionTypeId}
                optionsIsObject
                isTouched={touched.transactionTypeId}
                error={errors.transactionTypeId}
                onChange={handleTypeTransferChange}
                placeholder={t("typePlaceholder")}
                isDisabled={isDisabled}
              />
            </Helper.Question>

            <Helper.Answer
              className="mt-3"
              withRightMargin
              answerId="transactionTypeTransferId"
              text={t.el("eventTypeHelp", {
                components: [
                  <Fragment key={1}>
                    <br />
                    <br />
                  </Fragment>,
                  <br key={2} />,
                ],
              })}
            />
          </div>
        )}

        {!hiddenFields?.description && (
          <>
            <Helper.Question questionId={TransactionDetailsFields.description} type="input">
              <TextArea
                name={TransactionDetailsFields.description}
                label={t("description")}
                placeholder={t("descriptionPlaceholder")}
                isOptional
                value={values.description}
                error={errors.description}
                isTouched={touched.description}
                onChange={handleChange}
                isDisabled={isDisabled}
              />
            </Helper.Question>
            <Helper.Answer
              className="mt-3"
              withRightMargin
              answerId={TransactionDetailsFields.description}
              text={t("descriptionHelp")}
            />
          </>
        )}
      </Helper>
    </div>
  );
};

export default TransactionDetails;
