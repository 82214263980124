import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikConfig } from "formik";
import * as R from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { LoaderContainer } from "common/components/atoms";
import { H } from "common/components/atoms/Typography";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useStepForm, { ThreeStepForm } from "../components/step/useStepForm";
import Info from "./Info/Info";
import classes from "./ReceiveWarrants.module.scss";
import WarrantsWizardContext from "./ReceiveWarrantsContext.wizard";
import WarrantsFormData from "./WarrantsFormData";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.receiverSide");

type WarrantsForm = ThreeStepForm & {
  approvalKey: string;
};

const Context = () => {
  const navigate = useNavigate();

  const { invitedUser } = useStoreState((state) => state);
  const { setIsDarkTheme, setIsFooterShown, setHeaderContextShown } = useStoreActions((actions) => actions.app);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const { isLoading, warrantDetails } = WarrantsWizardContext.useStoreState((state) => state);
  const { getWarrantDetailsThunk, acceptWarrantThunk } = WarrantsWizardContext.useStoreActions((state) => state);

  const otherValues = useMemo<Pick<WarrantsForm, "approvalKey">>(() => {
    return {
      approvalKey: String(invitedUser.invitedUser?.approvalKey),
    };
  }, [invitedUser.invitedUser?.approvalKey]);

  const docLength = warrantDetails?.documentFiles ? warrantDetails?.documentFiles.length : 0;
  const { initialValues, validationSchema } = useStepForm(
    docLength,
    !!warrantDetails?.documentsNeedsSignature,
    otherValues
  );

  const submitHandler = useCallback<FormikConfig<WarrantsForm>["onSubmit"]>(
    async (values) => {
      try {
        const request = await acceptWarrantThunk({
          approvalKey: values.approvalKey,
          signature: values.signature || undefined,
        });

        if (request) {
          setInvitedUser(null);

          navigate(getPath(["user", "dashboard"]), { replace: true });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [acceptWarrantThunk, setInvitedUser, navigate]
  );

  useEffect(() => {
    if (!R.isNil(invitedUser) && !R.isNil(invitedUser.invitedUser) && !R.isNil(invitedUser?.invitedUser?.approvalKey)) {
      getWarrantDetailsThunk(invitedUser.invitedUser.approvalKey).catch(() => {
        navigate(getPath(["user", "dashboard"]), { replace: true });
      });
    }
  }, [invitedUser, getWarrantDetailsThunk, navigate]);

  useEffect(() => {
    setIsDarkTheme(true);
    setIsFooterShown(false);
    setHeaderContextShown(false);

    return () => {
      setIsDarkTheme(false);
      setIsFooterShown(true);
      setHeaderContextShown(true);
    };
  }, [setIsDarkTheme, setIsFooterShown, setHeaderContextShown]);

  return (
    <LoaderContainer loading={isLoading}>
      <PageContent className={classes["container"]} data-testid="receive-warrant-test-id">
        <H.s>{t("title")}</H.s>
        <Info />
        <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={validationSchema}>
          <WarrantsFormData />
        </Formik>
      </PageContent>
    </LoaderContainer>
  );
};

const ReceiveWarrantsWizard = () => {
  return (
    <WarrantsWizardContext.Provider>
      <Context />
    </WarrantsWizardContext.Provider>
  );
};

export default ReceiveWarrantsWizard;
