import { FC, useMemo } from "react";
import classNames from "classnames";
import { defaultTo, isNil } from "ramda";

import { P, Ui } from "common/components/atoms";
import { InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { getCurrentAgreementContext } from "../functions";
import classes from "./ExpiredInfo.module.scss";

type PropsTypes = JSX.IntrinsicElements["div"] & {
  type: InstrumentTypesNamesEnum;
};

/**
 * Description: components to represent status for expired agreement
 */

const t = createTranslation(TranslationNS.common, "organisms.agreement.expiredInfo");

const ExpiredInfo: FC<PropsTypes> = ({ className, type, ...props }) => {
  const { agreementDetails } = getCurrentAgreementContext(type).useStoreState((state) => state);

  // getting the reason property from agreement details to find the amount of clause for specific termination
  // API giving different reasons formatting and for this reason we need to modify different reasons in different ways
  const clauseReason = useMemo(
    () =>
      defaultTo(
        "",
        // passing away has by prefix in agreement details object
        agreementDetails?.terminationReason === "Passing Away" ? "by Passing Away" : agreementDetails?.terminationReason
      )
        .split(" ")
        .map((el, i) =>
          i > 0
            ? el
                .split("")
                .map((item, i) => (i === 0 ? item.toUpperCase() : item))
                .join("")
            : el
        )
        .join(" ")
        .replaceAll(" ", "")
        .split("")
        .reduce((acc, curr, i) => acc + (i === 0 ? curr.toLowerCase() : curr), ""),
    [agreementDetails?.terminationReason]
  );

  if (agreementDetails?.planStatusId !== PlanStatusesBasedOnAPIStatusId.expired) {
    return null;
  }

  // depends on selected reason - finding time duration (month, year, days) for termination
  const clauseReasonTimeUnit = (agreementDetails as any)?.[`${clauseReason}TimeUnit`] as string;

  return (
    <div className={classNames(classes["container"], className)} {...props}>
      <Ui.l bold className="mb-2">
        {t("expiredDate", {
          expiredDate: transformDateToCommonDateFormat(agreementDetails?.optionsExpirationDate),
        })}
      </Ui.l>

      <P.m className="mb-2">Termination date: {transformDateToCommonDateFormat(agreementDetails?.terminatedAt)}</P.m>

      <P.m className="mb-2">
        {t("expiredReason", {
          expiredReason: agreementDetails?.terminationReason,
        })}
      </P.m>

      {!agreementDetails?.excludePostTerminationClauses && !isNil(clauseReason) && !isNil(clauseReasonTimeUnit) ? (
        <P.m className="mb-2">
          {t("expiredClause", {
            expiredClause: "Post termination exercise clause",
            expiredAmount: `${
              !isNil(agreementDetails) && !isNil(clauseReason) && !isNil(clauseReasonTimeUnit)
                ? `${(agreementDetails as any)[clauseReason]} ${clauseReasonTimeUnit?.toLowerCase()}${
                    Number((agreementDetails as any)[clauseReason]) > 1 ? "s" : ""
                  }`
                : ""
            }`,
          })}
        </P.m>
      ) : null}

      {!isNil(agreementDetails?.terminationReasonComment) ? (
        <Ui.m italic className="mt-1">
          {agreementDetails?.terminationReasonComment}
        </Ui.m>
      ) : null}
    </div>
  );
};

export default ExpiredInfo;
