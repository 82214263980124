import { memo, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { headerText,usePlanStatus } from "common/plan/planUtils";
import { useStoreActions } from "store/store";

import PlanForms, { PlanFormType } from "../../../../wizards/create-plan/steps/basics/sidebar/forms/plan-form";
import ManagePlansContext from "../../../managePlansContext";

const EditPlans = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const statuses = usePlanStatus();

  const { getOwnershipPlansThunk } = useStoreActions((actions) => actions.company);
  const editPlanInfo = ManagePlansContext.useStoreState((state) => state.editPlanInfo);
  const setEditPlanInfo = ManagePlansContext.useStoreActions((actions) => actions.setEditPlanInfo);

  const planFormType = useMemo(() => {
    const planType = editPlanInfo?.programId ? "" : "OneOff";
    const instrumentType = editPlanInfo?.instrumentTypeId === InstrumentTypesIdsEnum.OPTION ? "SOPlan" : "RSAPlan";
    return `${planType}${instrumentType}` as PlanFormType;
  }, [editPlanInfo?.instrumentTypeId, editPlanInfo?.programId]);

  const handleEditPlanSave = useCallback(() => {
    getOwnershipPlansThunk(companyId ? +companyId : 0);
  }, [companyId, getOwnershipPlansThunk]);

  const title = headerText(planFormType, true, editPlanInfo?.representedBy);

  const handleClose = useCallback(() => {
    setEditPlanInfo(null);
  }, [setEditPlanInfo]);

  return (
    <SlidePanel show={!!editPlanInfo} onHide={handleClose}>
      <SlidePanel.Header
        tagText={statuses[editPlanInfo?.statusId || 0]?.label}
        tagVariant={statuses[editPlanInfo?.statusId || 0]?.variant}
        title={title}
        onHide={handleClose}
      />
      <PlanForms
        planId={editPlanInfo?.id}
        formType={planFormType}
        onSave={handleEditPlanSave}
        setIsSidebarOpen={handleClose}
      />
    </SlidePanel>
  );
};

export default memo(EditPlans);
