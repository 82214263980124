import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { useStoreActions, useStoreState } from "store/store";

const useOnboardCompanyIntegration = (withTransaction: boolean) => {
  const { companyId } = useParams<{ companyId?: string }>();

  const { setIntegration, getCompanyThunk } = useStoreActions((actions) => actions.companyOnboardingModel);
  const regular = useStoreState((state) => state.companyOnboardingModel.integrations?.regular);
  const company = useStoreState((state) => state.companyOnboardingModel.company);

  useEffect(() => {
    if (companyId && Number(companyId) !== regular?.companyId) {
      setIntegration({
        type: "all",
        data: null,
      });
    }
    if (companyId) {
      setIntegration({
        type: "regular",
        data: { companyId: Number(companyId), withTransaction },
      });
    }
  }, [companyId, regular?.companyId, setIntegration, withTransaction]);

  useEffect(() => {
    if ((companyId && !company?.companyId) || (companyId && Number(companyId) !== company?.companyId)) {
      getCompanyThunk(Number(companyId)).catch((e) => {
        console.log(e);
      });
    }
  }, [companyId, company?.companyId, getCompanyThunk]);
};

export default useOnboardCompanyIntegration;
