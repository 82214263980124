import { FC } from "react";
import { Modal } from "react-bootstrap";
import cn from "classnames";
import { isEmpty, isNil } from "ramda";

import { Button, H, P } from "common/components/atoms";

import classes from "../../stakeholders-management.module.scss";
import { Stakeholder } from "../../types";

type PropsTypes = {
  removeStakeholderId?: number;
  stakeholderToRemove?: Stakeholder;
  isStakeholdersLoading: boolean;
  handleRemoveModalClose: () => void;
  handleRemoveStakeholder: () => void;
};

const DeleteStakeholderModal: FC<PropsTypes> = ({
  removeStakeholderId,
  stakeholderToRemove,
  isStakeholdersLoading,
  handleRemoveModalClose,
  handleRemoveStakeholder,
}) => {
  return (
    <Modal centered show={!isNil(removeStakeholderId)} onHide={handleRemoveModalClose}>
      <div className={cn("p-5", classes["content"])}>
        <H.xs className="mb-3">Delete stakeholder</H.xs>
        <P.s>
          Are you sure you want to delete{" "}
          {!isNil(stakeholderToRemove?.companyName) && !isEmpty(stakeholderToRemove?.companyName)
            ? stakeholderToRemove?.companyName
            : `${stakeholderToRemove?.firstName} ${stakeholderToRemove?.lastName}`}{" "}
          as stakeholder?
        </P.s>

        <div className="d-flex justify-content-center mt-5">
          <Button
            variant="danger"
            className="me-2"
            isLoading={isStakeholdersLoading}
            isDisabled={isStakeholdersLoading}
            onClick={handleRemoveStakeholder}
          >
            Delete
          </Button>

          <Button
            variant="secondary"
            isLoading={isStakeholdersLoading}
            isDisabled={isStakeholdersLoading}
            onClick={handleRemoveModalClose}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteStakeholderModal;
