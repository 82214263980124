import { FC, useCallback, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

import { Button, ModalInfo, Ui } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDetailsContext } from "../../../stakeholder-details.context";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.shareSeriesTable.modals.removePledge");

type Props = {
  show: boolean;
  issuedSharesId?: number;
  handleClose?: () => void;
};
const RemovePledgedModal: FC<Props> = (props) => {
  return (
    <ModalInfo size="sm" show={props.show} header={t("removePledge")}>
      <PledgeForm {...props} />
    </ModalInfo>
  );
};

type PledgeFormProps = Props;

const PledgeForm: FC<PledgeFormProps> = (props) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const getDataFunction = StakeholderDetailsContext.useStoreState((state) => state.getDataFunction);

  const onSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      await axios.post("/api/equity-management/cap-table/remove-pledges", { issuedSharesId: props.issuedSharesId });
      props?.handleClose?.();
      getDataFunction?.();
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  }, [getDataFunction, props]);

  return (
    <div>
      <Modal.Body>
        <Ui.xl>{t("removePledgeDescription")}</Ui.xl>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" isLoading={isSubmitting} isDisabled={isSubmitting} onClick={onSubmit}>
          {t("remove")}
        </Button>
        <Button variant="secondary" isDisabled={isSubmitting} onClick={props.handleClose}>
          {t("cancel")}
        </Button>
      </Modal.Footer>
    </div>
  );
};

export default RemovePledgedModal;
