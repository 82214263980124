import { FC, MouseEventHandler } from "react";

import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { P } from "common/components/atoms/Typography";
import { createTranslation } from "translation/helpers";

interface SecurityPermissionsProps {
  open: boolean;
  handleClose: (withModalOpen?: boolean) => void;
}

const translation = createTranslation("pages", "companyProfile.stakeholders.securityPermissionsDetails");

const CompanyStakeholderForm: FC<SecurityPermissionsProps> = ({ open, handleClose }) => {
  return (
    <SlidePanel show={open} onHide={handleClose}>
      <SlidePanel.Header title={translation("title")} onHide={handleClose} />
      <SlidePanel.Section title={translation("owner.title")} isSingle>
        <div data-testid="member-form-container-test-id" className="d-flex flex-column team-form-container">
          <P.m className="mb-4">{translation("owner.description")}</P.m>

          <P.m bold>{translation("manager.title")}</P.m>

          <P.m className="mb-4">{translation("manager.description")}</P.m>

          <P.m bold className="mb-1">
            {translation("stakeholder.title")}
          </P.m>

          <P.m className="mb-4">{translation("stakeholder.description")}</P.m>
        </div>
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button variant="primary" onClick={handleClose as unknown as MouseEventHandler<HTMLButtonElement>}>
          {translation("goBack")}
        </Button>
      </SlidePanel.Actions>
    </SlidePanel>
  );
};

export default CompanyStakeholderForm;
