import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";

import OnboardCompanyRouter from "../onboard/company/OnboardCompanyRouter";
import ReceiveAccessControl from "./receive-access-control/ReceiveAccessControl";
import ReceiveConvertibleLoanWizard from "./receive-convertible-loan/ReceiveConvertibleLoan.wizard";
import ReceiveConvertibleNoteWizard from "./receive-convertible-note/ReceiveConvertibleNote.wizard";
import ReceiveIssueSharesWizard from "./receive-issue-shares/ReceiveIssueShares.wizard";
import ReceiveRsaPlanWizard from "./receive-plan/RSA-plan/receive-rsa-plan-wizard";
import ReceiveSoPlanWizard from "./receive-plan/SO-plan/receive-so-plan-wizard";
import ReceivePlanManager from "./receive-plan-manager/ReceivePlanManager.wizard";
import ReceiveSettleWarrantWizard from "./receive-settle-warrant/ReceiveSettleWarrant.wizard";
import StakeholderReceiver from "./receive-stakeholder/StakeholderReceiver";
import ReceiveTeam from "./receive-team/ReceiveTeam";
import ReceiveWarrantsWizard from "./receive-warrant/ReceiveWarrants.wizard";

const ReceivingInvitationsRouter: FC = () => {
  return (
    <Routes>
      <Route path={ROUTER_V2.receive.rsaPlan} element={<ReceiveRsaPlanWizard />} />
      <Route path={ROUTER_V2.receive.soPlan} element={<ReceiveSoPlanWizard />} />
      <Route path={ROUTER_V2.receive.planManager} element={<ReceivePlanManager />} />
      <Route path={ROUTER_V2.receive.warrant} element={<ReceiveWarrantsWizard />} />
      <Route path={ROUTER_V2.receive.warrantSettlement} element={<ReceiveSettleWarrantWizard />} />
      <Route path={ROUTER_V2.receive.issueShares} element={<ReceiveIssueSharesWizard />} />
      <Route path={ROUTER_V2.receive.convertibleLoan} element={<ReceiveConvertibleLoanWizard />} />
      <Route path={ROUTER_V2.receive.convertibleNote} element={<ReceiveConvertibleNoteWizard />} />
      <Route path={ROUTER_V2.receive.companyAccess} element={<ReceiveAccessControl />} />
      <Route path={ROUTER_V2.receive.team} element={<ReceiveTeam />} />
      <Route path={ROUTER_V2.receive.stakeholder} element={<StakeholderReceiver />} />
      <Route path={ROUTER_V2.receive.company} element={<OnboardCompanyRouter />} />
    </Routes>
  );
};

export default ReceivingInvitationsRouter;
