import { Theme } from "@nivo/core";

import { scssVariables } from "common/utils/constants";

export default {
  background: "transparent",
  fontFamily: scssVariables.fontFamilyBase,
  textColor: scssVariables.foregroundMedium,
  fontSize: parseInt(scssVariables.uiXXS),
  crosshair: {
    line: {
      stroke: scssVariables.positive500,
      strokeWidth: 1,
      strokeDasharray: "2",
    },
  },
  axis: {
    domain: {
      line: {
        stroke: scssVariables.element1,
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        strokeWidth: 0,
      },
    },
  },
  grid: {
    line: {
      strokeWidth: 0,
    },
  },
  tooltip: {
    container: {
      background: "transparent",
      color: scssVariables.positive500,
      boxShadow: "none",
    },
    basic: {},
    chip: {},
    table: {},
    tableCell: {},
    tableCellValue: {},
  },
} as Theme;
