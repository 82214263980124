import { ChangeEvent, FC, useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import classNames from "classnames";
import { useFormikContext } from "formik";

import Helper from "common/components/atoms/Helper/Helper";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { ShareClassFormData } from "common/shareClass/types";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { P, Ui } from "../../../components/atoms";
import classes from "../../../program/components/ProgramForm/VestingDetails/VestingDetails.module.scss";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.shareClassTypeField");

interface ShareClassTypeFieldProps extends FormGroupProps {
  changeCallback?: (value: number | string) => void;
}
const ShareClassTypeField: FC<ShareClassTypeFieldProps> = (props) => {
  const { changeCallback } = props;
  const shareClassTypesState = useStoreState((state) => state.common.dropdowns?.shareClassTypes || []);

  const { values, setFieldValue } = useFormikContext<Pick<ShareClassFormData, "shareClassTypeId">>();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.name, +e.target.value);
      if (changeCallback) {
        changeCallback(+e.target.value);
      }
    },
    [changeCallback, setFieldValue]
  );

  useEffect(() => {
    if (changeCallback && shareClassTypesState.length && !values.shareClassTypeId) {
      setFieldValue("shareClassTypeId", shareClassTypesState[0]?.id);
      if (changeCallback) {
        changeCallback(shareClassTypesState[0]?.id);
      }
    }
  }, [shareClassTypesState, setFieldValue, changeCallback, values.shareClassTypeId]);

  return (
    <FormGroup controlId="shareClassTypeId" {...props}>
      <Helper>
        <InputHeader label={t("label")} />
        {shareClassTypesState.map((el) => (
          <div
            className={classNames(classes.vestingType, { [classes.selected]: +values.shareClassTypeId === el.id })}
            key={el.id}
          >
            <Form.Check className={classes.vestingTypeRadio} type="radio" id={`vestType_${el.id}`}>
              <Form.Check.Input
                type="radio"
                name="shareClassTypeId"
                value={el.id}
                onChange={handleChange}
                checked={+values.shareClassTypeId === el.id}
              />
              <div>
                <Form.Check.Label>
                  <Ui.m tag="span" color="foregroundHigh" style={{ fontWeight: 500 }}>
                    {el.name}
                  </Ui.m>
                </Form.Check.Label>
                <P.s color="foregroundLow" style={{ marginTop: 2, cursor: "default" }}>
                  {el.description}
                </P.s>
              </div>
            </Form.Check>
          </div>
        ))}
      </Helper>
    </FormGroup>
  );
};

export default ShareClassTypeField;
