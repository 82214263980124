import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { MsalProvider } from "@azure/msal-react";
import { StoreProvider } from "easy-peasy";

import App from "app/App";
import authProvider from "common/authentication/authProvider";
import Spinner from "common/components/atoms/Spinner/Spinner";
import store from "store/store";

import "common/declaration/declaration.d";
import "common/authentication/axiosConfig";
import "./index.scss";
import "./scss/setup.scss";
import "./translation/setup";

import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
  <StrictMode>
    <StoreProvider store={store}>
      <Suspense fallback={<Spinner />}>
        <MsalProvider instance={authProvider}>
          <App />
        </MsalProvider>
      </Suspense>
    </StoreProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
