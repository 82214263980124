import { FC } from "react";
import { useFormikContext } from "formik";
import * as R from "ramda";

import TextField, { TextFieldProps } from "common/components/atoms/TextField/TextField";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { PoolFormValues } from "../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";
import classes from "./styles.module.scss";

export type AuthorizedSharesFieldProps = TextFieldProps & Partial<Record<"totalNumberOfShares", number>>;

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.authorizedSharesField");

const PoolAuthorizedSharesField: FC<AuthorizedSharesFieldProps> = ({ label, totalNumberOfShares, ...props }) => {
  const fNumber = useFormatNumbers();
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<Pick<PoolFormValues, "numberOfShares">>();

  return (
    <TextField
      type="number"
      label={t("label")}
      isTouched={touched.numberOfShares}
      error={errors.numberOfShares}
      value={values.numberOfShares}
      className={classes["authorized-input"]}
      name="numberOfShares"
      onChange={handleChange}
      onBlur={handleBlur}
      info={t("info", {
        percentage: fNumber(
          (R.defaultTo(0, Number(values.numberOfShares)) / R.defaultTo(1, totalNumberOfShares)) * 100,
          "percent"
        ),
      })}
      {...props}
    />
  );
};

export default PoolAuthorizedSharesField;
