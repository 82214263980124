import { ChangeEvent, FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { useFormikContext } from "formik";

import Check from "common/components/atoms/Checks/Check";
import Helper from "common/components/atoms/Helper/Helper";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData, fields } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.sharesDetails");

const ProgramTypeField: FC = () => {
  const instrumentTypesStore = useStoreState((state) => state.common.dropdowns?.instrumentTypes || []);

  const { values, handleBlur, setFieldValue, setFieldTouched } = useFormikContext<CreateProgramFormData>();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setFieldValue(e.target.name, Number(e.target.value)).then();
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (values.programTypeId === InstrumentTypesIdsEnum.RSA) {
      setFieldValue(fields.exercisePrice, undefined).then(() => {
        setFieldValue(fields.optionsExpiration, undefined).then();
      });
      setFieldTouched(fields.exercisePrice, false);
      setFieldTouched(fields.optionsExpiration, false);
    }
  }, [setFieldTouched, setFieldValue, values.programTypeId]);

  const answer = useMemo(() => {
    return {
      render: () =>
        t.el("programTypeDescription", {
          components: [
            <Fragment key={1}>
              <br />
              <br />
            </Fragment>,
            <strong key={2} />,
            <br key={3} />,
          ],
        }),
    };
  }, []);

  return (
    <Form.Group className="mt-4">
      <Helper.Question questionId="programTypeId" answer={answer}>
        <InputHeader label={t("programTypeId.label")} />
      </Helper.Question>
      <Helper.Answer className="mt-1" answerId="programTypeId" />

      <div className="mt-2">
        {instrumentTypesStore
          .slice()
          .sort((a, b) => a.id - b.id)
          .map((item) => (
            <Check
              key={item.id}
              label={item.name}
              value={item.id}
              checked={item.id === values.programTypeId}
              onChange={handleChange}
              onBlur={handleBlur}
              name={fields.programTypeId}
              type="radio"
            />
          ))}
      </div>
    </Form.Group>
  );
};
export default ProgramTypeField;
