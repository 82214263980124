import { FC, SyntheticEvent, useCallback, useMemo } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import InputFeedback from "common/components/atoms/input-blocks/input-feedback/input-feedback";
import { H, P, Ui } from "common/components/atoms/Typography";
import { LinkIcon } from "common/icons/svg";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import classes from "./step.module.scss";
import { ThreeStepForm } from "./useStepForm";

const t = createTranslation(TranslationNS.common, "receive.stakeholder");

export type ReadAndAcceptDocumentType = {
  documentTypeId: number;
  downloadId: string;
  fileName: string;
  id?: number;
  label?: string;
  isSelected?: boolean;
};

export type ReadAcceptDocumentsProps = {
  documents: ReadAndAcceptDocumentType[];
  documentsCallback?: (documents: ReadAndAcceptDocumentType[]) => void;
  actionsStart?: () => void;
  actionsEnd?: () => void;
  noticeText?: string;
  isSingleSelection?: boolean;
  className?: string;
  title?: string;
  description?: string;
};

const DOCUMENT_AGREEMENT_ID = 4;

const DocumentsStep: FC<ReadAcceptDocumentsProps> = ({ documents, className, title, description }) => {
  const sortedDocuments = useMemo(() => {
    return documents.sort((a, b) => {
      if (a.documentTypeId === DOCUMENT_AGREEMENT_ID && b.documentTypeId !== DOCUMENT_AGREEMENT_ID) {
        return -1; // a comes before b
      } else if (a.documentTypeId !== DOCUMENT_AGREEMENT_ID && b.documentTypeId === DOCUMENT_AGREEMENT_ID) {
        return 1; // b comes before a
      }
      return 0; // a and b are the same
    });
  }, [documents]);

  const { handleChange, values, handleBlur, errors, touched } = useFormikContext<ThreeStepForm>();

  const DocumentView = useCallback(({ downloadId, fileName }: { downloadId: string; fileName: string }) => {
    const openDoc = (e: SyntheticEvent) => {
      e.preventDefault();
      openRemoteDocument(downloadId);
    };
    return (
      <div>
        <a key={1} target="_blank" rel="noreferrer" href="#" onClick={openDoc}>
          {fileName}
          <span className="ms-1">
            <LinkIcon />
          </span>
        </a>
        &nbsp;
      </div>
    );
  }, []);

  return (
    <div
      className={cn("d-flex py-3 px-4", classes["borders"], className, {
        "is-invalid": touched?.documents && errors.documents,
      })}
    >
      <Ui.l className="pt-half me-3">2.</Ui.l>
      <div>
        <H.xxs className="mb-1">{title ? title : t("steps.acceptDocuments.title")}</H.xxs>
        <P.s className="mb-4">{description}</P.s>

        {!documents.length && <div>There is no documents attached</div>}
        {sortedDocuments.map((el, index) => {
          return (
            <ChecksGroup key={el.downloadId}>
              <ChecksGroup.Check
                className={cn({
                  [classes.isGreen]: values.documents[index],
                })}
                name={`documents[${index}]`}
                checked={values?.documents[index]}
                label={
                  <DocumentView
                    fileName={el.documentTypeId !== DOCUMENT_AGREEMENT_ID ? el.fileName : t("checkbox1.title")}
                    downloadId={el.downloadId}
                  />
                }
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <InputFeedback
                isTouched={(touched?.documents as any)?.[index]}
                error={(errors?.documents as any)?.[index]}
              />
            </ChecksGroup>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsStep;
