import { Action, action, createContextStore } from "easy-peasy";

import { ShareholderSelectedTabOptions } from "./components/Tabs";
import { CapTableStakeholderDetailsType } from "./types";

interface ContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  details: CapTableStakeholderDetailsType | null;
  setDetails: Action<this, this["details"]>;

  closeFunction: null | Function;
  setCloseFunction: Action<this, this["closeFunction"]>;

  getDataFunction: null | Function;
  setGetDataFunction: Action<this, this["getDataFunction"]>;

  selectedTab: ShareholderSelectedTabOptions | null;
  setSelectedTab: Action<this, this["selectedTab"]>;
}

export const StakeholderDetailsContext = createContextStore<ContextModel>({
  isLoading: false,
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),

  details: null,
  setDetails: action((state, payload) => {
    state.details = payload;
  }),

  closeFunction: null,
  setCloseFunction: action((state, payload) => {
    state.closeFunction = payload;
  }),

  getDataFunction: null,
  setGetDataFunction: action((state, payload) => {
    state.getDataFunction = payload;
  }),

  selectedTab: null,
  setSelectedTab: action((state, payload) => {
    state.selectedTab = payload;
  }),
});
