import { FC, useCallback, useEffect, useState } from "react";
import {
  FilterFnOption,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Row,
  useReactTable,
} from "@tanstack/react-table";
import { FormikErrors, useFormikContext } from "formik";
import { defaultTo } from "ramda";

import { Button, P } from "common/components/atoms";
import { ImportErrorHandler, ImportTable } from "common/components/atoms/ImportTable";
import { manageRow } from "common/components/atoms/ImportTable/ImportTableHelper";
import StickyActions from "common/components/atoms/ImportTable/StickyActions/StickyActions";
import useCompanyRemainingStakeholders from "common/hooks/useCompanyRemainingStakeholders";
import { CheckIcon, InformationCircleIcon, PlusIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../import-ownership/OwnershipTable/OwnershipTable.module.scss";
import { OwnershipFormValues } from "../../import-ownership/useImportOwnershipForm";
import useFoundersColumns from "./useFoundersColumns";

const t = createTranslation(TranslationNS.pages, "onboard.company.importFounders");

export type FoundersFormValues = Omit<OwnershipFormValues, "dateOfOwnership" | "shareClassName">;

type TableProps = FoundersFormValues & { checkbox?: boolean };

type Props = {
  initialItems: FoundersFormValues[];
  showStakeholdersLimitModal(): void;
};

const FoundersTable: FC<Props> = ({ initialItems, showStakeholdersLimitModal }) => {
  const company = useStoreState((state) => state.companyOnboardingModel.company);
  const { maxNumberOfStakeholders } = useCompanyRemainingStakeholders(undefined, false);

  const { values, setValues, isSubmitting, isValid } = useFormikContext<TableProps[]>();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [listErrors, setListErrors] = useState<FormikErrors<TableProps[]>>([]);
  const [globalFilter, setGlobalFilter] = useState("");

  const { columns, countOfActiveCheckboxes } = useFoundersColumns();

  const errorFilterData: FilterFnOption<FoundersFormValues> = useCallback(
    (row: Row<FoundersFormValues>) => {
      return !!listErrors[Number(row?.id)];
    },
    [listErrors]
  );

  const updateData = useCallback(
    (rowIndex: number, type: string) => {
      setValues(
        manageRow(values, rowIndex, type, initialItems[0], ["stakeholderId", "shareholderId", "issuedShareId"])
      );
    },
    [initialItems, setValues, values]
  );

  const table = useReactTable({
    data: values,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: false,
    columnResizeMode: "onChange",
    enableColumnResizing: true,
    meta: { updateData, footerShow: true },
    state: {
      globalFilter: globalFilter,
    },
    globalFilterFn: errorFilterData,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const handleAddNewRow = useCallback(() => {
    if (maxNumberOfStakeholders && maxNumberOfStakeholders < values.length) {
      showStakeholdersLimitModal();
    } else {
      setValues([...values, { ...initialItems[0] }]);
    }
  }, [initialItems, setValues, showStakeholdersLimitModal, maxNumberOfStakeholders, values]);

  useEffect(() => {
    if (isSubmitting) {
      setShowErrorMessage(!isValid);
    }
  }, [isSubmitting, isValid]);

  return (
    <>
      <div className={classes.content}>
        {showErrorMessage && (
          <ImportErrorHandler
            updateErrorCallback={setListErrors}
            isFilterActive={!!globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        <ImportTable table={table} tableType="founders" />

        <div className="my-2 d-flex align-items-center p-2" style={{ backgroundColor: scssVariables.element2 }}>
          <InformationCircleIcon className="me-2" color={scssVariables.foregroundLow} />

          <P.s>{t("infoDescription")}</P.s>
        </div>

        {values?.reduce((acc, curr) => acc + defaultTo(0, Number(curr.numberOfShares)), 0) ===
        company?.numberOfShares ? (
          <div className="mt-2 mb-5">
            <div
              className="d-flex align-items-center p-2 w-max-content rounded-2"
              style={{ backgroundColor: scssVariables.positive050 }}
            >
              <CheckIcon className="me-2" /> <P.s>{t("sharesAllocated")}</P.s>
            </div>
          </div>
        ) : null}

        <div className={classes.bottomActions}>
          {!globalFilter ? (
            <Button isDisabled={!!globalFilter} onClick={handleAddNewRow} iconLeft={<PlusIcon />} variant="secondary">
              {t("add")}
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
      <StickyActions countOfActiveCheckboxes={countOfActiveCheckboxes} descriptionContent={t("tooltipDescription")} />
    </>
  );
};

export default FoundersTable;
