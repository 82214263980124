import { FC, useMemo } from "react";

import { sortCaptableData } from "common/utils/functions";

import { IssueEquityWarrantsDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import { WarrantsTableSortValue } from "./WarrantsTable";
import WarrantsTableRow from "./WarrantsTableRow";

type Props = {
  sortedValue: WarrantsTableSortValue;
  warrants?: IssueEquityWarrantsDTO[];
};

const WarrantsTableBody: FC<Props> = ({ sortedValue, warrants }) => {
  const sortedData = useMemo(() => {
    return sortCaptableData({
      data: warrants || [],
      sortedField: sortedValue.field,
      sortType: sortedValue.type,
    });
  }, [sortedValue, warrants]);

  return (
    <tbody className={classes.body}>
      {sortedData?.map((el) => (
        <WarrantsTableRow key={el.id} item={el} />
      ))}
    </tbody>
  );
};

export default WarrantsTableBody;
