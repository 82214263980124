import { FC } from "react";
import { Form } from "formik";

import Helper from "common/components/atoms/Helper/Helper";
import DocumentStatusField from "common/program/components/ProgramForm/DocumentStatusField";

import ProgramHelper from "../../../components/ProgramHelper";
import ProgramApprovalDateField from "../../../programFields/ProgramApprovalDateField";
import ProgramFileDataField from "../../../programFields/ProgramFileDataField";
import ProgramSigningManagerField from "../../../programFields/ProgramSigningManagerField";
import FormButtons from "./FormButton";

const FormContent: FC<{ programId: string }> = () => {
  return (
    <Form>
      <Helper>
        <div className="mb-4">
          <ProgramFileDataField />
        </div>

        <DocumentStatusField className="mb-5" />

        <ProgramHelper className="mb-5" questionId={"approvalDate"} type="input">
          <ProgramApprovalDateField />
        </ProgramHelper>

        <ProgramHelper className="mb-5" questionId={"signingManager"} colInputSize={6}>
          <ProgramSigningManagerField />
        </ProgramHelper>

        <FormButtons />
      </Helper>
    </Form>
  );
};

export default FormContent;
