import axios, { AxiosResponse } from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { InvestmentEntity } from "store/types";

import { PostDeclinePlan, ReceiveRsaPlan } from "../common/types";

export type AcceptPlan = {
  signature?: File;
  approvalKey: string;
  planId: number;
};

interface ReceiveOwnershipContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, boolean>;

  isPlanInfoCorrect: boolean;
  setIsPlanInfoCorrect: Action<this, this["isPlanInfoCorrect"]>;

  companyName: string;
  setCompanyName: Action<this, string>;

  selectedCompany: InvestmentEntity | null;
  setSelectedCompany: Action<this, this["selectedCompany"]>;

  isRemoteCompanySelected: boolean;
  setIsRemoteCompanySelected: Action<this, boolean>;

  companyOrgNumber: string;
  setCompanyOrgNumber: Action<this, string>;

  isHoldingCompanySelected: boolean;
  setIsHoldingCompanySelected: Action<this, boolean>;

  signature: File | null;
  setSignature: Action<this, File | null>;

  planDetails: ReceiveRsaPlan | null;
  setPlanDetails: Action<this, ReceiveRsaPlan | null>;

  getPlanDetailsThunk: Thunk<this, string, null, {}, Promise<AxiosResponse<ReceiveRsaPlan>>>;
  acceptPlanThunk: Thunk<this, AcceptPlan>;
  declinePlanThunk: Thunk<this, PostDeclinePlan>;
}

const apiBase = "/api/ownership/plan/rsa";

const ReceiveRsaPlanContext = createContextStore<ReceiveOwnershipContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    isPlanInfoCorrect: false,
    setIsPlanInfoCorrect: action((state, payload) => {
      state.isPlanInfoCorrect = payload;
    }),

    companyName: "",
    setCompanyName: action((state, payload) => {
      state.companyName = payload;
    }),

    selectedCompany: null,
    setSelectedCompany: action((state, payload) => {
      state.selectedCompany = payload;
    }),

    isRemoteCompanySelected: false,
    setIsRemoteCompanySelected: action((state, payload) => {
      state.isRemoteCompanySelected = payload;
    }),

    companyOrgNumber: "",
    setCompanyOrgNumber: action((state, payload) => {
      state.companyOrgNumber = payload;
    }),

    signature: null,
    setSignature: action((state, payload) => {
      state.signature = payload;
    }),

    isHoldingCompanySelected: false,
    setIsHoldingCompanySelected: action((state, payload) => {
      state.isHoldingCompanySelected = payload;
    }),

    planDetails: null,
    setPlanDetails: action((state, payload) => {
      state.planDetails = payload;
    }),

    getPlanDetailsThunk: thunk(async (actions, key) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<ReceiveRsaPlan>(`${apiBase}/invitation-details/${key}`);

        if (request.status === 200) {
          actions.setPlanDetails(request.data);

          actions.setIsHoldingCompanySelected(request.data.stakeholder.usingHoldingCompany);
        }
        return request;
      } catch (e) {
        return Promise.reject(e);
      } finally {
        actions.setIsLoading(false);
      }
    }),

    acceptPlanThunk: thunk(async (_, payload) => {
      try {
        const formData = new FormData();
        formData.append("approvalKey", String(payload.approvalKey));
        formData.append("planId", String(payload.planId));
        if (payload.signature) {
          formData.append("signature", payload.signature);
        }

        return await axios.post(`${apiBase}/accept-plan-invitation`, formData);
      } catch (e) {
        return Promise.reject(e);
      }
    }),
    declinePlanThunk: thunk(async (_, payload) => {
      try {
        return await axios.post(`${apiBase}/decline`, payload);
      } catch (e) {
        return Promise.reject(e);
      }
    }),
  },
  {
    name: "Receive RSA plan",
  }
);

export default ReceiveRsaPlanContext;
