import { FC, useMemo } from "react";
import { Point } from "@nivo/line";
import cn from "classnames";
import { format } from "date-fns";
import { concat, isNil, pluck } from "ramda";

import { LinearChart, Ui } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { sortValuationData, transformChartAxisToYearsOrQuarters } from "common/helpers";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { FilledArrowDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { getLeftPaddingValueForChartLabel } from "common/utils/functions";
import { CommonOwnershipPlanDetails } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Valuation.module.scss";

type TooltipPropsTypes = {
  point: Point;
};

const Tooltip: FC<TooltipPropsTypes> = ({ point }) => {
  const fNumber = useFormatNumbers();

  return (
    <div className={classes.tooltip}>
      <Ui.xs bold>{fNumber(Number(point.data.y), "value")}</Ui.xs>
      <div className={classes.triangle}>
        <FilledArrowDownIcon width={25} height={10} stroke="white" direction="bottom" />
      </div>
    </div>
  );
};

const t = createTranslation(TranslationNS.common, "organisms.agreement.valuationChart");

type PropsTypes = {
  agreement?: CommonOwnershipPlanDetails | null;
};

const ValuationChart: FC<PropsTypes> = ({ agreement }) => {
  const { currencyName } = useCurrency();
  const fNumber = useFormatNumbers();

  const data = useMemo(() => {
    if (agreement) {
      if (agreement.valuationDataPoints.length === 1) {
        const initialPoint = {
          x: agreement.planStartDate,
          y:
            (agreement.instrumentTypeId === InstrumentTypesIdsEnum.RSA
              ? agreement.purchasePrice
              : agreement.exercisePrice) * agreement.numberOfShares,
        };

        return [
          {
            id: t("valuation"),
            color: scssVariables.additional6500,
            chartBgColor: scssVariables.element2,
            data: sortValuationData(concat([initialPoint], agreement.valuationDataPoints)),
          },
        ];
      }
      return [
        {
          id: t("valuation"),
          color: scssVariables.additional6500,
          chartBgColor: scssVariables.element2,
          data: sortValuationData(agreement.valuationDataPoints),
        },
      ];
    }

    return [];
  }, [agreement]);


  const bottomValues = useMemo(() => {
    return (
      data &&
      transformChartAxisToYearsOrQuarters({
        data: data?.[0]?.data || [],
      })
    );
  }, [data]);

  if (data?.[0]?.data?.length < 2) return null;


  return (
    <div className={cn("mb-3", classes.valuation)}>
      <Ui.xs className={classes["chart-title"]}>{currencyName}</Ui.xs>
      <Ui.l bold className="mb-5">
        {t("historicValueForShares")}
      </Ui.l>

      <div className={classes.legend}>
        <Ui.xs
          style={{
            color: scssVariables.foregroundMedium,
          }}
        >
          {t.el("historicValueDescription", {
            values: {
              shares: fNumber(agreement?.totalShares, "amount"),
            },
            components: [<strong key="1" />],
          })}
        </Ui.xs>
      </div>

      {!isNil(data) && data?.[0]?.data?.length && (
        <LinearChart
          dashedLines
          height={250}
          gridYValues={4}
          showPointOnHover
          data={data || []}
          chartType="linear"
          axisLeft={{
            tickSize: 0,
            tickValues: 5,
            tickPadding: 5,
            format: (value) => fNumber(value, "amount"),
          }}
          axisRight={null}
          margin={{
            left: getLeftPaddingValueForChartLabel(pluck("y", agreement?.valuationDataPoints || []) as number[]),
            right: 30,
            top: 10,
            bottom: 70,
          }}
          tooltip={Tooltip}
          axisBottom={{
            tickSize: 0,
            tickValues: 1,
            renderTick: (tick) => {
              const isTickVisible = bottomValues?.includes(tick.value);

              return isTickVisible ? (
                <foreignObject width={80} height={15} x={tick.x} y={tick.y + 30}>
                  <Ui.xs style={{ color: scssVariables.foregroundLow }}>
                    {format(new Date(tick.value), "MMM dd, yyyy")}
                  </Ui.xs>
                </foreignObject>
              ) : (
                <></>
              );
            },
          }}
        />
      )}
    </div>
  );
};

export default ValuationChart;
