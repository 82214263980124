import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useStoreActions, useStoreState } from "store/store";

import { getPath } from "../Router/RouterHelper";

const useOnboardingMiddleware = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useStoreState((state) => state.authentication);
  const { setRedirectUrl } = useStoreActions((actions) => actions.app);
  const { redirectUrl } = useStoreState((state) => state.app);
  const userStore = useStoreState((state) => state.account.user);

  useEffect(() => {
    if (location.pathname.includes("/invitation/")) {
      setRedirectUrl(location.pathname);
    }
  }, [location.pathname, setRedirectUrl]);

  useEffect(() => {
    if (redirectUrl && redirectUrl !== location.pathname && isAuthenticated) {
      navigate(redirectUrl);
    } else if (!redirectUrl && userStore && !userStore.isOnboarded && location.pathname != "/unimicro-landing") {
      navigate(getPath(["onboard", "user"]));
    }
  }, [isAuthenticated, location.pathname, navigate, redirectUrl, setRedirectUrl, userStore]);
};

export default useOnboardingMiddleware;
