import { FC, ReactNode } from "react";
import classNames from "classnames";

import { Ui } from "common/components/atoms";
import { WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./CommonStyles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.documents");

type Props = {
  label?: string;
  actions?: ReactNode;
  iconClassName?: string;
  isRequired?: boolean;
  className?: string;
};

const EmptyDocumentsRow: FC<Props> = ({ actions, className, label, iconClassName, isRequired }) => {
  return (
    <tr className={classNames(classes["table-row"], className)}>
      <td>
        {!isRequired ? (
          <Ui.s className={classNames("fw-400", iconClassName)} style={{ color: scssVariables.foregroundLow }}>
            {t("emptyPlaceholder")}
          </Ui.s>
        ) : (
          <div className={classNames("d-flex align-items-center w-100", classes["missing-documents"])}>
            <WarningIcon className={iconClassName} width={24} height={24} />

            <Ui.m className="ms-3 fw-400">
              {label ? label : isRequired ? t("documentsMissing") : t("emptyPlaceholder")}
            </Ui.m>

            {isRequired && actions ? actions : null}
          </div>
        )}
      </td>
    </tr>
  );
};

export default EmptyDocumentsRow;
