import { FC } from "react";

import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import DocumentToggleElement from "common/components/atoms/MenuToggleElement/DocumentToggleElement";
import { P, Ui } from "common/components/atoms/Typography";
import { ChevronDownIcon, FileIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { remoteDocumentType } from "common/types/documents";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./DocumentList.module.scss";

const t = createTranslation(TranslationNS.common, "molecules.documentList");

const DocumentList: FC<{
  className?: string;
  documents: remoteDocumentType[];
  cbAfterAction?: () => void;
  onEdit?: () => void;
  isDeleting?: boolean;
}> = ({ className, documents, onEdit, cbAfterAction, isDeleting = true }) => {
  return (
    <CollapsibleContainer defaultOpen withoutDivider className={className} Header={DocumentListHeader}>
      <div className={classes["container"]}>
        {!documents.length ? (
          <div className={classes["empty"]}>
            <div>
              <P.l style={{ color: scssVariables.foregroundHigh }}>{t("emptyTitle")}</P.l>

              <P.s className="mt-2" style={{ color: scssVariables.foregroundMedium }}>
                {t("emptyDescription")}
              </P.s>
            </div>
            <div className={classes["empty-items"]}>
              <div className="mb-2">
                <FileIcon className="me-1" />

                <div className={classes["empty-section"]} />
              </div>

              <div className="mb-2">
                <FileIcon className="me-1" />

                <div className={classes["empty-section"]} />
              </div>

              <div>
                <FileIcon className="me-1" />

                <div className={classes["empty-section"]} />
              </div>
            </div>
          </div>
        ) : null}

        {documents.map((el) => (
          <div key={el.downloadId} className={classes["document-item"]}>
            <div className="d-flex align-items-center">
              <FileIcon height={24} width={24} />
              <Ui.m bold className="ms-2">
                {el.fileName}
              </Ui.m>
            </div>

            <DocumentToggleElement isDeleting={isDeleting} el={el} onEdit={onEdit} cbAfterAction={cbAfterAction} />
          </div>
        ))}
      </div>
    </CollapsibleContainer>
  );
};

const DocumentListHeader = ({ activeEventKey, onClick }: CollapsibleHeaderProps) => (
  <div className={classes["header"]} onClick={onClick}>
    <Ui.l bold>{t("title")}</Ui.l>

    <ChevronDownIcon
      fontSize={16}
      color={scssVariables.foregroundMedium}
      direction={activeEventKey ? "top" : "bottom"}
    />
  </div>
);

export default DocumentList;
