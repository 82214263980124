import { FC, useCallback, useMemo, useState } from "react";

import { AccessLevels } from "common/access-control/types";
import Table from "common/components/atoms/Table/Table";
import Tabs from "common/components/atoms/Tabs/Tabs";

import { FeaturesListGetDTO } from "../../../types";
import classes from "./features-table.module.scss";
import TableBody from "./table-body";
import TableHeader from "./table-head";

type PropsTypes = {
  features: FeaturesListGetDTO;
  onChangeAccessLevel: (featureCategory: string, featureId: number, accessLevel: AccessLevels) => void;
};

const FeaturesTable: FC<PropsTypes> = ({ features, onChangeAccessLevel }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const featuresCategories = useMemo(
    () => Object.keys(features).map((featureCategoriesNames) => featureCategoriesNames),
    [features]
  );

  const handleChangeAccessLevel = useCallback(
    (featureId: number, accessLevel: AccessLevels) => {
      onChangeAccessLevel(featuresCategories[selectedTab], featureId, accessLevel);
    },
    [featuresCategories, selectedTab, onChangeAccessLevel]
  );

  return (
    <div className={classes["access-table"]}>
      <Tabs tabs={featuresCategories} activeTab={selectedTab} selectTab={setSelectedTab} />
      <Table
        className="mt-6"
        headComponent={<TableHeader />}
        bodyComponent={
          <TableBody
            featuresCategory={Object.values(features)[selectedTab]}
            onChangeAccessLevel={handleChangeAccessLevel}
          />
        }
      />
    </div>
  );
};

export default FeaturesTable;
