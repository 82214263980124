import { FC, useCallback, useEffect, useState } from "react";
import { Formik } from "formik";
import { defaultTo, isNil } from "ramda";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import classes from "./ConvertNotePanel.module.scss";
import OverrideSharePriceModal from "./OverrideSharePriceModal";
import OverrideSharesModal from "./OverrideSharesModal";
import Actions from "./sections/Actions";
import Conversion from "./sections/Conversion";
import Documentation from "./sections/Documentation";
import Info from "./sections/Info";
import useConvertNoteForm, { ConvertNoteFormValues } from "./useConvertNoteForm";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertNote");

const ConvertNoteForm: FC = () => {
  const { companyId } = useStoreState((state) => state.activeCompanyModel);

  const { noteId } = IssueEquityContext.useStoreState((state) => state);
  const numberOfNoteShares = IssueEquityContext.useStoreState((state) => state.numberOfNoteShares);
  const noteSharePrice = IssueEquityContext.useStoreState((state) => state.noteSharePrice);
  const calculatedConvertNote = IssueEquityContext.useStoreState((state) => state.calculateCN);

  const {
    getConversionDetailsCNThunk,
    setNoteSharePrice,
    convertCNThunk,
    setIsConvertNoteEditPanelOpen,
    getIssueEquityThunk,
  } = IssueEquityContext.useStoreActions((actions) => actions);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (noteId) {
      setLoading(true);
      getConversionDetailsCNThunk(noteId)
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
    }
  }, [noteId, getConversionDetailsCNThunk]);

  const handleSubmit = useCallback(
    async (values: ConvertNoteFormValues) => {
      try {
        let formValues = values;

        if (!isNil(noteSharePrice) || numberOfNoteShares !== defaultTo(0, calculatedConvertNote?.numberOfNewShares)) {
          formValues = { ...formValues, overrideSharesCalculation: true };
        }

        if (!isNil(noteSharePrice)) {
          formValues = { ...formValues, overrideSharePrice: noteSharePrice };
        }

        if (numberOfNoteShares !== defaultTo(0, calculatedConvertNote?.numberOfNewShares)) {
          formValues = { ...formValues, overrideNumberOfShares: numberOfNoteShares };
        }

        await convertCNThunk(formValues);
        notify(t("successSaved"), true, "success");
        setIsConvertNoteEditPanelOpen({ open: false });
        getIssueEquityThunk(companyId || 0);
        setNoteSharePrice(undefined);
      } catch (e) {
        console.log("error", e);
      }
    },
    [
      calculatedConvertNote?.numberOfNewShares,
      companyId,
      convertCNThunk,
      getIssueEquityThunk,
      noteSharePrice,
      numberOfNoteShares,
      setIsConvertNoteEditPanelOpen,
      setNoteSharePrice,
    ]
  );

  const { initialValues, validationSchema } = useConvertNoteForm();
  return (
    <SlidePanel.Body isLoading={loading}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <div className={classes.wrap}>
          <Info />
          <Conversion />
          <Documentation />
          <Actions />

          <OverrideSharesModal />
          <OverrideSharePriceModal />
        </div>
      </Formik>
    </SlidePanel.Body>
  );
};
export default ConvertNoteForm;
