import { FC } from "react";
import { flexRender, Row } from "@tanstack/react-table";
import cn from "classnames";

import { CapTableIssueShares } from "../../types";

type RowProps = {
  row: Row<Partial<CapTableIssueShares>>;
  rowClickHandler: (id: number) => void;
};

const BodyRow: FC<RowProps> = ({ row, rowClickHandler }) => {
  return (
    <tr>
      {row.getVisibleCells().map((cell) => (
        <td
          style={{ cursor: "pointer" }}
          onClick={() =>
            cell.column.columnDef.meta?.allowClick ? rowClickHandler(row.original.issuedSharesId as number) : undefined
          }
          key={cell.id}
          className={cn(cell.column.columnDef.meta?.bodyClass)}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
};
export default BodyRow;
