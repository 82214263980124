import { FC, useCallback } from "react";
import cn from "classnames";
import { parseISO } from "date-fns";
import * as R from "ramda";
import { isNil } from "ramda";

import { Collapsible, Label, P, Ui } from "common/components/atoms/index";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CheckIcon, ChevronRightSmallIcon, CloseIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { ExerciseRequestStatusEnum } from "common/types/Exxercise.types";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { IOngoingExercises } from "../../../../pages/my-porfolio/exercising/ExercisingContext";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.exercising.history");

type Props = {
  history: IOngoingExercises[];
  setActiveExerciseRequest?: (val: IOngoingExercises) => void;
};

const ExercisingHistory: FC<Props> = ({ history, setActiveExerciseRequest }) => {
  const fNumber = useFormatNumbers();

  const handleClick = useCallback(
    (item: IOngoingExercises) => () => {
      setActiveExerciseRequest?.(item);
    },
    [setActiveExerciseRequest]
  );
  const renderHead = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div className={classes["head"]} onClick={onClick}>
          <div className="d-flex align-items-center">
            <Ui.xl bold>{t("title")}</Ui.xl>

            <div className={classes["count"]}>{R.defaultTo(0, history.length)}</div>
          </div>

          <ChevronRightSmallIcon
            className={cn(classes["chevron"], {
              [classes["open"]]: Boolean(activeEventKey),
            })}
          />
        </div>
      );
    },
    [history.length]
  );

  return (
    <Collapsible defaultOpen withoutDivider={false} Header={renderHead} className={cn("mt-3", classes["collapsible"])}>
      <div className={classes["content"]}>
        {!history.length ? (
          <div className="p-4">
            <Ui.xs>{t("noHistory")}</Ui.xs>
          </div>
        ) : (
          history.map((item, index) => {
            const declinedDate = item?.approvedAt
              ? item?.approvedAt
              : item?.issuedAt
              ? item?.issuedAt
              : item?.moneyTransferredAt
              ? item?.moneyTransferredAt
              : item?.requestedAt;

            return (
              <div className={classes.request} key={index} onClick={handleClick(item)}>
                {item.exerciseRequestStatus === ExerciseRequestStatusEnum.Declined ? (
                  <CloseIcon fontSize={24} color={scssVariables.critical500} className="flex-shrink-0" />
                ) : (
                  <CheckIcon fontSize={24} className="flex-shrink-0" color={scssVariables.positive500} />
                )}

                <div className="ms-3 me-2 flex-fill">
                  <P.m style={{ fontSize: 16 }}>
                    {item.exerciseRequestStatus === ExerciseRequestStatusEnum.Declined ? (
                      <>
                        {t("declinedDescription", {
                          numberOfOptions: item.numberOfOptions,
                          price: fNumber(item?.payment?.amount || item?.amount, "unitPrice", {
                            customCurrencySymbol: item.currencySymbol,
                          }),
                          date: transformDateToCommonDateFormat(parseISO(declinedDate), "EEEE dd.MM.yyyy"),
                          managerName: item?.managerName || "Manager",
                          reason: item.comment,
                        })}

                        {!isNil(item.comment) ? (
                          <>
                            {" "}
                            {t.el("declinedReason", {
                              components: [<i key={1} />],
                              values: {
                                reason: item.comment,
                              },
                            })}
                          </>
                        ) : null}
                        <br />
                      </>
                    ) : (
                      t("exercised", {
                        options: item.numberOfOptions,
                        value: fNumber(item.payment?.amount || item?.amount, "value", {
                          customCurrencySymbol: item.currencySymbol,
                        }),
                        date: item.approvedAt
                          ? transformDateToCommonDateFormat(parseISO(item.approvedAt), "EEEE dd.MM.yyyy")
                          : "-",
                      })
                    )}
                  </P.m>
                </div>

                <Label type={item.exerciseRequestStatus === ExerciseRequestStatusEnum.Declined ? "danger" : "success"}>
                  {item.exerciseRequestStatus === ExerciseRequestStatusEnum.Declined
                    ? t("declined")
                    : t("sharesIssued")}
                </Label>
              </div>
            );
          })
        )}
      </div>
    </Collapsible>
  );
};

export default ExercisingHistory;
