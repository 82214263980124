import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { getEMPath, getPath } from "app/Router/RouterHelper";
import { LoaderContainer } from "common/components/atoms";
import TableBrowserStorage, { useTableBrowserStorage } from "common/components/atoms/ImportTable/TableBrowserStorage";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { BreggCompanyShareholders, OnboardingTransactionGetDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";

import OwnershipActions from "../import-ownership/OwnershipActions/OwnershipActions";
import useImportOwnershipForm, { OwnershipFormValues } from "../import-ownership/useImportOwnershipForm";
import useOnboardCompanyIntegration from "../useOnboardCompanyIntegration";
import classes from "./ImportStakeholders.module.scss";
import StakeholdersLimitModalWrapper from "./stakeholdersLimitModalWrapper/stakeholdersLimitModalWrapper";
import StakeholdersTable from "./StakeholdersTable/StakeholdersTable";
import useImportStakeholders from "./useImportStakeholders";

const ImportStakeholdersForm: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId?: string }>();
  const { subscription } = useStripeSubscription();

  const [completedOnboarding, setCompletedOnboarding] = useState(false);
  const [isStakeholdersLimitSubmitModalOpen, setIsStakeholdersLimitSubmitModalOpen] = useState(false);

  const company = useStoreState((state) => state.companyOnboardingModel.company);
  const stakeholders = useStoreState((state) => state.companyOnboardingModel.shareholders);
  const loading = useStoreState((state) => state.companyOnboardingModel.isGetCompanyLoading);
  const isStakeholdersLimitModalOpen = useStoreState(
    (state) => state.companyOnboardingModel.isStakeholderLimitModalOpen
  );

  const { getStakeholdersThunk, setStakeholdersThunk, setTransaction, setStakeholderLimitModalOpen } = useStoreActions(
    (actions) => actions.companyOnboardingModel
  );

  const { removeImportTable } = useTableBrowserStorage<BreggCompanyShareholders[]>();

  useOnboardCompanyIntegration(true);

  const { setStakeholdersData, stakeholdersData } = useImportStakeholders();

  const { validationSchema, initialValues } = useImportOwnershipForm(
    stakeholdersData,
    true,
    company?.foundingDate,
    company?.shareClasses as unknown as OnboardingTransactionGetDTO["shareClasses"]
  );

  const handleLoadData = useCallback(
    (data: BreggCompanyShareholders[]) => {
      setStakeholdersData(data);
    },
    [setStakeholdersData]
  );
  const handleImportExcelData = useCallback(
    (data: BreggCompanyShareholders[]) => {
      setStakeholdersData((prev) => [...prev, ...data]);
    },
    [setStakeholdersData]
  );

  const handleSubmit = useCallback<FormikConfig<OwnershipFormValues[]>["onSubmit"]>(
    async (values) => {
      try {
        const existedIds = values
          .filter((stakeholder) => stakeholder.stakeholderId)
          .map((stakeholder) => stakeholder.stakeholderId);

        const stakeholderIdsToDelete = (stakeholders || [])
          .filter((stakeholder) => !existedIds.includes(stakeholder.stakeholderId))
          .map((stakeholder) => stakeholder.stakeholderId) as number[];

        let stakeholdersToSubmit = values.map((stakeholder) => ({
          ...stakeholder,
          isCompanyOwned: Boolean(stakeholder.isCompanyOwned),
          countryId: stakeholder.countryId || null,
          stakeholderId: stakeholder.stakeholderId || null,
        }));

        if (subscription?.maximumStakeholders && subscription.maximumStakeholders < stakeholdersToSubmit.length) {
          const trimmedStakeholdersToSubmit = stakeholdersToSubmit.slice(0, 25); // not more than 25
          const trimmedStakeholdersToDelete = stakeholdersToSubmit.slice(25);

          stakeholdersToSubmit = trimmedStakeholdersToSubmit;

          trimmedStakeholdersToDelete.forEach((stakeholder) => {
            stakeholder.stakeholderId && stakeholderIdsToDelete.push(stakeholder.stakeholderId);
          });
        }

        const data = {
          companyId: Number(companyId),
          completedOnboarding,
          stakeholderIdsToDelete,
          stakeholders: stakeholdersToSubmit,
        };
        await setStakeholdersThunk(data);
        removeImportTable();
        setTransaction(null);
        setIsStakeholdersLimitSubmitModalOpen(false);

        if (completedOnboarding) {
          navigate(getPath(["onboard", "company", "importTransaction"], { companyId }));
        } else {
          navigate(getEMPath(["ownership", "capTable"], { companyId }));
        }
      } catch (e) {
        setIsStakeholdersLimitSubmitModalOpen(false);
        console.log(e);
      }
    },
    [
      stakeholders,
      subscription?.maximumStakeholders,
      companyId,
      completedOnboarding,
      setStakeholdersThunk,
      removeImportTable,
      setTransaction,
      navigate,
    ]
  );

  useEffect(() => {
    if (companyId) {
      getStakeholdersThunk(Number(companyId)).catch((e) => {
        console.log(e);
      });
    }
  }, [companyId, getStakeholdersThunk]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <div className={classes.wrap}>
        <OwnershipActions
          backButtonHidden
          handleImportData={handleImportExcelData}
          setCompletedOnboarding={setCompletedOnboarding}
          isStakeholdersLimitModalOpen={isStakeholdersLimitSubmitModalOpen}
          setIsStakeholdersLimitModalOpen={setIsStakeholdersLimitSubmitModalOpen}
        />

        <LoaderContainer loading={loading}>
          <StakeholdersTable handleLoadData={handleLoadData} />
        </LoaderContainer>

        <StakeholdersLimitModalWrapper
          isOpen={isStakeholdersLimitModalOpen}
          handleClose={() => setStakeholderLimitModalOpen(false)}
        />
      </div>
    </Formik>
  );
};

const ImportStakeholders: FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  return (
    <TableBrowserStorage tableName={`stakeholders-${companyId}`}>
      <ImportStakeholdersForm />
    </TableBrowserStorage>
  );
};
export default ImportStakeholders;
