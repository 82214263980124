import { FC } from "react";

import RenderStakeholderFields from "../fields/RenderStakeholderFields/RenderStakeholderFields";
import { StakeholderFieldsParamsType } from "../Stakeholder.types";
import useCompany from "./useCompany";

type CompanyProps = {
  updatedFields?: StakeholderFieldsParamsType;
  isDisabled?: boolean;
};

const Company: FC<CompanyProps> = ({ updatedFields, isDisabled }) => {
  const { topFields, otherFields } = useCompany(updatedFields);

  return <RenderStakeholderFields otherFields={otherFields} topFields={topFields} isDisabled={isDisabled} />;
};
export default Company;
