import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { useWizardCloseModal, useWizardStepper } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import SummaryForm from "./SummaryForm";
import { SummaryFormNewType } from "./types";
import { summarySchema } from "./validation-schema";

export enum DocumentStatuses {
  WillUpload = 2,
  AllUploaded = 3,
  NotNeeded = 4,
}

const t = createTranslation(TranslationNS.pages, "createPlan.summary");

const Summary: FC = () => {
  const navigate = useNavigate();
  const { programId } = useParams<{ programId: string }>();

  useWizardStepper(2);
  useWizardCloseModal(true);

  const selectedProgram = useStoreState((state) => state.programModel.program);
  const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const { grantPlanThunk, grantPlanDraftThunk } = useStoreActions((actions) => actions.planModel);

  const summaryInfo = useMemo(() => {
    return {
      totalDraftPlans: selectedProgram?.draftPlans?.length || 0,
      programName: selectedProgram?.name || "",
      totalPlans: selectedProgram?.plans?.length || 0,
    };
  }, [selectedProgram?.draftPlans?.length, selectedProgram?.name, selectedProgram?.plans?.length]);

  const documentFiles = useMemo(() => {
    return selectedProgram?.documentFiles.filter((file) => file.documentTypeId === 3) || [];
  }, [selectedProgram?.documentFiles]);

  const initialValues: SummaryFormNewType = useMemo(
    () => ({
      documentFiles: {
        newFiles: [],
        oldFiles: documentFiles || [],
      },
      plans:
        selectedProgram?.draftPlans
          .filter((plan) => plan.statusId === 1)
          .map((plan) => ({
            ...plan,
            sendInvitation: !!plan.stakeholderEmail && plan.sendEmailInvitation,
          })) || [],
      documentationStatusId: DocumentStatuses.AllUploaded,
      saveAsDraft: false,
      companyId: companyId || 0,
      programId: programId ? +programId : 0,
      grantDate: undefined,
    }),
    [companyId, documentFiles, programId, selectedProgram?.draftPlans]
  );

  const onSubmit = useCallback<FormikConfig<SummaryFormNewType>["onSubmit"]>(
    async (values, { setSubmitting }) => {
      const grantPlan = values.saveAsDraft ? grantPlanDraftThunk : grantPlanThunk;

      const oldFileIds = values.documentFiles?.oldFiles.map((file) => file.fileId) || [];

      const plans = values.saveAsDraft
        ? values.plans.map((plan) => plan.id)
        : values.plans.map((plan) => ({
            planId: plan.id,
            sendEmailInvitation: plan.sendInvitation,
          }));

      const res = await grantPlan({
        plans: plans as unknown as never,
        companyId: values?.companyId,
        documentsStatusId: values.documentationStatusId,
        newFiles: values.documentFiles?.newFiles,
        oldFileIds: oldFileIds,
        programId: values.programId,
        grantDate: values.grantDate,
      });

      if (res) {
        notify(t(values.saveAsDraft ? "successPlanUpdated" : "successPlanGranted"), true, "success");
        navigate(getEMPath(["plans", "agreements"]));
      }
      setSubmitting(false);
    },
    [grantPlanDraftThunk, grantPlanThunk, navigate]
  );

  const handleBack = useCallback(() => {
    navigate(getEMPath(["createPlan", "basics"], { programId }));
  }, [navigate, programId]);

  useEffect(() => {
    if (!selectedProgram) {
      navigate(getEMPath(["createPlan", "basics"], { programId }));
    }
  }, [navigate, programId, selectedProgram]);

  return (
    <WizardContent.Content>
      <Formik initialValues={initialValues} validationSchema={summarySchema} onSubmit={onSubmit} enableReinitialize>
        <SummaryForm
          isDraftPlans={!selectedProgram?.draftPlans.length}
          handleBack={handleBack}
          summaryInfo={summaryInfo}
        />
      </Formik>
    </WizardContent.Content>
  );
};

export default Summary;
