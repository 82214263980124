import { FC, useCallback, useMemo } from "react";
import axios from "axios";
import cn from "classnames";
import { FormikConfig, useFormik } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { P } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import TextField from "common/components/atoms/TextField/TextField";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext from "../../../MyPortfolioContext";
import classes from "./CompanyInvitationModal.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.companyInviteModal");

const tValidate = createTranslation(TranslationNS.validation);

const CompanyInvitationModal: FC = () => {
  const showInviteCompanyModal = MyPortfolioContext.useStoreState((state) => state.showInviteCompanyModal);
  const inviteCompanyData = MyPortfolioContext.useStoreState((state) => state.inviteCompanyData);

  const setShowInviteCompanyModal = MyPortfolioContext.useStoreActions((actions) => actions.setShowInviteCompanyModal);

  const closeCallback = useCallback(() => {
    setShowInviteCompanyModal({ show: false });
  }, [setShowInviteCompanyModal]);

  const inviteExistingTitle = inviteCompanyData?.companyInvitationId
    ? t("resendCompanyTitle", { companyName: inviteCompanyData?.companyName })
    : t("inviteSuggestedCompanyTitle", { companyName: inviteCompanyData?.companyName });

  const inviteNewTitle = t("inviteNewCompanyTitle");

  return (
    <ModalInfo
      header={inviteCompanyData ? inviteExistingTitle : inviteNewTitle}
      show={showInviteCompanyModal}
      handleClose={closeCallback}
    >
      <FormData handleClose={closeCallback} />
    </ModalInfo>
  );
};

type FormDataProps = {
  handleClose?: () => void;
};
const fields = {
  companyName: "companyName",
  reciverName: "reciverName",
  email: "email",
  companyInvitationId: "companyInvitationId",
} as const;
const FormData: FC<FormDataProps> = ({ handleClose }) => {
  const data = MyPortfolioContext.useStoreState((state) => state.inviteCompanyData);
  const getPortfolioThunk = MyPortfolioContext.useStoreActions((state) => state.getPortfolioThunk);

  const initialValues = useMemo(
    () => ({
      [fields.email]: R.defaultTo("", data?.email),
      [fields.reciverName]: R.defaultTo("", data?.reciverName),
      [fields.companyName]: R.defaultTo("", data?.companyName),
      [fields.companyInvitationId]: R.defaultTo(undefined, data?.companyInvitationId),
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [fields.email]: Yup.string().email(tValidate("invalidEmail")).required(tValidate("required")),
        [fields.reciverName]: Yup.string().required(tValidate("required")),
        [fields.companyName]: Yup.string().required(tValidate("required")),
      }),
    []
  );

  const submitHandler = useCallback<FormikConfig<typeof initialValues>["onSubmit"]>(
    async (values) => {
      try {
        await axios.post("/api/company/invite", values);
        notify(t("successMessage"), true, "success", 2000);
        getPortfolioThunk(null).catch((e) => {
          console.log(e);
        });
        handleClose?.();
      } catch (error) {
        console.log(error);
      }
    },
    [getPortfolioThunk, handleClose]
  );

  const { values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div className="mt-1">
      <form onSubmit={handleSubmit}>
        <P.m className={classes.description}>{t("description")}</P.m>
        <div className="d-flex justify-content-center flex-column">
          <TextField
            className={cn(classes.fields, "mt-4")}
            type="text"
            label={t("companyName")}
            value={values.companyName}
            error={errors.companyName}
            isTouched={touched.companyName}
            name={fields.companyName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            className={cn(classes.fields, "mt-4")}
            type="text"
            label={t("representativeName")}
            value={values.reciverName}
            error={errors.reciverName}
            isTouched={touched.reciverName}
            name={fields.reciverName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <TextField
            className={cn(classes.fields, "mb-5 mt-4")}
            type="email"
            label={t("representativeEmail")}
            value={values.email}
            error={errors.email}
            isTouched={touched.email}
            name={fields.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="primary" type="submit" className="me-2">
            {t("sendInvite")}
          </Button>
          <Button isDisabled={isSubmitting} variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CompanyInvitationModal;
