import { FC } from "react";
import TabContainer, { TabContainerProps } from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";

import WizardLayoutStore from "../../../store";
import Item from "./Item/Item";

export type ContentItemsProps = TabContainerProps & {};

type ContentItemsComponent = FC<ContentItemsProps> & {
  Item: typeof Item;
};

const ContentItems: ContentItemsComponent = ({
  activeKey,
  children,
  ...props
}) => {
  const sidebarContentActiveItemStore = WizardLayoutStore.useStoreState(
    (state) => state.sidebarContentActiveItem
  );

  return (
    <TabContainer
      id="wizard-sidebar-content-items"
      {...props}
      activeKey={activeKey || sidebarContentActiveItemStore}
    >
      <TabContent>{children}</TabContent>
    </TabContainer>
  );
};

ContentItems.Item = Item;

export default ContentItems;
