import React, { FC } from "react";
import classNames from "classnames";

import { PieChart } from "common/components/atoms";
import { scssVariables } from "common/utils/constants";

import classes from "./empty.module.scss";

const EmptyChart: FC<{ percentage: number } & JSX.IntrinsicElements["div"]> = ({ className, percentage, ...props }) => {
  return (
    <div className={classNames(className, classes["chart-container"])} {...props}>
      <PieChart
        height={108}
        pieThickness={0.7}
        isInteractive={false}
        data={[
          {
            id: "1",
            value: percentage,
            color: scssVariables.additional13100,
          },
          {
            id: "2",
            value: 100 - percentage,
            color: "transparent",
          },
        ]}
      />

      <div className={classNames("mt-2 mb-1", classes["title-section"])} />
      <div className={classes["subtitle-section"]} />
    </div>
  );
};

export default EmptyChart;
