import { ChangeEvent, FC, useCallback } from "react";
import { useFormikContext } from "formik";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import classes from "common/components/atoms/ImportTable/inputs/inputs.module.scss";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";

type SourceFieldProps = {
  options: { id: number | string; name: string }[];
  rowIndex: number;
  columnId: string;
  isSearchable: boolean;
  resetValue?: string;
  placeholder?: string;
  isClearable?: boolean;
};

type FormikValues = {
  [key: string]: number | undefined;
};

const TransactionField: FC<SourceFieldProps> = ({
  rowIndex,
  columnId,
  isSearchable,
  options,
  resetValue,
  placeholder,
  isClearable,
}) => {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = useFormikContext<FormikValues[]>();

  const error = errors[rowIndex]?.[columnId];
  const value = values[rowIndex]?.[columnId];
  const touchedValue = touched?.[rowIndex]?.[columnId];

  const handleChangeValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (resetValue) {
        setFieldValue(`[${rowIndex}].${resetValue}`, -1);
      }
      handleChange(e);
    },
    [handleChange, resetValue, rowIndex, setFieldValue]
  );

  return (
    <>
      {error && touchedValue && (
        <div className={classes.errorIcon}>
          <Tooltip className={classes.errorTooltip} popupContent={error}>
            <span>
              <WarningExclamationMarkIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <Dropdown
        name={`[${rowIndex}].${columnId}`}
        className={classes.selectInput}
        isSearchable={isSearchable}
        options={options}
        optionsIsObject
        selectedValue={value}
        onChange={handleChangeValue}
        onBlur={handleBlur}
        placeholder={placeholder}
        isClearable={isClearable}
      />
    </>
  );
};

export default TransactionField;
