import { DocumentationDetailsFields } from "../form-sections/documentation-details/form-fields";
import { TransactionDetailsFields } from "../form-sections/transaction-details/form-fields";

export const ChangeNominalValueFields = {
  companyId: "companyId",
  description: TransactionDetailsFields.description,
  transactionDate: "transactionDate",
  transactionId: "transactionId",
  nominalShareValue: "nominalShareValue",
  ...DocumentationDetailsFields,
} as const;
