import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import TextArea from "common/components/atoms/TextArea/TextArea";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import { createTranslation, TranslationNS } from "translation";

import { PlanForm } from "../../create-one-off-plans/types";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar.fields");

type PropsType = {
  className?: string;
};

const EssentialTermsField: FC<PropsType> = ({ className }) => {
  const { handleBlur, values, errors, touched, handleChange } = useFormikContext<PlanForm>();

  const answer = useMemo(() => ({ render: () => t.el("essentialTerms.answer") }), []);

  return (
    <div className={className}>
      <Helper.Question questionId={f.essentialTerms} type="input" answer={answer}>
        <TextArea
          rows={6}
          isOptional
          label={t("essentialTerms.label")}
          name={f.essentialTerms}
          value={values.essentialTerms}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.essentialTerms}
          isTouched={touched.essentialTerms}
          info={t("essentialTerms.info")}
        />
      </Helper.Question>
      <Helper.Answer className="mt-1" answerId={f.essentialTerms} withRightMargin />
    </div>
  );
};

export default EssentialTermsField;
