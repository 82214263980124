import { FC, useCallback, useMemo } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import { FormikConfig, useFormik } from "formik";
import { defaultTo } from "ramda";
import { number, object, ref } from "yup";

import { Button, P, TextField } from "common/components/atoms";
import { InformationCircleIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import ManagePlansContext from "../../../managePlansContext";
import classes from "./MarkSharesAsSoldModal.module.scss";

type PropsForm = {
  handleClose: () => void;
  planId?: number;
  exercisedSharesSold?: number;
  maxNumberOfShares?: number;
  fullName: string;
  dataSaveCallback: () => void;
};

type FormValues = {
  planId: number;
  exercisedSharesSold?: number;
  maxNumberOfShares: number;
  submitType?: "markAsSold" | "reset";
};

const vt = createTranslation(TranslationNS.validation);

const MarkSharesAsSoldModalForm: FC<PropsForm> = ({ handleClose, dataSaveCallback, ...props }) => {
  const markSharesAsSoldInfoThunk = ManagePlansContext.useStoreActions((actions) => actions.markSharesAsSoldInfoThunk);

  const initialValues = useMemo<FormikConfig<FormValues>["initialValues"]>(() => {
    return {
      planId: defaultTo(0, props.planId),
      exercisedSharesSold: defaultTo(undefined, props.exercisedSharesSold),
      maxNumberOfShares: defaultTo(0, props.maxNumberOfShares),
    };
  }, [props.exercisedSharesSold, props.maxNumberOfShares, props.planId]);

  const validationSchema = useMemo(() => {
    return object().shape({
      exercisedSharesSold: number()
        .integer(vt("integer"))
        .required(vt("required"))
        .min(0, vt("minNumber", { number: 0 }))
        .max(ref("maxNumberOfShares"), vt("maxNumber", { number: props.maxNumberOfShares })),
    });
  }, [props.maxNumberOfShares]);

  const submit = useCallback(
    async (values: FormValues) => {
      try {
        await markSharesAsSoldInfoThunk(values);
        notify(
          values.submitType === "markAsSold"
            ? "The shares were marked as sold"
            : "The number of sold shares has been reset to 0",
          true,
          "success"
        );
        dataSaveCallback();
      } catch (error) {
        console.error(error);
      }
    },
    [dataSaveCallback, markSharesAsSoldInfoThunk]
  );

  const { values, handleChange, handleSubmit, errors, touched, handleBlur, setFieldValue, isSubmitting } =
    useFormik<FormValues>({
      initialValues,
      validationSchema,
      onSubmit: submit,
    });

  const handleReset = useCallback(async () => {
    await setFieldValue("exercisedSharesSold", 0);
    await setFieldValue("submitType", "reset");
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  const handleMarkAsSold = useCallback(async () => {
    await setFieldValue("submitType", "markAsSold");
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  return (
    <div>
      <P.m className="text-start mb-3">
        Let us know how many shares have been sold from {props.fullName}’s agreement so we can keep the value accurate
        and up to date.
      </P.m>
      <div className={classNames(classes.infoBanner, "d-flex align-items-center mb-4")}>
        <InformationCircleIcon fontSize={24} />
        <P.s>Only exercised shares can be marked as sold.</P.s>
      </div>
      <Form onSubmit={handleSubmit}>
        <TextField
          name="exercisedSharesSold"
          value={values.exercisedSharesSold}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Number of shares sold"
          error={errors.exercisedSharesSold}
          info={`Max ${props.maxNumberOfShares} shares`}
          isTouched={touched.exercisedSharesSold}
          type="number"
        />

        <Modal.Footer className="d-flex justify-content-between">
          <div>
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting && values.submitType === "markAsSold"}
              onClick={handleMarkAsSold}
            >
              Mark as sold
            </Button>
            <Button isDisabled={isSubmitting} className="ms-2" variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </div>
          {!!props.exercisedSharesSold && (
            <Button
              isDisabled={isSubmitting}
              isLoading={isSubmitting && values.submitType === "reset"}
              variant="tertiary"
              onClick={handleReset}
              style={{ color: "#C54049" }}
            >
              Reset sold shares
            </Button>
          )}
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default MarkSharesAsSoldModalForm;
