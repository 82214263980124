import { FC } from "react";
import { Modal } from "react-bootstrap";

import Button from "common/components/atoms/Button/Button";
import { CloseIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { H } from "../../Typography";
import classes from "./corner-modal.module.scss";

const t = createTranslation(TranslationNS.common, "components.confirmLeaveModal");

export type PropsTypes = {
  isOpen: boolean;
  content: string;
  onCancel: () => void;
  onAccept: () => void;
};

const CornerModal: FC<PropsTypes> = ({ isOpen, content, onAccept, onCancel }) => {
  return (
    <Modal show={isOpen} onEscapeKeyDown={onCancel} className={classes["corner-modal"]}>
      <div className="d-flex justify-content-end">
        <Button size="s" variant="tertiary" onClick={onCancel}>
          <CloseIcon />
        </Button>
      </div>
      <div className="p-4 pt-1 d-flex flex-column align-items-center">
        <H.xs className="mb-2">{t("title")}</H.xs>
        <p className="ui-s text-medium mb-3">{content}</p>
        <div>
          <Button variant="secondary" onClick={onCancel} className="me-2">
            {t("cancelBtn")}
          </Button>
          <Button onClick={onAccept}>{t("agreeBtn")}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default CornerModal;
