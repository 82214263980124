import { FC, memo, useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Ui } from "common/components/atoms";
import { ActiveUserBudgeIcon, UsersIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./InvitedStakeholdersIndicator.module.scss";

type PropsTypes = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "company.capTable.invitationIndicator");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const Indicator: FC<PropsTypes> = ({ className }) => {
  const { companyId } = useParams();
  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);
  const { totalStakeholders, activeStakeholders } = useStoreState((store) => store.stakeholderInvitation);
  const { getInvitedStakeholdersIndicatorData, openMultipleInviteModal } = useStoreActions(
    (actions) => actions.stakeholderInvitation
  );

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getInvitedStakeholdersIndicatorData(Number(companyId));
      setIsLoading(false);
    })();
  }, [companyId, getInvitedStakeholdersIndicatorData]);

  if (totalStakeholders === activeStakeholders) return null;

  return (
    <div className={cn(className, classes.border, "d-flex justify-content-between align-items-center py-3 px-4")}>
      <div className="w-75">
        <div className="d-flex align-items-center gap-1 mb-2">
          <div className={cn("d-flex align-items-center p-1", classes.iconsBorder)}>
            <UsersIcon fontSize={20} className="me-1" />
            <ActiveUserBudgeIcon fontSize={16} />
          </div>
          <Ui.l bold color="foregroundMedium">{`${isLoading ? "-" : activeStakeholders}/${
            isLoading ? "-" : totalStakeholders
          }`}</Ui.l>
          <Ui.m className="text-low">{t("active")}</Ui.m>
        </div>
        <ProgressBar
          className={classes.progressBar}
          animated={isLoading}
          now={activeStakeholders}
          max={totalStakeholders}
        />
      </div>
      <Button
        variant="secondary"
        onClick={() => openMultipleInviteModal("your")}
        isDisabled={!hasFullAccess}
        tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
        tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
      >
        {t("inviteBtn")}
      </Button>
    </div>
  );
};

const InvitedStakeholdersIndicator: FC<PropsTypes> = (props) => {
  const { hasViewAccess, hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);

  if (hasViewAccess || hasFullAccess) {
    return <Indicator {...props} />;
  }

  return null;
};

export default memo(InvitedStakeholdersIndicator);
