export enum InstrumentTypesNamesEnum {
  RSA = "Restricted Stock Award",
  OPTION = "Stock Option",
  RSU = "Restricted Stock Unit",
  PHANTOM = "Phantom Shares",
}

export enum InstrumentTypesIdsEnum {
  RSA = 1,
  RSU = 2,
  OPTION = 3,
}

export enum VestingTypesNamesEnum {
  TIME_VESTING = "Time-based vesting",
  CONDITION_BASED_VESTING = "Condition-based vesting",
  COMBINE_BASED_VESTING = "Combine time and condition based vesting",
}

export enum DocumentStatusEnum {
  DOCUMENT_UPLOADED = 1,
  REVIEW_LATER = 2,
  NO_DOCUMENT_REQUIRED = 3,
}

export enum PlanDocumentStatusEnum {
  GENERATE = 1,
  UPLOAD = 2,
  LATER = 3,
  NOT_REQUIRED = 4,
}

export enum PeriodTimeEnum {
  YEAR = "Year",
  MONTH = "Month",
  DAY = "Day",
}

export enum RelationshipTypesEnum {
  EMPLOYEE = 1,
  INVESTOR = 2,
  FOUNDER = 3,
  ADVISOR = 4,
  BOARD = 5,
  CONSULTANT = 6,
  OTHER = 7,
}

export enum CurrencyTypesEnum {
  BRITISH_POUND = 4,
  EURO = 2,
  NORWEGIAN_KRONER = 1,
  UNITED_STATES_DOLLAR = 3,
}

export enum VestingTypesEnum {
  TIME_VESTING = 1,
  MILESTONE_VESTING = 2,
  COMBINE_VESTING = 3,
}

export enum IssueEquityConvertibleStatuses {
  Draft = 1,
  Approved = 2,
  Sent = 3,
  Cancelled = 4,
  ConversionRequest = 5,
  Declined = 6,
  Converted = 7,
  Expired = 8,
  Converting = 9,
}

export enum PlanStatusesBasedOnAPIStatusId {
  draft = 1,
  approved = 2,
  cancelled = 3,
  active = 4,
  closed = 5,
  completed = 6,
  granted = 7,
  terminated = 8,
  waitingForReceiver = 9,
  waitingForManager = 10,
  expired = 11,
}

export enum AgreementMilestoneStatuses {
  inProgress = 1,
  complete = 2,
  incomplete = 3,
}

export enum AgreementUIType {
  regular = "regular",
  slidePanel = "slidePanel",
}

export enum OnboardingMethodsEnum {
  NO_INTEGRATION = 1,
  BR_REG = 2,
  UNI_MICRO = 3,
  SR_BANK_UNI_MICRO = 4,
  FEW_OWNERS_COMPANY = 5,
}

export enum PoolStatuses {
  draft = 1,
  active = 2,
  expired = 3,
}

export enum PoolTypesOfSource {
  new = 1,
  existing,
}

export enum StripeSubscriptionTypes {
  NoSubscription = 0,
  Start = 1,
  Growth = 2,
}

export enum CapTableGroupByOptions {
  default = 1,
  shareholder = 2,
  relationship = 3,
  shareClass = 4,
  instrument = 5,
}

export enum UploadFileTypes {
  PoolDocument = 1,
  ProgramDocument = 2,
  ProgramPlanDocument = 3,
  PlanDocument = 4,
  ShareClassDocument = 5,
  ShareIssuanceDocument = 14,
  ConvertibleNoteDocument = 17,
  ConvertibleLoanDocument = 19,
  WarrantDocument = 21,
  WarrantSettlementDocument = 23,
  ConvertibleNoteConversionDocument = 25,
  ConvertibleLoanConversionDocument = 27,
  BuySellDocument = 29,
  SplitTransaction = 30,
  NominalValueTransaction = 31,
  TransactionBundle = 32,
}
