import React, { FC } from "react";
import cn from "classnames";
import { equals } from "ramda";

import { VestingTimeIcon, ViewListIcon } from "common/icons/svg";

import classes from "../styles.module.scss";

// Small component using for RSA and SO charts component to switch between chart UI and VestingTable UI components
type Props = {
  activeTab: "chart" | "table";
  setActiveTab: (tab: "chart" | "table") => void;
};

const TabSwitcher: FC<Props> = ({ activeTab, setActiveTab }) => {
  return (
    <div className={classes["tab-switcher"]}>
      <div
        className={cn(classes["active-tab-item"], {
          [classes["left"]]: activeTab === "chart",
          [classes["right"]]: activeTab === "table",
        })}
      />

      <div
        className={cn(classes["tab-item"], {
          [classes["active"]]: equals(activeTab, "chart"),
        })}
        onClick={() => {
          setActiveTab("chart");
        }}
      >
        <VestingTimeIcon />
      </div>

      <div
        className={cn(classes["tab-item"], {
          [classes["active"]]: equals(activeTab, "table"),
        })}
        onClick={() => {
          setActiveTab("table");
        }}
      >
        <ViewListIcon />
      </div>
    </div>
  );
};

export default TabSwitcher;
