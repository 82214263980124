import { CSSProperties, forwardRef, useEffect } from "react";
import BDropdown from "react-bootstrap/Dropdown";
import cn from "classnames";

import classes from "../Dropdown/Dropdown.module.scss";

export type MenuOption = {
  title: string;
  handler: () => void;
  disabled?: boolean;
  selected?: boolean;
};

type PropsTypes = {
  handleToggleMenu: (isOpen: boolean) => void;
  show: boolean;
  options: MenuOption[];
  style?: CSSProperties;
  className?: string;
};

const DropdownMenu = forwardRef<HTMLDivElement, PropsTypes>(
  ({ style, className, show, options = [], handleToggleMenu }, ref) => {
    useEffect(() => {
      handleToggleMenu(show);
    }, [show, handleToggleMenu]);

    return (
      <div
        ref={ref}
        className={cn(classes["dropdown-button-container"], className)}
        data-testid="dropdown-menu-container"
        style={style}
      >
        <div className={cn("py-half", classes["dropdown-button-menu"])}>
          {options.map((option, i: number) => {
            return (
              <BDropdown.Item
                key={`Dropdown key for single item is: ${option}-${i}`}
                onClick={() => option.handler()}
                data-testid={`dropdown-menu-item-${i}-test-id`}
                eventKey={i}
                disabled={option.disabled}
                className={cn(classes["dropdown-button-item"], {
                  [classes["selected"]]: option.selected,
                })}
              >
                {option.title}
              </BDropdown.Item>
            );
          })}
        </div>
      </div>
    );
  }
);

DropdownMenu.displayName = "DropDownMenu";

export default DropdownMenu;
