import { FC, ReactNode, RefObject } from "react";
import cn from "classnames";

import classes from "./step.module.scss";
import StepImportant from "./StepImportant";
import StepInfo from "./StepInfo";
import StepItem from "./StepItem";
import StepPoint from "./StepPoint";

type PropsTypes = {
  children: ReactNode;
  className?: string;
  customRef?: RefObject<any>;
};

const Step: FC<PropsTypes> & {
  Item: typeof StepItem;
  Point: typeof StepPoint;
  Info: typeof StepInfo;
  Important: typeof StepImportant;
} = ({ children, className, customRef }) => {
  return (
    <div className={cn(classes.step, className)} ref={customRef}>
      {children}
    </div>
  );
};
Step.Item = StepItem;
Step.Point = StepPoint;
Step.Info = StepInfo;
Step.Important = StepImportant;

export default Step;
