import { FC, useCallback, useEffect, useState } from "react";

import { Check, ChecksGroup, Ui } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";

interface PlanTypeDropDownProps {
  selectedValue: number | undefined;
  optionValues: { name: string; description?: string; id: number }[];
  label?: string;
  onChange?: (id: number) => void;
  isStockOptionsDisabled?: boolean;
}

const PlanTypeDropDown: FC<PlanTypeDropDownProps> = ({
  selectedValue,
  optionValues,
  label,
  onChange,
  isStockOptionsDisabled,
}) => {
  const [value, setValue] = useState(selectedValue);

  const handleChange = useCallback(
    (id: number) => {
      setValue(id);
      onChange?.(id);
    },
    [onChange]
  );

  useEffect(() => {
    if (value !== selectedValue) {
      setValue(selectedValue);
    }
  }, [selectedValue, value]);

  return (
    <div>
      {label && <Ui.m className="mb-1 fw-500">{label}</Ui.m>}

      <ChecksGroup>
        {optionValues.map((el) => (
          <Check
            key={el.id}
            type="radio"
            label={el.name}
            description={el?.description}
            checked={el.id === value}
            onChange={() => {
              handleChange(el.id);
            }}
            disabled={isStockOptionsDisabled && el.id === InstrumentTypesIdsEnum.OPTION}
          />
        ))}
      </ChecksGroup>
    </div>
  );
};

export default PlanTypeDropDown;
