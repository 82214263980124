import React, { FC, useCallback, useMemo, useRef } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { Button, P, SlidePanel } from "common/components/atoms";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { createTranslation, TranslationNS } from "translation";

import DocumentUploadWithStatuses from "../../../../../../../common/components/molecules/DocumentUploadWithStatuses/DocumentUploadWithStatuses";
import { SplitEditDTO, SplitGetResponseDTO } from "../../../types";
import SplitDetails from "../form-sections/split-details/split-details";
import { splitDetailsValidationSchema } from "../form-sections/split-details/validation";
import TransactionDetails from "../form-sections/transaction-details/transaction-details";
import { transactionDetailsValidationSchema } from "../form-sections/transaction-details/validation";
import { EditSplitFields, SplitFields } from "./form-fields";

type PropsTypes = {
  transactionToEdit: SplitGetResponseDTO;
  onSubmit: (transaction: FormData, resetForm?: boolean) => Promise<boolean>;
  onClose: () => void;
  minTransactionDate?: string;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");
const tValidation = createTranslation(TranslationNS.validation);

const EditSplitTransactionForm: FC<PropsTypes> = ({ transactionToEdit, onSubmit, onClose, minTransactionDate }) => {
  const formRef = useRef<HTMLDivElement>(null);

  const formInitialValues: SplitEditDTO = useMemo(
    () => {
      const { transactionTypeId, ...transaction } = transactionToEdit;
      return {
        ...transaction,
        description: transaction.description || "",
        prevFiles: transaction.documentFiles || [],
        documents: [],
      };
    },
    // eslint-disable-next-line
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...transactionDetailsValidationSchema({ tFunction: tValidation, excludeFields: { transactionTypeId: true } }),
        ...splitDetailsValidationSchema(tValidation),
      }),
    []
  );

  const handleSubmit = useCallback(
    async (values: SplitEditDTO, actions: FormikHelpers<SplitEditDTO>) => {
      const formData = new FormData();

      Object.values(EditSplitFields).forEach((key) => {
        const formValue = values[key];

        if (!formValue) return;

        if (key === SplitFields.documents) {
          if (!R.isEmpty(formValue)) {
            values.documents.forEach((el) => formData.append("FilesData.Files", el));
          }
        } else if (key === SplitFields.prevFiles) {
          if (!R.isEmpty(formValue)) {
            values?.prevFiles?.forEach(
              (el) => el.fileId && formData.append("FilesData.OldFileIds", el.fileId.toString())
            );
          }
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      const createdSuccessfully = await onSubmit(formData);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }
    },
    [onSubmit]
  );

  return (
    <Formik onSubmit={handleSubmit} initialValues={formInitialValues} validationSchema={validationSchema}>
      {({ isSubmitting, submitForm }) => {
        return (
          <Form>
            <SlidePanel show={true} customRef={formRef}>
              <SlidePanel.Header isEdit title={t("splitForm.title")} onHide={onClose} />

              <SlidePanel.Section title={t("transactionDetails.title")}>
                <TransactionDetails
                  hiddenFields={{ transactionTypeId: true, transactionTypeTransferId: true }}
                  minTransactionDate={minTransactionDate}
                />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("splitDetails.title")}>
                <SplitDetails />
              </SlidePanel.Section>

              <SlidePanel.Section title={t("documentationDetails.title")}>
                <P.s className="mb-4">{t("documentationDetails.description")}</P.s>

                <DocumentUploadWithStatuses
                  oldFilesName={EditSplitFields.prevFiles}
                  newFilesName={EditSplitFields.documents}
                />
              </SlidePanel.Section>

              <SlidePanel.Actions>
                <Button isLoading={isSubmitting} onClick={submitForm} type="submit" className="me-3">
                  {t("sharedFormButtons.editBtn")}
                </Button>
                <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                  {t("sharedFormButtons.cancelBtn")}
                </Button>
              </SlidePanel.Actions>
            </SlidePanel>
            <ToastFormikValidator />
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditSplitTransactionForm;
