import { FC, SyntheticEvent, useCallback, useMemo } from "react";
import cn from "classnames";

import { H, P, Ui } from "common/components/atoms/Typography";
import { CommonFileIcon, LinkIcon } from "common/icons/svg";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { ReadAndAcceptDocumentType } from "./documents";
import classes from "./step.module.scss";

const t = createTranslation(TranslationNS.common, "receive.stakeholder");

export type ReadAcceptDocumentsProps = {
  title?: string;
  description?: string;
  documents: ReadAndAcceptDocumentType[];
  withStepShown?: boolean;
};

const DOCUMENT_AGREEMENT_ID = 4;

const DocumentsViewOnlyStep: FC<ReadAcceptDocumentsProps> = ({
  documents,
  title,
  description,
  withStepShown = false,
}) => {
  const sortedDocuments = useMemo(() => {
    return documents.sort((a, b) => {
      if (a.documentTypeId === DOCUMENT_AGREEMENT_ID && b.documentTypeId !== DOCUMENT_AGREEMENT_ID) {
        return -1; // a comes before b
      } else if (a.documentTypeId !== DOCUMENT_AGREEMENT_ID && b.documentTypeId === DOCUMENT_AGREEMENT_ID) {
        return 1; // b comes before a
      }
      return 0; // a and b are the same
    });
  }, [documents]);

  const DocumentView = useCallback(({ downloadId, fileName }: { downloadId: string; fileName: string }) => {
    const openDoc = (e: SyntheticEvent) => {
      e.preventDefault();
      openRemoteDocument(downloadId);
    };
    return (
      <div className={cn("mb-1", classes["documents-single"])}>
        <a key={1} className="d-flex align-items-center" target="_blank" rel="noreferrer" href="#" onClick={openDoc}>
          <CommonFileIcon className={cn("me-1", classes["icon"])} width={24} height={24} />

          {fileName}
          <Ui.m bold className="ms-1">
            <LinkIcon />
          </Ui.m>
        </a>
        &nbsp;
      </div>
    );
  }, []);

  return (
    <div className={cn("d-flex py-3 px-4", classes["borders"])}>
      {withStepShown ? <Ui.l className="pt-half me-3">2.</Ui.l> : null}

      <div>
        <H.xxs className="mb-1">{title ? title : t("steps.acceptDocuments.title")}</H.xxs>
        <P.s className="mb-4">{description}</P.s>

        {!documents.length && <div>There is no documents attached</div>}

        {sortedDocuments.map((el) => {
          return (
            <DocumentView
              key={el.downloadId}
              fileName={el.documentTypeId !== DOCUMENT_AGREEMENT_ID ? el.fileName : t("checkbox1.title")}
              downloadId={el.downloadId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsViewOnlyStep;
