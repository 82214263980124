import { FC, useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import cn from "classnames";
import { useFormikContext } from "formik";
import { assoc, dissoc } from "ramda";

import { CommonFileIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { PlanForm } from "pages/equity-management/plans/wizards/create-plan/types";
import { generateFormDataPlan } from "store/PlanModel";
import { createTranslation, TranslationNS } from "translation";

import classes from "./viewAgreement.module.scss";

type PropsTypes = {
  className?: string;
  handleSubmit: () => void;
};

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar.generatePlanDocument");

const fetchDocument = async (plan: PlanForm) => {
  const formData = generateFormDataPlan(plan);

  return await axios
    .post("/api/ownership/plan-pdf-view/stock-options", formData, { responseType: "blob" })
    .then((response) => {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open() as Window;
      pdfWindow.location.href = fileURL;
    });
};

const ViewAgreement: FC<PropsTypes> = ({ className, handleSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { values, isValid } = useFormikContext<PlanForm>();

  const handleDownload = useCallback(async () => {
    if (!isValid) {
      handleSubmit();
    } else {
      try {
        setIsLoading(true);
        const formValues = dissoc("isExcludedClause", values);

        await fetchDocument(assoc("excludePostTerminationClauses", values.isExcludedClause, formValues));
      } finally {
        setIsLoading(false);
      }
    }
  }, [handleSubmit, isValid, values]);

  return (
    <div className={className}>
      <div className={cn(classes["view-agreement"], "py-2 px-5")}>
        <CommonFileIcon className="me-3" height={40} width={40} color={scssVariables.foregroundOnSecondary} />
        {isLoading ? (
          <Spinner animation="border" size="sm" className="ms-5" />
        ) : (
          <span onClick={handleDownload} className={classes["link-btn"]}>
            {t("viewAgreement")}
          </span>
        )}
      </div>
    </div>
  );
};

export default ViewAgreement;
