import React, { FC } from "react";
import { Image } from "react-bootstrap";
import classNames from "classnames";

import EmptyBarImage from "./assets/empty-bar.png";
import classes from "./empty.module.scss";

const EmptyBar: FC<JSX.IntrinsicElements["div"]> = ({ className, ...props }) => {
  return (
    <div className={classNames(className, classes["chart-container"])} {...props}>
      <Image src={EmptyBarImage} />
    </div>
  );
};

export default EmptyBar;
