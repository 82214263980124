import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { useStoreState } from "../../store/store";
import { StripeSubscriptionTypes } from "../enums/enum";
import { SubscriptionDetailsDTOType } from "../types/Common.types";

// prop for hook will be used by manually adding this company id for the hook, in other case
// hook will try to take this param from the useParams hook
const useStripeSubscription = (companyOuterId?: number, skipRequest?: boolean) => {
  const { companyId } = useParams<{ companyId: string }>();
  const [subscription, setSubscription] = useState<SubscriptionDetailsDTOType | null>(null);

  const invitedUser = useStoreState((state) => state.invitedUser);

  const checkCompanySubscription = useCallback(async () => {
    try {
      const request = await axios.get<SubscriptionDetailsDTOType | null>(
        `/api/company/subscription/${companyOuterId ? companyOuterId : companyId}`
      );

      if (request.status === 200) {
        setSubscription(request.data);
      }
    } catch (e) {
      console.error({ e });
    }
  }, [companyId, companyOuterId]);

  const connectCompanySubscription = useCallback(async () => {
    try {
      const request = await axios.post("/api/onboarding/company/check-subscription", {
        companyId: Number(companyOuterId ? companyOuterId : companyId),
        invitationId: invitedUser?.invitedUser?.entityId,
      });

      return await request.data;
    } catch (e) {
      console.error({ e });
    } finally {
      checkCompanySubscription();
    }
  }, [checkCompanySubscription, companyId, companyOuterId, invitedUser?.invitedUser?.entityId]);

  const freeStakeholdersLeft = useMemo(() => {
    if (
      (subscription?.subscriptionType === StripeSubscriptionTypes.NoSubscription ||
        subscription?.subscriptionType === StripeSubscriptionTypes.Start) &&
      !isNaN(subscription?.remainingStakeholders)
    ) {
      return subscription.remainingStakeholders;
    } else {
      return null;
    }
  }, [subscription?.remainingStakeholders, subscription?.subscriptionType]);

  useEffect(() => {
    if (!skipRequest) {
      if (companyId || companyOuterId) {
        checkCompanySubscription();
      }
    }
  }, [checkCompanySubscription, companyId, companyOuterId, skipRequest]);

  return {
    subscription,
    freeStakeholdersLeft,
    connectCompanySubscription,
    checkCompanySubscription,
    refetchSubscription: checkCompanySubscription,
  };
};

export default useStripeSubscription;
