import axios from "axios";
import { actionOn, thunk } from "easy-peasy";

import { ShareClassModel } from "./modelTypes";
import { getThunkTypes } from "./storeThunk";

export const shareClassModel: ShareClassModel = {
  isGetShareClassLoading: false,
  isPostShareClassLoading: false,
  shareClass: null,
  shareClassesCompany: [],
  shareClassesCompanyDetails: [],
  //thunks
  getShareClassesCompanyThunk: thunk(async (actions, payload, { fail }) => {
    return await axios.get(`/api/equity-management/share-class/company/${payload}`).catch(fail);
  }),
  //actionOn
  onGetShareClassesCompany: actionOn(
    (actions) => getThunkTypes(actions.getShareClassesCompanyThunk),
    (state, target) => {
      const [startType, successType] = target.resolvedTargets;
      state.isGetShareClassLoading = startType === target.type;

      switch (target.type) {
        case startType:
          state.shareClassesCompany = [];
          break;
        case successType:
          state.shareClassesCompany = target.result.data;
      }
    }
  ),
};
