import { useCallback, useEffect } from "react";
import classNames from "classnames";
import { FormikErrors, useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import { CheckIcon, ChevronLeftIcon, WarningExclamationMarkIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "../ImportTable.module.scss";

const t = createTranslation(TranslationNS.common, "components.importModal");

const ErrorFilterType = "ERROR_FILTER";

type ErrorMessageProps<T extends object> = {
  isFilterActive: boolean;
  updateErrorCallback: (errors: FormikErrors<T>) => void;
  setGlobalFilter: (filterValue: string) => void;
};

const ImportErrorHandler = <T extends object>({
  isFilterActive,
  setGlobalFilter,
  updateErrorCallback,
}: ErrorMessageProps<T>) => {
  const { errors } = useFormikContext();

  const handleClick = useCallback(() => {
    setGlobalFilter(isFilterActive ? "" : ErrorFilterType);
  }, [isFilterActive, setGlobalFilter]);

  const isValidationError = Object.keys(errors).length > 0;

  useEffect(() => {
    if (!isFilterActive) {
      updateErrorCallback(errors);
    }
  }, [errors, isFilterActive, updateErrorCallback]);

  return (
    <div className={classNames(classes.errorNotification, { [classes.errorValid]: !isValidationError })}>
      <span className={classes.errorIcon}>{!isValidationError ? <CheckIcon /> : <WarningExclamationMarkIcon />}</span>
      <span className={classes.errorText}>
        {isValidationError
          ? t.el("tableError", {
              components: [<strong key="1" />],
              count: Object.keys(errors).length,
            })
          : t("tableValidated")}
      </span>
      {(isValidationError || isFilterActive) && (
        <Button
          variant="dark"
          size="s"
          onClick={handleClick}
          iconLeft={isFilterActive ? <ChevronLeftIcon /> : undefined}
        >
          {isFilterActive ? t("goBack") : t("showRowsWithErrors")}
        </Button>
      )}
    </div>
  );
};

export default ImportErrorHandler;
