import axios from "axios";
import { action, thunk } from "easy-peasy";

import { CommonModel, DropDownsData } from "./modelTypes";

export const common: CommonModel = {
  // selectors
  dropdowns: null,
  //actions
  setDropdowns: action((state, payload) => {
    state.dropdowns = payload;
  }),
  // thunks
  getDropdownsThunk: thunk(async (actions) => {
    try {
      const response = await axios.get<DropDownsData>("/api/drop-downs");
      actions.setDropdowns(response.data);
    } catch (error) {
      console.warn(error);
    }
  }),
};
