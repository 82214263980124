import { FC } from "react";
import FormControl from "react-bootstrap/FormControl";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import FormSelect from "react-bootstrap/FormSelect";
import { useFormikContext } from "formik";

import { ShareClassServiceUpdateData } from "common/shareClass/ShareClassService";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import InputHeader from "../../../components/atoms/input-blocks/input-header/input-header";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.shareClassAntiDilutionField");

const ShareClassAntiDilutionField: FC<FormGroupProps> = (props) => {
  const shareClassAntiDilutionsState = useStoreState((state) => state.common.dropdowns?.shareClassAntiDilutions || []);

  const { values, touched, errors, handleChange } =
    useFormikContext<Pick<ShareClassServiceUpdateData, "shareClassAntiDilutionId">>();

  return (
    <FormGroup controlId="shareClassAntiDilutionId" {...props}>
      <InputHeader label={t("label")} />
      <FormSelect
        isInvalid={touched.shareClassAntiDilutionId && !!errors.shareClassAntiDilutionId}
        value={values.shareClassAntiDilutionId || ""}
        onChange={handleChange}
      >
        {shareClassAntiDilutionsState.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </FormSelect>
      <FormControl.Feedback type="invalid">{errors.shareClassAntiDilutionId}</FormControl.Feedback>
    </FormGroup>
  );
};

export default ShareClassAntiDilutionField;
