import { FC, ReactNode, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { useFormikContext } from "formik";

import { Tooltip } from "common/components/atoms";
import { MenuTabBarVerticalIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { OnboardingTransactionGetDTO } from "../../../../../../../../../../store/modelTypes";
import classes from "./row-actions.module.scss";

const t = createTranslation(TranslationNS.common, "importTable.actions");

type ActionsProps = {
  rowIndex: number;
  updateData?: (rowIndex: number, type: string) => void;
  globalFilter?: string;
  canDoActionField?: string;
  popupContent?: ReactNode;
  isBundleConfirmed: boolean;
  stakeholders: OnboardingTransactionGetDTO["stakeholders"];
};

type FormikValues = Record<string, string | number | undefined | boolean>;

const RowActions: FC<ActionsProps> = ({ rowIndex, updateData, globalFilter, canDoActionField, popupContent }) => {
  const { values } = useFormikContext<FormikValues[]>();

  const handleDelete = useCallback(() => {
    updateData?.(rowIndex, "deleteRow");
  }, [rowIndex, updateData]);

  const handleCopy = useCallback(() => {
    updateData?.(rowIndex, "copyRow");
  }, [rowIndex, updateData]);

  const handleAdd = useCallback(() => {
    updateData?.(rowIndex, "addRow");
  }, [rowIndex, updateData]);

  const disabled = canDoActionField ? values[rowIndex]?.[canDoActionField] === false : false;

  if (globalFilter) {
    return null;
  }

  return (
    <>
      <div className={classes.actions}>
        <Dropdown className={classes.dropdown} id={`actions_id_${rowIndex}`}>
          <Dropdown.Toggle disabled={!!globalFilter} className={classes.iconButton}>
            <MenuTabBarVerticalIcon color={scssVariables.foregroundHigh} />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item as="span" onClick={handleCopy}>
              {t("copyRow")}
            </Dropdown.Item>
            <Dropdown.Item as="span" onClick={handleAdd}>
              {t("addNewRow")}
            </Dropdown.Item>
            <Tooltip placement="left" show={disabled ? undefined : false} popupContent={popupContent} rootCloseDisabled>
              <div>
                <Dropdown.Item disabled={disabled} as="span" onClick={handleDelete}>
                  {t("deleteRow")}
                </Dropdown.Item>
              </div>
            </Tooltip>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      {/*{isConfirmRollbackModalOpen && (*/}
      {/*  <RollbackConfirmedTransaction*/}
      {/*    stakeholderName={stakeholderName}*/}
      {/*    numberOfShares={numberOfSharesConverted}*/}
      {/*    onSubmit={handleSubmitRollback}*/}
      {/*    onClose={handleCloseRollbackForm}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default RowActions;
