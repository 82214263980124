import { FC } from "react";
import cn from "classnames";

import Step from "common/components/atoms/Step/Step";

import { Transaction, TransactionStatus } from "../../../types";
import MiniTransactionItem from "./mini-transaction-item";
import classes from "./mini-transaction-item.module.scss";

type PropsTypes = {
  transactions: Transaction[];
  className: string;
};

const MiniTransactionsList: FC<PropsTypes> = ({ transactions, className }) => {
  return (
    <div className={cn(className, classes.darkBackground, "rounded-2 d-flex flex-column gap-2 p-3")}>
      {transactions.map((transaction) => {
        return (
          <Step.Item
            key={transaction.transactionId}
            id={String(transaction.transactionId)}
            className="d-block p-0"
            status={
              transaction.statusId === TransactionStatus.Pending ? "pending-transaction" : "committed-transaction"
            }
          >
            <MiniTransactionItem transaction={transaction} />
          </Step.Item>
        );
      })}
    </div>
  );
};

export default MiniTransactionsList;
