import { useEffect, useLayoutEffect, useRef } from "react";
import { Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { defaultTo, isNil } from "ramda";

import { publicRouteList } from "app/Router/RouterV2.types";
import { Banner, H } from "common/components/atoms";
import { StripePricingTable } from "common/components/molecules";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { notify } from "common/utils/notify/notifyFunction";
import { InvitedUser } from "store/InvitedUserModel";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./PricingTable.module.scss";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "onboard.company.pricingTable"),
  createTranslation(TranslationNS.common, "app.onboard"),
];

const PricingTable = () => {
  const navigate = useNavigate();
  const toasterRef = useRef<boolean>(false);
  const { companyId } = useParams<{ companyId?: string }>();

  const { subscription, connectCompanySubscription } = useStripeSubscription(Number(companyId));

  const account = useStoreState((state) => state.account);
  const invitedUser = useStoreState((state) => state.invitedUser);
  const setInvitedUser = useStoreActions((actions) => actions.invitedUser.setInvitedUser);

  const isAdmin = account?.user?.isSysAdmin;

  const handleAdminSkipRegistrationRequest = async () => {
    try {
      const request = await axios.post("/api/admin/add-subscription", {
        companyId,
      });

      if (request.status === 200) {
        navigate(`/${publicRouteList.subscriptionAccepted}`, {
          replace: true,
        });
      }
    } catch (e) {
      console.error({ e });
    }
  };

  // adding companyId info for invitedUser entity
  useEffect(() => {
    if (companyId) {
      if (
        isNil(invitedUser?.invitedUser?.companyId) ||
        defaultTo(0, invitedUser?.invitedUser?.companyId) !== Number(companyId)
      ) {
        const initialInvitedUser = defaultTo({}, invitedUser.invitedUser) as InvitedUser;

        setInvitedUser({ ...initialInvitedUser, companyId: Number(companyId), navigationFromApp: true });
      }
    }
  }, [companyId, invitedUser.invitedUser, setInvitedUser]);

  // if user has just 1 company and existing invitationID - triggering API call to apply existing subscription
  // with current company id and connecting subscription with stripe and unlisted API
  useEffect(() => {
    if (
      !subscription?.hasSubscription &&
      account?.user?.companies?.length === 1 &&
      invitedUser?.invitedUser?.entityId
    ) {
      connectCompanySubscription();
    }
  }, [
    account?.user?.companies?.length,
    connectCompanySubscription,
    invitedUser?.invitedUser?.entityId,
    subscription?.hasSubscription,
  ]);

  useLayoutEffect(() => {
    if (!toasterRef.current) {
      notify(tCommon("editLater"), true, "info", 8000);
      toasterRef.current = true;
    }
  }, []);

  return (
    <div className={classes.wrap}>
      <H.s className="mb-2">{t("title")}</H.s>
      {!isNil(companyId) ? <StripePricingTable companyId={String(companyId)} /> : null}

      <Row className="mt-3">
        {isAdmin ? (
          <div className={classes["ugly-button"]}>
            <button onClick={handleAdminSkipRegistrationRequest}>Subscribe company as admin</button>
          </div>
        ) : null}
        <Banner title={t("quantityTitle")} description={t("quantityDescription")} />
      </Row>
    </div>
  );
};

export default PricingTable;
