import { useParams } from "react-router-dom";

import { useStoreState } from "../../store/store";

const useCheckSubscription = () => {
  const { companyId = "0" } = useParams();
  const { account } = useStoreState((state) => state);

  return !!account.user?.companies?.find((el) => el.id === Number(companyId))?.hasSubscription;
};

export default useCheckSubscription;
