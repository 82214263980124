import { FC, useCallback, useEffect, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import cn from "classnames";
import { useFormikContext } from "formik";
import * as R from "ramda";
import { isNil } from "ramda";

import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Helper from "common/components/atoms/Helper/Helper";
import { InstrumentTypesIdsEnum, VestingTypesEnum } from "common/enums/enum";
import { newPlanFieldsNames as f } from "common/plan/planFormFields";
import VestingDetails from "common/program/components/ProgramForm/VestingDetails/VestingDetails";
import classes from "pages/equity-management/plans/wizards/create-plan/sidebar/form.module.scss";
import SpentProgressBar from "pages/equity-management/plans/wizards/create-program/components/SpentProgressBar/SpentProgressBar";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../../../../../create-one-off-plans/CreateGrantOneOffPlan";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

type VestingDetailsForm = Required<Pick<OwnershipPlanForm, "conditions">> & Omit<OwnershipPlanForm, "conditions">;

type VestingPlanDetailsProps = {
  instrumentType?: InstrumentTypesIdsEnum;
};

const VestingPlanDetails: FC<VestingPlanDetailsProps> = ({ instrumentType }) => {
  const { values, setFieldValue, errors, touched, handleBlur } = useFormikContext<VestingDetailsForm>();

  const isFormDisabled = !isNil(values.subscriptionAvailable) && !values.subscriptionAvailable.remainingAgreements;

  const optionsRemains = useMemo(() => {
    const totalOptionsForAllConditions =
      R.defaultTo(0, Number(values.timeVestedShares)) +
      values.conditions.reduce((acc, curr) => acc + R.defaultTo(0, Number(curr.numberOfOptions)), 0);

    const sharesForPlan = R.defaultTo(0, Number(values.numberOfShares));

    return {
      remains: sharesForPlan - totalOptionsForAllConditions,
      now: totalOptionsForAllConditions,
    };
  }, [values.timeVestedShares, values.conditions, values.numberOfShares]);

  const handleVestingStartDateChange = useCallback(
    (date: Date | string) => {
      setFieldValue(f.vestingStartsAt, date);
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (values.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING) {
      setFieldValue(f.timeVestedShares, 0);
      setFieldValue(f.vestingPeriod, undefined);
      setFieldValue(f.vestingInterval, undefined);
      setFieldValue(f.vestingCliff, undefined);
    } else if (values.vestingTypeId === VestingTypesEnum.TIME_VESTING) {
      setFieldValue(f.conditions, []);
    }
  }, [setFieldValue, values.vestingTypeId]);

  return (
    <>
      <Helper>
        <Row className="mb-3 align-items-center">
          <Col>
            <Helper.Question questionId="vestingStartsAt" type="input">
              <DatePicker
                isDateOnlyString
                label={t("vestingDate")}
                date={values.vestingStartsAt}
                name={f.vestingStartsAt}
                error={errors.vestingStartsAt}
                isTouched={touched.vestingStartsAt}
                onChange={handleVestingStartDateChange}
                onBlur={handleBlur}
                isDisabled={isFormDisabled}
              />
            </Helper.Question>
          </Col>
          <Col>
            <SpentProgressBar
              now={optionsRemains.now}
              max={values.numberOfShares}
              title={
                values.planTypeId === InstrumentTypesIdsEnum.RSA ? t("distributedShares") : t("distributedOptions")
              }
              className={cn(classes["progress-section"], "mt-2")}
              style={{
                opacity:
                  values.vestingTypeId === VestingTypesEnum.COMBINE_VESTING ||
                  values.vestingTypeId === VestingTypesEnum.MILESTONE_VESTING
                    ? 1
                    : 0,
              }}
              valueText={t("remaining", {
                value: optionsRemains.remains,
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Helper.Answer
              className="mb-3"
              answerId={f.vestingStartsAt}
              text={t("vestingDateAnswer")}
              linkText={t("vestingDetailsReadMore")}
              link="/"
            />
          </Col>
        </Row>
      </Helper>
      <VestingDetails type="plan" instrumentType={instrumentType} />
    </>
  );
};

export default VestingPlanDetails;
