import { FC, ReactNode, useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";

type ToggleProps = {
  children: ReactNode | JSX.Element;
  eventKey: string;
  className?: string;
};
import classNames from "classnames";

import { ChevronDownIcon } from "common/icons/svg";

import classes from "./ExpansionPanel.module.scss";
const Toggle: FC<ToggleProps> = ({ eventKey, children, className }) => {
  const decoratedOnClick = useAccordionButton(eventKey);
  const { activeEventKey } = useContext(AccordionContext);

  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  return (
    <div className={classNames(classes.toggle, className)} onClick={decoratedOnClick}>
      {children}
      <ChevronDownIcon className={classes.icon} fontSize={24} direction={isCurrentEventKey ? "top" : "bottom"} />
    </div>
  );
};
export default Toggle;
