import { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { MultiValue } from "react-select";
import axios from "axios";
import cn from "classnames";
import { useFormik } from "formik";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import DiscardModal from "common/components/atoms/DiscardModal/DiscardModal";
import DragDrop from "common/components/atoms/Drag&Drop/Drag&Drop";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import MultiSelect from "common/components/atoms/MultiSelect/MultiSelect";
import Tag from "common/components/atoms/Tag/Tag";
import TextArea from "common/components/atoms/TextArea/TextArea";
import TextField from "common/components/atoms/TextField/TextField";
import { H, Ui } from "common/components/atoms/Typography";
import ViewPitchButton from "common/components/atoms/ViewPitchButton/ViewPitchButton";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import Sidebar from "common/layout/MainLayout/Sidebar/Sidebar";
import { initBeforeUnLoad, transformDropDownsData } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { CommonObjectType } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import FileItem from "../../../common/components/atoms/FileItem/FileItem";
import { InformationCircleOutlinedIcon } from "../../../common/icons/svg";
import classes from "./pitch.module.scss";

const [translation, tCommon] = [
  createTranslation(TranslationNS.pages, "companyProfile.pitch"),
  createTranslation(TranslationNS.common, "noAccess"),
];

type ExtendedCommon = CommonObjectType & {
  businessModelId?: number;
  industryId?: number;
  verticalId?: number;
};

const apiBase = "/api/attract";

const CompanyProfilePitchPage: FC = () => {
  useDocumentTitleUpdate(translation("title"));

  const { companyId } = useParams<{ companyId: string }>();

  const { hasFullAccess } = useFeatures(FEATURES.attract);

  const pitch = useStoreState((state) => state.company.pitch);
  const industryDropDown = useStoreState((state) => state.common.dropdowns)?.industries;
  const verticalDropDown = useStoreState((state) => state.common.dropdowns)?.verticals;
  const businessModelsDropDown = useStoreState((state) => state.common.dropdowns)?.businessModels;

  const getPitch = useStoreActions((state) => state.company.getPitchThunk);

  const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
  const [pdfAttachment, setPdfAttachment] = useState<File | null>({} as File);
  const [isDiscardModalActive, setIsDiscardModalActive] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      oneliner: R.defaultTo("", pitch?.oneliner),
      elevatorPitch: R.defaultTo("", pitch?.elevatorPitch),
      mission: R.defaultTo("", pitch?.mission),
      vision: R.defaultTo("", pitch?.vision),
      problem: R.defaultTo("", pitch?.problem),
      solution: R.defaultTo("", pitch?.solution),
      competitiveAdvantage: R.defaultTo("", pitch?.competitiveAdvantage),
      introVideoUrl: R.defaultTo("", pitch?.introVideoUrl),
      industries: pitch?.industries?.sort(
        (a: CommonObjectType, b: CommonObjectType) => (a?.sortingIndex as number) - (b?.sortingIndex as number)
      ),
      verticals: pitch?.verticals?.sort(
        (a: CommonObjectType, b: CommonObjectType) => (a?.sortingIndex as number) - (b?.sortingIndex as number)
      ),
      businessModels: pitch?.businessModels?.sort(
        (a: CommonObjectType, b: CommonObjectType) => (a?.sortingIndex as number) - (b?.sortingIndex as number)
      ),
    }),
    [pitch]
  );

  const { values, resetForm, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  window.onload = function () {
    /* istanbul ignore next */
    initBeforeUnLoad(false);
  };

  function handleClose() {
    setIsDiscardModalActive(false);
  }

  function handleDiscard() {
    resetForm();
  }

  async function fileUpload() {
    try {
      if (!pdfAttachment) return;
      const formData = new FormData();
      formData.append("ImageFile", pdfAttachment as File);
      const request = await axios.post(`${apiBase}/pitch-file/${pitch?.id}`, formData);

      /* istanbul ignore next */
      if (request.status === 200) {
        notify(translation("notify.pdfFileUpdated"), true, "success");

        getPitch(Number(pitch?.id));
        setPdfAttachment(null);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }

  async function submitHandler() {
    setIsDataLoading(true);

    try {
      const pitchData = {
        id: pitch?.id,
        oneliner: values.oneliner,
        elevatorPitch: values.elevatorPitch,
        mission: values.mission ? values.mission : null,
        vision: values.vision ? values.vision : null,
        problem: values.problem ? values.problem : null,
        solution: values.solution ? values.solution : null,
        competitiveAdvantage: values.competitiveAdvantage ? values.competitiveAdvantage : null,
        introVideoUrl: values.introVideoUrl ? values.introVideoUrl : null,
        industries: values.industries?.map((el: ExtendedCommon, index) => ({
          industryId: el.industryId,
          sortingIndex: index,
        })),
        verticals: values.verticals?.map((el: ExtendedCommon, index) => ({
          verticalId: el.verticalId,
          sortingIndex: index,
        })),
        businessModels: values.businessModels?.map((el: ExtendedCommon, index) => ({
          businessModelId: el.businessModelId,
          sortingIndex: index,
        })),
      };

      const request = await axios.post(`${apiBase}/pitch`, pitchData);

      if (request.status === 200) {
        notify(translation("notify.changesSaved"), true, "success", 3000);
        await getPitch(Number(pitch?.id));
      }

      if (!R.isEmpty(pdfAttachment)) {
        await fileUpload();
      }
      // resetForm();
    } catch (e) {
      /* istanbul ignore next */
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      setTimeout(() => {
        setIsDataLoading(false);
      }, 500);
    }
  }

  useEffect(() => {
    if (companyId) {
      const fetchPitch = async () => {
        await getPitch(+companyId);
      };

      try {
        setIsDataLoading(true);
        fetchPitch();
      } finally {
        setIsDataLoading(false);
      }
    }
  }, [companyId, getPitch]);

  return (
    <>
      <PageContent data-testid="company-profile-pitch-test-id">
        <PageContent.Header>
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{translation("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>

          <ViewPitchButton id={Number(companyId)} />
        </PageContent.Header>
        <LoaderContainer loading={isDataLoading}>
          <div className="d-flex flex-column paper-container bg-white">
            <div className="d-flex flex-column mb-10">
              <TextArea
                isOptional
                maxLength={100}
                label={translation("oneliner.label")}
                placeholder={translation("oneliner.placeholder")}
                value={values.oneliner}
                onChange={handleChange("oneliner")}
              />

              <Ui.xs className="mt-1 mb-5">{translation("oneliner.description")}</Ui.xs>

              <TextArea
                isOptional
                maxLength={500}
                value={values.elevatorPitch}
                label={translation("shortIntro.label")}
                placeholder={translation("shortIntro.placeholder")}
                onChange={handleChange("elevatorPitch")}
              />

              <TextField
                isOptional
                className="my-5"
                value={values.mission}
                data-testid="mission-test-id"
                label={translation("showMorePitch.mission.label")}
                onChange={handleChange("mission")}
              />

              <TextField
                isOptional
                className="mb-5"
                value={values.vision}
                data-testid="vision-test-id"
                label={translation("showMorePitch.vision.label")}
                onChange={handleChange("vision")}
              />

              <TextArea
                isOptional
                className="mb-5"
                value={values.problem}
                data-testid="text-area-Problem we solve"
                label={translation("showMorePitch.problem.label")}
                onChange={handleChange("problem")}
              />

              <TextArea
                isOptional
                className="mb-5"
                data-testid="text-area-Our solution"
                value={values.solution}
                label={translation("showMorePitch.solution.label")}
                onChange={handleChange("solution")}
              />

              <TextArea
                isOptional
                value={values.competitiveAdvantage}
                data-testid="text-area-Competitive advantage"
                label={translation("showMorePitch.competitiveAdvantage.label")}
                onChange={handleChange("competitiveAdvantage")}
              />
            </div>

            <div className="d-flex flex-column mb-10">
              <H.xs className="mb-4">{translation("media.title")}</H.xs>

              <TextField
                isOptional
                type="video"
                className="mb-1"
                placeholder="http://"
                value={values.introVideoUrl}
                label={translation("media.introVideoUrl.label")}
                onChange={handleChange("introVideoUrl")}
              />

              <Ui.xs className="mb-5">{translation("media.introVideoUrl.hint")}</Ui.xs>

              <DragDrop
                isLabelOptional
                attachedFile={
                  (!R.isNil(pitch) &&
                    !R.isNil(pitch?.pitchDeckFilePath) &&
                    `${process.env.REACT_APP_BLOB_PUBLIC_URL}${pitch.pitchDeckFilePath}`) ||
                  undefined
                }
                content={translation("media.pdfAttachment.content")}
                customEndLabelContent={translation("media.pdfAttachment.endLabelContent")}
                label={translation("media.pdfAttachment.label")}
                onChange={(e: File) => {
                  setPdfAttachment(e);
                }}
              />

              <div
                className={cn("mt-1", classes["pdf-section"], {
                  [classes["pdf-section-active"]]: !R.isNil(pdfAttachment) && !R.isEmpty(pdfAttachment),
                })}
              >
                <div
                  data-testid="show-attachment-info-test-id"
                  className="d-flex justify-content-between"
                  style={{
                    height: !R.isNil(pdfAttachment) && !R.isEmpty(pdfAttachment) ? "auto" : 0,
                  }}
                >
                  <FileItem
                    file={{
                      fileId: 1,
                      documentTypeId: 1,
                      fileName: translation("media.pdfAttachment.selectedFile", {
                        name: pdfAttachment?.name,
                      }),
                    }}
                    onRemove={() => setPdfAttachment(null)}
                  />
                </div>
              </div>

              <div className="d-flex align-items-center">
                <div style={{ transform: "scale(0.8)", marginRight: 5 }}>
                  <InformationCircleOutlinedIcon color="#153EA6" />
                </div>
                <p className="m-0 ui-xs" style={{ opacity: 0.8 }}>
                  {translation("allowLookAtPitch")}
                </p>
              </div>
            </div>

            <div className="d-flex flex-column mb-10">
              <h4 className="head-xs m-0 mb-3">{translation("details.title")}</h4>

              <MultiSelect
                className="mb-1"
                isDraggable
                withMaxLimitLabel
                maxInputCount={10}
                label={translation("details.industries.label")}
                options={transformDropDownsData(industryDropDown)}
                defaultValues={transformDropDownsData(values.industries, "industryId")}
                onChange={(e: MultiValue<{ id: number; value: string }>) => {
                  const transformedData = e.map((el) => ({
                    industryId: el.id,
                    name: el.value,
                  }));

                  setFieldValue("industries", transformedData);
                }}
              />

              <p className="m-0 mb-5 ui-xs" style={{ opacity: 0.8 }}>
                {translation("firstPrimary")}
              </p>

              <MultiSelect
                isDraggable
                withMaxLimitLabel
                maxInputCount={10}
                label={translation("details.verticals.label")}
                options={transformDropDownsData(verticalDropDown)}
                defaultValues={transformDropDownsData(values.verticals, "verticalId")}
                onChange={(e: MultiValue<{ id: number; value: string }>) => {
                  const transformedData = e.map((el) => ({
                    verticalId: el.id,
                    name: el.value,
                  }));

                  setFieldValue("verticals", transformedData);
                }}
              />

              <p className="m-0 mt-1 mb-5 ui-xs" style={{ opacity: 0.8 }}>
                {translation("firstPrimary")}
              </p>

              <MultiSelect
                isDraggable
                withMaxLimitLabel
                maxInputCount={10}
                label={translation("businessModel")}
                options={transformDropDownsData(businessModelsDropDown)}
                defaultValues={transformDropDownsData(values?.businessModels, "businessModelId")}
                onChange={(e: MultiValue<{ id: number; value: string }>) => {
                  const transformedData = e.map((el) => ({
                    businessModelId: el.id,
                    name: el.value,
                  }));

                  setFieldValue("businessModels", transformedData);
                }}
              />
            </div>

            <div className="d-flex flex-column">
              <div className="d-flex flex-column" style={{ width: 120 }}>
                <Button
                  isDisabled={isDataLoading || !hasFullAccess}
                  isLoading={isDataLoading}
                  data-testid="pitch-page-submit-btn-test-id"
                  tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                  tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
                  onClick={() => handleSubmit()}
                >
                  {translation("save")}
                </Button>
              </div>
            </div>
            <DiscardModal
              isUser
              visible={isDiscardModalActive}
              handleClose={handleClose}
              handleDiscard={handleDiscard}
              handleSubmit={submitHandler}
            />
          </div>
        </LoaderContainer>
      </PageContent>

      <Sidebar>
        <div style={{ width: 320 }}></div>
      </Sidebar>
    </>
  );
};

export default CompanyProfilePitchPage;
