import { FC } from "react";
import { useFormikContext } from "formik";

import TextField, { TextFieldProps } from "common/components/atoms/TextField/TextField";
import { createTranslation, TranslationNS } from "translation";

import { fields as f, StakeholderFormValues } from "../Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder.fields");

type StakeholderFirstNameFieldProps = TextFieldProps & {};
const StakeholderEmailField: FC<StakeholderFirstNameFieldProps> = (props) => {
  const { values, errors, touched, handleChange, handleBlur } = useFormikContext<StakeholderFormValues>();

  const prefix = values.isCompanyOwned ? "isCompany." : "";

  return (
    <TextField
      {...props}
      label={t(`${prefix}email`)}
      value={values.email}
      error={errors.email}
      isTouched={touched.email}
      name={f.email}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default StakeholderEmailField;
