import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Collapsible, LinkHelpText, P, Table, Ui } from "common/components/atoms";
import { ChevronDownIcon, PlusIcon, QuestionCircleIcon } from "common/icons/svg";
import { CollapsibleHeaderProps, sortingParams } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import IssueEquityContext from "pages/equity-management/ownership/financial-instruments/IssueEquityContext";
import { createTranslation, TranslationNS } from "translation";

import { IssueEquityConvertibleNotesDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import ConvertibleNoteTableBody from "./ConvertibleNoteTableBody";
import ConvertibleNoteTableHead from "./ConvertibleNoteTableHead";
import ConvertibleNoteTableFooter from "./ConvertibNoteTableFooter";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.issueEquity.convertibleNote"),
  createTranslation(TranslationNS.common, "noAccess"),
];

export type ConvertibleNoteTableSortValue = {
  field: keyof IssueEquityConvertibleNotesDTO;
  type: keyof typeof sortingParams;
};

const ConvertibleNoteTable = () => {
  const { hasFullAccess } = useFeatures(FEATURES.issueEquity);

  const issueEquity = IssueEquityContext.useStoreState((state) => state.issueEquity);
  const setIsConvertibleNoteEditPanelOpen = IssueEquityContext.useStoreActions(
    (actions) => actions.setIsConvertibleNoteEditPanelOpen
  );

  const [activeSortedValue, setActiveSortedValue] = useState<ConvertibleNoteTableSortValue>({
    field: "stakholderName",
    type: sortingParams.inc,
  });

  const rows = useMemo(
    () => [
      {
        key: "stakholderName",
        value: t("table.head.stakeholders"),
      },
      {
        key: "investedAmount",
        value: t("table.head.investmentAmount"),
      },

      {
        key: "discount",
        value: t("table.head.discount"),
      },
      {
        key: "triggerAmount",
        value: t("table.head.triggerAmount"),
      },
      {
        key: "expirationDate",
        value: t("table.head.expirationDate"),
      },
      {
        key: "status",
        value: t("table.head.status"),
      },
    ],
    []
  );

  const handleCreate = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setIsConvertibleNoteEditPanelOpen({ open: true });
    },
    [setIsConvertibleNoteEditPanelOpen]
  );

  const CollapsibleHeader = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div className={classes["collapsible"]} onClick={onClick}>
          <div className="d-flex align-items-center">
            <Ui.m bold className="me-1">
              {t("table.title")}
            </Ui.m>

            <LinkHelpText title="" content={t("table.infoDescription")}>
              <QuestionCircleIcon width={20} height={20} color={scssVariables.foregroundLow} />
            </LinkHelpText>
          </div>
          <div className="d-flex">
            <Button
              size="s"
              isOnlyIcon
              variant="secondary"
              isDisabled={!hasFullAccess}
              className={cn(classes["add-btn"], "me-5")}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : ""}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              onClick={handleCreate}
            >
              <PlusIcon />
            </Button>
            <div className={classes.icon}>
              <ChevronDownIcon
                fontSize={24}
                color={scssVariables.foregroundMedium}
                direction={activeEventKey ? "top" : "bottom"}
              />
            </div>
          </div>
        </div>
      );
    },
    [handleCreate, hasFullAccess]
  );

  return (
    <Collapsible defaultOpen className="mb-5" headTitle={t("table.title")} Header={CollapsibleHeader}>
      {R.or(R.isNil(issueEquity?.convertibleNotes), !issueEquity?.convertibleNotes?.length) ? (
        <div className={classes["empty-container"]}>
          <div className={classes["empty"]}>
            <P.s>{t("empty")}</P.s>
          </div>
        </div>
      ) : (
        <Table
          headComponent={
            <ConvertibleNoteTableHead
              rows={rows}
              sortedValue={activeSortedValue}
              setSortedValue={setActiveSortedValue}
            />
          }
          bodyComponent={
            <ConvertibleNoteTableBody
              sortedValue={activeSortedValue}
              data={R.defaultTo([], issueEquity?.convertibleNotes)}
            />
          }
          footComponent={
            <ConvertibleNoteTableFooter
              investment={(issueEquity?.convertibleNotes || []).reduce((acc, curr) => acc + curr.investedAmount, 0)}
            />
          }
        />
      )}
    </Collapsible>
  );
};

export default ConvertibleNoteTable;
