import { FC } from "react";

import DocumentList from "common/components/molecules/DocumentList/DocumentList";
import EventHistory from "common/components/molecules/EventHistory/EventHistory";
import { InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId, VestingTypesEnum } from "common/enums/enum";

import RSAAgreementContext from "../../../contexts/RSAAgreementContext";
import MilestonesModal from "../../common/agreement-actions/milestones-modal/MilestonesModal";
import ExpiredInfo from "../../common/expired-info/ExpiredInfo";
import Milestones from "../../common/milestones/Milestones";
import ReleaseScheduleChart from "../../common/release-schedule-chart/ReleaseScheduleChart";
import TerminationInfo from "../../common/termination-info/TerminationInfo";
import ValuationChart from "../../common/valuation-chart/ValuationChart";
import Overview from "../overview/Overview";
import RSAAgreementDetails from "../rsa-agreement-details/RSAAgreementDetails";

/**
 * Description: rsa agreement main template that's using into slide and regular UI types.
 * Contains all information for  stock options agreement type
 */

const RSAAgreementTemplate: FC = () => {
  const agreementDetails = RSAAgreementContext.useStoreState((state) => state.agreementDetails);
  const { getAgreementDetailsThunk } = RSAAgreementContext.useStoreActions((actions) => actions);

  const isAgreementExpired = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.expired;

  return (
    <>
      <ExpiredInfo className="mb-3" type={InstrumentTypesNamesEnum.RSA} />

      <TerminationInfo className="mb-3" type={InstrumentTypesNamesEnum.RSA} />

      <Overview />

      {agreementDetails?.vestingTypeId !== VestingTypesEnum.MILESTONE_VESTING && (
        <ReleaseScheduleChart className="my-3" agreement={agreementDetails} isLosted={isAgreementExpired} />
      )}

      <Milestones className="my-3" type={InstrumentTypesNamesEnum.RSA} />

      <ValuationChart agreement={agreementDetails} />

      <RSAAgreementDetails />

      <EventHistory collapsible data={agreementDetails?.eventHistory} />

      <DocumentList
        className="mt-3"
        documents={agreementDetails?.documentFiles || []}
        isDeleting={agreementDetails?.planStatusId !== PlanStatusesBasedOnAPIStatusId.active}
        cbAfterAction={() => {
          getAgreementDetailsThunk(Number(agreementDetails?.id));
        }}
      />

      <MilestonesModal type={InstrumentTypesNamesEnum.RSA} />
    </>
  );
};

export default RSAAgreementTemplate;
