import { useEffect, useState } from "react";
import axios from "axios";

import { CompanyCardProps } from "common/landingDashboard/components/CompanyCard";

const useGetLandingCompanies = () => {
  const [companies, setCompanies] = useState<CompanyCardProps[]>([]);

  const getCompanies = async () => {
    try {
      const request = await axios.get("/api/landing");

      if (request.status === 200) {
        setCompanies(request.data.companies);
      }
    } catch (e) {
      console.warn({ e });
    }
  };

  useEffect(() => {
    getCompanies();
  }, []);

  return {
    companies,
  };
};

export default useGetLandingCompanies;
