import { FC, memo, useCallback } from "react";
import { useFormikContext } from "formik";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import FileUploader, { FilesDataMultiple, FilesDataSingle } from "common/components/atoms/FileUploader/FileUploader";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { createTranslation, TranslationNS } from "translation";

import { summaryFields } from "../form-fields";
import { DocumentStatuses } from "../summary";
import { SummaryFormNewType } from "../types";

const t = createTranslation(TranslationNS.pages, "createPlan.summary");

const ProgramDocument: FC = () => {
  const { values, setFieldValue, handleChange } = useFormikContext<SummaryFormNewType>();

  const handleFileChange = useCallback(
    (data: FilesDataSingle | FilesDataMultiple) => {
      setFieldValue(summaryFields.documentFiles, data);
    },
    [setFieldValue]
  );

  return (
    <>
      <div className="mb-5 mt-6">
        <FileUploader
          multiple
          isOptional
          label={t("uploadDocuments")}
          onChange={handleFileChange}
          prevFileData={values.documentFiles?.oldFiles}
        />
      </div>
      <InputHeader label={t("documentStatus")} isOptional={true} />
      <ChecksGroup className="mb-6">
        <ChecksGroup.Check
          type="radio"
          name={summaryFields.documentationStatusId}
          label={t("uploaded")}
          checked={+values.documentationStatusId === DocumentStatuses.AllUploaded}
          value={DocumentStatuses.AllUploaded}
          onChange={handleChange}
        />
        <ChecksGroup.Check
          type="radio"
          name={summaryFields.documentationStatusId}
          label={t("willUpload")}
          checked={+values.documentationStatusId === DocumentStatuses.WillUpload}
          value={DocumentStatuses.WillUpload}
          onChange={handleChange}
        />
        <ChecksGroup.Check
          type="radio"
          name={summaryFields.documentationStatusId}
          label={t("noDocuments")}
          checked={+values.documentationStatusId === DocumentStatuses.NotNeeded}
          value={DocumentStatuses.NotNeeded}
          onChange={handleChange}
        />
      </ChecksGroup>
    </>
  );
};

export default memo(ProgramDocument);
