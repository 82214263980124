import { ComponentType } from "react";
import { motion } from "framer-motion";

import {
  animationVariants,
  pageAnimationTransition,
} from "common/utils/framer-animation-constants";

export default function WithRouteAnimation<T extends {}>(
  WrappedComponent: ComponentType<T>
): ComponentType<T> {
  return (props: T) => (
    <motion.div
      exit="out"
      animate="in"
      initial="initial"
      variants={animationVariants}
      transition={pageAnimationTransition}
     /* style={{
        top: 0,
        display: "flex",
        alignItems: "center",
        position: "absolute",
      }}*/
    >
      <WrappedComponent {...props} />
    </motion.div>
  );
}
