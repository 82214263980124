import React, { FC, KeyboardEvent, MouseEventHandler, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import Select, {
  ActionMeta,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuProps,
  MultiValue,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  OptionProps,
  Options,
  Props,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEndHandler,
} from "react-sortable-hoc";
import * as R from "ramda";

import { createTranslation } from "translation/helpers";

import { FilledArrowDownIcon } from "../../../icons/svg";
import InputFeedback from "../input-blocks/input-feedback/input-feedback";
import MultiSelectStyles from "./MultiSelectStyles";

type MultiSelectProps = {
  label: string;
  isCreatable?: boolean;
  options?: any[];
  error?: string;
  className?: string;
  isDraggable?: boolean;
  isMulti?: boolean;
  defaultValues?: OnChangeValue<any, true>;
  onCreateOption?: (value: string) => void;
  maxInputCount?: number;
  isLabelOptional?: boolean;
  withMaxLimitLabel?: boolean;
  inputWidth?: number;
  placeholder?: string;
  onChange?: (newValue: MultiValue<any>, actionMeta?: ActionMeta<unknown>) => void;
};

const translation = createTranslation("common", "components.multiSelect");

function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = R.clone(array) as Array<T>;

  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);

  return slicedArray;
}

const SortableMultiValue = SortableElement((props: MultiValueProps<any>) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const findIndex = props.selectProps.value.findIndex((el: any) => el.id === props.data.id);

  const innerProps = {
    ...props.innerProps,
    onMouseDown,
    isFirstData: findIndex === 0,
  };

  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select) as React.ComponentClass<Props<any, true> & SortableContainerProps>;

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => (
  <components.DropdownIndicator {...props}>
    <FilledArrowDownIcon color="#444444" />
  </components.DropdownIndicator>
);

/* istanbul ignore next */
const Option = (props: OptionProps<any>) => {
  return (
    <components.Option {...props}>
      <Form.Check.Input required type="checkbox" name="termsAgreed" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.label}</label>
    </components.Option>
  );
};

const MultiSelect: FC<MultiSelectProps> = ({
  label,
  isCreatable,
  onCreateOption,
  options,
  className,
  isLabelOptional,
  withMaxLimitLabel,
  maxInputCount = 5,
  defaultValues = [],
  onChange,
  error,
  inputWidth,
  placeholder,
  isDraggable,
  isMulti = true,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [, setValues] = useState<OnChangeValue<any, true>>(defaultValues);

  const withoutOptions = useMemo(() => R.isEmpty(options), [options]);

  const controlStyles = (base: any) => ({
    ...base,
    border: Boolean(error) && "2px solid #ff9d42",
  });

  const isValidNewOption = (inputValue: string, selectValue: Options<unknown>) =>
    inputValue.length > 0 && selectValue.length < maxInputCount;

  /* istanbul ignore next */
  const renderMenuComponent = (props: MenuProps<unknown, true, GroupBase<unknown>>) => {
    const optionSelectedLength = props.getValue().length || 0;

    return (
      <components.Menu {...props}>
        {optionSelectedLength < maxInputCount ? (
          props.children
        ) : (
          <div style={{ margin: 15 }}>{translation("maxLimit")}</div>
        )}
      </components.Menu>
    );
  };

  function changeHandler(values: OnChangeValue<any, true>) {
    /* istanbul ignore next */
    setValues(values);
    /* istanbul ignore next */
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValues = arrayMove(defaultValues, oldIndex, newIndex);

    onChange && onChange(newValues);
  };

  function onKeyDown(event: KeyboardEvent) {
    /* istanbul ignore next */
    if (!inputValue) return;
    /* istanbul ignore next */
    if (event.key === "Enter" || event.key === "Tab") {
      /* istanbul ignore next */
      setValues([...defaultValues, { label: inputValue, value: inputValue }]);
      /* istanbul ignore next */
      setInputValue("");
      /* istanbul ignore next */
      onChange && onChange([...defaultValues, { label: inputValue, value: inputValue }]);
    }
  }

  useEffect(() => {
    if (withoutOptions) {
      setValues(defaultValues);
    }
  }, [defaultValues, withoutOptions]);

  return (
    <div
      className={className}
      style={{ position: "relative", width: inputWidth }}
      data-testid="multi-select-component-container-test-id"
    >
      <div className="d-flex justify-content-between align-items-center mb-1">
        <p className="m-0 ui-s" style={{ fontWeight: 500 }}>
          {label}
          {isLabelOptional && (
            <>
              {" "}
              <span style={{ color: "#858792", fontWeight: "normal", fontSize: 16 }}>
                {translation("optionalLabel")}
              </span>
            </>
          )}
        </p>

        {withMaxLimitLabel && maxInputCount && (
          <p className="m-0 paragraph-s" style={{ color: "#858792" }} data-testid="multi-select-max-input-test-id">
            {translation("maxInputCount", { maxInputCount })}
          </p>
        )}
      </div>

      {isCreatable ? (
        <CreatableSelect
          isMulti={isMulti as any}
          options={options}
          inputValue={inputValue}
          placeholder={placeholder}
          value={withoutOptions ? defaultValues : undefined}
          closeMenuOnSelect={false}
          styles={{
            ...MultiSelectStyles,
            control: controlStyles,
          }}
          hideSelectedOptions={false}
          onCreateOption={onCreateOption}
          isValidNewOption={isValidNewOption}
          menuIsOpen={withoutOptions ? false : undefined}
          components={{
            Menu: renderMenuComponent,
            DropdownIndicator,
            Option,
          }}
          onChange={withoutOptions ? changeHandler : onChange}
          onKeyDown={withoutOptions ? onKeyDown : undefined}
          onInputChange={setInputValue}
        />
      ) : isDraggable ? (
        <SortableSelect
          isMulti={isMulti as any}
          axis="xy"
          useDragHandle
          options={options}
          value={defaultValues}
          placeholder={placeholder}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          styles={{
            ...MultiSelectStyles,
            control: (base: any) => ({
              ...base,
              minHeight: 48,
              border: error ? "2px solid #fc9b42" : "2px solid #58585C",
            }),
          }}
          components={{
            Menu: renderMenuComponent,
            DropdownIndicator,
            Option,
            // @ts-ignore We're failing to provide a required index prop to SortableElement
            MultiValue: SortableMultiValue,
            MultiValueLabel: SortableMultiValueLabel as unknown as any,
          }}
          getHelperDimensions={({ node }) => node.getBoundingClientRect()}
          onSortEnd={onSortEnd}
          onChange={onChange}
        />
      ) : (
        <Select
          isMulti={isMulti as any}
          value={defaultValues}
          placeholder={placeholder}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={options}
          styles={{
            ...MultiSelectStyles,
            control: (base: any) => ({
              ...base,
              height: 48,
              border: error ? "2px solid #fc9b42" : "2px solid #58585C",
            }),
          }}
          components={{
            Menu: renderMenuComponent,
            DropdownIndicator,
            Option,
          }}
          onChange={onChange}
        />
      )}
      {error && (
        <div data-testid="multi-select-error-container-test-id">
          <InputFeedback error={error} isTouched />
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
