import { FC, useCallback } from "react";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import ConvertNoteForm from "./ConvertNoteForm";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertNote");

const ConvertNotePanel: FC = () => {
  const isConvertNoteEditPanelOpen = IssueEquityContext.useStoreState((state) => state.isConvertNoteEditPanelOpen);

  const setNoteSharePrice = IssueEquityContext.useStoreActions((actions) => actions.setNoteSharePrice);
  const setIsConvertNoteEditPanelOpen = IssueEquityContext.useStoreActions(
    (actions) => actions.setIsConvertNoteEditPanelOpen
  );

  const handleClose = useCallback(() => {
    setIsConvertNoteEditPanelOpen({ open: false });
    setNoteSharePrice(undefined);
  }, [setIsConvertNoteEditPanelOpen, setNoteSharePrice]);

  return (
    <SlidePanel show={isConvertNoteEditPanelOpen}>
      <SlidePanel.Header title={t("title")} onHide={handleClose} />
      <ConvertNoteForm />
    </SlidePanel>
  );
};

export default ConvertNotePanel;
