import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import * as R from "ramda";

import DefaultImage from "common/components/atoms/FileUploadSection/DefaultImage/DefaultImage";
import { useStoreState } from "store/store";

type UserProfileImageProps = {
  width?: number;
  height?: number;
};

const UserProfileImage: React.FC<UserProfileImageProps> = ({ width = 24, height = 25 }) => {
  const { user } = useStoreState((state) => state.account);
  const [previewUrl, setPreviewUrl] = useState<string>();

  useEffect(() => {
    /* istanbul ignore next */
    if (!R.isNil(user) && !R.isNil(user?.profileImageFilePath)) {
      setPreviewUrl(`${process.env.REACT_APP_BLOB_PUBLIC_URL}${user.profileImageFilePath}`);
    }
  }, [user]);

  return (
    <>
      {previewUrl ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ width: 24, height: 24, marginRight: 8 }}
        >
          <Image alt="profile" roundedCircle src={previewUrl} style={{ height, width }} />
        </div>
      ) : (
        <DefaultImage defaultImageType="profile" imageWidth={height} imageHeight={width} />
      )}
    </>
  );
};

export default UserProfileImage;
