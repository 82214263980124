import React from "react";

import { Button, H, ModalInfo, P } from "common/components/atoms";
import { CheckIcon } from "common/icons/svg";
import { useStoreActions, useStoreState } from "store/store";

import classes from "./styles.module.scss";

const InvitationSentModal = () => {
  const { isInviteSentModalOpen } = useStoreState((store) => store.stakeholderInvitation);
  const { closeInviteSentModalModal } = useStoreActions((store) => store.stakeholderInvitation);

  return (
    <ModalInfo show={isInviteSentModalOpen} centered>
      <div className={classes["circle"]}>
        <CheckIcon fontSize={48} />
      </div>

      <H.xs className="my-3">Invitation sent</H.xs>

      <P.m>
        We’ve sent an invitation email to Chloee. By following the instructions, they will be able to access Unlisted
        with the role you assigned.
      </P.m>

      <Button
        className="mt-5"
        onClick={() => {
          closeInviteSentModalModal();
        }}
      >
        Got it
      </Button>
    </ModalInfo>
  );
};

export default InvitationSentModal;
