import { FC, MouseEventHandler, useCallback, useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty, isNil } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button } from "common/components/atoms";
import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon, PlusIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import DocumentsContext, { SingleProgramDocumentType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import NestedTableRow from "../../PoolDocuments/PoolDocumentsTable/NestedTableRow";
import AgreementsHeadingTableRow from "./AgreementsHeadingTableRow";

type CompanyDocumentsTableRowProps = {
  data: SingleProgramDocumentType;
};

const ProgramDocumentsTableRow: FC<CompanyDocumentsTableRowProps> = ({ data }) => {
  const { hasFullAccess } = useFeatures(FEATURES.documents);

  const { searchValue } = DocumentsContext.useStoreState((state) => state);
  const { setIsEditPanelOpen, setSelectedSingleProgram } = DocumentsContext.useStoreActions((actions) => actions);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const rowClickHandler = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const uploadHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      setSelectedSingleProgram(data);

      setIsEditPanelOpen({
        isOpen: true,
        documentType: "program",
      });
    },
    [data, setIsEditPanelOpen, setSelectedSingleProgram]
  );

  useEffect(() => {
    if (searchValue.trim() && !isOpen) {
      rowRef?.current?.click();
    }
  }, [searchValue, isOpen]);

  return (
    <>
      <motion.tr
        ref={rowRef}
        data-testid="company-documents-table-single-row-test-id"
        className={cn(classes["table-row"], {
          [classes.hovered]: isHovered,
        })}
        onHoverEnd={setIsHovered.bind(null, false)}
        onHoverStart={setIsHovered.bind(null, true)}
        onClick={rowClickHandler}
      >
        <td>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center position-relative">
              <motion.div
                initial={{ transform: "rotate(0deg)" }}
                className="me-2"
                animate={{
                  transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              >
                <FilledArrowDownIcon color={scssVariables.foregroundLow} />
              </motion.div>

              <Ui.m className="ms-3 fw-500">
                <Highlighter
                  autoEscape={true}
                  textToHighlight={data.name}
                  searchWords={[searchValue as string]}
                  highlightClassName={classes.highligted}
                  unhighlightClassName={classes.unhighlited}
                />
              </Ui.m>

              {isHovered ? (
                <Button
                  size="s"
                  variant="tertiary"
                  iconLeft={<PlusIcon />}
                  className={classes["upload-btn"]}
                  isDisabled={!hasFullAccess}
                  onClick={uploadHandler}
                >
                  Upload
                </Button>
              ) : null}
            </div>

            {data.missingDocuments ? (
              <WarningIcon fontSize={24} color={scssVariables.warning700} className="ms-auto me-1" />
            ) : null}
          </div>
        </td>
      </motion.tr>

      <AnimatePresence>
        {isOpen && !isNil(data.programDocuments) && !isEmpty(data.programDocuments)
          ? data.programDocuments.map((el) => <NestedTableRow key={el.downloadId} data={el} />)
          : null}

        {isOpen && data ? <AgreementsHeadingTableRow data={data.agreements} /> : null}
      </AnimatePresence>
    </>
  );
};

export default ProgramDocumentsTableRow;
