import axios from "axios";
import { action, thunk } from "easy-peasy";

import { InvestmentEntityModel } from "./types";

export const investmentEntity: InvestmentEntityModel = {
  // selectors
  generalInfo: null,
  teamMembers: null,
  contactPersonDropdown: null,
  investmentThesis: null,
  //actions
  setGeneralInfo: action((state, payload) => {
    state.generalInfo = payload;
  }),
  setTeamMembers: action((state, payload) => {
    state.teamMembers = payload;
  }),
  setContactPersonDropdown: action((state, payload) => {
    state.contactPersonDropdown = payload.map((person) => {
      return {
        name: `${person.firstName} ${person.lastName}`,
        teamMemberId: person.teamMemberId,
      };
    });
  }),
  setInvestmentThesis: action((state, payload) => {
    state.investmentThesis = payload;
  }),
  // thunks
  getGeneralInfoThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/investment-entity/general/${id}`);

      if (request.status === 200) {
        actions.setGeneralInfo(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getTeamMembersThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/investment-entity/team/${id}`);

      if (request.status === 200) {
        actions.setTeamMembers(request.data?.teamMembers);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getContactPersonDropdownThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/investment-entity/contact-person-drop-down/${id}`);

      if (request.status === 200) {
        actions.setContactPersonDropdown(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
  getInvestmentThesisThunk: thunk(async (actions, id) => {
    try {
      const request = await axios.get(`/api/investment-thesis/${id}`);

      if (request.status === 200) {
        actions.setInvestmentThesis(request.data);
      }
      if (request.status === 204) {
        actions.setInvestmentThesis({
          id,
          oneliner: "",
          shortIntroduction: "",
          investmentEntityId: -1,
          companyPhases: [],
          fundingStages: [],
          minimumInvestmentAmount: 0,
          maximumInvestmentAmount: 0,
          industries: [],
          verticals: [],
          geographicalAreas: [],
          countries: [],
        });
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    }
  }),
};
