import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import ContextMenu, { ContextMenuProps } from "common/components/atoms/ContextMenu/ContextMenu";
import { MenuTabBarVerticalIcon, PieChartIcon, TransactionsIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext from "../../MyPortfolioContext";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview");

type ActionsProps = {
  companyId: number;
  companyName: string;
  hasAccessToCaptable?: boolean;
};

const Actions: FC<ActionsProps> = ({ companyId, companyName, hasAccessToCaptable }) => {
  const navigate = useNavigate();
  const openTransactions = MyPortfolioContext.useStoreActions((actions) => actions.openTransactionsPreview);

  const handleViewCompany = useCallback(() => {
    navigate(getPath(["equityManagement", "ownership", "capTable"], { companyId: companyId }));
  }, [companyId, navigate]);

  const handleViewTransactions = useCallback(() => {
    openTransactions({ companyId, companyName });
  }, [companyId, companyName, openTransactions]);

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const menuItems: ContextMenuProps["items"] = [
      {
        key: "viewCompany",
        label: t("viewCompany"),
        icon: <PieChartIcon />,
        onClick: handleViewCompany,
        isDisabled: !hasAccessToCaptable,
      },
      {
        key: "viewTransactions",
        label: t("viewTransactions"),
        icon: <TransactionsIcon />,
        onClick: handleViewTransactions,
      },
    ];
    return menuItems;
  }, [handleViewCompany, handleViewTransactions, hasAccessToCaptable]);

  return (
    <ContextMenu items={menuItems} className="w-100">
      <Button size="m" isOnlyIcon variant="tertiary" data-testcompanyid="plan-dropdown-btn" className="w-100">
        <MenuTabBarVerticalIcon color="#2B011F" />
      </Button>
    </ContextMenu>
  );
};

export default Actions;
