import { FC, useRef } from "react";
import { animate, useIsomorphicLayoutEffect } from "framer-motion";

import useFormatNumbers, { Config, NumberType } from "common/hooks/useFormatNumbers";

type Props = {
  from: number;
  to: number;
  formatterConfig?: {
    type: NumberType;
    config: Config;
  };
};

const AnimatedText: FC<Props> = ({ to, from, formatterConfig }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const fNumber = useFormatNumbers(formatterConfig?.config.customCurrencySymbol);

  useIsomorphicLayoutEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    element.textContent = fNumber(String(from), formatterConfig?.type);

    const controls = animate(from, to, {
      duration: 0.2,
      ease: "linear",
      onUpdate(value) {
        element.textContent = fNumber(String(value), formatterConfig?.type);
      },
    });

    return () => {
      controls.stop();
    };
  }, [ref, from, to]);

  return <span ref={ref} />;
};

export default AnimatedText;
