import { Helper } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import ProgramHelper from "../../../../../wizards/create-program/components/ProgramHelper";
import ProgramDescriptionField from "../../../../../wizards/create-program/programFields/ProgramDescriptionField";
import ProgramNameField from "../../../../../wizards/create-program/programFields/ProgramNameField";

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.editProgram");
const ProgramDetailsFields = () => {
  return (
    <Helper>
      <ProgramHelper questionId="name">
        <ProgramNameField />
      </ProgramHelper>

      <ProgramHelper colInputSize={12} className="mt-4" questionId="description">
        <ProgramDescriptionField />
      </ProgramHelper>
    </Helper>
  );
};
export default ProgramDetailsFields;
