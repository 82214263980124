import { useMemo } from "react";
import { useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import TextArea from "common/components/atoms/TextArea/TextArea";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData, fields } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.basics");

const ProgramEssentialTermsField = () => {
  const { handleBlur, values, errors, touched, handleChange } = useFormikContext<CreateProgramFormData>();

  const answer = useMemo(() => ({ render: () => t.el("essentialTerms.answer") }), []);

  return (
    <Helper.Question questionId="essentialTerms" type="input" answer={answer}>
      <TextArea
        rows={6}
        isOptional
        label={t("essentialTerms.label")}
        name={fields.essentialTerms}
        value={values.essentialTerms}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.essentialTerms}
        isTouched={touched.essentialTerms}
        info={t("essentialTerms.info")}
      />
    </Helper.Question>
  );
};

export default ProgramEssentialTermsField;
