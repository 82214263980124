import { ChangeEvent, FC, memo, ReactNode, useCallback } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";

import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";

import Check from "../../Checks/Check";
import classes from "./inputs.module.scss";

type CheckboxInputProps = {
  placeholder?: string;
  className?: string;
  columnId: string;
  rowIndex: number;
  canDoActionField?: string;
  popupContent?: ReactNode;
};

type FormikValues = {
  [key: string]: string | number | undefined | boolean;
};

const CheckboxInput: FC<CheckboxInputProps> = memo(
  ({ placeholder = "", className, columnId, rowIndex, canDoActionField, popupContent }) => {
    const { values, handleBlur, errors, touched, setFieldValue } = useFormikContext<FormikValues[]>();

    const error = errors[rowIndex]?.[columnId];
    const value = values[rowIndex]?.[columnId];
    const touchedValue = touched?.[rowIndex]?.[columnId];

    const disabled = canDoActionField ? values[rowIndex]?.[canDoActionField] === false : false;

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setFieldValue(e.target.name, e.target.checked);
      },
      [setFieldValue]
    );

    return (
      <Tooltip placement="right" show={disabled ? undefined : false} popupContent={popupContent} rootCloseDisabled>
        <div>
          {error && touchedValue && (
            <div className={classes.errorIcon}>
              <Tooltip className={classes.errorTooltip} popupContent={error}>
                <span>
                  <WarningExclamationMarkIcon />
                </span>
              </Tooltip>
            </div>
          )}

          <Check
            name={`[${rowIndex}].${columnId}`}
            placeholder={placeholder}
            className={classNames(className)}
            checked={!!value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
        </div>
      </Tooltip>
    );
  }
);

export default CheckboxInput;
