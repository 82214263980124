import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { defaultTo } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import { FilledArrowDownIcon, MenuTabBarVerticalIcon, PlusIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import DocumentsContext, { SingleProgramDocumentType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import EmptyDocumentsRow from "../../EmptyDocumentsRow";
import NestedAgreementTableRow from "./NestedAgreementTableRow";

const UploadIcon = () => (
  <div className="d-flex align-items-center">
    <PlusIcon width={24} height={24} className="me-2" />
    <Ui.m>Upload</Ui.m>
  </div>
);

const NestedTableRow: FC<{ data: SingleProgramDocumentType["agreements"][0] }> = ({ data }) => {
  const { hasFullAccess } = useFeatures(FEATURES.documents);

  const { searchValue } = DocumentsContext.useStoreState((state) => state);
  const { setIsEditPanelOpen, setSelectedSingleAgreement } = DocumentsContext.useStoreActions((actions) => actions);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const filteredDocuments = useMemo(() => {
    const docs = defaultTo([], data?.documents).filter((el) =>
      el.fileName.toLowerCase().includes(searchValue.toLowerCase())
    );

    return docs;
  }, [data?.documents, searchValue]);

  const items = useMemo<ContextMenuProps["items"]>(
    () => [
      {
        label: <UploadIcon />,
        key: "Upload",
        isDisabled: !hasFullAccess,
        onClick: () => {
          setSelectedSingleAgreement(data);

          setIsEditPanelOpen({
            isOpen: true,
            documentType: "agreement",
          });
        },
      },
    ],
    [data, hasFullAccess, setIsEditPanelOpen, setSelectedSingleAgreement]
  );

  const rowClickHandler = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const uploadHanlder: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      setSelectedSingleAgreement(data);

      setIsEditPanelOpen({
        isOpen: true,
        documentType: "agreement",
      });
    },
    [data, setIsEditPanelOpen, setSelectedSingleAgreement]
  );

  useEffect(() => {
    if (searchValue.trim() && !isOpen) {
      rowRef?.current?.click();
    }
  }, [searchValue, isOpen]);

  return (
    <>
      <motion.tr
        ref={rowRef}
        className={cn(classes["table-row"], {
          [classes["hovered"]]: isHovered,
          [classes["selected"]]: isOpen,
        })}
        onHoverEnd={setIsHovered.bind(null, false)}
        onHoverStart={setIsHovered.bind(null, true)}
        onClick={rowClickHandler}
      >
        <td
          className={cn(classes["nested"], {
            [classes["inner-nested-first"]]: true,
          })}
        >
          <div className="d-flex w-100 align-items-center position-relative">
            <motion.div
              initial={{ transform: "rotate(0deg)" }}
              className="me-2"
              animate={{
                transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
              }}
            >
              <FilledArrowDownIcon className={classes.arrow} color={scssVariables.foregroundLow} />
            </motion.div>

            <div className="position-relative d-flex align-items-center">
              <Ui.m className="fw-500">
                <Highlighter
                  autoEscape={true}
                  textToHighlight={data.name}
                  searchWords={[searchValue as string]}
                  highlightClassName={classes.highligted}
                  unhighlightClassName={classes.unhighlited}
                />
              </Ui.m>

              {isHovered ? (
                <Button
                  size="s"
                  variant="tertiary"
                  iconLeft={<PlusIcon />}
                  className={classes["upload-btn"]}
                  isDisabled={!hasFullAccess}
                  onClick={uploadHanlder}
                >
                  Upload
                </Button>
              ) : null}
            </div>
          </div>
        </td>
      </motion.tr>

      <AnimatePresence>
        {!isOpen ? null : filteredDocuments.length ? (
          filteredDocuments.map((el, index) => (
            <NestedAgreementTableRow key={`Inner table row index - ${index}`} data={el} />
          ))
        ) : (
          <EmptyDocumentsRow
            isRequired={data.missingDocuments}
            iconClassName="ms-13"
            actions={
              <ContextMenu items={items} drop="down" className="ms-auto">
                <Button
                  isOnlyIcon
                  variant="tertiary"
                  data-testid="plan-dropdown-btn"
                  className={classes["menu-button"]}
                >
                  <MenuTabBarVerticalIcon />
                </Button>
              </ContextMenu>
            }
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default NestedTableRow;
