import { FC } from "react";
import cn from "classnames";

import { H } from "common/components/atoms/Typography";

const Title: FC<JSX.IntrinsicElements["h1"]> = ({ className, children, ...props }) => (
  <H.l className={cn(className)} {...props}>
    {children}
  </H.l>
);

export default Title;
