import React, { FC, useEffect } from "react";
import classNames from "classnames";
import { equals, isNil } from "ramda";

import { Button } from "common/components/atoms";
import { InstrumentTypesNamesEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";

import { getCurrentAgreementContext } from "../functions";
import classes from "./SimulationActions.module.scss";

export enum SimulationValues {
  "2x" = "2x",
  "3x" = "3x",
  "5x" = "5x",
  "10x" = "10x",
  "20x" = "20x",
  "50x" = "50x",
  "100x" = "100x",
}

type Props = {
  type: InstrumentTypesNamesEnum;
};

const SimulationActions: FC<Props> = ({ type }) => {
  const agreementDetails = getCurrentAgreementContext(type).useStoreState((state) => state.agreementDetails);
  const overviewSimulationValue = getCurrentAgreementContext(type).useStoreState(
    (state) => state.overviewSimulationValue
  );
  const setOverviewSimulationValue = getCurrentAgreementContext(type).useStoreActions(
    (actions) => actions.setOverviewSimulationValue
  );

  const isAgreementExpired = agreementDetails?.planStatusId === PlanStatusesBasedOnAPIStatusId.expired;

  const handleReset = () => {
    setOverviewSimulationValue(null);
  };

  const handleValueChange = (value: SimulationValues) => {
    if (overviewSimulationValue === value) {
      setOverviewSimulationValue(null);
    } else {
      setOverviewSimulationValue(value);
    }
  };

  useEffect(() => {
    setOverviewSimulationValue(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isNil(agreementDetails)) {
      setOverviewSimulationValue(null);
    }
  }, [agreementDetails, setOverviewSimulationValue]);

  return (
    <div>
      {isNil(overviewSimulationValue) ? null : (
        <Button size="s" variant="tertiary" id={classes["reset"]} onClick={handleReset}>
          Reset
        </Button>
      )}

      {Object.keys(SimulationValues).map((el) => (
        <Button
          key={el}
          size="s"
          id={classes["btn"]}
          isDisabled={isAgreementExpired}
          className={classNames("ms-1", {
            [classes["selected"]]: equals(overviewSimulationValue, el),
          })}
          onClick={() => {
            handleValueChange(el as SimulationValues);
          }}
        >
          {el}
        </Button>
      ))}
    </div>
  );
};

export default SimulationActions;
