import { FC, useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";

import { getAttractPath } from "app/Router/RouterHelper";
import NoAccess from "common/access-control/no-access/NoAccess";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";

// Access Middleware for Attract
const AccessMiddlewareAttract: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { pathname } = useLocation();

  const manageAccess = useMemo(() => {
    return {
      [getAttractPath(["general"], { companyId })]: FEATURES.attract,
      [getAttractPath(["pitch"], { companyId })]: FEATURES.attract,
      [getAttractPath(["team"], { companyId })]: FEATURES.attract,
      [getAttractPath(["fundingStatus"], { companyId })]: FEATURES.attract,
    };
  }, [companyId]);

  const accessKey = manageAccess[pathname.replace(/\/$/, "")];
  const features = useFeatures(accessKey);

  if (accessKey && !features.hasFullAccess && !features.hasViewAccess) {
    return <NoAccess />;
  }

  return <Outlet />;
};

export default AccessMiddlewareAttract;
