import { FC, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";

import { Button, InfoAlert, P, SlidePanel } from "common/components/atoms";
import DocumentUploadWithStatuses from "common/components/molecules/DocumentUploadWithStatuses/DocumentUploadWithStatuses";
import StakeholderSection from "common/components/molecules/StakeholderSection/StakeholderSection";
import { InformationCircleIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import FormValidatorToast from "../../../../financial-instruments/FormValidatorToast";
import { useTransactionsService } from "../../../transactions-service";
import { IssueSharesEditDTO, IssueSharesGetResponseDTO, Transaction, TransactionStatus } from "../../../types";
import DependentTransactions from "../../rollback-confirmed-transaction/dependent-transactions";
import { EditBuySellFields } from "../buy-sell/form-fields";
import { DocumentationDetailsFieldsType } from "../form-sections/documentation-details/types";
import { documentationDetailsValidationSchema } from "../form-sections/documentation-details/validation";
import { receiverDetailsValidationSchema } from "../form-sections/receiver-details/validation";
import SharesDetails from "../form-sections/shares-details/shares-details";
import { sharesDetailsValidationSchema } from "../form-sections/shares-details/validation";
import TransactionDetails, { SHARE_ISSUES } from "../form-sections/transaction-details/transaction-details";
import { transactionDetailsValidationSchema } from "../form-sections/transaction-details/validation";
import { EditIssueSharesFields, IssueSharesFields } from "./form-fields";

type PropsTypes = {
  onSubmit: (transaction: FormData) => Promise<boolean>;
  onClose: () => void;
  transactionToEdit: IssueSharesGetResponseDTO;
};

const PANEL_HEADER_ID = "equity-shares-id";

const t = createTranslation(TranslationNS.pages, "company.transactions");
const tValidation = createTranslation(TranslationNS.validation);

const EditIssueSharesForm: FC<PropsTypes> = ({ onClose, onSubmit, transactionToEdit }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const [dependentTransactions, setDependentTransactions] = useState<Transaction[]>([]);

  const { checkIssueSharesTransaction } = useTransactionsService(companyId);

  const openDependentTransactionsModal = useCallback((transactions: Transaction[]) => {
    setDependentTransactions(transactions);
  }, []);

  const closeDependentTransactionsModal = useCallback(() => {
    setDependentTransactions([]);
  }, []);

  const initialValues: IssueSharesEditDTO = useMemo(
    () => {
      return {
        [EditIssueSharesFields.transactionId]: transactionToEdit.transactionId,
        [EditIssueSharesFields.transactionTypeId]: transactionToEdit.transactionTypeId || undefined,
        [EditIssueSharesFields.transactedAt]: transactionToEdit.sharePurchaseDate,
        [EditIssueSharesFields.description]: transactionToEdit?.description || "",

        [EditIssueSharesFields.companyId]: +companyId,
        [EditIssueSharesFields.firstName]: transactionToEdit?.firstName || "",
        [EditIssueSharesFields.lastName]: transactionToEdit?.lastName || "",
        [EditIssueSharesFields.email]: transactionToEdit?.email || "",
        [EditIssueSharesFields.isCompanyOwned]: transactionToEdit.isCompanyOwned,
        [EditIssueSharesFields.companyName]: transactionToEdit?.companyName || "",
        [EditIssueSharesFields.organizationNumber]: transactionToEdit?.organizationNumber || "",
        [EditIssueSharesFields.numberOfShares]: transactionToEdit.numberOfShares,
        [EditIssueSharesFields.sharePurchaseDate]: transactionToEdit.sharePurchaseDate,
        [EditIssueSharesFields.purchasePrice]: transactionToEdit.purchasePrice,
        [EditIssueSharesFields.shareClassId]: transactionToEdit.shareClassId,
        [EditIssueSharesFields.relationshipTypeId]: transactionToEdit.relationshipTypeId,
        [EditIssueSharesFields.address]: transactionToEdit?.address || "",
        [EditIssueSharesFields.dateOfBirth]: transactionToEdit?.dateOfBirth,
        [EditIssueSharesFields.businessPostAddress]: transactionToEdit?.businessPostAddress || "",
        [EditIssueSharesFields.businessEmail]: transactionToEdit?.businessEmail || "",
        [EditIssueSharesFields.countryId]: transactionToEdit?.countryId,
        [EditIssueSharesFields.phoneNumber]: transactionToEdit?.phoneNumber || "",
        [EditIssueSharesFields.stakeholderId]: transactionToEdit.stakeholderId,
        [EditIssueSharesFields.documents]: [],
        [EditIssueSharesFields.relationshipTypeId]: transactionToEdit.relationshipTypeId,
        [EditIssueSharesFields.documentStatusId]: transactionToEdit.documentStatusId,
        [EditIssueSharesFields.prevFiles]: transactionToEdit?.documentFiles || [],
        [EditIssueSharesFields.eventName]: transactionToEdit?.eventName || "",
      };
    },
    // eslint-disable-next-line
    [transactionToEdit]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...transactionDetailsValidationSchema({ tFunction: tValidation, excludeFields: { transactedAt: true } }),
        ...sharesDetailsValidationSchema(tValidation),
        ...receiverDetailsValidationSchema(tValidation),
        ...documentationDetailsValidationSchema(tValidation),
      }),
    []
  );

  const handleSubmit = useCallback(
    async (values: IssueSharesEditDTO, actions: FormikHelpers<IssueSharesEditDTO>) => {
      const { documentStatusId, documents, ...fieldsForCheck }: DocumentationDetailsFieldsType & IssueSharesEditDTO =
        values;

      if (transactionToEdit?.statusId === TransactionStatus.Confirmed) {
        const { failedTransactions, hasErrorsAfterEdit } = await checkIssueSharesTransaction(fieldsForCheck);

        if (hasErrorsAfterEdit && failedTransactions.length) {
          openDependentTransactionsModal(failedTransactions);
          return false;
        }

        if (hasErrorsAfterEdit) {
          return false;
        }
      }

      const formData = new FormData();

      Object.values(EditIssueSharesFields as Omit<typeof EditIssueSharesFields, "transactionTypeTransferId">).forEach(
        (key) => {
          const formValue = values[key];

          if (key === IssueSharesFields.transactionTypeId && !values[key]) {
            formData.append(key, SHARE_ISSUES.toString());
          }

          if (!formValue) return;

          if (key === EditBuySellFields.documents) {
            values.documents.forEach((el: any) => formData.append("FilesData.Files", el));
          } else {
            formData.append(key, String(formValue).trim());
          }
        }
      );

      if (values["prevFiles"]?.length) {
        values.prevFiles.forEach((el: any) => formData.append("FilesData.OldFileIds", el.fileId));
      }

      const createdSuccessfully = await onSubmit(formData);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }
    },
    [checkIssueSharesTransaction, onSubmit, openDependentTransactionsModal, transactionToEdit?.statusId]
  );

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <FormValidatorToast panelId={PANEL_HEADER_ID} />
            <SlidePanel.Header
              title={t("issueSharesForm.title")}
              tagText={t("issueSharesForm.editTag")}
              tagVariant="information"
              onHide={onClose}
            />
            {transactionToEdit.statusId === TransactionStatus.Confirmed && (
              <InfoAlert
                customContent={
                  <div className="d-flex py-1 px-2">
                    <div className="me-1">
                      <InformationCircleIcon fontSize="24" color={scssVariables.information900} />
                    </div>
                    <P.s>{t("editInfo")}</P.s>
                  </div>
                }
                type="Info"
                className="mb-5"
              />
            )}

            <SlidePanel.Section title={t("transactionDetails.title")}>
              <TransactionDetails
                hiddenFields={{ transactedAt: true, transactionTypeTransferId: true, eventName: true }}
              />
            </SlidePanel.Section>

            <SlidePanel.Section title={t("receiverDetails.title")}>
              <StakeholderSection />
            </SlidePanel.Section>

            <SlidePanel.Section title={t("sharesDetails.title")}>
              <SharesDetails isDateWithTime={true} />
            </SlidePanel.Section>

            <SlidePanel.Section title={t("documentationDetails.title")}>
              <P.s className="mb-4">{t("documentationDetails.description")}</P.s>

              <DocumentUploadWithStatuses
                oldFilesName={EditIssueSharesFields.prevFiles}
                newFilesName={EditIssueSharesFields.documents}
              />
            </SlidePanel.Section>

            <SlidePanel.Actions>
              <Button isLoading={isSubmitting} type="submit" onClick={() => handleSubmit()} className="me-3">
                {t("sharedFormButtons.editBtn")}
              </Button>
              <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                {t("sharedFormButtons.cancelBtn")}
              </Button>
            </SlidePanel.Actions>

            {!!dependentTransactions.length && (
              <DependentTransactions transactions={dependentTransactions} onClose={closeDependentTransactionsModal} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditIssueSharesForm;
