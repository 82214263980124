import {  FC, useCallback } from "react";
import axios from "axios";
import { Formik } from "formik";

import Button from "common/components/atoms/Button/Button";
import Dropdown  from "common/components/atoms/Dropdown/Dropdown";
import TextField from "common/components/atoms/TextField/TextField";
import StepWrapper, { StepWrapperProps } from "common/layout/WizardSinglePage/StepWrapper/StepWrapper";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { EquityManagementOnboardingDTO, OnSubmit } from "../../types";
import { companyFields, companySchema } from "./shema";
import { CompanyDTO } from "./types";

type PropsTypes = {
  prefillInitialValues: CompanyDTO | null;
  onSubmit: OnSubmit;
} & StepWrapperProps;

const t = createTranslation(TranslationNS.pages, "managerOnboarding.companyStep");

const Company: FC<PropsTypes> = ({ prefillInitialValues, stepRef, isDisabled, onSubmit }) => {
  const getAccount = useStoreActions((state) => state.account.getAccountThunk);

  const initialValues: CompanyDTO = {
    companyId: prefillInitialValues?.companyId || null,
    name: prefillInitialValues?.name || "",
    headquartersCountryId: prefillInitialValues?.headquartersCountryId || null,
    city: prefillInitialValues?.city || "",
    websiteUrl: prefillInitialValues?.websiteUrl || "",
  };

  const handleCompanySubmit = useCallback(
    async (values: CompanyDTO) => {
      try {
        const response = await axios.post<EquityManagementOnboardingDTO>("/api/onboarding/company", values);

        if (response.status === 200) {
          onSubmit(response.data);
          notify(t("successNotification"), true, "success");
          getAccount();
        }
      } catch (e) {
        console.log(e);
      }
    },
    [getAccount, onSubmit]
  );

  const countriesDropDownList = useStoreState((state) => state.common.dropdowns)?.countries;

  return (
    <StepWrapper stepRef={stepRef} isDisabled={isDisabled}>
      <Formik
        enableReinitialize={true}
        initialValues={prefillInitialValues || initialValues}
        validationSchema={companySchema}
        onSubmit={handleCompanySubmit}
      >
        {({ values, errors, touched, handleChange, setFieldValue, isSubmitting, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <p className="ui-l fw-bold mb-1">{t("subtitle")}</p>
              <h2 className="mb-3">{t("title")}</h2>

              <TextField
                className="mb-3"
                label={t("companyName")}
                value={values?.name || ""}
                error={errors.name}
                isTouched={touched.name}
                onChange={handleChange(companyFields.name)}
              />
              <div className="d-flex mb-3">
                <Dropdown
                  className="w-75 me-2"
                  label={t("headquartersCountry")}
                  selectedValue={
                    countriesDropDownList?.find((country) => country.id === values.headquartersCountryId)?.name
                  }
                  options={countriesDropDownList?.map((country) => country.name) || []}
                  onChange={(selectedCountryName: string) => {
                    const selectedCountry = countriesDropDownList?.find(
                      (country) => country.name === selectedCountryName
                    );
                    setFieldValue(companyFields.headquartersCountryId, selectedCountry?.id || 0);
                  }}
                  isSearchable={true}
                  isTouched={touched.headquartersCountryId}
                  error={errors.headquartersCountryId}
                />
                <TextField
                  label={t("city")}
                  value={values?.city || ""}
                  error={errors.city}
                  isTouched={touched.city}
                  onChange={handleChange(companyFields.city)}
                />
              </div>
              <TextField
                className="mb-3"
                label={t("website")}
                value={values?.websiteUrl || ""}
                error={errors.websiteUrl}
                isTouched={touched.websiteUrl}
                isOptional={true}
                onChange={handleChange(companyFields.websiteUrl)}
              />
              <Button type="submit" isLoading={isSubmitting} isDisabled={!!prefillInitialValues?.name}>
                {t("submitBtn")}
              </Button>
            </form>
          );
        }}
      </Formik>
    </StepWrapper>
  );
};

export default Company;
