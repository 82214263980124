import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CapTableStore from "../store";
import useInviteShareholderService, { GetStakeholderInfoDTO } from "./InviteShareholderService";
import Modal from "./Modal";

const InviteShareholderModal: FC = () => {
  const { companyId = "0" } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [shareholderData, setShareholderData] = useState<GetStakeholderInfoDTO>();
  const { actualData } = CapTableStore.useStoreState((state) => state);
  const { getShareholderInfo } = useInviteShareholderService(companyId);

  useEffect(() => {
    if (actualData.hasCompanyInvitation && !dontShowAgain) {
      setIsOpen(true);
    }
  }, [actualData.hasCompanyInvitation, dontShowAgain]);

  useEffect(() => {
    if (isOpen) {
      (async () => {
        setIsLoading(true);
        const data = await getShareholderInfo();

        if (data) {
          setShareholderData(data);
        }
        setIsLoading(false);
      })();
    }
  }, [getShareholderInfo, isOpen]);

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
    setDontShowAgain(true);
  }, []);

  return isOpen && !isLoading && shareholderData ? (
    <Modal onClose={handleCloseModal} stakeholder={shareholderData} />
  ) : null;
};

export default InviteShareholderModal;
