import { FC, useMemo, useState } from "react";
import cn from "classnames";
import { defaultTo } from "ramda";

import { Table, Ui } from "common/components/atoms";
import { CapTableStakeholderDetailsType } from "common/components/organisms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { FilledArrowDownIcon } from "common/icons/svg";
import { sortingParams } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { StakeholderDetailsContext } from "../../stakeholder-details.context";
import classes from "../../stakeholder-details.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.instruments");

const Head = ({
  sortingValue,
  setSortingValue,
}: {
  sortingValue: sortingParams;
  setSortingValue: (e: sortingParams) => void;
}) => {
  const rows = useMemo(
    () => [
      {
        key: "instrument",
        value: t("head.instrument"),
      },
      {
        key: "amount",
        value: t("head.amount"),
      },

      {
        key: "shares",
        value: t("head.shares"),
      },
      {
        key: "stake",
        value: t("head.stake"),
      },
      {
        key: "expiryDate",
        value: t("head.expiryDate"),
      },
    ],
    []
  );

  return (
    <thead className={classes["head-row"]}>
      <tr>
        {rows.map((el, i) => (
          <th
            key={el.key}
            className={cn(classes["head-item"], classes["no-border"])}
            onClick={
              el.key === "amount"
                ? () => {
                    sortingValue === sortingParams.inc
                      ? setSortingValue(sortingParams.desc)
                      : setSortingValue(sortingParams.inc);
                  }
                : undefined
            }
          >
            <div
              className={cn("d-flex align-items-center fw-500", {
                "justify-content-end": i === 1 || i === 2 || i === 3,
              })}
            >
              <Ui.xs>{el.value}</Ui.xs>

              {el.key === "amount" ? (
                <span className={classes.sort}>
                  <FilledArrowDownIcon
                    style={{
                      transition: "all 0.2s",
                      transform: sortingValue === sortingParams.inc ? "rotate(0deg)" : "rotate(180deg)",
                    }}
                  />
                </span>
              ) : null}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Body: FC<{ data: CapTableStakeholderDetailsType["financialInstruments"] }> = ({ data }) => {
  const fNumber = useFormatNumbers();

  return (
    <tbody>
      {data.length ? (
        data.map((el) => (
          <tr key={el.financialInstrumentId} className={cn(classes.tableBorder, classes["no-border"])}>
            <td>
              <Ui.s>{el.instrumentName}</Ui.s>
            </td>

            <td className="text-end">
              <Ui.s>{fNumber(el.amount, "value")}</Ui.s>
            </td>

            <td className="text-end">
              <Ui.s>{fNumber(el.numberOfShares, "amount")}</Ui.s>
            </td>

            <td className="text-end">
              <Ui.s>{fNumber(el.stake, "percent")}</Ui.s>
            </td>

            <td>
              <Ui.s>{el.expiryDate ? transformDateToCommonDateFormat(el.expiryDate) : "-"}</Ui.s>
            </td>
          </tr>
        ))
      ) : (
        <tr className={cn(classes.tableBorder, classes["no-border"])} style={{ height: 80 }}>
          <td width="100%" colSpan={10}>
            <Ui.s color="foregroundLow">{t("empty")}</Ui.s>
          </td>
        </tr>
      )}
    </tbody>
  );
};

const InstrumentsTab: FC = () => {
  const details = StakeholderDetailsContext.useStoreState((state) => state.details);

  const [sortingValue, setSortingValue] = useState<sortingParams>(sortingParams.desc);

  const sortedData = useMemo(
    () =>
      (details?.financialInstruments || []).sort((a, b) =>
        sortingValue === sortingParams.inc ? a.amount - b.amount : b.amount - a.amount
      ),
    [details?.financialInstruments, sortingValue]
  );

  return (
    <div className={cn("m-4", classes["details-container"])}>
      <div className={cn(classes["head"])} style={{ backgroundColor: scssVariables.element2 }}>
        <div className="d-flex justify-content-between align-items-center px-3">
          <div className="text-start">
            <div className="d-flex align-items-center">
              <Ui.m bold>{t("title")}</Ui.m>

              <div className={classes["badge"]}>
                <Ui.xs>{details?.financialInstruments?.length}</Ui.xs>
              </div>
            </div>

            <Ui.xs color="foregroundLow">{t("description")}</Ui.xs>
          </div>
        </div>
      </div>

      <Table
        className={classes["table"]}
        headComponent={<Head sortingValue={sortingValue} setSortingValue={setSortingValue} />}
        bodyComponent={<Body data={defaultTo([], sortedData)} />}
      />
    </div>
  );
};

export default InstrumentsTab;
