export enum PlanStatuses {
  draft = "Draft",
  approved = "Approved",
  cancelled = "Cancelled",
  active = "Active",
  closed = "Closed",
  completed = "Completed",
  granted = "Granted",
}

export enum DocumentStatuses {
  missing = "missing",
  complete = "complete",
  "not required" = "not Required",
}
