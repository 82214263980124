import { useMemo } from "react";
import { compareAsc } from "date-fns";
import * as R from "ramda";
import * as Yup from "yup";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";

const tv = createTranslation(TranslationNS.validation);

export type ConvertNoteFormValues = {
  noteId: number;
  agreementDate: string;
  conversionReasonId?: number;
  noteSettleDate: string;
  shareClassId?: number;
  comment?: string;
  documentStatusId: DocumentStatusEnum;
  documents: FilesDataMultiple;
  valuationAtConversion: number;
  investmentAmount?: number;
  shareIssueDate?: string;
  saveAsConverting: boolean;
  valuationCap?: number;
  interestRate?: number;
  discount?: number;
  sharesInCompanyBeforeConversion?: number;
  overrideSharesCalculation: boolean;
  overrideNumberOfShares?: number;
  overrideSharePrice?: number;
};

const useConvertNoteForm = () => {
  const convertNote = IssueEquityContext.useStoreState((state) => state.conversionDetailsCN);

  const initialValues: ConvertNoteFormValues = useMemo(
    () => ({
      noteId: R.defaultTo(0, convertNote?.id),
      conversionReasonId: R.defaultTo(undefined, convertNote?.conversionReasonId),
      investmentAmount: R.defaultTo(0, convertNote?.investmentAmount),
      valuationAtConversion: R.defaultTo(0, convertNote?.valuationAtConversion),
      noteSettleDate: convertNote?.noteSettleDate || createDateString(new Date()),
      agreementDate: convertNote?.agreementDate || createDateString(new Date()),
      shareClassId: R.defaultTo(undefined, convertNote?.shareClassId),
      comment: R.defaultTo("", convertNote?.comment),
      documentStatusId: R.defaultTo(DocumentStatusEnum.REVIEW_LATER, convertNote?.documentStatusId),
      saveAsConverting: false,
      valuationCap: R.defaultTo(0, convertNote?.valuationCap),
      interestRate: R.defaultTo(0, convertNote?.interestRate),
      discount: R.defaultTo(0, convertNote?.discount),
      sharesInCompanyBeforeConversion: R.defaultTo(0, convertNote?.sharesInCompanyBeforeConversion),
      documents: {
        newFiles: [],
        oldFiles: R.defaultTo([], convertNote?.conversionDocumentFiles),
      },
      shareIssueDate: R.defaultTo(createDateString(new Date()), convertNote?.shareIssueDate),
      overrideSharesCalculation: false,
    }),
    [convertNote]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      conversionReasonId: Yup.number().min(1, tv("required")).required(tv("required")),
      shareClassId: Yup.number().required(tv("required")),
      agreementDate: Yup.date(),
      noteSettleDate: Yup.date()
        .required(tv("required"))
        .test("noteSettleDate", "Must must be after agreement date", (value, object) => {
          return compareAsc(value || new Date(), object.parent.agreementDate) === 1;
        }),
      shareIssueDate: Yup.date()
        .required(tv("required"))
        .test("shareIssueDate", "Must be after agreement date", (value, object) => {
          return compareAsc(value || new Date(), object.parent.agreementDate) === 1;
        }),
      valuationAtConversion: Yup.number()
        .required(tv("required"))
        .min(Yup.ref("investmentAmount"), tv("minNumber", { number: "Investment Amount" })),
      documents: Yup.object().when("documentStatusId", {
        is: (documentValue: number) => +documentValue === DocumentStatusEnum.DOCUMENT_UPLOADED,
        then: Yup.object().shape({
          newFiles: Yup.mixed().when("oldFiles", {
            is: (oldFiles: any) => !oldFiles.length,
            then: Yup.array().min(1, tv("required")),
          }),
        }),
      }),
      valuationCap: Yup.number().nullable().min(0, tv("notNegative")),
    });
  }, []);

  return {
    initialValues,
    validationSchema,
  };
};

export default useConvertNoteForm;
