import { FC, useCallback, useMemo } from "react";
import AccordionBody from "react-bootstrap/AccordionBody";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { isEmpty } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, FilesList, P, Ui } from "common/components/atoms";
import PieChart, { PieChartProps } from "common/components/atoms/Charts/Pie.chart";
import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import { PoolStatuses } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import {
  CheckBadgeIcon,
  ChevronDownIcon,
  CompanyBuildingIcon,
  InformationCircleIcon,
  UnissuedIcon,
  WarningIcon,
} from "common/icons/svg";
import Possibilities from "common/shareClass/components/Possibilities/Possibilities";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { useStoreState } from "store/store";
import { PoolType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import ExpiryBanner, { getExpirationState } from "./components/ExpiryBanner/ExpiryBanner";
import ProgramCard from "./components/ProgramCard/ProgramCard";
import StandaloneTable from "./components/StandaloneTable/StandaloneTable";
import classes from "./PoolBody.module.scss";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.poolsAndPrograms.poolItem"),
  createTranslation(TranslationNS.common, "noAccess"),
];

export type PoolBodyProps = {
  pool: PoolType;
};

const PoolBody: FC<PoolBodyProps> = ({ pool }) => {
  const navigate = useNavigate();
  const fNumber = useFormatNumbers();

  const approvalOptions = useStoreState((state) => state.common.dropdowns)?.approvalBodies;
  const { hasFullAccess } = useFeatures(FEATURES.poolsAndPrograms);

  const isDraftedPool = pool.status === PoolStatuses.draft;
  const selectedApprovalOption = approvalOptions?.find((el) => el.id === pool.approvalBodyId);

  const pieChartData = useMemo<PieChartProps["data"]>(() => {
    return [
      {
        id: t("shares.reserved"),
        value: pool.sharesUsedInAgreements,
        color: scssVariables.positive700,
      },
      {
        id: t("shares.authorisedShares"),
        value: pool.authorisedOfSharesLeft,
        color: scssVariables.element1,
      },
    ];
  }, [pool]);

  const handleAddProgramClick = useCallback(() => {
    navigate(getEMPath(["createProgram", "main"]), { state: { poolId: pool.id } });
  }, [navigate, pool.id]);

  const { isPoolExpired, isPoolCloseToExpiry } = getExpirationState(pool?.expiryDate);

  return (
    <AccordionBody className={classes.wrap}>
      <ExpiryBanner className="mb-2" expiryDate={pool?.expiryDate} />

      <div className={classes.content}>
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <CheckBadgeIcon strokeWidth={0.7} width={48} height={48} color={scssVariables.foregroundLow} />

            <div className="ms-2">
              <Ui.xxxl bold>{fNumber(pool.authorisedOfShares, "amount")}</Ui.xxxl>
              <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("authorisedShares")}</Ui.s>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <UnissuedIcon width={48} height={48} color={scssVariables.foregroundLow} />

            <div className="ms-2">
              <Ui.xxxl bold>{fNumber(pool?.unIssuedShares, "amount")}</Ui.xxxl>
              <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("unissuedShares")}</Ui.s>
            </div>
          </div>

          <div className="d-flex align-items-center">
            <CompanyBuildingIcon width={48} height={48} strokeWidth={0.7} color={scssVariables.foregroundLow} />

            <div className="ms-2">
              <Ui.xxxl bold>{fNumber(pool?.totalPercentage, "percent")}</Ui.xxxl>
              <Ui.s style={{ color: scssVariables.foregroundMedium }}>{t("percentOfAllShares")}</Ui.s>
            </div>
          </div>
        </div>

        <div className={classes.separator} />

        <div className="d-flex justify-content-between mb-5">
          <div className="d-flex flex-column w-50">
            <div className={classes["pie-chart-wrap"]}>
              <div className={classes["pie-chart"]}>
                <PieChart isInteractive={false} height={180} data={pieChartData} />
              </div>

              <div className={classes["shares-legend"]}>
                {pieChartData.map((item, index) => (
                  <div key={item.id} className={classes["shares-legend__item"]}>
                    <div className={classes["shares-legend__item__value"]}>{fNumber(item.value, "amount")}</div>

                    <div className={classes["shares-legend__item__description"]}>
                      <span
                        style={{ backgroundColor: item.color, borderColor: index === 0 ? item.color : undefined }}
                      />
                      {item.id}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {pool?.description ? (
              <P.s className="mt-3" color={scssVariables.foregroundMedium}>
                {pool.description}
              </P.s>
            ) : null}
          </div>

          <div className="w-50">
            <Possibilities
              hasDividends={pool.hasDividends}
              hasPreferences={pool.hasPreferences}
              hasVotingRights={pool.hasVotingRights}
              className="px-2 py-0 mb-3"
            />

            <div>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <Ui.s bold>{t("shareClass")}</Ui.s>
                <Ui.s className="text-end">{pool.shareClassName}</Ui.s>
              </div>

              <div className="d-flex justify-content-between align-items-center mb-2">
                <Ui.s bold>{t("dateOfApproval")}</Ui.s>
                <div className="d-flex text-end align-items-center">
                  <Ui.s>{pool?.approvedAt ? transformDateToCommonDateFormat(pool?.approvedAt) : "-"}</Ui.s>
                  {selectedApprovalOption?.name ? <Ui.s className="ms-1">({selectedApprovalOption?.name})</Ui.s> : null}
                </div>
              </div>

              <div
                className="d-flex justify-content-between align-items-center mb-2"
                style={
                  isPoolExpired
                    ? { color: scssVariables.critical500 }
                    : isPoolCloseToExpiry
                    ? { color: scssVariables.warning500 }
                    : undefined
                }
              >
                <div className="d-flex align-items-center">
                  {isPoolExpired && <WarningIcon fontSize={24} color={scssVariables.critical900} className="me-1" />}
                  {isPoolCloseToExpiry && (
                    <WarningIcon fontSize={24} color={scssVariables.warning900} className="me-1" />
                  )}
                  <Ui.s bold>{t("expiryDate")}</Ui.s>
                </div>
                <Ui.s className="text-end">
                  {pool?.expiryDate ? transformDateToCommonDateFormat(pool?.expiryDate) : "-"}
                </Ui.s>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <Ui.s bold>{t("source")}</Ui.s>
                <Ui.s className="text-end">{pool.shareSourceTypeName}</Ui.s>
              </div>
            </div>
          </div>
        </div>

        <CollapsibleContainer
          className={cn(classes["background-surface"], "rounded-3 mb-2 p-3")}
          withoutDivider
          Header={({ activeEventKey, onClick }: CollapsibleHeaderProps) => (
            <div className="d-flex justify-content-between align-items-center" onClick={onClick}>
              <div className="d-flex align-items-center">
                <Ui.l bold className="me-1">
                  {t("programsTitle")}
                </Ui.l>
                <Ui.xs className={classes.counter}>{pool?.ownershipPrograms?.length || 0}</Ui.xs>
              </div>

              <div className="d-flex align-items-center">
                <Button
                  size="s"
                  variant="secondary"
                  isDisabled={!hasFullAccess || isDraftedPool}
                  tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                  tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
                  onClick={handleAddProgramClick}
                  className="me-2"
                >
                  {t("addNewProgram")}
                </Button>

                <ChevronDownIcon
                  fontSize={24}
                  color={scssVariables.foregroundMedium}
                  direction={activeEventKey ? "top" : "bottom"}
                />
              </div>
            </div>
          )}
        >
          {isDraftedPool ? (
            <div className="mt-3 d-flex align-items-center" style={{ color: scssVariables.foregroundLow }}>
              <InformationCircleIcon width={24} height={24} />
              <Ui.xs className="ms-1">{t("finishPoolToStartAddingPrograms")}</Ui.xs>
            </div>
          ) : (
            <div className="d-flex flex-wrap justify-content-between gap-2 mt-2">
              {!isEmpty(pool.ownershipPrograms) ? (
                pool.ownershipPrograms.map((program) => (
                  <div key={program.id} style={{ flexBasis: "calc(50% - 8px)" }}>
                    <ProgramCard program={program} />
                  </div>
                ))
              ) : (
                <div className="mt-3 d-flex align-items-center" style={{ color: scssVariables.foregroundLow }}>
                  <InformationCircleIcon width={24} height={24} />
                  <Ui.xs className="ms-1">{t("empty")}</Ui.xs>
                </div>
              )}
            </div>
          )}
        </CollapsibleContainer>

        <CollapsibleContainer
          className={cn(classes["background-surface"], "rounded-3 mb-2 p-3")}
          withoutDivider
          Header={({ activeEventKey, onClick }: CollapsibleHeaderProps) => (
            <div className="d-flex justify-content-between align-items-center" onClick={onClick}>
              <div>
                <div className="d-flex align-items-center">
                  <Ui.l bold className="me-1">
                    {t("standaloneTitle")}
                  </Ui.l>
                  <Ui.xs className={classes.counter}>{pool?.standAloneAgreementsCount || 0}</Ui.xs>
                </div>
                <P.s color="foregroundMedium">{t("standaloneSubtitle")}</P.s>
              </div>

              <ChevronDownIcon
                fontSize={24}
                color={scssVariables.foregroundLow}
                direction={activeEventKey ? "top" : "bottom"}
              />
            </div>
          )}
        >
          <StandaloneTable agreements={pool.standAloneAgreements} />
        </CollapsibleContainer>

        <CollapsibleContainer
          className={cn(classes["background-surface"], "rounded-3 p-3")}
          withoutDivider
          Header={({ activeEventKey, onClick }: CollapsibleHeaderProps) => (
            <div className="d-flex justify-content-between align-items-center" onClick={onClick}>
              <div className="d-flex align-items-center">
                <Ui.l bold className="me-1">
                  {t("documentsTitle")}
                </Ui.l>
                <Ui.xs className={classes.counter}>{pool?.documentFiles?.length || 0}</Ui.xs>
              </div>

              <ChevronDownIcon
                fontSize={24}
                color={scssVariables.foregroundLow}
                direction={activeEventKey ? "top" : "bottom"}
              />
            </div>
          )}
        >
          <FilesList files={pool.documentFiles} />
        </CollapsibleContainer>
      </div>
    </AccordionBody>
  );
};

export default PoolBody;
