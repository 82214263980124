import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import { formatNumber } from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";

type dataSingle = {
  id: string;
  value: number;
  color: string;
  withThumb?: boolean;
  labelColor?: string;
  thumbColor?: string;
  showTooltip?: boolean;
};

type ProgressChartProps = {
  total?: number;
  height?: number;
  data: dataSingle[];
  thumbColor?: string;
  withoutLabel?: boolean;
  withLeftBorder?: boolean;
  totalSharesBarColor?: string;
  totalSharesBorderColor?: string;
};

const ProgressChart: FC<ProgressChartProps> = ({
  data,
  height = 18,
  total = 3250,
  withoutLabel,
  withLeftBorder,
  totalSharesBarColor,
  totalSharesBorderColor,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [xPosition, setXPosition] = useState<number>(0);
  const [isUpdateUI, setIsUpdateUI] = useState<boolean>(false);
  const [isLegendShown, setIsLegendShown] = useState<boolean>(false);
  const [legendStyles, setLegendStyles] = useState<{
    color: string;
    value: string;
  }>({} as { color: string; value: string });

  const dataToDisplay = useMemo(
    () => [
      ...data,
      {
        id: "total",
        value: total - data.reduce((acc, curr: dataSingle) => acc + curr.value, 0),
        color: totalSharesBarColor ? totalSharesBarColor : scssVariables.surface1,
        showTooltip: false,
        labelColor: "#6913BE",
        withLeftBorder,
      },
    ],
    [data, total, totalSharesBarColor, withLeftBorder]
  );

  const renderProgressBar = useCallback(
    () =>
      dataToDisplay.map((el, index, array) => {
        const isItemLast = index === array.length - 1;

        return (
          <ProgressBar
            key={index}
            isChild
            now={el.value}
            max={total}
            data-custom={el.id}
            label={withoutLabel ? undefined : `${el.value} ${el.id}`}
            style={{
              paddingLeft: isItemLast ? 10 : undefined,
              paddingRight: isItemLast ? undefined : 10,
              display: el.value === 0 ? "none" : "flex",
              cursor: "pointer",
              fontWeight: "bold",
              textAlign: isItemLast ? "left" : "right",
              color: el.labelColor,
              backgroundColor: el.color,
              visibility: el.value > 0 ? "visible" : "hidden",
              borderTopRightRadius: el.value > 0 && isItemLast ? height : "none",
              borderBottomRightRadius: el.value > 0 && isItemLast ? height : "none",
              borderTopLeftRadius: withLeftBorder && el.value === total && isItemLast ? height : 0,
              borderBottomLeftRadius: withLeftBorder && el.value === total && isItemLast ? height : 0,
              border: isItemLast
                ? `1px dashed ${totalSharesBorderColor ? totalSharesBorderColor : scssVariables.positive900}`
                : "none",
              borderLeft:
                el.value === total
                  ? `1px dashed ${totalSharesBorderColor ? totalSharesBorderColor : scssVariables.positive900}`
                  : "none",
            }}
            onMouseOver={(e) => {
              if (el.showTooltip && ref.current) {
                setXPosition(e.clientX - ref?.current?.getBoundingClientRect().x);
                setIsLegendShown(el.showTooltip);
                setLegendStyles({
                  color: el.color,
                  value: `${el.id} ${formatNumber(el.value)}`,
                });
              }
            }}
            onMouseOut={() => {
              setIsLegendShown(false);
            }}
            onMouseMove={(e) => {
              if (el.showTooltip && ref.current) {
                setXPosition(e.clientX - ref.current?.getBoundingClientRect().x);
                setIsLegendShown(true);
              }
            }}
          />
        );
      }),
    [dataToDisplay, total, withoutLabel, height, withLeftBorder, totalSharesBorderColor]
  );

  const renderProgressThumbs = useCallback(() => {
    return data
      .filter((el) => el.withThumb)
      .map((el) => {
        const findIndex = data.findIndex((item) => item.id === el.id);

        const thumbWidthPosition = data
          .filter((_, i) => i <= findIndex)
          .reduce((acc, curr) => {
            const findEl = document.querySelector(`[data-custom="${curr.id}"]`);

            return acc + (findEl ? findEl?.getBoundingClientRect()?.width : 0);
          }, 0);

        return (
          <div
            key={`Thumb value is - ${el.id}`}
            style={{
              width: 4,
              top: "50%",
              borderRadius: 5,
              position: "absolute",
              pointerEvents: "none",
              transition: "all 0.2s",
              left: thumbWidthPosition,
              height: `${height * 1.7}px`,
              transform: "translateY(-48%)",
              backgroundColor: el.thumbColor,
              opacity: el.value === 0 || el.value === total ? 0 : 1,
            }}
          />
        );
      });
  }, [data, height, total]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsUpdateUI(true);
      clearTimeout(timer);
    }, 600);
  }, [data]);

  useEffect(() => {
    if (isUpdateUI) {
      const timer = setTimeout(() => {
        setIsUpdateUI(false);
        clearTimeout(timer);
      }, 200);
    }
  }, [isUpdateUI]);

  return (
    <div
      style={{
        height,
        width: "100%",
        position: "relative",
      }}
    >
      {renderProgressThumbs()}
      {isLegendShown && (
        <div
          style={{
            top: 0,
            left: 0,
            zIndex: 10,
            borderRadius: 2,
            display: "flex",
            padding: "5px 9px",
            position: "absolute",
            alignItems: "center",
            pointerEvents: "none",
            backgroundColor: "white",
            boxShadow: "rgb(0 0 0 / 25%) 0px 1px 2px",
            transform: `translate(${xPosition}px, -40px)`,
          }}
        >
          <div
            style={{
              width: 12,
              height: 12,
              marginRight: 7,
              backgroundColor: legendStyles.color,
            }}
          />
          <p className="m-0 p-0 ui-s fw-bold">{legendStyles.value}</p>
        </div>
      )}
      <ProgressBar
        style={{
          height,
          borderRadius: height,
        }}
        ref={ref}
        max={total}
      >
        {renderProgressBar()}
      </ProgressBar>
    </div>
  );
};

export default ProgressChart;
