import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { addDays, addMonths, addYears, format } from "date-fns";
import { useFormik } from "formik";
import { defaultTo, isEmpty, isNil } from "ramda";
import * as Yup from "yup";

import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { EMAIL_VALIDATION_PATTERN } from "common/utils/validators";
import ManagePlansContext from "pages/equity-management/plans/manage-plans/managePlansContext";
import { useStoreActions } from "store/store";
import { PlanTerminationDetailsType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import { PlanTerminationPOSTData } from "../../../manage-plans.types";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans.terminatePlan");
const validationTranslation = createTranslation(TranslationNS.validation);

const usePlanTermination = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const { getOwnershipPlansThunk } = useStoreActions((actions) => actions.company);
  const { terminationPlanInfo } = ManagePlansContext.useStoreState((state) => state);
  const { setTerminationPlanInfo, terminateRsaPlanThunk, terminateSoPlanThunk } = ManagePlansContext.useStoreActions(
    (actions) => actions
  );

  const [isOverrideSharesOpen, setIsOverrideSharesOpen] = useState<boolean>(false);

  const terminationSoReasonVariants = useMemo(() => {
    /**
     * this section is hardcoded as we don't have an API provided texts.
     * Potentially may be updated and replaced with other, better solution
     * Important to note
     * @param { key } string That is used for this array data is hardcoded,
     * but the value is getting from the termination api get method
     * it might be updated in future
     */
    return [
      {
        key: "Retirement",
        text: t("terminationReasonVariants.retirement"),
      },
      {
        key: "Voluntary Termination",
        text: t("terminationReasonVariants.voluntaryTermination"),
      },
      {
        key: "Involuntary Termination",
        text: t("terminationReasonVariants.involuntaryTermination"),
      },
      {
        key: "Termination with Cause",
        text: t("terminationReasonVariants.terminatedWithClause"),
      },
      {
        key: "Disability",
        text: t("terminationReasonVariants.disability"),
      },
      {
        key: "Passing Away",
        text: t("terminationReasonVariants.passedAway"),
      },
      {
        key: "Liquidation Event",
        text: t("terminationReasonVariants.liquidationEvent"),
      },
    ];
  }, []);

  const terminationRsaReasonVariants = useMemo(() => {
    return [
      {
        key: "Retirement",
        text: t("terminationRsaReasonVariants.retirement"),
      },
      {
        key: "Voluntary Termination",
        text: t("terminationRsaReasonVariants.voluntaryTermination"),
      },
      {
        key: "Involuntary Termination",
        text: t("terminationRsaReasonVariants.involuntaryTermination"),
      },
      {
        key: "Termination with Cause",
        text: t("terminationRsaReasonVariants.terminatedWithClause"),
      },
      {
        key: "Disability",
        text: t("terminationRsaReasonVariants.disability"),
      },
      {
        key: "Passing Away",
        text: t("terminationRsaReasonVariants.passedAway"),
      },
      {
        key: "Liquidation Event",
        text: t("terminationRsaReasonVariants.liquidationEvent"),
      },
    ];
  }, []);

  const validationSchema = useMemo(() => {
    const expirationDate =
      terminationPlanInfo?.instrumentTypeId === InstrumentTypesIdsEnum.RSA
        ? Yup.string()
        : Yup.string().required(validationTranslation("required"));

    return Yup.object().shape({
      notifyEmail: Yup.string()
        .matches(EMAIL_VALIDATION_PATTERN, validationTranslation("invalidEmail"))
        .when("sendEmailInvitation", {
          is: true,
          then: Yup.string().required(validationTranslation("required")),
        }),
      expirationDate: expirationDate,
    });
  }, [terminationPlanInfo?.instrumentTypeId]);

  const initialValues = useMemo(
    () => ({
      notifyEmail: defaultTo("", terminationPlanInfo?.stakeholder?.email),
      acceleratedVestingAccepted: false,
      newVestedShares: 0,
      sendEmailInvitation: false,
      message: "",
      terminationReason: undefined as PlanTerminationDetailsType["terminationOptions"][number] | undefined,
      expirationDate: undefined,
    }),
    [terminationPlanInfo]
  );

  const { values, handleChange, errors, touched, resetForm, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: terminateHandler,
  });

  const handleClose = useCallback(() => {
    setTerminationPlanInfo(null);
    resetForm();
  }, [resetForm, setTerminationPlanInfo]);

  const overrideSharesOpen = useCallback(() => {
    setIsOverrideSharesOpen(true);
  }, [setIsOverrideSharesOpen]);

  const overrideSharesClose = useCallback(() => {
    setIsOverrideSharesOpen(false);
  }, [setIsOverrideSharesOpen]);

  const updateOverrideShares = useCallback(
    (shares: number) => {
      setFieldValue("newVestedShares", shares);
    },
    [setFieldValue]
  );

  async function terminateHandler() {
    try {
      const data: PlanTerminationPOSTData = {
        planId: defaultTo(0, terminationPlanInfo?.planId),
        terminationReasonId: defaultTo(0, values.terminationReason?.id),
        acceleratedVesting: values.acceleratedVestingAccepted,
        newVestedShares: values.newVestedShares,
        comment: values.message,
        sendEmailInvitation: values.sendEmailInvitation,
      };

      if (terminationPlanInfo?.instrumentTypeId === InstrumentTypesIdsEnum.OPTION) {
        data["expirationDate"] = format(new Date(values.expirationDate as unknown as string), "yyyy-MM-dd");
      }

      if (values.sendEmailInvitation) {
        data["email"] = values.notifyEmail;
      }

      if (terminationPlanInfo?.instrumentTypeId === InstrumentTypesIdsEnum.RSA) {
        await terminateRsaPlanThunk(data);
      }

      if (terminationPlanInfo?.instrumentTypeId === InstrumentTypesIdsEnum.OPTION) {
        await terminateSoPlanThunk(data);
      }

      await getOwnershipPlansThunk(Number(companyId));
    } catch (e) {
      return await Promise.reject(e);
    } finally {
      resetForm();
    }
  }

  useEffect(() => {
    // this variable will apply the necessary time-range value depends on API response for reason time unit
    // 0 - days; 1 - months; 2 - years;
    const addDateHandler =
      values.terminationReason?.timeUnit === 0
        ? addDays
        : values.terminationReason?.timeUnit === 1
        ? addMonths
        : addYears;

    setFieldValue(
      "expirationDate",
      addDateHandler(new Date(), defaultTo(0, values.terminationReason?.unitsOfTimeToExpiration))
    );
  }, [setFieldValue, values.terminationReason?.timeUnit, values.terminationReason?.unitsOfTimeToExpiration]);

  useEffect(() => {
    if (!isNil(terminationPlanInfo?.terminationOptions) && !isEmpty(terminationPlanInfo?.terminationOptions)) {
      setFieldValue("terminationReason", terminationPlanInfo?.terminationOptions?.[0]);
    }
  }, [setFieldValue, terminationPlanInfo?.terminationOptions]);

  useEffect(() => {
    setFieldValue(
      "newVestedShares",
      values.acceleratedVestingAccepted
        ? defaultTo(0, terminationPlanInfo?.notVestedShares) + defaultTo(0, terminationPlanInfo?.vestedShares)
        : terminationPlanInfo?.vestedShares
    );
  }, [
    setFieldValue,
    terminationPlanInfo?.notVestedShares,
    terminationPlanInfo?.vestedShares,
    values.acceleratedVestingAccepted,
  ]);

  return {
    errors,
    handleChange,
    handleClose,
    handleSubmit,
    setFieldValue,
    touched,
    values,
    terminationSoReasonVariants,
    terminationRsaReasonVariants,
    isOverrideSharesOpen,
    overrideSharesOpen,
    overrideSharesClose,
    updateOverrideShares,
  };
};

export default usePlanTermination;
