import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import cn from "classnames";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Collapsible, LinkHelpText, P, Table, Ui } from "common/components/atoms";
import { ChevronDownIcon, PlusIcon, QuestionCircleIcon } from "common/icons/svg";
import { CollapsibleHeaderProps, sortingParams } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import IssueEquityContext from "pages/equity-management/ownership/financial-instruments/IssueEquityContext";
import { createTranslation, TranslationNS } from "translation";

import { IssueEquityWarrantsDTO } from "../../IssueEquityContext.types";
import classes from "../../Tables.module.scss";
import WarrantsTableBody from "./WarrantsTableBody";
import WarrantsTableHead from "./WarrantsTableHead";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.issueEquity.warrants"),
  createTranslation(TranslationNS.common, "noAccess"),
];

export type WarrantsTableSortValue = {
  field: keyof IssueEquityWarrantsDTO;
  type: keyof typeof sortingParams;
};

const WarrantsTable = () => {
  const { hasFullAccess } = useFeatures(FEATURES.issueEquity);

  const storeWarrants = IssueEquityContext.useStoreState((state) => state.issueEquity?.warrants);

  const setIsWarrantsEditPanelOpen = IssueEquityContext.useStoreActions(
    (actions) => actions.setIsWarrantsEditPanelOpen
  );

  const [activeSortedValue, setActiveSortedValue] = useState<WarrantsTableSortValue>({
    field: "stakholderName",
    type: sortingParams.inc,
  });

  const rows = useMemo(
    () => [
      {
        key: "stakholderName",
        value: t("table.head.stakeholders"),
      },
      {
        key: "shareClassName",
        value: t("table.head.shareClass"),
      },
      {
        key: "warrantPrice",
        value: t("table.head.warrantPrice"),
      },
      {
        key: "exercisePriceSortingValue",
        value: t("table.head.exercisePrice"),
      },
      {
        key: "numberOfShares",
        value: t("table.head.shares"),
      },
      {
        key: "expirationDate",
        value: t("table.head.expirationDate"),
      },
      {
        key: "status",
        value: t("table.head.status"),
      },
    ],
    []
  );

  // added this transformation because we need to have exercise price property for warrants, but we don't have it as original property from API
  // to prevent new value we need to modify existing API response (this is need to use sorting feature inside tables
  const warrants = useMemo(() => {
    return (storeWarrants || []).map((item) => ({
      ...item,
      exercisePriceSortingValue: (item.warrantPrice || 0) * item.numberOfShares,
    }));
  }, [storeWarrants]);

  const handleCreate = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      setIsWarrantsEditPanelOpen({ open: true });
    },
    [setIsWarrantsEditPanelOpen]
  );

  const CollapsibleHeader = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div className={classes["collapsible"]} onClick={onClick}>
          <div className="d-flex align-items-center">
            <Ui.m bold className="me-1">
              {t("table.title")}
            </Ui.m>

            <LinkHelpText title="" content={t("table.infoDescription")}>
              <QuestionCircleIcon width={20} height={20} color={scssVariables.foregroundLow} />
            </LinkHelpText>
          </div>
          <div className="d-flex">
            <Button
              size="s"
              isOnlyIcon
              variant="secondary"
              isDisabled={!hasFullAccess}
              className={cn(classes["add-btn"], "me-5")}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : ""}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              onClick={handleCreate}
            >
              <PlusIcon />
            </Button>

            <div className={classes["icon"]}>
              <ChevronDownIcon
                fontSize={24}
                color={scssVariables.foregroundMedium}
                direction={activeEventKey ? "top" : "bottom"}
              />
            </div>
          </div>
        </div>
      );
    },
    [handleCreate, hasFullAccess]
  );

  return (
    <Collapsible defaultOpen headTitle={t("table.title")} className="mb-5" Header={CollapsibleHeader}>
      {R.or(R.isNil(warrants), !warrants?.length) ? (
        <div className={classes["empty-container"]}>
          <div className={classes["empty"]}>
            <P.s>{t("empty")}</P.s>
          </div>
        </div>
      ) : (
        <Table
          headComponent={
            <WarrantsTableHead rows={rows} sortedValue={activeSortedValue} setSortedValue={setActiveSortedValue} />
          }
          bodyComponent={<WarrantsTableBody sortedValue={activeSortedValue} warrants={warrants} />}
        />
      )}
    </Collapsible>
  );
};

export default WarrantsTable;
