import { FC } from "react";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Ui } from "common/components/atoms";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
import { InstrumentTypesNamesEnum } from "common/enums/enum";
import { PlusIcon } from "common/icons/svg";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import { PlanStatuses } from "../../../../ownership/documents/documents.types";
import classes from "./ProgramContainer.module.scss";

const tCommon = createTranslation(TranslationNS.common);

type ProgramHeaderProps = {
  title?: string;
  stockType?: InstrumentTypesNamesEnum;
  data: OwnershipProgramTableData[];
  description?: string;
  cliff?: number;
  vestingPeriod?: number;
  vestingPeriodTimeUnit?: string;
  cliffTimeUnit?: string;
  status?: string;
  handleAddPlan?: () => void;
};
const ProgramHeader: FC<ProgramHeaderProps> = ({
  title,
  stockType,
  data,
  handleAddPlan,
  cliff,
  vestingPeriod,
  status,
  vestingPeriodTimeUnit = "Months",
  cliffTimeUnit = "Months",
}) => {
  const { hasFullAccess } = useFeatures(FEATURES.managePlans);

  return (
    <div className={cn("d-flex justify-content-between align-items-center", classes["program-header"])}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <Ui.xl bold>{title}</Ui.xl>
          <div className={classes["rounded-container"]}>
            <Ui.s>{data.length}</Ui.s>
          </div>
        </div>
        <div className="mt-1">
          <ul className={classes.subHeader}>
            <li
              className={cn({
                [classes.approved]: status === PlanStatuses.approved,
                [classes.draft]: status === PlanStatuses.draft,
              })}
            >
              <span>{status}</span>
            </li>
            {stockType && (
              <li>
                <span>{stockType}</span>
              </li>
            )}
            {!!cliff && (
              <li>
                <span>{tCommon(`period.vestingCliff${cliffTimeUnit}`, { count: cliff })}</span>
              </li>
            )}
            {!!vestingPeriod && (
              <li>
                <span>{tCommon(`period.vestingPeriod${vestingPeriodTimeUnit}`, { count: vestingPeriod })}</span>
              </li>
            )}
          </ul>
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column">
          <ExpansionPanel.ToggleIgnore>
            <Button
              size="s"
              isOnlyIcon
              variant="primary"
              data-testid="event-button"
              onClick={handleAddPlan}
              isDisabled={status === PlanStatuses.draft || !hasFullAccess}
              tooltipTitle={!hasFullAccess ? tCommon("noAccess.viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("noAccess.tooltip") : undefined}
              className={classes["add-btn"]}
            >
              <PlusIcon />
            </Button>
          </ExpansionPanel.ToggleIgnore>
        </div>
      </div>
    </div>
  );
};

export default ProgramHeader;
