import React, { FC } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { equals } from "ramda";

import { Ui } from "common/components/atoms";
import { ClockFileCashIcon, PieLineGraph2Icon } from "common/icons/svg";

import classes from "./CompanyInformation.module.scss";

// TODO: probably need to replace with common component (not exist for now)
type Props = {
  activeTab: number;
  setSelectedTab: (val: number) => void;
};

const CompanyInfoTabs: FC<Props> = ({ activeTab, setSelectedTab }) => {
  const [_, setSearchParams] = useSearchParams();

  return (
    <div
      className={classNames(classes["tabs-head"], {
        [classes["first"]]: equals(activeTab, 0),
        [classes["second"]]: equals(activeTab, 1),
      })}
    >
      <div
        className={classNames(classes["tab-item"], {
          [classes["active"]]: equals(activeTab, 0),
        })}
        onClick={() => {
          setSelectedTab(0);
          setSearchParams("tab=details");
        }}
      >
        <PieLineGraph2Icon />
        <Ui.s className="ms-1">Company details</Ui.s>
      </div>

      <div
        className={classNames(classes["tab-item"], {
          [classes["active"]]: equals(activeTab, 1),
        })}
        onClick={() => {
          setSelectedTab(1);
          setSearchParams("tab=billing");
        }}
      >
        <ClockFileCashIcon />
        <Ui.s className="ms-1">Plan and Billing</Ui.s>
      </div>
    </div>
  );
};

export default CompanyInfoTabs;
