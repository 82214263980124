import { useCallback } from "react";
import { useFormikContext } from "formik";

import { H } from "common/components/atoms/Typography";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import PoolDescriptionField from "common/pool/components/PoolForm/PoolDescriptionField";
import PoolNameField from "common/pool/components/PoolForm/PoolNameField";
import { createTranslation, TranslationNS } from "translation";

import PoolHelper from "../../components/PoolHelper";
import { poolSubmitter } from "../../CreatePoolForm";
import { PoolFormValues } from "../../usePoolForm";

const t = createTranslation(TranslationNS.pages, "createPool.basics");

const Basic = () => {
  useToastFormikValidator();

  const { isSubmitting, setFieldValue, values } = useFormikContext<PoolFormValues>();

  const handleSubmitter = useCallback(() => {
    setFieldValue("submitter", values?.id ? poolSubmitter.draft : poolSubmitter.postpone);
  }, [setFieldValue, values?.id]);

  return (
    <WizardContent.Content step={1}>
      <H.xs className="text-left mb-5">{t("title")}</H.xs>
      <PoolHelper questionId="poolName" answerText={t.el("nameAnswer")} className="mb-3">
        <PoolNameField />
      </PoolHelper>

      <PoolHelper
        colInputSize={12}
        questionId="poolDescription"
        answerText={t.el("descriptionAnswer")}
        className="mb-3"
      >
        <PoolDescriptionField />
      </PoolHelper>

      <div className="d-flex mt-6">
        <WizardContent.Controls.ContinueButton
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          onClick={handleSubmitter}
          className="ms-auto"
        />
      </div>
    </WizardContent.Content>
  );
};

export default Basic;
