import { FC } from "react";
import classNames from "classnames";

import { EditIcon } from "common/icons/svg";

import classes from "./infoCart.module.scss";

type InfoCartProps = {
  title: string;
  description: string;
  handleClick?: () => void;
  className?: string;
};
const InfoCart: FC<InfoCartProps> = ({ title, description, handleClick, className }) => {
  return (
    <div className={classNames(classes.card, className)}>
      <div>
        <b className={classes.title}>{title}</b>
      </div>
      <div className={classes.description}>{description}</div>
      {handleClick && <EditIcon onClick={handleClick} className={classes.editIcon} width="20px" height="20px" />}
    </div>
  );
};

export default InfoCart;
