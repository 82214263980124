import { FC } from "react";

import { Milestone as MilestoneProps } from "../types";
import Milestone from "./milestone";

type PropsTypes = {
  milestones: MilestoneProps[];
};

const VestingMilestones: FC<PropsTypes> = ({ milestones }) => {
  return (
    <div className="d-flex flex-wrap gap-5 mt-3">
      {milestones.map((milestone) => (
        <Milestone {...milestone} key={milestone.title} />
      ))}
    </div>
  );
};

export default VestingMilestones;
