import { AnimatePresence } from "framer-motion";
import cn from "classnames";
import * as R from "ramda";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import Spinner from "common/components/atoms/Spinner/Spinner";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { addHttp } from "common/utils/functions";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";

import SingleCompanyDetail from "./components/SingleCompanyDetail";
import classes from "./CompanyList.module.scss";

const SHOW_ALL_OPTION = "Showing all";

const CompanyList: FC = () => {
  const { t } = useTranslation(TranslationNS.pages, {
    keyPrefix: "public.companyList",
  });
  const { id } = useParams<{ id: string }>();

  const [filterOptions, setFilterOptions] = useState<string[]>();
  const [selectedOption, setSelectedOption] = useState<string>(SHOW_ALL_OPTION);

  const { HubCompanyList } = useStoreState((state) => state.company);
  const { getHubCompanyListThunk } = useStoreActions((state) => state.company);

  const filteredCompanies = useMemo(() => {
    return selectedOption === SHOW_ALL_OPTION
      ? HubCompanyList?.hubEventCompanies
      : HubCompanyList?.hubEventCompanies.filter((el) => R.pluck("name", el?.industries).includes(selectedOption));
  }, [HubCompanyList?.hubEventCompanies, selectedOption]);

  const renderCompanies = useCallback(() => {
    return filteredCompanies?.map((el) => {
      const imagePath = el?.logoImageFilePath
        ? `${process.env.REACT_APP_BLOB_PUBLIC_URL}${el?.logoImageFilePath}`
        : undefined;

      const tagsData = [
        el.fundingStageName ? { id: 9999, name: el.fundingStageName } : null,
        el.primaryVertical ? { ...el.primaryVertical } : null,
        el.primaryIndustry ? { ...el.primaryIndustry } : null,
      ].filter((el) => el);

      return (
        <div
          key={`Key for single company list item is - ${el.companyId}`}
          className={classes["company-list-list-element"]}
        >
          <SingleCompanyDetail
            url={imagePath}
            title={el.name}
            id={el.companyId}
            tags={tagsData as []}
            foundedAt={el?.foundedAt}
            city={el.headquartersCity}
            oneLiner={el.elevatorPitch}
            companyPhase={el.companyPhaseName}
            country={el.headquartersCountryName}
          />
        </div>
      );
    });
  }, [filteredCompanies]);

  const renderPartners = useCallback(() => {
    return (
      <>
        <div className={cn(classes["company-list-partners"], "mt-8 mb-10")}>
          {HubCompanyList?.hubEventPartners?.map((el) => {
            const imagePath = el?.logoImageFilePath
              ? `${process.env.REACT_APP_BLOB_PUBLIC_URL}${el?.logoImageFilePath}`
              : "";

            const onPartnerClickHandler = () => {
              const url = addHttp(el?.websiteUrl);
              window.open(url, "_blank");
            };

            return (
              <div
                title={el?.name}
                key={`Key for single partner item is - ${el.id}`}
                className={cn(classes["company-list-partners-single"], "cursor-pointer")}
                data-testid="single-company-partner-test-id"
                onClick={onPartnerClickHandler}
              >
                <Image width="100%" height="100%" src={imagePath} style={{ objectFit: "contain" }} />
              </div>
            );
          })}
        </div>
      </>
    );
  }, [HubCompanyList?.hubEventPartners]);

  useEffect(() => {
    getHubCompanyListThunk(Number(id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (HubCompanyList) {
      const industries = Array.from(
        new Set(R.pluck("name", R.flatten(R.pluck("industries", HubCompanyList?.hubEventCompanies))))
      );

      setFilterOptions([SHOW_ALL_OPTION, ...industries]);
    }
  }, [HubCompanyList]);

  return (
    <>
      <PageContent data-testid="company-list-public-page-test-id" className="d-flex flex-column">
        {!R.isNil(HubCompanyList) ? (
          <>
            {HubCompanyList?.logoImageFilePath && (
              <Image
                className={classes["company-list-logo"]}
                src={`${process.env.REACT_APP_BLOB_PUBLIC_URL}${HubCompanyList?.logoImageFilePath}`}
              />
            )}

            <p className="m-0 head-m text-center mt-1 mb-1" style={{ wordBreak: "break-word" }}>
              {HubCompanyList?.name}
            </p>

            <p
              className={cn(
                "m-0 ui-lg-s ui-xs-xs align-self-center text-center mb-10",
                classes["company-list-description"]
              )}
            >
              {HubCompanyList?.description}
            </p>

            <Dropdown
              style={{ minWidth: "200px", maxWidth: "330px" }} // TODO WIDTH
              className="mb-4"
              label={t("filterBy")}
              selectedValue={selectedOption}
              options={R.defaultTo([], filterOptions)}
              onChange={(e: string) => {
                setSelectedOption(e);
              }}
            />

            <AnimatePresence>
              <div
                className={cn(
                  "d-flex flex-wrap justify-content-md-center justify-content-xxl-start",
                  classes["company-list-list-wrapper"]
                )}
              >
                {renderCompanies()}
              </div>
            </AnimatePresence>

            <p className="m-0 head-s text-center mt-sm-2 mt-xxl-11">{t("partners")}</p>

            {renderPartners()}
          </>
        ) : (
          <Spinner />
        )}
      </PageContent>
    </>
  );
};

export default CompanyList;
