import { Action, action, createContextStore } from "easy-peasy";

interface TableBrowserContextType {
  timestamp: string;
  tableName: string;
  // actions
  setTimestamp: Action<this, string>;
  setTableName: Action<this, string>;
}

const TableBrowserContext = createContextStore<TableBrowserContextType>(
  {
    timestamp: "",
    tableName: "",
    // actions
    setTimestamp: action((state, payload) => {
      state.timestamp = payload;
    }),
    setTableName: action((state, payload) => {
      state.tableName = payload;
    }),
  },
  {
    name: "TableBrowserContext",
  }
);

export default TableBrowserContext;
