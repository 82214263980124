import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";

import { BreggCompanyShareholders } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { Button, ChecksGroup, H, ModalInfo, P } from "../../index";
import useTableBrowserStorage from "../TableBrowserStorage/useTableBrowserStorage";

const t = createTranslation(TranslationNS.common, "importTable.unsavedChangesModal");

export type UnsavedChangesOptions<T extends string[]> = "browser" | "remove" | keyof Readonly<{ [K in T[number]]: K }>;

export type SelectionItems<T extends string[]> = {
  title: string;
  description: string;
  id: UnsavedChangesOptions<T>;
};

type UnsavedChangesModalProps<T extends string[]> = {
  selectedValue?: UnsavedChangesOptions<T>;
  selectedTypeCallback?: (selected: UnsavedChangesOptions<T>) => void;
  additionalItems?: SelectionItems<T>[];
};
const UnsavedChangesModal = <T extends string[]>({
  selectedValue,
  selectedTypeCallback,
  additionalItems = [],
}: UnsavedChangesModalProps<T>) => {
  const [selected, setSelected] = useState<UnsavedChangesOptions<T>>(selectedValue || "browser");
  const [show, setShow] = useState<boolean>(false);

  const { getImportTableData, timestamp } = useTableBrowserStorage<BreggCompanyShareholders[]>();

  useEffect(() => {
    const storageData = getImportTableData();
    if (storageData?.data && storageData?.data.length) {
      setShow(true);
    }
  }, [getImportTableData]);

  const data = useMemo<SelectionItems<T>[]>(
    () => [
      {
        title: t("draftVersion.title"),
        description: t("draftVersion.subtitle", { date: format(+timestamp, "eee dd. MMM, HH:mm") }),
        id: "browser",
      },
      ...additionalItems,
      {
        title: t("startOver.title"),
        description: t("startOver.subtitle"),
        id: "remove",
      },
    ],
    [additionalItems, timestamp]
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSelected(e.target.value as UnsavedChangesOptions<T>);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleContinue = useCallback(() => {
    selectedTypeCallback?.(selected);
    handleClose();
  }, [selectedTypeCallback, selected, handleClose]);

  return (
    <ModalInfo
      size="sm"
      show={show}
      handleClose={handleClose}
      footer={
        <>
          <Button variant="primary" onClick={handleContinue}>
            {t("continue")}
          </Button>
          <Button onClick={handleClose} variant="secondary">
            {t("cancel")}
          </Button>
        </>
      }
    >
      <H.xs>{t("title")}</H.xs>
      <P.m className="mt-3">{t("description")}</P.m>
      <div className="mt-3">
        {data.map((item) => (
          <ChecksGroup.Check
            key={item.id}
            type="radio"
            label={item.title}
            description={item.description}
            checked={selected === item.id}
            name={"unsavedChanges"}
            value={item.id}
            onChange={handleChange}
          />
        ))}
      </div>
    </ModalInfo>
  );
};

export default UnsavedChangesModal;
