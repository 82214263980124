import axios from "axios";
import { Action, action, ActionOn, actionOn, createContextStore, Thunk, thunk } from "easy-peasy";

import { notify } from "common/utils/notify/notifyFunction";
import { getThunkTypes } from "store/storeThunk";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

export interface IExercisingItem {
  reminderSentAt: string | null;
  approvedAt: string | null;
  companyName: string;
  convertPrice: string;
  currencySymbol: string;
  exerciseRequestId: number;
  exerciseRequestStatus: number;
  issuedAt: string | null;
  moneyTransferredAt: string | null;
  numberOfOptions: number;
  planId: number;
  requestedAt: string;
  programName: string;
  vestedNotExercised: number;
  requestorName: string;
  avatarFilePath?: string;
  requestorCompanyName?: string;
  requestorIsCompanyOwned: boolean;
}

interface IExercisingContextModel {
  loading: boolean;
  requestLoading: boolean;
  overview: {
    pendingApprovalTotal: number;
    submittedTotal: number;
    waitingForPaymentTotal: number;
  };
  pendingApproval: IExercisingItem[];
  readyToBeIssued: IExercisingItem[];
  waitingForPayment: IExercisingItem[];
  historicExercises: IExercisingItem[];
  issueShare: IExercisingItem | null;
  isIssueSharesModalOpen: boolean;
  setIsIssueSharesModalOpen: Action<this, boolean>;
  setIssueShare: Action<this, IExercisingItem | null>;
  setLoadingRequest: Action<this, boolean>;
  getMenageExercisingThunk: Thunk<this, number>;
  approveSinglePendingThunk: Thunk<this, number>;
  approveAllPendingThunk: Thunk<this, number>;
  sendSingleReminderThunk: Thunk<this, number>;
  sendAllRemindersThunk: Thunk<this, number>;
  markAsPaidThunk: Thunk<this, number>;
  approveIssueShareThunk: Thunk<this, { exerciseRequestId: number; fileAgreement?: { newFile?: File | null } }>;
  onGetMenageExercisingAction: ActionOn<this>;

  declineExercising: IExercisingItem | null;
  setDeclineExercising: Action<this, this["declineExercising"]>;
  missingBankInformation: boolean;
  setMissingBankInformation: Action<this, this["missingBankInformation"]>;
}

const ExercisingContext = createContextStore<IExercisingContextModel>(
  {
    loading: false,
    requestLoading: false,
    issueShare: null,
    declineExercising: null,
    missingBankInformation: false,
    setDeclineExercising: action((state, payload) => {
      state.declineExercising = payload;
    }),
    overview: {
      pendingApprovalTotal: 0,
      submittedTotal: 0,
      waitingForPaymentTotal: 0,
    },
    pendingApproval: [],
    readyToBeIssued: [],
    waitingForPayment: [],
    historicExercises: [],
    isIssueSharesModalOpen: false,
    setIsIssueSharesModalOpen: action((state, payload) => {
      state.isIssueSharesModalOpen = payload;
    }),
    setIssueShare: action((state, payload) => {
      state.issueShare = payload;
    }),
    setLoadingRequest: action((state, payload) => {
      state.requestLoading = payload;
    }),
    setMissingBankInformation: action((state, payload) => {
      state.missingBankInformation = payload;
    }),
    getMenageExercisingThunk: thunk(async (_, companyId, { fail }) => {
      try {
        const response = await axios.get(`/api/ownership/manage-exercising/${companyId}`);
        return response.data || [];
      } catch (e) {
        fail(e);
        throw e;
      }
    }),
    approveSinglePendingThunk: thunk(async (actions, exerciseRequestId, { fail }) => {
      try {
        actions.setLoadingRequest(true);
        return await axios.post("/api/ownership/manage-exercising/approve-request", { exerciseRequestId });
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoadingRequest(false);
      }
    }),
    approveAllPendingThunk: thunk(async (actions, companyId, { fail }) => {
      try {
        actions.setLoadingRequest(true);
        await axios.post("/api/ownership/manage-exercising/approve-all-requests", { companyId });
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoadingRequest(false);
      }
    }),
    sendSingleReminderThunk: thunk(async (actions, exerciseRequestId, { fail }) => {
      try {
        actions.setLoadingRequest(true);
        return await axios.post("/api/ownership/manage-exercising/remind-pending-payment", { exerciseRequestId });
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoadingRequest(false);
      }
    }),
    sendAllRemindersThunk: thunk(async (actions, companyId, { fail }) => {
      try {
        actions.setLoadingRequest(true);
        await axios.post("/api/ownership/manage-exercising/remind-all-pending-payments", { companyId });
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoadingRequest(false);
      }
    }),
    markAsPaidThunk: thunk(async (actions, exerciseRequestId, { fail }) => {
      try {
        actions.setLoadingRequest(true);
        return await axios.post("/api/ownership/manage-exercising/mark-as-paid", { exerciseRequestId });
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoadingRequest(false);
      }
    }),
    approveIssueShareThunk: thunk(async (actions, payload, { fail }) => {
      try {
        actions.setLoadingRequest(true);
        const formData = new FormData();
        formData.append("exerciseRequestId", String(payload.exerciseRequestId));
        formData.append("file", (payload?.fileAgreement?.newFile as Blob) || "");
        await axios.post("/api/ownership/manage-exercising/issue-shares", formData);
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoadingRequest(false);
      }
    }),
    onGetMenageExercisingAction: actionOn(
      (actions) => getThunkTypes(actions.getMenageExercisingThunk),
      (state, target) => {
        const [startType, successType, errorType] = target.resolvedTargets;
        state.loading = startType === target.type;
        if (successType === target.type) {
          state.overview.pendingApprovalTotal = target.result.pendingApprovalTotal;
          state.overview.submittedTotal = target.result.readyToBeIssued.length;
          state.overview.waitingForPaymentTotal = target.result.waitingForPaymentTotal;
          state.pendingApproval = target.result.pendingApproval;
          state.readyToBeIssued = target.result.readyToBeIssued;
          state.waitingForPayment = target.result.waitingForPayment;
          state.historicExercises = target.result.historicExercises;
          state.missingBankInformation = target.result.missingBankInformation;
        }
        if (errorType === target.type) {
          notify(t("Error"), true, "error");
        }
      }
    ),
  },
  {
    name: "Exercising",
  }
);

export default ExercisingContext;
