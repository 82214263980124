import axios from "axios";
import { action, thunk } from "easy-peasy";

import { PoolCompanyGetDTO, PoolCreateOrUpdateDTO, PoolGetDTO, PoolModel } from "./modelTypes";

export const SOURCE_IS_EXISTING_SHARES_ID = -1;
export const SOURCE_IS_NEW_SHARES_ID = 0;

export const poolModel: PoolModel = {
  pool: null,
  companyPools: [],
  isGetPoolLoading: false,
  isGetPoolsLoading: false,
  isPostPoolLoading: false,
  //action
  setPoolAction: action((state, pool) => {
    state.pool = pool || null;
  }),
  setPoolsAction: action((state, pools) => {
    state.companyPools = pools || [];
  }),
  setLoadingAction: action((state, { param, status }) => {
    state[param] = status;
  }),
  //thunks
  getCompanyPoolsThunk: thunk(async (actions, companyId) => {
    try {
      actions.setLoadingAction({ param: "isGetPoolsLoading", status: true });
      const res = await axios.get<PoolCompanyGetDTO[]>(`/api/pool/company/${companyId}`);
      actions.setPoolsAction(res.data);
      return res;
    } catch (e) {
      actions.setPoolsAction([]);
      return Promise.reject(e);
    } finally {
      actions.setLoadingAction({ param: "isGetPoolsLoading", status: false });
    }
  }),
  getPoolThunk: thunk(async (actions, poolId) => {
    try {
      actions.setLoadingAction({ param: "isGetPoolLoading", status: true });
      const res = await axios.get<PoolGetDTO>(`/api/pool/${poolId}`);
      actions.setPoolAction(res.data);
      return res;
    } catch (e) {
      actions.setPoolAction(null);
      return Promise.reject(e);
    } finally {
      actions.setLoadingAction({ param: "isGetPoolLoading", status: false });
    }
  }),
  createOrUpdatePoolThunk: thunk(async (actions, formValues) => {
    try {
      actions.setLoadingAction({ param: "isPostPoolLoading", status: true });

      const formData = new FormData();

      Object.keys(formValues).forEach((key) => {
        const item = key as keyof PoolCreateOrUpdateDTO;
        const allowTypes = ["number", "string", "boolean"];
        if (allowTypes.includes(typeof formValues?.[item])) {
          formData.append(key, String(formValues[item]));
        }
      });

      if (formValues?.filesData?.files?.length) {
        formValues.filesData.files.forEach((file) => {
          formData.append("filesData.files", file);
        });
      }
      if (formValues?.filesData?.oldFiles?.length) {
        formValues.filesData.oldFiles.forEach((file) => {
          formData.append("filesData.oldFileIds", String(file.fileId));
        });
      }

      const res = await axios.post<PoolGetDTO>("/api/pool", formData);
      actions.setPoolAction(res.data);
      return res;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setLoadingAction({ param: "isPostPoolLoading", status: false });
    }
  }),
  approvePoolThunk: thunk(async (actions, payload) => {
    try {
      actions.setLoadingAction({ param: "isPostPoolLoading", status: true });
      return await axios.post("/api/pool/approve", payload);
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setLoadingAction({ param: "isPostPoolLoading", status: false });
    }
  }),
};
