import { FC, memo, useCallback } from "react";

import Button from "common/components/atoms/Button/Button";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import { createTranslation, TranslationNS } from "translation";

import classes from "./GrantPlanModal.module.scss";

const t = createTranslation(TranslationNS.pages, "createPlan.summary.grantAllPlans");

type AddEmailModalProps = {
  handleGrantPlan: () => void;
  handleCloseModal: () => void;
  show: boolean;
  totalPlans: number;
};

const GrantPlanModal: FC<AddEmailModalProps> = ({ show, handleGrantPlan, handleCloseModal, totalPlans }) => {
  const handleGrant = useCallback(() => {
    handleGrantPlan();
    handleCloseModal();
  }, [handleCloseModal, handleGrantPlan]);

  return (
    <ModalInfo className={classes.sendInvite} header={t("title")} show={show} handleClose={handleCloseModal}>
      <p className="mt-1">
        {t("description")}
      </p>
      <div className="mt-3">
        <div>
          <Button onClick={handleGrant} variant="primary" type="button" className="me-2">
            {t("grant", { count: totalPlans })}
          </Button>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </ModalInfo>
  );
};

export default memo(GrantPlanModal);
