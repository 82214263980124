import axios, { AxiosResponse } from "axios";
import { defaultTo } from "ramda";

import store from "store/store";

export type ShareClassServiceGetResponse = {
  id: number;
  shareClassTypeId: number;
  name: string;
  purpose: string | null;
  hasVotingRights: boolean;
  votingRightsMultiple: number;
  hasDividends: boolean;
  hasPreferences: boolean;
  seniorityLevel: number | null;
  seniorityLevelMultiple: number | null;
  isParticipating: boolean;
  participatingCap: number | null;
  shareClassAntiDilutionId: number | null;
};

export type ShareClassServiceCreateData = {
  companyId: number;
  shareClassTypeId: ShareClassServiceGetResponse["shareClassTypeId"];
  name: ShareClassServiceGetResponse["name"];
  purpose?: ShareClassServiceGetResponse["purpose"];
  hasVotingRights: ShareClassServiceGetResponse["hasVotingRights"];
  votingRightsMultiple: ShareClassServiceGetResponse["votingRightsMultiple"];
  hasDividends: ShareClassServiceGetResponse["hasDividends"];
  hasPreferences: ShareClassServiceGetResponse["hasPreferences"];
  seniorityLevel: ShareClassServiceGetResponse["seniorityLevel"];
  seniorityLevelMultiple: ShareClassServiceGetResponse["seniorityLevelMultiple"];
  isParticipating: ShareClassServiceGetResponse["isParticipating"];
  participatingCap: ShareClassServiceGetResponse["participatingCap"];
  shareClassAntiDilutionId: ShareClassServiceGetResponse["shareClassAntiDilutionId"];
};

export type ShareClassServiceUpdateData = ShareClassServiceCreateData & {
  shareClassId: ShareClassServiceGetResponse["id"];
};

export type ShareClassServiceCompanyShareClassesResponse = Pick<ShareClassServiceGetResponse, "id" | "name">[];

class ShareClassService {
  static apiBase = "/api/equity-management/share-class";

  static get(shareClassId: string | number): Promise<AxiosResponse<ShareClassServiceGetResponse>> {
    return axios.get<ShareClassServiceGetResponse>(`${ShareClassService.apiBase}/${shareClassId}`);
  }

  static create(data: ShareClassServiceCreateData): Promise<AxiosResponse<ShareClassServiceGetResponse>> {
    const activeCompanyId = store.getState().activeCompanyModel.companyId;

    if (!activeCompanyId) {
      return Promise.reject();
    }

    if (!data.companyId) {
      data.companyId = activeCompanyId;
    }

    return axios.post<ShareClassServiceGetResponse>(ShareClassService.apiBase, data);
  }

  static update(data: ShareClassServiceUpdateData): Promise<AxiosResponse<ShareClassServiceGetResponse>> {
    const activeCompanyId = store.getState().activeCompanyModel.companyId;

    if (!activeCompanyId) {
      return Promise.reject();
    }

    if (!data.companyId) {
      data.companyId = activeCompanyId;
    }

    return axios.post<ShareClassServiceGetResponse>(ShareClassService.apiBase, data);
  }

  static companyShareClasses(
    companyId: string | number = store.getState().activeCompanyModel.companyId || 0
  ): Promise<AxiosResponse<ShareClassServiceCompanyShareClassesResponse>> {
    if (!companyId) {
      return Promise.reject();
    }

    return axios.get<ShareClassServiceCompanyShareClassesResponse>(`${ShareClassService.apiBase}/company/${companyId}`);
  }

  static companyShareClassesWithDetails(
    companyId?: string | number
  ): Promise<AxiosResponse<ShareClassServiceGetResponse[]>> {
    companyId = companyId || store.getState().activeCompanyModel.companyId || 0;
    if (!companyId) {
      return Promise.reject();
    }

    return axios.get<ShareClassServiceGetResponse[]>(`${ShareClassService.apiBase}/company-with-details/${companyId}`);
  }

  static responseToUpdateData(shareClass: ShareClassServiceGetResponse): ShareClassServiceUpdateData {
    return {
      companyId: store.getState().activeCompanyModel.companyId || 0,
      shareClassId: shareClass.id,
      shareClassTypeId: shareClass.shareClassTypeId,
      name: shareClass.name,
      purpose: shareClass.purpose,
      hasVotingRights: shareClass.hasVotingRights,
      votingRightsMultiple: defaultTo(1, shareClass.votingRightsMultiple),
      hasDividends: shareClass.hasDividends,
      hasPreferences: shareClass.hasPreferences,
      seniorityLevel: shareClass.seniorityLevel,
      seniorityLevelMultiple: shareClass.seniorityLevelMultiple,
      isParticipating: shareClass.isParticipating,
      participatingCap: shareClass.participatingCap,
      shareClassAntiDilutionId: shareClass.shareClassAntiDilutionId,
    };
  }
}

export default ShareClassService;
