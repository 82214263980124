import { FC, useMemo } from "react";

import { ACCESS_LEVELS,AccessLevels } from "common/access-control/types";
import Check from "common/components/atoms/Checks/Check";

import { FeatureCategory } from "../../../types";

type BodyPropsTypes = {
  featuresCategory: FeatureCategory;
  onChangeAccessLevel: (featureId: number, accessLevel: AccessLevels) => void;
};

const TableBody: FC<BodyPropsTypes> = ({ featuresCategory, onChangeAccessLevel }) => {
  const sortedFeatures = useMemo(
    () => Object.entries(featuresCategory).sort((a, b) => a[1].featureName.localeCompare(b[1].featureName)),
    [featuresCategory]
  );

  return (
    <tbody>
      {sortedFeatures.map(([featureId, feature]) => (
        <tr key={featureId}>
          <td>{feature.featureName}</td>
          <td>
            <Check
              style={{ width: "20px", margin: "auto" }}
              type="radio"
              checked={feature.accessLevel === ACCESS_LEVELS.NO_ACCESS}
              disabled={feature.disabledAccessLevel === ACCESS_LEVELS.NO_ACCESS}
              onChange={() => onChangeAccessLevel(Number(featureId), 0)}
            />
          </td>
          <td>
            <Check
              style={{ width: "20px", margin: "auto" }}
              type="radio"
              checked={feature.accessLevel === ACCESS_LEVELS.VIEW}
              disabled={feature.disabledAccessLevel === ACCESS_LEVELS.VIEW}
              onChange={() => onChangeAccessLevel(Number(featureId), 1)}
            />
          </td>
          <td>
            <Check
              style={{ width: "20px", margin: "auto" }}
              type="radio"
              checked={feature.accessLevel === ACCESS_LEVELS.FULL}
              disabled={feature.disabledAccessLevel === ACCESS_LEVELS.FULL}
              onChange={() => onChangeAccessLevel(Number(featureId), 2)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
