import { FC, ReactNode } from "react";

import { H } from "common/components/atoms/Typography";

import classes from "./WizardHeader.module.scss";

export type WizardHeaderProps = {
  title?: ReactNode;
};

const WizardHeader: FC<WizardHeaderProps> = ({ title }) => (
  <div className={classes["header"]}>{!!title && <H.l className={`${classes["title"]}`}>{title}</H.l>}</div>
);

export default WizardHeader;
