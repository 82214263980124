import { FC } from "react";

import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "companySettings.accessControlPage.inviteEditForm.accessTable");

const TableHeader: FC = () => {
  return (
    <thead>
      <tr>
        <th style={{ width: "200px" }}>{t("pages")}</th>
        <th>{t("noAccess")}</th>
        <th>{t("viewOnly")}</th>
        <th>{t("full")}</th>
      </tr>
    </thead>
  );
};

export default TableHeader;
