import { useMemo } from "react";
import { compareAsc } from "date-fns";
import * as R from "ramda";
import * as Yup from "yup";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum } from "common/enums/enum";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";

const tv = createTranslation(TranslationNS.validation);

export type ConvertLoanFormValues = {
  loanId: number;
  valuationAtConversion: number;
  comment?: string;
  conversionReasonId?: number;
  loanSettleDate: string;
  shareIssueDate?: string;
  agreementDate: string;
  shareClassId?: number;
  documentStatusId: DocumentStatusEnum;
  documents: FilesDataMultiple;
  loanAmount?: number;
  saveAsConverting: boolean;
  loanAmountRepaid?: number;
  interestRepaid: boolean;
  interestRate?: number;
  valuationCap?: number;
  sharesInCompanyBeforeConversion?: number;
  discount?: number;
  interest: number;
  interestOverride?: number;
};

const useConvertLoanForm = () => {
  const convertLoan = IssueEquityContext.useStoreState((state) => state.conversionDetailsCL);

  const initialValues: ConvertLoanFormValues = useMemo(
    () => ({
      remainingInvestment: R.defaultTo(0, convertLoan?.remainingInvestment),
      loanId: R.defaultTo(0, convertLoan?.id),
      conversionReasonId: R.defaultTo(undefined, convertLoan?.conversionReasonId),
      loanAmount: R.defaultTo(0, convertLoan?.loanAmount),
      valuationAtConversion: R.defaultTo(0, convertLoan?.valuationAtConversion),
      loanSettleDate: convertLoan?.loanSettleDate || createDateString(new Date()),
      agreementDate: convertLoan?.agreementDate || createDateString(new Date()),
      shareClassId: R.defaultTo(undefined, convertLoan?.shareClassId),
      comment: R.defaultTo("", convertLoan?.comment),
      documentStatusId: R.defaultTo(DocumentStatusEnum.REVIEW_LATER, convertLoan?.documentStatusId),
      saveAsConverting: false,
      interestRate: R.defaultTo(0, convertLoan?.interestRate),
      interestRepaid: R.defaultTo(false, convertLoan?.interestRepaid),
      loanAmountRepaid: R.defaultTo(0, convertLoan?.loanAmountRepaid),
      valuationCap: R.defaultTo(0, convertLoan?.valuationCap),
      sharesInCompanyBeforeConversion: R.defaultTo(0, convertLoan?.sharesInCompanyBeforeConversion),
      discount: R.defaultTo(0, convertLoan?.discount),
      interest: R.defaultTo(0, convertLoan?.interest),
      documents: {
        newFiles: [],
        oldFiles: R.defaultTo([], convertLoan?.conversionDocumentFiles),
      },
      shareIssueDate: R.defaultTo(createDateString(new Date()), convertLoan?.shareIssueDate),
      interestOverride: R.defaultTo(0, convertLoan?.interest),
    }),
    [convertLoan]
  );

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      conversionReasonId: Yup.number().min(1, tv("required")).required(tv("required")),
      shareClassId: Yup.number().required(tv("required")),
      agreementDate: Yup.date(),
      loanSettleDate: Yup.date()
        .required(tv("required"))
        .test("loanSettleDate", "Must must be after agreement date", (value, object) => {
          return (
            compareAsc(value || new Date(), object.parent.agreementDate) === 0 ||
            compareAsc(value || new Date(), object.parent.agreementDate) === 1
          );
        }),
      shareIssueDate: Yup.date()
        .required(tv("required"))
        .test("shareIssueDate", "Must be after agreement date", (value, object) => {
          return (
            compareAsc(value || new Date(), object.parent.agreementDate) === 0 ||
            compareAsc(value || new Date(), object.parent.agreementDate) === 1
          );
        }),
      valuationAtConversion: Yup.number()
        .required(tv("required"))
        .min(Yup.ref("loanAmount"), tv("minNumber", { number: "Loan amount" })),
      loanAmountRepaid: Yup.number()
        .required(tv("required"))
        .max(Yup.ref("loanAmount"), tv("maxNumber", { number: "Loan amount" })),

      documents: Yup.object().when("documentStatusId", {
        is: (documentValue: number) => +documentValue === DocumentStatusEnum.DOCUMENT_UPLOADED,
        then: Yup.object().shape({
          newFiles: Yup.mixed().when("oldFiles", {
            is: (oldFiles: any) => !oldFiles.length,
            then: Yup.array().min(1, tv("required")),
          }),
        }),
      }),
      interestOverride: Yup.number()
        .nullable()
        .min(0, tv("minNumber", { number: "0" })),
    });
  }, []);

  return {
    initialValues,
    validationSchema,
  };
};

export default useConvertLoanForm;
