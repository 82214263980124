import { FC, useCallback, useMemo } from "react";
import { format } from "date-fns";
import { motion } from "framer-motion";
import { defaultTo } from "ramda";

import { Button, P, Tag, Ui } from "common/components/atoms";
import { animationStakeholder } from "common/components/molecules/StakeholderSection/fields/RenderStakeholderFields/RenderStakeholderFields";
import { CloseIcon, CommonFileIcon, FolderIcon, PledgesNotesIcon } from "common/icons/svg";

const t = createTranslation(TranslationNS.common, "organisms.stakeholderDetails.shareSeriesTable.expandedRow");

import { createTranslation, TranslationNS } from "translation";

import classes from "../../stakeholder-details.module.scss";
import { CapTableIssueShares } from "../../types";
import ViewDocument from "./ViewDocument";

type Props = Partial<CapTableIssueShares> & {
  columnSize: number;
  open: boolean;
  clickRowCallback: (issuedSharesId: number) => void;
};

const ExpandedRow: FC<Props> = ({ clickRowCallback, issuedSharesId, ...props }) => {
  const handleRowClick = useCallback(() => {
    clickRowCallback(issuedSharesId || 0);
  }, [clickRowCallback, issuedSharesId]);

  const listDocuments = useMemo(() => defaultTo([], props.files), [props?.files]);

  return (
    <tr>
      <td className={classes["expanded-row"]} colSpan={props.columnSize}>
        <motion.div initial="close" variants={animationStakeholder} animate={props.open ? "open" : "close"}>
          <div className="d-flex gap-3 pt-4 pb-9">
            <div className="d-flex gap-2 pe-3" style={{ width: 300 }}>
              <div>
                <PledgesNotesIcon fontSize={24} />
              </div>
              <div>
                <Ui.m color="foregroundHigh" className="mb-2 lh-lg">
                  {t("pledgeNotes")}
                </Ui.m>
                <P.s style={{ whiteSpace: "pre-wrap" }}>{props?.pledgeNotes ? props?.pledgeNotes : "-"}</P.s>
              </div>
            </div>
            <div className="d-flex gap-2 pe-3" style={{ width: 480 }}>
              <div>
                <CommonFileIcon fontSize={24} />
              </div>
              <div>
                <Ui.m color="foregroundHigh" className="mb-2 lh-lg">
                  {t("description")}
                </Ui.m>

                <>
                  <div className="d-flex align-items-center gap-1 mb-1">
                    <Tag size="s" variant="complete">
                      {props.transactionCategoryName}
                    </Tag>
                    <Ui.xs bold color="foregroundHigh">
                      {props.shares} shares ({props.transactionTypeName})
                    </Ui.xs>
                    <Ui.xs color="foregroundLow">
                      {props?.transactedAt ? format(new Date(props?.transactedAt), "d. MMM yyyy") : ""}
                    </Ui.xs>
                  </div>
                  <P.s style={{ whiteSpace: "pre-wrap" }}>{props.description ? props.description : "-"}</P.s>
                </>
              </div>
            </div>
            <div className="d-flex gap-2 pe-3">
              <div>
                <FolderIcon fontSize={24} />
              </div>
              <div>
                <Ui.m color="foregroundHigh" className="mb-2 lh-lg d-flex align-items-center">
                  {t("documents")} <Ui.xs className={classes.counter}>{listDocuments.length}</Ui.xs>
                </Ui.m>
                {listDocuments.map((file, index) => (
                  <ViewDocument fileName={file.fileName} downloadId={file.downloadId} key={index} />
                ))}
                {listDocuments.length === 0 && <P.s>-</P.s>}
              </div>
            </div>
            <Button className={classes.closeBtn} variant="tertiary" size="s" isOnlyIcon onClick={handleRowClick}>
              <CloseIcon />
            </Button>
          </div>
        </motion.div>
      </td>
    </tr>
  );
};

export default ExpandedRow;
