import { FC, ReactNode } from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";
import classNames from "classnames";

import classes from "./Message.module.scss";

export type MessageProps = JSX.IntrinsicElements["div"] & {
  message?: ReactNode;
  onContinue?: ButtonProps["onClick"];
  onDiscard?: ButtonProps["onClick"];
};

const Message: FC<MessageProps> = ({
  className,
  message,
  onContinue,
  onDiscard,
  ...props
}) => (
  <div className={classNames(classes["wrap"], className)} {...props}>
    <div>{message}</div>
    {!!onContinue && (
      <Button variant="link" onClick={onContinue}>
        Continue
      </Button>
    )}
    {!!onDiscard && (
      <Button variant="link" onClick={onDiscard}>
        Discard
      </Button>
    )}
  </div>
);

export default Message;
