import { FC, useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import cn from "classnames";
import { useFormikContext } from "formik";

import { Ui } from "common/components/atoms/Typography";
import { WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { PlanForm } from "pages/equity-management/plans/wizards/create-one-off-plans/types";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { newPlanFieldsNames as f } from "../../planFormFields";
import agreementClasses from "../viewAgreement/viewAgreement.module.scss";
import UpdateOrgNumberModal from "./updateOrgNumberModal/updateOrgNumberModal";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar.generatePlanDocument");

const fetchOrganizationNumber = async (companyId: number) => {
  try {
    const response = await axios.get(`/api/attract/general-information/${companyId}`);
    if (response.status === 200) return response.data.organizationNumber;
  } catch (e) {
    console.log(e);
  }
};
type UpdateOrganisationNumberProps = {
  setOrganizationNumber: (organizationNumber: string) => void;
  isCompanyOwner?: boolean;
  companyOrgNumber?: string | null;
  receiverOrgNumber?: string | null;
};
const UpdateOrganisationNumber: FC<UpdateOrganisationNumberProps> = ({
  setOrganizationNumber,
  isCompanyOwner,
  companyOrgNumber,
  receiverOrgNumber,
}) => {
  const companyId = useStoreState((state) => state.activeCompanyModel?.companyId);
  const userCompanies = useStoreState((state) => state.account.user?.companies);

  const activeCompanyName = useMemo(() => {
    return userCompanies?.find((company) => company.id === companyId)?.name;
  }, [userCompanies, companyId]);

  const [isUpdateOrgNumberOpen, setIsUpdateOgrNumberOpen] = useState(false);
  const [orgNumberType, setOrgNumberType] = useState<"company" | "receiver">("company");

  const { values, setFieldValue } = useFormikContext<PlanForm>();

  const handleOpenCompanyModal = useCallback(() => {
    setIsUpdateOgrNumberOpen(true);
    setOrgNumberType("company");
  }, []);

  const handleOpenReceiverModal = useCallback(() => {
    setIsUpdateOgrNumberOpen(true);
    setOrgNumberType("receiver");
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsUpdateOgrNumberOpen(false);
  }, []);

  const handleGetOrgNumber = useCallback(() => {
    if (companyId) {
      fetchOrganizationNumber(companyId).then(setOrganizationNumber);
    }
    setIsUpdateOgrNumberOpen(false);
  }, [companyId, setOrganizationNumber]);

  const updateReceiverOrgNumber = useCallback(
    (orgNumber: string) => {
      setFieldValue(f.organizationNumber, orgNumber);
    },
    [setFieldValue]
  );

  useEffect(() => {
    handleGetOrgNumber();
  }, [handleGetOrgNumber]);

  const textStatus = useMemo(() => {
    if (!companyOrgNumber && !receiverOrgNumber && isCompanyOwner) {
      return "combined";
    } else if (isCompanyOwner && !receiverOrgNumber) {
      return "receiver";
    }
    return "company";
  }, [companyOrgNumber, isCompanyOwner, receiverOrgNumber]);

  return (
    <>
      <div
        className={cn("d-flex", "mt-2", "p-2")}
        style={{ backgroundColor: scssVariables.additional1200, borderRadius: 4 }}
      >
        <span style={{ fontSize: "24px" }}>
          <WarningIcon />
        </span>
        <div className="ms-2">
          <Ui.s className="mb-2">
            {textStatus === "combined" &&
              t.el("needTwoOrgNumber", {
                components: [<strong key={1} />],
              })}
            {textStatus === "company" && t("needCompanyOrgNumber")}
            {textStatus === "receiver" && t("needReceiverOrgNumber")}
          </Ui.s>

          {!companyOrgNumber && (
            <Ui.s className={cn(agreementClasses["link-btn"], "mb-1")} onClick={handleOpenCompanyModal}>
              {t("updateCompanyOrgNumber")}
            </Ui.s>
          )}
          {!receiverOrgNumber && isCompanyOwner && (
            <Ui.s className={cn(agreementClasses["link-btn"], "mb-1")} onClick={handleOpenReceiverModal}>
              {t("updateReceiverOrgNumber")}
            </Ui.s>
          )}
        </div>
      </div>

      {isUpdateOrgNumberOpen && companyId && (
        <UpdateOrgNumberModal
          companyName={activeCompanyName}
          receiverCompanyName={values.companyName || `${values.firstName} ${values.lastName}`}
          updateReceiverOrgNumber={updateReceiverOrgNumber}
          type={orgNumberType}
          companyId={companyId}
          onSubmit={handleGetOrgNumber}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

export default UpdateOrganisationNumber;
