import { FC } from "react";
import classNames from "classnames";

import classes from "./Possibilities.module.scss";
import Possibility, { PossibilityProps } from "./Possibility/Possibility";

export type PossibilitiesProps = JSX.IntrinsicElements["div"] &
  Pick<PossibilityProps, "big"> &
  Record<"hasVotingRights" | "hasDividends" | "hasPreferences", boolean> &
  Partial<Record<"votingRightsMultiple", number>> &
  Partial<Record<"isPreferred", boolean>>;

const Possibilities: FC<PossibilitiesProps> = ({
  className,
  big,
  hasVotingRights,
  votingRightsMultiple,
  hasDividends,
  hasPreferences,
  isPreferred,
  ...props
}) => (
  <div
    className={classNames(
      classes["possibilities"],
      {
        [classes["with-border-bottom"]]: isPreferred,
      },
      className
    )}
    {...props}
  >
    <Possibility big={big} enabled={hasVotingRights} multiply={votingRightsMultiple} label="voting-rights" />
    <Possibility big={big} enabled={hasDividends} label="dividends" />
    <Possibility big={big} enabled={hasPreferences} label="preferences" />
  </div>
);

export default Possibilities;
