import { FC, useCallback } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import WithRouteAnimation from "common/HOC/WithRouteAnimation";

import { User } from "../../PostponedComponents/onboard-manager-wizard/wizard-steps";
import classes from "./OnboardUserTerms.module.scss";

const OnboardUserTerms: FC<{ nextStep?: string }> = ({ nextStep }) => {
  const navigate = useNavigate();

  const handleNext = useCallback(() => {
    navigate(nextStep || "/onboard/company");
  }, [navigate, nextStep]);

  return (
    <Container fluid className="d-flex font-secondary" data-testid="onboard-user-terms-container-test-id">
      <Container className="d-flex flex-column m-auto p-0">
        <div className={classes.wrap}>
          <User isDisabled={false} onSubmit={handleNext} />
        </div>
      </Container>
    </Container>
  );
};

export default WithRouteAnimation(OnboardUserTerms);
