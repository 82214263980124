import { FC, Fragment, MouseEventHandler, useCallback, useMemo } from "react";
import { Formik, FormikValues } from "formik";
import * as Yup from "yup";

import { Button, H, Helper, SlidePanel, Ui } from "common/components/atoms";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext from "../../ExercisingContext";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.exercising.issueSharesOffCanvas"),
  createTranslation(TranslationNS.validation),
];

const IssueSharesForm: FC<{ companyId: number }> = ({ companyId }) => {
  const issueShare = ExercisingContext.useStoreState((state) => state.issueShare);
  const requestLoading = ExercisingContext.useStoreState((state) => state.requestLoading);
  const setIssueShare = ExercisingContext.useStoreActions((actions) => actions.setIssueShare);
  const approveIssueShareThunk = ExercisingContext.useStoreActions((actions) => actions.approveIssueShareThunk);
  const getMenageExercisingThunk = ExercisingContext.useStoreActions((actions) => actions.getMenageExercisingThunk);

  const initialValues = useMemo(
    () => ({
      documentStatusId: undefined,
    }),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        documentStatusId: Yup.number().required(tValidation("required")),
      }),
    []
  );

  const handleCloseModal = useCallback(() => {
    setIssueShare(null);
  }, [setIssueShare]);

  const submitHandler = useCallback(
    async (values: FormikValues) => {
      if (issueShare?.exerciseRequestId) {
        approveIssueShareThunk({ exerciseRequestId: issueShare.exerciseRequestId, ...values }).then(() => {
          notify(t("success", { name: issueShare?.requestorName }), true, "success", 2000);
          getMenageExercisingThunk(companyId);
          handleCloseModal();
        });
      }
    },
    [
      issueShare?.exerciseRequestId,
      issueShare?.requestorName,
      approveIssueShareThunk,
      getMenageExercisingThunk,
      companyId,
      handleCloseModal,
    ]
  );

  const SectionHeader = () => {
    return (
      <div className="d-flex align-items-center">
        <Helper.Question questionId="documentation-id">
          <H.xxs>{t("documentation")}</H.xxs>{" "}
        </Helper.Question>
      </div>
    );
  };

  return (
    <SlidePanel show={!!issueShare} onHide={handleCloseModal}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ handleSubmit }) => {
          return (
            <Helper>
              <SlidePanel.Header
                title={t("title", {
                  options: issueShare?.numberOfOptions,
                  name: issueShare?.requestorName,
                })}
                onHide={handleCloseModal}
              />

              <SlidePanel.Section title={<SectionHeader />} isSingle>
                <Helper.Answer
                  className="mb-4"
                  answerId="documentation-id"
                  text={t.el("infoDescription", {
                    components: [
                      <Fragment key={1}>
                        <br />
                        <br />
                      </Fragment>,
                    ],
                  })}
                />

                <GeneratePlanDocument autoGenerateAgreement={false} managerSignature noinvitations exercising />
              </SlidePanel.Section>

              <SlidePanel.Actions className="flex-column">
                <Ui.m>{t("sharesWillBeAproved")}</Ui.m>

                <div className="d-flex me-auto mt-8">
                  <Button
                    isLoading={requestLoading}
                    isDisabled={requestLoading}
                    onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                  >
                    {t("issueShares")}
                  </Button>

                  <Button
                    className="ms-3"
                    variant="secondary"
                    isLoading={requestLoading}
                    isDisabled={requestLoading}
                    onClick={handleCloseModal}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </SlidePanel.Actions>
            </Helper>
          );
        }}
      </Formik>
    </SlidePanel>
  );
};

const IssueSharesOffCanvas: FC<{ companyId: number }> = ({ companyId }) => {
  return <IssueSharesForm companyId={companyId} />;
};

export default IssueSharesOffCanvas;
