import { FC, useCallback } from "react";

import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import ConvertLoanForm from "./ConvertLoanForm";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertLoan");

const ConvertLoanPanel: FC = () => {
  const { isConvertLoanEditPanelOpen } = IssueEquityContext.useStoreState((state) => state);
  const { setIsConvertLoanEditPanelOpen } = IssueEquityContext.useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setIsConvertLoanEditPanelOpen({ open: false });
  }, [setIsConvertLoanEditPanelOpen]);

  return (
    <SlidePanel show={isConvertLoanEditPanelOpen}>
      <SlidePanel.Header title={t("title")} onHide={handleClose} />
      <ConvertLoanForm />
    </SlidePanel>
  );
};

export default ConvertLoanPanel;
