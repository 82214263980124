import { FC, ReactNode, useCallback } from "react";

import { notify } from "common/utils/notify/notifyFunction";

interface CopyTextProps {
  className?: string | undefined;
  copyText: string;
  notifyText?: string;
  children?: ReactNode;
}

const CopyText: FC<CopyTextProps> = ({ children, className, copyText, notifyText }) => {
  const handleCopy = useCallback(() => {
    return new Promise((res) => {
      const el = document.createElement("input");
      el.value = copyText;
      document.body.appendChild(el);
      el.select();
      el.setSelectionRange(0, 99999);
      navigator.clipboard?.writeText(el.value).then(() => {
        res(copyText);
      });
      document.body.removeChild(el);
    }).then((value) => {
      notify(`${notifyText || "Copied:"} ${value}`, true, "success", 2000, false, "top-center");
    });
  }, [copyText, notifyText]);

  return (
    <button data-testid="copy-value-btn-test-id" onClick={handleCopy} className={className}>
      {children}
    </button>
  );
};

export default CopyText;
