import { useEffect, useRef } from "react";
import { useFormikContext } from "formik";

import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

const tValidation = createTranslation(TranslationNS.common, "notify");

const useToastFormikValidator = (showAlert: boolean = true) => {
  const { isValid, isValidating, isSubmitting, submitCount } = useFormikContext();
  const numberOfSubmits = useRef(0);

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      if (submitCount > numberOfSubmits.current) {
        if (showAlert) {
          notify(tValidation("formValidationError"), true, "error", 3000);
        }
        const element = document.querySelector(".is-invalid");
        element?.scrollIntoView({ block: "center", behavior: "smooth" });
        numberOfSubmits.current = submitCount;
      }
    }
  }, [isValid, isValidating, isSubmitting, submitCount, showAlert]);
  return;
};

export const ToastFormikValidator = ({ showAlert = true }: { showAlert?: boolean }) => {
  useToastFormikValidator(showAlert);
  return null;
};

export const useToastFormikValidatorWithParams = (
  isValid: boolean,
  isValidating: boolean,
  isSubmitting: boolean,
  submitCount: number,
  showAlert: boolean = true
) => {
  const numberOfSubmits = useRef(0);

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting) {
      if (submitCount > numberOfSubmits.current) {
        if (showAlert) {
          notify(tValidation("formValidationError"), true, "error", 3000);
        }
        const element = document.querySelector(".is-invalid");
        element?.scrollIntoView({ block: "center", behavior: "smooth" });
        numberOfSubmits.current = submitCount;
      }
    }
  }, [isValid, isValidating, isSubmitting, submitCount, showAlert]);
  return;
};

export default useToastFormikValidator;
