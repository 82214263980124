import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import cn from "classnames";

import { Label, Tooltip, Ui } from "common/components/atoms";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { InformationCircleOutlinedIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioContext from "../../MyPortfolioContext";
import classes from "./InfoData.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview.infoBlocks");

const InfoData: FC = () => {
  const general = MyPortfolioContext.useStoreState((state) => state.portfolio);
  const { currencySymbol } = useCurrencyById(general?.commonCurrencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  return (
    <div className={classes.wrap}>
      <div className={classes.block}>
        <div className={classes.note}>{t("totalValueShares")}</div>
        <Ui.xl bold className={classes.title}>
          {fNumber(general?.totalValue, "value")}
        </Ui.xl>
        <div className={classes.statistic}>
          <Row>
            <Col>
              <Ui.m>
                {t("profitOrLossPercentage.title")}
                <Tooltip className={classes.content} popupContent={t("profitOrLossPercentage.tooltip")}>
                  <span className={classes.icon}>
                    <InformationCircleOutlinedIcon />
                  </span>
                </Tooltip>
              </Ui.m>
            </Col>
            <Col className={"text-end"}>
              <Label className={classes.label} type={(general?.totalProfitPercentage || 0) >= 0 ? "success" : "danger"}>
                {fNumber(general?.totalProfitPercentage, "percent")}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <Ui.m>
                {t("profitOrLoss.title")}
                <Tooltip className={classes.content} popupContent={t("profitOrLoss.tooltip")}>
                  <span className={classes.icon}>
                    <InformationCircleOutlinedIcon />
                  </span>
                </Tooltip>
              </Ui.m>
            </Col>
            <Col className={"text-end"}>
              <Ui.m>{fNumber(general?.totalProfitLoss, "value")}</Ui.m>
            </Col>
          </Row>
          <Row>
            <Col>
              <Ui.m>
                {t("investedAmount.title")}
                <Tooltip className={classes.content} popupContent={t("investedAmount.tooltip")}>
                  <span className={classes.icon}>
                    <InformationCircleOutlinedIcon />
                  </span>
                </Tooltip>
              </Ui.m>
            </Col>
            <Col className={"text-end"}>
              <Ui.m>{fNumber(general?.investedAmount, "value")}</Ui.m>
            </Col>
          </Row>
        </div>
      </div>
      <div className={classes.block}>
        <div className={classes.note}>{t("totalValueInstruments")}</div>
        <Ui.xl bold className={classes.title}>
          {fNumber(general?.totalValueInstruments)}
        </Ui.xl>
        <div className={cn(classes.statistic, "mt-4")}>
          <Row>
            <Col>
              <Ui.s>{t("instrument")}</Ui.s>
            </Col>
            <Col className={"text-end"}>
              <Ui.s>{t("potential")}</Ui.s>
            </Col>
            <Col className={"text-end"}>
              <Ui.s>{t("value")}</Ui.s>
            </Col>
          </Row>
          <Row>
            <Col>
              <Ui.m>
                {t("stockOptions.title")}
                <Tooltip className={classes.content} popupContent={t("stockOptions.tooltip")}>
                  <span className={classes.icon}>
                    <InformationCircleOutlinedIcon />
                  </span>
                </Tooltip>
              </Ui.m>
            </Col>
            <Col className={"text-end"}>
              <Label
                className={classes.label}
                type={(general?.totalValueStockOptionsPercentage || 0) >= 0 ? "success" : "danger"}
              >
                {fNumber(general?.totalValueStockOptionsPercentage, "percent")}
              </Label>
            </Col>

            <Col className="text-end">
              <Ui.m>{fNumber(general?.totalValueStockOptions, "value")}</Ui.m>
            </Col>
          </Row>
          <Row>
            <Col>
              <Ui.m>
                {t("warrants.title")}
                <Tooltip className={classes.content} popupContent={t("warrants.tooltip")}>
                  <span className={classes.icon}>
                    <InformationCircleOutlinedIcon />
                  </span>
                </Tooltip>
              </Ui.m>
            </Col>
            <Col className={"text-end"}>
              <Label
                className={classes.label}
                type={(general?.totalValueWarrantsPercentage || 0) >= 0 ? "success" : "danger"}
              >
                {fNumber(general?.totalValueWarrantsPercentage, "percent")}
              </Label>
            </Col>
            <Col className="text-end">
              <Ui.m>{fNumber(general?.totalValueWarrants, "value")}</Ui.m>
            </Col>
          </Row>
          <Row>
            <Col>
              <Ui.m>
                {t("convertibles.title")}
                <Tooltip className={classes.content} popupContent={t("convertibles.tooltip")}>
                  <span className={classes.icon}>
                    <InformationCircleOutlinedIcon />
                  </span>
                </Tooltip>
              </Ui.m>
            </Col>
            <Col className={"text-end"}>
              <Ui.m>{fNumber(general?.totalValueConvertibles)}</Ui.m>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default InfoData;
