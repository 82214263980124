import { useMemo } from "react";
import { defaultTo } from "ramda";
import * as Yup from "yup";

import { createTranslation, TranslationNS } from "translation";

import { CapitalIncreaseFormGetDTO } from "../../../../../types";

const t = createTranslation(TranslationNS.validation);

export type ImportTransactionFormValues = {
  transactionId?: number;
  transactionDate: string;
  transactionType?: number;
  numberOfShares: number | undefined;
  sharePrice: number | undefined;
  shareClassId: number;
  receivingShareholderId: number;
  description: string;
};

export const fields = {
  transactionDate: "transactionDate",
  transactionType: "transactionType",
  numberOfShares: "numberOfShares",
  sharePrice: "sharePrice",
  total: "total",
  shareClassId: "shareClassId",
  receivingShareholderId: "receivingShareholderId",
  sourceShareholderId: "sourceShareholderId",
  description: "description",
} as const;

export const initValues = (
  sharePrice: number,
  date: string,
  shareClassId: number,
  transactionTypeId: number
): ImportTransactionFormValues => ({
  transactionId: undefined,
  [fields.transactionDate]: date,
  [fields.transactionType]: transactionTypeId,
  [fields.numberOfShares]: undefined,
  [fields.sharePrice]: sharePrice,
  [fields.shareClassId]: shareClassId,
  [fields.receivingShareholderId]: -1,
  [fields.description]: "",
});

const useImportTransactionForm = (
  bundle: CapitalIncreaseFormGetDTO,
  transactionsInProgress: ImportTransactionFormValues[],
  shareClassId: number,
  transactionTypeId: number
) => {
  const data = useMemo<ImportTransactionFormValues[]>(() => {
    const mapFunction = (transaction: ImportTransactionFormValues) => ({
      transactionId: defaultTo(undefined, transaction.transactionId),
      [fields.transactionDate]: defaultTo(bundle.transactedAt, transaction.transactionDate),
      [fields.transactionType]: defaultTo(undefined, transaction.transactionType),
      [fields.numberOfShares]: defaultTo(undefined, transaction.numberOfShares),
      [fields.sharePrice]: defaultTo(bundle.sharePrice, transaction.sharePrice),
      [fields.shareClassId]: defaultTo(0, transaction.shareClassId),
      [fields.receivingShareholderId]: defaultTo(0, transaction.receivingShareholderId),
      [fields.description]: defaultTo("", transaction.description),
    });

    if (transactionsInProgress.length > 0) {
      return transactionsInProgress.map(mapFunction);
    }

    if (bundle.transactions.length > 0) {
      return bundle.transactions.map(mapFunction);
    }

    return [initValues(bundle.sharePrice, bundle.transactedAt, shareClassId, transactionTypeId)];
  }, [
    bundle.sharePrice,
    bundle.transactedAt,
    bundle.transactions,
    shareClassId,
    transactionTypeId,
    transactionsInProgress,
  ]);

  const personValidationSchema = useMemo(() => {
    const objectSchema = Yup.object().shape({
      [fields.transactionDate]: Yup.string().required(t("required")).nullable(true),
      [fields.numberOfShares]: Yup.number()
        .nullable(true)
        .test("is-decimal", t("integer"), (value) => !!String(value).match(/^\d+$/))
        .min(1, t("required"))
        .required(t("required")),
      [fields.sharePrice]: Yup.number()
        .nullable(true)
        .min(
          0.000001,
          t("minNumber", {
            number: "0,000001",
          })
        )
        .required(t("required")),
      [fields.shareClassId]: Yup.number().min(1, t("required")).required(t("required")),
      [fields.receivingShareholderId]: Yup.number().min(0, t("required")).required(t("required")),
    });

    return Yup.array().of(objectSchema);
  }, []);

  return { personValidationSchema, data };
};

export default useImportTransactionForm;
