import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import FileUploader, { FilesDataSingle } from "common/components/atoms/FileUploader/FileUploader";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { H, P } from "common/components/atoms/Typography";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.pages, "onboard.company.importExcelModal");

type ImportFromExcelCanvasProps<T> = {
  onClose: () => void;
  handleImportData: (handleImportData: T[]) => void;
  api: string;
  keyParam: string;
};
const ImportFromExcelCanvas = <T,>({ onClose, handleImportData, keyParam, api }: ImportFromExcelCanvasProps<T>) => {
  const { companyId } = useParams<{ companyId?: string }>();

  const [file, setFile] = useState<File | null | undefined>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback((filesData: FilesDataSingle | null) => {
    setFile(filesData?.newFile);
  }, []);

  const handleUpload = useCallback(() => {
    if (file && companyId) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("companyId", companyId);

      setIsLoading(true);
      axios
        .post<{ [key: string]: T[] | null }>(api, formData)
        .then((response) => {
          handleImportData(response?.data?.[keyParam] || []);
          onClose();
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [api, companyId, file, handleImportData, keyParam, onClose]);

  return (
    <div>
      <SlidePanel.Header title={t("title")} onHide={onClose} />
      <div className="flex-column">
        <H.xxs className={"mt-5"}>{t("subtitle")}</H.xxs>
        <div className="flex-grow-1;" style={{ minHeight: "calc(100vh - 104px - 260px)" }}>
          <P.m className={"mt-2"}>{t("description")}</P.m>
          <FileUploader
            onChange={handleChange}
            className={"mt-4"}
            multiple={false}
            label={t("subtitle")}
            accept={".csv, .xlsx, .xls"}
            filesOnly="Xlsx"
          />
        </div>
        <SlidePanel.Actions
          isLoading={isLoading}
          isDisabledPrimary={!file}
          primaryTitle={t("upload")}
          secondaryTitle={t("cancel")}
          primaryAction={handleUpload}
          secondaryAction={onClose}
        />
      </div>
    </div>
  );
};

export default ImportFromExcelCanvas;
