import {
  ShareClassServiceGetResponse,
  ShareClassServiceUpdateData,
} from "./ShareClassService";

export enum ShareClassTypes {
  common = 1,
  preferred,
}

export type ShareClass = ShareClassServiceGetResponse;

export type ShareClassFormData = Omit<
  ShareClassServiceUpdateData,
  "companyId" | "shareClassId"
>;
