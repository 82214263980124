import { FC, useCallback, useContext, useEffect, useState } from "react";
import { AccordionContext } from "react-bootstrap";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { defaultTo, isEmpty, isNil } from "ramda";

import { Button, P, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CommonFileIcon, PlusIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../../transactions-service";
import { NominalValueGetResponseDTO, Transaction, TransactionStatus } from "../../../../types";
import { TransactionContextMenuHandlers } from "../../../transactions-list/transaction-item/body";
import classes from "./styles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.transactions.changeNominalValueForm");

type PropsTypes = {
  transaction: Transaction;
  isNominalDataUpdates?: boolean;
  setIsNominalValueDataUpdated?: (val: boolean) => void;
  contextMenuHandlers?: TransactionContextMenuHandlers;
};

const NominalValueItemBody: FC<PropsTypes> = ({
  contextMenuHandlers,
  transaction,
  isNominalDataUpdates,
  setIsNominalValueDataUpdated,
}) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const fNumber = useFormatNumbers(transaction.currencySymbol);
  const { activeEventKey } = useContext(AccordionContext);

  const { getSingleNominalValueDetails } = useTransactionsService(companyId);

  const [data, setData] = useState<NominalValueGetResponseDTO | null>(null);

  const isPending = transaction.statusId === TransactionStatus.Pending;

  const getDetailsDataHandler = useCallback(async () => {
    try {
      const request = await getSingleNominalValueDetails(transaction.transactionId);

      if (request) {
        setData(request);
      }
    } catch (e) {
      console.error({ e });
    } finally {
      if (isNominalDataUpdates) {
        setIsNominalValueDataUpdated?.(false);
      }
    }
  }, [getSingleNominalValueDetails, isNominalDataUpdates, setIsNominalValueDataUpdated, transaction.transactionId]);

  useEffect(() => {
    if (isNominalDataUpdates) {
      getDetailsDataHandler();
    }
  }, [getDetailsDataHandler, isNominalDataUpdates]);

  useEffect(() => {
    if (!isNil(activeEventKey)) {
      getDetailsDataHandler();
    }
  }, [activeEventKey, getDetailsDataHandler, getSingleNominalValueDetails, transaction.transactionId]);

  return (
    <div className={cn("p-3", classes["container"])}>
      <div className="d-flex align-items-center justify-content-between">
        <div className={classes["info-section"]}>
          <Ui.m bold className="mb-1">
            {t("updateBefore")}
          </Ui.m>

          <div className={classes["content"]}>
            <div>
              <Ui.xxl bold>{fNumber(transaction.sharePriceBefore, "sharePrice")}</Ui.xxl>
              <Ui.s>{t("nominalShareValue")}</Ui.s>
            </div>
          </div>
        </div>

        <ArrowRightIcon className={classes["icon"]} />

        <div className={classes["info-section"]}>
          <Ui.m bold className="mb-1">
            {t("updateAfter")}
          </Ui.m>

          <div className={classes["content"]}>
            <div>
              <Ui.xxl bold style={{ color: isPending ? scssVariables.information700 : scssVariables.positive700 }}>
                {fNumber(transaction.sharePrice, "sharePrice")}
              </Ui.xxl>
              <Ui.s>{t("nominalShareValue")}</Ui.s>
            </div>
          </div>
        </div>
      </div>

      {transaction?.description ? (
        <div className="mt-4">
          <P.s
            dangerouslySetInnerHTML={{
              __html: transaction?.description?.replace(/\r\n/g, "<br />"),
            }}
          />
        </div>
      ) : null}

      <div className={cn("mt-3 px-3 py-2", classes["documents"])}>
        {!isEmpty(defaultTo([], transaction?.files)) ? (
          <div className="d-flex flex-wrap">
            {defaultTo([], transaction?.files).map((el) => {
              return (
                <div
                  key={el.downloadId}
                  className={cn("d-flex align-items-center", classes["single-document"])}
                  onClick={() => {
                    openRemoteDocument(el?.downloadId);
                  }}
                >
                  <CommonFileIcon fontSize={24} className="me-2" />
                  <Ui.s>{el.fileName}</Ui.s>
                </div>
              );
            })}
          </div>
        ) : (
          <Button
            size="s"
            variant="tertiary"
            onClick={() => {
              contextMenuHandlers?.handleOpenManageDocumentsTransaction?.(transaction);
            }}
            iconLeft={<PlusIcon />}
          >
            Add documents
          </Button>
        )}
      </div>
    </div>
  );
};

export default NominalValueItemBody;
