import { FC, KeyboardEventHandler, useContext, useEffect, useMemo, useState } from "react";
import { AccordionContext, Col, useAccordionButton } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { useFormik } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import Check from "common/components/atoms/Checks/Check";
import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import DiscardModal from "common/components/atoms/DiscardModal/DiscardModal";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import MegaDropdown from "common/components/atoms/MegaDropdown/MegaDropdown";
import Spinner from "common/components/atoms/Spinner/Spinner";
import Tag from "common/components/atoms/Tag/Tag";
import TextField from "common/components/atoms/TextField/TextField";
import ViewPitchButton from "common/components/atoms/ViewPitchButton/ViewPitchButton";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { FilledArrowDownIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import Sidebar from "common/layout/MainLayout/Sidebar/Sidebar";
import { initBeforeUnLoad, scrollToTop } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import classes from "./funding.module.scss";

const [translation, tCommon] = [
  createTranslation(TranslationNS.pages, "companyProfile.funding"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const required = "This field is required";

const VALIDATION_SCHEMA = Yup.object().shape({
  companyPhaseId: Yup.number().moreThan(0, required),
  fundraisingStatusId: Yup.number().moreThan(0, required),
  fundingStageId: Yup.number().moreThan(0, required),
});

const CompanyProfileFundingPage: FC = () => {
  useDocumentTitleUpdate(translation("title"));

  const { companyId } = useParams<{ companyId: string }>();
  const currentDate = new Date();

  const { hasFullAccess } = useFeatures(FEATURES.attract);

  const companyPhaseDropDown = useStoreState((state) => state.common.dropdowns)?.companyPhases;
  const fundingInstrumentDropDown = useStoreState((state) => state.common.dropdowns)?.fundingInstruments;
  const fundingStageDropDown = useStoreState((state) => state.common.dropdowns)?.fundingStages;
  const fundingStatusDropDown = useStoreState((state) => state.common.dropdowns)?.fundraisingStatus;
  const { funding } = useStoreState((state) => state.company);
  const { getFundingThunk } = useStoreActions((state) => state.company);
  const user = useStoreState((state) => state.account.user);
  const activeCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);

  const [isFundingLoading, setIsFundingLoading] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [isSubmitBtnPressed, setIsSubmitBtnPressed] = useState<boolean>(false);
  const [isDiscardModalActive, setIsDiscardModalActive] = useState<boolean>(false);

  const companyCurrency = useMemo(
    () => user?.companies.find((company) => company.id === activeCompanyId)?.currencyCode,
    [user?.companies, activeCompanyId]
  );

  const initialValues = useMemo(
    () => ({
      fundingInstrument: R.defaultTo(
        undefined,
        fundingInstrumentDropDown?.find((el) => el.id === funding?.fundingInstrumentId)?.name
      ),
      companyPhaseId: R.defaultTo(0, funding?.companyPhaseId),
      fundraisingStatusId: R.defaultTo(0, funding?.fundraisingStatusId),
      fundingStageId: R.defaultTo(0, funding?.fundingStageId),
      fundingTargetAmount: R.defaultTo(0, funding?.fundingTargetAmount),
      closingDatePreferred: R.defaultTo(undefined, funding?.closingDatePreferred),
      fundingInstrumentId: R.defaultTo(-1, funding?.fundingInstrumentId),
      estimatedPreMoneyValuation: R.defaultTo(0, funding?.estimatedPreMoneyValuation),
      discountRate: R.defaultTo("", funding?.discountRate),
      valuationCap: R.defaultTo("", funding?.valuationCap),
    }),
    [funding, fundingInstrumentDropDown]
  );

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const isInputsDisabled = values.fundraisingStatusId === 1;

  window.onload = function () {
    /* istanbul ignore next */
    initBeforeUnLoad(false);
  };

  function handleClose() {
    setIsDiscardModalActive(false);
  }

  function handleDiscard() {
    /* istanbul ignore next */
    resetForm();
  }

  async function submitHandler() {
    setIsDataLoading(true);
    try {
      const fundingData = {
        id: funding?.id,
        companyPhaseId: values.companyPhaseId,
        fundraisingStatusId: values.fundraisingStatusId,
        fundingStageId: values.fundingStageId,
        fundingTargetAmount: values.fundingTargetAmount ? values.fundingTargetAmount : null,
        closingDatePreferred: values.closingDatePreferred ? values.closingDatePreferred : null,
        fundingInstrumentId: values.fundingInstrumentId >= 0 ? values.fundingInstrumentId : null,
        estimatedPreMoneyValuation: values.estimatedPreMoneyValuation ? values.estimatedPreMoneyValuation : null,
        discountRate: values.discountRate ? values.discountRate : null,
        valuationCap: values.valuationCap ? values.valuationCap : null,
      };

      const request = await axios.post("/api/attract/funding", fundingData);

      /* istanbul ignore next */
      if (request.status === 200) {
        notify(translation("notify.changesSaved"), true, "success", 3000);
        await getFundingThunk(companyId ? +companyId : 0);
      }
    } catch (e) {
      /* istanbul ignore next */
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      setTimeout(() => {
        setIsDataLoading(false);
      }, 500);
    }
  }

  useEffect(() => {
    if (companyId) {
      const fetchFunding = async () => {
        await getFundingThunk(+companyId);
      };

      try {
        setIsFundingLoading(true);
        fetchFunding();
      } finally {
        setIsFundingLoading(false);
      }
    }
  }, [companyId, getFundingThunk]);

  function CompanyPhaseToggle({ eventKey }: { eventKey: string }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    const { activeEventKey } = useContext(AccordionContext);

    const selectedValue = companyPhaseDropDown?.find((el) => el.id === values.companyPhaseId);

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
      if (event.key === "Enter") {
        decoratedOnClick(event);
      }
    };

    return (
      <div
        tabIndex={0}
        className="d-flex py-4 px-3 cursor-pointer"
        onKeyDown={handleKeyDown}
        onClick={decoratedOnClick}
      >
        <Col xs={11}>
          <div className="d-flex flex-column">
            <p className="ui-m fw-bold m-0 mb-1">{selectedValue?.name}</p>
            <p className="ui-s m-0">{selectedValue?.description}</p>
          </div>
        </Col>
        <Col
          xs={1}
          data-testid="company-phase-test-id"
          className={cn("d-flex align-items-center justify-content-center", classes["form-floating"])}
        >
          <div className={classes["mega-dropdown-horizontal-line"]} />
          <div
            className={cn(classes["mega-dropdown-arrow"], { [classes["mega-dropdown-arrow-active"]]: activeEventKey })}
          >
            <FilledArrowDownIcon direction="right" color="#444444" />
          </div>
        </Col>
      </div>
    );
  }

  function FundingStageToggle({ eventKey }: { eventKey: string }) {
    const decoratedOnClick = useAccordionButton(eventKey);
    const { activeEventKey } = useContext(AccordionContext);

    const selectedValue = fundingStageDropDown?.find((el) => el.id === values.fundingStageId);

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
      if (event.key === "Enter") {
        decoratedOnClick(event);
      }
    };

    return (
      <div
        tabIndex={0}
        className="d-flex py-4 px-3 cursor-pointer"
        onKeyDown={handleKeyDown}
        onClick={decoratedOnClick}
      >
        <Col xs={11}>
          <div className="d-flex flex-column">
            <p className="ui-m fw-bold m-0 mb-1">{selectedValue?.name}</p>
            <p className="ui-s m-0">{selectedValue?.description}</p>
          </div>
        </Col>
        <Col
          xs={1}
          data-testid="funding-stage-test-id"
          className={cn("d-flex align-items-center justify-content-center", classes["form-floating"])}
        >
          <div className={classes["mega-dropdown-horizontal-line"]} />
          <div
            className={cn(classes["mega-dropdown-arrow"], { [classes["mega-dropdown-arrow-active"]]: activeEventKey })}
          >
            <FilledArrowDownIcon direction="right" color="#444444" />
          </div>
        </Col>
      </div>
    );
  }

  useEffect(() => {
    if (isSubmitBtnPressed) {
      if (!R.isEmpty(errors)) {
        scrollToTop();
        notify(
          "There is some error in the form, please check that everything is filled out correctly.",
          true,
          "error",
          10000
        );
      }

      setTimeout(() => {
        setIsSubmitBtnPressed(false);
      }, 200);
    }
  }, [isSubmitBtnPressed, errors]);

  return (
    <>
      <PageContent data-testid="company-profile-funding-test-id">
        <PageContent.Header>
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{translation("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>
          <ViewPitchButton id={Number(companyId)} />
        </PageContent.Header>
        <LoaderContainer loading={isFundingLoading}>
          <div className="d-flex flex-column bg-white paper-container">
            <p className="m-0 mb-1 ui-m fw-500">{translation("companyPhase.label")} *</p>
            <MegaDropdown
              error={Boolean(errors.companyPhaseId && touched.companyPhaseId)}
              HeadComponent={<CompanyPhaseToggle eventKey="0" />}
            >
              <>
                {!R.isNil(companyPhaseDropDown) ? (
                  companyPhaseDropDown?.map((el, index) => {
                    return (
                      <Check
                        tabIndex={0}
                        key={`Checkbox value for company phase is - ${el.name} - ${el.id}`}
                        data-testid="company-phase-item-test-id"
                        className={cn(classes["funding-checkbox"], { "mt-2": index })}
                        label={el.name}
                        description={el.description}
                        checked={values.companyPhaseId === el.id}
                        onChange={() => {
                          setFieldValue("companyPhaseId", el.id);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            setFieldValue("companyPhaseId", el.id);
                          }
                        }}
                      />
                    );
                  })
                ) : (
                  <p>{translation("companyPhase.noData")}</p>
                )}
              </>
            </MegaDropdown>

            {errors.companyPhaseId && touched.companyPhaseId && (
              <>
                <p
                  className="m-0 mt-1 ui-xs"
                  style={{
                    color: "#d83f3f",
                  }}
                >
                  {errors.companyPhaseId}
                </p>
              </>
            )}

            <div className="d-flex flex-column mt-5">
              <p
                className="m-0 mb-3 ui-m fw-500"
                style={{
                  color: errors.fundraisingStatusId && touched.fundraisingStatusId ? "#d83f3f" : undefined,
                }}
              >
                {translation("fundraisingStatus.label")} *
              </p>

              <ChecksGroup>
                {fundingStatusDropDown?.map((el, index, array) => {
                  return (
                    <ChecksGroup.Check
                      size={20}
                      label={el.name}
                      data-testid="funding-single-item-test-id"
                      checked={values.fundraisingStatusId === el.id}
                      key={`Checkbox funding profile key is - ${el.id}`}
                      className={cn(classes["funding-checkbox"], { "mb-3": index !== array.length })}
                      onChange={() => {
                        setFieldValue("fundraisingStatusId", el.id);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          setFieldValue("fundraisingStatusId", el.id);
                        }
                      }}
                    />
                  );
                })}
              </ChecksGroup>
            </div>

            {errors.fundraisingStatusId && touched.fundraisingStatusId && (
              <>
                <p
                  className="m-0 mt-1 ui-xs"
                  style={{
                    color: "#d83f3f",
                  }}
                >
                  {errors.fundraisingStatusId}
                </p>
              </>
            )}

            <p className="m-0 mt-7 mb-1 ui-m" style={{ fontWeight: 500 }}>
              {translation.el("fundrisingStage.label")} *
            </p>

            <MegaDropdown
              error={Boolean(errors.fundingStageId && touched.fundingStageId)}
              HeadComponent={<FundingStageToggle eventKey="0" />}
            >
              <>
                {!R.isNil(fundingStageDropDown) ? (
                  fundingStageDropDown?.map((el, index) => {
                    return (
                      <Check
                        tabIndex={0}
                        label={el.name}
                        description={el.description}
                        data-testid="funding-stage-item-test-id"
                        checked={values.fundingStageId === el.id}
                        className={cn(classes["funding-checkbox"], { "mt-2": index })}
                        key={`Checkbox value for company phase is - ${el.name} - ${el.id}`}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            setFieldValue("companyPhaseId", el.id);
                          }
                        }}
                        onChange={() => {
                          setFieldValue("fundingStageId", el.id);
                        }}
                      />
                    );
                  })
                ) : (
                  <p>{translation("fundrisingStage.noData")}</p>
                )}
              </>
            </MegaDropdown>

            {errors.fundingStageId && touched.fundingStageId && (
              <>
                <p
                  className="m-0 mt-1 ui-xs"
                  style={{
                    color: "#d83f3f",
                  }}
                >
                  {errors.fundingStageId}
                </p>
              </>
            )}

            <TextField
              isOptional
              type="number"
              className="mt-5 mb-1"
              style={{ width: "250px" }}
              data-testid="funding-target-amount-test-id"
              isDisabled={isInputsDisabled}
              value={String(values.fundingTargetAmount)}
              label={translation("fundingTargetAmount.label")}
              onChange={handleChange("fundingTargetAmount")}
            />

            <p className="ui-xs mb-5" style={{ opacity: 0.8 }}>
              {translation.el("fundingTargetAmount.description", {
                values: {
                  currencyName: companyCurrency ? companyCurrency : "NOK",
                },
              })}
            </p>

            <DatePicker
              isOptional
              className="mb-1"
              isDisabled={isInputsDisabled}
              label={translation("closingDatePreferred.label")}
              date={values.closingDatePreferred}
              isDateOnlyString={true}
              onChange={(date) => {
                if (new Date(date).valueOf() <= currentDate.valueOf()) {
                  notify(translation("notify.dateNotInPast"), true, "error");
                  return;
                }

                setFieldValue("closingDatePreferred", date);
              }}
            />

            <p className="ui-xs mb-5" style={{ opacity: 0.8 }}>
              {translation("closingDatePreferred.description")}
            </p>

            <Dropdown
              isOptional
              className="mb-1"
              label={translation("fundingInstrument.label")}
              isDisabled={isInputsDisabled}
              options={!R.isNil(fundingInstrumentDropDown) ? fundingInstrumentDropDown.map((el) => el.name) : []}
              selectedValue={
                !R.isNil(fundingInstrumentDropDown)
                  ? fundingInstrumentDropDown?.find((el) => el.id === values.fundingInstrumentId)?.name
                  : ""
              }
              onChange={(instrument: string) => {
                const selectedInstrument = fundingInstrumentDropDown?.find((el) => el.name === instrument);

                setFieldValue("fundingInstrumentId", selectedInstrument?.id);
                setFieldValue("fundingInstrument", instrument);
              }}
            />

            <p className="ui-xs" style={{ opacity: 0.8 }}>
              {translation("fundingInstrument.description")}
            </p>
            {!values.fundingInstrument || values.fundingInstrument === "Not disclosed" ? null : (
              <div className="mb-5">
                {values.fundingInstrument === "Convertible Note" ? null : (
                  <>
                    <TextField
                      className="mt-5 mb-1"
                      type="number"
                      isOptional={true}
                      isDisabled={isInputsDisabled}
                      label={translation("estimatedPreMoneyValuation.label")}
                      value={String(values.estimatedPreMoneyValuation)}
                      onChange={handleChange("estimatedPreMoneyValuation")}
                    />
                    <p className="ui-xs mb-5" style={{ opacity: 0.8 }}>
                      {translation("estimatedPreMoneyValuation.description")}
                    </p>
                  </>
                )}

                <TextField
                  type="number"
                  isOptional={true}
                  isDisabled={isInputsDisabled}
                  value={String(values.discountRate)}
                  label={translation("discountRate.label")}
                  onChange={handleChange("discountRate")}
                />

                <p className="ui-xs mb-5" style={{ opacity: 0.8 }}>
                  {translation("discountRate.description")}
                </p>

                <TextField
                  className="mb-1"
                  type="number"
                  isOptional={true}
                  isDisabled={isInputsDisabled}
                  value={String(values.valuationCap)}
                  label={translation("valuationCap.label")}
                  onChange={handleChange("valuationCap")}
                />

                <p className="ui-xs" style={{ opacity: 0.8 }}>
                  {translation("valuationCap.description")}
                </p>
              </div>
            )}

            <p className="m-0 mb-3 ui-xs" style={{ opacity: 0.8 }}>
              {translation("requiredFields")}
            </p>

            <div className="d-flex flex-column" style={{ width: 120 }}>
              <Button
                isDisabled={isDataLoading || !hasFullAccess}
                isLoading={isDataLoading}
                data-testid="funding-submit-btn-test-id"
                tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                tooltipMessage={tCommon("tooltip")}
                onClick={() => {
                  setIsSubmitBtnPressed(true);
                  handleSubmit();
                }}
              >
                {translation("save")}
              </Button>
            </div>
            <DiscardModal
              isUser
              visible={isDiscardModalActive}
              handleClose={handleClose}
              handleDiscard={handleDiscard}
              handleSubmit={submitHandler}
            />
          </div>
        </LoaderContainer>

        {isDataLoading && <Spinner />}
      </PageContent>

      <Sidebar>
        <div style={{ width: 320 }}></div>
      </Sidebar>
    </>
  );
};

export default CompanyProfileFundingPage;
