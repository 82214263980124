import { FC } from "react";

import { ValuationSingle } from "store/types";

import classes from "../../valuation.module.scss";
import ValuationContext from "../../ValuationContext";
import ValuationTableRow from "./ValuationTableRow";

type ValuationTableBodyProps = {
  data: ValuationSingle[];
};

const ValuationTableBody: FC<ValuationTableBodyProps> = ({ data }) => {
  const { selectedRowId } = ValuationContext.useStoreState((state) => state);
  const { setRemoveValuation } = ValuationContext.useStoreActions((actions) => actions);

  return (
    <tbody className={classes["table-body"]}>
      {data.map((el) => (
        <ValuationTableRow
          key={`Key for table inner single row is - ${el.id}`}
          {...el}
          coloredRow={selectedRowId}
          onValuationRemove={setRemoveValuation}
        />
      ))}
    </tbody>
  );
};

export default ValuationTableBody;
