import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Formik, FormikConfig, useFormikContext } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import Tag from "common/components/atoms/Tag/Tag";
import ViewPitchButton from "common/components/atoms/ViewPitchButton/ViewPitchButton";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import Sidebar from "common/layout/MainLayout/Sidebar/Sidebar";
import { scrollToTop } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import {
  FACEBOOK_VALIDATION_PATTERN,
  INSTAGRAM_VALIDATION_PATTERN,
  URL_VALIDATION_PATTERN,
} from "common/utils/validators";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import "./general.module.scss";

import LoaderContainer from "../../../common/components/atoms/LoaderContainer/LoaderContainer";
import ContactPersonPart from "./formParts/ContactPersonPart";
import DemographicsPart from "./formParts/DemographicsPart";
import HeadquartersPart from "./formParts/HeadquartersPart";
import MainPart from "./formParts/MainPart";
import SocialMediaPart from "./formParts/SocialMediaPart";

type PropsTypes = {
  isTestsPurposes?: boolean;
};

const [translation, tCommon] = [
  createTranslation(TranslationNS.pages, "companyProfile.general"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const validationTranslation = createTranslation("validation");

export type FormGeneralValues = {
  name: string;
  organizationNumber: string;
  foundedAt?: string;
  numberOfEmployees: number | null;
  websiteUrl: string;
  headquartersCountryId: number;
  headquartersCity: string;
  currencyId: number | null;
  contactPersonTeamMemberId: string | null;
  calendlyLink: string;
  linkedInLink: string;
  mediumLink: string;
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  logoPreview?: string;
  logoFile?: File;
};

const CompanyProfileGeneralPage: FC<PropsTypes> = ({ isTestsPurposes }) => {
  useDocumentTitleUpdate(translation("title"));

  const { companyId } = useParams<{ companyId: string }>();

  const { hasFullAccess } = useFeatures(FEATURES.attract);

  const { generalInfo } = useStoreState((state) => state.company);

  const { getGeneralInfoThunk } = useStoreActions((state) => state.company);
  const [isLoading, setIsLoading] = useState(true);

  const uploadFile = useCallback(
    async (logoFile?: File) => {
      if (logoFile) {
        const formData = new FormData();
        formData.append("ImageFile", logoFile);

        try {
          await axios.post(`/api/attract/logo/${generalInfo?.id}`, formData);
        } catch (error) {
          console.log(error);
        }
      }
    },
    [generalInfo?.id]
  );

  const initialValues: FormGeneralValues = useMemo(
    () => ({
      name: R.defaultTo("", generalInfo?.name),
      organizationNumber: R.defaultTo("", generalInfo?.organizationNumber),
      foundedAt: generalInfo?.foundedAt,
      numberOfEmployees: R.defaultTo(null, generalInfo?.numberOfEmployees),
      websiteUrl: R.defaultTo("", generalInfo?.websiteUrl),
      headquartersCountryId: R.defaultTo(0, generalInfo?.headquartersCountryId),
      headquartersCity: R.defaultTo("", generalInfo?.headquartersCity),
      currencyId: R.defaultTo(null, generalInfo?.currencyId),
      contactPersonTeamMemberId: R.defaultTo(null, generalInfo?.contactPersonTeamMemberId),
      calendlyLink: R.defaultTo("", generalInfo?.calendlyUrl),
      linkedInLink: R.defaultTo("", generalInfo?.linkedInUrl),
      mediumLink: R.defaultTo("", generalInfo?.mediumUrl),
      facebookLink: R.defaultTo("", generalInfo?.metaUrl),
      twitterLink: R.defaultTo("", generalInfo?.twitterHandle),
      instagramLink: R.defaultTo("", generalInfo?.instagramUrl),
      logoPreview: generalInfo?.logoImageFilePath
        ? `${process.env.REACT_APP_BLOB_PUBLIC_URL}${generalInfo?.logoImageFilePath}`
        : "",
      logoFile: undefined,
    }),
    [generalInfo]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(validationTranslation("required")),
        organizationNumber: Yup.string(),
        websiteUrl: Yup.string().matches(URL_VALIDATION_PATTERN, validationTranslation("invalidURL")),
        calendlyLink: Yup.string().matches(URL_VALIDATION_PATTERN, validationTranslation("invalidURL")),
        linkedInLink: Yup.string().matches(URL_VALIDATION_PATTERN, validationTranslation("invalidURL")),
        mediumLink: Yup.string().matches(URL_VALIDATION_PATTERN, validationTranslation("invalidURL")),
        facebookLink: Yup.string().matches(FACEBOOK_VALIDATION_PATTERN, validationTranslation("invalidURL")),
        twitterLink: Yup.string().matches(URL_VALIDATION_PATTERN, validationTranslation("invalidURL")),
        instagramLink: Yup.string().matches(INSTAGRAM_VALIDATION_PATTERN, validationTranslation("invalidURL")),
      }),
    []
  );

  function addHttpIfMissing(url: string | null) {
    return url ? ((url as string).includes("http") ? url : `http://${url}`) : null;
  }

  const updateCompanyProfile = useCallback(
    async (values: FormGeneralValues) => {
      try {
        const generalInfoData = {
          id: generalInfo?.id,
          name: values.name,
          organizationNumber: values.organizationNumber,
          foundedAt: values.foundedAt,
          numberOfEmployees: values.numberOfEmployees,
          websiteUrl: addHttpIfMissing(values.websiteUrl),
          headquartersCountryId: values.headquartersCountryId,
          currencyId: values.currencyId,
          headquartersCity: values.headquartersCity ? values.headquartersCity : null,
          contactPersonTeamMemberId: values?.contactPersonTeamMemberId,
          calendlyUrl: addHttpIfMissing(values?.calendlyLink),
          linkedInUrl: addHttpIfMissing(values?.linkedInLink),
          mediumUrl: addHttpIfMissing(values?.mediumLink),
          metaUrl: addHttpIfMissing(values?.facebookLink),
          twitterHandle: addHttpIfMissing(values?.twitterLink),
          instagramUrl: addHttpIfMissing(values?.instagramLink),
        };

        const request = await axios.post("/api/attract/general-information", generalInfoData);

        if (request.status === 200) {
          return true;
        }
      } catch (e) {
        return false;
      }
    },
    [generalInfo?.id]
  );

  const submitHandler = useCallback<FormikConfig<FormGeneralValues>["onSubmit"]>(
    async (values) => {
      await uploadFile(values.logoFile);
      const sent = await updateCompanyProfile(values);
      if (sent) {
        notify(translation("notify.changesSaved"), true, "success");
        await getGeneralInfoThunk(companyId ? +companyId : 0);
      }
    },
    [companyId, getGeneralInfoThunk, updateCompanyProfile, uploadFile]
  );

  const getData = useCallback(
    async (companyId: number) => {
      setIsLoading(true);
      await getGeneralInfoThunk(companyId);
      setIsLoading(false);
    },
    [getGeneralInfoThunk]
  );

  useEffect(() => {
    getData(companyId ? +companyId : 0).then();
  }, [companyId, getData]);

  return (
    <>
      <PageContent data-testid="company-profile-general-test-id">
        <PageContent.Header>
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{translation("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>

          <ViewPitchButton id={Number(companyId)} />
        </PageContent.Header>

        <LoaderContainer loading={isLoading}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={submitHandler}
            validationSchema={validationSchema}
          >
            <FormContainer isTestsPurposes={isTestsPurposes} hasFullAccess={hasFullAccess} />
          </Formik>
        </LoaderContainer>
      </PageContent>

      <Sidebar>
        <div style={{ width: 320 }}></div>
      </Sidebar>
    </>
  );
};

type FormContainerProps = {
  isTestsPurposes?: boolean;
  hasFullAccess: boolean;
};
const FormContainer: FC<FormContainerProps> = ({ isTestsPurposes, hasFullAccess }) => {
  const { isSubmitting, handleSubmit, submitCount, errors } = useFormikContext();
  const handleClickSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  useEffect(() => {
    if (submitCount > 0 && !R.isEmpty(errors)) {
      notify("Name field is required", true, "error", 5000);
      scrollToTop();
    }
  }, [submitCount, errors]);

  return (
    <div className="d-flex flex-column general paper-container bg-white">
      <div style={{ maxWidth: 700 }}>
        <MainPart />
        <SocialMediaPart />
        <HeadquartersPart isTestsPurposes={isTestsPurposes} />
        <DemographicsPart />
        <ContactPersonPart />
        <div className="d-flex flex-column" style={{ width: 120 }}>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting || !hasFullAccess}
            data-testid="company-profile-submit-button"
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            onClick={handleClickSubmit}
          >
            {translation("save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileGeneralPage;
