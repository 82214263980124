import * as Yup from "yup";
import { StringSchema } from "yup";
import { RequiredNumberSchema } from "yup/lib/number";
import { RequiredStringSchema } from "yup/lib/string";

import { TFunction } from "translation/helpers";

import { TransactionDetailsFields } from "./form-fields";

type DefaultFieldsType = Partial<
  Record<
    keyof typeof TransactionDetailsFields,
    RequiredStringSchema<string | undefined> | StringSchema | RequiredNumberSchema<number | undefined>
  >
>;

type ValidationArguments = {
  tFunction: TFunction;
  excludeFields?: {
    [TransactionDetailsFields.transactedAt]?: boolean;
    [TransactionDetailsFields.transactionTypeId]?: boolean;
    [TransactionDetailsFields.description]?: boolean;
  };
};

export const transactionDetailsValidationSchema = ({ tFunction, excludeFields }: ValidationArguments) => {
  const defaultValidationFields: DefaultFieldsType = {
    [TransactionDetailsFields.transactedAt]: Yup.string().required(tFunction("required")),
    [TransactionDetailsFields.description]: Yup.string().notRequired(),
  };

  if (excludeFields?.transactedAt) {
    delete defaultValidationFields?.transactedAt;
  }

  if (excludeFields?.description) {
    delete defaultValidationFields?.description;
  }

  if (excludeFields?.transactionTypeId) {
    delete defaultValidationFields?.transactionTypeId;
  }

  return defaultValidationFields;
};
