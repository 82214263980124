import React, { memo } from "react";

import { Ui } from "common/components/atoms/Typography";
import { scssVariables } from "common/utils/constants";

import classes from "../../valuation.module.scss";

type ValuationTableHeadProps = {
  rows: string[];
};

const ValuationTableHead: React.FC<ValuationTableHeadProps> = ({ rows }) => {
  return (
    <thead className={classes["table-head"]}>
      <tr>
        {rows.map((el, i, array) => (
          <th key={`Table head key for company vluation table head is  - ${el}`}>
            <div
              className="d-flex align-items-center"
              style={{ justifyContent: i === array.length - 1 ? "end" : undefined }}
            >
              <Ui.s
                className="fw-400"
                style={{
                  margin: "0 5px 0 0",
                  color: scssVariables.foregroundMedium,
                }}
              >
                {el}
              </Ui.s>
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default memo(ValuationTableHead);
