import { FC } from "react";
import { flexRender, HeaderGroup } from "@tanstack/react-table";
import cn from "classnames";

import { FilledArrowDownIcon } from "common/icons/svg";

import classes from "../../stakeholder-details.module.scss";

type HeadRowProps = {
  headerGroup: HeaderGroup<any>;
};
const HeadRow: FC<HeadRowProps> = ({ headerGroup }) => {
  return (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <th key={header.id}>
          <div
            style={{ gap: 4 }}
            className={cn("d-flex align-items-center", header.column.columnDef.meta?.headClass, {
              "cursor-pointer select-none": header.column.getCanSort(),
            })}
            onClick={header.column.getToggleSortingHandler()}
          >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {header.column.getCanSort() && (
              <span className={classes.sorting}>
                <FilledArrowDownIcon
                  style={{
                    color: header.column.getIsSorted() ? "black" : "#806779",
                    transition: "all 0.2s",
                    transform: (header.column.getIsSorted() as string) === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </span>
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

export default HeadRow;
