import { FC } from "react";
import cn from "classnames";

import classes from "./Tabs.module.scss";

export type TabsProps = {
  tabs: string[];
  activeTab: number;
  selectTab: (event: any) => void;
};

const Tabs: FC<TabsProps> = ({ tabs, activeTab, selectTab }) => {
  return (
    <div className="d-flex flex-row" data-testid="tabs-container-test-id">
      {tabs &&
        tabs.map((tab: string, index: number) => {
          if (index === activeTab) {
            return (
              <div className={cn(classes["tab-item-active"], "p-2")} key={tab}>
                <p className="ui-m">{tab}</p>
              </div>
            );
          } else {
            return (
              <div
                className={cn(classes["tab-item"], "p-2")}
                key={tab}
                data-testid="tabs-single-item-test-id"
                onClick={() => selectTab(index)}
              >
                <p className="ui-m">{tab}</p>
              </div>
            );
          }
        })}
    </div>
  );
};

export default Tabs;
