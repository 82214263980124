import { FC,useMemo } from "react";
import { Form, Formik, FormikConfig } from "formik";

import { ShareClassFormData } from "common/shareClass/types";
import { getShareClassValidationObject } from "common/shareClass/validationSchema";

import HasDividendsField from "./HasDividendsField";
import HasVotingRightsField from "./HasVotingRightsField";
import IsParticipatingField from "./IsParticipatingField";
import NameField from "./NameField";
import ParticipatingCapField from "./ParticipatingCapField";
import PurposeField from "./PurposeField";
import SeniorityLevelField from "./SeniorityLevelField";
import SeniorityLevelMultipleField from "./SeniorityLevelMultipleField";
import ShareClassAntiDilutionField from "./ShareClassAntiDilutionField";
import ShareClassTypeField from "./ShareClassTypeField";
import VotingRightsMultipleField from "./VotingRightsMultipleField";

export type ShareClassFormProps = FormikConfig<ShareClassFormData> & {
  validateFields?: (keyof ShareClassFormData)[];
};

type ShareClassFormComponent = FC<ShareClassFormProps> & {
  Form: typeof Form;
  NameField: typeof NameField;
  SeniorityLevelMultipleField: typeof SeniorityLevelMultipleField;
  SeniorityLevelField: typeof SeniorityLevelField;
  IsParticipatingField: typeof IsParticipatingField;
  HasDividendsField: typeof HasDividendsField;
  ShareClassTypeField: typeof ShareClassTypeField;
  ShareClassAntiDilutionField: typeof ShareClassAntiDilutionField;
  HasVotingRightsField: typeof HasVotingRightsField;
  PurposeField: typeof PurposeField;
  VotingRightsMultipleField: typeof VotingRightsMultipleField;
  ParticipatingCapField: typeof ParticipatingCapField;
};

const ShareClassForm: ShareClassFormComponent = ({ validateFields, children, ...props }) => {
  const validationSchema = useMemo(
    () => (validateFields ? getShareClassValidationObject().pick(validateFields) : getShareClassValidationObject()),
    [validateFields]
  );

  return (
    <Formik<ShareClassFormData> {...props} validationSchema={validationSchema}>
      {children}
    </Formik>
  );
};

ShareClassForm.Form = Form;
ShareClassForm.NameField = NameField;
ShareClassForm.SeniorityLevelMultipleField = SeniorityLevelMultipleField;
ShareClassForm.SeniorityLevelField = SeniorityLevelField;
ShareClassForm.IsParticipatingField = IsParticipatingField;
ShareClassForm.HasDividendsField = HasDividendsField;
ShareClassForm.ShareClassTypeField = ShareClassTypeField;
ShareClassForm.ShareClassAntiDilutionField = ShareClassAntiDilutionField;
ShareClassForm.HasVotingRightsField = HasVotingRightsField;
ShareClassForm.PurposeField = PurposeField;
ShareClassForm.VotingRightsMultipleField = VotingRightsMultipleField;
ShareClassForm.ParticipatingCapField = ParticipatingCapField;

export default ShareClassForm;
