import React, { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import { Button, ContextMenu, ContextMenuProps, NewAvatar, Table, Ui } from "common/components/atoms";
import { DeleteIcon, EditIcon, MenuTabBarVerticalIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { EditStakeholderDTO, PostStakeholderDTO, StakeholderContact } from "../types";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.form.contacts.table");

const THead: FC = () => {
  return (
    <thead>
      <tr>
        <th className="fw-400">
          <Ui.s color="foregroundLow">{t("name")}</Ui.s>
        </th>
        <th className="fw-400">
          <Ui.s color="foregroundLow">{t("role")}</Ui.s>
        </th>
        <th className="fw-400 text-end">
          <Ui.s color="foregroundLow">{t("action")}</Ui.s>
        </th>
      </tr>
    </thead>
  );
};

const TRow: FC<StakeholderContact & { index: number; handleEditClick: (val: number) => void }> = ({
  avatarPathURL,
  firstName,
  lastName,
  email,
  stakeholderContactRoleId,
  handleEditClick,
  index,
}) => {
  const dropdownValues = useStoreState((state) => state.common?.dropdowns)?.stakeholderContactRoles || [];
  const selectedValue = dropdownValues.find((el) => el.id === stakeholderContactRoleId)?.name;

  const { values, setFieldValue } = useFormikContext<PostStakeholderDTO | EditStakeholderDTO>();

  const items = useMemo(() => {
    return [
      {
        key: "edit",
        label: t("edit"),
        icon: <EditIcon />,
        onClick: () => {
          handleEditClick(index);
        },
        forceHideDropdown: true,
      },
      {
        key: "delete",
        label: "Delete",
        type: "delete",
        onClick: () => {
          const updatedValues = values.contacts.filter((_, i) => i !== index);

          setFieldValue("contacts", updatedValues);
        },
        icon: <DeleteIcon />,
        forceHideDropdown: true,
      },
    ] as ContextMenuProps["items"][0][];
  }, [handleEditClick, index, setFieldValue, values.contacts]);

  return (
    <tr>
      <td>
        <div className="d-flex align-items-center">
          <NewAvatar size="m" firstName={firstName} lastName={lastName} imageUrl={avatarPathURL} />

          <div className="ms-1">
            <Ui.s bold>
              {firstName} {lastName}
            </Ui.s>

            <Ui.s color="foregroundLow">{email}</Ui.s>
          </div>
        </div>
      </td>

      <td style={{ verticalAlign: "middle" }}>
        <Ui.s>{selectedValue}</Ui.s>
      </td>

      <td style={{ verticalAlign: "middle" }}>
        <div className="d-flex justify-content-end">
          <ContextMenu items={items}>
            <Button
              size="s"
              isOnlyIcon
              variant="tertiary"
              data-testid="plan-dropdown-btn"
              style={{ height: 30, width: 30 }}
            >
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </div>
      </td>
    </tr>
  );
};

const TBody: FC<{ data: StakeholderContact[]; handleEditClick: (val: number) => void }> = ({
  data,
  handleEditClick,
}) => {
  return (
    <tbody>
      {data.map((props, index) => (
        <TRow key={index} index={index} {...props} handleEditClick={handleEditClick} />
      ))}
    </tbody>
  );
};

const StakeholderFormContactsTable: FC<{ handleEditClick: (val: number) => void }> = ({ handleEditClick }) => {
  const { values } = useFormikContext<PostStakeholderDTO | EditStakeholderDTO>();

  return (
    <Table
      className="mt-3"
      headComponent={<THead />}
      bodyComponent={<TBody data={values.contacts} handleEditClick={handleEditClick} />}
    />
  );
};

export default StakeholderFormContactsTable;
