import { ChangeEvent, FC, ReactNode, useCallback, useEffect, useState } from "react";
import axios from "axios";

import { Dropdown } from "common/components/atoms";
import { CompanyBuildingIcon, UserIcon } from "common/icons/svg";
import { highlightSubstring } from "common/utils/HighliteText";
import { createTranslation, TranslationNS } from "translation";

import { DropdownPropsNew } from "../../atoms/Dropdown/Dropdown";
import { SearchStakeholderResult, StakeholderFormValues } from "../StakeholderSection/Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder");

type ShareholderSearchProps = Omit<DropdownPropsNew<true>, "options" | "onChange"> & {
  companyId: number | string;
  handleSelect?: (e: ChangeEvent<HTMLInputElement>, data?: StakeholderFormValues) => void;
};

type ItemElementProps = {
  data: StakeholderFormValues;
  value: string;
};

const ItemElement: FC<ItemElementProps> = ({ data, value }) => {
  const email = data.email ? `(${data.email})` : "";
  return (
    <div className="d-flex justify-content-between" style={{ color: "#2B011F" }}>
      <span>
        {highlightSubstring(
          data.isCompanyOwned
            ? `${data.companyName} - ${data.firstName} ${data.lastName} `
            : `${data.firstName} ${data.lastName} `,
          value
        )}
        {email}
      </span>
      <span>{data.isCompanyOwned ? <CompanyBuildingIcon fontSize={20} /> : <UserIcon fontSize={20} />}</span>
    </div>
  );
};

const StakeholderSearch: FC<ShareholderSearchProps> = ({
  label = t("label"),
  placeholder = t("placeholder"),
  name = "someName",
  className,
  companyId,
  selectedValue,
  ...props
}) => {
  ///new things
  const [listCompanies, setListCompanies] = useState<{ id: string | number; name: string; element: ReactNode }[]>([]);
  const [fullListCompanies, setFullListCompanies] = useState<StakeholderFormValues[]>([]);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const itemData = fullListCompanies.find((el) => el.stakeholderId === +e.target.value);

      const item: Partial<StakeholderFormValues> | undefined = e.target.value ? itemData : undefined;

      return props.handleSelect?.(e, item as StakeholderFormValues);
    },
    [fullListCompanies, props]
  );

  useEffect(() => {
    if (companyId) {
      setIsSearchLoading(true);
      axios
        .get<SearchStakeholderResult>(`/api/equity-management/shareholder/all/${companyId}`)
        .then((res) => {
          setListCompanies(
            res.data.shareholders.map((data) => ({
              id: data.stakeholderId || "",
              name: data.isCompanyOwned
                ? `${data.companyName} (${data.firstName} ${data.lastName}) `
                : `${data.firstName} ${data.lastName}`,
              element: <ItemElement data={data} value={""} />,
            }))
          );
          setFullListCompanies(res.data.shareholders);
        })
        .finally(() => {
          setIsSearchLoading(false);
        });
    }
  }, [companyId]);

  const handleHighlight = useCallback(
    (search: string) => {
      setListCompanies(
        fullListCompanies.map((data) => ({
          id: data.stakeholderId || "",
          name: data.isCompanyOwned
            ? `${data.companyName} (${data.firstName} ${data.lastName}) `
            : `${data.firstName} ${data.lastName}`,
          element: <ItemElement data={data} value={search} />,
        }))
      );
    },
    [fullListCompanies]
  );

  const totalStakeholders = t("totalStakeholder", { count: fullListCompanies.length });

  return (
    <Dropdown
      className={className}
      label={label || "Find existing shareholder"}
      placeholder={placeholder || "Search by name or company name"}
      isSearchable
      optionsIsObject
      name={name}
      selectedValue={selectedValue}
      options={listCompanies}
      onChange={handleChange}
      isLoading={isSearchLoading}
      searchChangeCallback={handleHighlight}
      withoutToggleChevron
      showSearchIcon
      isClearable
      isSearchComponent
      isTouched={props.isTouched}
      error={props.error}
      meta={totalStakeholders}
      {...props}
    />
  );
};

export default StakeholderSearch;
