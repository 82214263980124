import { FC, useMemo, useState } from "react";
import cn from "classnames";

import CTable, { TableProps as CTableProps } from "common/components/atoms/Table/Table";
import { sortingParams } from "common/types/Collapsible.types";
import { createTranslation, TranslationNS } from "translation";

import { CapTable } from "../../CapTable.types";
import CapTableStore from "../../store";
import Body from "./Body";
import Foot from "./Foot";
import Head from "./Head";
import classes from "./Table.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.table");

export type TableProps = Omit<CTableProps, "headComponent" | "bodyComponent"> & Record<string, unknown>;
export type HeaderRow = { key: keyof CapTable; value: string; className?: string; disableSorting?: boolean };

export type ActualCapTableSortValue = {
  field: keyof CapTable;
  type: keyof typeof sortingParams;
};

const Table: FC<TableProps> = ({ className, ...props }) => {
  const [activeSortedValue, setActiveSortedValue] = useState<ActualCapTableSortValue>({
    field: "shareholderName",
    type: sortingParams.inc,
  });

  const { actualData } = CapTableStore.useStoreState((state) => state);

  const rows: HeaderRow[] = useMemo(
    () => [
      {
        key: "shareholderName",
        value: t("actual.shareholder"),
      },
      {
        key: "shareClasses",
        value: t("common.shareClass"),
      },

      {
        key: "relationship",
        value: t("actual.relationship"),
      },
      {
        key: "shares",
        value: t("common.shares"),
        className: "ms-auto",
      },
      {
        key: "ownershipPercentage",
        value: t("common.ownership"),
        className: "ms-auto",
      },
      {
        key: "totalValue",
        value: t("actual.totalValue"),
        className: "ms-auto",
      },
      {
        key: "pledgedShares",
        value: t("actual.notes"),
        className: "ms-auto",
        disableSorting: true,
      },
    ],
    []
  );

  return (
    <CTable
      className={cn(classes.table, className, {
        ["pe-none"]: !actualData.hasAccessToDetails,
        [classes.removeHoverEffects]: !actualData.hasAccessToDetails,
      })}
      headComponent={<Head rows={rows} sortedValue={activeSortedValue} setSortedValue={setActiveSortedValue} />}
      bodyComponent={<Body sortedValue={activeSortedValue} />}
      footComponent={<Foot />}
      {...props}
    />
  );
};

export default Table;
