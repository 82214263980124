import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import classes from "./LinkButton.module.scss";

type LinkButtonProps = {
  text: string;
  icon?: string;
  textItalic?: string;
  path: string;
  className?: string;
};

const LinkButton: React.FC<LinkButtonProps> = ({ text, textItalic, icon, path, className }) => {
  if (icon) {
    return (
      <Link to={path} data-testid="link-button-container-test-id">
        <Button variant="" className={classNames(classes["btn"], className)}>
          <div className="me-2">
            <i className={icon} style={{ fontSize: 32 }} data-testid="link-button-icon-test-id"></i>
          </div>
          <div className="align-center uitext-m" style={{ alignSelf: "center" }}>
            {text}
            <i className="ms-2" style={{ fontWeight: 100 }} data-testid="link-button-textitalic-test-id">
              {textItalic}
            </i>
          </div>
        </Button>
      </Link>
    );
  }
  return (
    <Link to={path} data-testid="link-button-container-test-id">
      <Button variant="" className={classNames(classes["btn"], className)}>
        <div className="align-center uitext-m" style={{ alignSelf: "center" }}>
          {text}
          <i className="ms-2" style={{ fontWeight: 100 }} data-testid="link-button-textitalic-test-id">
            {textItalic}
          </i>
        </div>
      </Button>
    </Link>
  );
};

export default LinkButton;
