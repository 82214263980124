import { Route, Routes } from "react-router-dom";

import CapTable from "./CapTable";
import CapTableStore from "./store";

const CapTableRouter = () => {
  return (
    <CapTableStore.Provider>
      <Routes>
        <Route path="/" element={<CapTable />} />
      </Routes>
    </CapTableStore.Provider>
  );
};

export default CapTableRouter;
