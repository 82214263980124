import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AccordionContext, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { defaultTo, isEmpty } from "ramda";

import { Button, P, Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import {
  ArrowUpIcon,
  CommonFileAddIcon,
  CommonFileIcon,
  InformationCircleIcon,
  PlusIcon,
  TotalValueIcon,
  WarningIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { openRemoteDocument } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../../transactions-service";
import { CapitalIncreaseGetDTO, Transaction, TransactionCategory, TransactionStatus } from "../../../../types";
import TransactionsList from "../../transactions-list";
import { TransactionContextMenuHandlers } from "../body";
import classes from "../transaction-item.module.scss";

type PropsTypes = {
  bundle: Transaction;
  eventKey: string;
  contextMenuHandlers: TransactionContextMenuHandlers;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.transactionItem");

const CapitalIncreaseBody: FC<PropsTypes> = ({ bundle, eventKey, contextMenuHandlers }) => {
  const { companyId = "0" } = useParams();
  const fNumber = useFormatNumbers();
  const { activeEventKey } = useContext(AccordionContext);
  const { getCapitalIncrease } = useTransactionsService(companyId);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [capitalIncreaseData, setCapitalIncreaseData] = useState<CapitalIncreaseGetDTO>();

  const getCapitalIncreaseRequest = useCallback(async () => {
    setIsLoading(true);
    setCapitalIncreaseData(await getCapitalIncrease(bundle.transactionBundleId!));

    setIsLoading(false);
  }, [bundle.transactionBundleId, getCapitalIncrease]);

  const totalAmount = useMemo(() => {
    if (capitalIncreaseData?.totalIncrease && capitalIncreaseData?.sharePrice) {
      return (capitalIncreaseData.totalIncrease * capitalIncreaseData.sharePrice).toFixed(8);
    }

    return 0;
  }, [capitalIncreaseData]);

  const totalAmountOfSharesInsideTransactions = useMemo(
    () =>
      defaultTo([] as Transaction[], capitalIncreaseData?.transactions).reduce(
        (acc: number, curr: Transaction) => acc + curr.numberOfShares,
        0
      ),
    [capitalIncreaseData?.transactions]
  );

  useEffect(() => {
    // check if accordion body is opened
    if (eventKey === activeEventKey) {
      getCapitalIncreaseRequest();
    }
  }, [activeEventKey, eventKey, getCapitalIncrease, getCapitalIncreaseRequest, bundle.statusId]);

  return (
    <>
      <div className={cn("p-4 mb-2", classes.bodyContainer, classes.darkBackground)}>
        <div
          className="d-flex justify-content-evenly pb-4 mb-3"
          style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}
        >
          <div className="d-flex flex-fill">
            <CommonFileAddIcon color={scssVariables.foregroundLow} width={40} height={40} />
            <div className="ms-2">
              <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(capitalIncreaseData?.totalIncrease, "amount")}</Ui.xxl>
              <Ui.xs className="text-medium">{t("newShares")}</Ui.xs>
            </div>
          </div>

          <div className="d-flex flex-fill">
            <ArrowUpIcon width={40} height={40} color={scssVariables.foregroundLow} />
            <div className="ms-2">
              <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(totalAmount, "amount")}</Ui.xxl>
              <Ui.xs className="text-medium">{t("totalAmount")}</Ui.xs>
            </div>
          </div>

          <div className="d-flex flex-fill">
            <TotalValueIcon width={40} height={40} color={scssVariables.foregroundLow} />
            <div className="ms-2">
              <Ui.xxl bold>{isLoading ? <Spinner /> : fNumber(capitalIncreaseData?.sharePrice, "sharePrice")}</Ui.xxl>
              <Ui.xs className="text-medium">{t("sharePrice")}</Ui.xs>
            </div>
          </div>
        </div>
        {capitalIncreaseData?.description && (
          <div>
            <P.s
              className="text-medium mb-4"
              dangerouslySetInnerHTML={{
                __html: capitalIncreaseData?.description?.replace(/\r\n/g, "<br />"),
              }}
            />
          </div>
        )}

        <div className={cn("my-3 px-3 py-2", classes["documents"])}>
          {!isEmpty(defaultTo([], bundle?.files)) ? (
            <div className="d-flex flex-wrap">
              {defaultTo([], bundle?.files).map((el) => {
                return (
                  <div
                    key={el.downloadId}
                    className={cn("d-flex align-items-center", classes["single-document"])}
                    onClick={() => {
                      openRemoteDocument(el?.downloadId);
                    }}
                  >
                    <CommonFileIcon fontSize={24} className="me-2" />
                    <Ui.s>{el.fileName}</Ui.s>
                  </div>
                );
              })}
            </div>
          ) : (
            <Button
              size="s"
              variant="tertiary"
              onClick={() => {
                contextMenuHandlers?.handleOpenManageDocumentsTransaction?.(bundle as Transaction | null);
              }}
              iconLeft={<PlusIcon />}
            >
              Add documents
            </Button>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between mb-2">
          <Ui.m bold className="text-medium">
            {t("transactions")}
          </Ui.m>

          {capitalIncreaseData?.totalIncrease === totalAmountOfSharesInsideTransactions ? null : (
            <Tag variant="draft">
              <WarningIcon className="me-1" />
              {defaultTo(0, capitalIncreaseData?.totalIncrease) > totalAmountOfSharesInsideTransactions
                ? t("sharesRemaining", {
                    shares: fNumber(
                      defaultTo(0, capitalIncreaseData?.totalIncrease) - totalAmountOfSharesInsideTransactions,
                      "amount"
                    ),
                  })
                : t("sharesTooMany", {
                    shares: fNumber(
                      totalAmountOfSharesInsideTransactions - defaultTo(0, capitalIncreaseData?.totalIncrease),
                      "amount"
                    ),
                  })}
            </Tag>
          )}
        </div>
        {(capitalIncreaseData?.transactions?.length || -1) > 0 ? (
          <TransactionsList
            isMini
            transactions={capitalIncreaseData?.transactions || []}
            bundleView
            itemIdToOpen={null}
            {...contextMenuHandlers}
          />
        ) : (
          <div className="border-1 rounded-2 py-2 px-3" style={{ backgroundColor: scssVariables.surface1 }}>
            <Ui.l bold className="mb-3">
              {t("emptyTitle")}
            </Ui.l>
            <div className="d-flex align-items-center text-low">
              <InformationCircleIcon />
              <Ui.xs className="ms-2">{t("emptyDescription")}</Ui.xs>
            </div>
          </div>
        )}
      </div>
      {bundle.categoryId === TransactionCategory.CapitalIncrease && bundle.statusId === TransactionStatus.Confirmed && (
        <Ui.s color="foregroundLow" className={cn("py-2 px-4", classes.bodyContainer, classes.darkBackground)}>{`${t(
          "totalSharesAfterTransactions"
        )}: ${fNumber(bundle.numbersOfSharesInCompany, "amount")}`}</Ui.s>
      )}
    </>
  );
};

export default CapitalIncreaseBody;
