import { FC, useCallback } from "react";
import { useParams } from "react-router-dom";

import { StakeholderDetails } from "common/components/organisms";

import CapTableStore from "../../store";

type PropsTypes = {
  show: boolean;
  type: "actual" | "diluted";
  stakeholderId: number;
  handleOpenEditStakeholderModal: () => void;
  handleClose: () => void;
};

const ShareholderDetailsModal: FC<PropsTypes> = ({
  show,
  type,
  stakeholderId,
  handleClose,
  handleOpenEditStakeholderModal,
}) => {
  const { companyId } = useParams<{ companyId: string }>();

  const selectedDateForActualCapTable = CapTableStore.useStoreState((state) => state.selectedDateForActualCapTable);
  const { getActualDataThunk, getDilutedDataThunk } = CapTableStore.useStoreActions((actions) => actions);

  const onModalExit = useCallback(() => {
    if (type === "actual") {
      getActualDataThunk({
        companyId: Number(companyId),
        date: selectedDateForActualCapTable,
      });
    } else {
      getDilutedDataThunk({
        companyId: Number(companyId),
      });
    }

    handleClose();
  }, [companyId, getActualDataThunk, getDilutedDataThunk, handleClose, selectedDateForActualCapTable, type]);

  return (
    <StakeholderDetails
      show={show}
      handleClose={onModalExit}
      getDataLink={`/api/equity-management/cap-table/stakeholder-details/${stakeholderId}`}
    >
      <StakeholderDetails.Details
        editButtonPress={(id) => {
          handleOpenEditStakeholderModal();
          handleClose();
        }}
      />
      <StakeholderDetails.Tabs />
      <StakeholderDetails.Content mode="tabs-view" />
    </StakeholderDetails>
  );
};

export default ShareholderDetailsModal;
