import { FC } from "react";

import { Ui } from "common/components/atoms/Typography";

const TableHead: FC = () => {
  return (
    <thead>
      <tr>
        <th>
          <Ui.xs bold>name</Ui.xs>
        </th>
        <th>
          <Ui.xs bold>email</Ui.xs>
        </th>
        <th></th>
        <th></th>
      </tr>
    </thead>
  );
};

export default TableHead;
