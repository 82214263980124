import { FC, useCallback, useState } from "react";
import { useFormikContext } from "formik";

import { ModalInfo, SlidePanel } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../../IssueEquityContext";
import { ConvertLoanFormValues } from "../useConvertLoanForm";
const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertLoan.actions");

const Actions: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { setIsConvertLoanEditPanelOpen } = IssueEquityContext.useStoreActions((actions) => actions);

  const { handleSubmit, setFieldValue, isSubmitting, validateForm } = useFormikContext<ConvertLoanFormValues>();

  const handleSave = useCallback(() => {
    setFieldValue("saveAsConverting", true);
    handleSubmit();
  }, [handleSubmit, setFieldValue]);

  const handleConvert = useCallback(() => {
    setFieldValue("saveAsConverting", false);
    validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        setIsOpen(true);
      } else {
        handleSubmit();
      }
    });
  }, [handleSubmit, setFieldValue, validateForm]);

  const handleCancel = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmitForm = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const handleClosePanel = useCallback(() => {
    setIsConvertLoanEditPanelOpen({ open: false });
  }, [setIsConvertLoanEditPanelOpen]);

  return (
    <SlidePanel.Actions>
      <div className="flex-grow-1">
        <Button isLoading={isSubmitting} isDisabled={isSubmitting} onClick={handleConvert}>
          {t("submit")}
        </Button>
        <Button
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          variant="secondary"
          className="ms-3"
          onClick={handleClosePanel}
        >
          {t("cancel")}
        </Button>
      </div>

      <Button
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
        variant="tertiary"
        onClick={handleSave}
        className="ms-3"
      >
        {t("save")}
      </Button>
      <ModalInfo
        size={"sm"}
        show={isOpen}
        header={t("modal.title")}
        footer={
          <>
            <Button isLoading={isSubmitting} isDisabled={isSubmitting} onClick={handleSubmitForm}>
              {t("modal.convert")}
            </Button>
            <Button isLoading={isSubmitting} isDisabled={isSubmitting} onClick={handleCancel} variant="secondary">
              {t("modal.cancel")}
            </Button>
          </>
        }
      >
        {t("modal.description")}
      </ModalInfo>
    </SlidePanel.Actions>
  );
};

export default Actions;
