import { FC } from "react";

import { createTranslation, TranslationNS } from "translation";

import DocumentsContext, { SingleProgramDocumentType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import EmptyDocumentsRow from "../../EmptyDocumentsRow";
import ProgramDocumentsTableRow from "./ProgramDocumentsTableRow";

const t = createTranslation(TranslationNS.pages, "company.documents");

type ProgramDocumentsTableBodyProps = {
  data: SingleProgramDocumentType[];
};

const ProgramDocumentsTableBody: FC<ProgramDocumentsTableBodyProps> = ({ data }) => {
  const { searchValue } = DocumentsContext.useStoreState((state) => state);

  return (
    <tbody className={classes["body"]}>
      {data.length ? (
        data.map((el, index) => (
          <ProgramDocumentsTableRow key={`${el.name} ${index}`} data={el} />
        ))
      ) : (
        <EmptyDocumentsRow label={searchValue?.trim() ? t("noResults") : undefined} />
      )}
    </tbody>
  );
};

export default ProgramDocumentsTableBody;
