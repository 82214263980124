import { FC } from "react";
import classNames from "classnames";

import classes from "./Description.module.scss";

export type DescriptionProps = JSX.IntrinsicElements["p"] & {};

const Description: FC<DescriptionProps> = ({
  className,
  children,
  ...props
}) => (
  <p className={classNames(classes["description"], className)} {...props}>
    {children}
  </p>
);

export default Description;
