import React, { ChangeEvent, FC, useCallback } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";
import { pluck } from "ramda";

import { Button, Dropdown, H, TextField } from "common/components/atoms";
import { fields } from "common/components/molecules/StakeholderSection/Stakeholder.types";
import { CloseIcon } from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../stakeholders-management.module.scss";
import { EditStakeholderDTO, PostStakeholderDTO, StakeholderContact } from "../types";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.form.contacts");

type Props = {
  handleClose: () => void;
  addContact: () => void;
  selectedContactIndex?: number;
};

const StakeholderFormSingleContact: FC<Props> = ({ addContact, handleClose, selectedContactIndex }) => {
  const dropdownValues = useStoreState((state) => state?.common?.dropdowns)?.stakeholderContactRoles || [];
  const { values, errors, setFieldValue, handleSubmit } = useFormikContext<
    (PostStakeholderDTO | EditStakeholderDTO) &
      Record<"addContactFormOpen", boolean> &
      Record<"editingContact", boolean> &
      Record<"internalFormSubmitted", boolean>
  >();

  const changeEvent = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(`contacts.${selectedContactIndex}.${e.target.name}`, e.target.value);
    },
    [selectedContactIndex, setFieldValue]
  );

  return (
    <div className={classNames("p-3", classes["contact-form"])}>
      <div className="d-flex justify-content-between">
        <H.xxs className="mb-3">{values.editingContact ? t("updateContact") : t("addContact")}</H.xxs>

        <Button
          size="s"
          isOnlyIcon
          className="ps-0"
          variant="secondary"
          iconLeft={<CloseIcon fontSize={24} />}
          onClick={() => {
            handleClose();
            // resetForm();
          }}
        />
      </div>

      <TextField
        isTouched
        className="mb-3"
        name={fields.firstName}
        label={t("form.firstName")}
        value={values.contacts?.[Number(selectedContactIndex)]?.firstName}
        error={
          typeof errors !== "string"
            ? (errors.contacts?.[selectedContactIndex as number] as Partial<StakeholderContact>)?.firstName
            : undefined
        }
        onChange={changeEvent}
      />

      <TextField
        isTouched
        className="mb-3"
        name={fields.lastName}
        label={t("form.lastName")}
        value={values.contacts?.[Number(selectedContactIndex)]?.lastName}
        error={
          typeof errors !== "string"
            ? (errors.contacts?.[selectedContactIndex as number] as Partial<StakeholderContact>)?.lastName
            : undefined
        }
        onChange={changeEvent}
      />

      <TextField
        isTouched
        isOptional
        className="mb-3"
        name={fields.email}
        label={t("form.email")}
        value={values.contacts?.[Number(selectedContactIndex)]?.email}
        error={
          typeof errors !== "string"
            ? (errors.contacts?.[selectedContactIndex as number] as Partial<StakeholderContact>)?.email
            : undefined
        }
        onChange={changeEvent}
      />

      <TextField
        isOptional
        className="mb-3"
        name={fields.phoneNumber}
        label={t("form.phoneNumber")}
        value={values.contacts?.[Number(selectedContactIndex)]?.phoneNumber}
        onChange={changeEvent}
      />

      <Dropdown
        isOptional
        label={t("form.role")}
        selectedValue={
          dropdownValues.find(
            (el) => el.id === values.contacts?.[Number(selectedContactIndex)]?.stakeholderContactRoleId
          )?.name
        }
        options={pluck("name", dropdownValues)}
        onChange={(value) => {
          const selectedValue = dropdownValues?.find((el) => el.name === value);

          setFieldValue(`contacts.${selectedContactIndex}.stakeholderContactRoleId`, selectedValue?.id);
        }}
      />

      <div className="d-flex mt-5">
        <Button size="s" className="me-2" onClick={handleSubmit as any}>
          {values.editingContact ? t("form.update") : t("form.add")}
        </Button>

        <Button
          size="s"
          variant="secondary"
          onClick={() => {
            handleClose();
            // resetForm();
          }}
        >
          {t("form.cancel")}
        </Button>
      </div>
    </div>
  );
};

export default StakeholderFormSingleContact;
