import { FC, Fragment, useCallback, useState } from "react";
import BTable from "react-bootstrap/Table";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import cn from "classnames";

import { CapTableIssueShares } from "common/components/organisms/stakeholder-details/types";

import classes from "../../stakeholder-details.module.scss";
import { ShareSeriesTypes } from "./ActionsCell";
import BodyRow from "./BodyRow";
import ExpandedRow from "./ExpandedRow";
import HeadRow from "./HeadRow";
import RemovePledgedModal from "./modals/RemovePledgedModal";
import SetPledgedModal from "./modals/SetPledgedModal";
import useShareSeriesTable from "./useShareSeriesTable";

type PledgesNotes = {
  show: boolean;
  issuedSharesId?: number;
  pledgeNotes?: string;
  hasPledges?: boolean;
};

const ShareSeriesTable: FC<{ data: CapTableIssueShares[]; className: string }> = ({ data, className }) => {
  const [pledgedNotes, setPledgedNotes] = useState<PledgesNotes>({ show: false });
  const [removePledgedNotes, setRemovePledgedNotes] = useState<PledgesNotes>({ show: false });

  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [sorting, setSorting] = useState<SortingState>([{ id: "shares", desc: false }]);

  const columns = useShareSeriesTable();

  const actionCallback = useCallback((type: string, data: { id?: number; pledgeNotes?: string }) => {
    const variant = type as ShareSeriesTypes;

    if (variant === "editPledge" || variant === "markAsPledged") {
      setPledgedNotes({
        show: true,
        issuedSharesId: data.id,
        hasPledges: variant === "editPledge",
        pledgeNotes: data.pledgeNotes,
      });
    } else if (variant === "removePledge") {
      setRemovePledgedNotes({
        show: true,
        issuedSharesId: data.id,
      });
    }
  }, []);

  const handleClosePledgeModal = useCallback(() => {
    setPledgedNotes({ show: false });
    setRemovePledgedNotes({ show: false });
  }, []);

  const table = useReactTable({
    data: data,
    columns,
    state: {
      sorting,
    },
    meta: {
      actionCallback,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleRowClick = useCallback((id: number) => {
    setExpandedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((rowId) => rowId !== id);
      }
      return [...prev, id];
    });
  }, []);

  return (
    <div className={className}>
      <BTable className={cn(classes.table, classes.ownershipTable)}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <HeadRow key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <Fragment key={row.id}>
              <BodyRow row={row} rowClickHandler={handleRowClick} />

              {expandedRows.includes(row.original.issuedSharesId as number) && (
                <ExpandedRow
                  open={expandedRows.includes(row.original.issuedSharesId as number)}
                  columnSize={columns.length}
                  clickRowCallback={handleRowClick}
                  {...row.original}
                />
              )}
            </Fragment>
          ))}
        </tbody>
      </BTable>
      <SetPledgedModal {...pledgedNotes} handleClose={handleClosePledgeModal} />
      <RemovePledgedModal {...removePledgedNotes} handleClose={handleClosePledgeModal} />
    </div>
  );
};

export default ShareSeriesTable;
