import { FC } from "react";
import TabPane, { TabPaneProps } from "react-bootstrap/TabPane";

export type ItemProps = Omit<TabPaneProps, "eventKey"> & {
  name: TabPaneProps["eventKey"];
};

const Item: FC<ItemProps> = ({ name, children, ...props }) => (
  <TabPane eventKey={name} {...props}>
    {children}
  </TabPane>
);

export default Item;
