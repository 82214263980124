import { FC, useCallback, useEffect } from "react";
import { useFormikContext } from "formik";

import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { P } from "common/components/atoms/Typography";
import DocumentUploadWithStatusesSignatureEmailInvitation from "common/components/molecules/DocumentUploadWithStatusesSignatureEmailInvitation/DocumentUploadWithStatusesSignatureEmailInvitation";
import { createTranslation, TranslationNS } from "translation";

import useConvertibleLoanEditPanel from "../useConvertibleLoanEditPanel";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan.editPanel");

const LoanDocumentation: FC = () => {
  const { initialValues } = useConvertibleLoanEditPanel();

  const { values, errors, touched, setFieldValue } = useFormikContext<typeof initialValues>();

  const handleDocumentChange = useCallback(
    (data: FilesDataMultiple) => {
      setFieldValue("documents", data);
    },
    [setFieldValue]
  );

  const handleSetDocumentStatus = useCallback(
    (statusId: number) => {
      setFieldValue("documentStatusId", statusId);
    },
    [setFieldValue]
  );

  const handleSetInvitation = useCallback(
    (isChecked: boolean) => {
      setFieldValue("sendInvitationEmail", isChecked);
    },
    [setFieldValue]
  );

  const handleSetSigmature = useCallback(
    (isChecked: boolean) => {
      setFieldValue("documentsNeedsSignature", isChecked);
      if (isChecked) {
        setFieldValue("sendInvitationEmail", true);
      }
    },
    [setFieldValue]
  );
  useEffect(() => {
    if (values.documentsNeedsSignature) {
      setFieldValue("sendInvitationEmail", true);
    }
  }, [setFieldValue, values.documentsNeedsSignature, values.sendInvitationEmail]);

  return (
    <>
      <P.m className="mb-4">{t("docDescription")}</P.m>

      <DocumentUploadWithStatusesSignatureEmailInvitation
        documentError={errors.documents?.newFiles as string}
        documentTouched={touched.documents as unknown as boolean}
        error={errors.documentStatusId}
        touched={touched.documentStatusId}
        documentStatus={values.documentStatusId}
        isNeedSignatureChecked={values.documentsNeedsSignature}
        isNeedEmailInvitationChecked={values.sendInvitationEmail}
        onFileUploaderChange={handleDocumentChange}
        setDocumentStatus={handleSetDocumentStatus}
        setIsNeedEmailInvitationChecked={handleSetInvitation}
        setIsNeedSignatureChecked={handleSetSigmature}
        prevData={values.documents.oldFiles}
      />
    </>
  );
};

export default LoanDocumentation;
