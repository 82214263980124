import React, { useEffect, useState } from "react";
import cn from "classnames";

import FileUploadButton from "common/components/atoms/FileUploadButton/FileUploadButton";
import DefaultImage from "common/components/atoms/FileUploadSection/DefaultImage/DefaultImage";
import { MAX_IMAGE_FILE_SIZE } from "common/utils/constants";

import InputFeedback from "../input-blocks/input-feedback/input-feedback";
import { Ui } from "../Typography";
import classes from "./FileUploadSection.module.scss";

type FileUploadSectionProps = {
  circularImage: boolean;
  defaultImageType: string;
  imageHeight?: number;
  imageWidth: number;
  previewUrl?: string;
  descriptionText: string;
  alternativeText?: string;
  buttonText: string;
  setFileToUpload: (value: any) => void;
  acceptedFormats?: string;
  isDisabled?: boolean;
};

const FileUploadSection: React.FC<FileUploadSectionProps> = ({
  circularImage,
  defaultImageType,
  imageHeight,
  imageWidth,
  previewUrl,
  descriptionText,
  alternativeText,
  buttonText,
  setFileToUpload,
  acceptedFormats,
  isDisabled,
}) => {
  const [error, setError] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>();

  function onDragDrop(e: any) {
    e.preventDefault();

    const file = e?.dataTransfer?.files?.[0] as File;

    if (file.size > MAX_IMAGE_FILE_SIZE) {
      setError("Size is too big. You can upload files up to 5 MB.");

      return;
    }

    readFile(file);
  }

  useEffect(() => {
    if (previewUrl) {
      setImagePreviewUrl(previewUrl);
    }
  }, [previewUrl]);

  function readFile(file: any) {
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result?.toString());
      setFileToUpload(file);
    };
    reader.readAsDataURL(file);
  }

  function onDragOver(e: any) {
    e.preventDefault();
  }

  return (
    <>
      <div
        onDrop={onDragDrop}
        onDragOver={onDragOver}
        className="d-flex flex-column"
        data-testid="file-upload-section-container-test-id"
      >
        <div className="d-flex">
          {imagePreviewUrl ? (
            <img
              src={imagePreviewUrl}
              alt={alternativeText}
              // className={`${circularImage ? classes["picture-circular"] : ""}${descriptionText ? "" : "m-auto"}`}
              className={cn("mb-2", {
                [classes["picture-circular"]]: circularImage,
                "m-auto": descriptionText,
              })}
              style={{
                height: imageHeight,
                width: imageWidth,
                maxHeight: 150,
                objectFit: "cover",
              }}
            />
          ) : (
            <DefaultImage defaultImageType={defaultImageType} imageWidth={imageWidth} imageHeight={imageHeight} />
          )}
        </div>
        <div className="d-flex flex-column">
          <div className={`d-flex flex-row ${descriptionText ? "mb-2 mt-4" : "m-auto"}`}>
            <FileUploadButton
              label={buttonText}
              onSelectedFile={readFile}
              acceptedFormats={acceptedFormats}
              isDisabled={isDisabled}
              setError={setError}
              size={"s"}
            />
          </div>
          {descriptionText ? <Ui.xs style={{ opacity: 0.6 }}>{descriptionText}</Ui.xs> : null}
        </div>
      </div>

      {error ? <InputFeedback isTouched error={error} /> : null}
    </>
  );
};

export default FileUploadSection;
