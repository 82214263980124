import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import cn from "classnames";
import { useFormik } from "formik";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import DiscardModal from "common/components/atoms/DiscardModal/DiscardModal";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Spinner from "common/components/atoms/Spinner/Spinner";
import Tag from "common/components/atoms/Tag/Tag";
import TextArea from "common/components/atoms/TextArea/TextArea";
import { H, Ui } from "common/components/atoms/Typography";
import ViewPitchButton from "common/components/atoms/ViewPitchButton/ViewPitchButton";
import TeamMember from "common/components/molecules/TeamMember/TeamMember";
import { PlusIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import Sidebar from "common/layout/MainLayout/Sidebar/Sidebar";
import { initBeforeUnLoad } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { TeamMember as TeamMemberType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import CompanyTeamMemberForm from "./components/CompanyMemberForm";
import classes from "./team.module.scss";

const COMPANY_MEMBER_IDS = [
  { name: "founder", id: 1 },
  { name: "coFounder", id: 2 },
  { name: "employee", id: 3 },
];

const translation = createTranslation(TranslationNS.pages, "companyProfile.team");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

const CompanyProfileTeamPage: FC = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { hasFullAccess } = useFeatures(FEATURES.attract);

  const { team } = useStoreState((state) => state.company);

  const teamMemberRoleDropDown = useStoreState((state) => state.common.dropdowns)?.teamMemberRoles;
  const securityRoleDropDown = useStoreState((state) => state.common.dropdowns)?.securityRoles;

  const { getTeamThunk } = useStoreActions((state) => state.company);

  const [isTeamLoading, setIsTeamLoading] = useState<boolean>(true);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<TeamMemberType>();
  const [isDiscardModalActive, setIsDiscardModalActive] = useState<boolean>(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState<boolean>(false);
  const [isAddingEmployee, setIsAddingEmployee] = useState<boolean>(false);

  const initialValues = useMemo(
    () => ({
      boardDescription: R.defaultTo("", team?.boardDescription),
      teamMembers: R.defaultTo([] as TeamMemberType[], team?.teamMembers),
      teamDescription: R.defaultTo("", team?.teamDescription),
    }),
    [team]
  );

  const { values, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: submitHandler,
  });

  const { teamMembersArray, boardMembersArray } = useMemo(
    () =>
      values.teamMembers?.reduce((acc, curr) => {
        const includeArray = R.intersection(R.pluck("id", curr.teamMemberRoles), R.pluck("id", COMPANY_MEMBER_IDS));

        if (!R.isEmpty(includeArray)) {
          acc.teamMembersArray = R.append(curr, acc.teamMembersArray);
        } else {
          acc.boardMembersArray = R.append(curr, acc.boardMembersArray);
        }

        return acc;
      }, {} as { [key: string]: TeamMemberType[] }),
    [values.teamMembers]
  );

  window.onload = function () {
    /* istanbul ignore next */
    initBeforeUnLoad(false);
  };

  function handleClose() {
    setIsDiscardModalActive(false);
  }

  function handleDiscard() {
    resetForm();
  }

  async function submitHandler() {
    setIsDataLoading(true);

    try {
      const teamMembersData = {
        id: companyId ? +companyId : 0,
        teamDescription: values.teamDescription ? values.teamDescription : null,
        boardDescription: values.boardDescription ? values.boardDescription : null,
        teamMembers: values.teamMembers?.map((el: TeamMemberType) => ({
          id: el.id,
          // TODO replace with permissions or remove
          // securityRoleId: el.securityRoleId,
        })),
      };

      const request = await axios.post("/api/attract/team", teamMembersData);

      /* istanbul ignore next */
      if (request.status === 200) {
        notify(translation("notify.teamMembersUpdated"), true, "success");
        await getTeamThunk(companyId ? +companyId : 0);
      }
    } catch (e) {
      /* istanbul ignore next */
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      const timer = setTimeout(() => {
        setIsDataLoading(false);
        clearTimeout(timer);
      }, 500);
    }
  }

  const renderTeamMembers = useCallback(
    () =>
      teamMembersArray?.map((el) => (
        <TeamMember
          key={`Team member single item key is - ${el.firstName} ${el.lastName} - ${el.id}`}
          firstName={el.firstName}
          lastName={el.lastName}
          email={el.email}
          imgLink={el.userProfileImageFilePath}
          jobRoles={el.teamMemberRoles.map((el) => el.name)}
          onEdit={() => {
            setSelectedMember(el);
            setIsAddMemberModalOpen(true);
          }}
          onChange={() => {
            /* istanbul ignore next */
            if (!R.isNil(values.teamMembers) && !R.isNil(securityRoleDropDown)) {
              const filteredValues = R.clone([...values.teamMembers]);
              const memberIndex = filteredValues.findIndex((item) => item.id === el.id);

              setFieldValue("teamMembers", filteredValues[memberIndex]);
            }
          }}
        />
      )),
    [teamMembersArray, securityRoleDropDown, values.teamMembers, setFieldValue]
  );

  const renderBoardMembers = useCallback(
    () =>
      boardMembersArray?.map((el) => (
        <TeamMember
          key={`Team member single item key is - ${el.firstName} ${el.lastName} - ${el.id}`}
          firstName={el.firstName}
          lastName={el.lastName}
          email={el.email}
          imgLink={el.userProfileImageFilePath}
          // TODO replace with permissions or remove
          // securityRoleId={el.securityRoleId}
          jobRoles={el.teamMemberRoles.map((role) => role.name)}
          onEdit={() => {
            setSelectedMember(el);
            setIsAddMemberModalOpen(true);
          }}
          onChange={() => {
            /* istanbul ignore next */
            if (!R.isNil(values.teamMembers) && !R.isNil(securityRoleDropDown)) {
              const filteredValues = R.clone([...values.teamMembers]);
              const memberIndex = filteredValues.findIndex((item) => item.id === el.id);

              setFieldValue("teamMembers", filteredValues[memberIndex]);
            }
          }}
        />
      )),
    [boardMembersArray, securityRoleDropDown, values.teamMembers, setFieldValue]
  );

  useEffect(() => {
    if (companyId) {
      const fetchTeam = async () => {
        await getTeamThunk(+companyId);
      };

      try {
        setIsTeamLoading(true);
        fetchTeam();
      } finally {
        setIsTeamLoading(false);
      }
    }
  }, [companyId, getTeamThunk]);

  return (
    <>
      <PageContent data-testid="company-profile-team-test-id">
        <PageContent.Header>
          <div className="d-flex align-items-center">
            <PageContent.Header.Title className="me-2">{translation("title")}</PageContent.Header.Title>
            {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>
          <ViewPitchButton id={Number(companyId)} />
        </PageContent.Header>
        <LoaderContainer loading={isTeamLoading}>
          <div className="d-flex flex-column bg-white paper-container">
            <div className="d-flex flex-column">
              <H.xxs className="mb-3">{translation("company.title")}</H.xxs>

              <TextArea
                isOptional
                maxLength={500}
                value={values.teamDescription}
                label={translation("company.teamDescription.label")}
                onChange={handleChange("teamDescription")}
              />

              <Ui.xs className="mt-1" style={{ opacity: 0.8 }}>
                {translation("company.teamDescription.description")}
              </Ui.xs>

              {!R.isNil(teamMembersArray) && (
                <>
                  <Ui.s className={cn("mt-3", classes["team-member-label"])}>{translation("membersList.member")}</Ui.s>

                  {renderTeamMembers()}

                  <div className="mt-2">
                    <Button
                      iconLeft={<PlusIcon />}
                      variant="tertiary"
                      data-testid="add-team-member-btn-test-id"
                      onClick={() => {
                        setIsAddingEmployee(true);
                        setIsAddMemberModalOpen(true);
                      }}
                      isDisabled={!hasFullAccess}
                    >
                      {translation("addTeamMember")}
                    </Button>
                  </div>
                </>
              )}
            </div>

            <div className="d-flex flex-column mt-5">
              <H.xxs className="mb-3">{translation("boardAndAdvisors.title")}</H.xxs>

              <TextArea
                isOptional
                maxLength={500}
                value={values.boardDescription}
                label={translation("boardAndAdvisors.boardDescription.label")}
                onChange={handleChange("boardDescription")}
              />

              <Ui.xs className="mt-1" style={{ opacity: 0.8 }}>
                {translation("boardAndAdvisors.boardDescription.description")}
              </Ui.xs>

              {!R.isNil(boardMembersArray) && (
                <>
                  <Ui.s className={cn("mt-5", classes["team-member-label"])}>{translation("membersList.member")}</Ui.s>

                  {renderBoardMembers()}
                </>
              )}
              <div className="mt-2">
                <Button
                  iconLeft={<PlusIcon />}
                  variant="tertiary"
                  data-testid="add-board-member-btn-test-id"
                  dataTestId="add-board-member-btn-test-id"
                  onClick={() => {
                    setIsAddingEmployee(false);
                    setIsAddMemberModalOpen(true);
                  }}
                  isDisabled={!hasFullAccess}
                >
                  {translation("addBoardMemberOrAdvisor")}
                </Button>
              </div>
            </div>

            <div className="d-flex flex-column mt-12">
              <div className="d-flex flex-column" style={{ width: 130 }}>
                <Button
                  isDisabled={isDataLoading || !hasFullAccess}
                  isLoading={isDataLoading}
                  data-testid="team-submit-btn-test-id"
                  tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                  tooltipMessage={tCommon("tooltip")}
                  onClick={() => handleSubmit()}
                >
                  {translation("save")}
                </Button>
              </div>
            </div>
          </div>
          <DiscardModal
            isUser
            visible={isDiscardModalActive}
            handleClose={handleClose}
            handleDiscard={handleDiscard}
            handleSubmit={submitHandler}
          />

          <CompanyTeamMemberForm
            isAddingEmployee={isAddingEmployee}
            companyId={companyId}
            open={isAddMemberModalOpen}
            selectedMember={selectedMember}
            currentMembers={team?.teamMembers || []}
            teamMemberRoleDropDown={teamMemberRoleDropDown}
            onChange={setSelectedMember}
            handleClose={(withModalOpen) => {
              setSelectedMember(undefined);
              setIsAddMemberModalOpen(Boolean(withModalOpen));
              setIsAddingEmployee(false);
            }}
          />
        </LoaderContainer>
        {isDataLoading && <Spinner />}
      </PageContent>

      <Sidebar>
        <div style={{ width: 320 }}></div>
      </Sidebar>
    </>
  );
};

export default CompanyProfileTeamPage;
