import { useCallback } from "react";
import { Location,useLocation, useNavigate } from "react-router-dom";

export function useRedirectTo() {
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  return useCallback(
    (location: Location | string, state?: { [key: string]: string | number }): void => {
      if (locationState?.redirectTo) {
        if (state) {
          location = locationState?.redirectTo;
        } else if (typeof location === "string") {
          location = locationState?.redirectTo;
        } else {
          location.pathname = locationState?.redirectTo;
        }
      }

      if (state) {
        navigate(location as string, state);
      } else {
        navigate(location);
      }
    },
    [navigate, locationState?.redirectTo]
  );
}
