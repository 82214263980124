import * as Yup from "yup";

import { TFunction } from "translation/helpers";

import { SharesDetailsFields } from "./form-fields";

export const sharesDetailsValidationSchema = (tFunction: TFunction, isPurchaseDateOptional: boolean = false) => {
  const defaultFields = {
    [SharesDetailsFields.numberOfShares]: Yup.number()
      .test("is-decimal", "Invalid value", (value) => !!String(value).match(/^\d+$/))
      .min(
        1,
        tFunction("minNumber", {
          number: 1,
        })
      )
      .required(tFunction("required")),
    [SharesDetailsFields.purchasePrice]: Yup.number()
      .min(
        0.000001,
        tFunction("minNumber", {
          number: "0,000001",
        })
      )
      .required(tFunction("required")),
    [SharesDetailsFields.shareClassId]: Yup.number().required(tFunction("required")),
  };

  if (isPurchaseDateOptional) {
    return defaultFields;
  }

  return {
    ...defaultFields,
    [SharesDetailsFields.sharePurchaseDate]: Yup.string().required(tFunction("required")),
  };
};
