import { FC, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, LoaderContainer, P, Tag, Ui } from "common/components/atoms";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import { InformationCircleIcon } from "common/icons/svg";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import DeclineExercising from "./components/DeclineExercising/DeclineExercising";
import History from "./components/History/History";
import IssueSharesOffCanvas from "./components/IssueSharesOffCanvas/IssueSharesOffCanvas";
import ExercisingOverview from "./components/Overview/Overview";
import PendingApproval from "./components/PendingApproval/PendingApproval";
import ReadyToBeIssued from "./components/ReadyToBeIssued/ReadyToBeIssued";
import WaitingForPayment from "./components/WaitingForPayment/WaitingForPayment";
import ExercisingContext from "./ExercisingContext";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.exercising"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const Content: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>();
  const { hasFullAccess } = useFeatures(FEATURES.exercise);

  const loading = ExercisingContext.useStoreState((state) => state.loading);
  const missingBankInformation = ExercisingContext.useStoreState((state) => state.missingBankInformation);
  const issueShare = ExercisingContext.useStoreState((state) => state.issueShare);
  const getMenageExercisingThunk = ExercisingContext.useStoreActions((actions) => actions.getMenageExercisingThunk);

  const goToBankDetailsPage = () => {
    navigate(getEMPath(["settings", "bankDetails"], { companyId }));
  };

  useEffect(() => {
    if (companyId) {
      getMenageExercisingThunk(+companyId);
    }
  }, [companyId, getMenageExercisingThunk]);

  return (
    <PageContent data-testid="company-exercising-page-test-id">
      <PageContent.Header>
        <div className="d-flex  align-items-center">
          <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
        </div>
      </PageContent.Header>

      <LoaderContainer loading={loading}>
        {missingBankInformation ? (
          <div
            className="d-flex p-3 pe-4 mb-3 align-items-center"
            style={{ backgroundColor: scssVariables.information050 }}
          >
            <InformationCircleIcon fontSize={20} color={scssVariables.information700} />

            <div className="ms-2 me-9">
              <Ui.m bold className="mb-1">
                {t("emptyBankInfo.title")}
              </Ui.m>
              <P.s>{t("emptyBankInfo.description")}</P.s>
            </div>

            <Button
              size="s"
              className="ms-auto"
              style={{ backgroundColor: scssVariables.information700 }}
              onClick={goToBankDetailsPage}
            >
              {t("emptyBankInfo.addDetails")}
            </Button>
          </div>
        ) : null}

        <ExercisingOverview />
        <PendingApproval companyId={companyId ? +companyId : 0} />
        <WaitingForPayment companyId={companyId ? +companyId : 0} />

        <ReadyToBeIssued />

        <History />
        <DeclineExercising />

        {issueShare && companyId && <IssueSharesOffCanvas companyId={+companyId} />}
      </LoaderContainer>
    </PageContent>
  );
};

const CompanyExercisingPage: FC = () => {
  return (
    <ExercisingContext.Provider>
      <Content />
    </ExercisingContext.Provider>
  );
};

export default CompanyExercisingPage;
