import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { PoolTypesOfSource } from "common/enums/enum";
import { PoolFormValues } from "pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { createTranslation, TranslationNS } from "translation";

export type ShareSourceTypeFieldProps = {
  className?: string;
  isDisabled?: boolean;
};

export const typesOfSource = [PoolTypesOfSource.new, PoolTypesOfSource.existing];

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.shareSourceTypeField");

const PoolShareSourceTypeField: FC<ShareSourceTypeFieldProps> = ({ className, isDisabled }) => {
  const { values, touched, errors, handleChange, handleBlur } =
    useFormikContext<Pick<PoolFormValues, "shareSourceTypeId">>();

  const options = useMemo(() => typesOfSource.map((item) => ({ name: t(`options.${item}`), id: item })), []);

  return (
    <Dropdown
      label={t("label")}
      isTouched={touched.shareSourceTypeId}
      error={errors.shareSourceTypeId}
      selectedValue={values.shareSourceTypeId}
      name="shareSourceTypeId"
      onChange={handleChange}
      onBlur={handleBlur}
      optionsIsObject
      options={options}
      className={className}
      isDisabled={isDisabled}
    />
  );
};

export default PoolShareSourceTypeField;
