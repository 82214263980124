import { FC, ReactNode } from "react";
import { Col, Row, RowProps } from "react-bootstrap";

import { Helper } from "common/components/atoms";

export type PoolHelperProps = RowProps & {
  colInputSize?: number;
  colAnswerSize?: number;
  children?: ReactNode;
  questionId: string;
  answerId?: string;
  answerText: ReactNode;
  link?: string;
  linkText?: string;
};

const PoolHelper: FC<PoolHelperProps> = ({
  label,
  colInputSize = 6,
  colAnswerSize = 12,
  questionId,
  answerId,
  answerText,
  link,
  linkText,
  children,
  ...props
}) => {
  return (
    <Helper>
      <Row {...props}>
        <Col md={colInputSize}>
          <Helper.Question questionId={questionId} type="input">
            {children}
          </Helper.Question>
        </Col>
        <Col md={colAnswerSize}>
          <Helper.Answer
            className={"mt-3"}
            answerId={answerId || questionId}
            text={answerText}
            link={link}
            linkText={linkText}
            withRightMargin
          />
        </Col>
      </Row>
    </Helper>
  );
};

export default PoolHelper;
