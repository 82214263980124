import { useParams } from "react-router-dom";
import axios from "axios";

import { Button, ModalInfo, P } from "common/components/atoms";

import DocumentsContext from "../../DocumentsContext";

const RemoveDocument = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { removalDocumentId, isRemovalModalGoverning } = DocumentsContext.useStoreState((state) => state);
  const { getCompanyDocumentsThunk, setIsLoading, setRemovalDocumentId, setIsRemovalModalGoverning } =
    DocumentsContext.useStoreActions((actions) => actions);

  const handleClose = () => {
    setRemovalDocumentId(null);
    setIsRemovalModalGoverning(false);
  };

  const handleRemove = async () => {
    setIsLoading(true);
    try {
      await axios.delete(`/api/document/${removalDocumentId}`);
      await getCompanyDocumentsThunk(Number(companyId));

      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  const renderFooter = () => {
    return (
      <>
        <Button onClick={handleRemove}>Delete</Button>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
      </>
    );
  };

  return (
    <ModalInfo
      show={Boolean(removalDocumentId)}
      header="Are you sure you want to delete document?"
      handleClose={handleClose}
      footer={renderFooter()}
    >
      <P.m>
        {isRemovalModalGoverning
          ? "The document might be attached to ownership agreements. If you delete this it will break the link between the documents."
          : "This can not be undone."}
      </P.m>
    </ModalInfo>
  );
};

export default RemoveDocument;
