import { FC, useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { assoc, update } from "ramda";

import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import classes from "common/components/atoms/ImportTable/inputs/inputs.module.scss";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";

import { TransactionTypes } from "../../../../equity-management/ownership/transactions/types";
import { fields } from "../useImportTransactionForm";

type SourceFieldProps = {
  sourceOptions: { id: number | string; name: string }[];
  stakeholderOptions: { id: number | string; name: string }[];
  hasFromShareholder?: number[];
  dependOnField: string;
  rowIndex: number;
  columnId: string;
  placeholder?: string;
};

type FormikValues = {
  [key: string]: number | undefined;
};

const SourceField: FC<SourceFieldProps> = ({
  sourceOptions,
  stakeholderOptions,
  hasFromShareholder,
  dependOnField,
  rowIndex,
  columnId,
  placeholder,
}) => {
  const { values, handleChange, handleBlur, errors, touched, setValues } = useFormikContext<FormikValues[]>();

  const error = errors[rowIndex]?.[columnId];
  const value = values[rowIndex]?.[columnId];
  const touchedValue = touched?.[rowIndex]?.[columnId];

  const selectedRowWithNewShares =
    values[rowIndex]?.[fields.transactionType] === TransactionTypes.Foundation ||
    values[rowIndex]?.[fields.transactionType] === TransactionTypes.RsaSharesIssued;

  const dependOnValue = values[rowIndex]?.[dependOnField] || 0;
  const isStakeholder = useMemo(() => {
    return hasFromShareholder?.includes(dependOnValue);
  }, [dependOnValue, hasFromShareholder]);

  const data = useMemo(() => {
    if (isStakeholder) {
      return { options: stakeholderOptions, isSearchable: true };
    } else {
      return { options: sourceOptions, isSearchable: false };
    }
  }, [isStakeholder, sourceOptions, stakeholderOptions]);

  useEffect(() => {
    if (selectedRowWithNewShares) {
      const currentItem = values.find((_, index) => index === rowIndex);

      setValues(update(rowIndex, assoc(fields.sourceShareholderId, 1, currentItem), values));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowWithNewShares, setValues, values?.[rowIndex]?.[fields.transactionType]]);

  return (
    <>
      {error && touchedValue && (
        <div className={classes.errorIcon}>
          <Tooltip className={classes.errorTooltip} popupContent={error}>
            <span>
              <WarningExclamationMarkIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <Dropdown
        name={`[${rowIndex}].${columnId}`}
        className={classes.selectInput}
        isSearchable={data.isSearchable}
        options={data.options}
        optionsIsObject
        selectedValue={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </>
  );
};

export default SourceField;
