import { FC } from "react";
import { useFormikContext } from "formik";

import { Button, ModalInfo, P } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

type PropsTypes = {
  dataManagerRef?: any;
  onClose: () => void;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.submitBuySellTransactionModal");

const SubmitModal: FC<PropsTypes> = ({ dataManagerRef, onClose }) => {
  const { isSubmitting, handleSubmit } = useFormikContext();

  return (
    <ModalInfo
      show={true}
      size="sm"
      header={t("title")}
      footer={
        <div className="d-flex">
          <Button
            isLoading={isSubmitting}
            onClick={() => {
              handleSubmit();
              dataManagerRef?.current?.resetFilesData();
            }}
            type="submit"
            className="me-2"
          >
            {t("addBtn")}
          </Button>
          <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
            {t("cancelBtn")}
          </Button>
        </div>
      }
      handleClose={isSubmitting ? undefined : onClose}
    >
      <P.m>{t("body")}</P.m>
    </ModalInfo>
  );
};

export default SubmitModal;
