import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ExercisingHistory, Helper, LoaderContainer, OngoingExercises } from "common/components/atoms";
import Purchase from "common/components/atoms/OwnershipSinglePlanComponents/StockOptions/purchase/Purchase";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { createTranslation, TranslationNS } from "translation";

import ExerciseRequestStatus from "./components/ExerciseRequestStatus/ExerciseRequestStatus";
import RequestToExerciseOffCanvas from "./components/RequestToExerciseOffCanvas/RequestToExerciseOffCanvas";
import classes from "./exercising.module.scss";
import ExercisingContext from "./ExercisingContext";

const t = createTranslation(TranslationNS.pages, "myPortfolio.exercising");

const Content: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { exerciseRequestId } = useParams<{ exerciseRequestId?: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const ongoingExercises = ExercisingContext.useStoreState((state) => state.ongoingExercises);
  const exercisablePlans = ExercisingContext.useStoreState((state) => state.exercisablePlans);
  const activeExercisablePlan = ExercisingContext.useStoreState((state) => state.activeExercisablePlan);
  const activeExerciseRequest = ExercisingContext.useStoreState((state) => state.activeExerciseRequest);
  const setActiveExercisablePlan = ExercisingContext.useStoreActions((actions) => actions.setActiveExercisablePlan);
  const getUserExercisingThunk = ExercisingContext.useStoreActions((actions) => actions.getUserExercisingThunk);
  const setActiveExerciseRequest = ExercisingContext.useStoreActions((actions) => actions.setActiveExerciseRequest);
  const history = ExercisingContext.useStoreState((state) => state.history);

  useEffect(() => {
    setIsLoading(true);
    getUserExercisingThunk(exerciseRequestId).finally(() => {
      setIsLoading(false);
    });
  }, [exerciseRequestId, getUserExercisingThunk]);

  const handleRequestCallback = useCallback(
    (planId: number | null) => {
      setActiveExercisablePlan(planId);
    },
    [setActiveExercisablePlan]
  );

  return (
    <PageContent className={classes.exercising} data-testid="exercising-page-test-id">
      <Helper>
        <PageContent.Header>
          <Helper.Question questionId="exercising-title-id">
            <PageContent.Header.Title>{t("title")}</PageContent.Header.Title>
          </Helper.Question>
        </PageContent.Header>

        <Helper.Answer answerId="exercising-title-id" text={t("infoTitle")} className="mb-3" />
        <LoaderContainer loading={isLoading}>
          {!!exercisablePlans.length && <Purchase data={exercisablePlans} requestCallback={handleRequestCallback} />}
          <OngoingExercises ongoingExercises={ongoingExercises} setActiveExerciseRequest={setActiveExerciseRequest} />
          <ExercisingHistory history={history} setActiveExerciseRequest={setActiveExerciseRequest} />
          <RequestToExerciseOffCanvas open={!!activeExercisablePlan} />
          <ExerciseRequestStatus open={!!activeExerciseRequest} />
        </LoaderContainer>
      </Helper>
    </PageContent>
  );
};

const MyPortfolioExercising: FC = () => {
  return (
    <ExercisingContext.Provider>
      <Content />
    </ExercisingContext.Provider>
  );
};

export default MyPortfolioExercising;
