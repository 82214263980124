import {
  CSSProperties,
  FC,
  FocusEvent,
  KeyboardEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { default as ReactDatePicker } from "react-datepicker";
import cn from "classnames";
import { format as formatFns, getYear, parse, parseISO, setHours, setMinutes, setSeconds } from "date-fns";

import { CalendarIcon, CloseIcon } from "common/icons/svg";

import "react-datepicker/dist/react-datepicker.css";

import { InputFeedbackProps } from "../input-blocks/input-feedback/input-feedback";
import { InputHeaderProps } from "../input-blocks/input-header/input-header";
import TextField from "../TextField/TextField";
import classes from "./DatePicker.module.scss";
import DatePickerHeader from "./DatePickerHeader";

export type DatePickerProps = InputHeaderProps &
  InputFeedbackProps & {
    label?: string;
    date?: Date | string | null;
    onChange: (date: Date | string, name?: string) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    className?: string;
    placeholder?: string;
    testId?: string;
    isDateOnlyString?: boolean;
    isClearable?: boolean;
    name?: string;
    style?: CSSProperties;
    isWithTimeSelect?: boolean;
    minDate?: Date;
    maxDate?: Date;

    CustomDatePickerInput?: JSX.Element;
  };

export const dateFormatWithTime = "yyyy-MM-dd'T'HH:mm";
export const dateFormatWithoutTime = "yyyy-MM-dd";

/*
  use this function to create initial values for dates.
  1) It will make sure we use correct date format
  2) Most important is to remove timezone information if we use date with time
 */
export const createDateString = (date: Date, withTime = false) => {
  return formatFns(date, withTime ? dateFormatWithTime : dateFormatWithoutTime);
};

const DatePicker: FC<DatePickerProps> = ({
  date,
  onChange,
  onBlur,
  label,
  isDisabled,
  isTouched,
  isOptional,
  error,
  meta,
  info,
  placeholder = "dd.mm.yyyy",
  className,
  testId,
  isDateOnlyString,
  isClearable,
  name,
  withoutInfoIcon,
  isWithTimeSelect,
  style,
  minDate,
  maxDate,

  CustomDatePickerInput,
}) => {
  const inputRef = useRef<any>(null);
  const [format, setFormat] = useState(isWithTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy");

  useEffect(() => {
    setFormat(isWithTimeSelect ? "dd.MM.yyyy HH:mm" : "dd.MM.yyyy");
  }, [isWithTimeSelect]);

  const dateObject = useMemo(() => {
    if (typeof date === "string" && date && isWithTimeSelect) {
      const MIN_YEAR = 1900;
      const MAX_YEAR = 2150;

      const dateValue = parseISO(date);
      const year = getYear(dateValue);
      if (year < MIN_YEAR || year > MAX_YEAR) {
        return parse("1900-01-01T00:00", "yyyy-MM-dd'T'HH:mm", new Date());
      } else {
        return dateValue;
      }
    }

    if (typeof date === "string" && date) {
      return parse(date, dateFormatWithoutTime, new Date());
    }

    if (date && typeof date === "object") {
      return date;
    }

    return new Date(new Date().setHours(12, 0, 0, 0));
  }, [date, isWithTimeSelect]);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === "Tab") {
      inputRef.current.setOpen(false);
    }
  }, []);

  const isSelectedDateIsMinDate = useMemo(() => {
    if (minDate) {
      return createDateString(dateObject) === createDateString(minDate);
    }
    return false;
  }, [dateObject, minDate]);

  const isSelectedDateIsMaxDate = useMemo(() => {
    if (maxDate) {
      return createDateString(dateObject) === createDateString(maxDate);
    }

    return false;
  }, [dateObject, maxDate]);

  const minTime = useMemo(() => {
    const date = new Date(new Date().setHours(0, 0, 0));

    if (isSelectedDateIsMinDate && minDate) {
      return setSeconds(setMinutes(setHours(date, minDate.getHours()), minDate.getMinutes()), minDate.getSeconds());
    }

    return date;
  }, [isSelectedDateIsMinDate, minDate]);

  const maxTime = useMemo(() => {
    const date = new Date(new Date().setHours(23, 59, 59));

    if (isSelectedDateIsMaxDate && maxDate) {
      return setSeconds(setMinutes(setHours(date, maxDate.getHours()), maxDate.getMinutes()), maxDate.getSeconds());
    }

    return date;
  }, [isSelectedDateIsMaxDate, maxDate]);

  const handleChange = useCallback(
    (selectedDate: Date | null) => {
      // selectedDate.getFullYear() < 2100 is a workaround for a bug in react-datepicker
      if (selectedDate && selectedDate.getFullYear() < 2100) {
        if (minDate && selectedDate < minDate) {
          onChange(
            isDateOnlyString
              ? isWithTimeSelect
                ? formatFns(minDate, dateFormatWithTime)
                : formatFns(minDate, dateFormatWithoutTime)
              : selectedDate,
            name
          );
        } else if (maxDate && selectedDate > maxDate) {
          onChange(
            isDateOnlyString
              ? isWithTimeSelect
                ? formatFns(maxDate, dateFormatWithTime)
                : formatFns(maxDate, dateFormatWithoutTime)
              : selectedDate,
            name
          );
        } else {
          onChange(
            isDateOnlyString
              ? isWithTimeSelect
                ? formatFns(selectedDate, dateFormatWithTime)
                : formatFns(selectedDate, dateFormatWithoutTime)
              : selectedDate,
            name
          );
        }
      } else {
        onChange("", name);
      }
    },
    [minDate, maxDate, onChange, isDateOnlyString, isWithTimeSelect, name]
  );

  const handleClear = useCallback(() => {
    onChange("", name);
  }, [name, onChange]);

  return (
    <div
      data-testid={`datepicker-container-${name || label}-test-id`}
      className={cn(classes["datepicker-container"], { [classes.withLabel]: !!label }, className)}
      style={style}
    >
      <ReactDatePicker
        autoComplete="off"
        showTimeSelect={isWithTimeSelect}
        timeFormat="HH:mm"
        selected={date ? dateObject : null}
        disabled={isDisabled}
        calendarStartDay={1}
        dateFormat={format}
        placeholderText={placeholder}
        name={name}
        ref={inputRef}
        maxDate={maxDate}
        minDate={minDate}
        minTime={minTime}
        maxTime={maxTime}
        customInput={
          CustomDatePickerInput ? (
            CustomDatePickerInput
          ) : (
            <TextField
              label={label}
              iconRight={isClearable && date ? <CloseIcon /> : <CalendarIcon />}
              iconRightOnClick={isClearable && date ? handleClear : undefined}
              isTouched={isTouched}
              isOptional={isOptional}
              isDisabled={isDisabled}
              meta={meta}
              info={info}
              error={error}
              withoutInfoIcon={withoutInfoIcon}
              data-testid={testId || `datepicker-input-${name || label}-test-id`}
              style={{
                cursor: "pointer",
              }}
            />
          )
        }
        renderCustomHeader={DatePickerHeader}
        onBlur={onBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default memo(DatePicker);
