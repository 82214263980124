import { useMemo } from "react";
import * as R from "ramda";
import * as Yup from "yup";

import {
  FACEBOOK_VALIDATION_PATTERN,
  INSTAGRAM_VALIDATION_PATTERN,
  URL_VALIDATION_PATTERN,
} from "common/utils/validators";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.validation);

export type FormGeneralValues = {
  name: string;
  organizationNumber: string;
  foundedAt?: string;
  numberOfEmployees: number | null;
  websiteUrl: string;
  headquartersCountryId: number;
  headquartersCity: string;
  currencyId: number;
  contactPersonTeamMemberId: string | null;
  calendlyLink: string;
  linkedInLink: string;
  mediumLink: string;
  facebookLink: string;
  twitterLink: string;
  instagramLink: string;
  logoPreview?: string;
  logoFile?: File;
};

const useCompanyInformationForm = () => {
  const { generalInfo } = useStoreState((state) => state.company);

  const initialValues: FormGeneralValues = useMemo(
    () => ({
      name: R.defaultTo("", generalInfo?.name),
      organizationNumber: R.defaultTo("", generalInfo?.organizationNumber),
      foundedAt: R.defaultTo("", generalInfo?.foundedAt),
      numberOfEmployees: R.defaultTo(null, generalInfo?.numberOfEmployees),
      websiteUrl: R.defaultTo("", generalInfo?.websiteUrl),
      headquartersCountryId: R.defaultTo(0, generalInfo?.headquartersCountryId),
      headquartersCity: R.defaultTo("", generalInfo?.headquartersCity),
      currencyId: R.defaultTo(0, generalInfo?.currencyId),
      contactPersonTeamMemberId: R.defaultTo(null, generalInfo?.contactPersonTeamMemberId),
      calendlyLink: R.defaultTo("", generalInfo?.calendlyUrl),
      linkedInLink: R.defaultTo("", generalInfo?.linkedInUrl),
      mediumLink: R.defaultTo("", generalInfo?.mediumUrl),
      facebookLink: R.defaultTo("", generalInfo?.metaUrl),
      twitterLink: R.defaultTo("", generalInfo?.twitterHandle),
      instagramLink: R.defaultTo("", generalInfo?.instagramUrl),
      logoPreview: generalInfo?.logoImageFilePath
        ? `${process.env.REACT_APP_BLOB_PUBLIC_URL}${generalInfo?.logoImageFilePath}`
        : "",
      logoFile: undefined,
    }),
    [generalInfo]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(t("required")),
        foundedAt: Yup.string().required(t("required")),
        currencyId: Yup.number().required(t("required")).min(1, t("required")),
        organizationNumber: Yup.string(),
        websiteUrl: Yup.string().matches(URL_VALIDATION_PATTERN, t("invalidURL")),
        calendlyLink: Yup.string().matches(URL_VALIDATION_PATTERN, t("invalidURL")),
        linkedInLink: Yup.string().matches(URL_VALIDATION_PATTERN, t("invalidURL")),
        mediumLink: Yup.string().matches(URL_VALIDATION_PATTERN, t("invalidURL")),
        facebookLink: Yup.string().matches(FACEBOOK_VALIDATION_PATTERN, t("invalidURL")),
        twitterLink: Yup.string().matches(URL_VALIDATION_PATTERN, t("invalidURL")),
        instagramLink: Yup.string().matches(INSTAGRAM_VALIDATION_PATTERN, t("invalidURL")),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useCompanyInformationForm;
