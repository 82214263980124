import { Action, action, ThunkOn, thunkOn } from "easy-peasy";

import { StoreModel } from "store/store";

export interface StatusModel {
  accountRequestReceived: boolean;
  setAccountRequestReceived: Action<this, boolean>;
  finishedInitialLoading: boolean;
  setFinishedInitialLoading: Action<this, boolean>;

  onLastInitialLoad: ThunkOn<StatusModel, string, StoreModel>;
}

export const status: StatusModel = {
  accountRequestReceived: false,
  setAccountRequestReceived: action((state, payload) => {
    state.accountRequestReceived = payload;
  }),
  finishedInitialLoading: false,
  setFinishedInitialLoading: action((state, payload) => {
    state.finishedInitialLoading = payload;
  }),

  onLastInitialLoad: thunkOn(
    (actions, storeActions) => storeActions.account.getAccountThunk,
    (state) => {
      state.setAccountRequestReceived(true);
    }
  ),
};
