import { useCallback, useMemo } from "react";
import * as R from "ramda";
import * as Yup from "yup";

import { InstrumentTypesIdsEnum, PlanDocumentStatusEnum, VestingTypesEnum } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { cliffError } from "pages/equity-management/plans/wizards/create-program/useProgramFormValidation";
import { createTranslation, TranslationNS } from "translation";

import { newPlanFieldsNames as f } from "./planFormFields";

const t = createTranslation(TranslationNS.validation);

const timeBasedCondition = (value: number) => {
  return +value === VestingTypesEnum.TIME_VESTING || +value === VestingTypesEnum.COMBINE_VESTING;
};

const milestoneBasedCondition = (value: number) => {
  return +value === VestingTypesEnum.MILESTONE_VESTING || +value === VestingTypesEnum.COMBINE_VESTING;
};

const companyVesting = (value: number) => {
  return +value === VestingTypesEnum.COMBINE_VESTING;
};
export const useOneOffPlanValidation = () => {
  const required = t("required");
  const integer = t("integer");
  const maxCharacters100 = t("maxCharacters", { number: "100" });
  const invalidEmail = t("invalidEmail");
  const minNumber1 = t("minNumber", { number: 1 });
  const minNumber0 = t("minNumber", { number: 0 });
  const minNumber000001 = t("minNumber", { number: "0,000001" });
  const vestingPeriod = t("vestingPeriod");
  const minimumMilestones = t("minimumMilestones", { number: 1 });

  const fNumber = useFormatNumbers();

  return useMemo(
    () =>
      Yup.object({
        [f.firstName]: Yup.string().required(required).max(100, maxCharacters100),
        [f.lastName]: Yup.string().required(required).max(100, maxCharacters100),
        [f.isCompanyOwned]: Yup.boolean(),
        [f.companyName]: Yup.string().when(f.isCompanyOwned, {
          is: true,
          then: Yup.string().required(required).max(100, maxCharacters100),
        }),
        [f.organizationNumber]: Yup.string().when([f.documentStatusId, f.isCompanyOwned], {
          is: (documentStatusId: PlanDocumentStatusEnum, isCompanyOwned: boolean) => {
            return isCompanyOwned && Number(documentStatusId) === PlanDocumentStatusEnum.GENERATE;
          },
          then: Yup.string().required(required),
        }),
        [f.email]: Yup.string()
          .email(invalidEmail)
          .when(f.sendEmailInvitation, {
            is: true,
            then: Yup.string().email(invalidEmail).required(required),
          }),
        [f.relationshipTypeId]: Yup.number().min(1),
        [f.reason]: Yup.string(),
        // poolId
        [f.poolId]: Yup.number().required().min(-1),
        [f.sharesFromStakeholderId]: Yup.number().when(f.poolId, (poolId) => {
          if (poolId === -1) {
            return Yup.number().required(required).min(0, required);
          }
          return Yup.number().notRequired();
        }),
        // shareDetails
        [f.numberOfShares]: Yup.number().when(f.numberOfSharesLeft, (numberOfSharesLeft) => {
          return Yup.number()
            .required(required)
            .integer(integer)
            .min(1, minNumber1)
            .max(
              +numberOfSharesLeft !== -1 ? +numberOfSharesLeft : Number.MAX_VALUE,
              t("maxNumber", { number: numberOfSharesLeft })
            );
        }),
        [f.purchasePrice]: Yup.number().when(f.planTypeId, {
          is: (planTypeId: number) => +planTypeId === InstrumentTypesIdsEnum.RSA,
          then: Yup.number().required(required).min(0.000001, minNumber000001),
          otherwise: Yup.number().min(0, minNumber0),
        }),
        [f.exercisePrice]: Yup.number().when(f.planTypeId, {
          is: (planTypeId: number) => +planTypeId === InstrumentTypesIdsEnum.OPTION,
          then: Yup.number().required(required).min(0.000001, minNumber000001),
        }),
        [f.optionsExpiration]: Yup.number().when(f.planTypeId, {
          is: (planTypeId: number) => +planTypeId === InstrumentTypesIdsEnum.OPTION,
          then: Yup.number().required(required).integer(integer).min(0, minNumber0),
        }),
        //vestingDetails
        [f.vestingStartsAt]: Yup.date().required(required),
        [f.allowAcceleratedVesting]: Yup.boolean(),
        [f.vestingPeriod]: Yup.number().when(f.vestingTypeId, {
          is: timeBasedCondition,
          then: Yup.number().integer(integer).required(required).min(1, minNumber1),
        }),
        [f.vestingInterval]: Yup.number().when(f.vestingTypeId, {
          is: timeBasedCondition,
          then: Yup.number()
            .required(required)
            .integer(integer)
            .min(1, minNumber1)
            .max(Yup.ref(f.vestingPeriod), vestingPeriod),
        }),
        [f.vestingCliff]: Yup.number().when(f.vestingTypeId, {
          is: timeBasedCondition,
          then: Yup.number()
            .required(required)
            .integer(integer)
            .test("vestingCliff", cliffError, (vestingCliff, obj) => {
              if (vestingCliff === 0) return true;

              return (
                typeof vestingCliff === "number" &&
                vestingCliff >= obj.parent["vestingInterval"] &&
                vestingCliff < obj.parent["vestingPeriod"]
              );
            }),
        }),
        [f.timeVestedShares]: Yup.number().when(f.vestingTypeId, {
          is: companyVesting,
          then: Yup.number().required(required).integer(integer).min(1, minNumber1),
        }),
        [f.conditions]: Yup.array().when(f.vestingTypeId, {
          is: milestoneBasedCondition,
          then: Yup.array()
            .min(1, minimumMilestones)
            .of(
              Yup.object().shape({
                title: Yup.string().required(required),
                // description: Yup.string().required(required),
                numberOfOptions: Yup.number().required(required).integer(integer).min(1, required),
                targetDate: Yup.date().required(required),
              })
            )
            .test("conditions", (value, obj) => {
              if (!obj.parent.conditions.length) return true;
              const total =
                +(obj.parent.timeVestedShares || 0) +
                (obj.parent.conditions || []).reduce(
                  (
                    acc: number,
                    curr: {
                      numberOfOptions?: number;
                    }
                  ) => acc + +R.defaultTo(0, curr.numberOfOptions),
                  0
                );
              if (total !== +obj.parent.numberOfShares) {
                throw new Yup.ValidationError(
                  `Total ${fNumber(total, "amount")} options must be equal to ${fNumber(
                    obj.parent.numberOfShares,
                    "amount"
                  )}`,
                  obj,
                  "conditions"
                );
              }

              return true;
            }),
        }),
        //exerciseClause
        [f.retirement]: Yup.number()
          .nullable()
          .when("isExcludedClause", {
            is: false,
            then: (scheme) => scheme.integer().required(required).min(0, minNumber0),
          }),
        [f.disability]: Yup.number()
          .nullable()
          .when("isExcludedClause", {
            is: false,
            then: (scheme) => scheme.integer().required(required).min(0, minNumber0),
          }),
        [f.terminationWithCause]: Yup.number()
          .nullable()
          .when("isExcludedClause", {
            is: false,
            then: (scheme) => scheme.integer().required(required).min(0, minNumber0),
          }),
        [f.involuntaryTermination]: Yup.number()
          .nullable()
          .when("isExcludedClause", {
            is: false,
            then: (scheme) => scheme.integer().required(required).min(0, minNumber0),
          }),
        [f.voluntaryTermination]: Yup.number()
          .nullable()
          .when("isExcludedClause", {
            is: false,
            then: (scheme) => scheme.integer().required(required).min(0, minNumber0),
          }),
        [f.byPassingAway]: Yup.number()
          .nullable()
          .when("isExcludedClause", {
            is: false,
            then: (scheme) => scheme.integer().required(required).min(0, minNumber0),
          }),
        //documentDetailsSchema
        [f.documentStatusId]: Yup.number().min(1, required).required(required),
        [f.fileAgreement]: Yup.object().when(f.documentStatusId, {
          is: (documentValue: number) => +documentValue === PlanDocumentStatusEnum.UPLOAD,
          then: Yup.object().shape({
            newFile: Yup.mixed().when("oldFile", {
              is: (oldFile: any) => !oldFile,
              then: Yup.mixed().required(required),
            }),
          }),
        }),
        [f.managerId]: Yup.number().required(required).typeError(required),
      }),
    [
      fNumber,
      integer,
      invalidEmail,
      maxCharacters100,
      minNumber0,
      minNumber000001,
      minNumber1,
      minimumMilestones,
      required,
      vestingPeriod,
    ]
  );
};

export const usePlanValidationSteps = (
  step: "planReceiver" | "sharesSource" | "sharesDetails" | "vestingConditions" | "exerciseClause" | "summary"
) => {
  const validationSchema = useOneOffPlanValidation();
  return useCallback(() => {
    switch (step) {
      case "planReceiver":
        return validationSchema.pick([
          f.firstName,
          f.lastName,
          f.email,
          f.isCompanyOwned,
          f.companyName,
          f.relationshipTypeId,
          f.reason,
        ]);
      case "sharesSource":
        return validationSchema.pick([f.poolId, f.sharesFromStakeholderId]);
      case "sharesDetails":
        return validationSchema.pick([
          f.numberOfShares,
          f.purchasePrice,
          f.exercisePrice,
          f.optionsExpiration,
          f.managerId,
        ]);
      case "vestingConditions":
        return validationSchema.pick([
          f.vestingStartsAt,
          f.allowAcceleratedVesting,
          f.vestingPeriod,
          f.vestingInterval,
          f.vestingCliff,
          f.timeVestedShares,
          f.conditions,
        ]);
      case "exerciseClause":
        return validationSchema.pick([
          f.retirement,
          f.disability,
          f.terminationWithCause,
          f.involuntaryTermination,
          f.voluntaryTermination,
          f.byPassingAway,
        ]);
      case "summary":
        return validationSchema.pick([f.fileAgreement, f.email, f.organizationNumber]);
    }
  }, [step, validationSchema]);
};
