import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import StakeholderSearch from "common/components/molecules/StakeholderSearch/StakeholderSearch";
import { StakeholderFormValues } from "common/components/molecules/StakeholderSection/Stakeholder.types";
import { useStoreState } from "store/store";

import { AvailableShareClass, SellerDetails as InitialSeller } from "../../../../types";
import { BuySellFields } from "../../buy-sell/form-fields";
import { ShareDetailsFieldsType } from "../shares-details/types";
import { TransactionDetailsFieldsType } from "../transaction-details/types";
import { SellerDetailsFields } from "./form-fields";
import SellerCard from "./seller-card";
import ShareClassesList from "./share-classes-list";
import classes from "./styles.module.scss";
import { SellerDetailsFieldsType } from "./types";

type PropsTypes = {
  setSelectedShareClass: (shareClass: AvailableShareClass | null) => void;
  selectedShareClassId: number | null;
  isDisabled?: boolean;
  initialSeller?: InitialSeller;
};

const SellerDetails: FC<PropsTypes> = ({ setSelectedShareClass, selectedShareClassId, isDisabled, initialSeller }) => {
  const { companyId } = useStoreState((state) => state.activeCompanyModel);
  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext<
    SellerDetailsFieldsType & TransactionDetailsFieldsType & ShareDetailsFieldsType & { transactionId: number }
  >();
  const [seller, setSeller] = useState<any | null>(initialSeller || null);

  const handleRemoveSeller = useCallback(() => {
    setFieldValue(SellerDetailsFields.sellerStakeholderId, undefined);
    setFieldValue(BuySellFields.numberOfShares, 0);
    setFieldValue(BuySellFields.purchasePrice, 0);
    setSeller(null);
    setSelectedShareClass(null);
  }, [setFieldValue, setSelectedShareClass]);

  const onSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>, seller?: StakeholderFormValues) => {
      if (e.target.value) {
        setFieldValue(SellerDetailsFields.sellerStakeholderId, seller?.stakeholderId);
        setFieldValue(BuySellFields.numberOfShares, 0);
        setFieldValue(BuySellFields.purchasePrice, 0);
        setSeller({
          stakeholderId: seller?.stakeholderId,
          firstName: seller?.firstName,
          lastName: seller?.lastName,
          isCompanyOwned: seller?.isCompanyOwned,
          companyName: seller?.companyName,
          // avatarFilePath: seller.avatarFilePath,
        });
      } else {
        handleRemoveSeller();
      }
    },
    [handleRemoveSeller, setFieldValue]
  );

  useEffect(() => {
    if (!values.sellerStakeholderId) {
      setSeller(null);
      setSelectedShareClass(null);
      setFieldValue(BuySellFields.numberOfShares, 0);
      setFieldValue(BuySellFields.purchasePrice, 0);
    }
  }, [setFieldValue, setSelectedShareClass, values.sellerStakeholderId]);

  return (
    <div data-testid="seller-details-from-id">
      <StakeholderSearch
        companyId={companyId || 0}
        name="sellerStakeholderId"
        selectedValue={values.sellerStakeholderId}
        error={errors.sellerStakeholderId}
        handleSelect={onSelect}
        isTouched={touched.sellerStakeholderId}
        isDisabled={isDisabled}
        onBlur={handleBlur}
      />
      {seller && (
        <div className={cn("p-3 mt-1", classes.sellerBackground)}>
          <SellerCard seller={seller} onRemoveSeller={handleRemoveSeller} />
          <ShareClassesList
            setSelectedShareClass={setSelectedShareClass}
            selectedShareClassId={selectedShareClassId}
            editingTransactionId={values.transactionId}
          />
        </div>
      )}
    </div>
  );
};

export default SellerDetails;
