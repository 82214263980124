import { FC, useMemo } from "react";
import cn from "classnames";
import { useFormikContext } from "formik";

import { VestingTypesEnum } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CheckIcon, WarningIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./PlanAllocationMessage.module.scss";

const t = createTranslation(
  TranslationNS.common,
  "program.components.programForm.vestingDetails.planAllocationMessage"
);

type PlanAllocationValues = {
  percentageOfIndividualPlanShares: number;
  vestingTypeId: VestingTypesEnum;
  vestingConditions: {
    percentageOfIndividualPlanShares: number;
  }[];
};
const PlanAllocationMessage: FC = () => {
  const { values, touched } = useFormikContext<PlanAllocationValues>();
  const fNumber = useFormatNumbers();

  const planAllocationLeft = useMemo<number>(
    () =>
      100 -
      (values.vestingConditions?.reduce(
        (prev, { percentageOfIndividualPlanShares }) => prev + percentageOfIndividualPlanShares,
        0
      ) || 0) -
      (values.percentageOfIndividualPlanShares || 0),
    [values.percentageOfIndividualPlanShares, values?.vestingConditions]
  );

  const planAllocationMessageState = useMemo<"success" | "error" | false>(() => {
    if (
      values.vestingTypeId &&
      +values.vestingTypeId !== VestingTypesEnum.MILESTONE_VESTING &&
      +values.vestingTypeId !== VestingTypesEnum.COMBINE_VESTING
    ) {
      return false;
    }

    if (
      (Array.isArray(touched.vestingConditions)
        ? touched.vestingConditions.some(({ percentageOfIndividualPlanShares }) => percentageOfIndividualPlanShares)
        : touched.vestingConditions) ||
      // @ts-ignore
      touched.percentageOfIndividualPlanShares
    ) {
      return planAllocationLeft === 0 ? "success" : "error";
    }

    return false;
    // @ts-ignore
  }, [planAllocationLeft, touched.percentageOfIndividualPlanShares, touched.vestingConditions, values.vestingTypeId]);

  if (!planAllocationMessageState) {
    return null;
  }

  return (
    <div className={cn("p-2 mb-5", classes["plan-allocation-message"], classes[planAllocationMessageState])}>
      {planAllocationMessageState === "success" ? (
        <CheckIcon className="icon-size-3 me-3" />
      ) : (
        <WarningIcon className="icon-size-3 me-3" />
      )}

      <span>
        {t.el(
          planAllocationMessageState === "success" ? "success" : planAllocationLeft > 0 ? "missing" : "overweight",
          { values: { percentage: fNumber(planAllocationLeft, "percent") } }
        )}
      </span>
    </div>
  );
};

export default PlanAllocationMessage;
