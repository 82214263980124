import { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { getPath } from "app/Router/RouterHelper";
import { Spinner } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";

import { Stakeholder } from "../components/receive.types";

export type StakeholderGetDTO = {
  companyName: string;
  managerName: string;
  stakeholder: Stakeholder;
};
const StakeholderReceiver = () => {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { invitedUser } = useStoreState((state) => state);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const acceptInvite = useCallback(async () => {
    try {
      await axios.post("/api/ownership/stakeholder/accept-invitation", {
        approvalKey: invitedUser.invitedUser?.approvalKey,
      });
      setInvitedUser(null);

      navigate(getPath(["myPortfolio", "portfolio"]), { replace: true });
    } catch (err) {
      console.log(err);
      navigate(getPath(["user", "dashboard"]), { replace: true });
    }
  }, [invitedUser, setInvitedUser, navigate]);

  useEffect(() => {
    if (ref.current) {
      acceptInvite();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <span ref={ref}>
      <Spinner />
    </span>
  );
};

export default StakeholderReceiver;
