import { FC } from "react";

import { ShareholderPostDTO } from "store/modelTypes";

import { RelationshipTypesEnum } from "../../../enums/enum";
import { DropdownPropsNew } from "../../atoms/Dropdown/Dropdown";
import { TextFieldProps } from "../../atoms/TextField/TextField";

export type StakeholderFormValues = Pick<
  ShareholderPostDTO,
  | "firstName"
  | "lastName"
  | "email"
  | "isCompanyOwned"
  | "companyName"
  | "organizationNumber"
  | "relationshipTypeId"
  | "businessEmail"
  | "dateOfBirth"
  | "address"
  | "countryId"
  | "businessPostAddress"
  | "phoneNumber"
  | "stakeholderId"
> & {};

export type StakeholderField = {
  key: keyof StakeholderFormValues;
  Component: FC<TextFieldProps>;
  meta: TextFieldProps | Omit<DropdownPropsNew, "onChange" | "options">;
  isTopField?: boolean;
};

export type StakeholderFieldsParamsType = {
  [key in keyof StakeholderFormValues]?: { isTopField: boolean; meta: TextFieldProps };
};

export type SearchStakeholderResult = { shareholders: StakeholderFormValues[] };

export const initialEmptyValues = {
  address: "",
  businessEmail: "",
  businessPostAddress: "",
  companyName: "",
  dateOfBirth: null,
  email: "",
  firstName: "",
  isCompanyOwned: false,
  lastName: "",
  organizationNumber: "",
  relationshipTypeId: RelationshipTypesEnum.EMPLOYEE,
  countryId: undefined,
  phoneNumber: "",
  stakeholderId: undefined,
  subscriptionAvailable: undefined,
};

export const updatedStakeholderFields: (keyof StakeholderFormValues)[] = [
  "stakeholderId",
  "firstName",
  "lastName",
  "email",
  "dateOfBirth",
  "address",
  "phoneNumber",
  "countryId",
  "isCompanyOwned",
  "companyName",
  "organizationNumber",
  "businessPostAddress",
  "relationshipTypeId",
  "phoneNumber",
];

export enum fields {
  firstName = "firstName",
  lastName = "lastName",
  isCompanyOwned = "isCompanyOwned",
  phoneNumber = "phoneNumber",
  dateOfBirth = "dateOfBirth",
  address = "address",
  countryId = "countryId",
  companyName = "companyName",
  organizationNumber = "organizationNumber",
  email = "email",
  relationshipTypeId = "relationshipTypeId",
  businessPostAddress = "businessPostAddress",
  stakeholderId = "stakeholderId",
}
