import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import { format } from "date-fns";
import { equals, isEmpty, isNil } from "ramda";

import { CompanyFeatures, FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import {
  Button,
  ContextMenu,
  ContextMenuProps,
  DatePicker,
  LinkHelpText,
  ToggleSwitch,
  Ui,
} from "common/components/atoms";
import SearchField from "common/components/atoms/SearchField/SearchField";
import { CapTableGroupByOptions } from "common/enums/enum";
import useDebounce from "common/hooks/useDebounce";
import {
  CalendarIcon,
  ChevronDownIcon,
  CloseIcon,
  EquityIcon,
  ResetIcon,
  ShareClassesIcon,
  ShareholdersRelationshipIcon,
  UploadIcon,
  UsersIcon,
} from "common/icons/svg";
import { downloadExcelFile } from "common/utils/download";
import { createTranslation, TranslationNS } from "translation";

import { CapTableVariantsType } from "../../CapTable";
import Valuation from "../../FullyDiluted/Valuation/Valuation";
import CapTableStore from "../../store";
import classes from "./CaptableActions.module.scss";

const t = createTranslation(TranslationNS.pages, "company.capTable.actions");

const CURRENT_SELECTED_TIME = new Date();

type PropsTypes = {
  type: CapTableVariantsType;
  setHowCalculateModalOpen?: (val: boolean) => void;
  setCapTableVariant: (val: CapTableVariantsType) => void;
} & JSX.IntrinsicElements["div"];

const companyFeatures = [CompanyFeatures.Growth];

const CapTableActions: FC<PropsTypes> = ({
  setHowCalculateModalOpen,
  setCapTableVariant,
  type = "Actual",
  className,
  ...props
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const capTableDilutedAccess = useFeatures(FEATURES.fullyDilutedCapTable, companyFeatures);

  // internal search value to use it in debounce function
  const [search, setSearch] = useState<string>("");
  const [valuation, setValuation] = useState<string>("");

  const isDiluted = equals(type, "Diluted");

  const dilutedData = CapTableStore.useStoreState((state) => state.dilutedData);
  const oldestTransactionDate = CapTableStore.useStoreState(
    (state) => state.actualData?.capTableSummary?.oldestTransactionDate || "1950-01-01T12:00:00"
  );
  const selectedDateForActualCapTable = CapTableStore.useStoreState((state) => state.selectedDateForActualCapTable);
  const selectedGroup = CapTableStore.useStoreState((state) =>
    isDiluted ? state.selectedDilutedGroup : state.selectedActualGroup
  );
  const setSelectedGroupAction = CapTableStore.useStoreActions((actions) =>
    isDiluted ? actions.setSelectedDilutedGroup : actions.setSelectedActualGroup
  );
  const setSearchValue = CapTableStore.useStoreActions((actions) => actions.setSearchValue);
  const setSelectedDateForActualCapTable = CapTableStore.useStoreActions(
    (actions) => actions.setSelectedDateForActualCapTable
  );

  const debouncedValue = useDebounce(search, 1000);

  const groupByOptions = useMemo(() => {
    const options: ContextMenuProps["items"] = [
      {
        key: String(CapTableGroupByOptions.default),
        forceHideDropdown: true,
        icon: <CloseIcon />,
        selected: equals(selectedGroup, CapTableGroupByOptions.default),
        label: "None",
        color: "red",
        onClick: () => {
          setSelectedGroupAction(CapTableGroupByOptions.default);
        },
      },
      {
        key: String(CapTableGroupByOptions.shareholder),
        icon: <UsersIcon />,
        forceHideDropdown: true,
        selected: equals(selectedGroup, CapTableGroupByOptions.shareholder),
        label: isDiluted ? t("groupBy.options.stakeholder") : t("groupBy.options.shareholder"),
        color: "red",
        onClick: () => {
          setSelectedGroupAction(CapTableGroupByOptions.shareholder);
        },
      },
      {
        key: "relationship",
        forceHideDropdown: true,
        icon: <ShareholdersRelationshipIcon />,
        selected: equals(selectedGroup, CapTableGroupByOptions.relationship),
        label: t("groupBy.options.relationship"),
        onClick: () => {
          setSelectedGroupAction(CapTableGroupByOptions.relationship);
        },
      },
      {
        key: "shareClass",
        icon: <ShareClassesIcon />,
        selected: equals(selectedGroup, CapTableGroupByOptions.shareClass),
        forceHideDropdown: true,
        label: t("groupBy.options.shareClass"),
        onClick: () => {
          setSelectedGroupAction(CapTableGroupByOptions.shareClass);
        },
      },
    ];

    if (isDiluted) {
      options.push({
        key: "instrument",
        forceHideDropdown: true,
        icon: <EquityIcon />,
        label: t("groupBy.options.instrument"),
        selected: equals(selectedGroup, CapTableGroupByOptions.instrument),
        onClick: () => {
          setSelectedGroupAction(CapTableGroupByOptions.instrument);
        },
      });
    }

    return options;
  }, [isDiluted, selectedGroup, setSelectedGroupAction]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, []);

  const handleExport = useCallback(async () => {
    downloadExcelFile(
      isDiluted
        ? `/api/export/fully-diluted/${companyId}/${
            isEmpty(valuation) ? dilutedData.capTableSummary.valuation : valuation
          }`
        : `/api/export/captable/${companyId}/${selectedGroup}/${format(
            selectedDateForActualCapTable ? new Date(selectedDateForActualCapTable) : new Date(),
            "yyyy-MM-dd HH:mm"
          )}`,
      isDiluted ? "Fully diluted capTableData" : "CapTable data"
    );
  }, [
    companyId,
    dilutedData.capTableSummary.valuation,
    isDiluted,
    selectedDateForActualCapTable,
    selectedGroup,
    valuation,
  ]);

  const handleCapTableVariant = useCallback(() => {
    setCapTableVariant(type === "Actual" ? "Diluted" : "Actual");
  }, [setCapTableVariant, type]);

  const handleDatePickerChange = useCallback(
    (e: string | Date) => {
      setSelectedDateForActualCapTable(new Date(e));
    },
    [setSelectedDateForActualCapTable]
  );

  const handleDateSelectionReset = useCallback(() => {
    setSelectedDateForActualCapTable(null);
  }, [setSelectedDateForActualCapTable]);

  const handleHowCalculateModal = useCallback(() => {
    setHowCalculateModalOpen?.(true);
  }, [setHowCalculateModalOpen]);

  useEffect(() => {
    setSearchValue(debouncedValue);
  }, [debouncedValue, setSearchValue]);

  useEffect(() => {
    setSearch("");
  }, [selectedGroup]);

  let contentText = "";

  if (!capTableDilutedAccess.hasSubscriptionAccess) {
    contentText = "You need a paid subscription to view your fully diluted cap table.";
  } else if (!capTableDilutedAccess.hasViewAccess && !capTableDilutedAccess.hasFullAccess) {
    contentText = "Contact your manager for access";
  }

  return (
    <div className={cn(className, "d-flex")} {...props}>
      <LinkHelpText
        title=""
        content={!capTableDilutedAccess.hasSysAdminAccess ? contentText : ""}
        placement="top"
        className="d-flex align-items-center ms-2 me-3"
      >
        <div className="d-flex align-items-center">
          <ToggleSwitch
            checked={isDiluted}
            className={classes["switch"]}
            disabled={
              !capTableDilutedAccess.hasSysAdminAccess &&
              (!capTableDilutedAccess.hasViewAccess || !capTableDilutedAccess?.hasSubscriptionAccess)
            }
            onChange={handleCapTableVariant}
          />
          <Ui.s className="fw-500" color={isDiluted ? "foregroundHigh" : "foregroundLow"}>
            {t("fullyDiluted")}
          </Ui.s>
        </div>
      </LinkHelpText>

      {!isDiluted ? (
        <div className="d-flex align-items-center">
          <LinkHelpText title="" content="Pick a date to explore cap table at that point in time." placement="top">
            <div className="me-1">
              <DatePicker
                isDateOnlyString
                isWithTimeSelect
                className={cn(classes["date-picker-container"], {
                  [classes["today"]]: isNil(selectedDateForActualCapTable),
                })}
                CustomDatePickerInput={
                  isNil(selectedDateForActualCapTable) ? (
                    <div className={cn("px-2", classes["date-picker-container"])}>
                      <Ui.m>Today</Ui.m> <CalendarIcon className="ms-1" fontSize={24} />
                    </div>
                  ) : undefined
                }
                date={selectedDateForActualCapTable ? new Date(selectedDateForActualCapTable) : CURRENT_SELECTED_TIME}
                onChange={handleDatePickerChange}
                minDate={new Date(oldestTransactionDate)}
              />
            </div>
          </LinkHelpText>

          {!isNil(selectedDateForActualCapTable) ? (
            <Button size="s" variant="secondary" iconLeft={<ResetIcon />} onClick={handleDateSelectionReset}>
              Today
            </Button>
          ) : null}
        </div>
      ) : null}

      <SearchField
        value={search}
        placeholder={t("search.placeholder")}
        onChange={handleChange}
        wrapperClassName="ms-3"
      />

      {isDiluted ? <Valuation valuation={valuation} setValuation={setValuation} /> : null}

      <div className="d-flex ms-auto mt-1">
        {isDiluted ? (
          <Button size="s" variant="tertiary" onClick={handleHowCalculateModal}>
            How did we calculate this?
          </Button>
        ) : null}

        <ContextMenu items={groupByOptions} chevronAnimation drop="down-centered">
          <Button
            size="s"
            variant="tertiary"
            className={classes["context-button"]}
            iconRight={<ChevronDownIcon className="chevron ms-half" />}
          >
            {t("groupBy.label")}
          </Button>
        </ContextMenu>

        <Button
          size="s"
          variant="tertiary"
          className={cn(classes["export-btn"], "ms-1")}
          iconRight={<UploadIcon className="ms-half" />}
          onClick={handleExport}
        >
          Export
        </Button>
      </div>
    </div>
  );
};

export default CapTableActions;
