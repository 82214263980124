import { FC, useCallback, useEffect } from "react";
import { Formik, FormikConfig } from "formik";

import { SlidePanel } from "common/components/atoms";
import { ProgramStatuses } from "common/types/Program";
import { useStoreActions, useStoreState } from "store/store";

import useProgramForm, { CreateProgramFormData } from "../../../wizards/create-program/useProgramForm";
import useProgramFormValidation from "../../../wizards/create-program/useProgramFormValidation";
import EditProgramFormContent from "./EditProgramFormContent";

export type ContentProps = {
  programId?: number;
  onClose: (isAfterSave?: boolean) => void;
};

const EditProgramForm: FC<ContentProps> = ({ programId, onClose }) => {
  const { isGetProgramLoading } = useStoreState((state) => state.programModel);
  const program = useStoreState((state) => state.programModel.program);
  const { getProgramThunk, setProgramThunk, approveProgramThunk } = useStoreActions((actions) => actions.programModel);

  const submitHandler = useCallback<FormikConfig<CreateProgramFormData>["onSubmit"]>(
    async ({ submitter, isExcludedClause, ...values }) => {
      try {
        if (programId) {
          await setProgramThunk({
            ...values,
            id: programId,
            excludePostTerminationClauses: isExcludedClause,
          });
          if (submitter === "approve") {
            await approveProgramThunk({
              id: programId,
              documentStatusId: values.documentStatusId,
              approvalDate: values.approvalDate,
            });
          }
        }
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    },
    [approveProgramThunk, onClose, programId, setProgramThunk]
  );

  const { initialValues } = useProgramForm(program);
  const { validationSchema } = useProgramFormValidation();

  useEffect(() => {
    if (programId) {
      getProgramThunk(programId).then();
    }
  }, [getProgramThunk, programId]);

  return (
    <SlidePanel.Body isLoading={isGetProgramLoading}>
      <SlidePanel.Header
        isEdit
        isDraft={program?.ownershipProgramStatusId === ProgramStatuses.draft}
        title={program?.name || ""}
        onHide={onClose}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
        enableReinitialize
      >
        <EditProgramFormContent />
      </Formik>
    </SlidePanel.Body>
  );
};

export default EditProgramForm;
