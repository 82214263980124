import { FC, memo, useCallback } from "react";

import Button from "common/components/atoms/Button/Button";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CreatePlanStore from "../../../store";
import classes from "./RemovePlan.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans.deletePlan");

type RemovePlanProps = {
  programId: string;
};
const RemovePlan: FC<RemovePlanProps> = ({ programId }) => {
  const deletePlanInfo = CreatePlanStore.useStoreState((state) => state.deletePlanInfo);
  const { setDeletePlanInfo } = CreatePlanStore.useStoreActions((actions) => actions);

  const { setDeletePlanThunk } = useStoreActions((actions) => actions.planModel);
  const loading = useStoreState((state) => state.planModel.isPostPlanLoading);

  const { getProgramThunk } = useStoreActions((actions) => actions.programModel);

  const handleClose = useCallback(() => {
    setDeletePlanInfo(null);
  }, [setDeletePlanInfo]);

  const handleRemove = useCallback(async () => {
    if (deletePlanInfo?.planId) {
      const res = await setDeletePlanThunk(deletePlanInfo?.planId);
      if (res) {
        getProgramThunk(+programId).then();
        notify(t("planRemoved"), true, "success");
        handleClose();
      }
    }
  }, [deletePlanInfo?.planId, setDeletePlanThunk, getProgramThunk, programId, handleClose]);

  return (
    <ModalInfo
      className={classes.removePlan}
      header={t("title", { name: deletePlanInfo?.stakeholderName })}
      show={!!deletePlanInfo}
      handleClose={handleClose}
      footer={
        <div>
          <Button isLoading={loading} isDisabled={loading} variant="danger" className="me-2" onClick={handleRemove}>
            {t("remove")}
          </Button>
          <Button isLoading={loading} isDisabled={loading} variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      }
    >
      <div className="mt-3">{t("description")}</div>
    </ModalInfo>
  );
};

export default memo(RemovePlan);
