import { FC, useMemo } from "react";
import Highlighter from "react-highlight-words";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import { CommonFileIcon, DeleteIcon, EyeOpenIcon, MenuTabBarVerticalIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { openRemoteDocument, transformDateToCommonDateFormat } from "common/utils/functions";

import DocumentsContext, { SingleCommonDocumentType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";

const NestedTableRow: FC<{ data: SingleCommonDocumentType }> = ({ data }) => {
  const { hasFullAccess } = useFeatures(FEATURES.documents);

  const { searchValue } = DocumentsContext.useStoreState((state) => state);
  const { setIsLoading, setRemovalDocumentId } = DocumentsContext.useStoreActions((actions) => actions);

  const items = useMemo<ContextMenuProps["items"]>(
    () => [
      {
        label: "View",
        key: "view",
        icon: <EyeOpenIcon />,
        onClick: async () => {
          try {
            setIsLoading(true);

            await openRemoteDocument(data.downloadId);
          } finally {
            setIsLoading(false);
          }
        },
      },
      {
        label: "Delete",
        key: "delete",
        type: "delete",
        isDisabled: !hasFullAccess,
        icon: <DeleteIcon color={scssVariables.critical500} />,
        onClick: () => {
          setRemovalDocumentId(data.companyFileId);
        },
      },
    ],
    [data.companyFileId, data.downloadId, hasFullAccess, setIsLoading, setRemovalDocumentId]
  );

  return (
    <tr className={classes["table-row"]}>
      <td className={classes.nested}>
        <div className="d-flex">
          <CommonFileIcon className="me-3" />

          <Highlighter
            autoEscape={true}
            textToHighlight={data.fileName}
            searchWords={[searchValue as string]}
            highlightClassName={classes.highligted}
            unhighlightClassName={classes.unhighlited}
          />
        </div>

        <Ui.s className="fw-400 ms-auto me-5" style={{ color: scssVariables.foregroundLow }}>
          {transformDateToCommonDateFormat(data.createdAt)}
        </Ui.s>

        <ContextMenu items={items} drop="down">
          <Button isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn" className={classes["menu-button"]}>
            <MenuTabBarVerticalIcon />
          </Button>
        </ContextMenu>
      </td>
    </tr>
  );
};

export default NestedTableRow;
