import { FC, useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import Signature from "common/components/atoms/Signature/Signature";
import { H, Ui } from "common/components/atoms/Typography";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./SignatureCollapsible.module.scss";

const t = createTranslation(TranslationNS.common, "commonComponentsForDifferentModules.signatureCollapsible");

type SignatureCollapsableProps = {
  signature: File | null;
  currentStep?: number;
  setSignature: (value: File | null) => void;
  title?: string;
  subtitle?: string;
  noticeText?: string;
};

const SignatureCollapsable: FC<SignatureCollapsableProps> = ({
  signature,
  currentStep,
  setSignature,
  title,
  subtitle,
  noticeText,
}) => {
  const headRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [showSignature, setShowSignature] = useState<boolean>(false);

  const renderHead = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div
          ref={headRef}
          className={classNames(classes["head"], {
            [classes["open"]]: Boolean(activeEventKey),
          })}
          onClick={(e) => {
            onClick(e);
            setIsOpen((prev) => !prev);
          }}
        >
          <div className={classes["title"]}>
            <span className={classNames(classes["point"], "ui-m")}>{currentStep ? `${currentStep}.` : "3."}</span>
            <H.xxxs>{title || t("title")}</H.xxxs>
          </div>
          <Ui.xs className={classNames(classes["subtitle"], "mt-1")}>{subtitle || t("subtitle")}</Ui.xs>
        </div>
      );
    },
    [currentStep, subtitle, title]
  );

  useEffect(() => {
    if (isOpen) {
      const timerID = setTimeout(() => {
        setShowSignature(true);

        clearTimeout(timerID);
      }, 200);
    }
  }, [isOpen]);

  return (
    <CollapsibleContainer
      defaultOpen
      withoutDivider
      className={classNames(classes["container"], {
        [classes["open"]]: isOpen,
        [classes["active"]]: signature && isOpen,
      })}
      Header={renderHead}
    >
      <div className={classes["content"]}>
        {showSignature && (
          <Signature
            onSubmit={(e) => {
              setSignature(e as File | null);
            }}
          />
        )}

        <Ui.xs className="mt-1" style={{ color: scssVariables.foregroundMedium }}>
          {noticeText || t("agreements")}
        </Ui.xs>
      </div>
    </CollapsibleContainer>
  );
};

export default SignatureCollapsable;
