import { FC, useMemo } from "react";

import { ACCESS_LEVELS } from "common/access-control/types";
import Tag, { TagVariant } from "common/components/atoms/Tag/Tag";
import { createTranslation, TranslationNS } from "translation";

import { FeatureCategory } from "../../../equity-management/settings/access-control/types";

type BodyPropsTypes = {
  featuresCategory: FeatureCategory;
};

const t = createTranslation(TranslationNS.pages, "companySettings.acceptInvitation.accessTable.accessLevel");

const TableBody: FC<BodyPropsTypes> = ({ featuresCategory }) => {
  const accessLevelNames: Record<number, { title: string; color: TagVariant }> = useMemo(
    () => ({
      [ACCESS_LEVELS.NO_ACCESS]: {
        title: t("noAccess"),
        color: "draft",
      },
      [ACCESS_LEVELS.VIEW]: {
        title: t("viewOnly"),
        color: "description",
      },
      [ACCESS_LEVELS.FULL]: {
        title: t("full"),
        color: "complete",
      },
    }),
    []
  );

  return (
    <tbody>
      {Object.entries(featuresCategory).map(([featureId, feature]) => (
        <tr key={featureId}>
          <td>
            <div className="ms-3">{feature.featureName}</div>
          </td>
          <td>
            <Tag variant={accessLevelNames[feature.accessLevel].color}>
              {accessLevelNames[feature.accessLevel].title}
            </Tag>
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
