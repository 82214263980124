import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import cn from "classnames";

import { NewAvatar } from "common/components/atoms";
import Tag from "common/components/atoms/Tag/Tag";
import { Ui } from "common/components/atoms/Typography";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { CommonFileIcon } from "common/icons/svg";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../../IssueEquityContext";
import classes from "../ConvertNotePanel.module.scss";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertNote.info");

const Info: FC = () => {
  const fNumber = useFormatNumbers();

  const conversionDetails = IssueEquityContext.useStoreState((state) => state.conversionDetailsCN);
  useToastFormikValidator();

  return (
    <div className={cn(classes.info, "mt-7")}>
      <div className={classes.userInfo}>
        <div className={classes.userAvatar}>
          <NewAvatar
            size="m"
            company={!!conversionDetails?.stakeholderCompanyName}
            firstName={conversionDetails?.firstName}
            lastName={conversionDetails?.lastName}
            imageUrl={conversionDetails?.imageUrl}
            companyName={conversionDetails?.stakeholderCompanyName}
          />
          <div className="ms-1">
            <Ui.xl bold>{`${conversionDetails?.firstName} ${conversionDetails?.lastName}`}</Ui.xl>
            <Ui.s className={classes.company}>{conversionDetails?.stakeholderCompanyName}</Ui.s>
          </div>
        </div>
        <div>
          <Tag variant="closed">{t("convertibleNote")}</Tag>
        </div>
      </div>
      <div className="mt-5">
        <Row>
          <Col md={4}>{t("investmentAmount")}</Col>
          <Col className="bold">{fNumber(conversionDetails?.investmentAmount, "amount")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("interestRate")}</Col>
          <Col className="bold">{fNumber(conversionDetails?.interestRate, "percent")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("triggerAmount")}</Col>
          <Col className="bold">{fNumber(conversionDetails?.triggerAmount, "amount")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("discount")}</Col>
          <Col className="bold">{fNumber(conversionDetails?.discount, "percent")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("valuationCap")}</Col>
          <Col className="bold">{fNumber(conversionDetails?.valuationCap, "value")}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("shareClass")}</Col>
          <Col className="bold">{conversionDetails?.shareClass || "-"}</Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("agreementDate")}</Col>
          <Col className="bold">
            {conversionDetails?.agreementDate ? transformDateToCommonDateFormat(conversionDetails?.agreementDate) : "-"}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("expirationDate")}</Col>
          <Col className="bold">
            {conversionDetails?.expirationDate
              ? transformDateToCommonDateFormat(conversionDetails?.expirationDate)
              : "-"}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={4}>{t("documentation")}</Col>
          <Col md={8}>
            {conversionDetails?.documentFiles.length
              ? conversionDetails.documentFiles.map((document, index) => (
                  <div
                    className={cn({ "mb-3": conversionDetails.documentFiles.length - 1 !== index }, classes.document)}
                    key={index}
                  >
                    <span>
                      <CommonFileIcon />
                    </span>
                    <a href="#">{document.fileName}</a>

                    {/* <DocumentToggleElement isDeleting={false} el={document} /> */}
                  </div>
                ))
              : "-"}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Info;
