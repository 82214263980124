import { FC } from "react";
import FormControl from "react-bootstrap/FormControl";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import Check from "common/components/atoms/Checks/Check";
import { createTranslation, TranslationNS } from "translation";

import { PoolFormValues } from "../../../../pages/equity-management/plans/wizards/create-pool/usePoolForm";
import InputHeader from "../../../components/atoms/input-blocks/input-header/input-header";
import { DocumentStatusEnum } from "../../../enums/enum";

export type DocumentStatusFieldProps = FormGroupProps;

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.documentStatusField");

export const documentStatuses: DocumentStatusEnum[] = [
  DocumentStatusEnum.REVIEW_LATER,
  DocumentStatusEnum.DOCUMENT_UPLOADED,
  DocumentStatusEnum.NO_DOCUMENT_REQUIRED,
];

const PoolDocumentStatusField: FC<DocumentStatusFieldProps> = (props) => {
  const { values, errors, handleChange } = useFormikContext<Pick<PoolFormValues, "documentStatusId">>();

  return (
    <FormGroup controlId="documentStatusId" {...props}>
      <InputHeader label={t("label")} />
      {documentStatuses.map((status) => (
        <Check
          required={true}
          type="radio"
          key={status}
          id={`documentStatusId-${status}`}
          name="documentStatusId"
          label={t(`options.${status}`)}
          value={status}
          checked={!!values?.documentStatusId && +values?.documentStatusId === status}
          onChange={handleChange}
        />
      ))}
      <FormControl.Feedback type="invalid">{errors.documentStatusId}</FormControl.Feedback>
    </FormGroup>
  );
};

export default PoolDocumentStatusField;
