import { CSSProperties, FC, ReactNode, useCallback, useMemo } from "react";
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from "react-bootstrap";
import cn from "classnames";
import { isEmpty } from "ramda";

import { CloseIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import { P, Ui } from "../Typography";
import classes from "./LinkHelpText.module.scss";

type LinkHelpTextProps = {
  title: string;
  content: ReactNode;
  children?: ReactNode;
  type?: "tooltip" | "modal";
  onClick?: <T>(value?: T) => void;
  className?: string;
  overlayClass?: string;
  style?: CSSProperties;
  tooltipWithTitle?: boolean;
  trigger?: OverlayTriggerProps["trigger"];
  placement?: OverlayTriggerProps["placement"];
  CustomOverlay?: JSX.Element;
  withCloseButton?: boolean;
};

const hideModalTooltipHandler = () => document.body.click();

const LinkHelpText: FC<LinkHelpTextProps> = ({
  title,
  content,
  type = "tooltip",
  onClick,
  className,
  style,
  children,
  tooltipWithTitle,
  trigger = ["hover", "hover"],
  placement = "bottom",
  overlayClass,
  CustomOverlay,
  withCloseButton,
}) => {
  const isModal = useMemo(() => type === "modal", [type]);

  const isEmptyOverlay = isEmpty(title) && isEmpty(content);

  const RenderedOverlayComponent = useCallback(() => {
    if (CustomOverlay) {
      return (
        <Tooltip
          id="link"
          className={cn(classes["tooltip"], {
            [classes["modal"]]: isModal,
            overlayClass,
          })}
        >
          {CustomOverlay}
        </Tooltip>
      );
    }

    if (isModal) {
      return (
        <Tooltip
          id="link"
          className={cn(classes["tooltip"], {
            [classes["modal"]]: isModal,
            overlayClass,
          })}
        >
          <Ui.m bold>{title}</Ui.m>

          <P.s
            className={cn({
              "mt-2": Boolean(title),
            })}
            color="foregroundLow"
          >
            {content}
          </P.s>

          {withCloseButton ? (
            <CloseIcon
              fontSize={20}
              color={scssVariables.foregroundLow}
              className={cn("position-absolute cursor-pointer", classes["close-btn"])}
              onClick={hideModalTooltipHandler}
            />
          ) : null}
        </Tooltip>
      );
    }

    return (
      <Tooltip id="link" className={cn(classes["tooltip"], overlayClass)}>
        {tooltipWithTitle ? (
          <Ui.xs bold className="mb-2" style={{ color: scssVariables.foregroundMedium }}>
            {title}
          </Ui.xs>
        ) : null}

        <Ui.xs style={{ color: scssVariables.foregroundLow }}>{content}</Ui.xs>
      </Tooltip>
    );
  }, [CustomOverlay, isModal, overlayClass, tooltipWithTitle, title, content, withCloseButton]);

  return (
    <OverlayTrigger
      rootClose
      placement={placement}
      trigger={trigger}
      overlay={CustomOverlay ? RenderedOverlayComponent() : isEmptyOverlay ? () => <></> : RenderedOverlayComponent()}
    >
      <span
        className={cn(classes["main-container"], className)}
        style={style}
        onClick={(e) => {
          e.preventDefault();

          if (onClick) {
            onClick();
          }
        }}
      >
        {children}
      </span>
    </OverlayTrigger>
  );
};

export default LinkHelpText;
