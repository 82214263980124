import { FC, useEffect, useState } from "react";
import { Modal as BModal } from "react-bootstrap";

type PropsTypes = {
  programId: number | null;
  onClose: () => void;
};

const Modal: FC<PropsTypes> = ({ onClose, programId }) => {
  const [program, setProgram] = useState();
  useEffect(() => {
    // TODO GET PROGRAM
  }, []);

  return (
    <BModal show={true} onHide={onClose}>
      asdasd
    </BModal>
  );
};

const ProgramModal: FC<PropsTypes> = (props) => {
  if (props.programId) {
    return <Modal {...props} />;
  }

  return null;
};

export default ProgramModal;
