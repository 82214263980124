import { FC, useMemo } from "react";
import { ComputedDatum } from "@nivo/pie";
import cn from "classnames";
import * as R from "ramda";

import PieChart from "common/components/atoms/Charts/Pie.chart";
import VerticalBarChart from "common/components/atoms/Charts/VerticalBar.chart";
import { Ui } from "common/components/atoms/Typography";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "../../common/Charts/Charts.module.scss";
import ShareClassesTooltip from "../../common/Charts/ShareClassesTooltip";
import ShareholdersTooltip, { PieDataCustom } from "../../common/Charts/ShareholdersTooltip";
import EmptyBar from "../../common/empty/empty-bar";
import EmptyChart from "../../common/empty/empty-chart";
import CapTableStore from "../../store";

const t = createTranslation(TranslationNS.pages, "company.capTable.charts");

export type ChartsProps = JSX.IntrinsicElements["div"] & Record<string, unknown>;

const colorsPalette = [
  scssVariables.additional11500,
  scssVariables.additional7500,
  scssVariables.additional8500,
  scssVariables.additional9500,
  scssVariables.additional10500,
  scssVariables.additional12500,
  scssVariables.additional2500,
  scssVariables.additional6500,
  scssVariables.additional5500,
  scssVariables.additional13500,
];

const getColor = (index: number): string => colorsPalette[index];

const Charts: FC<ChartsProps> = ({ className, ...props }) => {
  const fNumber = useFormatNumbers();
  const dataStore = CapTableStore.useStoreState((state) => state.actualData);

  const shareholdersTotal = useMemo(
    () => dataStore.capTable.reduce((curr, { shares }) => curr + shares, 0),
    [dataStore.capTable]
  );

  const shareholders: PieDataCustom[] = useMemo(() => {
    return dataStore.topStakeholders.map((item, index) => {
      return {
        id: item.stakeholderId.toString(),
        value: item.percentage,
        color: getColor(index),
        label: item.name,
        amount: item.numberOfShares,
      };
    });
  }, [dataStore.topStakeholders]);

  const relationship = useMemo(() => {
    if (!R.isNil(dataStore.relationshipOverview) && !R.isEmpty(dataStore.relationshipOverview)) {
      const relationship = dataStore.relationshipOverview.map((item) => ({
        id: item.name,
        value: item.percentage,
        color: "",
      }));

      relationship.forEach((item, index) => {
        item.color = getColor(index);
      });

      return relationship.sort((a, b) => a.value - b.value);
    } else {
      return ["Founders", "Employees", "Investor", "Board", "Others"].map((item, index) => {
        const value = 100 - index * 20;
        const emptyColors = [
          scssVariables.foregroundMedium,
          scssVariables.foregroundMedium,
          scssVariables.element1,
          scssVariables.foregroundHigh,
          scssVariables.element1,
        ];

        return { id: item, value, color: emptyColors[index] };
      });
    }
  }, [dataStore.relationshipOverview]);

  const shareClassData = useMemo(() => {
    return dataStore.shareClassDistribution.map((item, index) => ({
      id: item.shareClassName,
      value: item.percentage,
      color: getColor(index),
    }));
  }, [dataStore.shareClassDistribution]);

  return (
    <div className={cn("mt-3", classes["wrap"], className)} {...props}>
      <div className={classes["section"]}>
        {shareholdersTotal === 0 ? (
          <EmptyChart percentage={75} />
        ) : (
          <>
            <div className={classes["pie-chart-wrap"]}>
              <PieChart
                height={110}
                pieThickness={0.7}
                data={shareholders}
                isInteractive={shareholdersTotal > 0}
                tooltip={(props) => (
                  <ShareholdersTooltip pieTooltipData={props.datum as ComputedDatum<PieDataCustom>} />
                )}
              />
            </div>

            <Ui.s className="mt-1" style={{ color: scssVariables.foregroundLow }}>
              {t("shareholders.title")}
            </Ui.s>

            <Ui.l bold className="mt-1" style={{ color: scssVariables.foregroundMedium }}>
              {t("pieChart.total", {
                shares: fNumber(shareholdersTotal, "amount"),
              })}
            </Ui.l>
          </>
        )}
      </div>

      <div className={classes["section"]}>
        {R.isEmpty(dataStore.shareClassDistribution) ? (
          <EmptyChart percentage={90} />
        ) : (
          <>
            <div className={classes["pie-chart-wrap"]}>
              <PieChart
                height={110}
                pieThickness={0.75}
                data={shareClassData}
                tooltip={(props) => <ShareClassesTooltip pieTooltipData={props} />}
                isInteractive={!R.isEmpty(dataStore.shareClassDistribution)}
              />
            </div>

            <Ui.s className="mt-1" style={{ color: scssVariables.foregroundLow }}>
              {t("distribution.title")}
            </Ui.s>

            <Ui.l bold className="mt-1" style={{ color: scssVariables.foregroundMedium }}>
              {t("pieChart.shareClasses", {
                amount: shareClassData.length,
              })}
            </Ui.l>
          </>
        )}
      </div>

      <div className={classes["section"]}>
        {R.isEmpty(dataStore.relationshipOverview) ? (
          <EmptyBar />
        ) : (
          <div style={{ width: 200 }}>
            <VerticalBarChart data={relationship} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Charts;
