import { FC } from "react";
import classNames from "classnames";

import classes from "./MainLayout.module.scss";
import PageContent from "./PageContent/PageContent";
import Sidebar from "./Sidebar/Sidebar";

type MainLayoutComponent = FC<JSX.IntrinsicElements["div"]> & {
  Content: typeof PageContent;
  Sidebar: typeof Sidebar;
};

const MainLayout: MainLayoutComponent = ({ className, children, ...props }) => (
  <div className={classNames(classes["main-layout"], className)} {...props}>
    {children}
  </div>
);

MainLayout.Content = PageContent;
MainLayout.Sidebar = Sidebar;

export default MainLayout;
