import axios, { AxiosResponse } from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

export type CurrentOwnership = {
  companyId: number;
  name: string;
  avatarFilePath?: string;
  initials: string;
  instrumentType: number;
  numberOfShares: number;
  averagePurchasePrice: number;
  sharePrice: number;
  value: number;
  currencySymbol: string;
  profit: boolean;
  profitLossValue: number | null;
  profitLossValuePercentage: number;
  hasAccessToCapTable: boolean;
  companyValuationDate: string;
  companyValuation: number;
  ownershipPercentage: number;
};

export type OwnershipEntities = {
  isCompanyOwned: boolean;
  id: number;
  name: string;
  isActive: boolean;
};
export type SuggestedCompaniesType = {
  companyId: number;
  organizationNumber: string;
  name: string;
  initials: string;
  invitationSent: boolean;
  numberOfShares: number;
  email: string;
  invitedAt: string;
  companyInvitationId: number;
};

export type StockOptionsType = {
  companyId: number;
  name: string;
  initials: string;
  avatarFilePath?: string;
  vestedShares: number;
  numberOfShares: number;
  exercisePrice: number;
  value: number;
  currencySymbol: string;
  profit: boolean;
  profitLossValue: number;
  profitLossPercentage: number;
  hasAccessToCapTable: boolean;
};
export type ConvertiblesType = {
  companyId: number;
  name: string;
  initials: string;
  avatarFilePath?: string;
  instrumentType: number;
  instrumentName: string;
  investmentAmount: number;
  discount: number;
  interestRate: number;
  value: number;
  currencySymbol: string;
  hasAccessToCapTable: boolean;
};

export type WarrantsType = {
  companyId: number;
  name: string;
  initials: string;
  avatarFilePath?: string;
  numberOfShares: number;
  warrantPrice: number;
  exercisePrice: number;
  value: number;
  currencySymbol: string;
  profit: boolean;
  profitLossValue: number;
  profitLossPercentage: number;
  hasAccessToCapTable: boolean;
};

export type MyPortfolioOverviewGetDTO = {
  commonCurrencyId: number;
  currentOwnership: CurrentOwnership[];
  ownershipEntities: OwnershipEntities[];
  suggestedCompanies: SuggestedCompaniesType[];
  stockOptions: StockOptionsType[];
  convertibles: ConvertiblesType[];
  warrants: WarrantsType[];
  investedAmount: number;
  totalProfit: false;
  totalProfitLoss: number;
  totalProfitPercentage: number;
  totalValue: number;
  totalValueInstruments: number;
  totalValueStockOptions: number;
  totalValueStockOptionsPercentage: number;
  totalValueWarrants: number;
  totalValueWarrantsPercentage: number;
  totalValueConvertibles: number;
};

export type PortfolioSinglePlan = {
  id: number;
  name: string;
  companyName?: string;
  instrumentTypeId: number;
  instrumentTypeName: string;
  isCompanyOwned: boolean;
};

type PortfolioParams = {
  isCompanyOwned: boolean;
  id: number;
};

type InviteCompanyData = {
  companyName: string;
  reciverName: string;
  email: string;
  companyInvitationId?: number;
};

interface MyPortfolioContextModel {
  isLoading: boolean;
  isPortfolioLoading: boolean;
  portfolio: MyPortfolioOverviewGetDTO | null;
  userPlans: null | PortfolioSinglePlan[];
  showInviteCompanyModal: boolean;
  inviteCompanyData: null | InviteCompanyData;
  isTransactionsPreviewOpen: boolean;
  previewCompanyData: { companyId: number; companyName: string } | null;
  // actions
  setIsLoading: Action<this, boolean>;
  setPortfolioLoading: Action<this, boolean>;
  setUserPlans: Action<this, PortfolioSinglePlan[] | null>;
  setPortfolio: Action<this, MyPortfolioOverviewGetDTO | null>;
  setShowInviteCompanyModal: Action<this, { show: boolean; data?: InviteCompanyData }>;
  openTransactionsPreview: Action<this, { companyId: number; companyName: string }>;
  closeTransactionsPreview: Action<this>;
  // thanks
  getUserPlansThunk: Thunk<this>;
  getPortfolioThunk: Thunk<this, PortfolioParams[] | null, {}, {}, Promise<AxiosResponse<MyPortfolioOverviewGetDTO>>>;
}

const apiBase = "/api/ownership/ownership-plan";

const MyPortfolioContext = createContextStore<MyPortfolioContextModel>({
  isLoading: false,
  isPortfolioLoading: false,
  portfolio: null,
  userPlans: null,
  showInviteCompanyModal: false,
  inviteCompanyData: null,
  isTransactionsPreviewOpen: false,
  previewCompanyData: null,

  // actions
  setUserPlans: action((state, payload) => {
    state.userPlans = payload;
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setPortfolioLoading: action((state, payload) => {
    state.isPortfolioLoading = payload;
  }),
  setPortfolio: action((state, payload) => {
    state.portfolio = payload;
  }),
  setShowInviteCompanyModal: action((state, payload) => {
    state.showInviteCompanyModal = payload.show;
    state.inviteCompanyData = payload.data || null;
  }),
  openTransactionsPreview: action((state, payload) => {
    state.previewCompanyData = payload;
    state.isTransactionsPreviewOpen = true;
  }),
  closeTransactionsPreview: action((state) => {
    state.previewCompanyData = null;
    state.isTransactionsPreviewOpen = false;
  }),

  //thanks
  getPortfolioThunk: thunk(async (actions, payload) => {
    try {
      actions.setPortfolioLoading(true);

      const request = await axios.post("/api/portfolio", { ownershipEntities: payload });

      actions.setPortfolio(request.data);
      return request;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      actions.setPortfolioLoading(false);
    }
  }),
  getUserPlansThunk: thunk(async (actions) => {
    try {
      actions.setIsLoading(true);

      const request = await axios.get(`${apiBase}/user-plans`);

      if (request.status === 200) {
        actions.setUserPlans(request.data);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
});

export default MyPortfolioContext;
