import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Button from "common/components/atoms/Button/Button";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { H, P } from "common/components/atoms/Typography";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { apiBase } from "../../equity-management/settings/access-control/AccessControl";
import { ReceiveInviteGetDTO } from "../../equity-management/settings/access-control/types";
import FeaturesTable from "./features-table/features-table";
import classes from "./ReceiveAccessControl.module.scss";

const t = createTranslation(TranslationNS.pages, "companySettings.acceptInvitation");

const ReceiveAccessControl: FC = () => {
  const navigate = useNavigate();

  const { invitedUser } = useStoreState((state) => state.invitedUser);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);
  const { setIsDarkTheme, setIsFooterShown, setHeaderContextShown } = useStoreActions((actions) => actions.app);

  const [accessRights, setAccessRights] = useState<ReceiveInviteGetDTO | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAccess = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get<ReceiveInviteGetDTO>(
          `${apiBase}/invitation/${invitedUser?.entityId}/${invitedUser?.approvalKey}`
        );

        if (response.status === 200) {
          setAccessRights(response.data);
        }
      } catch (e) {
        console.log(e);
        navigate("/", { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccess();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsDarkTheme(true);
    setIsFooterShown(false);
    setHeaderContextShown(false);

    return () => {
      setIsDarkTheme(false);
      setIsFooterShown(true);
      setHeaderContextShown(true);
    };
    // eslint-disable-next-line
  }, []);

  const handleAccept = useCallback(async () => {
    try {
      const response = await axios.post(`${apiBase}/invitation`, {
        companyUserId: invitedUser?.entityId,
        approvalKey: invitedUser?.approvalKey,
      });

      if (response.status === 200) {
        notify(t("successNotification"), true, "success");

        setInvitedUser(null);
        navigate("/", { replace: true });
      }
    } catch (e) {
      console.log(e);
    }
  }, [navigate, invitedUser?.approvalKey, invitedUser?.entityId, setInvitedUser]);

  const handleDecline = useCallback(async () => {
    navigate("/", { replace: true });
  }, [navigate]);

  if (isLoading) {
    return (
      <Spinner
        style={{
          borderColor: scssVariables.element1,
          borderRightColor: "transparent",
        }}
      />
    );
  }

  return (
    <PageContent className={classes["container"]} data-testid="receive-warrant-test-id">
      <H.s>{t("title")}</H.s>

      <P.l className="my-6">
        {accessRights?.isAdmin
          ? t.el("descriptionAdmin", {
              tOptions: {
                companyName: accessRights.companyName,
              },
            })
          : t.el("description", {
              tOptions: {
                companyName: accessRights?.companyName,
              },
            })}
      </P.l>

      {accessRights && <FeaturesTable features={accessRights.features} />}

      <div className="mt-6">
        <Button className="me-3" onClick={handleAccept}>
          {t("acceptBtn")}
        </Button>
        <Button variant="secondary" onClick={handleDecline}>
          {t("declineBtn")}
        </Button>
      </div>
    </PageContent>
  );
};

export default ReceiveAccessControl;
