import { FC, useMemo } from "react";
import { useFormikContext } from "formik";

import { Helper, TextField } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
// import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { CreateProgramFormData } from "../useProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram.sharesDetails");

const ProgramNumberOfShares: FC = () => {
  const fNumber = useFormatNumbers();
  // const pools = useStoreState((state) => state.poolModel.companyPools);

  const { values, handleBlur, touched, errors, handleChange } = useFormikContext<CreateProgramFormData>();

  // const selectedPool = useMemo(() => pools.find(({ id }) => id === values.poolId), [pools, values.poolId]);

  /* const numberOfSharesHint = useMemo(() => {
    return selectedPool?.numberOfSharesLeft && values.numberOfShares
      ? t("sharesLeft", {
          shares: fNumber(selectedPool?.numberOfSharesLeft - values.numberOfShares, "amount"),
        })
      : undefined;
  }, [fNumber, selectedPool?.numberOfSharesLeft, values.numberOfShares]);
*/

  const numberOfSharesHint = useMemo(() => {
    return values?.numberOfSharesLeft && values.numberOfShares
      ? t("sharesLeft", {
          shares: fNumber(values?.numberOfSharesLeft - values.numberOfShares, "amount"),
        })
      : undefined;
  }, [fNumber, values.numberOfShares, values?.numberOfSharesLeft]);
  return (
    <Helper.Question
      questionId={"numberOfShares"}
      type="input"
      answer={t(`authorizedShares.answer.${values.programTypeId}`)}
    >
      <TextField
        min={0}
        label={t(`authorizedShares.label.${values.programTypeId}`)}
        type="number"
        className="position-relative"
        isTouched={touched.numberOfShares}
        error={errors.numberOfShares}
        value={values.numberOfShares}
        name="numberOfShares"
        onChange={handleChange}
        onBlur={handleBlur}
        info={numberOfSharesHint}
        withoutInfoIcon
      />
    </Helper.Question>
  );
};
export default ProgramNumberOfShares;
