import {
  boolean as yupBoolean,
  number as yupNumber,
  object as yupObject,
  string as yupString,
} from "yup";

import { participatingCapFieldSettings } from "common/shareClass/components/ShareClassForm/ParticipatingCapField";
import { votingRightsMultipleFieldSettings } from "common/shareClass/components/ShareClassForm/VotingRightsMultipleField";
import { createTranslation, TranslationNS } from "translation";

import { ShareClassTypes } from "./types";

const t = createTranslation(TranslationNS.validation);

export function getShareClassValidationObject() {
  return yupObject({
    name: yupString().required(t("required")),
    purpose: yupString().nullable(),
    shareClassTypeId: yupNumber().required(t("required")),
    hasVotingRights: yupBoolean(),
    votingRightsMultiple: yupNumber()
      .positive()
      .min(
        votingRightsMultipleFieldSettings.min,
        t("minNumber", { number: votingRightsMultipleFieldSettings.min })
      )
      .max(
        votingRightsMultipleFieldSettings.max,
        t("maxNumber", { number: votingRightsMultipleFieldSettings.max })
      )
      .required(t("required")),
    hasDividends: yupBoolean(),
    hasPreferences: yupBoolean(),

    // Preferred
    seniorityLevel: yupNumber()
      .nullable()
      .when("shareClassTypeId", {
        is: ShareClassTypes.preferred,
        then: (scheme) => scheme.positive().required(t("required")),
      }),
    seniorityLevelMultiple: yupNumber()
      .nullable()
      .when("shareClassTypeId", {
        is: ShareClassTypes.preferred,
        then: (scheme) => scheme.positive().required(t("required")),
      }),
    isParticipating: yupBoolean().when("shareClassTypeId", {
        is: ShareClassTypes.preferred,
        then: (scheme) => scheme.required(t("required")),
    }),
    participatingCap: yupNumber()
      .nullable()
      .when("shareClassTypeId", {
        is: ShareClassTypes.preferred,
        then: (scheme) =>
          scheme
            .positive()
            .min(
              participatingCapFieldSettings.min,
              t("minNumber", { number: participatingCapFieldSettings.min })
            )
            .max(
              participatingCapFieldSettings.max,
              t("maxNumber", { number: participatingCapFieldSettings.max })
            )
            .required(t("required")),
      }),
    shareClassAntiDilutionId: yupNumber()
      .nullable()
      /*.when("shareClassTypeId", {
        is: ShareClassTypes.preferred,
        then: (scheme) => scheme.required(t("required")),
      }),*/
  });
}
