import { FC } from "react";
import * as R from "ramda";

import { H, Ui } from "common/components/atoms/Typography";
import MoneyTransferWidget from "common/components/molecules/MoneyTransferWidget/MoneyTransferWidget";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import ReceiveSettleWarrantContext from "../ReceiveSettleWarrantContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.conversion.receiverSide");

const Info: FC = () => {
  const fNumber = useFormatNumbers();
  const { warrantSettlementDetails } = ReceiveSettleWarrantContext.useStoreState((state) => state);

  return (
    <div className="mb-4">
      <Ui.l className="mt-6 mb-3">
        {t.el("description1", {
          values: {
            companyName: warrantSettlementDetails?.companyName,
            shares: fNumber(warrantSettlementDetails?.numberOfShares, "amount"),
            totalPrice: fNumber(warrantSettlementDetails?.amount, "value"),
          },
          components: [<strong key={1} />],
        })}
      </Ui.l>

      <Ui.l className="mt-3 mb-6">{t("description2")}</Ui.l>

      <H.xxxs>{t("paymentInfo")}</H.xxxs>
      <MoneyTransferWidget
        withoutSubmit
        className="mt-3"
        name={R.defaultTo("", warrantSettlementDetails?.companyName)}
        swiftBic={String(warrantSettlementDetails?.swiftBic)}
        message={String(warrantSettlementDetails?.message)}
        accountNr={String(warrantSettlementDetails?.accountNumber)}
        amount={String(warrantSettlementDetails?.amount)}
      />
    </div>
  );
};

export default Info;
