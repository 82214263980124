import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import DocumentsStep from "../components/step/documents";
import SignatureStep from "../components/step/signature";
import StakeholderInformationStep from "../components/step/stakeholder-information";
import ReceiveConvertibleNoteContext from "./ReceiveConvertibleNoteContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleNote.receiverSide");

const ConvertibleNoteFormData = () => {
  const navigate = useNavigate();
  useToastFormikValidator();

  const { convertibleNoteDetails } = ReceiveConvertibleNoteContext.useStoreState((state) => state);

  const { declineConvertibleNoteThunk } = ReceiveConvertibleNoteContext.useStoreActions((state) => state);
  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const { handleSubmit, isSubmitting, setSubmitting } = useFormikContext();

  const declineHandler = useCallback(async () => {
    setSubmitting(true);
    try {
      await declineConvertibleNoteThunk({
        companyId: Number(convertibleNoteDetails?.companyId),
        convertibleNoteId: Number(convertibleNoteDetails?.id),
      });

      setInvitedUser(null);

      navigate(getPath(["user", "dashboard"]), { replace: true });
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  }, [
    convertibleNoteDetails?.companyId,
    convertibleNoteDetails?.id,
    declineConvertibleNoteThunk,
    navigate,
    setInvitedUser,
    setSubmitting,
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <StakeholderInformationStep
        stakeholder={convertibleNoteDetails?.stakeholder}
        description={t("steps.reviewStakeholder.description")}
      />

      {convertibleNoteDetails?.documentsNeedsSignature && (
        <>
          <div className="mt-3" />

          <DocumentsStep
            documents={convertibleNoteDetails?.documentFiles || []}
            description={t("steps.acceptDocuments.description")}
          />

          <div className="mt-3" />

          <SignatureStep description={t("steps.acceptAgreement.description")} />
        </>
      )}

      <div className="d-flex mt-7">
        <Button isLoading={isSubmitting} isDisabled={isSubmitting} type="submit">
          {t("accept")}
        </Button>
        <Button
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          className="ms-2"
          variant="secondary"
          onClick={declineHandler}
        >
          {t("decline")}
        </Button>
      </div>
    </Form>
  );
};

export default ConvertibleNoteFormData;
