import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import { assocPath, defaultTo, dissocPath } from "ramda";

import { Button, DatePicker, Dropdown as CDropdwn, Ui } from "common/components/atoms";
import { ButtonProps } from "common/components/atoms/Button/Button";
import { useOnClickOutside } from "common/hooks/useOnClickOutside";
import { ChevronDownIcon } from "common/icons/svg";
import { transformDateToCommonDateFormat } from "common/utils/functions";

import ManagePlansContext, { initialFiltersState } from "../../../managePlansContext";
import classes from "../AgreementsAppliedFilters.module.scss";

const DateDropdownItem: FC<{ index: number }> = ({ index }) => {
  const { companyId = "0" } = useParams();

  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);
  const setSavedAppliedFilters = ManagePlansContext.useStoreActions((actions) => actions.setSavedAppliedFilters);

  const handleResetFilters = useCallback(() => {
    setSavedAppliedFilters(assocPath([companyId, "dates"], initialFiltersState.dates, savedAppliedFilters));
  }, [companyId, savedAppliedFilters, setSavedAppliedFilters]);

  const selectedFilter = useMemo(() => {
    switch (index) {
      case 0: {
        return {
          title: "Expiry date",
          filters: savedAppliedFilters?.[companyId]?.dates?.expiryDate,
        };
      }

      case 1: {
        return {
          title: "Start date",
          filters: savedAppliedFilters?.[companyId]?.dates?.startDate,
        };
      }

      default: {
        return {
          title: "End date",
          filters: savedAppliedFilters?.[companyId]?.dates?.endDate,
        };
      }
    }
  }, [companyId, index, savedAppliedFilters]);

  return (
    <div>
      <div className="d-flex align-items-center justify-content-between">
        <Ui.l bold className="mb-3">
          {selectedFilter?.title}
        </Ui.l>

        {index === 0 ? (
          <Button variant="tertiary" size="s" onClick={handleResetFilters}>
            Reset
          </Button>
        ) : null}
      </div>
      <Row>
        <Col xs={6}>
          <DatePicker
            label="From"
            isClearable
            isDateOnlyString
            date={selectedFilter?.filters?.from ? new Date(selectedFilter?.filters?.from) : undefined}
            maxDate={selectedFilter?.filters?.to ? new Date(selectedFilter?.filters?.to) : undefined}
            onChange={(e) => {
              const updatedFilter = index === 0 ? "expiryDate" : index === 1 ? "startDate" : "endDate";

              if (!e) {
                setSavedAppliedFilters(dissocPath([companyId, "dates", updatedFilter, "from"], savedAppliedFilters));
                return;
              }

              const updatedFilters = assocPath(
                [companyId, "dates", updatedFilter, "from"],
                transformDateToCommonDateFormat(e, "yyyy-MM-dd"),
                savedAppliedFilters
              );

              setSavedAppliedFilters(updatedFilters);
            }}
          />
        </Col>
        <Col xs={6}>
          <DatePicker
            label="To"
            isClearable
            isDateOnlyString
            date={selectedFilter?.filters?.to ? new Date(selectedFilter?.filters?.to) : undefined}
            minDate={selectedFilter?.filters?.from ? new Date(selectedFilter?.filters?.from) : undefined}
            onChange={(e) => {
              const updatedFilter = index === 0 ? "expiryDate" : index === 1 ? "startDate" : "endDate";
              if (!e) {
                setSavedAppliedFilters(dissocPath([companyId, "dates", updatedFilter, "to"], savedAppliedFilters));
                return;
              }

              setSavedAppliedFilters(
                assocPath(
                  [companyId, "dates", updatedFilter, "to"],
                  transformDateToCommonDateFormat(e, "yyyy-MM-dd"),
                  savedAppliedFilters
                )
              );
            }}
          />
        </Col>
      </Row>

      {index !== 2 ? <div className={classNames("mt-3", classes["divider"])} /> : null}
    </div>
  );
};

const Toggle: FC<
  ButtonProps & {
    open: boolean;
    setOpen?: (val: boolean) => void;
  }
> = ({ open, setOpen, ...props }) => {
  const { companyId = "0" } = useParams();
  const savedAppliedFilters = ManagePlansContext.useStoreState((state) => state.savedAppliedFilters);

  const activeFilters = useMemo(() => {
    let count = 0;
    const dates: any = defaultTo({}, savedAppliedFilters?.[companyId]?.dates);

    for (const key in dates) {
      if (dates?.[key]?.from !== undefined) count++;
      if (dates?.[key]?.to !== undefined) count++;
    }

    return count;
  }, [companyId, savedAppliedFilters]);

  return (
    <Button
      {...props}
      size="s"
      variant="tertiary"
      className={classNames(
        classNames("ms-1", classes["toggle"], {
          [classes["active"]]: open,
        })
      )}
      id={classes["toggle"]}
      onClick={() => {
        setOpen?.(!open);
      }}
    >
      <div className="d-flex align-items-center">
        <Ui.s className="me-semihalf">Date</Ui.s>

        {activeFilters ? <Ui.s className={classes["count"]}>{activeFilters}</Ui.s> : null}

        <ChevronDownIcon
          fontSize={16}
          className={classNames({
            [classes["open"]]: open,
          })}
        />
      </div>
    </Button>
  );
};

const DatesFiltersDropdown = () => {
  const ref = useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  useOnClickOutside(ref, () => {
    setIsMenuOpen(false);
  });

  const dropdownOptions = useMemo(() => {
    const stateStatuses = initialFiltersState.dates;

    return Object.keys(stateStatuses).map((el, index) => ({
      name: el,
      id: index,
      element: <DateDropdownItem index={index} />,
    }));
  }, []);

  return (
    <div ref={ref}>
      <CDropdwn
        optionsIsObject
        menuOpen={isMenuOpen}
        options={dropdownOptions}
        className={classNames(classes["dropdown"], {
          [classes["date"]]: true,
        })}
        CustomToggleElement={<Dropdown.Toggle as={Toggle} open={isMenuOpen} setOpen={setIsMenuOpen} />}
        onChange={() => {}}
      />
    </div>
  );
};

export default DatesFiltersDropdown;
