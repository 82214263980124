import { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, ModalInfo } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan.deleteModal");

const DeleteLoanModal: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams<{ companyId: string }>();

  const { isOpenDeleteModalCL, loanId, stakeholderNameCL } = IssueEquityContext.useStoreState((state) => state);
  const { setIsOpenDeleteModalCL, deleteConvertibleLoanThunk, getIssueEquityThunk } =
    IssueEquityContext.useStoreActions((actions) => actions);

  const handleCancel = useCallback(() => {
    setIsOpenDeleteModalCL({ open: false });
  }, [setIsOpenDeleteModalCL]);

  const handleDelete = useCallback(async () => {
    if (loanId) {
      setIsLoading(true);
      try {
        await deleteConvertibleLoanThunk(loanId);
        if (companyId) {
          getIssueEquityThunk(+companyId);
        }
        setIsOpenDeleteModalCL({ open: false });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [companyId, deleteConvertibleLoanThunk, getIssueEquityThunk, loanId, setIsOpenDeleteModalCL]);

  return (
    <ModalInfo
      size="sm"
      show={isOpenDeleteModalCL}
      header={t("title", { name: stakeholderNameCL || "" })}
      footer={
        <>
          <Button isLoading={isLoading} isDisabled={isLoading} onClick={handleDelete}>
            {t("delete")}
          </Button>
          <Button isLoading={isLoading} isDisabled={isLoading} onClick={handleCancel} variant="secondary">
            {t("cancel")}
          </Button>
        </>
      }
    >
      {t("description")}
    </ModalInfo>
  );
};

export default DeleteLoanModal;
