import { FC, useCallback, useEffect, useRef, useState } from "react";
import cn from "classnames";

import classes from "./Sidebar.module.scss";
import Widget from "./Widget/Widget";

type SidebarComponent = FC<JSX.IntrinsicElements["div"] & { isPlanInfoWidgetVisible?: boolean }> & {
  Widget: typeof Widget;
};

const Sidebar: SidebarComponent = ({ className, isPlanInfoWidgetVisible, children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(true);

  const getScreenSize = useCallback(() => {
    if (ref.current) {
      setIsSticky(ref.current.clientHeight < window.innerHeight);
    }
  }, []);

  useEffect(() => {
    getScreenSize();
    window.addEventListener("resize", getScreenSize);

    return () => {
      window.removeEventListener("resize", getScreenSize);
    };
  }, [getScreenSize]);

  return (
    <div ref={ref} className={cn(classes.sidebar, className, { [classes.sticky]: isSticky })} {...props}>
      {children}
    </div>
  );
};

Sidebar.Widget = Widget;

export default Sidebar;
