import { useCallback } from "react";

import useCurrency from "../useCurrency";
import { formatNumber, getEndSymbol, getStartSymbol } from "./helpers";
import { FormatNumbers } from "./types";

const useFormatNumbers = (globalCustomCurrencySymbol?: string) => {
  const { currencySymbol } = useCurrency();

  const wrappedFormatter: FormatNumbers = useCallback(
    (value, type = "value", config = {}) => {
      const formatedNumber = formatNumber(value, { ...config, decimals: type });

      if (formatedNumber === "-") {
        config.hideCurrencySymbol = true;
      }

      const startSymbol = getStartSymbol(
        type,
        config?.hideCurrencySymbol || false,
        currencySymbol,
        globalCustomCurrencySymbol,
        config?.customCurrencySymbol
      );

      const endSymbol = getEndSymbol(
        type,
        config?.hideCurrencySymbol || false,
        currencySymbol,
        globalCustomCurrencySymbol,
        config?.customCurrencySymbol
      );

      return `${startSymbol}\u00A0${formatedNumber}\u00A0${endSymbol}`.trim(); // \u00A0 - NO-BREAK SPACE
    },
    [currencySymbol, globalCustomCurrencySymbol]
  );

  return wrappedFormatter;
};

export default useFormatNumbers;
