import { FC, useMemo } from "react";
import FormControl from "react-bootstrap/FormControl";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import Check from "common/components/atoms/Checks/Check";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { CreateProgramFormData } from "pages/equity-management/plans/wizards/create-program/useProgramForm";
import { createTranslation, TranslationNS } from "translation";

import { DocumentStatusEnum } from "../../../enums/enum";

const t = createTranslation(TranslationNS.common, "program.components.programForm.documentStatusId");

const DocumentStatusField: FC<FormGroupProps> = (props) => {
  const { values, touched, errors, handleChange } = useFormikContext<Pick<CreateProgramFormData, "documentStatusId">>();

  const programDocumentStatuses = useMemo(
    () => [
      DocumentStatusEnum.DOCUMENT_UPLOADED,
      DocumentStatusEnum.NO_DOCUMENT_REQUIRED,
      DocumentStatusEnum.REVIEW_LATER,
    ],
    []
  );

  return (
    <FormGroup controlId="documentStatusId" {...props}>
      <InputHeader label={t("label")} />
      {programDocumentStatuses.map((status) => (
        <Check
          type="radio"
          key={status}
          name="documentStatusId"
          label={t(`${status}`)}
          isInvalid={touched.documentStatusId && !!errors.documentStatusId}
          value={status}
          checked={!!values.documentStatusId && status === +values.documentStatusId}
          onChange={handleChange}
        />
      ))}
      <FormControl.Feedback type="invalid">{errors.documentStatusId}</FormControl.Feedback>
    </FormGroup>
  );
};

export default DocumentStatusField;
