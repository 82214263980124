import { ChangeEvent, useCallback } from "react";
import axios from "axios";
import { useFormikContext } from "formik";
import { defaultTo, pick } from "ramda";

import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import StakeholderSearch from "../../StakeholderSearch/StakeholderSearch";
import { fields, StakeholderFormValues, updatedStakeholderFields } from "../Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder");
const StakeholderSearchField = () => {
  const { companyId } = useStoreState((state) => state.activeCompanyModel);

  const { values, setValues, handleChange, errors, handleBlur, touched } = useFormikContext<StakeholderFormValues>();

  const handleSelect = useCallback(
    async (e: ChangeEvent<HTMLInputElement>, item?: StakeholderFormValues) => {
      if (item) {
        const valuesToUpdate: Partial<StakeholderFormValues & { subscriptionAvailable: any }> = {
          address: defaultTo("", item?.address),
          businessEmail: defaultTo("", item?.businessEmail),
          businessPostAddress: defaultTo("", item?.businessPostAddress),
          companyName: defaultTo("", item?.companyName),
          dateOfBirth: defaultTo(null, item?.dateOfBirth),
          email: defaultTo("", item?.email),
          firstName: defaultTo("", item?.firstName),
          isCompanyOwned: defaultTo(false, item?.isCompanyOwned),
          lastName: defaultTo("", item?.lastName),
          organizationNumber: defaultTo("", item?.organizationNumber),
          relationshipTypeId: item?.relationshipTypeId,
          countryId: defaultTo(undefined, item?.countryId),
          phoneNumber: defaultTo("", item?.phoneNumber),
          stakeholderId: defaultTo(undefined, item?.stakeholderId),
        };

        // checking available seats for agreements creation
        try {
          const request = await axios.post("/api/ownership/plan/check-stakeholder", {
            stakeholderId: item.stakeholderId,
            companyId,
          });

          if (request.status === 200) {
            valuesToUpdate.subscriptionAvailable = request.data;
          }
          valuesToUpdate.stakeholderId = item.stakeholderId;
        } catch (e) {
          console.error({ e });
        }

        setValues({
          ...values,
          ...valuesToUpdate,
        });
      }

      handleChange(e);
    },
    [companyId, handleChange, setValues, values]
  );

  const error = Object.keys(pick(updatedStakeholderFields, errors)).length > 0 ? t("stakeholderRequired") : "";

  return (
    <StakeholderSearch
      companyId={companyId || 0}
      name={fields.stakeholderId}
      selectedValue={values.stakeholderId}
      error={error}
      handleSelect={handleSelect}
      isTouched={touched.stakeholderId}
      onBlur={handleBlur}
    />
  );
};

export default StakeholderSearchField;
