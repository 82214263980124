import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { compareAsc } from "date-fns";
import { FormikHelpers } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum, RelationshipTypesEnum } from "common/enums/enum";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertibleLoan.editPanel");
const tv = createTranslation(TranslationNS.validation);

const useConvertibleLoanEditPanel = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const shareClassesCompany = useStoreState((state) => state.shareClassModel.shareClassesCompany);

  const { isConvertibleLoanEditPanelOpen } = IssueEquityContext.useStoreState((state) => state);
  const convertibleLoan = IssueEquityContext.useStoreState((state) => state.convertibleLoan);

  const { setIsConvertibleLoanEditPanelOpen, getIssueEquityThunk } = IssueEquityContext.useStoreActions(
    (actions) => actions
  );

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      statusId: R.defaultTo(undefined, convertibleLoan?.statusId),
      loanId: R.defaultTo(undefined, convertibleLoan?.id),
      firstName: R.defaultTo("", convertibleLoan?.firstName),
      lastName: R.defaultTo("", convertibleLoan?.lastName),
      email: R.defaultTo("", convertibleLoan?.email),
      companyName: R.defaultTo("", convertibleLoan?.stakeholderCompanyName),
      organizationNumber: R.defaultTo("", convertibleLoan?.organizationNumber),
      loanAmount: R.defaultTo(0, convertibleLoan?.loanAmount),
      discount: R.defaultTo(0, convertibleLoan?.discount),
      valuationCap: R.defaultTo(0, convertibleLoan?.valuationCap),
      expirationDate: R.defaultTo(undefined, convertibleLoan?.expirationDate),
      agreementDate: R.defaultTo(undefined, convertibleLoan?.agreementDate),
      interestRate: R.defaultTo(0, convertibleLoan?.interestRate),
      conversionPrice: R.defaultTo(0, convertibleLoan?.conversionPrice),
      isCompanyOwned: R.defaultTo(false, convertibleLoan?.isCompanyOwned),
      relationshipTypeId: R.defaultTo(RelationshipTypesEnum.INVESTOR, convertibleLoan?.relationshipTypeId),
      documentStatusId: R.defaultTo(undefined, convertibleLoan?.documentStatusId),
      documentsNeedsSignature: R.defaultTo(false, convertibleLoan?.documentsNeedsSignature),
      sendInvitationEmail: R.defaultTo(false, convertibleLoan?.sendInvitationEmail),
      isShareClassRequired: convertibleLoan?.shareClassId ? "yes" : "no",
      shareClassComment: R.defaultTo("", convertibleLoan?.shareClassComment),
      shareClassId: R.defaultTo(undefined, convertibleLoan?.shareClassId),
      dateOfBirth: R.defaultTo(undefined, convertibleLoan?.dateOfBirth),
      address: R.defaultTo("", convertibleLoan?.address),
      countryId: R.defaultTo(undefined, convertibleLoan?.countryId),
      businessPostAddress: R.defaultTo("", convertibleLoan?.businessPostAddress),
      phoneNumber: R.defaultTo("", convertibleLoan?.phoneNumber),
      stakeholderId: R.defaultTo(undefined, convertibleLoan?.stakeholderId),
      documents: (convertibleLoan?.documentFiles
        ? { newFiles: [], oldFiles: convertibleLoan?.documentFiles }
        : { newFiles: [], oldFiles: [] }) as FilesDataMultiple,
      saveAsDraft: false,
    }),
    [convertibleLoan]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(tv("required")),
        lastName: Yup.string().required(tv("required")),
        email: Yup.string()
          .email(tv("invalidEmail"))
          .when("sendInvitationEmail", {
            is: true,
            then: Yup.string().required(tv("required")),
          }),
        companyName: Yup.string().when("isCompanyOwned", {
          is: true,
          then: Yup.string().required(tv("required")),
        }),
        shareClassId: Yup.number().when("isShareClassRequired", {
          is: "yes",
          then: Yup.number().required(tv("required")),
        }),
        discount: Yup.number()
          .required(tv("required"))
          .min(0, tv("minNumber", { number: "0%" }))
          .max(100, tv("maxNumber", { number: "100%" })),
        interestRate: Yup.number()
          .required(tv("required"))
          .min(0, tv("minNumber", { number: "0%" }))
          .max(100, tv("maxNumber", { number: "100%" })),
        loanAmount: Yup.number()
          .required(tv("required"))
          .min(1, tv("minNumberMore", { number: 1 })),
        valuationCap: Yup.number().nullable().min(0, tv("notNegative")),
        conversionPrice: Yup.number().nullable().min(0, tv("notNegative")),
        agreementDate: Yup.date().required(tv("required")),
        expirationDate: Yup.date()
          .required(tv("required"))
          .test("agreementDate", "Must be after agreement date", (value, object) => {
            return compareAsc(value || new Date(), object.parent.agreementDate) === 1;
          }),
        documentStatusId: Yup.number().required(tv("requiredSection")),
        documents: Yup.object().when("documentStatusId", {
          is: (documentValue: number) => +documentValue === DocumentStatusEnum.DOCUMENT_UPLOADED,
          then: Yup.object().shape({
            newFiles: Yup.mixed().when("oldFiles", {
              is: (oldFiles: any) => !oldFiles.length,
              then: Yup.array().min(1, tv("required")),
            }),
          }),
        }),
      }),
    []
  );

  const onSubmit = useCallback(
    async (formValues: typeof initialValues, actions?: FormikHelpers<typeof initialValues>) => {
      setIsLoading(true);

      try {
        const formData = new FormData();

        Object.keys(formValues).forEach((key) => {
          const item = key as keyof typeof initialValues;
          const allowTypes = ["number", "string", "boolean"];
          if (allowTypes.includes(typeof formValues?.[item])) {
            if (item === "conversionPrice" || item === "valuationCap") {
              formData.append(key, !formValues[item] ? "0" : String(formValues[item]));
            } else {
              formData.append(key, String(formValues[item]));
            }
          }
        });

        if (formValues?.documents?.newFiles?.length) {
          formValues.documents.newFiles.forEach((file) => {
            formData.append("filesData.files", file);
          });
        }
        if (formValues?.documents?.oldFiles?.length) {
          formValues.documents.oldFiles.forEach((file) => {
            formData.append("filesData.oldFileIds", String(file.fileId));
          });
        }

        formData.append("CompanyId", String(companyId));

        const request = await axios.post("/api/equity-management/issue-equity/convertible-loan", formData);

        if (request.status === 200) {
          notify(
            formValues.email ? t("successMessage") : t("successMessageWithoutEmail"),
            true,
            "success",
            5000,
            false,
            "top-center"
          );
          await getIssueEquityThunk(Number(companyId));
          setIsConvertibleLoanEditPanelOpen({ open: false });
          actions?.resetForm();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [companyId, getIssueEquityThunk, setIsConvertibleLoanEditPanelOpen]
  );

  const closePanel = useCallback(() => {
    setIsConvertibleLoanEditPanelOpen({ open: false });
  }, [setIsConvertibleLoanEditPanelOpen]);

  return {
    isLoading,
    initialValues,
    validationSchema,
    shareClassesCompany,
    isConvertibleLoanEditPanelOpen,
    onSubmit,
    closePanel,
  };
};

export default useConvertibleLoanEditPanel;
