import { FC } from "react";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";
import * as R from "ramda";

import TextField from "common/components/atoms/TextField/TextField";
import { ShareClassFormData } from "common/shareClass/types";
import { createTranslation, TranslationNS } from "translation";

export const participatingCapFieldSettings = {
  min: 0,
  max: 1000,
};

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.participatingCapField");

const ParticipatingCapField: FC<FormGroupProps & Partial<{ withHelperLabel: boolean }>> = (props) => {
  const { touched, errors, values, handleChange, handleBlur } =
    useFormikContext<Pick<ShareClassFormData, "participatingCap">>();

  return (
    <FormGroup controlId="participatingCap" {...props}>
      <TextField
        type="number"
        label={t("label")}
        error={errors.participatingCap}
        isTouched={touched.participatingCap}
        min={participatingCapFieldSettings.min}
        max={participatingCapFieldSettings.max}
        value={R.defaultTo("", values.participatingCap)}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </FormGroup>
  );
};

export default ParticipatingCapField;
