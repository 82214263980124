import { FC } from "react";
import { useFormikContext } from "formik";

import { Ui } from "common/components/atoms";
import ToggleSwitch, { ToggleSwitchProps } from "common/components/atoms/ToggleSwitch/ToggleSwitch";
import { createTranslation, TranslationNS } from "translation";

import { fields as f, StakeholderFormValues } from "../Stakeholder.types";

const t = createTranslation(TranslationNS.common, "molecules.stakeholder.fields");

type StakeholderFirstNameFieldProps = ToggleSwitchProps & {};
const StakeholderCompanyField: FC<StakeholderFirstNameFieldProps> = (props) => {
  const { values, handleChange } = useFormikContext<StakeholderFormValues>();

  return (
    <div>
      <Ui.m className="mb-1 font-weight-normal">{t("throughCompany.label")}</Ui.m>

      <ToggleSwitch
        {...props}
        checked={values.isCompanyOwned}
        onChange={handleChange}
        name={f.isCompanyOwned}
        label={values.isCompanyOwned ? t("throughCompany.yes") : t("throughCompany.no")}
      />
    </div>
  );
};

export default StakeholderCompanyField;
