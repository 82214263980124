import { FC, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CloseConfirm from "./close-confirm/close-confirm";
import Basics from "./steps/basics/basics";
import Summary from "./steps/summary/summary";

const t = createTranslation(TranslationNS.pages, "createPlan");

const CreatePlanWizard: FC = () => {
  const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const { getOwnershipPlansThunk } = useStoreActions((state) => state.company);

  useEffect(() => {
    if (companyId) {
      getOwnershipPlansThunk(companyId);
    }
  }, [getOwnershipPlansThunk, companyId]);

  return (
    <WizardLayout data-testid="Create plan wizard layout">
      <WizardLayout.Header title={t("title")} />
      <WizardContent maxStep={2}>
        <Routes>
          <Route path={ROUTER_V2.equityManagement.createPlan.basics} element={<Basics />} />
          <Route path={ROUTER_V2.equityManagement.createPlan.summary} element={<Summary />} />
          <Route path="*" element={<Navigate to={getEMPath(["plans", "agreements"])} />} />
        </Routes>
      </WizardContent>
      <CloseConfirm />
    </WizardLayout>
  );
};

export default CreatePlanWizard;
