import { FC, useCallback, useMemo } from "react";
import { Form, useFormikContext } from "formik";

import { Helper, P, SlidePanel } from "common/components/atoms";
import SlowRequestInfo from "common/components/atoms/SlowRequestInfo/SlowRequestInfo";
import StakeholderSection, {
  StakeholderContextProps,
} from "common/components/molecules/StakeholderSection/StakeholderSection";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import GeneratePlanDocument from "common/plan/generatePlanDocument/generatePlanDocument";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import VestingPlanDetails from "../../../steps/basics/sidebar/form-parts/VestingPlanDetails/VestingPlanDetails";
import { PlanFormType } from "../../../steps/basics/sidebar/forms/plan-form";
import { PlanForm } from "../../../types";
import ApprovalInformation from "../../form-parts/approval-information/approval-information";
import Footer from "../../form-parts/footer/footer";
import ShareDetails from "../../form-parts/share-details/share-details";

const t = createTranslation(TranslationNS.pages, "createPlan.basics.planSidebar");

type FormDataProps = {
  handleClose: (isOpen: boolean) => void;
  isEdit?: boolean;
};

const FormData: FC<FormDataProps> = ({ handleClose, isEdit }) => {
  const selectedProgram = useStoreState((state) => state.programModel.program);

  const { handleSubmit, isSubmitting, values } = useFormikContext<PlanForm>();

  const handleAddPlan = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  const updatedFields = useMemo<StakeholderContextProps["updatedFields"]>(
    () => (values.sendEmailInvitation ? { email: { isTopField: true, meta: { isOptional: false } } } : undefined),
    [values.sendEmailInvitation]
  );

  return (
    <Form>
      <ToastFormikValidator />
      <Helper>
        <SlidePanel.Section
          title={<Helper.Question questionId="receiverDetails">{t("receiverBasics")}</Helper.Question>}
        >
          <Helper.Answer
            className="mb-3"
            answerId="receiverDetails"
            text={t("receiverDetailsAnswer")}
            linkText={t("receiverDetailsReadMore")}
            link="/"
          />
          <StakeholderSection updatedFields={updatedFields} isAgreementsFlow />
        </SlidePanel.Section>

        <SlidePanel.Section data-testid="shareDetailsCollapsible" title={t("shareDetails")}>
          <ShareDetails
            shareClass={selectedProgram?.shareClassName}
            planType={selectedProgram?.instrumentTypeId}
            programName={selectedProgram?.name}
          />
        </SlidePanel.Section>

        <SlidePanel.Section
          data-testid="vestingDetailsCollapsible"
          title={t("vestingDetails")}
          isDividerVisible={false}
        >
          <VestingPlanDetails instrumentType={InstrumentTypesIdsEnum.RSA} />
        </SlidePanel.Section>

        <SlidePanel.Section isDividerVisible={false} title={t("approvalInformation")}>
          <ApprovalInformation />
        </SlidePanel.Section>

        <SlidePanel.Section isDividerVisible={false} data-testid="exerciseDocumentCollapsible" title={t("document")}>
          <P.m>{t("documentsDescription")}</P.m>

          <GeneratePlanDocument autoGenerateAgreement={false} planFormType={PlanFormType.RSAPlan} managerSignature />
        </SlidePanel.Section>

        <SlowRequestInfo isSubmitting={isSubmitting} />

        <Footer isEdit={isEdit} handleAddPlan={handleAddPlan} isSubmitting={isSubmitting} handleClose={handleClose} />
      </Helper>
    </Form>
  );
};
export default FormData;
