import axios from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

export type CompanyType = {
  id: number;
  companyName: string;
  subscriptionExpireDate?: string;
  createdAt: string;
  createdBy: string;
  latestValuation: number;
  numberOfStakeholders: number;
  numberOfPlans: number;
  numberOfFinancialInstruments: number;
  numberOfTransactions: number;
  numberOfIssuedSharesIssuances: number;
  numberOfStakholdersInOtherCompanies: number;
  foundedAt?: string;
};

// export type AllCompaniesGetDto = {
//   companies: CompanyType[];
// };

interface AdminContextModel {
  isLoading: boolean;
  isAdminLoading: boolean;
  companies: CompanyType[] | null;

  setIsLoading: Action<this, boolean>;
  setAdminLoading: Action<this, boolean>;
  setCompanies: Action<this, CompanyType[] | null>;

  getCompaniesThunk: Thunk<this>;
}

const apiBase = "/api/admin";

const AdminContext = createContextStore<AdminContextModel>({
  isLoading: false,
  isAdminLoading: false,
  companies: null,

  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setCompanies: action((state, payload) => {
    state.companies = payload;
  }),
  setAdminLoading: action((state, payload) => {
    state.isAdminLoading = payload;
  }),

  getCompaniesThunk: thunk(async (actions) => {
    try {
      actions.setIsLoading(true);

      const request = await axios.get(`${apiBase}/all-companies`);

      if (request.status === 200) {
        actions.setCompanies(request.data.companies);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      actions.setIsLoading(false);
    }
  }),
});

export default AdminContext;
