import { FC, ReactNode } from "react";
import cn from "classnames";

import { Ui } from "common/components/atoms/Typography";
import { InformationCircleOutlinedIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import classes from "./input-feedback.module.scss";

export type InputFeedbackProps = {
  isDisabled?: boolean;
  isTouched?: boolean;
  error?: string | ReactNode;
  info?: string;
  withoutInfoIcon?: boolean;
  className?: string;
};

const InputFeedback: FC<InputFeedbackProps> = ({
  isDisabled = false,
  withoutInfoIcon,
  isTouched = false,
  error,
  info,
  className,
}) => {
  if (error && isTouched) {
    return (
      <div
        className={cn("d-flex align-items-center mt-half", classes.wrap, className, {
          "text-disabled": isDisabled,
        })}
      >
        <div
          className={cn("d-flex align-items-center", classes.error, {
            [classes.disabled]: isDisabled,
          })}
        >
          <span>
            <WarningIcon />
          </span>
          <div>{error}</div>
        </div>
      </div>
    );
  } else if (!(error && isTouched) && info) {
    return (
      <div
        title={info}
        className={cn("d-flex align-items-center mt-half", classes.wrap, className, {
          "text-disabled": isDisabled,
        })}
      >
        {
          <div
            className={cn("d-flex align-items-center", classes.info, {
              [classes.disabled]: isDisabled,
            })}
          >
            <span>{!withoutInfoIcon && <InformationCircleOutlinedIcon color={scssVariables.primary1} />}</span>
            <Ui.s>{info}</Ui.s>
          </div>
        }
      </div>
    );
  }

  return null;
};

export default InputFeedback;
