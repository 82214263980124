import { FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import cn from "classnames";
import { defaultTo } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { Button, H, Helper, InfoAlert, P, SlidePanel, Spinner, Table, Ui } from "common/components/atoms";
import { InstrumentTypesIdsEnum, PlanStatusesBasedOnAPIStatusId } from "common/enums/enum";
import { InfoIcon } from "common/icons/svg";
import { useWizardCloseModal, useWizardStepper } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { headerText, usePlanStatus } from "common/plan/planUtils";
import { scssVariables } from "common/utils/constants";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ReadMore from "../../../../../../../common/components/molecules/ReadMore/ReadMore";
import CreatePlanStore from "../../store";
import classes from "./basics.module.scss";
import Details from "./Details/Details";
import ProgressBar from "./ProgressBar/ProgressBar";
import RemovePlan from "./RemovePlan/RemovePlan";
import PlanForms, { PlanFormType } from "./sidebar/forms/plan-form";
import TableBody from "./table/table-body";
import TableHead from "./table/table-head";

const t = createTranslation(TranslationNS.pages, "createPlan.basics");

const Basics: FC = () => {
  useWizardStepper(1);
  useWizardCloseModal(false);
  const navigate = useNavigate();

  const { programId } = useParams<{ programId: string }>();
  const statuses = usePlanStatus();

  const { isSidebarOpen, planForm } = CreatePlanStore.useStoreState((state) => state);
  const { setIsSidebarOpen, setCreateOrEditPlan } = CreatePlanStore.useStoreActions((actions) => actions);

  const selectedProgram = useStoreState((state) => state.programModel.program);
  const isGetProgramLoading = useStoreState((state) => state.programModel.isGetProgramLoading);
  const { getProgramThunk } = useStoreActions((actions) => actions.programModel);

  const handleOpenSidebar = useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen, setIsSidebarOpen]);

  useEffect(() => {
    if (programId) {
      getProgramThunk(+programId).then();
    }
  }, [getProgramThunk, programId]);

  const planType = useMemo(() => {
    const instrumentType = selectedProgram?.instrumentTypeId === InstrumentTypesIdsEnum.OPTION ? "SOPlan" : "RSAPlan";
    const planType = planForm?.type === "existing" ? "Exist" : "";

    return `${planType}${instrumentType}` as PlanFormType;
  }, [planForm?.type, selectedProgram?.instrumentTypeId]);

  const handleAddPlan = useCallback(() => {
    setCreateOrEditPlan({ planId: null, type: "other" });
  }, [setCreateOrEditPlan]);

  const handleAddExistingPlan = useCallback(() => {
    setCreateOrEditPlan({ planId: null, type: "existing" });
  }, [setCreateOrEditPlan]);

  const handleNextStep = useCallback(() => {
    navigate(getEMPath(["createPlan", "summary"], { programId }));
  }, [navigate, programId]);

  const sidebarTitle = useMemo(() => {
    return headerText(planType, !!planForm?.planId, planForm?.stakeholderName);
  }, [planForm?.planId, planForm?.stakeholderName, planType]);

  const activePlans = defaultTo([], selectedProgram?.plans).filter(
    (el) => el.statusId !== PlanStatusesBasedOnAPIStatusId.draft
  );

  const draftedPlans = defaultTo([], selectedProgram?.plans).filter(
    (el) => el.statusId === PlanStatusesBasedOnAPIStatusId.draft
  );

  const isShowInvitationInfo = draftedPlans.find((plan) => plan.sendEmailInvitation);

  return (
    <WizardContent.Content>
      <div className={classes.wrap}>
        {isGetProgramLoading && <Spinner />}
        <H.xs className="mb-6">{t("title")}</H.xs>
        <Details />

        {selectedProgram?.essentialTerms && (
          <div className="mt-3">
            <Ui.s color="foregroundMedium" bold className="mb-1">
              Essential terms
            </Ui.s>
            <ReadMore
              description={selectedProgram.essentialTerms}
              readMoreColor="foregroundMedium"
              modalTitle="Essential terms"
            >
              <P.s color="foregroundMedium" style={{ whiteSpace: "break-spaces" }}>
                {selectedProgram.essentialTerms}
              </P.s>
            </ReadMore>
          </div>
        )}
        <ProgressBar />
        {draftedPlans.length ? (
          <div className={cn("m-auto mt-6", classes["shareholders-table"])}>
            <Ui.l bold className="mb-2" style={{ color: scssVariables.foregroundMedium }}>
              {t("drafts")}
            </Ui.l>
            <Table
              className={classes.planTable}
              headComponent={<TableHead />}
              bodyComponent={<TableBody plans={draftedPlans} />}
            />
          </div>
        ) : null}

        {isShowInvitationInfo && (
          <InfoAlert
            className="mt-5"
            type="Neutral"
            customContent={
              <div className="d-flex">
                <div className="me-2">
                  <InfoIcon fontSize="24" />
                </div>
                <P.s>{t("invitationInfo")}</P.s>
              </div>
            }
          />
        )}

        {activePlans.length ? (
          <div className={cn("m-auto mt-6", classes["shareholders-table"])}>
            <Ui.l bold className="mb-2" style={{ color: scssVariables.foregroundMedium }}>
              {t("activeAgreements")}
            </Ui.l>
            <Table
              className={classes.planTable}
              headComponent={<TableHead />}
              bodyComponent={<TableBody plans={activePlans} />}
            />
          </div>
        ) : null}

        <div className={cn("mt-7", classes.createPlanActions)}>
          <div className="mb-2">
            <Helper>
              <Helper.Question questionId="add-new-plan">
                <Button isFocusDisabled onClick={handleAddPlan} variant="secondary" data-testid="add-new-plan">
                  {t("addPlanButton")}
                </Button>
              </Helper.Question>

              <Helper.Answer
                className="mt-1"
                answerId="add-new-plan"
                text={t("addNewAgreeementHelperText")}
                // TODO: add link to the source
                link="#"
                linkText={t("addNewAgreeementHelperTextLink")}
              />
            </Helper>
          </div>
          <Helper>
            <Helper.Question questionId="add-existing-plan">
              <Button
                isFocusDisabled
                variant="secondary"
                onClick={handleAddExistingPlan}
                data-testid="add-existing-plan"
              >
                {t("addExistingPlanButton")}
              </Button>
            </Helper.Question>

            <Helper.Answer
              className="mt-1"
              answerId="add-existing-plan"
              text={t("addExistingAgreeementHelperText")}
              // TODO: add link to the source
              link="#"
              linkText={t("addNewAgreeementHelperTextLink")}
            />
          </Helper>
        </div>

        <SlidePanel show={isSidebarOpen} onHide={handleOpenSidebar}>
          <SlidePanel.Header
            tagText={planForm?.planId ? statuses[PlanStatusesBasedOnAPIStatusId.draft].label : undefined}
            tagVariant={planForm?.planId ? statuses[PlanStatusesBasedOnAPIStatusId.draft].variant : undefined}
            title={sidebarTitle}
            onHide={handleOpenSidebar}
          />
          <PlanForms formType={planType} planId={planForm?.planId} setIsSidebarOpen={setIsSidebarOpen} />
        </SlidePanel>
        <RemovePlan programId={programId ? programId : ""} />

        <div className="d-flex mt-8">
          <WizardContent.Controls.ContinueButton className="ms-auto" onClick={handleNextStep} />
        </div>
      </div>
    </WizardContent.Content>
  );
};

export default Basics;
