import { action } from "easy-peasy";

import { NotificationModel } from "store/types";

export const notifications: NotificationModel = {
  notificationMessages: [],
  unreadMessagesCount: 0,

  addNotificationMessage: action((state, payload) => {
    state.notificationMessages = [payload, ...state.notificationMessages];
    state.unreadMessagesCount += 1;
  }),
  addInitialNotificationMessages: action((state, payload) => {
    state.notificationMessages = [...payload];
    state.unreadMessagesCount = payload.length;
  }),
  removeAllNotificationMessages: action((state) => {
    state.notificationMessages = [];
  }),
  resetUnreadMessagesCount: action((state) => {
    state.unreadMessagesCount = 0;
  }),
};
