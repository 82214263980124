import { FC, useCallback, useState } from "react";
import { useFormikContext } from "formik";

import Button from "common/components/atoms/Button/Button";
import { createTranslation, TranslationNS } from "translation";

import { ThreeStepForm } from "../../../components/step/useStepForm";
import DeclineModal from "./decline-modal";

type PropsTypes = {
  isLoading: boolean;
  isExisting?: boolean;
  isSubmitActive?: boolean;
  onDecline: (declineReason: string) => void;
};

const t = createTranslation(TranslationNS.pages, "company.receivePlan.footer");

const ReceivePlanFooter: FC<PropsTypes> = ({ isExisting, isLoading, onDecline }) => {
  const { values } = useFormikContext<ThreeStepForm>();

  const [isDeclinedModalOpen, setIsDeclinedModalOpen] = useState(false);

  const toggleDeclinedModal = useCallback(() => setIsDeclinedModalOpen((state) => !state), []);

  return (
    <>
      <div className="mt-7 d-flex align-items-center">
        <Button
          className="me-2"
          data-testid="submit-btn-test-id"
          isLoading={isLoading}
          isDisabled={isExisting ? isLoading || !values.stakeholderInfoCorrect : isLoading}
          type="submit"
        >
          {t(isExisting ? "goToAgreement" : "acceptAgreement")}
        </Button>

        {isExisting ? (
          values.stakeholderInfoCorrect ? null : (
            <Button
              variant="secondary"
              data-testid="decline-btn-test-id"
              isDisabled={isLoading}
              isLoading={isLoading}
              onClick={toggleDeclinedModal}
            >
              {t("informationIsIncorrect")}
            </Button>
          )
        ) : (
          <Button
            variant="secondary"
            data-testid="decline-btn-test-id"
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={toggleDeclinedModal}
          >
            {t("declineAgreement")}
          </Button>
        )}
      </div>
      <DeclineModal
        isExisting={isExisting}
        isOpen={isDeclinedModalOpen}
        onModalClose={toggleDeclinedModal}
        onSubmit={onDecline}
        isLoading={isLoading}
      />
    </>
  );
};

export default ReceivePlanFooter;
