import { useCallback, useMemo } from "react";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps, NewAvatar, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CalendarIcon, MenuTabBarVerticalIcon, RemoveIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import ExercisingContext, { IExercisingItem } from "../../ExercisingContext";
import ExercisingCollapsable from "../ExercisingCollapsable/ExercisingCollapsable";
import classes from "../styles.module.scss";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.exercising.readyToBeIssued"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const ReadyToBeIssued = () => {
  const fNumber = useFormatNumbers();
  const { hasFullAccess } = useFeatures(FEATURES.exercise);

  const missingBankInformation = ExercisingContext.useStoreState((state) => state.missingBankInformation);
  const readyToBeIssued = ExercisingContext.useStoreState((state) => state.readyToBeIssued);
  const setIssueShare = ExercisingContext.useStoreActions((actions) => actions.setIssueShare);
  const setDeclineExercising = ExercisingContext.useStoreActions((actions) => actions.setDeclineExercising);

  const tooltipsContent = useMemo(() => {
    return {
      title: missingBankInformation ? tCommon("bankDetailsRequried") : !hasFullAccess ? tCommon("viewOnly") : undefined,
      message: missingBankInformation
        ? tCommon("addBankDetailsToAccess")
        : !hasFullAccess
        ? tCommon("tooltip")
        : undefined,
    };
  }, [hasFullAccess, missingBankInformation]);

  const menuItems = useCallback(
    (item: IExercisingItem) => {
      return [
        {
          key: "delete",
          type: "delete",
          noBorder: true,
          icon: <RemoveIcon />,
          label: t("decline"),
          onClick: !hasFullAccess
            ? undefined
            : () => {
                setDeclineExercising(item);
              },
          forceHideDropdown: true,
          isDisabled: !hasFullAccess || missingBankInformation,
        },
      ] as ContextMenuProps["items"];
    },
    [hasFullAccess, missingBankInformation, setDeclineExercising]
  );

  const handleOpenFormModal = useCallback(
    (item: IExercisingItem) => () => {
      setIssueShare(item);
    },
    [setIssueShare]
  );

  return (
    <ExercisingCollapsable
      title={t("title")}
      countItems={readyToBeIssued.length}
      description={t("description")}
      btnTitle={t("issueAllShares")}
      headButtonDisabled={!hasFullAccess || missingBankInformation}
      headButtonTooltipTitle={tooltipsContent.title}
      headButtonTooltipMessage={tooltipsContent.message}
    >
      {!readyToBeIssued.length ? (
        <div className="p-4">
          <Ui.s color="foregroundLow">{t("noHistory")}</Ui.s>
        </div>
      ) : (
        readyToBeIssued.map((item) => (
          <div key={item.exerciseRequestId} className={classes["row"]}>
            <NewAvatar
              company={item?.requestorIsCompanyOwned}
              imageUrl={item?.avatarFilePath}
              initials={item?.requestorIsCompanyOwned ? item?.requestorCompanyName : item.requestorName}
            />

            <div className="ms-2">
              <Ui.m bold className="mb-1">
                {item?.requestorIsCompanyOwned
                  ? `${item?.requestorCompanyName} (${item.requestorName})`
                  : item.requestorName}{" "}
                • {fNumber(item.numberOfOptions, "amount")} options
              </Ui.m>

              <div className="d-flex align-items-center">
                <CalendarIcon fontSize={16} color={scssVariables.foregroundLow} />
                <Ui.xs color="foregroundLow" className="ms-1">
                  {transformDateToCommonDateFormat(item.requestedAt, "d. MMM yyyy").toLowerCase()}
                  &nbsp;•&nbsp;
                </Ui.xs>

                <Ui.xs color="foregroundLow">
                  {t("item.description", {
                    price: fNumber(item.convertPrice),
                  })}
                </Ui.xs>
              </div>
            </div>

            <Button
              size="s"
              variant="tertiary"
              className="ms-auto"
              tooltipTitle={tooltipsContent.title}
              tooltipMessage={tooltipsContent.message}
              isDisabled={!hasFullAccess || missingBankInformation}
              onClick={handleOpenFormModal(item)}
            >
              {t("issueShares")}
            </Button>

            <ContextMenu items={menuItems(item)} className="ms-1">
              <Button isOnlyIcon variant="tertiary" className={classes["menu-button"]}>
                <MenuTabBarVerticalIcon />
              </Button>
            </ContextMenu>
          </div>
        ))
      )}
    </ExercisingCollapsable>
  );
};

export default ReadyToBeIssued;
