import { FC } from "react";
import { Navigate,Route, Routes } from "react-router-dom";

import { ROUTER_V2 } from "app/Router/RouterV2.types";
import Helper from "common/components/atoms/Helper/Helper";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardHeader from "common/layout/WizardLayout/WizardHeader/WizardHeader";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { createTranslation, TranslationNS } from "translation";

import CloseConfirm from "./CloseConfirm/CloseConfirm";
import Main from "./Main/Main";
import PreferredPreferences from "./PreferredPreferences/PreferredPreferences";
import CreateShareClassStore from "./store";

const t = createTranslation(TranslationNS.pages, "createShareClass");

const CreateShareClass: FC = () => (
  <CreateShareClassStore.Provider>
    <WizardLayout>
      <Helper>
        <WizardHeader title={<Helper.Question questionId="createShareClass" labelLeft={t("title")} />} />
        <div style={{ maxWidth: 638, margin: "0 auto" }}>
          <Helper.Answer answerId={"createShareClass"} link="/" linkText={t("titleReadMore")} text={t("titleAnswer")} />
        </div>
      </Helper>
      <WizardContent maxStep={2}>
        <Routes>
          <Route path={ROUTER_V2.equityManagement.createShareClass.main} element={<Main />} />
          <Route
            path={ROUTER_V2.equityManagement.createShareClass.preferredPreferences}
            element={<PreferredPreferences />}
          />
          <Route path="*" element={<Navigate to={ROUTER_V2.equityManagement.createShareClass.main} />} />
        </Routes>

        <CloseConfirm />
      </WizardContent>
    </WizardLayout>
  </CreateShareClassStore.Provider>
);

export default CreateShareClass;
