import { Pool } from "./Pool";

export enum ProgramStatuses {
  draft = 1,
  publish,
}

export type Member = {
  id: string;
  firstName: string;
  lastName: string;
  avatar?: string | null;
  shares: number;
};

export type Program = {
  id: string;
  label: string;
  purpose: string;
  pool: Pool;
  shareClass: string;
  type: string;
  numberOfShares: number;
  purchasePriceOfShares: number;
  members: Member[];
};
