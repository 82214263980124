import { FC, memo, useEffect, useState } from "react";
import { default as BTSpinner } from "react-bootstrap/Spinner";

import OneOffRsaPlan from "pages/equity-management/plans/wizards/create-one-off-plans/sidebar/one-off-rsa-plan";
import OneOffStockOptionsPlan from "pages/equity-management/plans/wizards/create-one-off-plans/sidebar/one-off-stock-options-plan";
import ExistingRsaPlanForm from "pages/equity-management/plans/wizards/create-plan/sidebar/forms/existingRsaPlan/existing-rsa-plan";
import ExistingOptionsPlanForm from "pages/equity-management/plans/wizards/create-plan/sidebar/forms/existingSOPlan/existing-options-plan";
import NewRsaPlanForm from "pages/equity-management/plans/wizards/create-plan/sidebar/forms/newRsaPlan/new-rsa-plan";
import NewOptionsPlanForm from "pages/equity-management/plans/wizards/create-plan/sidebar/forms/newSOplan/new-options-plan";
import { useStoreActions, useStoreState } from "store/store";

export enum PlanFormType {
  RSAPlan = "RSAPlan",
  SOPlan = "SOPlan",
  ExistRSAPlan = "ExistRSAPlan",
  ExistSOPlan = "ExistSOPlan",
  OneOffRSAPlan = "OneOffRSAPlan",
  OneOffSOPlan = "OneOffSOPlan",
}

type PropsType = {
  formType: PlanFormType | null;
  setIsSidebarOpen: (isOpen: boolean) => void;
  planId?: number | null;
  onSave?: () => void;
};

const PlanForms: FC<PropsType> = ({ formType, setIsSidebarOpen, onSave, planId }) => {
  const { getPlanThunk } = useStoreActions((actions) => actions.planModel);
  const plan = useStoreState((state) => state.planModel.plan);
  const [loading, setLoading] = useState(!!planId);

  const planToEdit = planId ? plan : null;

  useEffect(() => {
    if (planId) {
      getPlanThunk(planId).finally(() => {
        setLoading(false);
      });
    }
  }, [getPlanThunk, planId]);

  if (loading)
    return (
      <div className="d-flex justify-content-center pt-8">
        <BTSpinner animation="border" />
      </div>
    );

  return (
    <>
      {formType === PlanFormType.RSAPlan && (
        <NewRsaPlanForm planToEdit={planToEdit} setIsSidebarOpen={setIsSidebarOpen} onSave={onSave} />
      )}
      {formType === PlanFormType.SOPlan && (
        <NewOptionsPlanForm planToEdit={planToEdit} setIsSidebarOpen={setIsSidebarOpen} onSave={onSave} />
      )}
      {formType === PlanFormType.ExistRSAPlan && (
        <ExistingRsaPlanForm setIsSidebarOpen={setIsSidebarOpen} planToEdit={planToEdit} onSave={onSave} />
      )}
      {formType === PlanFormType.ExistSOPlan && (
        <ExistingOptionsPlanForm setIsSidebarOpen={setIsSidebarOpen} planToEdit={planToEdit} onSave={onSave} />
      )}
      {formType === PlanFormType.OneOffSOPlan && (
        <OneOffStockOptionsPlan planToEdit={planToEdit} setIsSidebarOpen={setIsSidebarOpen} onSave={onSave} />
      )}
      {formType === PlanFormType.OneOffRSAPlan && (
        <OneOffRsaPlan planToEdit={planToEdit} setIsSidebarOpen={setIsSidebarOpen} onSave={onSave} />
      )}
    </>
  );
};

export default memo(PlanForms);
