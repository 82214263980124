import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { FormControl } from "react-bootstrap";
import ReactPhoneInput from "react-phone-input-2";
import cn from "classnames";
import parseMin from "libphonenumber-js/min";

import { SELECT_DIGITS_PATTERN } from "common/utils/validators";
import { createTranslation } from "translation/helpers";

import "react-phone-input-2/lib/style.css";

import InputFeedback, { InputFeedbackProps } from "../input-blocks/input-feedback/input-feedback";
import InputHeader, { InputHeaderProps } from "../input-blocks/input-header/input-header";
import classes from "../TextField/TextField.module.scss";
import mobileInputClasses from "./MobileNumberInput.module.scss";

type MobileNumberInputProps = InputHeaderProps &
  InputFeedbackProps & {
    inputValue: string;
    countryCode: string;
    setInputValue: Dispatch<SetStateAction<string>>;
    setCountryCode: Dispatch<SetStateAction<string>>;
    setIsValid?: Dispatch<SetStateAction<boolean | undefined>>;
    className?: string;
  };

const translation = createTranslation("common", "components.mobileNumberInput");

const MobileNumberInput: FC<MobileNumberInputProps> = ({
  label = translation("label"),
  inputValue,
  countryCode,
  setInputValue,
  setIsValid,
  setCountryCode,
  isOptional = false,
  isDisabled = false,
  meta,
  error,
  info,
  className,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [indentWidth, setIndentWidth] = useState<number>();
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();

  function onChangeAndSetPhoneMask(e: ChangeEvent<HTMLInputElement>) {
    let { value } = e.target;
    value = value.replace(SELECT_DIGITS_PATTERN, "");
    const mask = `${value.substring(0, 3)} ${value.substring(3, 6)} ${value.substring(6, value.length)}`;

    setInputValue(mask.trim());
  }

  useEffect(() => {
    /* istanbul ignore next */
    if (dropdownRef?.current) {
      setIndentWidth(dropdownRef?.current?.offsetWidth);
    }
  }, [countryCode]);

  useEffect(() => {
    const isValid = parseMin(`+${countryCode}${inputValue}`)?.isValid();
    setIsInputValid(isValid);
    setIsValid && setIsValid(isValid);
  }, [inputValue, countryCode, setIsValid]);

  return (
    <div className={cn("flex-column", { [className as string]: className })} data-testid="mobile-number-test-id">
      <InputHeader label={label} isDisabled={isDisabled} isOptional={isOptional} meta={meta} />

      <div className="d-flex position-relative">
        <div className={`d-flex align-items-center h-100 position-absolute ${classes["icon-left"]}`}>
          <div ref={dropdownRef}>
            <ReactPhoneInput
              enableSearch
              country="NO"
              disableSearchIcon
              value={countryCode}
              inputStyle={{ display: "none" }}
              onChange={(e) => {
                setCountryCode(e);
                setInputValue("");
                inputRef?.current?.focus();
              }}
            />
            <div className={mobileInputClasses["phone-number-country-code"]}>+{countryCode}</div>
          </div>
        </div>

        <FormControl
          type="phoneNumber"
          value={inputValue}
          aria-label={label}
          disabled={isDisabled}
          isInvalid={!isInputValid}
          onChange={onChangeAndSetPhoneMask}
          style={{ textIndent: indentWidth ? indentWidth + 4 : 0 }}
        />
      </div>

      <InputFeedback isDisabled={isDisabled} isTouched={true} error={error} info={info} />
    </div>
  );
};

export default MobileNumberInput;
