import { FC } from "react";
import cn from "classnames";

import { Tooltip } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { FilledArrowDownIcon, InformationCircleOutlinedIcon } from "common/icons/svg";

import classes from "./Ownership.module.scss";

type ProfitOrLossProps = {
  status: "profit" | "loss";
  value: number | null;
  currency?: string;
  percentage?: number;
};

const ProfitOrLoss: FC<ProfitOrLossProps> = ({ status, value, currency, percentage }) => {
  const fNumber = useFormatNumbers(currency);

  return value === null ? (
    <div className={classes.noData}>
      <span className={classes.noData__icon}>
        <Tooltip className={classes.content} popupContent={"Missing transaction data"}>
          <InformationCircleOutlinedIcon />
        </Tooltip>
      </span>
      <span className={classes.noData__text}>N/A</span>
    </div>
  ) : (
    <div className={cn(classes.withData)}>
      <span className={classes.withData__text}>{fNumber(value, "value")}</span>
      <span
        className={cn(classes.withData__icon, {
          [classes.profit]: status === "profit",
          [classes.loss]: status === "loss",
        })}
      >
        <FilledArrowDownIcon
          style={{
            transform: status === "profit" ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
        <span className={classes.withData__percentage}>{fNumber(Math.abs(percentage || 0), "percent")}</span>
      </span>
    </div>
  );
};
export default ProfitOrLoss;
