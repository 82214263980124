import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import ContextMenu, { ContextMenuProps } from "common/components/atoms/ContextMenu/ContextMenu";
import { MenuTabBarVerticalIcon, PieChartIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Warrants.module.scss";

const t = createTranslation(TranslationNS.pages, "myPortfolio.overview");

type ActionsProps = {
  id: number;
  hasAccess?: boolean;
};
const Actions: FC<ActionsProps> = ({ id, hasAccess }) => {
  const navigate = useNavigate();

  const handleViewCompany = useCallback(() => {
    navigate(getPath(["equityManagement", "ownership", "capTable"], { companyId: id }));
  }, [id, navigate]);

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const menuItems: ContextMenuProps["items"] = [
      {
        key: "viewCompany",
        label: t("viewCompany"),
        icon: <PieChartIcon />,
        onClick: handleViewCompany,
        isDisabled: !hasAccess,
      },
    ];
    return menuItems;
  }, [handleViewCompany, hasAccess]);

  return (
    <ContextMenu items={menuItems}>
      <Button size="s" isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn" className={classes.actionButton}>
        <MenuTabBarVerticalIcon color="#2B011F" />
      </Button>
    </ContextMenu>
  );
};

export default Actions;
