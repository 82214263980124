import { FC, memo, useCallback, useMemo } from "react";
import classNames from "classnames";
import { FormikConfig,useFormik } from "formik";
import * as Yup from "yup";

import Button from "common/components/atoms/Button/Button";
import ModalInfo from "common/components/atoms/ModalInfo/ModalInfo";
import TextField from "common/components/atoms/TextField/TextField";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CreatePlanStore from "../../../store";
import classes from "./AddEmailModal.module.scss";

const t = createTranslation(TranslationNS.pages, "createPlan.summary.updateEmailModal");
const tValidate = createTranslation(TranslationNS.validation);

type AddEmailModalProps = {
  programId: number;
};

const AddEmailModal: FC<AddEmailModalProps> = ({ programId }) => {
  const planData = CreatePlanStore.useStoreState((state) => state.updatePlanEmail);
  const { setUpdatePlanEmail } = CreatePlanStore.useStoreActions((actions) => actions);

  const handleClose = useCallback(() => {
    setUpdatePlanEmail(null);
  }, [setUpdatePlanEmail]);

  return (
    <ModalInfo
      className={classes.sendInvite}
      header={t("title", { name: planData?.name })}
      show={!!planData}
      handleClose={handleClose}
    >
      {planData?.planId && <FormData handleClose={handleClose} planId={planData.planId} programId={programId} />}
    </ModalInfo>
  );
};

type FormDataProps = {
  handleClose: () => void;
  planId: number;
  programId: number;
};
const FormData: FC<FormDataProps> = ({ handleClose, planId, programId }) => {
  const { setUpdateEmailThunk } = useStoreActions((actions) => actions.planModel);
  const { getProgramThunk } = useStoreActions((actions) => actions.programModel);

  const initialValues = useMemo(
    () => ({
      email: "",
      planId,
    }),
    [planId]
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().email(tValidate("invalidEmail")).required(tValidate("required")),
      }),
    []
  );

  const submitHandler = useCallback<FormikConfig<typeof initialValues>["onSubmit"]>(
    async (values, { setSubmitting }) => {
      const res = await setUpdateEmailThunk(values);
      if (res) {
        getProgramThunk(+programId).then();
        notify(t("success"), true, "success");
        handleClose();
      }
      setSubmitting(false);
    },
    [getProgramThunk, handleClose, programId, setUpdateEmailThunk]
  );

  const { values, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <div className="mt-3">
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-center">
          <TextField
            className={classNames(classes.email, "mb-5")}
            type="email"
            label={t("description")}
            value={values.email}
            error={errors.email}
            isTouched={touched.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="primary" type="submit" className="me-2">
            {t("addEmail")}
          </Button>
          <Button isLoading={isSubmitting} isDisabled={isSubmitting} variant="secondary" onClick={handleClose}>
            {t("cancel")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default memo(AddEmailModal);
