import { FC, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import { Button, InfoAlert, P, SlidePanel, Ui } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import DocumentUploadWithStatuses from "common/components/molecules/DocumentUploadWithStatuses/DocumentUploadWithStatuses";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { WarningExclamationMarkIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { SplitPostDTO } from "../../../types";
import { documentationDetailsValidationSchema } from "../form-sections/documentation-details/validation";
import SplitDetails from "../form-sections/split-details/split-details";
import { splitDetailsValidationSchema } from "../form-sections/split-details/validation";
import TransactionDetails from "../form-sections/transaction-details/transaction-details";
import { transactionDetailsValidationSchema } from "../form-sections/transaction-details/validation";
import { SplitFields } from "./form-fields";

type PropsTypes = {
  onSubmit: (transaction: FormData, resetForm?: boolean) => Promise<boolean>;
  onClose: () => void;
  isSplitDisabled: boolean;
  minTransactionDate?: string;
};

const t = createTranslation(TranslationNS.pages, "company.transactions");
const tValidation = createTranslation(TranslationNS.validation);

const CreateSplitTransactionForm: FC<PropsTypes> = ({ onSubmit, onClose, isSplitDisabled, minTransactionDate }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const defaultTransactionDate = useMemo(() => {
    const todayMidday = format(new Date().setHours(12, 0, 0, 0), dateFormatWithTime);

    if (minTransactionDate) {
      if (todayMidday > minTransactionDate) {
        return todayMidday;
      } else {
        return minTransactionDate;
      }
    }

    return todayMidday;
  }, [minTransactionDate]);

  const formInitialValues: SplitPostDTO = useMemo(
    () => ({
      [SplitFields.companyId]: +companyId,
      [SplitFields.transactedAt]: defaultTransactionDate,

      [SplitFields.description]: "",
      [SplitFields.multiplier]: isSplitDisabled ? 0 : 2,
      [SplitFields.documents]: [],
      [SplitFields.documentStatusId]: undefined as unknown as number,
    }),
    // eslint-disable-next-line
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...transactionDetailsValidationSchema({ tFunction: tValidation, excludeFields: { transactionTypeId: true } }),
        ...splitDetailsValidationSchema(tValidation),
        ...documentationDetailsValidationSchema(tValidation),
      }),
    []
  );

  const handleSubmit = useCallback(
    async (values: SplitPostDTO, actions: FormikHelpers<SplitPostDTO>) => {
      const formData = new FormData();

      Object.values(SplitFields).forEach((key) => {
        const formValue = values[key];

        if (!formValue) return;

        if (key === SplitFields.documents) {
          if (!R.isEmpty(formValue)) {
            values.documents.forEach((el: any) => formData.append("FilesData.Files", el));
          }
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      const createdSuccessfully = await onSubmit(formData);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }
    },
    [onSubmit]
  );

  return (
    <Formik onSubmit={handleSubmit} initialValues={formInitialValues} validationSchema={validationSchema}>
      {({ isSubmitting, submitForm }) => {
        return (
          <Form>
            <SlidePanel.Header title={t("splitForm.title")} onHide={onClose} />

            {isSplitDisabled && (
              <InfoAlert
                className="p-3 mt-7 mb-5"
                type="Warning"
                customContent={
                  <div className="d-flex">
                    <WarningExclamationMarkIcon height={24} width={56} color={scssVariables.warning900} />
                    <div className="ms-2">
                      <Ui.m bold className="mb-1">
                        {t("splitForm.pendingTransactionTitle")}
                      </Ui.m>
                      <Ui.s>{t("splitForm.pendingTransactionContent")}</Ui.s>
                    </div>
                  </div>
                }
              />
            )}

            <SlidePanel.Section title={t("transactionDetails.title")}>
              <TransactionDetails
                hiddenFields={{ transactionTypeId: true, transactionTypeTransferId: true }}
                minTransactionDate={minTransactionDate}
                isDisabled={isSplitDisabled}
              />
            </SlidePanel.Section>

            <SlidePanel.Section title={t("splitDetails.title")}>
              <SplitDetails isDisabled={isSplitDisabled} />
            </SlidePanel.Section>

            <SlidePanel.Section title={t("documentationDetails.title")}>
              <P.s className="mb-4">{t("documentationDetails.description")}</P.s>

              <DocumentUploadWithStatuses
                oldFilesName={SplitFields.prevFiles}
                newFilesName={SplitFields.documents}
                disabled={isSplitDisabled}
              />
            </SlidePanel.Section>

            <SlidePanel.Actions>
              <Button
                isLoading={isSubmitting}
                onClick={submitForm}
                isDisabled={isSplitDisabled}
                type="submit"
                className="me-3"
              >
                {t("sharedFormButtons.submitBtn")}
              </Button>
              <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                {t("sharedFormButtons.cancelBtn")}
              </Button>
            </SlidePanel.Actions>
            <ToastFormikValidator />
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateSplitTransactionForm;
