import axios from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { InvestmentEntity } from "store/types";

export type OwnershipPlanDocumentFile = {
  documentTypeId: number;
  downloadId: string;
  fileName: string;
  firstName: string;
  lastName: string;
  planId: number;
  companyName: string | null;
  companyOwned: boolean;
  numberOfShares: number;
};

export type ReceiveOwnershipPlanResponse = {
  companyName: string;
  planCreatorName: string;
  totalNumberOfShares: number;
  totalShareValuation: number;
  documents?: OwnershipPlanDocumentFile[];
  currencyId: number;
};

export type AcceptManagerPlantPostDTO = {
  planIds: number[];
  signature: File | null;
  approvalKey: string;
};

interface ReceiveOwnershipContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, boolean>;

  companyName: string;
  setCompanyName: Action<this, string>;

  selectedCompany: InvestmentEntity | null;
  setSelectedCompany: Action<this, this["selectedCompany"]>;

  isRemoteCompanySelected: boolean;
  setIsRemoteCompanySelected: Action<this, boolean>;

  companyOrgNumber: string;
  setCompanyOrgNumber: Action<this, string>;

  isHoldingCompanySelected: boolean;
  setIsHoldingCompanySelected: Action<this, boolean>;

  signature: File | null;
  setSignature: Action<this, File | null>;

  planDetails: ReceiveOwnershipPlanResponse | null;
  setPlanDetails: Action<this, ReceiveOwnershipPlanResponse | null>;

  getPlanDetailsThunk: Thunk<this, string>;

  acceptPlanThunk: Thunk<this, AcceptManagerPlantPostDTO>;
}

const ReceivePlanManagerContext = createContextStore<ReceiveOwnershipContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    companyName: "",
    setCompanyName: action((state, payload) => {
      state.companyName = payload;
    }),

    selectedCompany: null,
    setSelectedCompany: action((state, payload) => {
      state.selectedCompany = payload;
    }),

    isRemoteCompanySelected: false,
    setIsRemoteCompanySelected: action((state, payload) => {
      state.isRemoteCompanySelected = payload;
    }),

    companyOrgNumber: "",
    setCompanyOrgNumber: action((state, payload) => {
      state.companyOrgNumber = payload;
    }),

    signature: null,
    setSignature: action((state, payload) => {
      state.signature = payload;
    }),

    isHoldingCompanySelected: false,
    setIsHoldingCompanySelected: action((state, payload) => {
      state.isHoldingCompanySelected = payload;
    }),

    planDetails: null,
    setPlanDetails: action((state, payload) => {
      state.planDetails = payload;
    }),

    getPlanDetailsThunk: thunk(async (actions, key) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<ReceiveOwnershipPlanResponse>(
          `/api/ownership/ownership-plan/manager-signature-details/${key}`
        );

        if (request.status === 200) {
          actions.setPlanDetails(request.data);
        }
      } finally {
        actions.setIsLoading(false);
      }
    }),
    acceptPlanThunk: thunk(async (actions, payload) => {
      const { planIds, signature, approvalKey } = payload;
      try {
        const formData = new FormData();

        formData.append("signature", signature as File);
        formData.append("approvalKey", String(approvalKey));
        planIds.forEach((id) => {
          formData.append("planIds", String(id));
        });
        actions.setIsLoading(true);

        const request = await axios.post("/api/ownership/ownership-plan/manager-sign-plans", formData);

        if (request.status === 200) {
          return true;
        }
      } finally {
        actions.setIsLoading(false);
      }
    }),
  },
  {
    name: "Manager accept plan",
  }
);

export default ReceivePlanManagerContext;
