import { FC, useEffect } from "react";

import Helper from "common/components/atoms/Helper/Helper";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardHeader from "common/layout/WizardLayout/WizardHeader/WizardHeader";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CreateProgramForm from "./CreateProgramForm";

const t = createTranslation(TranslationNS.pages, "createProgram");

const CreateProgram: FC = () => {
  const { setProgramAction } = useStoreActions((actions) => actions.programModel);

  useEffect(() => {
    setProgramAction(null);
  }, [setProgramAction]);

  return (
    <WizardLayout>
      <Helper>
        <WizardHeader title={<Helper.Question questionId="createProgram" labelLeft={t("title")} />} />
        <div style={{ maxWidth: 638, margin: "0 auto" }}>
          <Helper.Answer answerId="createProgram" linkText={t("readMore")} link="/" text={t("answer")} />
        </div>
      </Helper>

      <WizardContent maxStep={4}>
        <CreateProgramForm />
      </WizardContent>
    </WizardLayout>
  );
};

export default CreateProgram;
