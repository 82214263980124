import * as Yup from "yup";

import { EMAIL_VALIDATION_PATTERN } from "common/utils/validators";
import { TFunction } from "translation/helpers";

import { ReceiverDetailsFields } from "./form-fields";

export const receiverDetailsValidationSchema = (tFunction: TFunction) => ({
  [ReceiverDetailsFields.firstName]: Yup.string().required(tFunction("required")),
  [ReceiverDetailsFields.lastName]: Yup.string().required(tFunction("required")),
  [ReceiverDetailsFields.isCompanyOwned]: Yup.boolean(),
  [ReceiverDetailsFields.companyName]: Yup.string().when("isCompanyOwned", {
    is: true,
    then: Yup.string().required(tFunction("required")),
  }),
  [ReceiverDetailsFields.email]: Yup.string()
    .matches(EMAIL_VALIDATION_PATTERN, tFunction("invalidEmail"))
    .when("isNeedEmailInvitation", {
      is: true,
      then: Yup.string().required(tFunction("required")),
    }),
});
