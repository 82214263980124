import { FC,useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import axios from "axios";
import { isNil } from "ramda";

import Button from "common/components/atoms/Button/Button";
import { H, P } from "common/components/atoms/Typography";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../PoolsAndPrograms.module.scss";
import PoolsAndProgramsStore from "../store";

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.poolItem");

const DeleteItem: FC = () => {
  const { companyId } = useParams();

  const deletingItem = PoolsAndProgramsStore.useStoreState((state) => state.deletingItem);
  const setDeletingItem = PoolsAndProgramsStore.useStoreActions((actions) => actions.setDeletingItem);

  const getPoolsAndProgramsThunk = useStoreActions((state) => state.company.getPoolsAndProgramsThunk);

  const offCanvasCloseHandler = useCallback((): void => {
    setDeletingItem(undefined);
  }, [setDeletingItem]);

  const deletePoolHanlder = async () => {
    try {
      const request = await axios.delete(deletingItem?.type === "Pool" ? `/api/pool/${deletingItem?.id}` : `/api/program/${deletingItem?.id}`);

      if (request.status === 200) {
        await getPoolsAndProgramsThunk(companyId);
        offCanvasCloseHandler();
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal
      size="lg"
      centered
      show={!isNil(deletingItem)}
      className={classes["removal-modal"]}
      aria-labelledby="contained-modal-title-vcenter"
      onHide={offCanvasCloseHandler}
    >
      <div className={classes["content"]}>
        <H.xs className="text-center">
          {t("removeModal.title", {
            name: deletingItem?.name,
          })}
        </H.xs>

        <P.m className="mt-3 text-center">{t("removeModal.description")}</P.m>

        <div className="mt-5 d-flex justify-content-center">
          <Button onClick={deletePoolHanlder}>{t("remove")}</Button>

          <Button variant="secondary" className="ms-2" onClick={offCanvasCloseHandler}>
            {t("cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteItem;
