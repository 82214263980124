import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import * as R from "ramda";

import { getPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import Spinner from "common/components/atoms/Spinner/Spinner";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { ExercisingIcon, PieChartIcon, PoolsIcon } from "common/icons/svg";
import MainLayout from "common/layout/MainLayout/MainLayout";
import Menu, { MenuProps } from "common/layout/MainLayout/Menu/Menu";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { createTranslation, TranslationNS } from "translation";

import MyPortfolioExercising from "./exercising/Exercising";
import MyPortfolioContext from "./MyPortfolioContext";
import Portfolio from "./portfolio/Portfolio";
import RSAPage from "./rsa-plan/RSAPage";
import StockOptionsPage from "./stock-options-plan/StockOptionsPage";

const t = createTranslation(TranslationNS.pages, "myPortfolio.sideBar");

const PortfolioRouter: FC = () => {
  const [isDataLoading, setIsDataLoading] = useState(true);

  const { exerciseRequestId } = useParams<{ planId: string; exerciseRequestId?: string }>();

  const { userPlans } = MyPortfolioContext.useStoreState((state) => state);
  const { getUserPlansThunk } = MyPortfolioContext.useStoreActions((actions) => actions);

  const plansItems = useMemo(() => {
    return userPlans?.map((el) => {
      const generatedPath = () => {
        if (el.instrumentTypeId === InstrumentTypesIdsEnum.RSA) {
          return {
            pathname: getPath(["myPortfolio", "rsaPlan"], {
              planId: el.id,
            }),
            state: {
              programName: el.name,
            },
          };
        }

        // TODO: fix for RSU plans integration
        if (el.instrumentTypeId === InstrumentTypesIdsEnum.RSU) {
          return {
            pathname: getPath(["myPortfolio", "rsaPlan"], {
              planId: el.id,
            }),
            state: {
              programName: el.name,
            },
          };
        }

        if (el.instrumentTypeId === InstrumentTypesIdsEnum.OPTION) {
          return {
            pathname: getPath(["myPortfolio", "stockOptionsPlan"], {
              planId: el.id.toString(),
            }),
            state: {
              programName: el.name,
            },
          };
        }
      };

      return {
        to: generatedPath() as unknown as any,
        icon: <PoolsIcon fontSize={20} />,
        label:
          el.instrumentTypeId === 1
            ? t("myEquityPlans.routes.rsaAgreement")
            : t("myEquityPlans.routes.stockOptionsAgreement"),
        subLabel: el.companyName,
      } as MenuProps["items"][0];
    });
  }, [userPlans]);

  const myPortfolioMenu = useMemo<MenuProps[]>(() => {
    if (!R.isNil(plansItems) && !R.isEmpty(plansItems)) {
      return [
        {
          items: [
            {
              to: getPath(["myPortfolio", "portfolio"]),
              icon: <PieChartIcon fontSize={20} />,
              label: t("myEquityPlans.routes.portfolio"),
            },
            ...plansItems,
            {
              to: getPath(["myPortfolio", "exercising"], { exerciseRequestId: exerciseRequestId }),
              icon: <ExercisingIcon fontSize={20} />,
              label: t("myEquityPlans.routes.exercising"),
            },
          ],
        },
      ];
    }

    return [
      {
        items: [
          {
            to: ROUTER_V2.myPortfolio.portfolio,
            icon: <PieChartIcon />,
            label: t("myEquityPlans.routes.portfolio"),
          },
          {
            to: getPath(["myPortfolio", "exercising"], { exerciseRequestId: exerciseRequestId }),
            icon: <ExercisingIcon />,
            label: t("myEquityPlans.routes.exercising"),
          },
        ],
      },
    ];
  }, [exerciseRequestId, plansItems]);

  const renderMenu = useCallback(() => {
    return myPortfolioMenu.map((item, index) => <Menu key={index} {...item} />);
  }, [myPortfolioMenu]);

  useEffect(() => {
    getUserPlansThunk();
  }, [getUserPlansThunk]);

  useEffect(() => {
    if (userPlans) {
      setIsDataLoading(false);
    }
  }, [userPlans]);

  if (isDataLoading) {
    return (
      <PageContent style={{ position: "relative" }} data-testid="company-manage-plans-page">
        <Spinner />
      </PageContent>
    );
  }

  return (
    <MainLayout className="justify-content-start">
      <MainLayout.Sidebar>{renderMenu()}</MainLayout.Sidebar>

      <Routes>
        <Route path={ROUTER_V2.myPortfolio.portfolio} element={<Portfolio />} />
        <Route path={ROUTER_V2.myPortfolio.rsaPlan} element={<RSAPage />} />
        <Route path={ROUTER_V2.myPortfolio.stockOptionsPlan} element={<StockOptionsPage />} />
        <Route path={ROUTER_V2.myPortfolio.exercising} element={<MyPortfolioExercising />} />
        <Route path="*" element={<Navigate to={ROUTER_V2.myPortfolio.portfolio} />} />
      </Routes>
    </MainLayout>
  );
};

const MyPortfolioRouter = () => {
  return (
    <MyPortfolioContext.Provider>
      <PortfolioRouter />
    </MyPortfolioContext.Provider>
  );
};

export default MyPortfolioRouter;
