import { FC, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Tag } from "common/components/atoms";
import { SOAgreement, SOAgreementContext } from "common/components/organisms";
import { AgreementUIType } from "common/enums/enum";
import withAgreements from "common/HOC/withAgreements";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import Sidebar from "common/layout/MainLayout/Sidebar/Sidebar";
import { usePlanStatus } from "common/plan/planUtils";

import RequestToExerciseOffCanvas from "./RequestToExerciseOffCanvas/RequestToExerciseOffCanvas";

const MyPortfolioStockOptionsPlan: FC = () => {
  const location = useLocation();
  const { planId } = useParams<{ planId: string }>();

  const agreementDetails = SOAgreementContext.useStoreState((state) => state.agreementDetails);
  const getAgreementDetailsThunk = SOAgreementContext.useStoreActions((actions) => actions.getAgreementDetailsThunk);
  const activeExercisablePlan = SOAgreementContext.useStoreState((state) => state.activeExercisablePlan);
  const setActiveExercisablePlan = SOAgreementContext.useStoreActions((actions) => actions.setActiveExercisablePlan);

  const planStatuses = usePlanStatus();

  useEffect(() => {
    getAgreementDetailsThunk(Number(planId));
  }, [planId, getAgreementDetailsThunk]);

  useDocumentTitleUpdate(`Stock options ${agreementDetails?.companyName ? `(${agreementDetails?.companyName})` : ""}`);

  return (
    <>
      <PageContent data-testid="portfolio-stock-options-page">
        <PageContent.Header className="justify-content-start">
          <PageContent.Header.Title>{location.state?.programName || "Stock options"}</PageContent.Header.Title>

          <div className="mt-1">
            {agreementDetails?.planStatusId ? (
              <Tag className="mx-2" variant={planStatuses[agreementDetails?.planStatusId || 0].variant}>
                {planStatuses[agreementDetails?.planStatusId || 0].label}
              </Tag>
            ) : null}
          </div>
        </PageContent.Header>

        <SOAgreement
          type={AgreementUIType.regular}
          onRequestClick={() => {
            if (agreementDetails) {
              setActiveExercisablePlan(agreementDetails);
            }
          }}
        />
      </PageContent>

      {/* TODO: continue after agreement status sidebar will be done from designs */}
      <Sidebar style={{ marginTop: 88, width: 320 }} />

      <RequestToExerciseOffCanvas open={!!activeExercisablePlan} />
    </>
  );
};

export default withAgreements(
  MyPortfolioStockOptionsPlan,
  { SOAgreement: true },
  {
    exercising: true,
    overviewPurchaseButton: true,
    ongoingExercises: true,
    exercisingHistory: true,
  }
);
