import { FC, ReactNode } from "react";
import { Accordion, CollapseProps } from "react-bootstrap";
import classNames from "classnames";

import classes from "./ExpansionPanel.module.scss";

type AccordionCollapseProps = Omit<CollapseProps, "children"> & { eventKey: string; children: ReactNode };
const Collapse: FC<AccordionCollapseProps> = ({ children, className, ...props }) => {
  return (
    <Accordion.Collapse {...props}>
      <div className={classNames(classes.collapse, className)}>{children}</div>
    </Accordion.Collapse>
  );
};

export default Collapse;
