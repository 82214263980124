import React, { FC, MouseEventHandler, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import classNames from "classnames";
import { Formik, FormikValues } from "formik";
import { defaultTo, isEmpty, isNil } from "ramda";
import * as Yup from "yup";

import { Button, ChecksGroup, P, SlidePanel, Tag, Ui } from "common/components/atoms";
import FileUploader, { UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum, UploadFileTypes } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { ArrowRightIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { removeRemoteDocument } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../transactions-service";
import { Transaction, TransactionCategory } from "../../../types";
import formatTransactionDate from "../../format-transaction-date";
import classes from "./manage-documents-container.module.scss";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions.manageDocuments"),
  createTranslation("validation"),
];

type Props = {
  transaction: Transaction | null;
  onClose: () => void;
  setTransactions: (val: Transaction[]) => void;
  updateCurrentTransaction: (val: Transaction | null) => void;
  getAllTransactions?: () => void;
};

const validationSchema = () =>
  Yup.object().shape({
    documentStatus: Yup.number().required(tValidation("required")),
    files: Yup.array().when("documentStatus", {
      is: (status: number) => status === DocumentStatusEnum.DOCUMENT_UPLOADED,
      then: Yup.array().min(1, tValidation("required")),
    }),
  });

const ManageDocumentsContainer: FC<Props> = ({
  getAllTransactions,
  transaction,
  onClose,
  setTransactions,
  updateCurrentTransaction,
}) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fNumber = useFormatNumbers(transaction?.currencySymbol);
  const { getTransactions } = useTransactionsService(companyId);
  const isCapitalIncreaseType = transaction?.categoryId === TransactionCategory.CapitalIncrease;

  const documentsFileType = useMemo(() => {
    if (transaction?.categoryId === TransactionCategory.Issue) {
      return UploadFileTypes.ShareIssuanceDocument;
    }
    if (transaction?.categoryId === TransactionCategory.Sell) {
      return UploadFileTypes.BuySellDocument;
    }
    if (transaction?.categoryId === TransactionCategory.Split) {
      return UploadFileTypes.SplitTransaction;
    }
    if (transaction?.categoryId === TransactionCategory.ChangeNominalValue) {
      return UploadFileTypes.NominalValueTransaction;
    }
    if (transaction?.categoryId === TransactionCategory.CapitalIncrease) {
      return UploadFileTypes.TransactionBundle;
    }
  }, [transaction?.categoryId]);

  const topSectionTitle = useMemo(() => {
    if (transaction?.categoryId === TransactionCategory.ChangeNominalValue) {
      const isDecreased = defaultTo(0, transaction?.sharePriceBefore) > defaultTo(0, transaction?.sharePrice);

      return isDecreased ? "Nominal value decreased" : "Nominal value increased";
    }

    if (transaction?.categoryId === TransactionCategory.Issue) {
      return transaction?.toName;
    }
    if (transaction?.categoryId === TransactionCategory.CapitalIncrease) {
      return transaction?.eventName;
    }

    if (transaction?.categoryId === TransactionCategory.Sell) {
      return `${transaction?.fromName || "..."}  ->  ${transaction?.toName || "..."}`;
    }

    if (transaction?.categoryId === TransactionCategory.Split) {
      return (
        <>
          <Ui.m>{fNumber(transaction?.numberOfSharesBefore, "amount")}</Ui.m>
          <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundHigh} />
          <Ui.m bold className="ms-half">{`${fNumber(transaction?.numberOfShares, "amount")} shares • ${
            transaction?.categoryName
          } 1:${fNumber(transaction?.multiplier, Number.isInteger(transaction?.multiplier) ? "amount" : "unitPrice", {
            hideCurrencySymbol: true,
          })}`}</Ui.m>
        </>
      );
    }
  }, [
    fNumber,
    transaction?.categoryId,
    transaction?.categoryName,
    transaction?.eventName,
    transaction?.fromName,
    transaction?.multiplier,
    transaction?.numberOfShares,
    transaction?.numberOfSharesBefore,
    transaction?.sharePrice,
    transaction?.sharePriceBefore,
    transaction?.toName,
  ]);

  const removeRemoteDocumentHandler = useCallback(
    async (file: UploadedFile) => {
      try {
        await removeRemoteDocument(file.fileId);

        const data = await getTransactions();
        const currentTransaction = data.transactions.find((item) => item.transactionId === transaction?.transactionId);

        if (currentTransaction) {
          updateCurrentTransaction({
            ...currentTransaction,
            files: isNil(currentTransaction.files) ? [] : currentTransaction.files,
          });
        }

        getAllTransactions?.();
      } catch (e) {
        console.error({ e });
      }
    },
    [getAllTransactions, getTransactions, transaction?.transactionId, updateCurrentTransaction]
  );

  const submitHandler = useCallback(
    async (values: FormikValues) => {
      setIsLoading(true);

      try {
        const formData = new FormData();
        formData.append("companyId", companyId);
        formData.append("companyFileType", String(documentsFileType));
        formData.append("entityId", String(Math.abs(transaction?.transactionId || 0)));
        formData.append("documentStatusId", String(values.documentStatus));

        if (!isEmpty(values.files)) {
          values.files.forEach((file: File) => {
            formData.append("files", file);
          });
        }

        const request = await axios.post(`/api/document/upload-multiple-documents/${companyId}`, formData);

        if (request.status === 200) {
          notify(t("transactionUpdated"), true, "success");
          const transactions = await getTransactions();
          setTransactions(transactions.transactions);
          onClose();
        }
      } catch (e) {
        console.error({ e });
      } finally {
        setIsLoading(false);
      }
    },
    [companyId, documentsFileType, getTransactions, onClose, setTransactions, transaction?.transactionId]
  );

  return (
    <SlidePanel show={!isNil(transaction)}>
      <Formik
        enableReinitialize
        initialValues={{
          documentStatus: transaction?.documentStatusId || DocumentStatusEnum.DOCUMENT_UPLOADED,
          files: [],
        }}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ values, errors, touched, setFieldValue, handleSubmit }) => {
          return (
            <>
              <ToastFormikValidator />
              <SlidePanel.Header title={t("title")} onHide={onClose} />

              <div className={classNames("mt-7", classes["info"])}>
                <Ui.s bold className="mb-1 d-flex align-items-center">
                  {topSectionTitle}
                </Ui.s>

                <div className="d-flex align-items-center">
                  <Tag size="s" variant="complete">
                    {transaction?.categoryName}
                  </Tag>
                  {isCapitalIncreaseType ? (
                    <>
                      <Ui.xs color="foregroundLow" className="ms-2">
                        {transaction?.transactedAt
                          ? formatTransactionDate(defaultTo("", transaction?.transactedAt))
                          : null}
                      </Ui.xs>
                      <Ui.xs color="foregroundLow" className="mx-1">
                        •
                      </Ui.xs>
                      <Ui.xs color="foregroundLow">
                        {fNumber(transaction?.numberOfShares, "amount")} {t("totalShares")}
                      </Ui.xs>
                    </>
                  ) : (
                    <>
                      <Ui.xs color="foregroundLow" className="ms-2">
                        {transaction?.transactedAt
                          ? formatTransactionDate(defaultTo("", transaction?.transactedAt))
                          : null}
                      </Ui.xs>
                      <Ui.xs color="foregroundLow" className="mx-1">
                        •
                      </Ui.xs>
                      <Ui.xs color="foregroundLow">
                        {transaction?.categoryId === TransactionCategory.Split ? (
                          <>
                            {`${fNumber(transaction?.sharePriceBefore, "sharePrice")}/share`}
                            <ArrowRightIcon className="mx-half" />
                            {`${fNumber(transaction?.sharePrice, "sharePrice")}/share`}
                          </>
                        ) : transaction?.categoryId === TransactionCategory.ChangeNominalValue ? (
                          <>
                            {fNumber(transaction?.sharePriceBefore, "sharePrice")}
                            <ArrowRightIcon className="mx-half" />
                            {fNumber(transaction?.sharePrice, "sharePrice")}
                          </>
                        ) : (
                          `${fNumber(transaction?.transactionTotal, "value")} (${fNumber(
                            transaction?.sharePrice,
                            "sharePrice"
                          )}/share)`
                        )}
                      </Ui.xs>
                      <Ui.xs color="foregroundLow" className="mx-1">
                        •
                      </Ui.xs>
                      <Ui.xs color="foregroundLow">
                        {fNumber(transaction?.numberOfShares, "amount")} {t("totalShares")}
                      </Ui.xs>
                    </>
                  )}
                </div>
              </div>

              <SlidePanel.Section title={t("documentation")} isSingle>
                <P.m className="mb-5">{t("documentationDescription")}</P.m>

                <FileUploader
                  multiple
                  isTouched
                  size="large"
                  isOptional={values.documentStatus !== DocumentStatusEnum.DOCUMENT_UPLOADED}
                  label={t("uploadDocuments")}
                  error={errors.files as string}
                  prevFileData={transaction?.files}
                  onChange={(e) => {
                    setFieldValue("files", e.newFiles);
                  }}
                  customRemoveDocumentHandler={removeRemoteDocumentHandler}
                />

                <ChecksGroup
                  className="mt-4"
                  label={t("docsStatus")}
                  error={errors.documentStatus}
                  isTouched={touched.documentStatus}
                >
                  <ChecksGroup.Check
                    isNoFocus
                    type="radio"
                    label={t("uploaded")}
                    checked={values.documentStatus === DocumentStatusEnum.DOCUMENT_UPLOADED}
                    onChange={() => {
                      setFieldValue("documentStatus", DocumentStatusEnum.DOCUMENT_UPLOADED);
                    }}
                  />
                  <ChecksGroup.Check
                    isNoFocus
                    type="radio"
                    label={t("willReviewLater")}
                    checked={values.documentStatus === DocumentStatusEnum.REVIEW_LATER}
                    onChange={() => {
                      setFieldValue("documentStatus", DocumentStatusEnum.REVIEW_LATER);
                    }}
                  />
                  <ChecksGroup.Check
                    isNoFocus
                    type="radio"
                    label={t("noRequired")}
                    checked={values.documentStatus === DocumentStatusEnum.NO_DOCUMENT_REQUIRED}
                    onChange={() => {
                      setFieldValue("documentStatus", DocumentStatusEnum.NO_DOCUMENT_REQUIRED);
                    }}
                  />
                </ChecksGroup>
              </SlidePanel.Section>

              <SlidePanel.Actions>
                <Button
                  isLoading={isLoading}
                  className="me-2"
                  onClick={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
                >
                  {t("updateDocuments")}
                </Button>
                <Button isLoading={isLoading} variant="secondary" onClick={onClose}>
                  {t("cancel")}
                </Button>
              </SlidePanel.Actions>
            </>
          );
        }}
      </Formik>
    </SlidePanel>
  );
};

export default ManageDocumentsContainer;
