import { FC, ReactNode } from "react";
import BTable, { TableProps as BTableProps } from "react-bootstrap/Table";
import classNames from "classnames";

import classes from "./Table.module.scss";

export type TableProps = BTableProps & {
  headComponent?: ReactNode;
  bodyComponent?: ReactNode;
  footComponent?: ReactNode;
};

const Table: FC<TableProps> = ({
  className,
  headComponent,
  bodyComponent,
  footComponent,
  ...props
}) => (
  <BTable
    className={classNames(classes["table"], className)}
    {...props}
  >
    {headComponent}
    {bodyComponent}
    {footComponent}
  </BTable>
);

export default Table;
