import { Action, action, createContextStore } from "easy-peasy";

import { ValuationSingle } from "store/types";

interface ValuationContextModel {
  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  selectedRowId: number | undefined;
  setSelectedRowId: Action<this, this["selectedRowId"]>;

  isValuationEdit: boolean;
  setIsValuationEdit: Action<this, this["isValuationEdit"]>;

  removeValuation: ValuationSingle | null;
  setRemoveValuation: Action<this, this["removeValuation"]>;
}

const ValuationContext = createContextStore<ValuationContextModel>(
  {
    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    selectedRowId: undefined,
    setSelectedRowId: action((state, payload) => {
      state.selectedRowId = payload;
    }),

    isValuationEdit: false,
    setIsValuationEdit: action((state, payload) => {
      state.isValuationEdit = payload;
    }),

    removeValuation: null,
    setRemoveValuation: action((state, payload) => {
      state.removeValuation = payload;
    }),
  },
  {
    name: "Equity management. Valuation",
  }
);

export default ValuationContext;
