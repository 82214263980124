export type MainPathType = "full" | "base" | "relativeFull" | "relativeBase";

export type EquityOwnershipRouteType = keyof Omit<typeof ROUTER_V2.equityManagement.ownership, MainPathType>;
export type EquityPlanRouteType = keyof Omit<typeof ROUTER_V2.equityManagement.plans, MainPathType>;
export type AttractRouteType = keyof Omit<typeof ROUTER_V2.attract, MainPathType>;
export type CompanySettingsRouteType = keyof Omit<typeof ROUTER_V2.companySettings, MainPathType>;
export type ReceiveRouteType = keyof Omit<typeof ROUTER_V2.receive, MainPathType>;
export type createProgramRouteType = keyof Omit<typeof ROUTER_V2.equityManagement.createProgram, MainPathType>;

export const mainPath = <T extends string>(
  baseUrl: T
): {
  base: `/${T}`;
  full: `/${T}/*`;
  relativeBase: `${T}`;
  relativeFull: `${T}/*`;
} => {
  return {
    base: `/${baseUrl}`,
    full: `/${baseUrl}/*`,
    relativeBase: `${baseUrl}`,
    relativeFull: `${baseUrl}/*`,
  } as const;
};

export const ROUTER_V2 = {
  signIn: {
    ...mainPath("sign-in"),
  },
  user: {
    ...mainPath("user"),
    account: "account",
    general: "general",
    noAccess: "no-access",
    dashboard: "dashboard",
    notifications: "notifications",
  },
  onboard: {
    ...mainPath("onboard"),
    user: "user",
    company: {
      ...mainPath("company"),
      setup: "setup",
      getStarted: "get-started",
      companyInformation: ":companyId?/company-information",
      importStakeholders: ":companyId/import-stakeholders",
      importShareholders: ":companyId/import-shareholders",
      importTransaction: ":companyId/import-transaction",
      importFounders: ":companyId/import-founders",
      shareClasses: ":companyId/share-classes",
      final: ":companyId/final",
      teamMembers: "team-members",
      fields: "fields",
      pricing: ":companyId/pricing",
    },
    invitedUser: {
      ...mainPath("invited-user"),
      professional: "professional",
    },
  },
  invitation: {
    ...mainPath("invitation"),
    invitationType: ":invitationType/:entityId/:approvalKey?",
  },
  companyProfile: {
    ...mainPath("company-profile/:companyId"),
  },
  attract: {
    ...mainPath("attract/:companyId"),
    general: "general",
    pitch: "pitch",
    team: "team",
    fundingStatus: "funding-status",
  },
  equityManagement: {
    ...mainPath("equity-management/:companyId"),
    createPool: {
      ...mainPath("create-pool"),
      summary: "summary",
      basic: "basic",
      shares: "shares",
    },
    createProgram: {
      ...mainPath("create-program"),
      sharesDetails: "shares-details",
      summary: "summary",
      termination: "termination",
      vesting: "vesting",
      main: "main",
    },
    createShareClass: {
      ...mainPath("create-share-class"),
      summary: ":shareClassId/summary",
      preferredPreferences: ":shareClassId/preferred-preferences",
      main: "main",
    },
    createOneOffPlan: {
      ...mainPath("create-one-off-plan"),
      planReceiver: "plan-receiver",
      sharesSource: "shares-source",
      sharesDetails: "shares-details",
      vestingConditions: "vesting-conditions",
      exerciseClause: "post-termination-exercise-clause",
      summary: "summary",
    },
    createPlan: {
      ...mainPath("create-plan"),
      start: "start",
      basics: "basics/:programId",
      summary: "summary/:programId",
    },
    createCapitalIncrease: {
      ...mainPath("capital-increase/:transactionId?"),
      general: "general",
      transactions: "transactions",
    },
    plans: {
      ...mainPath("plans"),
      poolsAndPrograms: "pools-and-programs",
      agreements: "agreements",
      exercising: "exercising",
    },
    ownership: {
      ...mainPath("ownership"),
      financialInstruments: "financial-instruments",
      documents: "documents",
      valuation: "valuation",
      capTable: {
        ...mainPath("cap-table"),
      },
      shareClasses: "share-classes",
      stakeholdersManagement: "stakeholders-management",
      transactions: "transactions",
    },
    gettingStartedGuide: "getting-started-guide",
    settings: {
      ...mainPath("settings"),
      accessControl: "access-control",
      bankDetails: "bank-details",
      companyInformation: "company-information",
    },
  },
  companySettings: {
    ...mainPath("company-settings/:companyId"),
    accessControl: "access-control",
    bankInformation: "bank-information",
  },
  companyDetails: {
    ...mainPath("company-details/:id"),
  },
  receive: {
    ...mainPath("receive"),
    rsaPlan: "rsa-plan",
    soPlan: "so-plan",
    planManager: "plan-manager",
    issueShares: "issue-shares",
    convertibleNote: "convertible-note",
    convertibleLoan: "convertible-loan",
    warrant: "warrant",
    warrantSettlement: "warrant-settlement",
    companyAccess: "company-access",
    stakeholder: "stakeholder",
    team: "team",
    company: "company",
    subscriptionAccepted: "subscription-accepted",
  },
  event: {
    ...mainPath("event/:id"),
  },
  myPortfolio: {
    ...mainPath("my-portfolio"),
    portfolio: "portfolio",
    exercising: "exercising/:exerciseRequestId?",
    rsaPlan: ":planId/rsa-plan",
    stockOptionsPlan: ":planId/stock-options-plan",
  },
  admin: {
    ...mainPath("admin"),
    company: "company",
  },
  uniMicroLanding: {
    ...mainPath("unimicro-landing"),
  },
  storybook: {
    ...mainPath("storybook"),
  },
} as const;

export const publicRouteList = {
  companyDetails: "company-details",
  event: "event",
  subscriptionAccepted: "subscription-accepted",
};
