import { useMemo } from "react";

import { useStoreState } from "../../store/store";

const useCurrency = () => {
  const companies = useStoreState((state) => state.account.user?.companies);
  const companyId = useStoreState((state) => state.activeCompanyModel?.companyId);
  const dropdowns = useStoreState((state) => state.common?.dropdowns);

  const selectedCompany = useMemo(() => companies?.find((el) => el.id === companyId), [companyId, companies]);

  const selectedCurrency = useMemo(
    () => dropdowns?.currencies?.find((el) => el.id === selectedCompany?.currencyId),
    [dropdowns?.currencies, selectedCompany?.currencyId]
  );

  return {
    currencyName: selectedCurrency?.name,
    currencyCode: selectedCompany?.currencyCode,
    formattedCurrency: selectedCurrency?.symbol,
    currencySymbol: selectedCurrency?.symbol,
  };
};

export default useCurrency;
