import { FC, ReactNode } from "react";
import cn from "classnames";

import classes from "./Tag.module.scss";

// Match name of the variant with class name for easy mapping
export type TagVariant = "access" | "description" | "declined" | "information" | "draft" | "complete" | "closed" | "expired";
type Size = "s" | "m";

type PropsType = {
  variant?: TagVariant;
  size?: Size;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  className?: string;
  children?: ReactNode;
};

const Tag: FC<PropsType> = ({ children, variant = "description", size = "m", iconLeft, iconRight, className }) => {
  return (
    <span className={cn(classes.tag, classes[variant], classes[size], className)}>
      {iconLeft && <span className={`me-1 ${classes.icon}`}>{iconLeft}</span>}
      {children}
      {iconRight && <span className={`ms-1 ${classes.icon}`}>{iconRight}</span>}
    </span>
  );
};

export default Tag;
