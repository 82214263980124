import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import DocumentsStep from "../components/step/documents";
import SignatureStep from "../components/step/signature";
import StakeholderInformationStep from "../components/step/stakeholder-information";
import WarrantsWizardContext from "./ReceiveWarrantsContext.wizard";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.receiverSide");

const WarrantsFormData = () => {
  const navigate = useNavigate();
  useToastFormikValidator();

  const { warrantDetails } = WarrantsWizardContext.useStoreState((state) => state);

  const { setInvitedUser } = useStoreActions((actions) => actions.invitedUser);

  const { declineWarrantThunk } = WarrantsWizardContext.useStoreActions((state) => state);

  const { handleSubmit, isSubmitting, setSubmitting } = useFormikContext();

  const declineHandler = useCallback(async () => {
    setSubmitting(true);
    try {
      await declineWarrantThunk({
        companyId: Number(warrantDetails?.companyId),
        warrantId: Number(warrantDetails?.id),
      });
      setInvitedUser(null);

      navigate(getPath(["user", "dashboard"]), { replace: true });
    } catch (e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  }, [declineWarrantThunk, navigate, setInvitedUser, setSubmitting, warrantDetails?.companyId, warrantDetails?.id]);

  return (
    <Form onSubmit={handleSubmit}>
      <StakeholderInformationStep
        stakeholder={warrantDetails?.stakeholder}
        managerName={"manager"}
        description={t("steps.reviewStakeholder.description")}
      />

      {warrantDetails?.documentsNeedsSignature && (
        <>
          <div className="mt-3" />

          <DocumentsStep
            documents={warrantDetails?.documentFiles || []}
            description={t("steps.acceptDocuments.description")}
          />

          <div className="mt-3" />

          <SignatureStep description={t("steps.acceptAgreement.description")} />
        </>
      )}

      <div className="d-flex mt-7">
        <Button isLoading={isSubmitting} type="submit" isDisabled={isSubmitting}>
          {t("accept")}
        </Button>
        <Button
          isLoading={isSubmitting}
          isDisabled={isSubmitting}
          className="ms-2"
          variant="secondary"
          onClick={declineHandler}
        >
          {t("decline")}
        </Button>
      </div>
    </Form>
  );
};

export default WarrantsFormData;
