import { FC, useCallback, useEffect, useState } from "react";
import { Formik } from "formik";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import IssueEquityContext from "../../IssueEquityContext";
import classes from "./ConvertLoanPanel.module.scss";
import Actions from "./sections/Actions";
import Conversion from "./sections/Conversion";
import Documentation from "./sections/Documentation";
import Info from "./sections/Info";
import useConvertLoanForm, { ConvertLoanFormValues } from "./useConvertLoanForm";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.convertLoan");

const ConvertLoanForm: FC = () => {
  const { companyId } = useStoreState((state) => state.activeCompanyModel);

  const { loanId } = IssueEquityContext.useStoreState((state) => state);
  const { getConversionDetailsCLThunk, convertCLThunk, setIsConvertLoanEditPanelOpen, getIssueEquityThunk } =
    IssueEquityContext.useStoreActions((actions) => actions);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loanId) {
      setLoading(true);
      getConversionDetailsCLThunk(loanId)
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loanId, getConversionDetailsCLThunk]);

  const handleSubmit = useCallback(
    async (values: ConvertLoanFormValues) => {
      try {
        if (!values.interestOverride) {
          delete values.interestOverride;
        }
        await convertCLThunk(values);
        notify(t("successSaved"), true, "success");
        setIsConvertLoanEditPanelOpen({ open: false });
        getIssueEquityThunk(companyId || 0);
      } catch (e) {
        console.log("error", e);
      }
    },
    [companyId, convertCLThunk, getIssueEquityThunk, setIsConvertLoanEditPanelOpen]
  );

  const { initialValues, validationSchema } = useConvertLoanForm();

  return (
    <SlidePanel.Body isLoading={loading}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        <div className={classes.wrap}>
          <Info />
          <Conversion />
          <Documentation />
          <Actions />
        </div>
      </Formik>
    </SlidePanel.Body>
  );
};
export default ConvertLoanForm;
