import { useCallback, useContext, useMemo } from "react";

import { HelperAnswer, HelperContext } from "./Helper";

export const useHelperQuestion = (questionId: string) => {
  const { activeQuestions, setActiveQuestion, setQuestionAnswer } = useContext(HelperContext);

  const question = useMemo(
    () => activeQuestions.find((question) => question.id === questionId),
    [activeQuestions, questionId]
  );

  const setActive = useCallback(
    (active?: boolean) => {
      setActiveQuestion?.(questionId, active);
    },
    [questionId, setActiveQuestion]
  );

  const setAnswer = useCallback(
    (answer: HelperAnswer | string) => {
      setQuestionAnswer?.(questionId, answer);
    },
    [questionId, setQuestionAnswer]
  );

  // return useMemo(() => {
  return { active: question?.active ?? false, setActive, setAnswer };
  // }, [question?.active, setActive, setAnswer]);
};

export const useHelperAnswer = (answerId: string) => {
  const { activeQuestions, answers } = useContext(HelperContext);

  const question = useMemo(
    () => activeQuestions.find((question) => question.id === answerId),
    [activeQuestions, answerId]
  );

  const Answer = useMemo(() => {
    if (!answers[answerId]) {
      return null;
    }
    return typeof answers[answerId] === "string"
      ? () => answers[answerId] as string
      : (answers[answerId] as HelperAnswer)?.render;
  }, [answers, answerId]);

  return { active: question?.active ?? false, Answer };
};
