import { FC, ReactNode } from "react";

import WizardLayoutStore from "../store";

const WizardStepContent: FC<{ step: number, children?: ReactNode }> = ({ step, children }) => {
  const currentStepState = WizardLayoutStore.useStoreState(state => state.currentStep);

  if (step !== currentStepState) {
    return null;
  }

  return <>{children}</>;
};

export default WizardStepContent;
