import { FC, JSX, ReactNode, useMemo } from "react";
import classNames from "classnames";

import { VestingTypesEnum } from "common/enums/enum";
import { TargetIcon, TimeHourglassIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Item.module.scss";

const t = createTranslation(TranslationNS.pages, "createProgram.summary.vestingConditions");

export type HeaderItemProps = JSX.IntrinsicElements["div"] & {
  icon?: ReactNode;
  label?: ReactNode;
  value?: ReactNode;
};

const HeaderItem: FC<HeaderItemProps> = ({ className, icon, label, value, ...props }) => (
  <div className={classNames(classes["header-item"], className)} {...props}>
    {icon}

    <div>
      {!!label && <div className="ui-xxs text-medium">{label}</div>}

      {!!value && <div className="ui-s">{value}</div>}
    </div>
  </div>
);

type HeaderComponent = FC<JSX.IntrinsicElements["div"]> & {
  Item: typeof HeaderItem;
};

const Header: HeaderComponent = ({ className, children, ...props }) => (
  <div className={classNames("p-2", classes["header"], className)} {...props}>
    {children}
  </div>
);

Header.Item = HeaderItem;

const Body: FC<JSX.IntrinsicElements["div"]> = ({ className, children, ...props }) => (
  <div className={classNames("ui-s py-3", className)} {...props}>
    {children}
  </div>
);

export type ItemProps = JSX.IntrinsicElements["div"] & {
  vestingType: VestingTypesEnum;
};

type ItemComponent = FC<ItemProps> & {
  Header: typeof Header;
  Body: typeof Body;
};

const Item: ItemComponent = ({ className, vestingType, children, ...props }) => {
  const icon = useMemo<ReactNode>(() => {
    const iconProps: JSX.IntrinsicElements["svg"] = {
      className: "icon-size-4 me-1",
    };

    switch (vestingType) {
      case VestingTypesEnum.TIME_VESTING:
        return <TimeHourglassIcon {...iconProps} />;
      case VestingTypesEnum.MILESTONE_VESTING:
        return <TargetIcon {...iconProps} />;
    }

    return null;
  }, [vestingType]);

  return (
    <div className={classNames("mb-2", classes["item"], className)} {...props}>
      {!!icon && (
        <div className="d-flex align-items-center px-1 py-2">
          {icon}

          <div className="ui-bold-s">{t(`vestingTypeNames.${vestingType}`)}</div>
        </div>
      )}

      <div className={classes["content"]}>{children}</div>
    </div>
  );
};

Item.Header = Header;
Item.Body = Body;

export default Item;
