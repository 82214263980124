import { useMemo } from "react";

import { PlanFormType } from "pages/equity-management/plans/wizards/create-plan/steps/basics/sidebar/forms/plan-form";
import { createTranslation, TranslationNS } from "translation";

import { TagVariant } from "../components/atoms";
import { PlanStatusesBasedOnAPIStatusId } from "../enums/enum";

const t = createTranslation(TranslationNS.common, "plan.status");
const tPlan = createTranslation(TranslationNS.pages, "createPlan.basics");

type PlanStatus = {
  label: string;
  variant: TagVariant;
};

type PlanStatuses = Record<string, { label: string; variant: TagVariant }>;

type UsePlanType = {
  (statusId: PlanStatusesBasedOnAPIStatusId): PlanStatus;
  (): PlanStatuses;
};

export const usePlanStatus = ((statusId?: PlanStatusesBasedOnAPIStatusId): PlanStatus | PlanStatuses => {
  const statuses = useMemo<PlanStatuses>(
    () => ({
      [PlanStatusesBasedOnAPIStatusId.draft]: {
        label: t("draft"),
        variant: "draft",
      },
      [PlanStatusesBasedOnAPIStatusId.approved]: {
        label: t("approved"),
        variant: "complete",
      },
      [PlanStatusesBasedOnAPIStatusId.cancelled]: {
        label: t("cancelled"),
        variant: "closed",
      },
      [PlanStatusesBasedOnAPIStatusId.active]: {
        label: t("active"),
        variant: "complete",
      },
      [PlanStatusesBasedOnAPIStatusId.closed]: {
        label: t("closed"),
        variant: "closed",
      },
      [PlanStatusesBasedOnAPIStatusId.completed]: {
        label: t("completed"),
        variant: "complete",
      },
      [PlanStatusesBasedOnAPIStatusId.granted]: {
        label: t("granted"),
        variant: "information",
      },
      [PlanStatusesBasedOnAPIStatusId.terminated]: {
        label: t("terminated"),
        variant: "declined",
      },
      [PlanStatusesBasedOnAPIStatusId.waitingForManager]: {
        label: t("waitingForManager"),
        variant: "information",
      },
      [PlanStatusesBasedOnAPIStatusId.waitingForReceiver]: {
        label: t("waitingForReceiver"),
        variant: "information",
      },
      [PlanStatusesBasedOnAPIStatusId.expired]: {
        label: t("expired"),
        variant: "expired",
      },
    }),
    []
  );

  if (statusId) {
    return statuses?.[statusId];
  }
  return statuses;
}) as UsePlanType;

export const headerText = (formVariant: PlanFormType, isEdit: boolean, firstName = "") => {
  if (isEdit) return tPlan("planSidebar.editTitle", { firstName });

  if (formVariant === PlanFormType.RSAPlan || formVariant === PlanFormType.SOPlan) {
    return tPlan("planSidebar.addTitle");
  }

  if (formVariant === PlanFormType.ExistSOPlan || formVariant === PlanFormType.ExistRSAPlan) {
    return tPlan("planSidebar.addExistingTitle");
  }
};
