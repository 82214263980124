import { useCallback, useEffect, useMemo } from "react";
import { useLocation } from "react-router";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Form, Formik, FormikConfig } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { useWizard } from "common/layout/WizardLayout/hooks";
import { useStoreActions, useStoreState } from "store/store";

import Basic from "./Steps/Basic/Basic";
import Shares from "./Steps/Shares/Shares";
import Summary from "./Steps/Summary/Summary";
import usePoolForm, { PoolFormValues } from "./usePoolForm";
import usePoolFormValidation from "./usePoolFormValidation";

export enum poolSubmitter {
  draft = "draft",
  create = "create",
  postpone = "postpone",
}

const CreatePoolForm = () => {
  const navigate = useNavigate();
  const { state: poolState } = useLocation();
  const poolId = poolState?.poolId;

  const { companyId } = useParams<{ companyId: string }>();

  const createOrUpdatePoolThunk = useStoreActions((actions) => actions.poolModel.createOrUpdatePoolThunk);
  const { getPoolThunk, setPoolAction, approvePoolThunk } = useStoreActions((actions) => actions.poolModel);
  const pool = useStoreState((state) => state.poolModel.pool);

  const { wizardStep } = useWizard();

  const { initialValues } = usePoolForm(pool, companyId);

  const poolValidationSchema = usePoolFormValidation();

  const validationSchema = useMemo(() => {
    const validateStep = {
      1: ["name", "description"],
      2: ["shareSourceTypeId", "shareSourceStakeholderId", "shareClassId", "numberOfShares"],
      3: ["documentStatusId", "approvalDate", "expiryDate", "approvalBodyId", "filesData"],
    };

    return poolValidationSchema.pick(validateStep[wizardStep as keyof typeof validateStep]);
  }, [poolValidationSchema, wizardStep]);

  useEffect(() => {
    if (poolId) {
      getPoolThunk(poolId);
    } else {
      setPoolAction(null);
    }
  }, [getPoolThunk, poolId, setPoolAction]);

  const handleSubmit = useCallback<FormikConfig<PoolFormValues>["onSubmit"]>(
    async ({ submitter, ...values }, { setTouched }) => {
      try {
        let resPoolId = undefined;

        if (submitter !== poolSubmitter.postpone) {
          const res = await createOrUpdatePoolThunk(values);
          resPoolId = res?.data?.poolId;
        }

        setTouched({});

        if (wizardStep === 1) {
          navigate(getEMPath(["createPool", "shares"]), { state: { poolId: resPoolId }, replace: true });
        } else if (wizardStep === 2) {
          navigate(getEMPath(["createPool", "summary"]), { state: { poolId: resPoolId }, replace: true });
        } else if (wizardStep === 3) {
          if (submitter === poolSubmitter.create) {
            await approvePoolThunk(values);
          }
          navigate(getEMPath(["plans", "poolsAndPrograms"]));
        }
      } catch (error) {
        console.error(error);
      }
    },
    [approvePoolThunk, createOrUpdatePoolThunk, navigate, wizardStep]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <Form>
        <Routes>
          <Route path={ROUTER_V2.equityManagement.createPool.basic} element={<Basic />} />
          <Route path={ROUTER_V2.equityManagement.createPool.shares} element={<Shares />} />
          <Route path={ROUTER_V2.equityManagement.createPool.summary} element={<Summary />} />
        </Routes>
      </Form>
    </Formik>
  );
};

export default CreatePoolForm;
