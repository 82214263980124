import { FC, ReactNode } from "react";
import cn from "classnames";

import { Variant } from "store/GettingStartedGuideModel";

import classes from "./step.module.scss";

type PropsTypes = {
  children?: ReactNode;
  status?: Variant;
  id?: string;
  variant?: "circle" | "pill";
  className?: string;
};
const StepItem: FC<PropsTypes> = ({ children, status, id, variant = "circle", className }) => {
  return (
    <div
      className={cn(classes[status || "waiting"], className, {
        [classes.item]: variant === "circle",
        [classes.itemPill]: variant === "pill",
      })}
      id={id}
    >
      {children}
    </div>
  );
};

export default StepItem;
