import { ChangeEventHandler, FC, Fragment, useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormikContext } from "formik";

import { ChecksGroup } from "common/components/atoms";
import Helper from "common/components/atoms/Helper/Helper";
import PeriodPickerFormik from "common/components/atoms/PeriodPickerFormik/PeriodPickerFormik";
import { CreateProgramFormData } from "pages/equity-management/plans/wizards/create-program/useProgramForm";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "program.components.programForm.termination");

const TerminationFields: FC<{ withoutExcludingSection?: boolean }> = ({ withoutExcludingSection }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<CreateProgramFormData>();

  const terminations = useMemo(() => {
    return [
      {
        key: "retirementValue",
        label: t("retirementValue.label"),
        answer: t("retirementValue.answer"),
        amount: "2",
      },
      {
        key: "disabilityValue",
        label: t("disabilityValue.label"),
        answer: t("disabilityValue.answer"),
        amount: "12",
      },
      {
        key: "terminationWithCauseValue",
        label: t("terminationWithCauseValue.label"),
        answer: t("terminationWithCauseValue.answer"),
        amount: "1",
      },
      {
        key: "involuntaryTerminationValue",
        label: t("involuntaryTerminationValue.label"),
        answer: t("involuntaryTerminationValue.answer"),
        amount: "1",
      },
      {
        key: "voluntaryTerminationValue",
        label: t("voluntaryTerminationValue.label"),
        answer: t("voluntaryTerminationValue.answer"),
        amount: "3",
      },
      {
        key: "terminationByPassingValue",
        label: t("terminationByPassingValue.label"),
        answer: t("terminationByPassingValue.answer"),
        amount: "12",
      },
    ];
  }, []);

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (event) => {
      await setFieldValue(event.target.name, Boolean(+event.target.value));

      const isExcludedClause = Boolean(+event.target.value);

      terminations.forEach(({ key }) => {
        setFieldValue(key, isExcludedClause ? null : "");
        setFieldValue(key.replace("Value", "Unit"), isExcludedClause ? "" : "Month");
        setFieldTouched(key, false);
        setFieldTouched(key.replace("Value", "Unit"), false);
      });
    },
    [setFieldTouched, setFieldValue, terminations]
  );

  return (
    <Helper>
      {withoutExcludingSection ? null : (
        <ChecksGroup className="mb-5">
          <ChecksGroup.Check
            type="radio"
            label={t("includeClauses")}
            description={t("allowAfterTermination")}
            checked={!values.isExcludedClause}
            name="isExcludedClause"
            value={0}
            onChange={handleChange}
          />

          <ChecksGroup.Check
            type="radio"
            label={t("excludeClauses")}
            description={t("prohibitAfterTermination")}
            checked={values.isExcludedClause}
            name="isExcludedClause"
            value={1}
            onChange={handleChange}
          />
        </ChecksGroup>
      )}

      {values.isExcludedClause
        ? null
        : terminations.map((data) => (
            <Fragment key={data.key}>
              <Row className="mb-4">
                <Col md={8}>
                  <Helper.Question questionId={data.key} type="input">
                    <PeriodPickerFormik
                      label={data.label}
                      nameAmount={data.key}
                      namePeriod={data.key.replace("Value", "Unit")}
                      placeholderAmount={""}
                    />
                  </Helper.Question>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Helper.Answer className="mb-3" answerId={data.key}>
                    {data.answer}
                  </Helper.Answer>
                </Col>
              </Row>
            </Fragment>
          ))}
    </Helper>
  );
};

export default TerminationFields;
