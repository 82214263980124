import React, { FC } from "react";
import classNames from "classnames";
import { defaultTo } from "ramda";

import { createTranslation, TranslationNS } from "translation";

import { Button, NewAvatar, Ui } from "../../components/atoms";
import { SubscriptionDetailsDTOType } from "../../types/Common.types";
import classes from "./NoSubscription.module.scss";

const t = createTranslation(TranslationNS.common, "noSubscription");

type Props = Partial<
  Pick<
    SubscriptionDetailsDTOType,
    "buyerAvatarFilePath" | "buyerInitials" | "buyerFirstName" | "buyerLastName" | "buyerEmail"
  >
>;

const SubscriptionManagerInfo: FC<Props> = ({
  buyerAvatarFilePath,
  buyerInitials,
  buyerFirstName,
  buyerLastName,
  buyerEmail,
}) => {
  return (
    <div className={classNames(classes["subscription-manager"], "px-3 mt-1")}>
      <NewAvatar
        className="me-2"
        imageUrl={defaultTo(undefined, buyerAvatarFilePath)}
        initials={buyerInitials ? buyerInitials : `${buyerFirstName?.[0]}${buyerLastName?.[0]}`}
        size="m"
      />
      <div>
        <Ui.xl bold>
          {buyerFirstName} {buyerLastName}
        </Ui.xl>

        <Ui.s color="foregroundLow">{buyerEmail}</Ui.s>
      </div>

      <Button variant="secondary" size="s" as="a" href={`mailto:${buyerEmail}`}>
        {t("sendEmail")}
      </Button>
    </div>
  );
};

export default SubscriptionManagerInfo;
