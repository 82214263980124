import { FC } from "react";

import { H, Tag } from "common/components/atoms";
import { InstrumentTypesNamesEnum } from "common/enums/enum";
import { ProfileHeadIcon } from "common/icons/svg";
import { usePlanStatus } from "common/plan/planUtils";
import { createTranslation, TranslationNS } from "translation";

import { getCurrentAgreementContext } from "../functions";

/**
 * Description: agreement header part, displaying for slide panel section
 * @param { type } InstrumentTypesNamesEnum Type for specific agreement. Will choose the necessary context for agreement
 */

const t = createTranslation(TranslationNS.common, "organisms.agreement.header");

const SOAgreementHeader: FC<{ type: InstrumentTypesNamesEnum }> = ({ type }) => {
  const { agreementDetails } = getCurrentAgreementContext(type).useStoreState((state) => state);

  const planStatuses = usePlanStatus();

  return (
    <div className=" mb-5 d-flex align-items-center">
      <div className="me-2">
        <ProfileHeadIcon fontSize={40} />
      </div>

      <H.s>
        {t("title", {
          profileName: agreementDetails?.stakeholderName,
        })}
      </H.s>

      {agreementDetails?.planStatusId ? (
        <Tag variant={planStatuses[agreementDetails.planStatusId || 0].variant} className="ms-2">
          {planStatuses[agreementDetails.planStatusId || 0].label}
        </Tag>
      ) : null}
    </div>
  );
};

export default SOAgreementHeader;
