import axios from "axios";
import { Action, action, Actions, createContextStore, EffectOn, effectOn, StateMapper, Thunk, thunk } from "easy-peasy";

import { AgreementMilestoneStatuses, AgreementUIType } from "common/enums/enum";
import { CommonOwnershipPlanDetails } from "store/types";

import { IExercisablePlan, IOngoingExercises } from "../../../../../pages/my-porfolio/exercising/ExercisingContext";
import { SimulationValues } from "../components/common/simulation-actions/SimulationActions";
import { MilestoneModalProps } from "../components/common/types";
import { AgreementFeatures } from "../types";

/**
 * Description: context that might be used for stock options agreement usage
 * Potentially it's internal realisation. User should only pass @param { agreementID } number property
 * Initially adding the case - just for one @param { apiBase } url As we're using the same endpoint through all the places
 * Might be improved in the future.
 */

const apiBase = "/api/ownership/ownership-plan";

interface ContextModel {
  UIType: AgreementUIType;
  setUIType: Action<this, this["UIType"]>;

  isLoading: boolean;
  setIsLoading: Action<this, this["isLoading"]>;

  agreementDetails: CommonOwnershipPlanDetails | null;
  setAgreementDetails: Action<this, this["agreementDetails"] | null>;

  getAgreementDetailsThunk: Thunk<this, number>;

  milestoneModal: MilestoneModalProps | null;
  setMilestoneModal: Action<this, this["milestoneModal"]>;

  updateMilestoneStatus: Thunk<
    this,
    {
      planId: number;
      milestoneId: number;
      completedAt: string;
      statusId: AgreementMilestoneStatuses;
    }
  >;

  overviewSimulationValue: SimulationValues | null;
  setOverviewSimulationValue: Action<this, this["overviewSimulationValue"]>;

  features?: AgreementFeatures | null;
  setFeatures: Action<this, this["features"]>;

  effect?: EffectOn;

  activeExercisablePlan: CommonOwnershipPlanDetails | null | undefined;
  setActiveExercisablePlan: Action<this, CommonOwnershipPlanDetails | null>;
  exercisablePlans: IExercisablePlan[];
  addUserExercisingThunk: Thunk<this, { planId: number; numberOfOptions: number }>;
  loading: boolean;
  setLoading: Action<this, boolean>;
}

const SOAgreementContext = createContextStore<ContextModel>(
  {
    UIType: AgreementUIType.regular,
    setUIType: action((state, payload) => {
      state.UIType = payload;
    }),

    isLoading: false,
    setIsLoading: action((state, payload) => {
      state.isLoading = payload;
    }),

    agreementDetails: null,
    setAgreementDetails: action((state, payload) => {
      state.agreementDetails = payload;
    }),
    getAgreementDetailsThunk: thunk(async (actions, agreementID, { injections }) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.get<CommonOwnershipPlanDetails>(`${apiBase}/stock-option-details/${agreementID}`);

        if (request.status === 200) {
          actions.setAgreementDetails(request.data);
        }
      } catch (e) {
        return await Promise.reject(e);
      } finally {
        actions.setIsLoading(false);
      }
    }),

    milestoneModal: null,
    setMilestoneModal: action((state, payload) => {
      state.milestoneModal = payload;
    }),

    updateMilestoneStatus: thunk(async (actions, data) => {
      try {
        actions.setIsLoading(true);

        const request = await axios.post("/api/ownership/plan/milestones/update-status", data);

        if (request.status === 200) {
          actions.getAgreementDetailsThunk(data.planId);
        }
      } catch (e) {
        return await Promise.reject(e);
      } finally {
        actions.setIsLoading(false);
      }
    }),

    overviewSimulationValue: null,
    setOverviewSimulationValue: action((state, payload) => {
      state.overviewSimulationValue = payload;
    }),

    features: null,
    setFeatures: action((state, payload) => {
      state.features = payload;
    }),

    // running this effect to detect updates when we're setting agreement type
    effect: effectOn(
      [
        (state) => {
          const typedState = state as StateMapper<ContextModel>;

          return typedState;
        },
      ],
      (actions: Actions<ContextModel>, change, { injections }) => {
        // we're setting this action under the hood during setting type of agreement
        actions.setFeatures(injections?.features);
      }
    ),

    loading: false,
    setLoading: action((state, payload) => {
      state.loading = payload;
    }),
    activeExercisablePlan: null,
    setActiveExercisablePlan: action((state, payload) => {
      state.activeExercisablePlan = payload;
    }),

    exercisablePlans: [],
    addUserExercisingThunk: thunk(async (actions, payload, { fail }) => {
      try {
        actions.setLoading(true);
        const response = await axios.post("/api/ownership/user-exercising", payload);
        return response.data;
      } catch (e) {
        fail(e);
        throw e;
      } finally {
        actions.setLoading(false);
      }
    }),
  },
  {
    name: "Organism - SOAgreement context",
    injections: {} as AgreementFeatures,
  }
);

export default SOAgreementContext;
