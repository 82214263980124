  import { InstrumentTypesNamesEnum } from "common/enums/enum";

  import RSAAgreementContext from "../../contexts/RSAAgreementContext";
  import SOAgreementContext from "../../contexts/SOAgreementContext";

  type SelectedContextType = typeof RSAAgreementContext | typeof SOAgreementContext;

  type ContextesType<T> = {
    [key in InstrumentTypesNamesEnum]?: T extends infer U ? U : never;
  };

  const Contextes: ContextesType<SelectedContextType> = {
    [InstrumentTypesNamesEnum.RSA]: RSAAgreementContext,
    [InstrumentTypesNamesEnum.OPTION]: SOAgreementContext,
  };

  export function getCurrentAgreementContext(context: InstrumentTypesNamesEnum ) {
    const SelectedProvider = Contextes[context];

    if (!SelectedProvider) {
      return SOAgreementContext;
    }

    return SelectedProvider;
  }
