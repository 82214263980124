import { FC, useCallback } from "react";
import { Link } from "react-router-dom";

import { getPath } from "app/Router/RouterHelper";
import Button from "common/components/atoms/Button/Button";
import { H, Ui } from "common/components/atoms/Typography";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import useGetLandingCompanies from "common/hooks/useGetLandingCompanies";
// import AttractCard from "common/landingDashboard/components/AttractCard";
import CompanyCard from "common/landingDashboard/components/CompanyCard";
import DetailsCard from "common/landingDashboard/components/DetailsCard";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "./Dashboard.module.scss";

const t = createTranslation(TranslationNS.pages, "company.multiCompaniesDashboard");

const UserDashboard: FC = () => {
  useDocumentTitleUpdate("Welcome");

  const { companies } = useGetLandingCompanies();

  const { user } = useStoreState((state) => state.account);

  const RenderCompanyContent = useCallback(() => {
    if (companies.length > 1) {
      return (
        <>
          <DetailsCard />

          {companies.map((el) => (
            <CompanyCard key={el.id} {...el} />
          ))}
        </>
      );
    }

    if (companies.length === 1) {
      return (
        <>
          <DetailsCard />

          <CompanyCard {...companies[0]} />

          {/*<AttractCard isSingle followId={companies[0]?.id} />*/}
        </>
      );
    }
  }, [companies]);

  return (
    <PageContent className={classes["container"]} data-testid="company-profile-home-test-id">
      <PageContent.Header className="mb-1">
        <H.s>
          {t("title", {
            name: user?.firstName,
          })}
        </H.s>
      </PageContent.Header>
      <div className="d-flex align-items-center justify-content-between">
        <Ui.l>{t("description")}</Ui.l>

        <Button variant="tertiary">
          <Link to={getPath(["onboard", "company"])}>{t("newCompany")}</Link>
        </Button>
      </div>

      <div className="d-flex flex-wrap gap-4 mt-8">{<RenderCompanyContent />}</div>
    </PageContent>
  );
};

export default UserDashboard;
