import { FC, MouseEventHandler, useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormik } from "formik";
import * as R from "ramda";

import DatePicker from "common/components/atoms/DatePicker/DatePicker";
import Dropdown from "common/components/atoms/Dropdown/Dropdown";
import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import LinkHelpText from "common/components/atoms/LinkHelpText/LinkHelpText";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import TextField from "common/components/atoms/TextField/TextField";
import { P, Ui } from "common/components/atoms/Typography";
import DocumentUploadWithStatusesSignatureEmailInvitation from "common/components/molecules/DocumentUploadWithStatusesSignatureEmailInvitation/DocumentUploadWithStatusesSignatureEmailInvitation";
import WarrantDetails from "common/components/molecules/warrantDetails/WarrantDetails";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { useToastFormikValidatorWithParams } from "common/hooks/useToastFormikValidator";
import { InformationCircleOutlinedIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import IssueEquityContext from "pages/equity-management/ownership/financial-instruments/IssueEquityContext";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useSettleWarrantForm from "./useSettleWarrantForm";

const t = createTranslation(TranslationNS.pages, "company.issueEquity.warrants.conversion.settle");

const FormContent: FC<{ handleClosePanel: () => void }> = ({ handleClosePanel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const fNumber = useFormatNumbers();

  const { shareClassModel } = useStoreState((state) => state);

  const { warrantDetails, warrantId } = IssueEquityContext.useStoreState((state) => state);
  const { getWarrantDetailsThunk } = IssueEquityContext.useStoreActions((actions) => actions);

  const { initialValues, validationSchema, onSubmit } = useSettleWarrantForm(handleClosePanel);

  const {
    values,
    errors,
    submitCount,
    isValid,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    isSubmitting,
    isValidating,
    handleBlur,
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useToastFormikValidatorWithParams(isValid, isValidating, isSubmitting, submitCount);

  const handleDocumentChange = (data: FilesDataMultiple) => {
    setFieldValue("documents", data.newFiles);
  };

  const documentStatusChangeHandler = (status: number) => {
    setFieldValue("documentStatus", status);
  };

  const signatureChangeHandler = (value: boolean) => {
    setFieldValue("isNeedSignatureChecked", value);
  };

  const emailInvitationChangeHandler = (value: boolean) => {
    setFieldValue("isNeedEmailInvitationChecked", value);
  };

  useEffect(() => {
    if (warrantId) {
      setIsLoading(true);
      getWarrantDetailsThunk(warrantId)
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [getWarrantDetailsThunk, warrantId]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <SlidePanel.Section title="" isSingle>
        <WarrantDetails
          title={t("originalTerms")}
          companyPercentage={warrantDetails?.companyPercentage}
          email={warrantDetails?.email}
          expirationDate={warrantDetails?.expirationDate}
          strikePrice={R.defaultTo(0, warrantDetails?.strikePrice)}
          numberOfShares={R.defaultTo(0, warrantDetails?.numberOfShares)}
          warrantPrice={R.defaultTo(0, Number(warrantDetails?.warrantPrice))}
          representedBy={`${R.defaultTo("", warrantDetails?.firstName)} ${R.defaultTo("", warrantDetails?.lastName)}`}
        />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("settlement")}>
        <Row>
          <Col>
            <TextField
              min={0}
              type="number"
              label={t("numberOfShares")}
              value={values.numberOfShares}
              error={errors.numberOfShares}
              isTouched={touched.numberOfShares}
              name="numberOfShares"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>

          <Col>
            <DatePicker
              isDateOnlyString
              label={t("settleDate")}
              date={values.settleDate}
              error={errors.settleDate}
              isTouched={touched.settleDate}
              onChange={(date) => {
                setFieldValue("settleDate", date);
              }}
              name={"settleDate"}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row className="my-4">
          <Col>
            <Dropdown
              label={t("shareClass")}
              error={errors.shareClassId}
              selectedValue={values.shareClassId}
              isTouched={touched.shareClassId}
              options={shareClassModel.shareClassesCompany}
              optionsIsObject
              name="shareClassId"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>

          <Col />
        </Row>

        <Ui.m className="d-flex align-items-center fw-500 mb-4">
          {t("investedAmount", {
            amount: fNumber(R.defaultTo(0, warrantDetails?.strikePrice) * values.numberOfShares, "value"),
          })}
          <LinkHelpText type="modal" title="" content={t("investedAmountHelperText")}>
            <InformationCircleOutlinedIcon className="ms-1" width={24} height={24} />
          </LinkHelpText>
        </Ui.m>
      </SlidePanel.Section>

      <SlidePanel.Section title={t("documentation")} isDividerVisible={false}>
        <P.m className="mb-4">{t("docsDescription")}</P.m>

        <DocumentUploadWithStatusesSignatureEmailInvitation
          documentStatus={values.documentStatus}
          isNeedSignatureChecked={values.isNeedSignatureChecked}
          isNeedEmailInvitationChecked={values.isNeedEmailInvitationChecked}
          onFileUploaderChange={handleDocumentChange}
          setDocumentStatus={documentStatusChangeHandler}
          setIsNeedSignatureChecked={signatureChangeHandler}
          setIsNeedEmailInvitationChecked={emailInvitationChangeHandler}
        />

        <Ui.m italic className="mt-3" style={{ color: scssVariables.foregroundHigh }}>
          {t("settlingWarrants")}
        </Ui.m>
      </SlidePanel.Section>

      <SlidePanel.Actions
        secondaryTitle={t("cancel")}
        primaryTitle={t("sendAgreement")}
        secondaryAction={handleClosePanel}
        isDisabledPrimary={isSubmitting}
        isLoading={isSubmitting}
        primaryAction={handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}
      />
    </SlidePanel.Body>
  );
};

const SettleWarrantSlidePanel: FC = () => {
  const { isSettleWarrantEditPanelOpen } = IssueEquityContext.useStoreState((state) => state);
  const { setIsSettleWarrantEditPanelOpen, setWarrantDetails } = IssueEquityContext.useStoreActions(
    (actions) => actions
  );

  const handleClosePanel = useCallback(() => {
    setWarrantDetails(null);
    setIsSettleWarrantEditPanelOpen({ open: false });
  }, [setIsSettleWarrantEditPanelOpen, setWarrantDetails]);

  return (
    <SlidePanel show={isSettleWarrantEditPanelOpen}>
      <SlidePanel.Header title={t("title")} onHide={handleClosePanel} />
      <FormContent handleClosePanel={handleClosePanel} />
    </SlidePanel>
  );
};

export default SettleWarrantSlidePanel;
