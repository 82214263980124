export const initialTableConfig = {
  defaultColumn: {
    minWidth: 75,
    width: 150,
    maxWidth: 300,
  },
  initialState: {
    pageIndex: 0,
    pageSize: 10000,
  },
};

export const manageRow = <T extends object>(
  values: T[],
  rowIndex: number,
  type: string | undefined,
  initialItems: T,
  deleteKeys: (keyof T)[] = []
) => {
  if (type === "deleteRow") {
    return values.filter((_row, index) => index !== rowIndex);
  } else if (type === "copyRow") {
    const copyData = [...values];
    const copiedRow = { ...values[rowIndex] };
    deleteKeys.forEach((key) => {
      delete copiedRow[key];
    });
    copyData.splice(rowIndex + 1, 0, copiedRow);
    return copyData;
  } else if (type === "addRow") {
    const copyData = [...values];
    copyData.splice(rowIndex + 1, 0, { ...initialItems });
    return copyData;
  }
  return values;
};
