import { FC, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";
import * as R from "ramda";

import { DatePicker, Dropdown, TextField } from "common/components/atoms";
import classes from "common/components/atoms/SlidePanel/SlidePanel.module.scss";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { IssueSharesFields } from "../../issue-shares/form-fields";
import { SharesDetailsFields } from "./form-fields";
import { ShareDetailsFieldsType } from "./types";

type PropsTypes = {
  isDisabled?: boolean;
  isDateWithTime?: boolean;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.sharesDetails");

const SharesDetails: FC<PropsTypes> = ({ isDisabled, isDateWithTime }) => {
  const fNumber = useFormatNumbers();
  const { currencySymbol } = useCurrency();
  const { values, errors, touched, setFieldValue, handleChange } = useFormikContext<ShareDetailsFieldsType>();

  const { shareClassesCompany } = useStoreState((state) => state.shareClassModel);

  const onShareClassDropdownChange = useCallback(
    (e: string) => {
      const selectedClass = shareClassesCompany.find((shareClass) => shareClass.name === e);

      setFieldValue(IssueSharesFields.shareClassId, selectedClass!.id);
    },
    [setFieldValue, shareClassesCompany]
  );

  return (
    <>
      <Row>
        <Col>
          <TextField
            min={0}
            type="number"
            name={SharesDetailsFields.numberOfShares}
            label={t("amountOfShares")}
            value={values.numberOfShares}
            error={errors.numberOfShares}
            isTouched={touched.numberOfShares}
            onChange={handleChange}
            isDisabled={isDisabled}
          />
        </Col>
        <Col>
          <DatePicker
            isDateOnlyString
            isWithTimeSelect={isDateWithTime}
            date={values.sharePurchaseDate}
            name={SharesDetailsFields.sharePurchaseDate}
            className={classes["date-picker"]}
            label={t("sharesPurchaseDate")}
            isTouched={touched.sharePurchaseDate}
            error={errors.sharePurchaseDate}
            onChange={(date) => {
              setFieldValue(SharesDetailsFields.sharePurchaseDate, date);
            }}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <TextField
            min={0}
            step={0.01}
            type="number"
            value={values.purchasePrice}
            error={errors.purchasePrice}
            isTouched={touched.purchasePrice}
            label={t("purchasePrice")}
            onChange={handleChange(SharesDetailsFields.purchasePrice)}
            info={t("totalPurchasePrice", {
              price: fNumber(values.numberOfShares * values.purchasePrice, "value"),
            })}
            iconRight={errors.purchasePrice && touched.purchasePrice ? undefined : currencySymbol}
            isDisabled={isDisabled}
          />
        </Col>
        <Col>
          <Dropdown
            isTouched
            label={t("shareClass")}
            placeholder={t("chooseShareClass")}
            error={errors.shareClassId}
            selectedValue={shareClassesCompany.find((shareClass) => shareClass!.id === values.shareClassId)?.name}
            options={R.pluck("name", shareClassesCompany || [])}
            onChange={onShareClassDropdownChange}
            isDisabled={isDisabled}
          />
        </Col>
      </Row>
    </>
  );
};

export default SharesDetails;
