import { FC, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import * as R from "ramda";
import * as Yup from "yup";

import Button from "common/components/atoms/Button/Button";
import MobileNumberInput from "common/components/atoms/MobileNumberInput/MobileNumberInput";
import TextField from "common/components/atoms/TextField/TextField";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { notify } from "common/utils/notify/notifyFunction";
import ChangePasswordFrom from "pages/user/settings/components/ChangePasswordFrom";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import classes from "./account.module.scss";

const translation = createTranslation(TranslationNS.pages, "user.account");
const validationTranslation = createTranslation("validation");

const UserAccountPage: FC = () => {
  useDocumentTitleUpdate(translation("title"));

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("47");
  const [isInputValid, setIsInputValid] = useState<boolean | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState<boolean>(false);

  const { user } = useStoreState((state) => state.account);
  const { getAccountThunk } = useStoreActions((state) => state.account);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string().label(translation("email.label")).email(validationTranslation("invalidEmail")),
      }),
    []
  );

  const initialValues = useMemo(
    () => ({
      email: R.defaultTo("", user?.email),
      phoneNumber: R.defaultTo("", user?.mobileNumber),
      countryCode: R.defaultTo("", user?.mobilePrefix),
    }),
    [user]
  );

  const { values, errors, handleChange, dirty, touched, handleSubmit } = useFormik({
    onSubmit: submitHandler,
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  async function changeEmail() {
    const changeEmailData = {
      email: values.email,
    };
    try {
      const request = await axios.post("/api/users/change-email", changeEmailData);
      if (request.status === 200) {
        getAccountThunk();
        notify(translation("notify.emailChanged"), true, "success", 3000);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  async function changeMobileNumber() {
    const changeMobileNumberData = {
      mobileNumber: Number(phoneNumber.replace(/ /g, "")),
      mobilePrefix: Number(countryCode),
    };
    try {
      const request = await axios.post("/api/users/change-mobile", changeMobileNumberData);

      if (request.status === 200) {
        getAccountThunk();
        notify(translation("notify.mobileChanged"), true, "success", 3000);
      }
    } catch (e) {
      console.warn(JSON.parse(JSON.stringify(e)));
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }
  }

  async function submitHandler() {
    setIsLoading(true);
    try {
      if (isInputValid || R.isEmpty(phoneNumber)) {
        if (values.email !== user?.email) {
          await changeEmail();
        }
        if (isInputValid && !R.isEmpty(phoneNumber)) {
          if (`${countryCode}${phoneNumber}`.split(" ").join("") !== `${user?.mobilePrefix}${user?.mobileNumber}`) {
            await changeMobileNumber();
          }
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (user?.mobileNumber) {
      setPhoneNumber(`${user?.mobileNumber}`);
    }
    if (user?.mobilePrefix) {
      setCountryCode(`${user?.mobilePrefix}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContent>
      <PageContent.Header>
        <PageContent.Header.Title className="me-2">{translation("title")}</PageContent.Header.Title>
      </PageContent.Header>
      <div className={classes["account"]}>
        <h6 className="m-0 mb-2 p-0 head-xxs">{translation("contactInformation.title")}</h6>
        <p className="m-0 mb-5 p-0 paragraph-s">{translation("contactInformation.subtitle")}</p>
        <TextField
          type="email"
          label={translation("email.label")}
          value={values.email}
          style={{ width: "380px" }}
          className="mb-5"
          error={errors.email && touched.email ? String(errors.email) : undefined}
          onChange={handleChange("email")}
        />

        <div className="d-flex flex-column mb-10" style={{ width: 380 }}>
          <MobileNumberInput
            inputValue={phoneNumber}
            countryCode={countryCode}
            setIsValid={setIsInputValid}
            setInputValue={setPhoneNumber}
            setCountryCode={setCountryCode}
          />
        </div>

        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={() => {
            handleSubmit();
          }}
        >
          {translation("save")}
        </Button>

        <ChangePasswordFrom
          open={isChangePasswordModalOpen}
          handleClose={(withModalOpen) => {
            setIsChangePasswordModalOpen(Boolean(withModalOpen));
          }}
        />
      </div>
    </PageContent>
  );
};

export default UserAccountPage;
