import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { motion } from "framer-motion";
import * as R from "ramda";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import TextField from "common/components/atoms/TextField/TextField";
import { H, Ui } from "common/components/atoms/Typography";
import { CheckIcon, CloseIcon, CompanyBuildingIcon } from "common/icons/svg";
import { CollapsibleHeaderProps } from "common/types/Collapsible.types";
import { useStoreState } from "store/store";
import { InvestmentEntity } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import Button from "../../atoms/Button/Button";
import classes from "./PersonalOrCompanyRoleSelection.module.scss";

const t = createTranslation(TranslationNS.common, "commonComponentsForDifferentModules.personalOrCompanyRoleSelection");

type PersonalOrCompanyRoleSelectionProps = {
  title?: string;
  subtitle?: string;
  name: string;
  orgNumber: string;
  isRemote: boolean;
  isHoldingSelected: boolean;
  isCollapsible?: boolean;
  currentStep?: number;
  setName: (value: string) => void;
  setOrgNumber: (value: string) => void;
  setIsRemote: (value: boolean) => void;
  setIsHoldingSelected: (value: boolean) => void;
  selectedCompany?: InvestmentEntity | null;
  selectedInvestmentEntityId?: number;
  setSelectedCompany?: (value: InvestmentEntity | null) => void;
  isRadioDisabled?: boolean;
};

const PersonalOrCompanyRoleSelection: FC<PersonalOrCompanyRoleSelectionProps> = ({
  name,
  title,
  subtitle,
  isRemote,
  isHoldingSelected,
  orgNumber,
  setIsHoldingSelected,
  setIsRemote,
  setName,
  setOrgNumber,
  currentStep,
  selectedCompany,
  setSelectedCompany,
  selectedInvestmentEntityId,
  isCollapsible = true,
  isRadioDisabled,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isGenerateCompanyActive, setIsGenerateCompanyActive] = useState<boolean>(false);
  const { user } = useStoreState((state) => state.account);

  const companies = useMemo(
    () => user?.investmentEntities?.filter((el) => !el.isPersonal) || [],
    [user?.investmentEntities]
  );

  const renderHead = useCallback(
    ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
      return (
        <div
          className={classNames(classes["head"], {
            [classes["opened"]]: Boolean(activeEventKey),
          })}
          onClick={(e) => {
            if (isCollapsible) {
              onClick(e);
              setIsOpen((prev) => !prev);
            }
          }}
        >
          <div className={classes["title"]}>
            {currentStep && <span className={classNames(classes["point"], "ui-m")}>{currentStep}.</span>}

            <H.xxxs>{title ? title : t("title")}</H.xxxs>
          </div>
          <Ui.xs className={classNames(classes["subtitle"], "mt-1")}>{subtitle ? subtitle : t("subtitle")}</Ui.xs>
        </div>
      );
    },
    [isCollapsible, currentStep, title, subtitle]
  );

  const renderCompanyList = useCallback(() => {
    return (
      <div className={classes["companies"]}>
        {companies?.map((el) => {
          const isCompanySelected = R.equals(selectedCompany, el);

          const onCompanyClickHandler = () => {
            setSelectedCompany?.(isCompanySelected ? null : el);
            setIsRemote(!isCompanySelected);

            setIsGenerateCompanyActive(false);
            setOrgNumber("");
            setName("");
          };

          return (
            <div
              key={el.id}
              className={classNames(classes["single"], "mb-1", {
                [classes["selected"]]: isCompanySelected,
              })}
              onClick={onCompanyClickHandler}
            >
              <CompanyBuildingIcon />
              <Ui.m className="ms-3 fw-500">{el.name}</Ui.m>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{
                  opacity: isCompanySelected ? 1 : 0,
                  x: isCompanySelected ? 0 : 20,
                }}
                className={classes["company-check"]}
              >
                <CheckIcon />
              </motion.div>
            </div>
          );
        })}
      </div>
    );
  }, [companies, selectedCompany, setSelectedCompany, setIsRemote, setOrgNumber, setName]);

  useEffect(() => {
    if (name && !selectedInvestmentEntityId) {
      setIsGenerateCompanyActive(true);
    }

    if (selectedInvestmentEntityId) {
      const foundedCompany = user?.investmentEntities?.find((el) => el.id === selectedInvestmentEntityId) || null;

      setSelectedCompany?.(foundedCompany);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!companies.length && isHoldingSelected) {
      setIsGenerateCompanyActive(true);
    }
  }, [companies, isHoldingSelected]);

  return (
    <CollapsibleContainer
      defaultOpen
      withoutDivider
      collapsible={isCollapsible}
      className={classNames(classes["container"], {
        [classes["opened"]]: isOpen,
        [classes["selected"]]: R.or(!R.isNil(selectedCompany), name),
      })}
      Header={renderHead}
    >
      <div id="abb">
        <ChecksGroup className="mt-3 mb-2">
          <ChecksGroup.Check
            type="radio"
            label={t("personal")}
            checked={!isHoldingSelected}
            onChange={() => {
              setOrgNumber("");
              setName("");
              setSelectedCompany?.(null);
              setIsHoldingSelected(false);
            }}
            disabled={isRadioDisabled}
          />

          <ChecksGroup.Check
            type="radio"
            label={t("company")}
            checked={isHoldingSelected}
            onChange={() => {
              setIsHoldingSelected(true);
            }}
            disabled={isRadioDisabled}
          />
        </ChecksGroup>

        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{
            opacity: isHoldingSelected ? 1 : 0,
            height: isHoldingSelected ? "auto" : 0,
          }}
        >
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: isHoldingSelected ? 1 : 0,
              height: isHoldingSelected ? "auto" : 0,
              pointerEvents: isHoldingSelected ? "auto" : "none",
            }}
          >
            {renderCompanyList()}

            {isGenerateCompanyActive ? null : (
              <Button
                variant="tertiary"
                onClick={() => {
                  setIsRemote(false);
                  setSelectedCompany?.(null);
                  setIsHoldingSelected(true);
                  setIsGenerateCompanyActive(true);
                }}
              >
                {t("generateNewCompany")}
              </Button>
            )}
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: -100, height: 0 }}
            animate={{
              opacity: isGenerateCompanyActive ? 1 : 0,
              y: isGenerateCompanyActive ? 0 : -100,
              height: isGenerateCompanyActive ? "auto" : 0,
              pointerEvents: isGenerateCompanyActive ? "auto" : "none",
            }}
            className={classNames(classes["new-company"], {
              [classes["selected"]]: name.trim(),
              [classes["active"]]: isGenerateCompanyActive,
            })}
          >
            <Ui.l bold className="mb-4">
              {t("addNewCompany")}
            </Ui.l>

            <div className="position-relative">
              <Row>
                <Col>
                  <TextField
                    value={name}
                    label={t("companyName")}
                    className={classNames(classes["text-field"], "me-2")}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    onFocus={() => {
                      setIsRemote(false);
                    }}
                  />
                </Col>

                <Col>
                  <TextField
                    value={orgNumber}
                    label={t("orgsNumber")}
                    className={classes["text-field"]}
                    isDisabled={isRemote}
                    placeholder={t("placeholder")}
                    onChange={(e) => {
                      setOrgNumber(e.target.value);
                    }}
                  />
                </Col>
              </Row>

              <motion.div
                initial={{ opacity: 0, x: 20 }}
                animate={{
                  opacity: name.trim() ? 1 : 0,
                  x: name.trim() ? 0 : 20,
                }}
                className={classes["icon"]}
              >
                <CheckIcon />
              </motion.div>
            </div>

            {companies.length ? (
              <CloseIcon
                width={24}
                height={24}
                className={classes["close-icon"]}
                onClick={() => {
                  setIsGenerateCompanyActive(false);
                  setOrgNumber("");
                  setName("");
                }}
              />
            ) : null}
          </motion.div>
        </motion.div>
      </div>
    </CollapsibleContainer>
  );
};

export default PersonalOrCompanyRoleSelection;
