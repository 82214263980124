import { FC, useMemo } from "react";
import { defaultTo } from "ramda";

import { Tag, Ui } from "common/components/atoms";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { ArrowRightIcon, CommonFileIcon, FolderIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import { Transaction, TransactionStatus } from "../../../../types";
import formatTransactionDate from "../../../format-transaction-date";

type Props = {
  transaction: Transaction;
};

const NominalValueItemHead: FC<Props> = ({ transaction }) => {
  const isPending = transaction.statusId === TransactionStatus.Pending;
  const fNumber = useFormatNumbers(transaction.currencySymbol);

  const tagContent = useMemo(() => {
    const isDecreased = defaultTo(0, transaction.sharePriceBefore) > defaultTo(0, transaction.sharePrice);

    return {
      isDecreased,
      title: isDecreased ? "Nominal value decreased" : "Nominal value increased",
      tag: "Change nominal value",
    };
  }, [transaction.sharePrice, transaction.sharePriceBefore]);

  return (
    <div>
      <Ui.m className="mb-1" bold>
        {tagContent.title}
      </Ui.m>

      <div className="d-flex align-items-center">
        <Tag variant={isPending ? "closed" : "complete"}>
          <Ui.xs>{tagContent.tag}</Ui.xs>
        </Tag>

        <div
          className="d-flex align-items-center mx-2 px-1"
          style={{
            height: 20,
            borderRadius: 8,
            color: isPending ? scssVariables.information900 : scssVariables.foregroundLow,
            backgroundColor: isPending ? scssVariables.information050 : undefined,
          }}
        >
          <Ui.xs className="ms-1">{formatTransactionDate(transaction.transactedAt)}</Ui.xs>

          {isPending ? <Ui.xs>&nbsp;•&nbsp;Pending</Ui.xs> : null}
        </div>

        <div className="d-flex align-items-center" style={{ color: scssVariables.foregroundLow }}>
          <Ui.xs className="me-half">{fNumber(transaction.sharePriceBefore, "sharePrice")}</Ui.xs>
          <ArrowRightIcon height={16} width={16} color={scssVariables.foregroundLow} />
          <Ui.xs className="ms-half">{fNumber(transaction.sharePrice, "sharePrice")}</Ui.xs>

          <Ui.xs>&nbsp;(nominal value)</Ui.xs>

          {transaction.description ? (
            <CommonFileIcon className="ms-2" height={16} width={16} color={scssVariables.foregroundLow} />
          ) : null}

          {transaction?.numberOfFiles > 0 ? (
            <div className="d-flex align-items-center ms-1">
              <FolderIcon className="me-half" /> <Ui.xs>{transaction?.numberOfFiles}</Ui.xs>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default NominalValueItemHead;
