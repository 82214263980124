import { FC, MouseEventHandler, useCallback, useContext, useEffect, useRef, useState } from "react";
import { AccordionContext, Modal, ProgressBar } from "react-bootstrap";
import cn from "classnames";
import { assoc } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, H, P, Tag, Ui } from "common/components/atoms";
import AvatarsList from "common/components/atoms/AvatarsList/AvatarsList";
import { PoolStatuses } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { DeleteIcon, EditIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import PoolsAndProgramsStore from "pages/equity-management/plans/pools-and-programs/store";
import { ProgramType } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ProgramCard.module.scss";
import ProgramModal from "./ProgramModal/ProgramModal";

export type ProgramCardProps = {
  program: ProgramType;
};

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.poolsAndPrograms.programCard"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const ProgramCard: FC<ProgramCardProps> = ({ program }) => {
  const ref = useRef<HTMLDivElement>(null);
  const fNumber = useFormatNumbers();

  const { activeEventKey } = useContext(AccordionContext);
  const { hasFullAccess } = useFeatures(FEATURES.poolsAndPrograms);

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isReadMoreVisible, setIsReadMoreVisible] = useState<boolean>(false);
  const [programIdToOpen, setProgramIdToOpen] = useState<number | null>(null);

  const { setEditingItem, setDeletingItem } = PoolsAndProgramsStore.useStoreActions((actions) => actions);

  const openModalHandler = useCallback((): void => {
    setIsModalVisible(true);
  }, []);

  const closeModalHandler = useCallback((): void => {
    setIsModalVisible(false);
  }, []);

  const openEditingModal: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    const updatedProgram = assoc("type", "Program", program) as ProgramType;
    setEditingItem(updatedProgram);
  }, [program, setEditingItem]);

  const openRemovalModal: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      const updatedProgram = assoc("type", "Program", program) as ProgramType;
      setDeletingItem(updatedProgram);
    },
    [program, setDeletingItem]
  );

  const refReinitialize = useCallback(
    (ref: HTMLDivElement | null) => {
      if (!ref) return;

      const isOverflowing = ref.scrollHeight > ref.clientHeight;

      if (isOverflowing) {
        setIsReadMoreVisible(true);
      } else {
        setIsReadMoreVisible(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [program.purpose]
  );

  const onProgramClick = () => {
    // setProgramIdToOpen(program.id);
  };

  const onCloseProgramClick = () => {
    setProgramIdToOpen(null);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      refReinitialize(ref.current);
      clearTimeout(timerId);
    }, 500);
  }, [activeEventKey, refReinitialize]);

  return (
    <>
      <div className={cn("d-flex flex-column", classes["program-card"])} onClick={onProgramClick}>
        <div className="d-flex justify-content-between p-3 pb-0 h-100">
          <div className={classes["program-name-and-description-wrap"]}>
            <Ui.m bold className="mb-1">
              {program.name}
            </Ui.m>

            <P.s className={classes.purpose} title={program.purpose} color="foregroundMedium">
              <div ref={ref}>{program.purpose || "--"}</div>
            </P.s>

            {isReadMoreVisible ? (
              <Ui.s className={classes["read-more"]} color="foregroundMedium" onClick={openModalHandler}>
                Read more
              </Ui.s>
            ) : null}

            <div className="d-flex mb-2 mt-auto align-items-center w-100">
              <ProgressBar now={program.allocatedShares} max={program.numberOfShares} className={classes.progress} />
              <Ui.xs className="ms-1" color="foregroundLow">{`${fNumber(
                program.allocatedShares,
                "amount"
              )} of ${fNumber(program.numberOfShares, "amount")} ${t("sharesUsed")}`}</Ui.xs>
            </div>

            <AvatarsList className="mb-2" stakeholders={program.agreements} />
          </div>
        </div>

        <div className={classes["bottom-section"]}>
          <div>
            {program.statusId === PoolStatuses.draft ? (
              <Tag size="s" variant="draft" className="me-1">
                {t("draft")}
              </Tag>
            ) : null}

            <Tag size="s" variant="closed">
              {program.instrumentType}
            </Tag>
          </div>

          <div className="ms-auto">
            {program.planCount > 0 ? null : (
              <Button
                variant="tertiary"
                className="me-1"
                isOnlyIcon
                size="s"
                onClick={openRemovalModal}
                isDisabled={!hasFullAccess}
                tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
                tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
              >
                <DeleteIcon color={scssVariables.foregroundMedium} />
              </Button>
            )}

            <Button
              isOnlyIcon
              variant="tertiary"
              size="s"
              onClick={openEditingModal}
              isDisabled={!hasFullAccess}
              tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
              tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            >
              <EditIcon color={scssVariables.foregroundMedium} />
            </Button>
          </div>
        </div>
      </div>

      <Modal centered show={isModalVisible} className={classes["description-modal"]} onHide={closeModalHandler}>
        <div className={classes.content}>
          <H.xs className="mb-3">Description</H.xs>

          <P.m>{program?.purpose}</P.m>

          <Button variant="secondary" onClick={closeModalHandler}>
            Close
          </Button>
        </div>
      </Modal>

      <ProgramModal programId={programIdToOpen} onClose={onCloseProgramClick} />
    </>
  );
};

export default ProgramCard;
