import { ChangeEvent, FC, memo, useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { useFormikContext } from "formik";

import Check from "../../Checks/Check";

type CheckboxInputProps = {
  placeholder?: string;
  className?: string;
  columnId: string;
  allActionsCallback?: (value: number) => void;
  canDoActionField?: string;
};

type FormikValues = {
  [key: string]: string | number | boolean | undefined;
};

const CheckboxControllerInput: FC<CheckboxInputProps> = ({
  placeholder = "",
  className,
  columnId,
  allActionsCallback,
  canDoActionField,
}) => {
  const { values, setValues } = useFormikContext<FormikValues[]>();

  const checkboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      const action = values.reduce((acc, item) => {
        return item[columnId] === true ? acc + 1 : acc;
      }, 0);

      if (!action) {
        checkboxRef.current.checked = false;
        checkboxRef.current.indeterminate = false;
        allActionsCallback?.(0);
      } else if (
        action !== values.filter((val) => !(canDoActionField ? val[canDoActionField] === false : false)).length
      ) {
        checkboxRef.current.indeterminate = true;
        allActionsCallback?.(action);
      } else {
        checkboxRef.current.indeterminate = false;
        checkboxRef.current.checked = true;
        allActionsCallback?.(action);
      }
    }
  }, [allActionsCallback, columnId, values, canDoActionField]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const data = values.map((item) => {
        const canDoAction = canDoActionField ? item[canDoActionField] === false : false;
        return !canDoAction
          ? {
              ...item,
              [columnId]: e.target.checked,
            }
          : item;
      });
      setValues(data);
    },
    [canDoActionField, columnId, setValues, values]
  );

  return (
    <Check ref={checkboxRef} placeholder={placeholder} className={classNames(className)} onChange={handleChange} />
  );
};

export default memo(CheckboxControllerInput);
