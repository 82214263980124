import React, { FC, useMemo } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { defaultTo, isNil } from "ramda";

import { Ui } from "common/components/atoms";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";

import classes from "./ProgramContainer.module.scss";

type Props = {
  isOneOff?: boolean;
  isSO?: boolean;
  data: OwnershipProgramTableData[];
};

const ProgramFooterContent: FC<Props> = ({ isOneOff, isSO, data }) => {
  const fNumber = useFormatNumbers();

  const total = data.length;

  const shares = useMemo(() => {
    return data.reduce((acc, curr) => {
      const SOAgreement = curr?.instrumentTypeId === InstrumentTypesIdsEnum.OPTION;

      acc["vested"] = isNil(acc["vested"])
        ? !SOAgreement
          ? 0
          : curr.vestedShares
        : acc["vested"] + defaultTo(0, !SOAgreement ? 0 : curr.vestedShares);
      acc["total"] = isNil(acc["total"])
        ? !SOAgreement
          ? 0
          : curr.restrictedShares
        : acc["total"] + defaultTo(0, !SOAgreement ? 0 : curr.restrictedShares);
      acc["exercised"] = isNil(acc["exercised"])
        ? defaultTo(0, !SOAgreement ? 0 : curr.exercisedShares)
        : acc["exercised"] + defaultTo(0, !SOAgreement ? 0 : curr.exercisedShares);

      return acc;
    }, {} as { [anystring: string]: number });
  }, [data]);

  return (
    <tfoot>
      <tr>
        <td className="ps-4" colSpan={isOneOff ? 3 : isSO ? 2 : 1}>
          <Ui.s bold color="foregroundMedium">
            Total: {total}
          </Ui.s>
        </td>

        <td>
          <div className="d-flex flex-column">
            <Ui.xs color="foregroundLow">
              {fNumber(shares.vested, "amount")}/{fNumber(shares.total, "amount")}
            </Ui.xs>

            <ProgressBar
              id={classes["footer-progress"]}
              now={defaultTo(0, Math.ceil((shares.vested / shares.total) * 100))}
              bsPrefix="table-progress-bar"
            />
          </div>
        </td>

        <td colSpan={8}>
          {isSO || isOneOff ? (
            <div>
              <Ui.s bold color="foregroundMedium">
                {`${fNumber(defaultTo(0, shares?.exercised), "amount")}/${fNumber(
                  defaultTo(0, shares.vested),
                  "amount"
                )}`}
              </Ui.s>
            </div>
          ) : null}
        </td>
      </tr>
    </tfoot>
  );
};

export default ProgramFooterContent;
